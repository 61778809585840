/**
 * AI Service for Watershed
 *
 * This service provides a way to integrate AI capabilities into Watershed features.
 * It allows features to register their stores and actions with the AI service,
 * and provides a chat interface for users to interact with the AI.
 */

// Export AI Chat component
export { AIAgentChat } from './AIAgentChat';

// 2. Register and document your feature through the context
export { useAIAgentRegistry } from './AIAgentContext';

// 2a. Use this helper to create actions
export { createAction } from './actionUtils';
