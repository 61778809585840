import { atom } from 'jotai';
import { Flags } from '@watershed/constants/flags';

import {
  FeatureFlagsMap,
  getAllEnabledFlags,
  isFlagOn,
} from '../utils/FeatureFlag';
import {
  mustAtom,
  useSyncAtom,
} from '@watershed/shared-frontend/components/jotai';

const atomFeatureFlags = mustAtom(
  atom<FeatureFlagsMap | null>(null),
  'featureFlags'
);

export { atomFeatureFlags as atomFeatureFlags_FOR_TEST };

/**
 * This syncs the feature flag map to the atom
 * The function exists to prevent exporting the atom which shouldn't be used apart from the isOn version below
 */
export function useSyncAtomFeatureFlags(flags: FeatureFlagsMap) {
  useSyncAtom(atomFeatureFlags, flags);
}

/**
 * Atom returning a function that can determine whether a flag is enabled
 */
export const atomFeatureFlagIsOn = atom((get) => {
  const flags = get(atomFeatureFlags);
  return { isOn: (flag: Flags) => isFlagOn(flags, flag) };
});

/**
 * Atom returning all enabled flags
 */
export const atomEnabledFeatureFlags = atom<Set<Flags>>((get) => {
  return getAllEnabledFlags(get(atomFeatureFlags));
});
