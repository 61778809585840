import * as GQTypes from './graphql-operations';
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export const UserContactFieldsFragmentDoc = gql`
    fragment UserContactFields on User {
  id
  name
  displayName
  email
  createdAt
  loginActivated
}
    `;
export const UserSearchResultFragmentDoc = gql`
    fragment UserSearchResult on User {
  id
  name
}
    `;
export const ActivityDataTabDataIngestion_UserEditorFragmentDoc = gql`
    fragment ActivityDataTabDataIngestion_UserEditor on UserEditor {
  user {
    id
    displayName
  }
}
    `;
export const ActivityDataTabDataIngestion_ApiEditorFragmentDoc = gql`
    fragment ActivityDataTabDataIngestion_ApiEditor on ApiEditor {
  apiKeyId
}
    `;
export const ActivityDataTabDataIngestion_UploadFragmentDoc = gql`
    fragment ActivityDataTabDataIngestion_Upload on Upload {
  id
  displayName
  createdAt
  editor {
    ...ActivityDataTabDataIngestion_UserEditor
    ...ActivityDataTabDataIngestion_ApiEditor
  }
}
    ${ActivityDataTabDataIngestion_UserEditorFragmentDoc}
${ActivityDataTabDataIngestion_ApiEditorFragmentDoc}`;
export const ActivityDataUserUploadFragmentDoc = gql`
    fragment ActivityDataUserUpload on UserUpload {
  id
  category
  name
  createdAt
  uploader {
    id
    name
  }
  revisionRoot
  deletedAt
}
    `;
export const FootprintExportForListFragmentDoc = gql`
    fragment FootprintExportForList on FootprintAuditBundle {
  id
  createdAt
  status
  footprintEmissionsKind
  footprintSnapshot {
    id
    name
    identifier
    footprint {
      id
      name
    }
  }
}
    `;
export const IngestionExampleDataFieldsFragmentDoc = gql`
    fragment IngestionExampleDataFields on IngestionExampleData {
  id
  title
  columns {
    fieldName
    title
    description
    examples
    required
    optionalToMap
    defaultValue
  }
  rows {
    fields {
      fieldName
      value
    }
  }
  datasetId
}
    `;
export const CtsvOneSchemaFileFeedFieldsFragmentDoc = gql`
    fragment CtsvOneSchemaFileFeedFields on CtsvOneSchemaFileFeed {
  id
  oneSchemaFileFeedId
  numErrorImports
  numSuccessfulImports
  lastRun {
    id
    createdAt
    status
    sourceUserUploadCandidateId
  }
}
    `;
export const UploadSchemaDetailVersionFragmentDoc = gql`
    fragment UploadSchemaDetailVersion on CustomerTargetSchemaVersion {
  id
  schemaJson
  semverVersion
  versionNotesMd
  formattingNotesMd
  deprecatedAt
  plannedRemovedAt
  removedAt
  createdAt
  orgDatasetExampleData {
    id
    ...IngestionExampleDataFields
  }
  transforms {
    id
    transformTarget
    businessActivityType {
      id
      name
    }
  }
  oneSchemaFileFeed {
    ...CtsvOneSchemaFileFeedFields
  }
}
    ${IngestionExampleDataFieldsFragmentDoc}
${CtsvOneSchemaFileFeedFieldsFragmentDoc}`;
export const UploadSchemaExplorerDatasetFragmentDoc = gql`
    fragment UploadSchemaExplorerDataset on Dataset {
  id
  name
  canonicalDataset {
    id
    customerTargetSchemas(hasPublishedVersion: true) {
      id
      name
    }
  }
}
    `;
export const CtsvOneSchemaFileFeedImportFieldsFragmentDoc = gql`
    fragment CtsvOneSchemaFileFeedImportFields on CtsvOneSchemaFileFeedImport {
  id
  oneSchemaFileFeedImportId
  status
  destinationDataPreview
}
    `;
export const UserUploadCandidateFragmentDoc = gql`
    fragment UserUploadCandidate on UserUploadCandidate {
  errorCount
  errorData
  id
  name
  status
  userUploadKind
  datasetId
  createdAt
  rowCount
  ctsVersion {
    id
    schema {
      id
      name
    }
  }
  dataset {
    id
    name
  }
  fileFeed {
    id
    oneSchemaFileFeedId
  }
  latestFileFeedImport {
    ...CtsvOneSchemaFileFeedImportFields
  }
  submissions {
    id
    datasourceId
    measurementProject {
      id
      name
    }
  }
}
    ${CtsvOneSchemaFileFeedImportFieldsFragmentDoc}`;
export const GroupedAuditLogEntryFieldsFragmentDoc = gql`
    fragment GroupedAuditLogEntryFields on GroupedAuditLogEntry {
  id
  createdAt
  eventKind
  numEvents
  firstEventMetadata
  user {
    id
    displayName
  }
  objectKind
}
    `;
export const FootprintExclusionRuleForMethodologyExplainerFragmentDoc = gql`
    fragment FootprintExclusionRuleForMethodologyExplainer on FootprintExclusionRule {
  id
  stableId
  description
  outputValue
}
    `;
export const CalculationTraceForMethodologyExplainerFragmentDoc = gql`
    fragment CalculationTraceForMethodologyExplainer on MethodologyExplainerCalculationTrace {
  __typename
  id
  fingerprint
  sumOfFunctionalUnit
  sumOfConvertedActivity
  sumOfMeasure
  inputUnit
  convertedUnit
  quantitySpecifier
  emissionsModelVersionId
  emissionsModelEvaluationId
  footprintExclusionRule {
    ...FootprintExclusionRuleForMethodologyExplainer
  }
}
    ${FootprintExclusionRuleForMethodologyExplainerFragmentDoc}`;
export const DisclosureForMethodologyExplainerFragmentDoc = gql`
    fragment DisclosureForMethodologyExplainer on Disclosure {
  id
  qualityScore
  historicalEmissionsYears {
    id
    reportingYear
  }
  privateDisclosure {
    id
    privateDisclosureType
  }
  publicDisclosure {
    id
    reportType
    publicUrl
  }
  climateCommitments {
    id
    externalUrl
    kind
    ... on NetZeroCommitment {
      targetYear
    }
    ... on CarbonNeutralCommitment {
      targetYear
    }
    ... on ScienceBasedTargetCommitment {
      commitment {
        id
        stage
        targetClassification
      }
    }
  }
}
    `;
export const CompanyForMethodologyExplainerFragmentDoc = gql`
    fragment CompanyForMethodologyExplainer on Company {
  id
  name
  description
  url
  fullLogoUrl
  climateProgress
  disclosuresV2(opts: {historicalYearFilters: {}, climateCommitmentFilters: {}}) {
    ...DisclosureForMethodologyExplainer
  }
}
    ${DisclosureForMethodologyExplainerFragmentDoc}`;
export const BiBaseFieldMetaFragmentFragmentDoc = gql`
    fragment BiBaseFieldMetaFragment on BiBaseFieldMeta {
  __typename
  fieldId
  displayName
  fieldFamily
  description
  hiddenInFilters
  hiddenInGroupBy
  emptyStringType
  isMultiValue
  isCustomField
}
    `;
export const BiStringFieldMetaFragmentFragmentDoc = gql`
    fragment BiStringFieldMetaFragment on BiFieldStringMeta {
  shouldSentenceCaseValues
  shouldNullifyPseudoNulls
  hrefTemplate
  knownDisplayValueMappingType
  staticListOptions {
    value
    label
  }
}
    `;
export const BiNumberFieldMetaFragmentFragmentDoc = gql`
    fragment BiNumberFieldMetaFragment on BiFieldNumberMeta {
  precision
  disableCommas
  includeUnit
  shouldFormatToPercent
  unit
  unitDimension
  unitUserFacing
  dependentDimensions
  aggregatedUnitDimension
  aggregateType
  divideBy
  isCurrency
}
    `;
export const BiDateFieldMetaFragmentFragmentDoc = gql`
    fragment BiDateFieldMetaFragment on BiFieldDateMeta {
  format {
    day
    month
    year
  }
}
    `;
export const BiYearMonthFieldMetaFragmentFragmentDoc = gql`
    fragment BiYearMonthFieldMetaFragment on BiFieldYearMonthMeta {
  format {
    day
    month
    year
  }
}
    `;
export const BiQueryMetadataFieldsFragmentDoc = gql`
    fragment BiQueryMetadataFields on BiQueryMetadataResult {
  id
  dimensions {
    fieldMeta {
      ...BiBaseFieldMetaFragment
      ...BiStringFieldMetaFragment
      ...BiNumberFieldMetaFragment
      ...BiDateFieldMetaFragment
      ...BiYearMonthFieldMetaFragment
    }
  }
  measures {
    supportedDimensions
    fieldMeta {
      ...BiBaseFieldMetaFragment
      ...BiNumberFieldMetaFragment
    }
  }
  timeInterval
}
    ${BiBaseFieldMetaFragmentFragmentDoc}
${BiStringFieldMetaFragmentFragmentDoc}
${BiNumberFieldMetaFragmentFragmentDoc}
${BiDateFieldMetaFragmentFragmentDoc}
${BiYearMonthFieldMetaFragmentFragmentDoc}`;
export const BiQueryDataResultFieldsFragmentDoc = gql`
    fragment BiQueryDataResultFields on BiQueryDataResult {
  id
  data
  displayValueMappings {
    dimension
    listOptionItems {
      value
      label
    }
  }
  warnings {
    learnMoreLink
    warningMessage
    warningType
  }
}
    `;
export const BiQueryFilterFieldsFragmentDoc = gql`
    fragment BiQueryFilterFields on BiQueryFilter {
  dimension
  operator
  value
}
    `;
export const BiNormalizedMeasureFieldsFragmentDoc = gql`
    fragment BiNormalizedMeasureFields on BiDynamicNormalizingMeasure {
  baseMeasure
  normalizingQuery {
    measure
    computedDimensionType
    computedFiltersType
    filters {
      ...BiQueryFilterFields
    }
    dimensions
  }
}
    ${BiQueryFilterFieldsFragmentDoc}`;
export const BiSavedViewFragmentDoc = gql`
    fragment BiSavedView on BiSavedView {
  id
  stableId
  name
  dimensions
  chartKind
  editVisibility
  viewVisibility
  updatedAt
  createdAt
  owner {
    id
    name
    displayName
  }
  order {
    dimensionOrMeasure
    direction
  }
  filters {
    ...BiQueryFilterFields
  }
  normalizedMeasures {
    ...BiNormalizedMeasureFields
  }
  timeIntervals
  customMetrics {
    id
    stableId
  }
  searchTerm
  queryContext {
    ... on BiSavedViewSnapshotQueryContext {
      footprintKind
    }
  }
  mode
  metricOrder
}
    ${BiQueryFilterFieldsFragmentDoc}
${BiNormalizedMeasureFieldsFragmentDoc}`;
export const FootprintKindMetaForDrilldownFragmentDoc = gql`
    fragment FootprintKindMetaForDrilldown on SnapshotFootprintKindMeta {
  id
  footprintKind
  name
  description
  footprintInterval
}
    `;
export const LinkedReportFieldsFragmentDoc = gql`
    fragment LinkedReportFields on Report {
  id
  name
  createdAt
  reportKind
  config {
    id
    reportType
  }
}
    `;
export const MeasurementTimelineForMeasurementPageFragmentDoc = gql`
    fragment MeasurementTimelineForMeasurementPage on MeasurementTimeline {
  id
  kickoff
  deadline
}
    `;
export const LinkedMeasurementFieldsFragmentDoc = gql`
    fragment LinkedMeasurementFields on MeasurementProject {
  id
  name
  setupAt
  completedAt
  measurementTimeline {
    ...MeasurementTimelineForMeasurementPage
  }
}
    ${MeasurementTimelineForMeasurementPageFragmentDoc}`;
export const ClimateProgramTimelineProjectFieldsFragmentDoc = gql`
    fragment ClimateProgramTimelineProjectFields on ClimateProgramTimelineProject {
  __typename
  id
  name
  description
  kind
  startDate
  endDate
  plannedYear
  completedAt
  iconType
  object {
    __typename
    id
    ... on Report {
      ...LinkedReportFields
    }
    ... on MeasurementProject {
      ...LinkedMeasurementFields
    }
  }
  objectType
  canonicalClimateProgramProject {
    id
    name
    routeType
  }
}
    ${LinkedReportFieldsFragmentDoc}
${LinkedMeasurementFieldsFragmentDoc}`;
export const ClimateProgramProjectFieldsFragmentDoc = gql`
    fragment ClimateProgramProjectFields on ClimateProgramProject {
  __typename
  id
  name
  description
  kind
  startDate
  endDate
  plannedYear
  completedAt
  status
  iconType
  measurementProject {
    id
    name
  }
  canonicalClimateProgramProject {
    id
    routeType
    name
  }
}
    `;
export const CanonicalClimateProgramProjectMeasurementCopyFragmentDoc = gql`
    fragment CanonicalClimateProgramProjectMeasurementCopy on CanonicalClimateProgramProject {
  __typename
  id
  kind
  measurementObjectCopy
  routeType
  name
}
    `;
export const ClimateProgramProjectMeasurementCopyFragmentDoc = gql`
    fragment ClimateProgramProjectMeasurementCopy on ClimateProgramProject {
  __typename
  id
  canonicalClimateProgramProject {
    ...CanonicalClimateProgramProjectMeasurementCopy
  }
}
    ${CanonicalClimateProgramProjectMeasurementCopyFragmentDoc}`;
export const ClimateProgramProjectRequirementDatasetFieldsFragmentDoc = gql`
    fragment ClimateProgramProjectRequirementDatasetFields on ClimateProgramProjectRequirementDataset {
  id
  dataset {
    id
    name
    instructions {
      instructionsIntroMd
    }
    canonicalDataset {
      id
      kind
      instructions {
        sdiInstructionsIntroMd
      }
    }
  }
  additionalNotesMd
  canonicalGhgpRequirement {
    id
    ghgScope
    ghgScope3Category
    minimumFidelity
  }
}
    `;
export const ClimateProgramProjectMeasurementConcernsFieldsFragmentDoc = gql`
    fragment ClimateProgramProjectMeasurementConcernsFields on ClimateProgramProject {
  ...ClimateProgramProjectMeasurementCopy
  datasetRequirements {
    ...ClimateProgramProjectRequirementDatasetFields
  }
}
    ${ClimateProgramProjectMeasurementCopyFragmentDoc}
${ClimateProgramProjectRequirementDatasetFieldsFragmentDoc}`;
export const ClimateProgramProjectWithMeasurementConcernsFieldsFragmentDoc = gql`
    fragment ClimateProgramProjectWithMeasurementConcernsFields on ClimateProgramProject {
  __typename
  ...ClimateProgramProjectFields
  ...ClimateProgramProjectMeasurementConcernsFields
}
    ${ClimateProgramProjectFieldsFragmentDoc}
${ClimateProgramProjectMeasurementConcernsFieldsFragmentDoc}`;
export const CanonicalClimateProgramProjectForAddProjectFragmentDoc = gql`
    fragment CanonicalClimateProgramProjectForAddProject on CanonicalClimateProgramProject {
  id
  name
  description
  costEstimate
  timeEstimate
  iconType
  hasBenchmarkMapping
  matchObjectType
  reportKind
  routeType
  peerBenchmarkCompanies {
    id
    name
    fullLogoUrl
  }
  kind
}
    `;
export const CanonicalClimateProgramProjectWithMeasurementConcernsFieldsFragmentDoc = gql`
    fragment CanonicalClimateProgramProjectWithMeasurementConcernsFields on CanonicalClimateProgramProject {
  ...CanonicalClimateProgramProjectMeasurementCopy
  ...CanonicalClimateProgramProjectForAddProject
}
    ${CanonicalClimateProgramProjectMeasurementCopyFragmentDoc}
${CanonicalClimateProgramProjectForAddProjectFragmentDoc}`;
export const ClimateProgramCalendarEventFieldsFragmentDoc = gql`
    fragment ClimateProgramCalendarEventFields on ClimateProgramCalendarEvent {
  __typename
  id
  name
  startDate
  endDate
}
    `;
export const InstructionsStepForImportInstructionsFragmentDoc = gql`
    fragment InstructionsStepForImportInstructions on InstructionsStep {
  descriptionMd
  publicUrl
}
    `;
export const SdiInstructionsStepForImportInstructionsFragmentDoc = gql`
    fragment SDIInstructionsStepForImportInstructions on SdiInstructionsStep {
  title
  descriptionMd
}
    `;
export const SdiInstructionsFaqForImportInstructionsFragmentDoc = gql`
    fragment SDIInstructionsFaqForImportInstructions on SdiInstructionsFaqType {
  question
  answerMd
}
    `;
export const InstructionsBundleForImportInstructionsFragmentDoc = gql`
    fragment InstructionsBundleForImportInstructions on InstructionsBundle {
  instructionsIntroMd
  instructionsDataFormattingMd
  sdiInstructionsIntroMd
  sdiInstructionsDataFormattingMd
  ghgScopes
  instructionsSteps {
    ...InstructionsStepForImportInstructions
  }
  sdiInstructionsSteps {
    ...SDIInstructionsStepForImportInstructions
  }
  sdiInstructionsFaq {
    ...SDIInstructionsFaqForImportInstructions
  }
}
    ${InstructionsStepForImportInstructionsFragmentDoc}
${SdiInstructionsStepForImportInstructionsFragmentDoc}
${SdiInstructionsFaqForImportInstructionsFragmentDoc}`;
export const CustomerTargetSchemaVersionForDataFormatFragmentDoc = gql`
    fragment CustomerTargetSchemaVersionForDataFormat on CustomerTargetSchemaVersion {
  id
  formattingNotesMd
  schema {
    id
    name
    utilityType
    rank
  }
  orgDatasetExampleData {
    ...IngestionExampleDataFields
  }
  transforms {
    id
    transformTarget
    businessActivityType {
      id
      name
      versionId
    }
  }
}
    ${IngestionExampleDataFieldsFragmentDoc}`;
export const CustomerTargetSchemaForDataFormatFragmentDoc = gql`
    fragment CustomerTargetSchemaForDataFormat on CustomerTargetSchema {
  id
  name
  utilityType
  rank
  latestPublishedVersion {
    ...CustomerTargetSchemaVersionForDataFormat
  }
}
    ${CustomerTargetSchemaVersionForDataFormatFragmentDoc}`;
export const CanonicalDatasetFieldsForImportFragmentDoc = gql`
    fragment CanonicalDatasetFieldsForImport on CanonicalDataset {
  id
  name
  kind
  instructions {
    ...InstructionsBundleForImportInstructions
  }
  customerTargetSchemas(measurementProjectId: $measurementProjectId) {
    ...CustomerTargetSchemaForDataFormat
  }
}
    ${InstructionsBundleForImportInstructionsFragmentDoc}
${CustomerTargetSchemaForDataFormatFragmentDoc}`;
export const UutSchemaForIngestionFragmentDoc = gql`
    fragment UutSchemaForIngestion on UserUploadedTableSchemaColumn {
  index
  kind
  name
  alias
}
    `;
export const UserReviewMetadataForIngestionFragmentDoc = gql`
    fragment UserReviewMetadataForIngestion on UserUploadedTableUserReviewMetadata {
  headerRowIndex
}
    `;
export const UserUploadedTableFieldsWithoutDataPreviewFragmentDoc = gql`
    fragment UserUploadedTableFieldsWithoutDataPreview on UserUploadedTable {
  id
  sheetName
  sheetIndex
  errorMessage
  errorType
  numRows
  schema {
    ...UutSchemaForIngestion
  }
  uirSchema {
    ...UutSchemaForIngestion
  }
  parseConfig {
    noHeader
    skipTopNRows
    skipBottomNRows
    meltIdVars
  }
  roboCleanerResult {
    skipTopNRows
  }
  userReviewMetadata {
    ...UserReviewMetadataForIngestion
  }
  status
  parentFileMetadata {
    id
    name
  }
}
    ${UutSchemaForIngestionFragmentDoc}
${UserReviewMetadataForIngestionFragmentDoc}`;
export const UserUploadedTableWithOnlyDataPreviewFieldsFragmentDoc = gql`
    fragment UserUploadedTableWithOnlyDataPreviewFields on UserUploadedTable {
  status
  dataPreview {
    dataUntyped
  }
  rawDataPreview {
    dataUntyped
  }
  rawDataUnshiftedPreview {
    dataUntyped
  }
}
    `;
export const UserUploadedTableForDatasetPageFragmentDoc = gql`
    fragment UserUploadedTableForDatasetPage on UserUploadedTable {
  ...UserUploadedTableFieldsWithoutDataPreview
  ...UserUploadedTableWithOnlyDataPreviewFields
  extBartId
  businessActivityType
}
    ${UserUploadedTableFieldsWithoutDataPreviewFragmentDoc}
${UserUploadedTableWithOnlyDataPreviewFieldsFragmentDoc}`;
export const UserUploadAttemptFieldsForDatasetPageFragmentDoc = gql`
    fragment UserUploadAttemptFieldsForDatasetPage on UserUploadAttempt {
  id
  name
  createdAt
  size
  uploader {
    id
    name
  }
  description
  userUploadedTables {
    ...UserUploadedTableForDatasetPage
  }
  latestUutsIfRevisionExists {
    ...UserUploadedTableForDatasetPage
  }
  remoteWritten
}
    ${UserUploadedTableForDatasetPageFragmentDoc}`;
export const UserUploadForDatasetPageFragmentDoc = gql`
    fragment UserUploadForDatasetPage on UserUpload {
  id
  name
  category
  createdAt
  measurementProject {
    id
    name
    deletedAt
  }
  processingMode
  status
  uploader {
    id
    name
  }
  userVisibleAttempt {
    ...UserUploadAttemptFieldsForDatasetPage
  }
  revisionRoot
  userUploadTask {
    id
    datasource {
      id
      name
      dataset {
        id
        canonicalDataset {
          id
          kind
        }
      }
    }
  }
}
    ${UserUploadAttemptFieldsForDatasetPageFragmentDoc}`;
export const DatasetForSpecificDatasetPageFragmentDoc = gql`
    fragment DatasetForSpecificDatasetPage on Dataset {
  id
  name
  explainer
  usedFor
  potentialOverlaps
  canonicalDataset {
    ...CanonicalDatasetFieldsForImport
  }
  datasources {
    id
    name
    userUploadTasks {
      edges {
        node {
          id
          measurementProject {
            id
            name
          }
          measurementTask {
            id
            statusSimplified
            title
          }
          assignees {
            id
            name
            displayName
            didLatestEmailBounce
          }
          userUploads {
            edges {
              node {
                ...UserUploadForDatasetPage
              }
            }
          }
        }
      }
    }
  }
}
    ${CanonicalDatasetFieldsForImportFragmentDoc}
${UserUploadForDatasetPageFragmentDoc}`;
export const EnergyBreakdownFieldsFragmentDoc = gql`
    fragment EnergyBreakdownFields on EnergyConsumptionBreakdown {
  fields
  dataPoints {
    fieldValues {
      field
      value
    }
    marketTco2e
    locationTco2e
    mwhConsumed
    avoidedMarketTco2e
  }
  totalFootprintTco2e
  totalElectricityTco2e
  totalCleanMwh
  totalMwh
  totalFootprintResults {
    footprintKind
    totalTco2e
  }
}
    `;
export const EacPriceEstimateFieldsForDashboardFragmentDoc = gql`
    fragment EacPriceEstimateFieldsForDashboard on EacPriceEstimate {
  id
  country
  alternativeCountries
  minCreditPurchase
  priceEstimateUsdCents
}
    `;
export const CompanyWithClimateCommitmentsFragmentDoc = gql`
    fragment CompanyWithClimateCommitments on Company {
  id
  name
  fullLogoUrl
  sustainabilityWebsiteUrl
  disclosuresV2(opts: {climateCommitmentFilters: {}}) {
    id
    publicDisclosure {
      id
      publishingYear
      publicUrl
    }
    climateCommitments {
      id
      kind
      description
      externalUrl
      commitmentMadeDate
    }
  }
}
    `;
export const TaskStatusDumpAllFieldsFragmentDoc = gql`
    fragment TaskStatusDumpAllFields on TaskStatusDump {
  taskId
  taskName
  assigneeNames
  datasetName
  status
}
    `;
export const MeasurementProjectFieldsForShareStatusEngagementTaskFragmentDoc = gql`
    fragment MeasurementProjectFieldsForShareStatusEngagementTask on MeasurementProject {
  id
  name
  coverageStartDate
  coverageEndDate
  taskStatusDumps {
    ...TaskStatusDumpAllFields
  }
}
    ${TaskStatusDumpAllFieldsFragmentDoc}`;
export const AssetYearAllFieldsFragmentDoc = gql`
    fragment AssetYearAllFields on FinanceAssetYearNew {
  __typename
  id
  orgId
  createdAt
  updatedAt
  year
  assetCommercialRealEstateId
  assetCorporateId
  assetGroupId
  assetSovereignBondId
  assetPersonalMotorVehicleInsuranceId
  buildingSizeNative
  estimationMethodology
  headcount
  percentRenewable
  cloudSpendNative
  valueNative
  revenueNative
  flightMiles
  percentEmployeesWfh
  currencyCode
  currencyConversionRate
  totalMwh
  revenueSourceEmissionsYearId
  emissionsYearId
  scope1OverrideTco2e
  scope1OverridePcaf
  scope2LocationOverrideTco2e
  scope2LocationOverridePcaf
  scope2MarketOverrideTco2e
  scope2MarketOverridePcaf
  scope3OverrideKind
  scope3OverrideTco2e
  scope3OverridePcaf
  scope301OverrideTco2e
  scope302OverrideTco2e
  scope303OverrideTco2e
  scope304OverrideTco2e
  scope305OverrideTco2e
  scope306OverrideTco2e
  scope307OverrideTco2e
  scope308OverrideTco2e
  scope309OverrideTco2e
  scope310OverrideTco2e
  scope311OverrideTco2e
  scope312OverrideTco2e
  scope313OverrideTco2e
  scope314OverrideTco2e
  scope315OverrideTco2e
  buildingEstimationErr
  companyEstimationErr
  measuringScope1And2Emissions
  measuringMaterialScope3Emissions
  initiatedDecarbonizationPlan
  committedToNearTermSbtAlignedNetZero
  demonstratingYoyEmissionsInLineWithNetZero
  iciAlignmentOverride
  iciAlignmentStatus
  scenario
  tagData
}
    `;
export const GhgCategorizedEmissionDataFieldsFragmentDoc = gql`
    fragment GhgCategorizedEmissionDataFields on GhgCategorizedEmissionData {
  ghgCategoryId
  businessSubcategory
  proportion
  amountKgco2e
}
    `;
export const EmissionsYearWithGhgCategoryFragmentDoc = gql`
    fragment EmissionsYearWithGhgCategory on EmissionsYear {
  id
  createdAt
  publicDisclosureId
  privateDisclosureId
  footprintEstimateOutputId
  buildingEstimateOutputId
  emissionsYearSource
  assetCorporateId
  expenseCategory
  reportingYear
  numEmployees
  revenue
  revenueCurrency
  currencyConversionRate
  scope1
  scope2
  scope2Market
  scope2Location
  scope3
  scope301
  scope302
  scope303
  scope304
  scope305
  scope306
  scope307
  scope308
  scope309
  scope310
  scope311
  scope312
  scope313
  scope314
  scope315
  scope316
  scope317
  units
  percentageCleanEnergy
  totalMwh
  orgId
  scenario
  updatedAt
  emissionsByGhgCategory {
    topGhgCategories {
      ...GhgCategorizedEmissionDataFields
    }
  }
}
    ${GhgCategorizedEmissionDataFieldsFragmentDoc}`;
export const AssetForAssetPageFragmentDoc = gql`
    fragment AssetForAssetPage on IFinanceAsset {
  __typename
  id
  name
  currencyCode
  footprintSourceOptions(year: $year) {
    id
    label
    source
  }
  assetYears(year: $year) {
    ...AssetYearAllFields
    emissionsYear {
      ...EmissionsYearWithGhgCategory
    }
  }
  ... on AssetCorporate {
    assetGroupId
    companyId
  }
  ... on AssetRealEstate {
    assetGroupId
    companyId
  }
}
    ${AssetYearAllFieldsFragmentDoc}
${EmissionsYearWithGhgCategoryFragmentDoc}`;
export const FinanceChangelogLineItemFragmentDoc = gql`
    fragment FinanceChangelogLineItem on FinanceChangelogLineItem {
  id
  field
  oldValue
  newValue
}
    `;
export const FinanceChangelogEntryFragmentDoc = gql`
    fragment FinanceChangelogEntry on FinanceChangelogEntry {
  id
  createdAt
  eventKind
  userDisplayName
  objectLabel
  assetId
  fundId
  lineItems {
    id
    ...FinanceChangelogLineItem
  }
}
    ${FinanceChangelogLineItemFragmentDoc}`;
export const AssetHoldingAllFieldsFragmentDoc = gql`
    fragment AssetHoldingAllFields on FinanceAssetHolding {
  __typename
  id
  orgId
  createdAt
  updatedAt
  assetYearId
  fundId
  yearOfInitialInvestment
  attributionFactorOverride
  outstandingAmountNative
  outstandingAmountNativeQ1
  outstandingAmountNativeQ2
  outstandingAmountNativeQ3
  outstandingAmountNativeQ4
  currencyCode
  currencyConversionRate
  assetClass
  currencyCode
  currencyConversionRate
  tagData
  premiumNative
  emissionsYearId
}
    `;
export const AssetCorporateAllFieldsFragmentDoc = gql`
    fragment AssetCorporateAllFields on AssetCorporate {
  __typename
  id
  orgId
  createdAt
  updatedAt
  name
  naicsCode
  companyIsin
  companyLei
  companyUrl
  companyTicker
  companySAndPId
  companyDunsNumber
  companyEin
  companyVatId
  companyLocalRegistryId
  currencyCode
  countryAlpha2
  buildingSizeUnit
  companyId
  orgId
  otherData
  tagData
  externalId
  assetGroupId
  anonymousCompanyUuid
}
    `;
export const AssetRealEstateAllFieldsFragmentDoc = gql`
    fragment AssetRealEstateAllFields on AssetRealEstate {
  __typename
  id
  orgId
  createdAt
  updatedAt
  buildingKind
  name
  streetAddress
  streetAddress2
  city
  state
  countryAlpha2
  postalCode
  currencyCode
  buildingSizeUnit
  otherData
  tagData
  externalId
  assetGroupId
  sizeUnit
  country
  buildingName
}
    `;
export const AssetGroupAllFieldsFragmentDoc = gql`
    fragment AssetGroupAllFields on AssetGroup {
  __typename
  id
  orgId
  createdAt
  updatedAt
  name
  currencyCode
  otherData
  tagData
  externalId
  orgId
}
    `;
export const AssetGroupAssetAllFieldsFragmentDoc = gql`
    fragment AssetGroupAssetAllFields on AssetGroupAsset {
  __typename
  ... on AssetCorporate {
    ...AssetCorporateAllFields
  }
  ... on AssetRealEstate {
    ...AssetRealEstateAllFields
  }
  ... on AssetGroup {
    ...AssetGroupAllFields
  }
}
    ${AssetCorporateAllFieldsFragmentDoc}
${AssetRealEstateAllFieldsFragmentDoc}
${AssetGroupAllFieldsFragmentDoc}`;
export const AssetSovereignBondAllFieldsFragmentDoc = gql`
    fragment AssetSovereignBondAllFields on AssetSovereignBond {
  __typename
  id
  orgId
  createdAt
  updatedAt
  name
  sovereignEntity
  currencyCode
  tagData
}
    `;
export const AssetPersonalMotorVehicleInsuranceFieldsFragmentDoc = gql`
    fragment AssetPersonalMotorVehicleInsuranceFields on AssetPersonalMotorVehicleInsurance {
  __typename
  id
  orgId
  createdAt
  updatedAt
  name
  currencyCode
  tagData
}
    `;
export const AssetAllFieldsFragmentDoc = gql`
    fragment AssetAllFields on IFinanceAsset {
  __typename
  ... on AssetCorporate {
    ...AssetCorporateAllFields
  }
  ... on AssetRealEstate {
    ...AssetRealEstateAllFields
  }
  ... on AssetGroup {
    ...AssetGroupAllFields
  }
  ... on AssetSovereignBond {
    ...AssetSovereignBondAllFields
  }
  ... on AssetPersonalMotorVehicleInsurance {
    ...AssetPersonalMotorVehicleInsuranceFields
  }
}
    ${AssetCorporateAllFieldsFragmentDoc}
${AssetRealEstateAllFieldsFragmentDoc}
${AssetGroupAllFieldsFragmentDoc}
${AssetSovereignBondAllFieldsFragmentDoc}
${AssetPersonalMotorVehicleInsuranceFieldsFragmentDoc}`;
export const FinanceTagForChartSelectorFragmentDoc = gql`
    fragment FinanceTagForChartSelector on FinanceTag {
  id
  name
  columnFormat
}
    `;
export const FinanceAggregateMetricMoreFragmentDoc = gql`
    fragment FinanceAggregateMetricMore on FinanceAggregateMetric {
  __typename
  metric
  name
  description
  quantity
  unit
  additionalInfo
  isMissingInputs
}
    `;
export const FinanceStringMetricMoreFragmentDoc = gql`
    fragment FinanceStringMetricMore on FinanceStringMetric {
  __typename
  metric
  name
  description
  value
  unit
  additionalInfo
  isMissingInputs
}
    `;
export const FinanceAggregateEmptyMetricFragmentDoc = gql`
    fragment FinanceAggregateEmptyMetric on FinanceAggregateMetric {
  __typename
  metric
  name
  description
}
    `;
export const FinanceStringEmptyMetricFragmentDoc = gql`
    fragment FinanceStringEmptyMetric on FinanceStringMetric {
  __typename
  metric
  name
  description
}
    `;
export const FinanceMetricBarMinimalFragmentDoc = gql`
    fragment FinanceMetricBarMinimal on FinanceMetricBar {
  id
  metrics {
    ... on FinanceAggregateMetric {
      ...FinanceAggregateMetricMore
    }
    ... on FinanceStringMetric {
      ...FinanceStringMetricMore
    }
  }
  emptyMetrics {
    ... on FinanceAggregateMetric {
      ...FinanceAggregateEmptyMetric
    }
    ... on FinanceStringMetric {
      ...FinanceStringEmptyMetric
    }
  }
}
    ${FinanceAggregateMetricMoreFragmentDoc}
${FinanceStringMetricMoreFragmentDoc}
${FinanceAggregateEmptyMetricFragmentDoc}
${FinanceStringEmptyMetricFragmentDoc}`;
export const FinanceChartAllFieldsFragmentDoc = gql`
    fragment FinanceChartAllFields on FinanceChart {
  id
  title
  subtitle
  chartKind
  tooltipText
  data
  isWideFormat
  activeObjectType
  highchartsKind
  highchartsData
  highchartsUnit
  metric
}
    `;
export const SupplierContactFieldsFragmentDoc = gql`
    fragment SupplierContactFields on SupplierContact {
  id
  name
  email
  role
  userId
  companyId
}
    `;
export const PortcoFootprintReviewRequestMetadataAllFieldsFragmentDoc = gql`
    fragment PortcoFootprintReviewRequestMetadataAllFields on PortcoFootprintReviewRequestMetadata {
  id
  status
}
    `;
export const FinanceMeasurementProgressRowAllFieldsFragmentDoc = gql`
    fragment FinanceMeasurementProgressRowAllFields on FinanceMeasurementProgressRow {
  assetId
  assetName
  companyId
  projectName
  hasRequestedProgress
  hasRequestedFootprint
  coverageStartDate
  coverageEndDate
  contacts {
    ...SupplierContactFields
  }
  taskStatusDumps {
    ...TaskStatusDumpAllFields
  }
  portcoFootprintReviewRequestMetadata {
    ...PortcoFootprintReviewRequestMetadataAllFields
  }
  updatedAt
  measuredFootprintDetails {
    footprintSharedAt
    emissionsTco2e
  }
  fundIds
  hasApprovedProgressRequest
}
    ${SupplierContactFieldsFragmentDoc}
${TaskStatusDumpAllFieldsFragmentDoc}
${PortcoFootprintReviewRequestMetadataAllFieldsFragmentDoc}`;
export const FinanceAutomaticReminderAllFieldsFragmentDoc = gql`
    fragment FinanceAutomaticReminderAllFields on FinanceAutomaticReminder {
  id
  enabled
  reminderConfig
  reminderType
  deadline
}
    `;
export const CompanyFieldsForCcisFragmentDoc = gql`
    fragment CompanyFieldsForCCIS on Company {
  id
  naicsCode
  sbtiStage
  climateProgress
  sustainabilityWebsiteUrl
  isWatershedCorporateCustomer
}
    `;
export const CdpVendorDataFieldsFragmentDoc = gql`
    fragment CdpVendorDataFields on CdpVendorData {
  id
  publishingYear
  reportingYear
  scope1Nonzero
  scope2Nonzero
  scope3Nonzero
  totalEmissionsNonzero
  scope301Or302Nonzero
  scope1Verified
  scope2Verified
  scope3Verified
  pctEvaluationStatusesMatchResponse
  disclosureQualityScore
  scope301EvaluationStatus
  scope302EvaluationStatus
  scope303EvaluationStatus
  scope304EvaluationStatus
  scope305EvaluationStatus
  scope306EvaluationStatus
  scope307EvaluationStatus
  scope308EvaluationStatus
  scope309EvaluationStatus
  scope310EvaluationStatus
  scope311EvaluationStatus
  scope312EvaluationStatus
  scope313EvaluationStatus
  scope314EvaluationStatus
  scope315EvaluationStatus
  scope316EvaluationStatus
  scope317EvaluationStatus
}
    `;
export const CompanyPublicDisclosureFieldsFragmentDoc = gql`
    fragment CompanyPublicDisclosureFields on PublicDisclosure {
  id
  publishingYear
  reportType
  publicUrl
  qualityScore
  cdpVendorData {
    ...CdpVendorDataFields
  }
}
    ${CdpVendorDataFieldsFragmentDoc}`;
export const SupplierPrivateDisclosureFieldsFragmentDoc = gql`
    fragment SupplierPrivateDisclosureFields on PrivateDisclosure {
  id
  companyId
  orgId
  orgName
  surveyId
  privateDisclosureType
  createdAt
  disclosureQualityScore
  disclosureQualityExplanation
  thirdPartyVerified
  cdpVendorData {
    ...CdpVendorDataFields
  }
}
    ${CdpVendorDataFieldsFragmentDoc}`;
export const EmissionsYearScope1FieldsFragmentDoc = gql`
    fragment EmissionsYearScope1Fields on EmissionsYear {
  scope1
}
    `;
export const EmissionsYearScope2FieldsFragmentDoc = gql`
    fragment EmissionsYearScope2Fields on EmissionsYear {
  scope2Market
  scope2Location
}
    `;
export const EmissionsYearScope3FieldsFragmentDoc = gql`
    fragment EmissionsYearScope3Fields on EmissionsYear {
  scope3
  scope301
  scope302
  scope303
  scope304
  scope305
  scope306
  scope307
  scope308
  scope309
  scope310
  scope311
  scope312
  scope313
  scope314
  scope315
  scope316
  scope317
}
    `;
export const SupplierDisclosureHistoricalEmissionsYearFieldsFragmentDoc = gql`
    fragment SupplierDisclosureHistoricalEmissionsYearFields on EmissionsYear {
  id
  publicDisclosureId
  reportingYear
  numEmployees
  revenue
  revenueCurrency
  revenueUsd
  percentageCleanEnergy
  totalMwh
  units
  expenseCategory
  ...EmissionsYearScope1Fields
  ...EmissionsYearScope2Fields
  ...EmissionsYearScope3Fields
}
    ${EmissionsYearScope1FieldsFragmentDoc}
${EmissionsYearScope2FieldsFragmentDoc}
${EmissionsYearScope3FieldsFragmentDoc}`;
export const SimpleTimeseriesForDisclosuresFragmentDoc = gql`
    fragment SimpleTimeseriesForDisclosures on SimpleTimeseries {
  id
  base
  frequency
  values
}
    `;
export const SupplierDisclosureTargetFieldsFragmentDoc = gql`
    fragment SupplierDisclosureTargetFields on DisclosureTargetDetails {
  id
  name
  description
  baseYear
  filters {
    conjunction
    expressions {
      field
      operator
      value
    }
  }
  emissionsTarget {
    ...SimpleTimeseriesForDisclosures
  }
  emissionsType
  reductionRate
  intensityType
  unit
  unitDescription
  companyClimateCommitmentId
}
    ${SimpleTimeseriesForDisclosuresFragmentDoc}`;
export const SupplierDisclosureInitiativeFieldsFragmentDoc = gql`
    fragment SupplierDisclosureInitiativeFields on DisclosureInitiative {
  id
  name
  initiativeType
  description
  startYearMonth
  endYearMonth
  emissionsTimeseries {
    ...SimpleTimeseriesForDisclosures
  }
  filters {
    conjunction
    expressions {
      field
      operator
      value
    }
  }
  intensityType
}
    ${SimpleTimeseriesForDisclosuresFragmentDoc}`;
export const SbtCommitmentFieldsFragmentDoc = gql`
    fragment SBTCommitmentFields on SBTCommitment {
  id
  stage
  targetClassification
  nearTermTargetYear
  longTermTargetYear
  baselineYear
  netZeroCommitted
  commitmentType
  commitmentDeadline
  netZeroTargetYear
}
    `;
export const CompanyClimateCommitmentFieldsForCcisFragmentDoc = gql`
    fragment CompanyClimateCommitmentFieldsForCCIS on CompanyClimateCommitment {
  id
  kind
  description
  externalUrl
  commitmentMadeDate
  commitmentPeriodStart
  commitmentPeriodEnd
  ... on NetZeroCommitment {
    targetYear
  }
  ... on CarbonNeutralCommitment {
    targetYear
  }
  ... on ScienceBasedTargetCommitment {
    commitment {
      ...SBTCommitmentFields
    }
    submittedToSBTi
  }
  ... on CleanEnergyCommitment {
    targetYear
    targetPercentageCleanEnergy
  }
}
    ${SbtCommitmentFieldsFragmentDoc}`;
export const SupplierDisclosureFieldsFragmentDoc = gql`
    fragment SupplierDisclosureFields on Disclosure {
  id
  company {
    id
  }
  publicDisclosure {
    ...CompanyPublicDisclosureFields
  }
  privateDisclosure {
    ...SupplierPrivateDisclosureFields
  }
  historicalEmissionsYears {
    ...SupplierDisclosureHistoricalEmissionsYearFields
  }
  targets {
    ...SupplierDisclosureTargetFields
  }
  initiatives {
    ...SupplierDisclosureInitiativeFields
  }
  climateCommitments {
    ...CompanyClimateCommitmentFieldsForCCIS
  }
}
    ${CompanyPublicDisclosureFieldsFragmentDoc}
${SupplierPrivateDisclosureFieldsFragmentDoc}
${SupplierDisclosureHistoricalEmissionsYearFieldsFragmentDoc}
${SupplierDisclosureTargetFieldsFragmentDoc}
${SupplierDisclosureInitiativeFieldsFragmentDoc}
${CompanyClimateCommitmentFieldsForCcisFragmentDoc}`;
export const ParentCompanyWithDisclosuresFieldsFragmentDoc = gql`
    fragment ParentCompanyWithDisclosuresFields on Company {
  id
  name
  disclosuresV2(
    opts: {publicDisclosureFilters: {}, targetFilters: {}, climateCommitmentFilters: {}, initiativeFilters: {}, historicalYearFilters: {allScopes: true}}
  ) {
    ...SupplierDisclosureFields
  }
}
    ${SupplierDisclosureFieldsFragmentDoc}`;
export const ParentRelationshipFieldsFragmentDoc = gql`
    fragment ParentRelationshipFields on ParentRelationship {
  id
  parent {
    ...ParentCompanyWithDisclosuresFields
  }
  relationshipStartDate
  relationshipEndDate
}
    ${ParentCompanyWithDisclosuresFieldsFragmentDoc}`;
export const ParentRelationshipForSidebarFieldsFragmentDoc = gql`
    fragment ParentRelationshipForSidebarFields on ParentRelationship {
  id
  parent {
    id
    name
  }
  relationshipStartDate
  relationshipEndDate
}
    `;
export const EngagementTaskQuestionFieldsFragmentDoc = gql`
    fragment EngagementTaskQuestionFields on EngagementTaskQuestion {
  id
  label
  description
  componentId
  editable
  deletable
  questionKey
  statusJson
  whyCollectThis
  answerIsPrivate
  videoLink
}
    `;
export const EngagementTaskContainerFieldsFragmentDoc = gql`
    fragment EngagementTaskContainerFields on EngagementTaskContainer {
  id
  label
  description
  componentId
  editable
  statusJson
  sidebarStepLabelOverride
  questions {
    ...EngagementTaskQuestionFields
  }
  eyebrowText
  headerImage
}
    ${EngagementTaskQuestionFieldsFragmentDoc}`;
export const EngagementTaskContainerWithSubSectionFieldsFragmentDoc = gql`
    fragment EngagementTaskContainerWithSubSectionFields on EngagementTaskContainer {
  ...EngagementTaskContainerFields
  containers {
    ...EngagementTaskContainerFields
  }
}
    ${EngagementTaskContainerFieldsFragmentDoc}`;
export const EngagementTaskConfigFieldsFragmentDoc = gql`
    fragment EngagementTaskConfigFields on EngagementTaskConfig {
  id
  name
  sidebarTitle
  defaultSupplierFacingDescription
  rootFacingDescription
  orgId
  type
  configHasTaskAssignedToCompany
  containers {
    ...EngagementTaskContainerWithSubSectionFields
  }
  baseConfig {
    id
    containers {
      ...EngagementTaskContainerWithSubSectionFields
    }
  }
  timeNeeded
  category
  engagementTaskInputs
  optionalEngagementTaskInputs
}
    ${EngagementTaskContainerWithSubSectionFieldsFragmentDoc}`;
export const CompanyDisclosureHistoricalEmissionsYearFieldsFragmentDoc = gql`
    fragment CompanyDisclosureHistoricalEmissionsYearFields on EmissionsYear {
  id
  publicDisclosureId
  reportingYear
  numEmployees
  revenue
  revenueCurrency
  revenueUsd
  percentageCleanEnergy
  totalMwh
  ...EmissionsYearScope1Fields
  ...EmissionsYearScope2Fields
  ...EmissionsYearScope3Fields
}
    ${EmissionsYearScope1FieldsFragmentDoc}
${EmissionsYearScope2FieldsFragmentDoc}
${EmissionsYearScope3FieldsFragmentDoc}`;
export const ProposeTargetFieldsFragmentDoc = gql`
    fragment ProposeTargetFields on EngagementTask {
  proposeTargetFilters {
    conjunction
    expressions {
      field
      operator
      value
    }
  }
  proposeTargetEmissionsTarget {
    id
    base
    frequency
    values
  }
  proposeTargetIntensityType
}
    `;
export const ExpenseCategoryFieldsFragmentDoc = gql`
    fragment ExpenseCategoryFields on DiscloseEmissionsExpenseCategories {
  beaCode
  kgco2e
}
    `;
export const EngagementTaskFieldsFragmentDoc = gql`
    fragment EngagementTaskFields on EngagementTask {
  id
  crossOrgModelId
  reportingYear
  facilityName
  submittedAt
  createdAt
  dueDate
  status
  surveyKind
  supplierFacingDescription
  watershedGeneric
  engagementTaskConfig {
    ...EngagementTaskConfigFields
  }
  currentAssignee
  rootAssignee
  supplierAssignee
  initialSupplierAssigneeEmail
  approvedAt
  statusChangedAt
  rootOrgId
  rootCompany {
    id
    name
    fullLogoUrl
    url
  }
  supplierOrgId
  latestCeeWorkflowId
  latestPleeWorkflowId
  latestFootprintDisclosureWorkflowId
  company {
    id
    name
    naicsCode
    sbtiStage
    publicDisclosures: disclosuresV2(
      opts: {publicDisclosureFilters: {excludeCdp: true}, historicalYearFilters: {}}
    ) {
      id
      publicDisclosure {
        id
        publishingYear
      }
      historicalEmissionsYears {
        ...CompanyDisclosureHistoricalEmissionsYearFields
      }
    }
  }
  ...ProposeTargetFields
  expenseCategories {
    ...ExpenseCategoryFields
  }
  engagementTaskBatchId
}
    ${EngagementTaskConfigFieldsFragmentDoc}
${CompanyDisclosureHistoricalEmissionsYearFieldsFragmentDoc}
${ProposeTargetFieldsFragmentDoc}
${ExpenseCategoryFieldsFragmentDoc}`;
export const AssetCorporateDataForTasksFragmentDoc = gql`
    fragment AssetCorporateDataForTasks on AssetCorporate {
  id
  name
  companyId
  company {
    ...CompanyFieldsForCCIS
    parentRelationships(onlyGetActiveRelationships: true) {
      ...ParentRelationshipFields
    }
    parentRelationshipsForSidebar: parentRelationships {
      ...ParentRelationshipForSidebarFields
    }
    contacts {
      ...SupplierContactFields
    }
  }
  engagementTasks {
    ...EngagementTaskFields
  }
}
    ${CompanyFieldsForCcisFragmentDoc}
${ParentRelationshipFieldsFragmentDoc}
${ParentRelationshipForSidebarFieldsFragmentDoc}
${SupplierContactFieldsFragmentDoc}
${EngagementTaskFieldsFragmentDoc}`;
export const CompanyAutocompleteResultFragmentDoc = gql`
    fragment CompanyAutocompleteResult on Company {
  id
  name
  countryAlpha2
  naicsCode
  sAndP {
    id
    sAndPId
  }
  companyWebsite {
    id
    url
  }
  leis {
    id
    lei
    isPrimary
  }
}
    `;
export const AssetCorporateForMatchingFragmentDoc = gql`
    fragment AssetCorporateForMatching on AssetCorporate {
  id
  name
  company {
    ...CompanyAutocompleteResult
    id
    alternateNames
    betterCompanyId
    country
    createdAt
    deletedAt
    description
    isDemo
    logoUrl
    naicsCode
    naicsCodeSource
    name
    sfdcOpportunityId
    sfdcStage
    source
    sustainabilityWebsiteUrl
    updatedAt
    url
    userEditorId
    logoUrlId
    sustainabilityWebsiteUrlId
    companyWebsiteUrlId
    companyIngestionRecordUpdateId
    sourceId
    isHidden
    sAndP {
      id
      sAndPId
    }
  }
}
    ${CompanyAutocompleteResultFragmentDoc}`;
export const AssetAutoMatchDataFragmentDoc = gql`
    fragment AssetAutoMatchData on AssetAutoMatchData {
  id
  ranAutoMapping
  autoMatches {
    id
    confidenceScore
    company {
      ...CompanyAutocompleteResult
      id
      name
      country
      naicsCode
      sAndP {
        id
        sAndPId
      }
    }
  }
  autoMatched {
    confidenceScore
  }
}
    ${CompanyAutocompleteResultFragmentDoc}`;
export const AssetFinancialDataFragmentDoc = gql`
    fragment AssetFinancialData on AssetFinancialData {
  id
  assetValue
  assetValueCurrencyCode
  usedSAndPAssetValue
  revenue
  revenueCurrencyConversionRate
  usedSAndPRevenue
  isPrivate
}
    `;
export const AssetIndustryCodeDataFragmentDoc = gql`
    fragment AssetIndustryCodeData on AssetIndustryCodeData {
  id
  industryCode
  usedSAndPIndustryCode
}
    `;
export const FinanceDataFlagForFlagViewFragmentDoc = gql`
    fragment FinanceDataFlagForFlagView on FinanceDataFlag {
  id
  finSnapAssetId
  finSnapYearId
  finSnapFundId
  finSnapHoldingId
  assetYearId
  rowData
  checkKind
  objectKind
  category
  title
  description
  stepsToResolve
  columns
}
    `;
export const FinanceInputDataFlagForFlagViewFragmentDoc = gql`
    fragment FinanceInputDataFlagForFlagView on FinanceInputDataFlag {
  id
  assetCorporateId
  assetCommercialRealEstateId
  assetGroupId
  assetPersonalMotorVehicleInsuranceId
  assetSovereignBondId
  assetYearId
  assetHoldingId
  fundId
  checkKind
  columns
  rowData
}
    `;
export const FinanceImportsForRenderFragmentDoc = gql`
    fragment financeImportsForRender on FinanceImport {
  id
  createdAt
  errorMessages
  fileMetadata {
    id
    name
    uploader {
      id
      displayName
    }
  }
  dataFormat
  recordsCreated
  recordsUpdated
  accepted
}
    `;
export const FinanceSandboxHistoryFieldsFragmentDoc = gql`
    fragment FinanceSandboxHistoryFields on EmissionsYear {
  id
  createdAt
  scope1
  scope2Location
  scope2Market
  scope3
  footprintEstimateOutputId
  buildingEstimateOutputId
  reportingYear
  revenue
  revenueCurrency
  numEmployees
  priorEstimateInputs
}
    `;
export const FinanceSettingsFieldsFragmentDoc = gql`
    fragment FinanceSettingsFields on FinanceSettings {
  id
  estimateDownstreamScope3
  disabledFinanceDataFlags
}
    `;
export const WaterfallBlockAllFieldsFragmentDoc = gql`
    fragment WaterfallBlockAllFields on WaterfallBlock {
  id
  label
  value
  isIntermediateSum
  isSum
}
    `;
export const FootprintForFootprintDetailFragmentDoc = gql`
    fragment FootprintForFootprintDetail on Footprint {
  id
  name
  locked
  isGenerating
}
    `;
export const FootprintSnapshotForDrilldownFragmentDoc = gql`
    fragment FootprintSnapshotForDrilldown on FootprintSnapshot {
  id
  name
  createdAt
  status
  footprintInterval
  description
  createdBy {
    id
    displayName
  }
  footprint {
    ...FootprintForFootprintDetail
  }
}
    ${FootprintForFootprintDetailFragmentDoc}`;
export const FootprintConfigurationForSnapshotFieldsFragmentDoc = gql`
    fragment FootprintConfigurationForSnapshotFields on FootprintConfiguration {
  id
  methodologyConfig {
    id
    spendMethodology
  }
  marketplaceAllocationInstructions {
    edges {
      node {
        id
        amountKwh
        ghgCategoryId
        purchaseLineItem {
          id
          project {
            id
            name
          }
        }
      }
    }
  }
  measurementProjectDatasets {
    id
    measurementProject {
      id
      name
      createdAt
    }
    datasetsAndDatasetGroups {
      id
      datasetGroupName
      dataset {
        id
        name
        canonicalDataset {
          id
          kind
        }
      }
    }
  }
  carbonRemoval {
    id
    totalTco2e
  }
  releasesByBat {
    batName
    release {
      id
      displayName
      externalNotes
    }
    lifecyclePhase
    currentLifecyclePhase
    currentLifecyclePhaseUpdateDate
  }
}
    `;
export const MarketplaceAllocationInstructionForConfigurationFragmentDoc = gql`
    fragment MarketplaceAllocationInstructionForConfiguration on MarketplaceAllocationInstruction {
  id
  amountKwh
  locationCity
  locationGrid
  locationState
  locationCountry
  buildingName
  vendorEntity
  vendorSubentity
  categoryId
  ghgCategoryId
  footprintKind
  subcategoryId
  interval
  purchaseLineItem {
    id
    project {
      id
      name
    }
  }
}
    `;
export const FootprintFieldsFragmentDoc = gql`
    fragment FootprintFields on Footprint {
  id
  name
  orgId
  locked
  createdAt
  createdBy {
    id
    name
    displayName
  }
  deletedAt
  updatedAt
  defaultFootprintSnapshot {
    id
    footprintInterval
    isLatest
  }
  status
  isGenerating
}
    `;
export const FootprintSnapshotForFootprintSelectorFragmentDoc = gql`
    fragment FootprintSnapshotForFootprintSelector on FootprintSnapshot {
  id
  createdAt
  description
  name
  identifier
  status
  createdBy {
    id
    displayName
  }
  footprintInterval
  footprintConfiguration {
    id
  }
  watershedFootprintReviewRequest {
    id
    user {
      id
      displayName
    }
    status
    createdAt
  }
  reviewStatus
  approvedAt
  approvedBy {
    id
    displayName
  }
  isAutoGenerated
  isUserVisible
}
    `;
export const FootprintFieldsForFootprintSelectorFragmentDoc = gql`
    fragment FootprintFieldsForFootprintSelector on Footprint {
  ...FootprintFields
  latestPublishedFootprintSnapshot {
    id
  }
  footprintSnapshots(includeNonUserVisible: $includeNonUserVisible) {
    ...FootprintSnapshotForFootprintSelector
  }
}
    ${FootprintFieldsFragmentDoc}
${FootprintSnapshotForFootprintSelectorFragmentDoc}`;
export const FootprintUsagesForExclusionRuleStableFragmentDoc = gql`
    fragment FootprintUsagesForExclusionRuleStable on FootprintUsagesForExclusionRuleStable {
  stableId
  footprintsWithSnapshot {
    footprint {
      ...FootprintFieldsForFootprintSelector
    }
    footprintSnapshot {
      id
    }
  }
}
    ${FootprintFieldsForFootprintSelectorFragmentDoc}`;
export const FootprintExclusionRuleForEditorV2FragmentDoc = gql`
    fragment FootprintExclusionRuleForEditorV2 on FootprintExclusionRule {
  id
  filters {
    dimension
    operator
    value
  }
  timeIntervals
  ruleOrder
  stableId
  outputValue
  description
  updatedAt
}
    `;
export const FootprintCategoryRuleForEditorFragmentDoc = gql`
    fragment FootprintCategoryRuleForEditor on FootprintCategoryRule {
  id
  filters {
    dimension
    operator
    value
  }
  outputColumn
  outputValue
  ruleOrder
  stableId
  updatedAt
}
    `;
export const FootprintDetailFragmentDoc = gql`
    fragment FootprintDetail on Footprint {
  id
  name
  isGenerating
  locked
  canEdit
  canApprove
  footprintSnapshots {
    id
  }
  latestFootprintAssemblyRun {
    id
    status
  }
}
    `;
export const FootprintDetailReviewRequestFragmentDoc = gql`
    fragment FootprintDetailReviewRequest on WatershedFootprintReviewRequest {
  id
  createdAt
  updatedAt
  user {
    id
    displayName
  }
  status
}
    `;
export const FootprintDetailSnapshotFragmentDoc = gql`
    fragment FootprintDetailSnapshot on FootprintSnapshot {
  id
  isLatest
  footprintInterval
  status
  name
  createdAt
  description
  watershedFootprintReviewRequest {
    ...FootprintDetailReviewRequest
  }
}
    ${FootprintDetailReviewRequestFragmentDoc}`;
export const TimeseriesFieldsFragmentDoc = gql`
    fragment TimeseriesFields on FootprintAnalysisTimeSeries {
  id
  kind
  series {
    label
    value
    data {
      date
      value
      headcount
      revenue
      customIntensities {
        customIntensityId
        value
      }
    }
  }
}
    `;
export const BenchmarkFieldsForInsightsFragmentDoc = gql`
    fragment BenchmarkFieldsForInsights on Benchmark {
  id
  reportId
  name
  reportingYear
  publishingYear
  kind
  normalizationKind
  amountKgco2e
  kgco2eByScope {
    scope
    kgco2e
  }
  currency
  source
  qualityScore
  scope2Source
}
    `;
export const PeerCompanyGroupFieldsForInsightsFragmentDoc = gql`
    fragment PeerCompanyGroupFieldsForInsights on PeerCompanyGroup {
  id
  name
  footprintKinds
  members {
    id
    company {
      id
      name
    }
    shouldHideFromFootprintOverview
    mostRecentHighQualityBenchmark {
      ...BenchmarkFieldsForInsights
    }
  }
  selfBenchmarks(footprintSnapshotId: $footprintSnapshotId) {
    ...BenchmarkFieldsForInsights
  }
  shouldHideFromFootprintOverview
}
    ${BenchmarkFieldsForInsightsFragmentDoc}`;
export const DrilldownSavedViewFieldsFragmentDoc = gql`
    fragment DrilldownSavedViewFields on DrilldownSavedView {
  id
  name
  description
  isPrivate
  footprintKind
  aggregateKind
  inclusionKind
  intensityDenominatorName
  timeSeriesKind
  hasPieChart
  hasTimeSeriesChart
  hasDataTable
  filterExpressions {
    conjunction
    expressions {
      field
      operator
      value
    }
  }
  groupBy
  searchTerm
  defaultTimeInterval
  biSavedViewId
}
    `;
export const FootprintFieldsForFootprintListFragmentDoc = gql`
    fragment FootprintFieldsForFootprintList on Footprint {
  ...FootprintFields
  latestFootprintAssemblyRun {
    id
    isHidden
    footprintId
    footprintSnapshotId
    status
  }
}
    ${FootprintFieldsFragmentDoc}`;
export const ExtBartRowsDataLineageFragmentDoc = gql`
    fragment ExtBartRowsDataLineage on FileDataLineage {
  batType
  fileMetadataId
  rows
}
    `;
export const UserUploadedTableForSourceFileDataLineageFragmentDoc = gql`
    fragment UserUploadedTableForSourceFileDataLineage on UserUploadedTable {
  id
  sheetName
  numRows
  schema {
    ...UutSchemaForIngestion
  }
  userVisibleAttempt(includeDeleted: true) {
    id
    name
    category
    createdAt
    size
    uploader {
      id
      name
    }
    userUpload {
      id
      revisionRoot
      measurementProject(includeDeleted: true) {
        id
        name
        deletedAt
      }
      userUploadTask {
        id
        datasource {
          id
          name
        }
      }
    }
  }
}
    ${UutSchemaForIngestionFragmentDoc}`;
export const UutRowsDataLineageFragmentDoc = gql`
    fragment UutRowsDataLineage on UutRowsDataLineage {
  fileMetadataId
  rows
  userUploadedTable {
    ...UserUploadedTableForSourceFileDataLineage
  }
}
    ${UserUploadedTableForSourceFileDataLineageFragmentDoc}`;
export const SelectedCalculationDataLineageFragmentDoc = gql`
    fragment SelectedCalculationDataLineage on CalculationDataLineagePayload {
  extBartRows {
    ...ExtBartRowsDataLineage
  }
  fileMetadataIds
  uutRows {
    ...UutRowsDataLineage
  }
  isTruncated
}
    ${ExtBartRowsDataLineageFragmentDoc}
${UutRowsDataLineageFragmentDoc}`;
export const FilesForMethodologyExplainerFragmentDoc = gql`
    fragment FilesForMethodologyExplainer on FootprintAnalysisFile {
  id
  name
  category
  createdAt
  uploader {
    id
    name
  }
  userUploadTask {
    id
    datasource {
      id
      name
    }
  }
}
    `;
export const UnitConversionForMethodologyExplainerFragmentDoc = gql`
    fragment UnitConversionForMethodologyExplainer on UnitConversionRecord {
  assumptionId
  assumptionUuid
  conversionDescription
  conversionMultiplier
  inputUnit
  outputUnit
}
    `;
export const AssumptionSourceForMethodologyExplainerFragmentDoc = gql`
    fragment AssumptionSourceForMethodologyExplainer on AssumptionSource {
  id
  externalSourceNotes
  userVisibleSourceUrl
  hasMultiplierLicenseRestrictions
}
    `;
export const AssumptionForMethodologyExplainerFragmentDoc = gql`
    fragment AssumptionForMethodologyExplainer on AssumptionRecordForDashboard {
  id
  conversionDescription
  conversionMultiplier
  inputUnit
  outputUnit
  assumptionSource {
    ...AssumptionSourceForMethodologyExplainer
  }
  assumptionDescription {
    id
    updatedAt
    assumptionId
    userVisibleConversionDescription
  }
}
    ${AssumptionSourceForMethodologyExplainerFragmentDoc}`;
export const ConversionChainItemForMethodologyExplainerFragmentDoc = gql`
    fragment ConversionChainItemForMethodologyExplainer on ConversionChainItemForDashboard {
  __typename
  ... on UnitConversionRecord {
    ...UnitConversionForMethodologyExplainer
  }
  ... on AssumptionRecordForDashboard {
    ...AssumptionForMethodologyExplainer
  }
}
    ${UnitConversionForMethodologyExplainerFragmentDoc}
${AssumptionForMethodologyExplainerFragmentDoc}`;
export const ConversionFactorForMethodologyExplainerFragmentDoc = gql`
    fragment ConversionFactorForMethodologyExplainer on ConversionFactorForDashboard {
  id
  conversionFactorId
  inputUnit
  outputUnit
  conversionChain {
    ...ConversionChainItemForMethodologyExplainer
  }
}
    ${ConversionChainItemForMethodologyExplainerFragmentDoc}`;
export const EmissionsFactorForMethodologyExplainerFragmentDoc = gql`
    fragment EmissionsFactorForMethodologyExplainer on EmissionsFactorRecordForDashboard {
  id
  emissionsFactorId
  kgCo2e
  unit
  efName
  assumptionSource {
    ...AssumptionSourceForMethodologyExplainer
  }
  emissionsFactorDescription {
    id
    updatedAt
    emissionsFactorId
    userVisibleEfName
  }
  supplierDisclosure(
    opts: {historicalYearFilters: {}, climateCommitmentFilters: {}}
  ) {
    ...DisclosureForMethodologyExplainer
  }
  isVendorSpecific
  startMonth
  endMonth
}
    ${AssumptionSourceForMethodologyExplainerFragmentDoc}
${DisclosureForMethodologyExplainerFragmentDoc}`;
export const CalculationChainForMethodologyExplainerFragmentDoc = gql`
    fragment CalculationChainForMethodologyExplainer on CalculationChain {
  __typename
  conversionFactor {
    ...ConversionFactorForMethodologyExplainer
  }
  emissionsFactor {
    ...EmissionsFactorForMethodologyExplainer
  }
  locationEmissionsFactor {
    ...EmissionsFactorForMethodologyExplainer
  }
  inputQuantity
  inputUnit
  convertedQuantity
  tco2eQuantity
  tco2eLocationQuantity
  sumOfMeasure
  emissionsModelVersionId
}
    ${ConversionFactorForMethodologyExplainerFragmentDoc}
${EmissionsFactorForMethodologyExplainerFragmentDoc}`;
export const SingleRowTraceFragmentFragmentDoc = gql`
    fragment SingleRowTraceFragment on SingleRowTrace {
  id
  trace
  traceDetails {
    referenceValueDetails
    emissionsModelVariableDetails
    bartValueDetails
    emissionsModelVersionId
    emissionsModelEvaluationId
  }
  ghgCategoryId
}
    `;
export const SingleRowMethodologyExplainerFieldsFragmentDoc = gql`
    fragment SingleRowMethodologyExplainerFields on SingleRowMethodologyExplainer {
  id
  files {
    ...FilesForMethodologyExplainer
  }
  pipelineV2Chain {
    ...CalculationChainForMethodologyExplainer
  }
  marketTrace {
    ...SingleRowTraceFragment
  }
  locationTrace {
    ...SingleRowTraceFragment
  }
  biogenicCo2Trace {
    ...SingleRowTraceFragment
  }
  biogenicCo2LocationTrace {
    ...SingleRowTraceFragment
  }
  cleanPowerAvoidedEmissionsTrace {
    ...SingleRowTraceFragment
  }
  wasteEmissionsTrace {
    ...SingleRowTraceFragment
  }
  microplasticsInflowEmissionsTrace {
    ...SingleRowTraceFragment
  }
  microplasticsOutflowEmissionsTrace {
    ...SingleRowTraceFragment
  }
  substanceInflowEmissionsTrace {
    ...SingleRowTraceFragment
  }
  substanceOutflowEmissionsTrace {
    ...SingleRowTraceFragment
  }
  waterConsumptionEmissionsTrace {
    ...SingleRowTraceFragment
  }
  footprintExclusionRule {
    ...FootprintExclusionRuleForMethodologyExplainer
  }
}
    ${FilesForMethodologyExplainerFragmentDoc}
${CalculationChainForMethodologyExplainerFragmentDoc}
${SingleRowTraceFragmentFragmentDoc}
${FootprintExclusionRuleForMethodologyExplainerFragmentDoc}`;
export const FootprintSnapshotForProductsFragmentDoc = gql`
    fragment FootprintSnapshotForProducts on FootprintSnapshot {
  id
  status
  createdAt
}
    `;
export const EmissionsSliceMaterialFootprintRowUpdatedTaxonomyFragmentDoc = gql`
    fragment EmissionsSliceMaterialFootprintRowUpdatedTaxonomy on MaterialFootprintRowUpdatedTaxonomy {
  id
  orgMaterial
  vectors {
    vectorName
    vectorValues
  }
  product {
    id
    product
    totalEmissions
  }
  emissionsPerKg
  inputQuantity
  totalEmissions
  totalEmissionsPercent
}
    `;
export const EmissionsSliceProductFootprintRowUpdatedTaxonomyFragmentDoc = gql`
    fragment EmissionsSliceProductFootprintRowUpdatedTaxonomy on ProductFootprintRowUpdatedTaxonomy {
  id
  product
  vectors {
    vectorName
    vectorValues
  }
  emissionsPerUnit
  unitsProduced
  totalEmissions
  totalEmissionsPercent
  categoryEmissionsPercent
  relatedMaterials {
    id
    material
    percentageOfProduct
  }
}
    `;
export const WatershedFootprintReviewRequestForCreateFragmentDoc = gql`
    fragment WatershedFootprintReviewRequestForCreate on WatershedFootprintReviewRequest {
  id
  createdAt
  updatedAt
  user {
    id
    displayName
  }
  status
  discussion {
    id
  }
}
    `;
export const FootprintAssemblyRunForChangelogFragmentDoc = gql`
    fragment FootprintAssemblyRunForChangelog on FootprintAssemblyRun {
  id
  createdAt
  status
  generatedBy {
    id
    displayName
  }
  workflowId
  footprintSnapshotId
  footprintId
}
    `;
export const SupplierAttachmentFieldsFragmentDoc = gql`
    fragment SupplierAttachmentFields on SupplierAttachment {
  id
  fileMetadataId
  fileName
  companyId
  isImported
  crossOrgModelId
}
    `;
export const TargetsDialogDisclosureTargetDetailsFragmentDoc = gql`
    fragment TargetsDialogDisclosureTargetDetails on DisclosureTargetDetails {
  id
  name
  description
  baseYear
  filters {
    conjunction
    expressions {
      field
      operator
      value
    }
  }
  reductionRate
  intensityType
  emissionsTarget {
    id
    frequency
    base
    values
  }
  unit
  unitDescription
}
    `;
export const DisclosureForTargetsDialogFragmentDoc = gql`
    fragment DisclosureForTargetsDialog on Disclosure {
  id
  company {
    id
  }
  publicDisclosure {
    id
    reportType
  }
  privateDisclosure {
    id
    privateDisclosureType
  }
  targets {
    ...TargetsDialogDisclosureTargetDetails
  }
}
    ${TargetsDialogDisclosureTargetDetailsFragmentDoc}`;
export const CompanyEngagementTaskFieldsForOverviewFragmentDoc = gql`
    fragment CompanyEngagementTaskFieldsForOverview on EngagementTask {
  id
  createdAt
  approvedAt
  submittedAt
  companyId
  dueDate
  rootAssignee
  rootAssigneeDisplayName
  company {
    id
    name
  }
  rootCompanyId
  status
  statusChangedAt
  engagementTaskConfigId
  engagementTaskConfig {
    id
    name
    category
    rootFacingDescription
    baseConfig {
      id
    }
    archivedAt
  }
  engagementTaskBatchId
  initialSupplierAssigneeEmail
}
    `;
export const EngagementTaskTypeAndStatusFieldsFragmentDoc = gql`
    fragment EngagementTaskTypeAndStatusFields on EngagementTask {
  id
  submittedAt
  engagementTaskConfig {
    type
  }
}
    `;
export const EngagementNotificationSettingFieldsFragmentDoc = gql`
    fragment EngagementNotificationSettingFields on EngagementNotificationSetting {
  id
  userId
  companyId
  engagementTaskId
  shouldNotify
}
    `;
export const EngagementCohortWithCompanyIdsFieldsFragmentDoc = gql`
    fragment EngagementCohortWithCompanyIdsFields on EngagementCohortWithCompanyIds {
  id
  name
  companyIds
}
    `;
export const EngagementTaskConfigForEditingFragmentDoc = gql`
    fragment EngagementTaskConfigForEditing on EngagementTaskConfig {
  id
  name
  orgId
  canDelete
  type
  category
  order
  publishedAt
  archivedAt
  baseConfig {
    id
  }
}
    `;
export const SelfServeSupplierColumnFieldsFragmentDoc = gql`
    fragment SelfServeSupplierColumnFields on SupplierTableColumn {
  id
  field
  headerName
  questionKey
  columnFormat
  baseEngagementTaskConfigId
  isSupplierScore
  isSupplierScoreCriteria
}
    `;
export const EngagementTaskDeployStatusFieldsFragmentDoc = gql`
    fragment EngagementTaskDeployStatusFields on EngagementTaskDeployStatus {
  id
  success
  company {
    id
    name
    contacts {
      id
      name
      role
      email
    }
    isWatershedCorporateCustomer
  }
  latestContactEmail
  latestEmailSent {
    id
    users {
      id
      recipientEmail
      latestEmailStatus
    }
  }
}
    `;
export const EngagementTaskBatchFieldsForBulkDeployStatusFragmentDoc = gql`
    fragment EngagementTaskBatchFieldsForBulkDeployStatus on EngagementTaskBatch {
  id
  estimatedMinutesToAssignTasks
  completedAt
  deployStatuses {
    ...EngagementTaskDeployStatusFields
  }
}
    ${EngagementTaskDeployStatusFieldsFragmentDoc}`;
export const SupplierCategorizedEmissionDataFieldsFragmentDoc = gql`
    fragment SupplierCategorizedEmissionDataFields on CategorizedEmissionData {
  businessCategory
  businessSubcategory
  proportion
  amountKgco2e
}
    `;
export const SupplierCustomDataFieldsFragmentDoc = gql`
    fragment SupplierCustomDataFields on SupplierCustomData {
  id
  priority
  notes
  customData
}
    `;
export const SupplierRowFieldsFragmentDoc = gql`
    fragment SupplierRowFields on SupplierRow {
  id
  name
  groupingKey
  categoryId
  subcategoryId
  kgco2eQuantity
  convertedUnit
  convertedQuantity
  month
  ghgCategoryId
  beaCode
}
    `;
export const CompanyFieldsForCcisAndParentsFragmentDoc = gql`
    fragment CompanyFieldsForCCISAndParents on Company {
  ...CompanyFieldsForCCIS
  parentRelationships(onlyGetActiveRelationships: true) {
    ...ParentRelationshipFields
  }
  parentRelationshipsForSidebar: parentRelationships {
    ...ParentRelationshipForSidebarFields
  }
}
    ${CompanyFieldsForCcisFragmentDoc}
${ParentRelationshipFieldsFragmentDoc}
${ParentRelationshipForSidebarFieldsFragmentDoc}`;
export const SupplierHistoricalEmissionsFieldsFragmentDoc = gql`
    fragment SupplierHistoricalEmissionsFields on SupplierHistoricalEmissions {
  scope1
  scope2
  scope3
  scope301
  scope302
  scope303
  scope304
  scope305
  scope306
  scope307
  scope308
  scope309
  scope310
  scope311
  scope312
  scope313
  scope314
  scope315
  scope316
  scope317
  scope1Ratio
  scope2Ratio
  scope3Ratio
  publishingYear
  reportingYear
  surveyId
  source
  revenueUsd
  revenue
  revenueCurrency
  publicUrl
  units
  expenseCategory
}
    `;
export const SupplierFieldsFragmentDoc = gql`
    fragment SupplierFields on Supplier {
  id
  name
  footprintVendorNames
  supplierType
  totalKgco2e
  totalKgco2eFromFootprint
  totalSpending
  totalSpendUsd
  isTotalSpendingPartial
  latestDisclosureDateTime
  latestCdpDisclosurePublishingYear
  percentEmissions
  climateProgress
  sbtiStage
  company {
    ...CompanyFieldsForCCISAndParents
  }
  historicalEmissions {
    ...SupplierHistoricalEmissionsFields
  }
  industryAverageAllocatedEmissions {
    ...SupplierHistoricalEmissionsFields
  }
  customDataV2
  notes
  footprintTags {
    key
    value
  }
  ghgCategoryIds
}
    ${CompanyFieldsForCcisAndParentsFragmentDoc}
${SupplierHistoricalEmissionsFieldsFragmentDoc}`;
export const SupplierEmissionsFactorsFragmentDoc = gql`
    fragment SupplierEmissionsFactors on EmissionsFactorRecordForDashboard {
  id
  isVendorSpecific
  efName
  supplierDisclosure(opts: {historicalYearFilters: {}}) {
    id
    historicalEmissionsYears {
      id
      reportingYear
    }
    publicDisclosure {
      id
      reportType
    }
    privateDisclosure {
      id
      privateDisclosureType
    }
  }
  emissionsFactorDescription {
    id
    userVisibleEfName
  }
}
    `;
export const SupplierEmissionsModelEmissionsFactorsFragmentDoc = gql`
    fragment SupplierEmissionsModelEmissionsFactors on EmissionsModelEmissionsFactor {
  id
  isSupplierSpecific
  externalDescription
  supplierDisclosure(opts: {historicalYearFilters: {}}) {
    id
    historicalEmissionsYears {
      id
      reportingYear
    }
    publicDisclosure {
      id
      reportType
    }
    privateDisclosure {
      id
      privateDisclosureType
    }
  }
}
    `;
export const SupplierEmissionsByMonthFieldsFragmentDoc = gql`
    fragment SupplierEmissionsByMonthFields on LabeledTimeseries {
  label
  timeseries {
    base
    values
  }
}
    `;
export const PlanForPlanTabFragmentDoc = gql`
    fragment PlanForPlanTab on Plan {
  id
  name
  state
  createdAt
  isHidden
  forecast {
    id
    footprintSnapshotId
    footprintInterval
    footprintKindsMeta {
      id
      footprintKind
      name
      description
      footprintInterval
    }
    overrideFootprintKind
    referencePeriodInterval
  }
  relevantFootprintFields
}
    `;
export const SupplierTableColumnFieldsFragmentDoc = gql`
    fragment SupplierTableColumnFields on SupplierTableColumn {
  id
  field
  headerName
  description
  visible
  columnFormat
  selectOptions
  footprintTagName
  csatTagName
  isSupplierScore
  isSupplierScoreCriteria
  questionKey
}
    `;
export const SupplierTableFieldsFragmentDoc = gql`
    fragment SupplierTableFields on SupplierTable {
  columns {
    ...SupplierTableColumnFields
  }
}
    ${SupplierTableColumnFieldsFragmentDoc}`;
export const EngagementFunnelStepFieldsFragmentDoc = gql`
    fragment EngagementFunnelStepFields on EngagementFunnelStep {
  id
  stepName
  stepType
  sbtStage
  engagementTaskConfigId
  supplierTableColumn {
    id
    field
  }
  supplierTableColumnValues
}
    `;
export const EngagementFunnelsChartFieldsFragmentDoc = gql`
    fragment EngagementFunnelsChartFields on EngagementFunnel {
  id
  name
  steps {
    ...EngagementFunnelStepFields
  }
}
    ${EngagementFunnelStepFieldsFragmentDoc}`;
export const EngagementFunnelsProgressTrackerFieldsFragmentDoc = gql`
    fragment EngagementFunnelsProgressTrackerFields on EngagementFunnel {
  id
  name
  displayOnSupplierDetailsPage
  steps {
    id
    stepName
    stepType
    sbtStage
    engagementTaskConfigId
    supplierTableColumn {
      id
      field
    }
    supplierTableColumnValues
  }
}
    `;
export const SupplierChartConfigFieldsForMetricsChartFragmentDoc = gql`
    fragment SupplierChartConfigFieldsForMetricsChart on SupplierMetricsChartConfig {
  id
  title
  metricsTypes
}
    `;
export const SupplierChartConfigFieldsFragmentDoc = gql`
    fragment SupplierChartConfigFields on SupplierChartConfig {
  __typename
  id
  orgId
  title
  description
  chartType
  ... on SupplierStandardChartConfig {
    colorScale
    xField
    aggregateType
  }
  ... on SupplierEngagementFunnelChartConfig {
    funnels {
      id
      ...EngagementFunnelsChartFields
      ...EngagementFunnelsProgressTrackerFields
    }
  }
  ... on SupplierTaskStatusChangeOverTimeChartConfig {
    colorScale
    tasksStatusChangeConfig {
      status
      engagementTaskConfigId
    }
    isCumulative
  }
  ... on SupplierMetricsChartConfig {
    id
    ...SupplierChartConfigFieldsForMetricsChart
  }
  ... on SupplierSbtCommitmentOverTimeChartConfig {
    colorScale
  }
}
    ${EngagementFunnelsChartFieldsFragmentDoc}
${EngagementFunnelsProgressTrackerFieldsFragmentDoc}
${SupplierChartConfigFieldsForMetricsChartFragmentDoc}`;
export const SupplierViewFieldsFragmentDoc = gql`
    fragment SupplierViewFields on SupplierView {
  id
  reductionPlan {
    ...PlanForPlanTab
  }
  table {
    ...SupplierTableFields
  }
  columnOrder {
    columnId
    visible
    width
  }
  charts: chartsV2 {
    ...SupplierChartConfigFields
  }
  chartOrder {
    chartId
    visible
  }
  name
  filters {
    conjunction
    expressions {
      field
      operator
      value
    }
  }
  isDefault
}
    ${PlanForPlanTabFragmentDoc}
${SupplierTableFieldsFragmentDoc}
${SupplierChartConfigFieldsFragmentDoc}`;
export const EmissionsYearForSuppliersTableFragmentDoc = gql`
    fragment EmissionsYearForSuppliersTable on EmissionsYear {
  id
  reportingYear
  expenseCategory
  units
  ...EmissionsYearScope1Fields
  ...EmissionsYearScope2Fields
  ...EmissionsYearScope3Fields
}
    ${EmissionsYearScope1FieldsFragmentDoc}
${EmissionsYearScope2FieldsFragmentDoc}
${EmissionsYearScope3FieldsFragmentDoc}`;
export const DisclosureForSuppliersTableFragmentDoc = gql`
    fragment DisclosureForSuppliersTable on Disclosure {
  id
  publicDisclosure {
    publishingYear
    reportType
    publicUrl
    cdpVendorData {
      id
      reportingYear
    }
  }
  privateDisclosure {
    privateDisclosureType
    createdAt
    orgName
  }
  targets {
    id
    name
    description
    baseYear
    filters {
      conjunction
      expressions {
        field
        operator
        value
      }
    }
    emissionsTarget {
      ...SimpleTimeseriesForDisclosures
    }
    intensityType
    unit
    companyClimateCommitmentId
  }
  climateCommitments {
    ...CompanyClimateCommitmentFieldsForCCIS
  }
  initiatives {
    ...SupplierDisclosureInitiativeFields
  }
  historicalEmissionsYears {
    ...EmissionsYearForSuppliersTable
  }
}
    ${SimpleTimeseriesForDisclosuresFragmentDoc}
${CompanyClimateCommitmentFieldsForCcisFragmentDoc}
${SupplierDisclosureInitiativeFieldsFragmentDoc}
${EmissionsYearForSuppliersTableFragmentDoc}`;
export const SuppliersDataFieldsFragmentDoc = gql`
    fragment SuppliersDataFields on SuppliersData {
  id
  data
}
    `;
export const EngagementCohortFieldsFragmentDoc = gql`
    fragment EngagementCohortFields on EngagementCohort {
  id
  name
}
    `;
export const SuppliersSettingsFieldsFragmentDoc = gql`
    fragment SuppliersSettingsFields on SuppliersSettings {
  id
  includedGhgCategories
  viewLatestSupplierMapping
  footprintSnapshotIds
  footprintInterval
}
    `;
export const CompanyPortalSettingsFieldsFragmentDoc = gql`
    fragment CompanyPortalSettingsFields on CompanyPortalSettings {
  id
  portalCallout
  sidebarContent
  portalTitle
  toggleHeaderImage
}
    `;
export const BulkDeployStatusCompanyFieldsFragmentDoc = gql`
    fragment BulkDeployStatusCompanyFields on Company {
  id
  name
}
    `;
export const SupplierExpenseCategoryFieldsFragmentDoc = gql`
    fragment SupplierExpenseCategoryFields on SupplierExpenseCategory {
  companyId
  expenseCategories {
    beaCode
    kgco2e
  }
}
    `;
export const SupplierScoreCriteriaFieldsFragmentDoc = gql`
    fragment SupplierScoreCriteriaFields on SupplierScoreCriteria {
  id
  label
  supplierScoreId
  filters {
    dimension
    operator
    value
  }
  field
  weight
}
    `;
export const SupplierScorecardFieldsFragmentDoc = gql`
    fragment SupplierScorecardFields on SupplierScorecard {
  id
  name
  criteria {
    ...SupplierScoreCriteriaFields
  }
  field
}
    ${SupplierScoreCriteriaFieldsFragmentDoc}`;
export const SupplierForReductionsForecastFragmentDoc = gql`
    fragment SupplierForReductionsForecast on Supplier {
  id
  name
  company {
    id
  }
  disclosures(
    opts: {targetFilters: {}, climateCommitmentFilters: {}, historicalYearFilters: {allScopes: true}, publicDisclosureFilters: {}, privateDisclosureFilters: {orgId: $orgId}, initiativeFilters: {}}
  ) {
    ...SupplierDisclosureFields
  }
  industryAverageAllocatedEmissions {
    ...SupplierHistoricalEmissionsFields
  }
  footprintVendorNames
  cleanedFootprintVendorNames
  totalSpending
}
    ${SupplierDisclosureFieldsFragmentDoc}
${SupplierHistoricalEmissionsFieldsFragmentDoc}`;
export const BiSavedViewForLandingPageFragmentDoc = gql`
    fragment BiSavedViewForLandingPage on BiSavedView {
  id
  stableId
  name
  owner {
    id
    name
    displayName
  }
  updatedAt
  createdAt
}
    `;
export const BenchmarkFieldsForExplorerFragmentDoc = gql`
    fragment BenchmarkFieldsForExplorer on Benchmark {
  id
  reportId
  name
  reportingYear
  kgco2eByScope {
    scope
    kgco2e
  }
  companyId
}
    `;
export const ContactFieldsForEmailFragmentDoc = gql`
    fragment ContactFieldsForEmail on IContact {
  id
  email
  name
  role
  companyId
}
    `;
export const FinanceColumnAssetGridFragmentDoc = gql`
    fragment FinanceColumnAssetGrid on FinanceColumn {
  id
  field
  columnFormat
  columnConfiguration {
    ... on GridColumnConfigurationSingleSelect {
      __typename
      columnFormat
      options
    }
  }
  headerName
  description
  width
}
    `;
export const FinanceWorksheetAssetGridFragmentDoc = gql`
    fragment FinanceWorksheetAssetGrid on FinanceWorksheet {
  id
  hiddenColumns
  orgColumns {
    id
    ...FinanceColumnAssetGrid
  }
  standardView
  sortModel {
    field
    sort
  }
}
    ${FinanceColumnAssetGridFragmentDoc}`;
export const AssetPersonalMotorVehicleInsuranceAllFieldsFragmentDoc = gql`
    fragment AssetPersonalMotorVehicleInsuranceAllFields on AssetPersonalMotorVehicleInsurance {
  __typename
  id
  orgId
  createdAt
  updatedAt
  name
  currencyCode
  tagData
}
    `;
export const FinanceTagAllFieldsFragmentDoc = gql`
    fragment FinanceTagAllFields on FinanceTag {
  __typename
  id
  orgId
  createdAt
  updatedAt
  createdBy
  updatedBy
  name
  description
  columnFormat
  tagType
  columnConfiguration
  tagCategory
}
    `;
export const AssetCommentAttachmentForSidebarFragmentDoc = gql`
    fragment AssetCommentAttachmentForSidebar on AssetCommentAttachment {
  id
  assetCommentId
  fileMetadata {
    id
    name
  }
}
    `;
export const AssetCommentAllFieldsFragmentDoc = gql`
    fragment AssetCommentAllFields on AssetComment {
  orgId
  id
  createdAt
  updatedAt
  commentText
  userId
  lastEdited
  assetCorporateId
  assetCommercialRealEstateId
  assetGroupId
  deletedAt
  commentHistory
  user {
    id
    name
  }
  attachments {
    id
    ...AssetCommentAttachmentForSidebar
  }
}
    ${AssetCommentAttachmentForSidebarFragmentDoc}`;
export const FinanceHeaderCurrentFragmentDoc = gql`
    fragment FinanceHeaderCurrent on FinanceHeader {
  title
  fundExcluded
  isAssetGroupItem
}
    `;
export const FinanceSavedViewAllFieldsFragmentDoc = gql`
    fragment FinanceSavedViewAllFields on FinanceSavedView {
  id
  name
  standardView
  fundId
  layout
  metrics
  charts
  filters
}
    `;
export const DatasetForMeasurementPageFragmentDoc = gql`
    fragment DatasetForMeasurementPage on Dataset {
  id
  name
  canonicalDataset {
    id
    kind
  }
}
    `;
export const DatasetWithStageForMeasurementPageFragmentDoc = gql`
    fragment DatasetWithStageForMeasurementPage on DatasetWithStage {
  id
  dataset {
    ...DatasetForMeasurementPage
    canonicalDataset {
      id
      kind
    }
  }
  areAllTasksComplete
  areUploadsComplete
  isUsableInFootprint
  hasNoApprovedData
}
    ${DatasetForMeasurementPageFragmentDoc}`;
export const MeasurementTaskObjectDatasetFieldsFragmentDoc = gql`
    fragment MeasurementTaskObjectDatasetFields on Dataset {
  ...DatasetForMeasurementPage
  datasources {
    id
    name
  }
}
    ${DatasetForMeasurementPageFragmentDoc}`;
export const DataIssueUtils_DataIssueFragmentDoc = gql`
    fragment dataIssueUtils_DataIssue on DataIssue {
  comments {
    edges {
      node {
        id
        person {
          id
          isWatershedEmployee
          isWatershedContractor
        }
        createdAt
        isPublished
      }
    }
  }
}
    `;
export const DatasourceForMeasurementPageFragmentDoc = gql`
    fragment DatasourceForMeasurementPage on Datasource {
  id
  name
  dataset {
    ...DatasetForMeasurementPage
  }
}
    ${DatasetForMeasurementPageFragmentDoc}`;
export const FinancialsReviewItemForAssignmentPermissionCheckFragmentDoc = gql`
    fragment FinancialsReviewItemForAssignmentPermissionCheck on FinancialsReviewItem {
  id
  group {
    __typename
    id
    datasources {
      ...DatasourceForMeasurementPage
    }
  }
}
    ${DatasourceForMeasurementPageFragmentDoc}`;
export const ApproverFieldsForMeasurementPageFragmentDoc = gql`
    fragment ApproverFieldsForMeasurementPage on UserWithApprovalStatus {
  user {
    id
    name
    displayName
  }
  approvalStatus
}
    `;
export const FacilitiesPreviewForPillFragmentDoc = gql`
    fragment FacilitiesPreviewForPill on FacilitiesPreview {
  preview {
    id
    buildingName
  }
  totalCount
}
    `;
export const MeasurementObjectForMeasurementPageFragmentDoc = gql`
    fragment MeasurementObjectForMeasurementPage on MeasurementTaskObject {
  __typename
  id
  ... on MeasurementProjectDataset {
    modelId
    isCompleted
    dataset {
      ...MeasurementTaskObjectDatasetFields
    }
  }
  ... on DataIssue {
    ...dataIssueUtils_DataIssue
    linkedObject {
      __typename
      ... on UserUploadTask {
        id
        datasource {
          ...DatasourceForMeasurementPage
        }
      }
      ... on FinancialsReviewItem {
        ...FinancialsReviewItemForAssignmentPermissionCheck
      }
    }
  }
  ... on UserUploadTask {
    state
    assignees {
      id
      name
      displayName
    }
    approvers {
      ...ApproverFieldsForMeasurementPage
    }
    facilitiesPreview {
      ...FacilitiesPreviewForPill @defer(label: "FacilitiesPreviewForPill")
    }
    datasource {
      ...DatasourceForMeasurementPage
      hasIntegration
      dataset {
        ...DatasetForMeasurementPage
        canonicalDataset {
          id
          kind
        }
      }
    }
  }
  ... on FinancialsReviewItem {
    ...FinancialsReviewItemForAssignmentPermissionCheck
  }
  ... on FinancialsReviewGroup {
    datasources {
      ...DatasourceForMeasurementPage
    }
  }
  ... on MeasurementDataMappingTask {
    dataset {
      ...MeasurementTaskObjectDatasetFields
    }
  }
}
    ${MeasurementTaskObjectDatasetFieldsFragmentDoc}
${DataIssueUtils_DataIssueFragmentDoc}
${DatasourceForMeasurementPageFragmentDoc}
${FinancialsReviewItemForAssignmentPermissionCheckFragmentDoc}
${ApproverFieldsForMeasurementPageFragmentDoc}
${FacilitiesPreviewForPillFragmentDoc}
${DatasetForMeasurementPageFragmentDoc}`;
export const MeasurementTaskForMeasurementPageFragmentDoc = gql`
    fragment MeasurementTaskForMeasurementPage on MeasurementTask {
  id
  title
  link
  assignees {
    id
    name
    displayName
    didLatestEmailBounce
    email
  }
  approvers {
    id
    name
    displayName
    email
  }
  lockState
  isAssignedToWatershed
  statusSimplified
  object {
    ...MeasurementObjectForMeasurementPage
  }
}
    ${MeasurementObjectForMeasurementPageFragmentDoc}`;
export const NextMeasurementProjectDataFragmentDoc = gql`
    fragment NextMeasurementProjectData on MeasurementProject {
  id
  name
  active
  coverageStartDate
  footprintStatus
  measurementTimeline {
    ...MeasurementTimelineForMeasurementPage
  }
  datasetsWithStages {
    ...DatasetWithStageForMeasurementPage
  }
  measurementTasks {
    ...MeasurementTaskForMeasurementPage
  }
}
    ${MeasurementTimelineForMeasurementPageFragmentDoc}
${DatasetWithStageForMeasurementPageFragmentDoc}
${MeasurementTaskForMeasurementPageFragmentDoc}`;
export const ActiveMeasurementProjectForOnboardingHomePageFragmentDoc = gql`
    fragment ActiveMeasurementProjectForOnboardingHomePage on MeasurementProject {
  id
  name
  measurementTasks {
    ...MeasurementTaskForMeasurementPage
  }
  footprintStatus
}
    ${MeasurementTaskForMeasurementPageFragmentDoc}`;
export const OrganizationForOnboardingHomePageFragmentDoc = gql`
    fragment OrganizationForOnboardingHomePage on Organization {
  id
  users {
    edges {
      node {
        id
      }
    }
  }
  hasActiveWorkosConnection
}
    `;
export const ValueMappingOutputOptionFieldsFragmentDoc = gql`
    fragment ValueMappingOutputOptionFields on ValueMappingOutputOption {
  id
  title
  subtitle
  descriptionLong
  isSuggested
  category
  extraData
}
    `;
export const VisibleMarketplaceDeveloperFieldsFragmentDoc = gql`
    fragment VisibleMarketplaceDeveloperFields on MarketplaceDeveloper {
  id
  name
  url
  descriptionMd
  imageUrl
}
    `;
export const VisibleMarketplaceProjectArchetypeFieldsFragmentDoc = gql`
    fragment VisibleMarketplaceProjectArchetypeFields on MarketplaceProjectArchetype {
  __typename
  id
  kind
  unit
  name
  isUserVisible
  descriptionMd
  summaryMd
  imageUrl
  bannerImageUrl
  additionalityRating
  permanenceRating
  priceUsdCentsMin
  priceUsdCentsMax
  visibleDevelopers {
    edges {
      node {
        ...VisibleMarketplaceDeveloperFields
      }
    }
  }
}
    ${VisibleMarketplaceDeveloperFieldsFragmentDoc}`;
export const MaterialTableFieldsFragmentDoc = gql`
    fragment MaterialTableFields on MaterialVariantAndLCARow {
  id
  orgId
  materialExternalId
  materialName
  materialVariantName
  materialVariantTier
  materialVariantQuantityUnit
  materialVariantDescription
  materialVariantGroupId
  materialVariantSupplierExternalId
  lcaBoundary
  biogenicA1KgCO2e
  biogenicA2KgCO2e
  biogenicA3KgCO2e
  biogenicA4KgCO2e
  biogenicGateToGateKgCO2e
  biogenicCradleToGateKgCO2e
  biogenicCradleToGraveKgCO2e
  biogenicCradleToSiteKgCO2e
  fossilA1KgCO2e
  fossilA2KgCO2e
  fossilA3KgCO2e
  fossilA4KgCO2e
  fossilGateToGateKgCO2e
  fossilCradleToGateKgCO2e
  fossilCradleToGraveKgCO2e
  fossilCradleToSiteKgCO2e
  flagA1KgCO2e
  flagA2KgCO2e
  flagA3KgCO2e
  flagA4KgCO2e
  flagGateToGateKgCO2e
  flagCradleToGateKgCO2e
  flagCradleToGraveKgCO2e
  flagCradleToSiteKgCO2e
}
    `;
export const UserUploadedTableWithDataPreviewFragmentDoc = gql`
    fragment UserUploadedTableWithDataPreview on UserUploadedTable {
  ...UserUploadedTableFieldsWithoutDataPreview
  ...UserUploadedTableWithOnlyDataPreviewFields
}
    ${UserUploadedTableFieldsWithoutDataPreviewFragmentDoc}
${UserUploadedTableWithOnlyDataPreviewFieldsFragmentDoc}`;
export const UserUploadAttemptFieldsWithDataPreviewFragmentDoc = gql`
    fragment UserUploadAttemptFieldsWithDataPreview on UserUploadAttempt {
  id
  name
  createdAt
  size
  uploader {
    id
    name
  }
  description
  userUploadedTables {
    ...UserUploadedTableWithDataPreview
  }
  latestUutsIfRevisionExists {
    ...UserUploadedTableWithDataPreview
  }
  remoteWritten
}
    ${UserUploadedTableWithDataPreviewFragmentDoc}`;
export const UserUploadFieldsForFilePreviewFragmentDoc = gql`
    fragment UserUploadFieldsForFilePreview on UserUpload {
  id
  processingMode
  userVisibleAttempt {
    ...UserUploadAttemptFieldsWithDataPreview
  }
  userUploadTask {
    id
    measurementProject {
      id
    }
    datasource {
      id
      name
      dataset {
        id
        name
      }
    }
  }
  revisionRoot
}
    ${UserUploadAttemptFieldsWithDataPreviewFragmentDoc}`;
export const UserUploadedTableForTablePreviewDialogFragmentDoc = gql`
    fragment UserUploadedTableForTablePreviewDialog on UserUploadedTable {
  ...UserUploadedTableFieldsWithoutDataPreview
  ...UserUploadedTableWithOnlyDataPreviewFields
}
    ${UserUploadedTableFieldsWithoutDataPreviewFragmentDoc}
${UserUploadedTableWithOnlyDataPreviewFieldsFragmentDoc}`;
export const CustomActivityUserUploadTasksFieldsFragmentDoc = gql`
    fragment CustomActivityUserUploadTasksFields on UserUploadTask {
  id
  state
  userUploads {
    edges {
      node {
        id
      }
    }
  }
  createdAt
  datasource {
    id
  }
}
    `;
export const FacilityPeriodFragmentDoc = gql`
    fragment FacilityPeriod on BuildingPeriodInterface {
  kind
  sizeQuantity
  customFieldId
  customFieldValue
  orgUnitStableId
  orgUnitTypeStableId
}
    `;
export const CommonFacilitiesColumnsFragmentDoc = gql`
    fragment CommonFacilitiesColumns on BuildingInterface {
  buildingName
  editState
  sizeUnit
  periods {
    ...FacilityPeriod
  }
  streetAddress
  streetAddress2
  city
  state
  country
  leaseType
  buildingKind
  latitude
  longitude
  startYearMonth
  endYearMonth
}
    ${FacilityPeriodFragmentDoc}`;
export const FacilitiesForAddFacilitiesGridFragmentDoc = gql`
    fragment FacilitiesForAddFacilitiesGrid on Facility {
  id
  ...CommonFacilitiesColumns
  tasks(measurementProjectId: $measurementProjectId) {
    id
    measurementTask {
      id
      title
    }
    datasource {
      id
      name
      dataset {
        id
        name
        canonicalDataset {
          kind
          id
        }
      }
    }
  }
}
    ${CommonFacilitiesColumnsFragmentDoc}`;
export const CtsTransformParquetDataFragmentDoc = gql`
    fragment CtsTransformParquetData on ParquetData {
  id
  numRecords
  ndjsonSignedUrl
  ndjsonSize
  ndjsonByteBoundaries
  columns {
    name
    sqlType
    minValue
    maxValue
    numNulls
    approxDistinctValues
    sampleValues
  }
}
    `;
export const CtsTransformRunFieldsFragmentDoc = gql`
    fragment CtsTransformRunFields on CustomerTargetSchemaTransformRun {
  id
  sql
  transform {
    id
    transformTarget
    businessActivityType {
      id
      name
    }
  }
  input {
    id
    name
    category
    revisionRootFileMetadata {
      id
      name
    }
  }
  output {
    ...CtsTransformParquetData
  }
  standardizedOutput {
    ...CtsTransformParquetData
  }
  warnings {
    message
    blocking
  }
  error
  createdAt
  runner {
    id
    name
  }
}
    ${CtsTransformParquetDataFragmentDoc}`;
export const CtsTransformForEditorFragmentDoc = gql`
    fragment CtsTransformForEditor on CustomerTargetSchemaTransform {
  id
  deletedAt
  sql
  transformTarget
  businessActivityType {
    id
    name
  }
  runs {
    ...CtsTransformRunFields
  }
}
    ${CtsTransformRunFieldsFragmentDoc}`;
export const FileCustomTransformEditor_UserUploadFragmentDoc = gql`
    fragment FileCustomTransformEditor_userUpload on UserUpload {
  id
  actualUserUploadId
  name
  transformsIncludingDeleted {
    id
    businessActivityType {
      id
      name
    }
    transformTarget
    runs {
      id
      error
      warnings {
        blocking
      }
    }
    ...CtsTransformForEditor
  }
}
    ${CtsTransformForEditorFragmentDoc}`;
export const DatasetForImporterFragmentDoc = gql`
    fragment DatasetForImporter on Dataset {
  id
  name
  datasources {
    id
    name
    customerTargetSchemaIds
  }
  canonicalDataset {
    id
    kind
    customerTargetSchemas {
      ...CustomerTargetSchemaForDataFormat
    }
  }
}
    ${CustomerTargetSchemaForDataFormatFragmentDoc}`;
export const EditSessionFieldsFragmentDoc = gql`
    fragment EditSessionFields on CreateOneSchemaSessionForUserUploadPayload {
  oneSchemaEmbedId
  oneSchemaEmbedStatus
  oneSchemaEmbedUserJwt
  oneSchemaSessionToken
  templateKey
  signedUrl
  fileMetadataId
  fileName
  userUploadTaskId
  userUploadTask {
    id
    datasource {
      id
      dataset {
        ...DatasetForImporter
      }
    }
    measurementProject {
      id
    }
  }
  ctsVersionId
  ctsVersion {
    ...CustomerTargetSchemaVersionForDataFormat
  }
}
    ${DatasetForImporterFragmentDoc}
${CustomerTargetSchemaVersionForDataFormatFragmentDoc}`;
export const CustomFieldsForCommonFacilitiesColumnsFragmentDoc = gql`
    fragment CustomFieldsForCommonFacilitiesColumns on IngestionCustomField {
  id
  name
}
    `;
export const BuildingsTaskFacilitiesDataGrid_IngestionCustomFieldFragmentDoc = gql`
    fragment BuildingsTaskFacilitiesDataGrid_ingestionCustomField on IngestionCustomField {
  ...CustomFieldsForCommonFacilitiesColumns
  specificDatasets {
    id
  }
  appliesToAllDatasets
}
    ${CustomFieldsForCommonFacilitiesColumnsFragmentDoc}`;
export const BuildingPeriodInfoFragmentDoc = gql`
    fragment BuildingPeriodInfo on BuildingPeriodInterface {
  kind
  sizeQuantity
  startYearMonth
  endYearMonth
  partialCleanPowerPercent
  cleanPowerContractualInstrument
  powerUsageEffectiveness
  isBuildingEmpty
  customFieldValue
  customFieldId
  orgUnitTypeStableId
  orgUnitStableId
}
    `;
export const BuildingInfoFragmentDoc = gql`
    fragment BuildingInfo on BuildingInterface {
  streetAddress
  streetAddress2
  latitude
  longitude
  postalCode
  city
  state
  country
  latitude
  longitude
  startYearMonth
  endYearMonth
  sizeUnit
  buildingKind
  buildingSubkind
  leaseType
  buildingName
  buildingUniqueId
  isWithoutNaturalGas
  isWithoutRefrigerants
  periods {
    ...BuildingPeriodInfo
  }
  revision
  editState
}
    ${BuildingPeriodInfoFragmentDoc}`;
export const BuildingFormSupportingDocumentFragmentDoc = gql`
    fragment BuildingFormSupportingDocument on SupportingDocument {
  id
  file {
    id
    name
    remoteWritten
  }
  createdAt
  utilityType
  buildingUtilityId
}
    `;
export const BuildingsTaskFacilitiesDataGrid_FacilitiesFragmentDoc = gql`
    fragment BuildingsTaskFacilitiesDataGrid_facilities on Facility {
  createdAt
  updatedAt
  id
  buildingName
  streetAddress
  streetAddress2
  city
  state
  country
  sizeUnit
  startYearMonth
  endYearMonth
  ...BuildingInfo
  ...CommonFacilitiesColumns
  periods {
    id
    ...BuildingPeriodInfo
  }
  lockedRevisionForMeasurementProject(measurementProjectId: $measurementProjectId) {
    id
    createdAt
    ...CommonFacilitiesColumns
  }
  supportingDocuments {
    ...BuildingFormSupportingDocument
  }
}
    ${BuildingInfoFragmentDoc}
${CommonFacilitiesColumnsFragmentDoc}
${BuildingPeriodInfoFragmentDoc}
${BuildingFormSupportingDocumentFragmentDoc}`;
export const BuildingsTaskFacilitiesDataGrid_PageInfoFragmentDoc = gql`
    fragment BuildingsTaskFacilitiesDataGrid_pageInfo on PageInfo {
  startCursor
  endCursor
  hasNextPage
  hasPreviousPage
  totalRowCount
}
    `;
export const UtilitiesTaskFacilitiesDataGrid_FacilitiesFragmentDoc = gql`
    fragment UtilitiesTaskFacilitiesDataGrid_facilities on Facility {
  id
  buildingName
  startYearMonth
  endYearMonth
  streetAddress
  lockedRevisionForMeasurementProject(measurementProjectId: $measurementProjectId) {
    id
    buildingName
    streetAddress
  }
  buildingWithUtilities {
    id
    completionIntervals(userUploadTaskId: $userUploadTaskId, combineFuelKinds: true) {
      id
      data {
        utilityType
        fuelKind
        periodsWithActuals {
          start
          end
        }
        periodsWithSupportingDocs {
          start
          end
        }
      }
    }
  }
}
    `;
export const UtilitiesTaskFacilitiesDataGrid_PageInfoFragmentDoc = gql`
    fragment UtilitiesTaskFacilitiesDataGrid_pageInfo on PageInfo {
  startCursor
  endCursor
  hasNextPage
  hasPreviousPage
  totalRowCount
}
    `;
export const ApprovalTargetFieldsForChangingStatusFragmentDoc = gql`
    fragment ApprovalTargetFieldsForChangingStatus on ApprovalTarget {
  id
  approvalStatus
  lockState
  approvers {
    approvalStatus
    user {
      id
    }
  }
}
    `;
export const ChangelogEventPartsFragmentDoc = gql`
    fragment ChangelogEventParts on DataApprovalChangelogEvent {
  id
  activityType
  actorUser {
    id
    displayName
  }
  eventTime
  moreDetails
}
    `;
export const UserUploadTaskFieldsForBuildingMutationResponseFragmentDoc = gql`
    fragment UserUploadTaskFieldsForBuildingMutationResponse on UserUploadTask {
  id
  state
  importState
  buildingIds
  hasIncompleteBuildings
}
    `;
export const DataCollectionCommandPaletteCustomFieldFragmentDoc = gql`
    fragment DataCollectionCommandPaletteCustomField on IngestionCustomField {
  id
  name
  type
  possibleValues
  description
}
    `;
export const CustomFieldForBuildingUtilitiesDialogFragmentDoc = gql`
    fragment CustomFieldForBuildingUtilitiesDialog on IngestionCustomField {
  id
  name
  description
  type
  possibleValues
  specificUtilityTypes
}
    `;
export const CustomFieldForBuildingsGridPageFragmentDoc = gql`
    fragment CustomFieldForBuildingsGridPage on IngestionCustomField {
  ...DataCollectionCommandPaletteCustomField
  ...CustomFieldForBuildingUtilitiesDialog
  id
  name
  description
  type
  possibleValues
  specificDatasets {
    id
  }
  appliesToAllDatasets
}
    ${DataCollectionCommandPaletteCustomFieldFragmentDoc}
${CustomFieldForBuildingUtilitiesDialogFragmentDoc}`;
export const DatasetFieldsForCustomFieldEditDialogFragmentDoc = gql`
    fragment DatasetFieldsForCustomFieldEditDialog on Dataset {
  id
  name
  canonicalDataset {
    id
    kind
  }
}
    `;
export const CustomFieldForEditDialogFragmentDoc = gql`
    fragment CustomFieldForEditDialog on IngestionCustomField {
  id
  name
  createdAt
  type
  description
  possibleValues
  exampleValues
  appliesToAllDatasets
  specificDatasets {
    ...DatasetFieldsForCustomFieldEditDialog
  }
  specificUtilityTypes
  ingestionCustomFieldDatasources {
    id
    numUserUploadTasks
    datasetId
  }
  isMetric
}
    ${DatasetFieldsForCustomFieldEditDialogFragmentDoc}`;
export const BuildingForm_IngestionCustomFieldFragmentDoc = gql`
    fragment BuildingForm_ingestionCustomField on IngestionCustomField {
  id
  ...CustomFieldForBuildingsGridPage
  ...CustomFieldForEditDialog
}
    ${CustomFieldForBuildingsGridPageFragmentDoc}
${CustomFieldForEditDialogFragmentDoc}`;
export const OrganizationWithIngestionCustomFieldsFragmentDoc = gql`
    fragment OrganizationWithIngestionCustomFields on Organization {
  id
  ingestionCustomFields {
    ...BuildingForm_ingestionCustomField
  }
}
    ${BuildingForm_IngestionCustomFieldFragmentDoc}`;
export const UserUploadEditSheet_UserUploadFragmentDoc = gql`
    fragment UserUploadEditSheet_userUpload on UserUpload {
  id
  actualUserUploadId
  status
  ctsVersion {
    id
  }
}
    `;
export const BuildingUtilityFieldsForUtilitiesGridPageFragmentDoc = gql`
    fragment BuildingUtilityFieldsForUtilitiesGridPage on BuildingUtility {
  id
  periodStart
  periodEnd
  quantity
  unit
  utilityType
  fuelKind
  fuelCombustionReason
  notes
  provider
  productExternalId
  productName
  wasteTreatment
  wasteMaterial
  isWasteHazardous
  isWasteRadioactive
  waterUseKind
  refrigerantName
  refrigerantLeakageSource
  cleanPowerPercent
  cleanPowerContractualInstrument
  onsiteCleanFuelKind
  supportingDocuments {
    ...BuildingFormSupportingDocument
  }
  customFieldValues {
    id
    ingestionCustomFieldId
    customFieldValue
  }
  sourceFile {
    id
    name
    ...UserUploadEditSheet_userUpload
  }
}
    ${BuildingFormSupportingDocumentFragmentDoc}
${UserUploadEditSheet_UserUploadFragmentDoc}`;
export const BuildingUtilityConnectionFragoFragmentDoc = gql`
    fragment BuildingUtilityConnectionFrago on BuildingUtilityConnection {
  edges {
    node {
      ...BuildingUtilityFieldsForUtilitiesGridPage
    }
  }
  pageInfo {
    startCursor
    endCursor
    hasNextPage
    hasPreviousPage
    totalRowCount
  }
}
    ${BuildingUtilityFieldsForUtilitiesGridPageFragmentDoc}`;
export const UserUploadTaskForUtilitiesMutationResponseFragmentDoc = gql`
    fragment UserUploadTaskForUtilitiesMutationResponse on UserUploadTask {
  id
  state
}
    `;
export const ActivityDatasetForDataReviewFragmentDoc = gql`
    fragment ActivityDatasetForDataReview on ActivityDataset {
  businessActivityType
  updatedAt
  datasets {
    id
    name
  }
  lastUpdater {
    id
    displayName
  }
  uploads {
    id
  }
}
    `;
export const SupportingDocsForDirectEntryPreviewFragmentDoc = gql`
    fragment SupportingDocsForDirectEntryPreview on SupportingDocument {
  id
  file {
    id
    name
    remoteWritten
  }
  createdAt
  utilityType
  buildingUtilityId
}
    `;
export const BuildingUtilityFieldsForDirectEntryPreviewFragmentDoc = gql`
    fragment BuildingUtilityFieldsForDirectEntryPreview on BuildingUtility {
  id
  periodStart
  periodEnd
  quantity
  unit
  utilityType
  fuelKind
  fuelCombustionReason
  notes
  provider
  productExternalId
  productName
  wasteTreatment
  wasteMaterial
  isWasteHazardous
  isWasteRadioactive
  waterUseKind
  refrigerantName
  refrigerantLeakageSource
  cleanPowerPercent
  cleanPowerContractualInstrument
  onsiteCleanFuelKind
  supportingDocuments {
    ...SupportingDocsForDirectEntryPreview
  }
  customFieldValues {
    id
    ingestionCustomFieldId
    customFieldValue
  }
}
    ${SupportingDocsForDirectEntryPreviewFragmentDoc}`;
export const CompletionIntervalFieldsFragmentDoc = gql`
    fragment CompletionIntervalFields on BuildingCompletionIntervals {
  id
  data {
    utilityType
    fuelKind
    hasOnsiteCleanElectricity
    periodsWithActuals {
      start
      end
    }
  }
}
    `;
export const FacilityForDirectEntryDialogFragmentDoc = gql`
    fragment FacilityForDirectEntryDialog on Facility {
  id
  buildingName
  buildingWithUtilities {
    id
    completionIntervals(
      separateOnsiteCleanElectricity: true
      combineFuelKinds: true
      userUploadTaskId: $userUploadTaskId
    ) {
      ...CompletionIntervalFields
    }
  }
  periods {
    id
    startYearMonth
    endYearMonth
  }
  isWithoutRefrigerants
  startYearMonth
  endYearMonth
}
    ${CompletionIntervalFieldsFragmentDoc}`;
export const CustomColumnsForDirectEntryFragmentDoc = gql`
    fragment CustomColumnsForDirectEntry on IngestionCustomField {
  id
  name
  specificUtilityTypes
}
    `;
export const DirectEntryDialog_DatasetFragmentDoc = gql`
    fragment DirectEntryDialog_dataset on Dataset {
  id
  name
  datasources {
    id
  }
  canonicalDataset {
    id
    kind
    customerTargetSchemas {
      ...CustomerTargetSchemaForDataFormat
    }
  }
}
    ${CustomerTargetSchemaForDataFormatFragmentDoc}`;
export const ImportImport_UserUploadFragmentDoc = gql`
    fragment ImportImport_UserUpload on UserUpload {
  id
  name
  status
  userVisibleAttempt {
    id
    createdAt
    size
    userUploadedTables {
      id
      status
      sheetName
      sheetIndex
      errorType
      errorMessage
      ...UserUploadedTableWithOnlyDataPreviewFields
    }
  }
  ctsVersion {
    id
  }
}
    ${UserUploadedTableWithOnlyDataPreviewFieldsFragmentDoc}`;
export const UserUploadTaskStatusFragmentDoc = gql`
    fragment UserUploadTaskStatus on UserUploadTask {
  id
  state
  userUploads {
    edges {
      node {
        ...ImportImport_UserUpload
      }
    }
  }
}
    ${ImportImport_UserUploadFragmentDoc}`;
export const SharedFileForImportImportFragmentDoc = gql`
    fragment SharedFileForImportImport on SharedFile {
  id
  expiresAt
  fileMetadata {
    id
    name
    size
  }
}
    `;
export const CustomFieldForImporterPageFragmentDoc = gql`
    fragment CustomFieldForImporterPage on IngestionCustomField {
  id
  name
  type
  description
  bartTagName
  possibleValues
  specificDatasets {
    id
  }
  appliesToAllDatasets
}
    `;
export const OrganizationCustomFieldsForImporterPageFragmentDoc = gql`
    fragment OrganizationCustomFieldsForImporterPage on Organization {
  id
  ingestionCustomFields {
    ...CustomFieldForImporterPage
  }
}
    ${CustomFieldForImporterPageFragmentDoc}`;
export const UserUploadCandidateRowFieldsFragmentDoc = gql`
    fragment UserUploadCandidateRowFields on UserUploadCandidateRowFields {
  id
  data
  citation
}
    `;
export const UserUploadCandidateFieldsFragmentDoc = gql`
    fragment UserUploadCandidateFields on UserUploadCandidateEntry {
  ctsVersion {
    id
    schemaJson
  }
  candidateRows {
    ...UserUploadCandidateRowFields
  }
  id
}
    ${UserUploadCandidateRowFieldsFragmentDoc}`;
export const DocumentCandidateFieldsFragmentDoc = gql`
    fragment DocumentCandidateFields on DocumentCandidate {
  pdfUrl
  fileName
  id
}
    `;
export const UtilitiesDiffDialog_OrganizationFragmentDoc = gql`
    fragment UtilitiesDiffDialog_organization on Organization {
  id
  ingestionCustomFields {
    ...CustomFieldForBuildingsGridPage
  }
}
    ${CustomFieldForBuildingsGridPageFragmentDoc}`;
export const ImportFlowV3_CustomerTargetSchemaVersionFragmentDoc = gql`
    fragment ImportFlowV3_CustomerTargetSchemaVersion on CustomerTargetSchemaVersion {
  id
  versionNotesMd
  semverVersion
  orgDatasetExampleData {
    ...IngestionExampleDataFields
  }
  schema {
    id
    name
    utilityType
    rank
    canonicalDatasets {
      id
      instructions {
        sdiInstructionsFaq {
          question
          answerMd
        }
      }
    }
  }
}
    ${IngestionExampleDataFieldsFragmentDoc}`;
export const ValueMappingPageValueMappingRowFragmentDoc = gql`
    fragment ValueMappingPageValueMappingRow on ValueMappingRow {
  id
  baseRowJson
}
    `;
export const ValueMappingPageValueMappingFragmentDoc = gql`
    fragment ValueMappingPageValueMapping on ValueMapping {
  id
  revisionState
  valueMappingRows {
    id
    ...ValueMappingPageValueMappingRow
  }
}
    ${ValueMappingPageValueMappingRowFragmentDoc}`;
export const DataIssueWithCommentsFieldsFragmentDoc = gql`
    fragment DataIssueWithCommentsFields on DataIssue {
  id
  title
  description
  state
  createdAt
  isCustomerInitiated
  measurementProject {
    id
    completedAt
  }
  comments(isPublished: true) {
    edges {
      node {
        id
        person {
          id
          name
          displayName
          isWatershedEmployee
          isWatershedContractor
        }
        message
        isPublished
        createdAt
      }
    }
  }
}
    `;
export const ValueMappingPageValueMappingTaskFragmentDoc = gql`
    fragment ValueMappingPageValueMappingTask on ValueMappingTask {
  id
  issues(isPublished: true) {
    edges {
      node {
        ...DataIssueWithCommentsFields
      }
    }
  }
  publishStatus
}
    ${DataIssueWithCommentsFieldsFragmentDoc}`;
export const DatasourceValueMappingRuleFragmentDoc = gql`
    fragment DatasourceValueMappingRule on DatasourceValueMappingRule {
  id
  featureFlag
  valueMappingConfigId
  applicableDatasetKinds
  revisionData {
    id
    revisionState
  }
  valueMapping {
    ...ValueMappingPageValueMapping
  }
  valueMappingTask {
    ...ValueMappingPageValueMappingTask
  }
}
    ${ValueMappingPageValueMappingFragmentDoc}
${ValueMappingPageValueMappingTaskFragmentDoc}`;
export const DatasourceValueMappingCanonicalDatasetFragmentDoc = gql`
    fragment DatasourceValueMappingCanonicalDataset on CanonicalDataset {
  id
  kind
  name
}
    `;
export const DatasourceValueMappingMeasurementProjectFragmentDoc = gql`
    fragment DatasourceValueMappingMeasurementProject on MeasurementProject {
  id
  orgId
  datasetsWithStages {
    id
    dataset {
      id
      canonicalDataset {
        id
        ...DatasourceValueMappingCanonicalDataset
      }
    }
  }
  active
  valueMappingTask(valueMappingRuleId: $valueMappingRuleId) {
    id
    statusSimplified
    publishStatus
  }
}
    ${DatasourceValueMappingCanonicalDatasetFragmentDoc}`;
export const FinancialsReviewAccountDetailsFragmentDoc = gql`
    fragment FinancialsReviewAccountDetails on FinancialsAccount {
  displayAccountId
  description
}
    `;
export const FinancialsReviewItemInboxDetailsFragmentDoc = gql`
    fragment FinancialsReviewItemInboxDetails on FinancialsReviewItem {
  id
  account {
    ...FinancialsReviewAccountDetails
  }
  status
}
    ${FinancialsReviewAccountDetailsFragmentDoc}`;
export const IndustryClassificationDetailsFragmentDoc = gql`
    fragment IndustryClassificationDetails on IndustryClassification {
  id
  shortDescription
  longDescription
}
    `;
export const FinancialsReviewItemDetailsFragmentDoc = gql`
    fragment FinancialsReviewItemDetails on FinancialsReviewItem {
  id
  account {
    ...FinancialsReviewAccountDetails
  }
  status
  kind
  initialIndustryClassification {
    ...IndustryClassificationDetails
  }
  initiallyIncluded
  exclusionReason
  prompt
  userResponse
  askingEmployee {
    id
    name
  }
  discussion {
    ...DataIssueWithCommentsFields
  }
}
    ${FinancialsReviewAccountDetailsFragmentDoc}
${IndustryClassificationDetailsFragmentDoc}
${DataIssueWithCommentsFieldsFragmentDoc}`;
export const FinancialsReviewInboxItemFragmentDoc = gql`
    fragment FinancialsReviewInboxItem on FinancialsReviewItem {
  id
  account {
    displayAccountId
    description
  }
  status
  initialIndustryClassification {
    id
    shortDescription
  }
}
    `;
export const DashboardMeasurementTaskForOrgPageFragmentDoc = gql`
    fragment DashboardMeasurementTaskForOrgPage on MeasurementTask {
  id
  title
  link
  assignees {
    id
    name
  }
  isAssignedToWatershed
  statusSimplified
  object {
    __typename
    id
  }
}
    `;
export const DashboardMeasurementProjectFieldsForOrgPageFragmentDoc = gql`
    fragment DashboardMeasurementProjectFieldsForOrgPage on MeasurementProject {
  __typename
  id
  name
  active
  status
  completedAt
  coverageStartDate
  coverageEndDate
  coverageInterval
  deadline
  isApprovalFlowEnabled
  measurementTimeline {
    id
    kickoff
    deadline
  }
  measurementTasks {
    ...DashboardMeasurementTaskForOrgPage
    subTasks {
      ...DashboardMeasurementTaskForOrgPage
    }
    taskGroup
    dataset {
      id
      name
    }
  }
  userUploadTasks {
    edges {
      node {
        __typename
        id
        state
        datasource {
          id
          name
        }
        userUploads {
          edges {
            node {
              id
            }
          }
        }
        assignee {
          id
          name
        }
      }
    }
  }
  measurementProjectDatasets {
    id
    dataset {
      id
      name
      explainer
      customUsedFor
      customPotentialOverlaps
      canonicalDataset {
        id
        name
        kind
      }
      datasources {
        id
        name
        customUsedFor
        customPotentialOverlaps
        customerTargetSchemaIds
      }
    }
    isCompleted
  }
  supportedValueMappingRules
}
    ${DashboardMeasurementTaskForOrgPageFragmentDoc}`;
export const DataIssueForMeasurementPageFragmentDoc = gql`
    fragment DataIssueForMeasurementPage on DataIssue {
  id
  state
  title
}
    `;
export const UserUploadTaskForMeasurementPageFragmentDoc = gql`
    fragment UserUploadTaskForMeasurementPage on UserUploadTask {
  id
  datasource {
    id
    name
    userCanAccessDetails
    userCanApproveDatasource
    userCanManageDatasource
    isUserConfirmed
    dataset {
      ...DatasetForMeasurementPage
      canonicalDataset {
        id
        kind
      }
    }
    hasIntegration
  }
  issues(isPublished: true) {
    edges {
      node {
        ...DataIssueForMeasurementPage
      }
    }
  }
}
    ${DatasetForMeasurementPageFragmentDoc}
${DataIssueForMeasurementPageFragmentDoc}`;
export const MeasurementParentTaskForMeasurementPageFragmentDoc = gql`
    fragment MeasurementParentTaskForMeasurementPage on MeasurementParentTask {
  ...MeasurementTaskForMeasurementPage
  subTasks {
    ...MeasurementTaskForMeasurementPage
    taskGroup
    dataset {
      ...DatasetForMeasurementPage
    }
  }
  taskGroup
  dataset {
    ...DatasetForMeasurementPage
  }
}
    ${MeasurementTaskForMeasurementPageFragmentDoc}
${DatasetForMeasurementPageFragmentDoc}`;
export const MeasurementProjectForSendEmailsButtonFragmentDoc = gql`
    fragment MeasurementProjectForSendEmailsButton on MeasurementProject {
  id
  deadline
  active
}
    `;
export const ClimateProgramProjectForMeasurementPageFragmentDoc = gql`
    fragment ClimateProgramProjectForMeasurementPage on ClimateProgramProject {
  __typename
  id
  name
  canonicalClimateProgramProject {
    __typename
    id
    measurementObjectCopy
    kind
    routeType
    name
  }
}
    `;
export const MeasurementProjectForMeasurementPageFragmentDoc = gql`
    fragment MeasurementProjectForMeasurementPage on MeasurementProject {
  id
  name
  active
  setupAt
  completedAt
  footprintStatus
  coverageInterval
  userUploadTasks {
    edges {
      node {
        ...UserUploadTaskForMeasurementPage
      }
    }
  }
  measurementTimeline {
    ...MeasurementTimelineForMeasurementPage
  }
  measurementTasks {
    ...MeasurementParentTaskForMeasurementPage
  }
  datasetsWithStages {
    ...DatasetWithStageForMeasurementPage
  }
  deadline
  ...MeasurementProjectForSendEmailsButton
  climateProgramProjects {
    ...ClimateProgramProjectForMeasurementPage
  }
  isApprovalFlowEnabled
}
    ${UserUploadTaskForMeasurementPageFragmentDoc}
${MeasurementTimelineForMeasurementPageFragmentDoc}
${MeasurementParentTaskForMeasurementPageFragmentDoc}
${DatasetWithStageForMeasurementPageFragmentDoc}
${MeasurementProjectForSendEmailsButtonFragmentDoc}
${ClimateProgramProjectForMeasurementPageFragmentDoc}`;
export const UserFieldsForFacilitiesTasksFragmentDoc = gql`
    fragment UserFieldsForFacilitiesTasks on User {
  id
  name
  displayName
}
    `;
export const FacilitiesUserUploadTaskForTasksPageFragmentDoc = gql`
    fragment FacilitiesUserUploadTaskForTasksPage on UserUploadTask {
  id
  state
  assignees {
    ...UserFieldsForFacilitiesTasks
  }
  datasource {
    id
    name
  }
  measurementProject {
    id
    active
  }
  buildingIds
  approvers {
    approvalStatus
    user {
      ...UserFieldsForFacilitiesTasks
    }
  }
  facilitiesPreview {
    preview {
      buildingName
      id
    }
    totalCount
  }
  userUploads {
    edges {
      node {
        id
      }
    }
  }
  lockState
}
    ${UserFieldsForFacilitiesTasksFragmentDoc}`;
export const MeasurementProjectForConfigureFacilitiesTasksPageFragmentDoc = gql`
    fragment MeasurementProjectForConfigureFacilitiesTasksPage on MeasurementProject {
  ...MeasurementProjectForMeasurementPage
  userUploadTasks {
    edges {
      node {
        ...FacilitiesUserUploadTaskForTasksPage
      }
    }
  }
}
    ${MeasurementProjectForMeasurementPageFragmentDoc}
${FacilitiesUserUploadTaskForTasksPageFragmentDoc}`;
export const InstructionsBundleForImporterFragmentDoc = gql`
    fragment InstructionsBundleForImporter on InstructionsBundle {
  sdiInstructionsSteps {
    ...SDIInstructionsStepForImportInstructions
  }
  sdiInstructionsFaq {
    ...SDIInstructionsFaqForImportInstructions
  }
}
    ${SdiInstructionsStepForImportInstructionsFragmentDoc}
${SdiInstructionsFaqForImportInstructionsFragmentDoc}`;
export const FacilitiesMetadataForFacilitiesTasksFragmentDoc = gql`
    fragment FacilitiesMetadataForFacilitiesTasks on FacilitiesMetadata {
  buildingsDataset {
    id
    name
    ...DatasetForImporter
    instructions {
      ...InstructionsBundleForImporter
    }
  }
  utilitiesDataset {
    id
    name
  }
}
    ${DatasetForImporterFragmentDoc}
${InstructionsBundleForImporterFragmentDoc}`;
export const OrganizationForConfigureFacilitiesTasksPageFragmentDoc = gql`
    fragment OrganizationForConfigureFacilitiesTasksPage on Organization {
  id
  facilitiesMetadata {
    ...FacilitiesMetadataForFacilitiesTasks
  }
  ingestionCustomFields {
    ...CustomFieldForBuildingsGridPage
    ...CustomFieldForEditDialog
    bartTagName
    ingestionCustomFieldDatasources {
      id
    }
  }
}
    ${FacilitiesMetadataForFacilitiesTasksFragmentDoc}
${CustomFieldForBuildingsGridPageFragmentDoc}
${CustomFieldForEditDialogFragmentDoc}`;
export const FacilitiesForTasksPageFragmentDoc = gql`
    fragment FacilitiesForTasksPage on Facility {
  id
  ...CommonFacilitiesColumns
  editState
  tasks(measurementProjectId: $measurementProjectId) {
    id
    datasource {
      id
      name
      dataset {
        id
        name
        canonicalDataset {
          id
          kind
        }
      }
    }
  }
}
    ${CommonFacilitiesColumnsFragmentDoc}`;
export const OrgUnitTypeWithOrgUnitsForFacilitiesFragmentDoc = gql`
    fragment OrgUnitTypeWithOrgUnitsForFacilities on OrgUnitType {
  id
  name
  stableId
  orgUnits {
    id
    name
    stableId
  }
}
    `;
export const UserForFacilitiesImportFragmentDoc = gql`
    fragment UserForFacilitiesImport on User {
  id
  name
  displayName
  email
}
    `;
export const OrganizationFieldsForFacilitiesImportFragmentDoc = gql`
    fragment OrganizationFieldsForFacilitiesImport on Organization {
  id
  users {
    edges {
      node {
        ...UserForFacilitiesImport
      }
    }
  }
}
    ${UserForFacilitiesImportFragmentDoc}`;
export const FacilitiesUserUploadTaskFragmentDoc = gql`
    fragment FacilitiesUserUploadTask on UserUploadTask {
  id
  state
  assignees {
    ...UserFieldsForFacilitiesTasks
  }
  issues(isPublished: true) {
    edges {
      node {
        ...DataIssueWithCommentsFields
      }
    }
  }
  datasource {
    id
    name
    dataset {
      id
      canonicalDataset {
        id
        kind
      }
    }
    isApi
  }
  measurementProject {
    id
    active
    isApprovalFlowEnabled
  }
  buildingIds
  lockState
}
    ${UserFieldsForFacilitiesTasksFragmentDoc}
${DataIssueWithCommentsFieldsFragmentDoc}`;
export const MeasurementProjectForManageFacilitiesPageFragmentDoc = gql`
    fragment MeasurementProjectForManageFacilitiesPage on MeasurementProject {
  ...MeasurementProjectForMeasurementPage
  userUploadTasks {
    edges {
      node {
        ...FacilitiesUserUploadTask
      }
    }
  }
}
    ${MeasurementProjectForMeasurementPageFragmentDoc}
${FacilitiesUserUploadTaskFragmentDoc}`;
export const OrganizationCustomFieldsForManagePageFragmentDoc = gql`
    fragment OrganizationCustomFieldsForManagePage on Organization {
  id
  ingestionCustomFields {
    ...CustomFieldForBuildingsGridPage
    ...CustomFieldForEditDialog
    bartTagName
    ingestionCustomFieldDatasources {
      id
      numUserUploadTasks
    }
  }
  datasets {
    id
    ...DatasetFieldsForCustomFieldEditDialog
    ...DatasetForImporter
    instructions {
      ...InstructionsBundleForImporter
    }
  }
}
    ${CustomFieldForBuildingsGridPageFragmentDoc}
${CustomFieldForEditDialogFragmentDoc}
${DatasetFieldsForCustomFieldEditDialogFragmentDoc}
${DatasetForImporterFragmentDoc}
${InstructionsBundleForImporterFragmentDoc}`;
export const OrganizationFacilitiesUntypedFragmentDoc = gql`
    fragment OrganizationFacilitiesUntyped on Organization {
  id
  buildingsUntyped {
    id
    data
  }
}
    `;
export const OrganizationForCommonFacilitiesColumnsFragmentDoc = gql`
    fragment OrganizationForCommonFacilitiesColumns on Organization {
  id
  ingestionCustomFields {
    ...CustomFieldsForCommonFacilitiesColumns
  }
}
    ${CustomFieldsForCommonFacilitiesColumnsFragmentDoc}`;
export const OrgUnitTypesForCommonFacilitiesColumnsFragmentDoc = gql`
    fragment OrgUnitTypesForCommonFacilitiesColumns on OrgUnitType {
  id
  stableId
  name
  orgUnits {
    id
    name
    stableId
  }
}
    `;
export const SingleFacilityBuildingProperties_FacilityFragmentDoc = gql`
    fragment SingleFacilityBuildingProperties_facility on Facility {
  id
  createdAt
  updatedAt
  editState
  ...BuildingInfo
  periods {
    id
    ...BuildingPeriodInfo
  }
  supportingDocuments {
    ...BuildingFormSupportingDocument
  }
  lockedRevisionForMeasurementProject(measurementProjectId: $measurementProjectId) {
    id
    createdAt
    ...BuildingInfo
  }
  history {
    id
    revision
    createdAt
    ...BuildingInfo
  }
}
    ${BuildingInfoFragmentDoc}
${BuildingPeriodInfoFragmentDoc}
${BuildingFormSupportingDocumentFragmentDoc}`;
export const DatasetAndDatasetGroupsForGenerateFootprintFragmentDoc = gql`
    fragment DatasetAndDatasetGroupsForGenerateFootprint on FootprintDatasetOrDatasetGroup {
  id
  datasetGroupName
  dataset {
    id
    name
    canonicalDataset {
      id
      kind
    }
  }
}
    `;
export const FootprintConfigurationForGenerateFootprintFragmentDoc = gql`
    fragment FootprintConfigurationForGenerateFootprint on FootprintConfiguration {
  id
  methodologyConfig {
    id
    spendMethodology
    wasteEmissionsExclusions {
      wasteEmissionsMethodology
      shouldExclude
    }
  }
  measurementProjectDatasets {
    id
    measurementProject {
      id
      name
      createdAt
    }
    datasetsAndDatasetGroups {
      ...DatasetAndDatasetGroupsForGenerateFootprint
    }
  }
  exclusionRules {
    id
    stableId
    latestActiveRule {
      id
    }
  }
  categoryRules {
    id
    stableId
    latestActiveRule {
      id
    }
  }
  marketplaceAllocationInstructions {
    edges {
      node {
        id
      }
    }
  }
  releasesByBat {
    batName
    release {
      id
      updatedAt
    }
  }
}
    ${DatasetAndDatasetGroupsForGenerateFootprintFragmentDoc}`;
export const DatasetWithStageForGenerateFootprintFragmentDoc = gql`
    fragment DatasetWithStageForGenerateFootprint on DatasetWithStage {
  id
  dataset {
    id
    name
    canonicalDataset {
      id
      kind
    }
  }
  isUsableInFootprint
  hasUnsupportedBarts
  largeUserUploadedFiles
  isNonEmissive
  hasNoApprovedData
}
    `;
export const MeasurementProjectForGenerateFootprintFragmentDoc = gql`
    fragment MeasurementProjectForGenerateFootprint on MeasurementProject {
  id
  name
  createdAt
  datasetsWithStages {
    ...DatasetWithStageForGenerateFootprint
  }
  coverageInterval
}
    ${DatasetWithStageForGenerateFootprintFragmentDoc}`;
export const EmVersionFieldsForMethodologyInProductFragmentDoc = gql`
    fragment EMVersionFieldsForMethodologyInProduct on EmissionsModelVersion {
  id
  title
  stableId
  emissionsModelJson
  parameterResolverJson
  parameterResolver {
    id
  }
  referenceDataVersions {
    id
    slug
    latestSignedParquetOutputUrl
    latestSchema {
      id
      schemaJson
    }
    latestPublishedRevisionByVersionId {
      id
      revisionName
      internalSourceNotes
      signedParquetOutputUrl
    }
    source {
      id
      name
      externalSourceNotes
    }
  }
}
    `;
export const EmMatcherFieldsForMethodologyInProductFragmentDoc = gql`
    fragment EMMatcherFieldsForMethodologyInProduct on EmissionsModelMatcher {
  id
  businessActivityType
  filterExpression
  bindings
  quantitySpecifier
}
    `;
export const SupplierMappingV1AnalyticsFieldsFragmentDoc = gql`
    fragment SupplierMappingV1AnalyticsFields on SupplierMappingV1Analytics {
  mappedSupplierSpendUsd
  totalSupplierSpendUsd
}
    `;
export const VendorMatchingTaskFieldsFragmentDoc = gql`
    fragment VendorMatchingTaskFields on VendorMatchingTask {
  id
  measurementProject {
    id
    name
  }
}
    `;
export const CompanyForVendorMatchingFragmentDoc = gql`
    fragment CompanyForVendorMatching on Company {
  ...CompanyAutocompleteResult
  id
  name
  country
  url
  description
  naicsCode
  countryAlpha2
  sAndP {
    id
    sAndPId
  }
}
    ${CompanyAutocompleteResultFragmentDoc}`;
export const OrgCompanyForSupplierMappingFragmentDoc = gql`
    fragment OrgCompanyForSupplierMapping on OrgCompany {
  id
  name
  company {
    ...CompanyForVendorMatching
  }
  user {
    id
    name
    isWatershedEmployee
    isWatershedContractor
  }
  updatedAt
}
    ${CompanyForVendorMatchingFragmentDoc}`;
export const SupplierMappingTableSubRowFieldsFragmentDoc = gql`
    fragment SupplierMappingTableSubRowFields on SupplierMappingTableSubRow {
  id
  category
  subCategory
  currencyQuantity
  currency
  spendUsd
  datasetNames
}
    `;
export const CompanyAutoMatchFieldsFragmentDoc = gql`
    fragment CompanyAutoMatchFields on CompanyAutoMatch {
  id
  confidenceScore
  company {
    ...CompanyAutocompleteResult
    id
    name
    country
    naicsCode
    sAndP {
      id
      sAndPId
    }
  }
}
    ${CompanyAutocompleteResultFragmentDoc}`;
export const SupplierMappingTableRowFieldsFragmentDoc = gql`
    fragment SupplierMappingTableRowFields on SupplierMappingTableRow {
  id
  rawVendorEntity
  orgCompany {
    ...OrgCompanyForSupplierMapping
  }
  totalSpendUsd
  subRows {
    ...SupplierMappingTableSubRowFields
  }
  autoMatches {
    ...CompanyAutoMatchFields
  }
  autoMatched {
    confidenceScore
  }
  ranAutoMapping
  orgCompanyHasSupplierSpecificEF
}
    ${OrgCompanyForSupplierMappingFragmentDoc}
${SupplierMappingTableSubRowFieldsFragmentDoc}
${CompanyAutoMatchFieldsFragmentDoc}`;
export const SupplierMappingTableRowsPayloadFieldsFragmentDoc = gql`
    fragment SupplierMappingTableRowsPayloadFields on SupplierMappingTableRowsPayload {
  id
  totalNumVendorRows
  vendorRows {
    edges {
      node {
        ...SupplierMappingTableRowFields
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
  autoMappingButtonConditions {
    isDemoOrTestOrg
    noEligibleSuppliersByEmissions
  }
}
    ${SupplierMappingTableRowFieldsFragmentDoc}`;
export const OrganizationForOnboardingFlowFragmentDoc = gql`
    fragment OrganizationForOnboardingFlow on Organization {
  id
  name
  country
  fiscalYearStartMonth
}
    `;
export const OrgProfileForOnboardingFlowFragmentDoc = gql`
    fragment OrgProfileForOnboardingFlow on OrgProfile {
  id
  name
  fiscalYearStartMonth
  country
  industry
  isCompanyDataConfirmed
  hasMeasuredFootprintOutsideOfWatershed
  lastNonWatershedMeasurementYearString
  hasClimateCommitments
  climateCommitments
  otherClimateCommitment
  climateGoals
  climateMotivation
  completedAt
  completedBy {
    id
    name
  }
  updatedAt
  updatedBy {
    id
    name
  }
}
    `;
export const OrgUnitTypeRelationshipFragmentDoc = gql`
    fragment OrgUnitTypeRelationship on OrgUnitTypeRelationship {
  id
  orgUnitTypeIdFrom
  orgUnitTypeIdTo
  cardinality
  isRequired
  defaultOrgUnitId
}
    `;
export const RelatedOrgUnitTypeForOrgUnitDialogFragmentDoc = gql`
    fragment RelatedOrgUnitTypeForOrgUnitDialog on OrgUnitType {
  id
  name
  orgUnits {
    id
    name
  }
  relationship(relatedOrgUnitTypeId: $orgUnitTypeId) {
    ...OrgUnitTypeRelationship
  }
  relatedOrgUnits(relatedOrgUnitTypeId: $orgUnitTypeId) {
    id
    name
  }
}
    ${OrgUnitTypeRelationshipFragmentDoc}`;
export const OrgUnitTypeFieldsForOrgUnitDialogFragmentDoc = gql`
    fragment OrgUnitTypeFieldsForOrgUnitDialog on OrgUnitType {
  id
  name
  orgUnits {
    id
    name
    externalId
  }
  relatedOrgUnitTypes {
    ...RelatedOrgUnitTypeForOrgUnitDialog
  }
}
    ${RelatedOrgUnitTypeForOrgUnitDialogFragmentDoc}`;
export const OrgUnitTypeForOrgUnitGridFragmentDoc = gql`
    fragment OrgUnitTypeForOrgUnitGrid on OrgUnitType {
  id
  name
  orgUnits {
    id
    name
    externalId
  }
}
    `;
export const OrgUnitTypeForRelationshipDialogFragmentDoc = gql`
    fragment OrgUnitTypeForRelationshipDialog on OrgUnitType {
  ...OrgUnitTypeForOrgUnitGrid
  cyclesWhenFromNode(baseOrgUnitTypeId: $orgUnitTypeId)
  cyclesWhenToNode(baseOrgUnitTypeId: $orgUnitTypeId)
}
    ${OrgUnitTypeForOrgUnitGridFragmentDoc}`;
export const OrgStructureVersionForSidebarFragmentDoc = gql`
    fragment OrgStructureVersionForSidebar on OrgStructureVersion {
  id
  name
  isPublished
  effectiveFromDate
  effectiveToDate
}
    `;
export const OrgUnitForGridFragmentDoc = gql`
    fragment OrgUnitForGrid on OrgUnit {
  id
  name
  externalId
  description
  orgUnitTypeId
  relatedOrgUnits {
    id
    name
    orgUnitTypeId
  }
  orgUnitDatasources {
    id
    datasourceId
    orgUnitTypeStableId
    orgUnitStableId
    createdAt
    updatedAt
  }
}
    `;
export const OrgUnitTypeForExplorerFragmentDoc = gql`
    fragment OrgUnitTypeForExplorer on OrgUnitType {
  id
  name
  externalId
  isLegalEntity
  orgUnits {
    id
    name
    externalId
    description
    relatedOrgUnits {
      id
      name
      description
    }
  }
}
    `;
export const FootprintAnalysisTopVendorFieldsFragmentDoc = gql`
    fragment FootprintAnalysisTopVendorFields on FootprintAnalysisTopVendor {
  proportion
  series {
    data {
      date
      value
    }
  }
  vendorInfo {
    vendor
    program
    programUrl
    targetYear
    sbtTarget
  }
}
    `;
export const UserPermissionRequestFieldsFragmentDoc = gql`
    fragment UserPermissionRequestFields on UserPermissionRequest {
  id
  orgId
  name
  email
  state
  userId
  responderUser
  respondedAt
  creatorOrgId
  creatorUser
  permission
}
    `;
export const VendorFieldsFragmentDoc = gql`
    fragment VendorFields on Vendor {
  vendor
  program
  targetYear
  sbtTarget
}
    `;
export const HistoricalUserInputtedGrowthFactorDataFieldsFragmentDoc = gql`
    fragment HistoricalUserInputtedGrowthFactorDataFields on HistoricalUserInputtedGrowthFactorDataPoint {
  date
  growthFactors {
    growthFactorType
    customIntensityConfigId
    value
  }
}
    `;
export const SimpleTimeseriesFieldsFragmentDoc = gql`
    fragment SimpleTimeseriesFields on SimpleTimeseries {
  base
  frequency
  values
}
    `;
export const GrowthForecastFieldsFragmentDoc = gql`
    fragment GrowthForecastFields on GrowthForecast {
  id
  forecast {
    ...SimpleTimeseriesFields
  }
  growthFactorType
  customIntensityConfigId
  scalingFactorFilters {
    conjunction
    expressions {
      field
      operator
      value
    }
  }
  isDefaultScalingFactor
  hasUserInputtedHistoricalPeriod
}
    ${SimpleTimeseriesFieldsFragmentDoc}`;
export const ForecastScenarioFieldsFragmentDoc = gql`
    fragment ForecastScenarioFields on ForecastScenario {
  id
  name
  description
  isDefault
  updatedByName
  updatedAt
  historicalUserInputtedGrowthFactorDataPoints {
    ...HistoricalUserInputtedGrowthFactorDataFields
  }
  growthForecasts {
    ...GrowthForecastFields
  }
}
    ${HistoricalUserInputtedGrowthFactorDataFieldsFragmentDoc}
${GrowthForecastFieldsFragmentDoc}`;
export const ForecastPeriodFieldsFragmentDoc = gql`
    fragment ForecastPeriodFields on ForecastPeriod {
  interval
  data {
    date
    headcount
    revenue
    customGrowthFactors {
      customIntensityId
      value
    }
  }
}
    `;
export const CustomIntensityConfigFieldsFragmentDoc = gql`
    fragment CustomIntensityConfigFields on CustomIntensityConfig {
  id
  name
  description
  unit
  humanReadable
  quantityType
  isSbtValidated
}
    `;
export const ForecastFieldsForPlanFragmentDoc = gql`
    fragment ForecastFieldsForPlan on Forecast {
  id
  interval
  scenarios {
    ...ForecastScenarioFields
  }
  referencePeriodInterval
  historicalPeriod {
    ...ForecastPeriodFields
  }
  overrideFootprintKind
  validReferenceInterval
  customIntensityConfigs {
    ...CustomIntensityConfigFields
  }
  footprintSnapshotId
  footprintInterval
  businessCategories {
    businessCategory
  }
}
    ${ForecastScenarioFieldsFragmentDoc}
${ForecastPeriodFieldsFragmentDoc}
${CustomIntensityConfigFieldsFragmentDoc}`;
export const PlanVariablesFieldsFragmentDoc = gql`
    fragment PlanVariablesFields on PlanVariables {
  commitmentsHasNetZero
  commitmentsNetZeroYear
  cleanPowerStart
  commitmentsHasSBT
  commitmentsSBTTargetYear
  commitmentsSBTScope12
  commitmentsSBTScope3
  commitmentsSBTTermLength
  commitmentsSBTSubmissionDate
  flightsPerEmployeeForecastEnd
  percentWorkFromHomeForecastEnd
  netZeroSuppliersReductionPercent
}
    `;
export const AllSimpleTimeseriesFieldsFragmentDoc = gql`
    fragment AllSimpleTimeseriesFields on SimpleTimeseries {
  id
  base
  frequency
  values
}
    `;
export const CommonInitiativeFieldsFragmentDoc = gql`
    fragment CommonInitiativeFields on Initiative {
  __typename
  id
  name
  enabled
  costTimeseries {
    ...AllSimpleTimeseriesFields
  }
  description
  filters {
    expressions {
      field
      value
      operator
    }
    conjunction
  }
  scheduleType
  deletedAt
  createdAt
}
    ${AllSimpleTimeseriesFieldsFragmentDoc}`;
export const FootprintSliceInitiativeFieldsFragmentDoc = gql`
    fragment FootprintSliceInitiativeFields on FootprintSliceInitiative {
  ...CommonInitiativeFields
  reductionTimeseries {
    ...AllSimpleTimeseriesFields
  }
}
    ${CommonInitiativeFieldsFragmentDoc}
${AllSimpleTimeseriesFieldsFragmentDoc}`;
export const PrecomputedActivityBasedInitiativeFieldsFragmentDoc = gql`
    fragment PrecomputedActivityBasedInitiativeFields on PrecomputedActivityBasedInitiative {
  ...CommonInitiativeFields
  implementationTimeseries {
    ...AllSimpleTimeseriesFields
  }
  precomputedLever: lever
}
    ${CommonInitiativeFieldsFragmentDoc}
${AllSimpleTimeseriesFieldsFragmentDoc}`;
export const ReduxInitiativeFieldsFragmentDoc = gql`
    fragment ReduxInitiativeFields on Initiative {
  ... on FootprintSliceInitiative {
    ...FootprintSliceInitiativeFields
  }
  ... on PrecomputedActivityBasedInitiative {
    ...PrecomputedActivityBasedInitiativeFields
  }
}
    ${FootprintSliceInitiativeFieldsFragmentDoc}
${PrecomputedActivityBasedInitiativeFieldsFragmentDoc}`;
export const CommonPlanTargetFieldsFragmentDoc = gql`
    fragment CommonPlanTargetFields on PlanTarget {
  id
  createdAt
  description
  reportDescription
  customNotes
  emissionsTargetCustom {
    ...SimpleTimeseriesFields
  }
  baseYear
  intensityType
  customIntensityConfigId
  comparisonType
  reductionRate
  filters {
    conjunction
    expressions {
      field
      operator
      value
    }
  }
  deletedAt
  assignedTo
  parentTargetId
  interimTargetDate
  interimTargetValue
}
    ${SimpleTimeseriesFieldsFragmentDoc}`;
export const PlanTargetFieldsFragmentDoc = gql`
    fragment PlanTargetFields on PlanTarget {
  ...CommonPlanTargetFields
}
    ${CommonPlanTargetFieldsFragmentDoc}`;
export const PlanWithForecastFieldsFragmentDoc = gql`
    fragment PlanWithForecastFields on Plan {
  id
  name
  createdAt
  updatedAt
  updatedByName
  state
  baselineYearStart
  forecast {
    ...ForecastFieldsForPlan
  }
  variables {
    ...PlanVariablesFields
  }
  reduxInitiatives {
    ...ReduxInitiativeFields
  }
  targets {
    ...PlanTargetFields
  }
  reduxCarbonOffsets {
    ...SimpleTimeseriesFields
  }
  reduxCarbonOffsetsCosts {
    ...SimpleTimeseriesFields
  }
  reduxCarbonRemoval {
    ...SimpleTimeseriesFields
  }
  reduxCarbonRemovalCosts {
    ...SimpleTimeseriesFields
  }
  fiscalMonth
  isSbtValidated
  hideCarbonAvoidance
}
    ${ForecastFieldsForPlanFragmentDoc}
${PlanVariablesFieldsFragmentDoc}
${ReduxInitiativeFieldsFragmentDoc}
${PlanTargetFieldsFragmentDoc}
${SimpleTimeseriesFieldsFragmentDoc}`;
export const SbtiTargetExclusionFieldsFragmentDoc = gql`
    fragment SbtiTargetExclusionFields on SbtiTargetExclusion {
  id
  filters {
    conjunction
    expressions {
      field
      operator
      value
    }
  }
}
    `;
export const PeerCommitmentFieldsFragmentDoc = gql`
    fragment PeerCommitmentFields on CompanyClimateCommitment {
  __typename
  id
  description
  ... on ScienceBasedTargetCommitment {
    commitment {
      id
      nearTermTargetYear
      longTermTargetYear
      baselineYear
      stage
      targetClassification
    }
  }
}
    `;
export const TargetAndInitiativeForecastFieldsFragmentDoc = gql`
    fragment TargetAndInitiativeForecastFields on Plan {
  id
  targets {
    ...PlanTargetFields
  }
  reduxInitiatives {
    ...ReduxInitiativeFields
  }
}
    ${PlanTargetFieldsFragmentDoc}
${ReduxInitiativeFieldsFragmentDoc}`;
export const ForecastScenarioForScenarioSelectorFragmentDoc = gql`
    fragment ForecastScenarioForScenarioSelector on ForecastScenario {
  id
  name
  isDefault
}
    `;
export const ForecastForScenarioSelectorFragmentDoc = gql`
    fragment ForecastForScenarioSelector on Forecast {
  id
  footprintSnapshotId
  footprintInterval
  footprintKindsMeta {
    id
    footprintKind
    name
    description
    footprintInterval
  }
  acknowledgedFootprintSnapshotId
  scenarios {
    ...ForecastScenarioForScenarioSelector
  }
}
    ${ForecastScenarioForScenarioSelectorFragmentDoc}`;
export const AssigneeInfoForContainedQuestionsFragmentDoc = gql`
    fragment AssigneeInfoForContainedQuestions on ReportQuestionOrContainer {
  __typename
  ... on ReportQuestion {
    id
    assignees(reportId: $reportId) {
      id
      displayName
      name
    }
  }
  ... on ReportQuestionContainer {
    id
    items {
      __typename
      ... on ReportQuestion {
        id
        assignees(reportId: $reportId) {
          id
          displayName
          name
        }
      }
      ... on ReportQuestionContainer {
        id
        items {
          __typename
          ... on ReportQuestion {
            id
            assignees(reportId: $reportId) {
              id
              displayName
              name
            }
          }
          ... on ReportQuestionContainer {
            id
            items {
              __typename
              ... on ReportQuestion {
                id
                assignees(reportId: $reportId) {
                  id
                  displayName
                  name
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const AssigneesAvatarGroup_AssigneesFragmentDoc = gql`
    fragment AssigneesAvatarGroup_assignees on User {
  id
  displayName
}
    `;
export const ReportPageReportFragmentDoc = gql`
    fragment ReportPageReport on Report {
  __typename
  id
  name
  reportStatus
  reportKind
  warnings {
    id
    outputJson
    reportId
    validatorId
    acknowledgedBy {
      id
    }
  }
  config {
    id
    reportType
    shortName
    longName
    requiredPermissions
    createdAt
  }
  createdAt
  updatedAt
  deletedAt
  editedAt
}
    `;
export const ReportFileAttachmentItemFieldsFragmentDoc = gql`
    fragment ReportFileAttachmentItemFields on ReportAttachmentItem {
  authorId
  createdAt
  fmdId
  id
  kind
  name
  orgId
  updatedAt
  url
}
    `;
export const ReportQuestionUsingSavedViewFieldsFragmentDoc = gql`
    fragment ReportQuestionUsingSavedViewFields on ReportQuestion {
  id
  componentId
  isComputed
}
    `;
export const ReportExportButton_ReportFragmentDoc = gql`
    fragment ReportExportButton_report on Report {
  id
  name
  config {
    id
    reportType
    reportMetadataComputedItems: computedItems(
      filter: {componentId: "ReportMetadata"}
    ) {
      id
      resolvedStatus(reportId: $reportId) {
        id
        status
        blockingMessage
      }
      answer(reportId: $reportId) {
        id
        outputJson
      }
    }
  }
}
    `;
export const GuidanceItemComponent_ReportFragmentDoc = gql`
    fragment GuidanceItemComponent_report on Report {
  id
  config {
    shortName
    reportType
  }
}
    `;
export const NewAssigneeSelector_AssigneesFragmentDoc = gql`
    fragment NewAssigneeSelector_assignees on User {
  id
  displayName
}
    `;
export const ReportQuestionAssigneeSelector_ReportQuestionFragmentDoc = gql`
    fragment ReportQuestionAssigneeSelector_reportQuestion on ReportQuestion {
  id
  instance(reportId: $reportId) {
    id
    title
  }
  assignees(reportId: $reportId) {
    ...NewAssigneeSelector_assignees
  }
}
    ${NewAssigneeSelector_AssigneesFragmentDoc}`;
export const ReportQuestionFieldsFragmentDoc = gql`
    fragment ReportQuestionFields on ReportQuestion {
  id
  identifier
  identifierId
  componentId
  isComputed
  guidanceJson
}
    `;
export const ReportAnswerWarningForCardFragmentDoc = gql`
    fragment ReportAnswerWarningForCard on ReportAnswerWarning {
  id
  outputJson
  reportId
  acknowledgedBy {
    id
  }
  answer {
    id
    question {
      id
      isComputed
      identifier
      parentContainer {
        id
        label
      }
    }
  }
  validatorId
}
    `;
export const ResolvedReportQuestionInputsFailureForReportItemFragmentDoc = gql`
    fragment ResolvedReportQuestionInputsFailureForReportItem on ResolvedReportQuestionInputsFailure {
  __typename
  errorMessage
  questionIds
}
    `;
export const ItemWithQuestionComponent_AnswerFragmentDoc = gql`
    fragment ItemWithQuestionComponent_answer on ReportAnswer {
  id
  updatedAt
  outputJson
  editorName
}
    `;
export const ItemWithQuestionComponent_AnswerOverrideFragmentDoc = gql`
    fragment ItemWithQuestionComponent_answerOverride on ReportAnswerOverride {
  id
  updatedAt
  outputJson
  editorName
}
    `;
export const ReportQuestionForReportItemFieldsFragmentDoc = gql`
    fragment ReportQuestionForReportItemFields on ReportQuestion {
  __typename
  ...ReportQuestionAssigneeSelector_reportQuestion
  ...ReportQuestionFields
  parentContainer {
    id
    label
  }
  resolvedWarnings(reportId: $reportId) {
    ...ReportAnswerWarningForCard
  }
  resolvedInputs(reportId: $reportId) {
    ...ResolvedReportQuestionInputsFailureForReportItem
    ... on ResolvedReportQuestionInputsSuccess {
      __typename
      inputs
      unparsedInputs
    }
  }
  answer(reportId: $reportId) {
    ...ItemWithQuestionComponent_answer
  }
  overrideState(reportId: $reportId)
  answerOverride(reportId: $reportId) {
    ...ItemWithQuestionComponent_answerOverride
  }
  overrideStateHistory(reportId: $reportId, first: 1) {
    edges {
      node {
        id
        createdAt
        createdBy {
          id
          displayName
        }
        overrideState
      }
    }
  }
  resolvedStatus(reportId: $reportId) {
    id
    status
    blockingMessage
  }
  allowNotes
  allowedAttachmentKinds
  assignees(reportId: $reportId) {
    id
    displayName
    name
  }
}
    ${ReportQuestionAssigneeSelector_ReportQuestionFragmentDoc}
${ReportQuestionFieldsFragmentDoc}
${ReportAnswerWarningForCardFragmentDoc}
${ResolvedReportQuestionInputsFailureForReportItemFragmentDoc}
${ItemWithQuestionComponent_AnswerFragmentDoc}
${ItemWithQuestionComponent_AnswerOverrideFragmentDoc}`;
export const ReportQuestionContainerStatusFieldsFragmentDoc = gql`
    fragment ReportQuestionContainerStatusFields on ReportQuestionContainer {
  __typename
  id
  label
  resolvedStatus(reportId: $reportId) {
    id
    status
    blockingMessage
    excludingQuestionId
  }
}
    `;
export const ReportConfigFieldsFragmentDoc = gql`
    fragment ReportConfigFields on ReportConfig {
  id
  shortName
  longName
  description
  brandColor
  status
  reportType
  updatedAt
  useTableDisplay
  useMateriality
  useReportingIntention
  useOverrides
  isFinanceReport
  allVisibleInputs(reportId: $reportId) {
    id
    resolvedInputs(reportId: $reportId) {
      __typename
      ... on ResolvedReportQuestionInputsFailure {
        questionIds
        errorMessage
      }
      ... on ResolvedReportQuestionInputsSuccess {
        inputs
        unparsedInputs
      }
    }
    answer(reportId: $reportId) {
      id
      outputJson
    }
    resolvedStatus(reportId: $reportId) {
      blockingMessage
      excludingQuestionId
      id
      status
    }
  }
  allVisibleItems(reportId: $reportId) {
    __typename
    ... on ReportQuestion {
      ...ReportQuestionForReportItemFields
    }
    ... on ReportQuestionContainer {
      ...ReportQuestionContainerStatusFields
      id
      label
      description
      guidanceJson
      allVisibleQuestions(reportId: $reportId) {
        id
        ...ReportQuestionForReportItemFields
      }
    }
  }
}
    ${ReportQuestionForReportItemFieldsFragmentDoc}
${ReportQuestionContainerStatusFieldsFragmentDoc}`;
export const WarningItem_ReportAnswerWarningFragmentDoc = gql`
    fragment WarningItem_reportAnswerWarning on ReportAnswerWarning {
  id
  outputJson
  reportId
  acknowledgedBy {
    id
  }
  answer {
    id
    question {
      id
      isComputed
      identifier
      parentContainer {
        id
        label
      }
    }
  }
  validatorId
}
    `;
export const WarningItem_QuestionWarnings_ReportFragmentDoc = gql`
    fragment WarningItem_QuestionWarnings_report on ReportQuestion {
  id
  resolvedInputs(reportId: $reportId) {
    __typename
  }
  resolvedWarnings(reportId: $reportId) {
    ...WarningItem_reportAnswerWarning
    id
    acknowledgedBy {
      id
    }
  }
}
    ${WarningItem_ReportAnswerWarningFragmentDoc}`;
export const ReportQuestionDropdownMenu_QuestionFragmentDoc = gql`
    fragment ReportQuestionDropdownMenu_question on ReportQuestion {
  id
  componentId
  overrideState(reportId: $reportId)
  resolvedReportingIntention(reportId: $reportId) {
    id
    intention
  }
  instance(reportId: $reportId) {
    id
    title
    subtitle
  }
}
    `;
export const ReportQuestionFieldsWithReportingIntentionFragmentDoc = gql`
    fragment ReportQuestionFieldsWithReportingIntention on ReportQuestion {
  ...WarningItem_QuestionWarnings_report
  ...ReportQuestionForReportItemFields
  ...ReportQuestionDropdownMenu_question
  resolvedReportingIntention(reportId: $reportId) {
    id
    intention
    event {
      createdBy {
        id
        displayName
      }
      createdAt
      reason
    }
  }
}
    ${WarningItem_QuestionWarnings_ReportFragmentDoc}
${ReportQuestionForReportItemFieldsFragmentDoc}
${ReportQuestionDropdownMenu_QuestionFragmentDoc}`;
export const ReportQuestionNoteFieldsFragmentDoc = gql`
    fragment ReportQuestionNoteFields on Discussion {
  id
  title
  description
  state
  comments {
    edges {
      node {
        message
        id
        createdAt
        isPublished
        person {
          id
          displayName
          name
        }
      }
    }
  }
}
    `;
export const ReportQuestionItemFields_NotesFragmentDoc = gql`
    fragment ReportQuestionItemFields_notes on ReportQuestion {
  id
  ... @defer {
    notes(reportId: $reportId) {
      ...ReportQuestionNoteFields
    }
  }
}
    ${ReportQuestionNoteFieldsFragmentDoc}`;
export const ApproverSelector_ApproversFragmentDoc = gql`
    fragment ApproverSelector_approvers on ReportQuestionInstanceApprover {
  id
  user {
    id
    displayName
  }
}
    `;
export const QuestionApprovalResponseBanner_ApproverFragmentDoc = gql`
    fragment QuestionApprovalResponseBanner_approver on ReportQuestionInstanceApprover {
  id
  status
  comment
  user {
    id
    displayName
  }
}
    `;
export const QuestionApprovalSummary_ApprovalInfoFragmentDoc = gql`
    fragment QuestionApprovalSummary_approvalInfo on ReportQuestionInstanceApprovalInfo {
  id
  status
  currentUserAction
  currentUserApprovalRequestStatus
  approvers {
    ...ApproverSelector_approvers
  }
}
    ${ApproverSelector_ApproversFragmentDoc}`;
export const QuestionApprovalInfoFragmentDoc = gql`
    fragment QuestionApprovalInfo on ReportQuestionInstanceApprovalInfo {
  id
  status
  currentUserAction
  isLocked
  approvers {
    ...ApproverSelector_approvers
    ...QuestionApprovalResponseBanner_approver
  }
  ...QuestionApprovalSummary_approvalInfo
}
    ${ApproverSelector_ApproversFragmentDoc}
${QuestionApprovalResponseBanner_ApproverFragmentDoc}
${QuestionApprovalSummary_ApprovalInfoFragmentDoc}`;
export const ReportAttachmentForListFragmentDoc = gql`
    fragment ReportAttachmentForList on ReportAttachmentItem {
  id
  name
  kind
  url
  fmdId
  authorId
  createdAt
  updatedAt
}
    `;
export const ReportQuestionInstanceWithDeferredAttachmentItemsFragmentDoc = gql`
    fragment ReportQuestionInstanceWithDeferredAttachmentItems on ReportQuestionInstance {
  ... @defer {
    attachmentItems {
      ...ReportAttachmentForList
    }
  }
}
    ${ReportAttachmentForListFragmentDoc}`;
export const ReportItem_QuestionFragmentDoc = gql`
    fragment ReportItem_question on ReportQuestion {
  ...ReportQuestionFieldsWithReportingIntention
  ...ReportQuestionItemFields_notes
  id
  resolvedStatus(reportId: $reportId) {
    id
    status
  }
  instance(reportId: $reportId) {
    id
    title
    subtitle
    canAnswer
    canAssign
    canSetApprovers
    approvalInfo {
      id
      ...QuestionApprovalInfo
    }
    ...ReportQuestionInstanceWithDeferredAttachmentItems
  }
}
    ${ReportQuestionFieldsWithReportingIntentionFragmentDoc}
${ReportQuestionItemFields_NotesFragmentDoc}
${QuestionApprovalInfoFragmentDoc}
${ReportQuestionInstanceWithDeferredAttachmentItemsFragmentDoc}`;
export const CustomReport_ItemsFragmentDoc = gql`
    fragment CustomReport_items on ReportQuestionOrContainer {
  ... on ReportQuestion {
    id
    ...ReportItem_question
  }
  ... on ReportQuestionContainer {
    id
    allVisibleQuestions(reportId: $reportId) {
      id
      ...ReportItem_question
    }
  }
}
    ${ReportItem_QuestionFragmentDoc}`;
export const EditReportPage_ReportWithoutContainers_QuestionsFragmentDoc = gql`
    fragment EditReportPage_ReportWithoutContainers_questions on ReportQuestion {
  ...ReportItem_question
  id
}
    ${ReportItem_QuestionFragmentDoc}`;
export const EditReportPage_ReportWithContainers_QuestionsFragmentDoc = gql`
    fragment EditReportPage_ReportWithContainers_questions on ReportQuestion {
  ...ReportItem_question
  id
}
    ${ReportItem_QuestionFragmentDoc}`;
export const ReportWithConfigFieldsFragmentDoc = gql`
    fragment ReportWithConfigFields on Report {
  ...ReportExportButton_report
  ...GuidanceItemComponent_report
  id
  name
  editedAt
  cdpApiRegistration {
    id
    isReportRegistered
  }
  latestCdpSyncWorkflowStatus {
    id
    workflowId
    questions {
      id
      isSuccess
    }
  }
  config {
    ...ReportConfigFields
    allVisibleItems(reportId: $reportId) {
      ...CustomReport_items
    }
    allVisibleInputs(reportId: $reportId) {
      id
      ...ReportItem_question
      ...EditReportPage_ReportWithoutContainers_questions
      ...EditReportPage_ReportWithContainers_questions
    }
  }
}
    ${ReportExportButton_ReportFragmentDoc}
${GuidanceItemComponent_ReportFragmentDoc}
${ReportConfigFieldsFragmentDoc}
${CustomReport_ItemsFragmentDoc}
${ReportItem_QuestionFragmentDoc}
${EditReportPage_ReportWithoutContainers_QuestionsFragmentDoc}
${EditReportPage_ReportWithContainers_QuestionsFragmentDoc}`;
export const ReportItem_ReportFragmentDoc = gql`
    fragment ReportItem_report on Report {
  ...ReportWithConfigFields
}
    ${ReportWithConfigFieldsFragmentDoc}`;
export const EditReportPage_ReportWithoutContainers_ReportFragmentDoc = gql`
    fragment EditReportPage_ReportWithoutContainers_report on Report {
  ...ReportItem_report
}
    ${ReportItem_ReportFragmentDoc}`;
export const CdpSyncRegistrationDialog_ReportFragmentDoc = gql`
    fragment CdpSyncRegistrationDialog_report on Report {
  ...ReportItem_report
}
    ${ReportItem_ReportFragmentDoc}`;
export const CdpSyncRegistrationDialog_ReportQuestionFragmentDoc = gql`
    fragment CdpSyncRegistrationDialog_reportQuestion on ReportQuestion {
  ...ReportItem_question
}
    ${ReportItem_QuestionFragmentDoc}`;
export const CdpSyncButton_ReportFragmentDoc = gql`
    fragment CdpSyncButton_report on Report {
  ...CdpSyncRegistrationDialog_report
  id
  cdpApiRegistration {
    isReportRegistered
  }
  latestCdpSyncWorkflowStatus {
    id
    workflowId
    questions {
      id
      isSuccess
    }
  }
  config {
    allVisibleInputs(reportId: $reportId) {
      ...CdpSyncRegistrationDialog_reportQuestion
      id
      identifier
    }
  }
}
    ${CdpSyncRegistrationDialog_ReportFragmentDoc}
${CdpSyncRegistrationDialog_ReportQuestionFragmentDoc}`;
export const CdpContainerSyncButton_ReportFragmentDoc = gql`
    fragment CdpContainerSyncButton_report on Report {
  ...CdpSyncButton_report
  id
}
    ${CdpSyncButton_ReportFragmentDoc}`;
export const ReportQuestionContainerSheet_ReportFragmentDoc = gql`
    fragment ReportQuestionContainerSheet_report on Report {
  ...CdpContainerSyncButton_report
  id
  config {
    id
    shortName
    status
  }
}
    ${CdpContainerSyncButton_ReportFragmentDoc}`;
export const ReportQuestionContainerAssigneeSelector_ReportQuestionContainerFragmentDoc = gql`
    fragment ReportQuestionContainerAssigneeSelector_reportQuestionContainer on ReportQuestionContainer {
  id
  allVisibleQuestions(reportId: $reportId) {
    id
    ...ReportQuestionAssigneeSelector_reportQuestion
  }
  assignees(reportId: $reportId) {
    ...NewAssigneeSelector_assignees
  }
}
    ${ReportQuestionAssigneeSelector_ReportQuestionFragmentDoc}
${NewAssigneeSelector_AssigneesFragmentDoc}`;
export const ReportQuestionContainerCard_ContainerAssigneeSelectorContents_ReportQuestionContainerFragmentDoc = gql`
    fragment ReportQuestionContainerCard_ContainerAssigneeSelectorContents_reportQuestionContainer on ReportQuestionContainer {
  id
  ... @defer {
    ...ReportQuestionContainerAssigneeSelector_reportQuestionContainer
    assignees(reportId: $reportId) {
      id
      displayName
      name
    }
  }
}
    ${ReportQuestionContainerAssigneeSelector_ReportQuestionContainerFragmentDoc}`;
export const ReportQuestionContainerCard_ProgressSelector_ReportQuestionContainerFragmentDoc = gql`
    fragment ReportQuestionContainerCard_ProgressSelector_reportQuestionContainer on ReportQuestionContainer {
  id
  automaticProgress(reportId: $reportId)
  overriddenProgress(reportId: $reportId)
}
    `;
export const ReportQuestionContainerCard_ReportFragmentDoc = gql`
    fragment ReportQuestionContainerCard_report on Report {
  ...GuidanceItemComponent_report
  id
  name
  config {
    id
    shortName
    allVisibleItems(reportId: $reportId) {
      __typename
      ... on ReportQuestionContainer {
        ...ReportQuestionContainerCard_ContainerAssigneeSelectorContents_reportQuestionContainer
        id
        label
        description
        guidanceJson
        ... @defer {
          ...ReportQuestionContainerCard_ProgressSelector_reportQuestionContainer
          resolvedStatus(reportId: $reportId) {
            id
            status
            blockingMessage
          }
          automaticProgress(reportId: $reportId)
          overriddenProgress(reportId: $reportId)
          questionsWithResolvedWarnings(reportId: $reportId) {
            id
            resolvedWarnings(reportId: $reportId) {
              id
              ...WarningItem_reportAnswerWarning
            }
          }
        }
      }
    }
  }
}
    ${GuidanceItemComponent_ReportFragmentDoc}
${ReportQuestionContainerCard_ContainerAssigneeSelectorContents_ReportQuestionContainerFragmentDoc}
${ReportQuestionContainerCard_ProgressSelector_ReportQuestionContainerFragmentDoc}
${WarningItem_ReportAnswerWarningFragmentDoc}`;
export const EditReportPage_ReportWithContainers_ReportFragmentDoc = gql`
    fragment EditReportPage_ReportWithContainers_report on Report {
  ...ReportQuestionContainerSheet_report
  ...ReportQuestionContainerCard_report
  ...ReportItem_report
}
    ${ReportQuestionContainerSheet_ReportFragmentDoc}
${ReportQuestionContainerCard_ReportFragmentDoc}
${ReportItem_ReportFragmentDoc}`;
export const ReportConfigQuestionForTableFragmentDoc = gql`
    fragment ReportConfigQuestionForTable on ReportQuestion {
  id
  identifier
  displayName
  materiality(reportId: $reportId)
  overrideState(reportId: $reportId)
  resolvedReportingIntention(reportId: $reportId) {
    id
    intention
  }
  assignees(reportId: $reportId) {
    id
    name
    displayName
  }
  answer(reportId: $reportId) {
    latestCdpSyncEvent {
      id
      syncTime
      isSuccess
      reportQuestionId
      actor {
        name
        displayName
        id
      }
      failureKind
      failureDetails
    }
    id
    updatedAt
  }
  instance(reportId: $reportId) {
    id
    name
    title
    subtitle
    approvalInfo {
      id
      status
      approvers {
        id
        user {
          id
          displayName
          name
        }
      }
    }
    canAssign
    canSetApprovers
    lastUpdatedAt
  }
  identifier
  componentId
  resolvedInputs(reportId: $reportId) {
    ... on ResolvedReportQuestionInputsFailure {
      errorMessage
      questionIds
    }
  }
  path {
    id
    label
  }
  resolvedStatusIncludingContainer(reportId: $reportId) {
    id
    status
    blockingMessage
  }
}
    `;
export const ReportWithConfigForTableFragmentDoc = gql`
    fragment ReportWithConfigForTable on ReportWithConfig {
  id
  report {
    id
    name
    cdpApiRegistration {
      id
      isReportRegistered
      cdpOrganizationId
    }
  }
  config {
    id
    reportType
    useMateriality
    useReportingIntention
    useApprovals
    description
    status
    shortName
    longName
    allVisibleQuestions(reportId: $reportId) {
      ...ReportConfigQuestionForTable
    }
    allVisibleInputs(reportId: $reportId) {
      id
      componentId
    }
  }
}
    ${ReportConfigQuestionForTableFragmentDoc}`;
export const ReportAnswerForHistoryNodeFieldsFragmentDoc = gql`
    fragment ReportAnswerForHistoryNodeFields on ReportAnswer {
  id
  outputJson
  createdAt
  editorName
}
    `;
export const ReportAnswerOverrideForHistoryNodeFieldsFragmentDoc = gql`
    fragment ReportAnswerOverrideForHistoryNodeFields on ReportAnswerOverride {
  id
  outputJson
  createdAt
  editorName
}
    `;
export const CdpSyncQuestionStatusForHistoryNodeFieldsFragmentDoc = gql`
    fragment CdpSyncQuestionStatusForHistoryNodeFields on CdpSyncQuestionStatus {
  id
  createdAt
  actorName
  isSuccess
  failureKind
  failureDetails
}
    `;
export const ReportQuestionForHistoryNodeFieldsFragmentDoc = gql`
    fragment ReportQuestionForHistoryNodeFields on ReportQuestion {
  __typename
  ...ReportQuestionFields
  staticInputJson
  resolvedInputs(reportId: $reportId) {
    __typename
    ... on ResolvedReportQuestionInputsFailure {
      errorMessage
      questionIds
    }
    ... on ResolvedReportQuestionInputsSuccess {
      inputs
      unparsedInputs
    }
  }
  resolvedStatus(reportId: $reportId) {
    id
    status
    blockingMessage
    excludingQuestionId
  }
  instance(reportId: $reportId) {
    id
    name
    title
    subtitle
  }
  history(reportId: $reportId) {
    edges {
      node {
        ...ReportAnswerForHistoryNodeFields
      }
    }
  }
  overrideHistory(reportId: $reportId) {
    edges {
      node {
        ...ReportAnswerOverrideForHistoryNodeFields
      }
    }
  }
  cdpSyncHistory(reportId: $reportId) {
    edges {
      node {
        ...CdpSyncQuestionStatusForHistoryNodeFields
      }
    }
  }
  overrideState(reportId: $reportId)
}
    ${ReportQuestionFieldsFragmentDoc}
${ReportAnswerForHistoryNodeFieldsFragmentDoc}
${ReportAnswerOverrideForHistoryNodeFieldsFragmentDoc}
${CdpSyncQuestionStatusForHistoryNodeFieldsFragmentDoc}`;
export const PeerExternalReportAnswersForExternalAnswersListFragmentDoc = gql`
    fragment peerExternalReportAnswersForExternalAnswersList on PeerExternalReportAnswers {
  peerCompanies {
    id
    name
  }
  answers {
    id
    externalReportQuestionId
    publicDisclosure {
      id
      reportType
      publishingYear
      externalScore
      company {
        id
        name
        fullLogoUrl
      }
    }
    answerType
    answer
    watershedQualityScore
    questionPhrasing
    index
  }
  questions {
    id
    type {
      id
      type
      revision
    }
    reportQuestionIdentifier {
      id
      identifier
    }
    question
  }
}
    `;
export const ReportQuestionForBenchmarkFragmentDoc = gql`
    fragment ReportQuestionForBenchmark on ReportQuestion {
  __typename
  id
  identifier
  identifierId
  isComputed
  guidanceJson
  componentId
  resolvedStatus(reportId: $reportId) {
    id
    status
    blockingMessage
    excludingQuestionId
  }
  resolvedInputs(reportId: $reportId) {
    __typename
    ... on ResolvedReportQuestionInputsSuccess {
      inputs
      unparsedInputs
    }
    ... on ResolvedReportQuestionInputsFailure {
      errorMessage
      questionIds
    }
  }
  overrideState(reportId: $reportId)
  reportConfig {
    shortName
  }
  instance(reportId: $reportId) {
    id
    name
    title
    subtitle
  }
  peerExternalReportAnswers {
    ...peerExternalReportAnswersForExternalAnswersList
  }
  externalAnswerSuggestions(reportId: $reportId) {
    id
    suggestion
    suggestionSource {
      pageNumber
    }
    suggestionSourceType
    externalReportQuestion {
      id
      reportQuestionIdentifier {
        id
        identifier
      }
    }
    externalReportExtractionRun {
      id
      publicDisclosure {
        id
        publicUrl
        publishingYear
        reportType
        company {
          id
          name
          logo {
            id
            url
          }
        }
      }
    }
  }
}
    ${PeerExternalReportAnswersForExternalAnswersListFragmentDoc}`;
export const CustomReportContentFragmentDoc = gql`
    fragment CustomReportContent on Report {
  id
  config {
    id
    allVisibleItems(reportId: $reportId) {
      __typename
      ... on ReportQuestion {
        ...ReportQuestionForReportItemFields
      }
      ... on ReportQuestionContainer {
        id
        label
        description
        allVisibleQuestions(reportId: $reportId) {
          ... on ReportQuestion {
            ...ReportQuestionForReportItemFields
          }
        }
      }
    }
  }
}
    ${ReportQuestionForReportItemFieldsFragmentDoc}`;
export const ContainerAssigneesFragmentDoc = gql`
    fragment ContainerAssignees on ReportQuestionContainer {
  id
  assignees(reportId: $reportId) {
    id
    displayName
    name
  }
}
    `;
export const ItemWithQuestionComponent_QuestionFragmentDoc = gql`
    fragment ItemWithQuestionComponent_question on ReportQuestion {
  ...ReportQuestionAssigneeSelector_reportQuestion
  ...ReportQuestionItemFields_notes
  __typename
  id
  identifier
  guidanceJson
  componentId
  answer(reportId: $reportId) {
    ...ItemWithQuestionComponent_answer
  }
  overrideState(reportId: $reportId)
  overrideStateHistory(reportId: $reportId, first: 1) {
    edges {
      node {
        id
        createdAt
        createdBy {
          id
          displayName
        }
        overrideState
      }
    }
  }
  allowNotes
  allowedAttachmentKinds
  assignees(reportId: $reportId) {
    id
    displayName
    name
  }
  resolvedStatus(reportId: $reportId) {
    id
    status
    blockingMessage
  }
  resolvedWarnings(reportId: $reportId) {
    ...ReportAnswerWarningForCard
  }
  resolvedInputs(reportId: $reportId) {
    ...ResolvedReportQuestionInputsFailureForReportItem
    ... on ResolvedReportQuestionInputsSuccess {
      __typename
      inputs
      unparsedInputs
    }
  }
  resolvedReportingIntention(reportId: $reportId) {
    id
    intention
    event {
      createdBy {
        id
        displayName
      }
      createdAt
      reason
    }
  }
  instance(reportId: $reportId) {
    id
    title
    subtitle
    canAnswer
    canAssign
    canSetApprovers
    approvalInfo {
      ...QuestionApprovalInfo
    }
    ...ReportQuestionInstanceWithDeferredAttachmentItems
  }
}
    ${ReportQuestionAssigneeSelector_ReportQuestionFragmentDoc}
${ReportQuestionItemFields_NotesFragmentDoc}
${ItemWithQuestionComponent_AnswerFragmentDoc}
${ReportAnswerWarningForCardFragmentDoc}
${ResolvedReportQuestionInputsFailureForReportItemFragmentDoc}
${QuestionApprovalInfoFragmentDoc}
${ReportQuestionInstanceWithDeferredAttachmentItemsFragmentDoc}`;
export const ReportQuestionFieldsForDebugFragmentDoc = gql`
    fragment ReportQuestionFieldsForDebug on ReportQuestion {
  ...ReportQuestionForReportItemFields
  dynamicInputJson
  staticInputJson
  statusJson
}
    ${ReportQuestionForReportItemFieldsFragmentDoc}`;
export const ReportQuestionActivityTimeline_AnswerCreatedEventFragmentDoc = gql`
    fragment ReportQuestionActivityTimeline_answerCreatedEvent on ReportAnswerCreatedByUserChangelogEvent {
  id
  timestamp
  actor {
    id
    displayName
  }
  inputJson
  outputJson
}
    `;
export const ReportQuestionActivityTimeline_ApprovalActionEventFragmentDoc = gql`
    fragment ReportQuestionActivityTimeline_approvalActionEvent on ReportQuestionApprovalActionChangelogEvent {
  id
  timestamp
  actor {
    id
    displayName
  }
  comment
  previousApprovalStatus
  newApprovalStatus
  isLocked
}
    `;
export const ReportQuestionActivityTimeline_ApproversUpdatedEventFragmentDoc = gql`
    fragment ReportQuestionActivityTimeline_approversUpdatedEvent on ReportQuestionApproversUpdatedChangelogEvent {
  id
  timestamp
  actor {
    id
    displayName
  }
  previousApprovers {
    id
    displayName
  }
  newApprovers {
    id
    displayName
  }
  previousApprovalStatus
  newApprovalStatus
  isLocked
}
    `;
export const ReportQuestionActivityTimeline_AssigneesUpdatedEventFragmentDoc = gql`
    fragment ReportQuestionActivityTimeline_assigneesUpdatedEvent on ReportQuestionAssigneesUpdatedChangelogEvent {
  id
  timestamp
  actor {
    id
    displayName
  }
  previousAssignees {
    id
    displayName
  }
  newAssignees {
    id
    displayName
  }
}
    `;
export const ReportQuestionActivityTimeline_EventFragmentDoc = gql`
    fragment ReportQuestionActivityTimeline_event on ReportQuestionChangelogEvent {
  __typename
  ... on ReportAnswerCreatedByUserChangelogEvent {
    ...ReportQuestionActivityTimeline_answerCreatedEvent
  }
  ... on ReportQuestionApprovalActionChangelogEvent {
    ...ReportQuestionActivityTimeline_approvalActionEvent
  }
  ... on ReportQuestionApproversUpdatedChangelogEvent {
    ...ReportQuestionActivityTimeline_approversUpdatedEvent
  }
  ... on ReportQuestionAssigneesUpdatedChangelogEvent {
    ...ReportQuestionActivityTimeline_assigneesUpdatedEvent
  }
}
    ${ReportQuestionActivityTimeline_AnswerCreatedEventFragmentDoc}
${ReportQuestionActivityTimeline_ApprovalActionEventFragmentDoc}
${ReportQuestionActivityTimeline_ApproversUpdatedEventFragmentDoc}
${ReportQuestionActivityTimeline_AssigneesUpdatedEventFragmentDoc}`;
export const ReportQuestionActivityTimeline_QuestionFragmentDoc = gql`
    fragment ReportQuestionActivityTimeline_question on ReportQuestion {
  __typename
  id
  identifier
  identifierId
  componentId
  isComputed
  overrideState(reportId: $reportId)
  resolvedInputs(reportId: $reportId) {
    __typename
    ... on ResolvedReportQuestionInputsFailure {
      errorMessage
      questionIds
    }
    ... on ResolvedReportQuestionInputsSuccess {
      inputs
      unparsedInputs
    }
  }
  resolvedStatus(reportId: $reportId) {
    id
    status
    blockingMessage
    excludingQuestionId
  }
  instance(reportId: $reportId) {
    id
    title
    subtitle
    changelog {
      edges {
        node {
          ...ReportQuestionActivityTimeline_event
        }
      }
    }
  }
}
    ${ReportQuestionActivityTimeline_EventFragmentDoc}`;
export const ReportQuestionContainerSheetContents_ContentsBody_ReportFragmentDoc = gql`
    fragment ReportQuestionContainerSheetContents_ContentsBody_report on Report {
  ...ReportItem_report
  id
}
    ${ReportItem_ReportFragmentDoc}`;
export const ReportQuestionContainerSheetContents_ReportFragmentDoc = gql`
    fragment ReportQuestionContainerSheetContents_report on Report {
  ...ReportQuestionContainerSheetContents_ContentsBody_report
}
    ${ReportQuestionContainerSheetContents_ContentsBody_ReportFragmentDoc}`;
export const ReportQuestionDropdownMenu_ConfigFragmentDoc = gql`
    fragment ReportQuestionDropdownMenu_config on ReportConfig {
  useOverrides
}
    `;
export const CdpApiRegistration_ReportFragmentDoc = gql`
    fragment CdpApiRegistration_report on Report {
  cdpApiRegistration {
    cdpOrganizationId
    id
    isReportRegistered
    registeredReportId
  }
}
    `;
export const QuestionStatusForCdpSyncColumnFragmentDoc = gql`
    fragment QuestionStatusForCdpSyncColumn on CdpSyncQuestionStatus {
  id
  syncTime
  isSuccess
  reportQuestionId
  actor {
    name
    displayName
    id
  }
  failureKind
  failureDetails
}
    `;
export const QuestionStatusForCdpSyncDialogFragmentDoc = gql`
    fragment QuestionStatusForCdpSyncDialog on CdpSyncQuestionStatus {
  id
  reportId
  reportQuestionId
  syncTime
  isSuccess
  failureKind
  failureDetails
  reportQuestion {
    id
    parentContainer {
      id
      label
    }
    instance(reportId: $reportId) {
      id
      title
    }
    answer(reportId: $reportId) {
      id
      updatedAt
      latestCdpSyncEvent {
        id
        ...QuestionStatusForCdpSyncColumn
      }
    }
  }
}
    ${QuestionStatusForCdpSyncColumnFragmentDoc}`;
export const DrilldownSavedViewsForSelectorFragmentDoc = gql`
    fragment DrilldownSavedViewsForSelector on DrilldownSavedView {
  id
  name
  isPrivate
  biSavedViewId
  filterExpressions {
    conjunction
    expressions {
      field
      operator
      value
    }
  }
  footprintKind
}
    `;
export const StandardTableUiFragmentDoc = gql`
    fragment StandardTableUI on ReportItemStandardTable {
  id
  columns {
    label
    alignment
    isMarkdown
    minWidthPx
  }
  rows {
    values
    explanations {
      markdownText
      url
    }
    colspans
  }
  isFullWidth
}
    `;
export const SecrStandardTableUiFragmentDoc = gql`
    fragment SecrStandardTableUI on ReportItemSecrStandardTable {
  id
  rows {
    values
    explanations {
      markdownText
      url
    }
    colspans
  }
  isFullWidth
}
    `;
export const FootprintSnapshotMetadataForCustomReportsFragmentDoc = gql`
    fragment FootprintSnapshotMetadataForCustomReports on FootprintSnapshot {
  id
  footprintInterval
  status
}
    `;
export const TcfdArchetypeOpportunityFieldsFragmentDoc = gql`
    fragment TcfdArchetypeOpportunityFields on TcfdArchetypeOpportunity {
  id
  title
  userDescription
  archetypeRiskId
  geographies
  sectors
  distributionModel
}
    `;
export const UserForReportQuestionAssignmentFragmentDoc = gql`
    fragment UserForReportQuestionAssignment on User {
  id
  displayName
  email
  hasPermission(orgId: $orgId, permission: ViewFootprintDetail)
}
    `;
export const CompanyReportingFactsFieldsFragmentDoc = gql`
    fragment CompanyReportingFactsFields on CompanyReportingFacts {
  id
  companyId
  asOfMonth
  overriddenAt
  numEmployeesGlobal
  numEmployeesEu
  numEmployeesUk
  revenueGlobalUsd
  revenueEuUsd
  revenueUkUsd
  assetsGlobalUsd
  assetsEuUsd
  assetsUkUsd
  federalContractsUsUsd
  federalContractsUkUsd
  largestContractUkUsd
  primaryJurisdiction
  legalEntityCountries
  listedExchanges
  secFilerStatus
  lseListingStatus
  nfrdFilerStatus
  isDoingBusinessCa
  isBank
  isInsurance
  isSmallCreditInsurance
  isTradedBankingLlpUk
  isLlpUk
  isNfisFilerUk
  isDirectorsFilerUk
  isPublicEu
}
    `;
export const PeerExternalReportQuestionFieldsFragmentDoc = gql`
    fragment PeerExternalReportQuestionFields on ExternalReportQuestion {
  id
  type {
    id
    type
    revision
  }
  reportQuestionIdentifier {
    id
    identifier
  }
  question
}
    `;
export const PeerExternalReportAnswerFieldsFragmentDoc = gql`
    fragment PeerExternalReportAnswerFields on ExternalReportAnswer {
  id
  externalReportQuestionId
  publicDisclosure {
    id
    reportType
    publishingYear
    externalScore
    company {
      id
      name
      fullLogoUrl
    }
  }
  answerType
  answer
  watershedQualityScore
  questionPhrasing
  index
}
    `;
export const ReportSummaryFragmentDoc = gql`
    fragment ReportSummary on Report {
  id
  name
  updatedAt
}
    `;
export const ReportQuestionDependentFieldsFragmentDoc = gql`
    fragment ReportQuestionDependentFields on ReportQuestion {
  id
  resolvedWarnings(reportId: $reportId) {
    ...ReportAnswerWarningForCard
  }
  answer(reportId: $reportId) {
    id
    outputJson
  }
  dependentItems(filter: {excludedComputed: true}) {
    inputs {
      id
      updatedAt
      resolvedInputs(reportId: $reportId) {
        __typename
        ... on ResolvedReportQuestionInputsFailure {
          questionIds
          errorMessage
        }
        ... on ResolvedReportQuestionInputsSuccess {
          inputs
          unparsedInputs
        }
      }
      resolvedWarnings(reportId: $reportId) {
        ...ReportAnswerWarningForCard
      }
    }
    questionStatuses {
      id
      updatedAt
      resolvedStatus(reportId: $reportId) {
        id
        status
        blockingMessage
        excludingQuestionId
      }
    }
    containerStatuses {
      id
      resolvedStatus(reportId: $reportId) {
        id
        status
        blockingMessage
        excludingQuestionId
      }
    }
    containerProgresses {
      id
      automaticProgress(reportId: $reportId)
    }
  }
}
    ${ReportAnswerWarningForCardFragmentDoc}`;
export const ReportQuestionOverrideDependentFieldsFragmentDoc = gql`
    fragment ReportQuestionOverrideDependentFields on ReportQuestion {
  id
  answerOverride(reportId: $reportId) {
    id
    outputJson
  }
}
    `;
export const PermissionFieldsFragmentDoc = gql`
    fragment PermissionFields on PermissionItem {
  id
  objectId
  permission
  object {
    __typename
    id
    name
  }
  revokedAt
}
    `;
export const RoleInfoFragmentDoc = gql`
    fragment RoleInfo on Role {
  id
  name
  description
  permissions {
    ...PermissionFields
  }
}
    ${PermissionFieldsFragmentDoc}`;
export const UserRoleFieldsFragmentDoc = gql`
    fragment UserRoleFields on UserRoleAssignment {
  id
  revokedAt
  role {
    id
    ...RoleInfo
  }
}
    ${RoleInfoFragmentDoc}`;
export const UserTablePermissionsFragmentDoc = gql`
    fragment UserTablePermissions on User {
  id
  name
  displayName
  email
  userUploadTasks {
    id
    measurementProject {
      id
      name
      active
    }
    datasource {
      id
      name
      dataset {
        id
        name
      }
    }
  }
  roles(orgId: $orgId) {
    ...UserRoleFields
  }
  permissions(orgId: $orgId) {
    id
    ...PermissionFields
  }
  orgAccessId(orgId: $orgId)
}
    ${UserRoleFieldsFragmentDoc}
${PermissionFieldsFragmentDoc}`;
export const OrganizationMemberFieldsFragmentDoc = gql`
    fragment OrganizationMemberFields on User {
  id
  orgAccessCreatedAt(orgId: $orgId)
  name
  email
  loginActivated
  consecutiveLoginFailures
  managedByDirectoryType
  ...UserTablePermissions
  userRoles: roles(orgId: $orgId) {
    ...UserRoleFields
  }
  userPermissions: permissions(orgId: $orgId) {
    ...PermissionFields
  }
  didLatestEmailBounce
  hasValidAppSessions
}
    ${UserTablePermissionsFragmentDoc}
${UserRoleFieldsFragmentDoc}
${PermissionFieldsFragmentDoc}`;
export const RolesTableRoleFragmentDoc = gql`
    fragment RolesTableRole on Role {
  id
  name
  description
  createdAt
  createdBy {
    id
    name
  }
  workosGroupId
  permissions {
    id
    ...PermissionFields
  }
  users {
    id
    user {
      id
      displayName
      name
    }
  }
  apiKeys {
    id
    apiKey {
      id
      name
      description
    }
  }
}
    ${PermissionFieldsFragmentDoc}`;
export const RoleForUserSettingsFragmentDoc = gql`
    fragment RoleForUserSettings on Role {
  id
  name
  description
  permissions {
    id
    objectId
    permission
  }
  users {
    id
    user {
      id
      name
    }
  }
}
    `;
export const PermissionObjectWithChildrenFragmentDoc = gql`
    fragment PermissionObjectWithChildren on PermissionObjectWithChildren {
  children(search: $search) {
    id
    name
  }
}
    `;
export const PermissionObjectFragmentDoc = gql`
    fragment PermissionObject on PermissionObject {
  id
  name
}
    `;
export const AuthenticationSettingsSectionFragmentDoc = gql`
    fragment AuthenticationSettingsSection on Organization {
  id
  passwordAuthDisabled
  magicLinkDisabled
}
    `;
export const WorkosDirectoryBlockFragmentDoc = gql`
    fragment WorkosDirectoryBlock on WorkosDirectory {
  id
  directoryType
  name
  state
  createdAt
}
    `;
export const ScimSettingsSectionFragmentDoc = gql`
    fragment ScimSettingsSection on Organization {
  id
  workosOrgId
  workosDirectories {
    id
    ...WorkosDirectoryBlock
  }
}
    ${WorkosDirectoryBlockFragmentDoc}`;
export const SsoConnectionBlockFragmentDoc = gql`
    fragment SsoConnectionBlock on WorkosConnection {
  id
  connectionType
  name
  state
  createdAt
}
    `;
export const SsoSettingsSectionFragmentDoc = gql`
    fragment SsoSettingsSection on Organization {
  id
  workosOrgId
  workosConnections {
    id
    ...SsoConnectionBlock
  }
}
    ${SsoConnectionBlockFragmentDoc}`;
export const UserFieldsForUsersListFragmentDoc = gql`
    fragment UserFieldsForUsersList on User {
  id
  name
  displayName
  email
  loginActivated
  consecutiveLoginFailures
  managedByDirectoryType
  didLatestEmailBounce
  hasValidAppSessions
  userUploadTasks {
    id
    measurementProject {
      id
      name
      active
    }
    datasource {
      id
      name
      dataset {
        id
        name
      }
    }
  }
  roles(orgId: $orgId) {
    ...UserRoleFields
  }
  permissions(orgId: $orgId) {
    id
    ...PermissionFields
  }
  orgAccessId(orgId: $orgId)
  orgAccessCreatedAt(orgId: $orgId)
}
    ${UserRoleFieldsFragmentDoc}
${PermissionFieldsFragmentDoc}`;
export const PointsOfContactSectionFragmentDoc = gql`
    fragment PointsOfContactSection on Organization {
  id
  pointsOfContact {
    id
    kind
    watershedEmployee {
      id
      name
      email
    }
  }
}
    `;
export const SessionTimeoutSettingsSectionFragmentDoc = gql`
    fragment SessionTimeoutSettingsSection on Organization {
  id
  cookieTimeoutDays
  sessionTimeoutMinutes
}
    `;
export const WorkspaceSectionFragmentDoc = gql`
    fragment WorkspaceSection on Organization {
  id
  name
  fiscalYearStartMonth
  currency
}
    `;
export const SupportCaseForSupportCasesPageFragmentDoc = gql`
    fragment SupportCaseForSupportCasesPage on SupportCase {
  id
  subject
  description
  status
  priority
  createdAt
  user {
    id
    displayName
  }
  updatedAt
  lastUpdatedBy
  hasUnreadNotifications
}
    `;
export const SupportCaseRelatedItemForChipFragmentDoc = gql`
    fragment SupportCaseRelatedItemForChip on SupportCaseRelatedItem {
  id
  name
  url
}
    `;
export const SupportCaseForSidebarFragmentDoc = gql`
    fragment SupportCaseForSidebar on SupportCase {
  id
  status
  subject
  description
  priority
  issueType
  createdAt
  updatedAt
  user {
    id
    displayName
  }
  relatedItem {
    ...SupportCaseRelatedItemForChip
  }
  lastUpdatedBy
  productUrl
  currentAssignee
  subscriberUsers {
    id
    name
  }
}
    ${SupportCaseRelatedItemForChipFragmentDoc}`;
export const AuditLogEntryFieldsFragmentDoc = gql`
    fragment AuditLogEntryFields on AuditLogEntry {
  id
  createdAt
  eventKind
  eventMetadata
  userDisplayName
  appType
}
    `;
export const SupportCaseForTimelineFragmentDoc = gql`
    fragment SupportCaseForTimeline on SupportCase {
  id
  timeline(after: $after, before: $before, first: $first, last: $last) {
    edges {
      cursor
      node {
        id
        ...AuditLogEntryFields
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
  }
}
    ${AuditLogEntryFieldsFragmentDoc}`;
export const SupportCaseForSupportCasePageFragmentDoc = gql`
    fragment SupportCaseForSupportCasePage on SupportCase {
  id
  subject
  status
  hasUnreadNotifications
  currentUserIsSubcribed
  subscriberUsers {
    id
    name
  }
  ...SupportCaseForSidebar
  ...SupportCaseForTimeline
}
    ${SupportCaseForSidebarFragmentDoc}
${SupportCaseForTimelineFragmentDoc}`;
export const SupportCaseForSupportPanelFragmentDoc = gql`
    fragment SupportCaseForSupportPanel on SupportCase {
  id
  subject
  description
  status
  priority
  updatedAt
  lastUpdatedBy
}
    `;
export const CompanyFieldsForEngagementTasksFragmentDoc = gql`
    fragment CompanyFieldsForEngagementTasks on Company {
  id
  name
  fullLogoUrl
  naicsCode
}
    `;
export const TraceDetailsFragmentFragmentDoc = gql`
    fragment TraceDetailsFragment on TraceDetails {
  referenceValueDetails
  emissionsModelVariableDetails
  bartValueDetails
  emissionsModelVersionId
  emissionsModelEvaluationId
}
    `;
export const LineageRunDetailsFragmentDoc = gql`
    fragment LineageRunDetails on LineageRun {
  id
  jobSlug
  status
  startedAt
  endedAt
  parameters
  additionalOutput
}
    `;
export const UserUploadForFileDownloadFragmentDoc = gql`
    fragment UserUploadForFileDownload on UserUpload {
  id
  createdAt
  name
  category
  uploader {
    id
    name
  }
  revisionRoot
  measurementProject {
    id
    name
    deletedAt
  }
  userUploadTask {
    id
    datasource {
      id
      name
    }
  }
}
    `;
export const PublicDisclosureForBenchmarksFragmentDoc = gql`
    fragment PublicDisclosureForBenchmarks on PublicDisclosure {
  id
  reportType
  publishingYear
  reportingYears
  publicUrl
  qualityScore
  cdpVendorData {
    ...CdpVendorDataFields
  }
  externalScore
}
    ${CdpVendorDataFieldsFragmentDoc}`;
export const CompanyForBenchmarksFragmentDoc = gql`
    fragment CompanyForBenchmarks on Company {
  id
  name
  description
  sustainabilityWebsiteUrl
  disclosuresV2(
    opts: {historicalYearFilters: {}, climateCommitmentFilters: {}, excludeArchivedDisclosures: true}
  ) {
    id
    publicDisclosure {
      ...PublicDisclosureForBenchmarks
    }
    historicalEmissionsYears {
      id
      publicDisclosureId
      reportingYear
      numEmployees
      revenue
      revenueCurrency
      revenueUsd
      scope1
      scope2Market
      scope2Location
      scope3
      scope301
      scope302
      scope303
      scope304
      scope305
      scope306
      scope307
      scope308
      scope309
      scope310
      scope311
      scope312
      scope313
      scope314
      scope315
      scope316
      scope317
      units
      percentageCleanEnergy
      totalMwh
      scope123RevenueIntensityKgco2e {
        id
        scope1
        scope2Market
        scope2Location
        scope3
      }
    }
    climateCommitments {
      ...CompanyClimateCommitmentFieldsForCCIS
    }
  }
  naicsCode
  fullLogoUrl
}
    ${PublicDisclosureForBenchmarksFragmentDoc}
${CompanyClimateCommitmentFieldsForCcisFragmentDoc}`;
export const PeerCompanyGroupForBenchmarksFragmentDoc = gql`
    fragment PeerCompanyGroupForBenchmarks on PeerCompanyGroup {
  id
  name
  createdAt
  members {
    id
    company {
      ...CompanyForBenchmarks
    }
    revenueAtRisk
  }
  shouldHideFromFootprintOverview
  revenueForRevenueAtRisk
}
    ${CompanyForBenchmarksFragmentDoc}`;
export const AccessibleOrgsAndEngagementTasksFragmentDoc = gql`
    fragment AccessibleOrgsAndEngagementTasks on ActiveOrganization {
  id
  user {
    id
    accessibleOrgs {
      id
      name
      watershedPlanLegacy
      company {
        id
        fullLogoUrl
      }
      engagementTasks {
        id
        rootCompany {
          id
          name
          fullLogoUrl
        }
      }
    }
  }
}
    `;
export const BenchmarkFieldsFragmentDoc = gql`
    fragment BenchmarkFields on Benchmark {
  __typename
  id
  reportId
  name
  reportingYear
  publishingYear
  kind
  normalizationKind
  amountKgco2e
  kgco2eByScope {
    scope
    kgco2e
  }
  currency
  scope2Source
}
    `;
export const PeerCompanyGroupFieldsFragmentDoc = gql`
    fragment PeerCompanyGroupFields on PeerCompanyGroup {
  __typename
  id
  name
  footprintKinds
  members {
    id
    shouldHideFromFootprintOverview
    mostRecentHighQualityBenchmark {
      ...BenchmarkFields
    }
  }
  selfBenchmarks {
    ...BenchmarkFields
  }
  shouldHideFromFootprintOverview
}
    ${BenchmarkFieldsFragmentDoc}`;
export const PlanFieldsForHomepageFragmentDoc = gql`
    fragment PlanFieldsForHomepage on Plan {
  id
  name
  forecast {
    id
    interval
    customIntensityConfigs {
      ...CustomIntensityConfigFields
    }
  }
  targets {
    ...PlanTargetFields
  }
  carbonOffsets: reduxCarbonOffsets {
    id
    ...SimpleTimeseriesFields
  }
  carbonRemoval: reduxCarbonRemoval {
    id
    ...SimpleTimeseriesFields
  }
}
    ${CustomIntensityConfigFieldsFragmentDoc}
${PlanTargetFieldsFragmentDoc}
${SimpleTimeseriesFieldsFragmentDoc}`;
export const FootprintAnalysisTimeSeriesDataFieldsFragmentDoc = gql`
    fragment FootprintAnalysisTimeSeriesDataFields on FootprintAnalysisTimeSeriesDataPoint {
  date
  value
}
    `;
export const MarketplaceDocumentFieldsForPurchasesPageFragmentDoc = gql`
    fragment MarketplaceDocumentFieldsForPurchasesPage on MarketplaceDocument {
  id
  name
  kind
  downloadUrl
}
    `;
export const MarketplacePurchaseLineItemFieldsForPurchasesPageFragmentDoc = gql`
    fragment MarketplacePurchaseLineItemFieldsForPurchasesPage on MarketplacePurchaseLineItem {
  id
  amount
  amountUnit
  coverageInterval
  unitCostUsdCents
  deliveryDate
  isDelivered
  archetype {
    id
    name
    bannerImageUrl
    imageUrl
    descriptionMd
    certificateBlurb
    kind
    summaryMd
  }
  project {
    id
    name
    developer {
      id
      name
      imageUrl
    }
  }
  documents {
    edges {
      node {
        ...MarketplaceDocumentFieldsForPurchasesPage
      }
    }
  }
  eacMetadata {
    id
    countryWithEmissions
    countryWithEacs
    numEacsRequested
    mwh
    marketTco2e
  }
}
    ${MarketplaceDocumentFieldsForPurchasesPageFragmentDoc}`;
export const MarketplacePurchaseFieldsForPurchasesPageFragmentDoc = gql`
    fragment MarketplacePurchaseFieldsForPurchasesPage on MarketplacePurchase {
  id
  name
  createdAt
  updatedAt
  totalCostUsdCents
  customerStatus
  offerExpiration
  lineItems {
    edges {
      node {
        ...MarketplacePurchaseLineItemFieldsForPurchasesPage
      }
    }
  }
  documents {
    edges {
      node {
        ...MarketplaceDocumentFieldsForPurchasesPage
      }
    }
  }
  eacMetadata {
    id
    interval
    requestNotes
    ghgCategoryIds
    marketTco2e
  }
}
    ${MarketplacePurchaseLineItemFieldsForPurchasesPageFragmentDoc}
${MarketplaceDocumentFieldsForPurchasesPageFragmentDoc}`;
export const CurrencyExchangeRateForPurchasesPageFragmentDoc = gql`
    fragment CurrencyExchangeRateForPurchasesPage on CurrencyExchangeRate {
  id
  currency
  updatedAt
  value
}
    `;
export const IngestionCustomFieldForMeasurementPageFragmentDoc = gql`
    fragment IngestionCustomFieldForMeasurementPage on IngestionCustomField {
  id
  name
  type
  possibleValues
  description
  appliesToAllDatasets
  specificDatasets {
    ...DatasetFieldsForCustomFieldEditDialog
  }
}
    ${DatasetFieldsForCustomFieldEditDialogFragmentDoc}`;
export const IngestionCustomFieldDatasourceForMeasurementPageFragmentDoc = gql`
    fragment IngestionCustomFieldDatasourceForMeasurementPage on IngestionCustomFieldDatasource {
  id
  datasourceId
  ingestionCustomFieldId
  ingestionCustomFieldValue
}
    `;
export const PresetCustomTagForMeasurementPageFragmentDoc = gql`
    fragment PresetCustomTagForMeasurementPage on PresetCustomTag {
  ingestionCustomField {
    ...IngestionCustomFieldForMeasurementPage
  }
  ingestionCustomFieldDatasource {
    ...IngestionCustomFieldDatasourceForMeasurementPage
  }
}
    ${IngestionCustomFieldForMeasurementPageFragmentDoc}
${IngestionCustomFieldDatasourceForMeasurementPageFragmentDoc}`;
export const DataIssueWithCommentsForMeasurementPageFragmentDoc = gql`
    fragment DataIssueWithCommentsForMeasurementPage on DataIssue {
  comments {
    edges {
      node {
        id
        person {
          id
          isWatershedEmployee
          isWatershedContractor
        }
        createdAt
        isPublished
      }
    }
  }
}
    `;
export const MeasurementProjectPageIngestionCustomFieldFragmentDoc = gql`
    fragment MeasurementProjectPageIngestionCustomField on IngestionCustomField {
  ...DataCollectionCommandPaletteCustomField
  id
  name
  type
  possibleValues
  description
  appliesToAllDatasets
  specificDatasets {
    ...DatasetFieldsForCustomFieldEditDialog
  }
}
    ${DataCollectionCommandPaletteCustomFieldFragmentDoc}
${DatasetFieldsForCustomFieldEditDialogFragmentDoc}`;
export const IngestionCustomFieldForDatasourcePageFragmentDoc = gql`
    fragment IngestionCustomFieldForDatasourcePage on IngestionCustomField {
  ...DataCollectionCommandPaletteCustomField
  ...CustomFieldForBuildingUtilitiesDialog
  id
  name
  type
  possibleValues
  description
  appliesToAllDatasets
  specificDatasets {
    ...DatasetFieldsForCustomFieldEditDialog
  }
}
    ${DataCollectionCommandPaletteCustomFieldFragmentDoc}
${CustomFieldForBuildingUtilitiesDialogFragmentDoc}
${DatasetFieldsForCustomFieldEditDialogFragmentDoc}`;
export const OrganizationCustomFieldsForDatasourcePageFragmentDoc = gql`
    fragment OrganizationCustomFieldsForDatasourcePage on Organization {
  id
  ingestionCustomFields {
    ...IngestionCustomFieldForDatasourcePage
  }
}
    ${IngestionCustomFieldForDatasourcePageFragmentDoc}`;
export const BuildingForm_MeasurementProjectFragmentDoc = gql`
    fragment BuildingForm_measurementProject on MeasurementProject {
  id
  active
}
    `;
export const DatasourcePageContent_MeasurementProjectFragmentDoc = gql`
    fragment DatasourcePageContent_measurementProject on MeasurementProject {
  id
  active
  coverageInterval
  ...BuildingForm_measurementProject
}
    ${BuildingForm_MeasurementProjectFragmentDoc}`;
export const MeasurementProjectFieldsForDatasourcePageFragmentDoc = gql`
    fragment MeasurementProjectFieldsForDatasourcePage on MeasurementProject {
  id
  name
  active
  coverageInterval
  coverageStartDate
  coverageEndDate
  measurementTimeline {
    id
    deadline
  }
  isApprovalFlowEnabled
  ...DatasourcePageContent_measurementProject
}
    ${DatasourcePageContent_MeasurementProjectFragmentDoc}`;
export const ClimateProgramProjectRequirementDatasetForDatasourceInstructionsFragmentDoc = gql`
    fragment ClimateProgramProjectRequirementDatasetForDatasourceInstructions on ClimateProgramProjectRequirementDataset {
  id
  additionalNotesMd
  climateProgramProject {
    ...ClimateProgramProjectMeasurementCopy
    name
  }
}
    ${ClimateProgramProjectMeasurementCopyFragmentDoc}`;
export const DatasourceFilesObjectList_UserFragmentDoc = gql`
    fragment DatasourceFilesObjectList_user on User {
  id
  name
  isWatershedEmployee
  isWatershedContractor
}
    `;
export const DatasourceFilesObjectList_UserUploadedTableFragmentDoc = gql`
    fragment DatasourceFilesObjectList_userUploadedTable on UserUploadedTable {
  numRows
  status
  schema {
    index
    kind
    name
    alias
  }
  rawDataPreview {
    dataUntyped
  }
  dataPreview {
    dataUntyped
  }
}
    `;
export const DatasourceFilesObjectList_UserUploadAttemptFragmentDoc = gql`
    fragment DatasourceFilesObjectList_userUploadAttempt on UserUploadAttempt {
  id
  name
  createdAt
  userUploadSource
  remoteWritten
  uploader {
    id
    ...DatasourceFilesObjectList_user
  }
  userUploadedTables {
    ...DatasourceFilesObjectList_userUploadedTable
  }
  latestUutsIfRevisionExists {
    ...DatasourceFilesObjectList_userUploadedTable
  }
}
    ${DatasourceFilesObjectList_UserFragmentDoc}
${DatasourceFilesObjectList_UserUploadedTableFragmentDoc}`;
export const DatasourceFilesObjectList_UserUploadFragmentDoc = gql`
    fragment DatasourceFilesObjectList_userUpload on UserUpload {
  id
  actualUserUploadId
  associatedUtilityCount
  ctsVersion {
    id
  }
  latestAdeIdForSDIFacilitiesFlow
  name
  processingMode
  processingWorkflowId
  revisionRoot
  status
  userUploadKind
  userVisibleErrorMessage
  userVisibleAttempt {
    id
    ...DatasourceFilesObjectList_userUploadAttempt
  }
  userUploadTask {
    id
    datasource {
      id
      dataset {
        id
        canonicalDataset {
          id
          kind
        }
      }
    }
  }
}
    ${DatasourceFilesObjectList_UserUploadAttemptFragmentDoc}`;
export const DatasourcePageFilesSection_UserUploadFragmentDoc = gql`
    fragment DatasourcePageFilesSection_userUpload on UserUpload {
  ...DatasourceFilesObjectList_userUpload
}
    ${DatasourceFilesObjectList_UserUploadFragmentDoc}`;
export const DatasourcePageContent_UserUploadFragmentDoc = gql`
    fragment DatasourcePageContent_userUpload on UserUpload {
  id
  ...DatasourcePageFilesSection_userUpload
}
    ${DatasourcePageFilesSection_UserUploadFragmentDoc}`;
export const UserUploadForDatasourceFragmentDoc = gql`
    fragment UserUploadForDatasource on UserUpload {
  id
  name
  status
  processingMode
  userVisibleErrorMessage
  createdAt
  latestAdeIdForSDIFacilitiesFlow
  description
  revisionRoot
  processingWorkflowId
  isBeingValueMapped
  ctsVersion {
    id
  }
  userVisibleAttempt {
    id
    name
    size
    remoteWritten
    createdAt
    uploader {
      id
      name
    }
    userUploadedTables {
      ...UserUploadedTableWithDataPreview
    }
    latestUutsIfRevisionExists {
      ...UserUploadedTableWithDataPreview
    }
    userUploadSource
  }
  userUploadTask {
    id
    measurementProject {
      id
      name
    }
    facilitiesPreview {
      totalCount
    }
    datasource {
      id
      dataset {
        id
        canonicalDataset {
          id
          kind
        }
      }
    }
  }
  actualUserUploadId
  associatedUtilityCount
  transformsIncludingDeleted {
    ...CtsTransformForEditor
  }
  userUploadKind
  ...DatasourcePageContent_userUpload
}
    ${UserUploadedTableWithDataPreviewFragmentDoc}
${CtsTransformForEditorFragmentDoc}
${DatasourcePageContent_UserUploadFragmentDoc}`;
export const ValueMappingTaskFieldsForDatasourcePageFragmentDoc = gql`
    fragment ValueMappingTaskFieldsForDatasourcePage on ValueMappingTask {
  id
  mappingRuleId
  status
}
    `;
export const CtsFormWithResultsFieldsFragmentDoc = gql`
    fragment CtsFormWithResultsFields on CtsForm {
  id
  ctsVersionId
  customerTargetSchemaId
  datasetId
  title
  description
  entries {
    id
    data
    createdBy {
      id
      displayName
    }
  }
}
    `;
export const DatasourceSettingsDialog_UserUploadTaskFragmentDoc = gql`
    fragment DatasourceSettingsDialog_userUploadTask on UserUploadTask {
  id
  datasource {
    id
    name
    importInstructionsMd
    customerTargetSchemaIds
  }
}
    `;
export const UserUploadTaskFieldsForDatasourcePageFragmentDoc = gql`
    fragment UserUploadTaskFieldsForDatasourcePage on UserUploadTask {
  id
  createdAt
  state
  importState
  approvalStatus
  assignees {
    id
    displayName
    name
  }
  assignee {
    id
    displayName
    name
  }
  measurementTask {
    id
    status
    statusSimplified
  }
  measurementProject {
    ...MeasurementProjectFieldsForDatasourcePage
  }
  datasetRequirements {
    ...ClimateProgramProjectRequirementDatasetForDatasourceInstructions
  }
  userUploads(first: $userUploadLimit) {
    edges {
      node {
        ...UserUploadForDatasource
        ...UserUploadEditSheet_userUpload
      }
    }
  }
  issues(isPublished: true) {
    edges {
      node {
        ...DataIssueWithCommentsFields
      }
    }
  }
  valueMappingTask {
    id
    mappingRuleId
  }
  valueMappingTasks {
    ...ValueMappingTaskFieldsForDatasourcePage
  }
  approvers {
    ...ApproverFieldsForMeasurementPage
  }
  ctsForm {
    ...CtsFormWithResultsFields
  }
  lockState
  ...DatasourceSettingsDialog_userUploadTask
}
    ${MeasurementProjectFieldsForDatasourcePageFragmentDoc}
${ClimateProgramProjectRequirementDatasetForDatasourceInstructionsFragmentDoc}
${UserUploadForDatasourceFragmentDoc}
${UserUploadEditSheet_UserUploadFragmentDoc}
${DataIssueWithCommentsFieldsFragmentDoc}
${ValueMappingTaskFieldsForDatasourcePageFragmentDoc}
${ApproverFieldsForMeasurementPageFragmentDoc}
${CtsFormWithResultsFieldsFragmentDoc}
${DatasourceSettingsDialog_UserUploadTaskFragmentDoc}`;
export const IntegrationPartnerForDatasourcePageFragmentDoc = gql`
    fragment IntegrationPartnerForDatasourcePage on IntegrationPartner {
  id
  name
  logoUrl
  externalId
  dataType
  isRequestOnly
  applicableDatasources {
    id
    name
    hasIntegration
    isUserConfirmed
    activeIntegrationConnection {
      id
    }
    dataset {
      id
      name
    }
  }
  activeIntegrationConnections {
    id
    additionalIdentifier
  }
}
    `;
export const CanonicalDatasourceForDatasourcePageFragmentDoc = gql`
    fragment CanonicalDatasourceForDatasourcePage on CanonicalDatasource {
  id
  name
  canonicalDataset {
    id
    kind
  }
  integrationPartner {
    ...IntegrationPartnerForDatasourcePage
  }
}
    ${IntegrationPartnerForDatasourcePageFragmentDoc}`;
export const IngestionCustomFieldDatasourceForDatasourcePageFragmentDoc = gql`
    fragment IngestionCustomFieldDatasourceForDatasourcePage on IngestionCustomFieldDatasource {
  id
  datasourceId
  ingestionCustomFieldId
  ingestionCustomFieldValue
}
    `;
export const PresetCustomTagForDatasourcePageFragmentDoc = gql`
    fragment PresetCustomTagForDatasourcePage on PresetCustomTag {
  ingestionCustomField {
    ...IngestionCustomFieldForDatasourcePage
  }
  ingestionCustomFieldDatasource {
    ...IngestionCustomFieldDatasourceForDatasourcePage
  }
}
    ${IngestionCustomFieldForDatasourcePageFragmentDoc}
${IngestionCustomFieldDatasourceForDatasourcePageFragmentDoc}`;
export const PresetOrgUnitForDatasourcePageFragmentDoc = gql`
    fragment PresetOrgUnitForDatasourcePage on PresetOrgUnit {
  orgUnit {
    id
    name
  }
  orgUnitDatasource {
    id
  }
}
    `;
export const DatasourceFieldsForDatasourcePageFragmentDoc = gql`
    fragment DatasourceFieldsForDatasourcePage on Datasource {
  id
  name
  isUserConfirmed
  hasIntegrationOption
  hasIntegration
  customerTargetSchemaIds
  activeIntegrationConnection {
    id
    additionalIdentifier
  }
  instructions {
    ...InstructionsBundleForImportInstructions
  }
  dataset {
    id
    name
    datasources {
      id
      name
      customerTargetSchemaIds
    }
    canonicalDataset {
      ...CanonicalDatasetFieldsForImport
    }
    customExampleData {
      cells
    }
  }
  canonicalDatasource {
    ...CanonicalDatasourceForDatasourcePage
  }
  importInstructionsMd
  refreshCadence
  userUploadTasks {
    edges {
      node {
        ...UserUploadTaskFieldsForDatasourcePage
      }
    }
  }
  isUserConfirmed
  hasApiAccess
  notesFromWatershedMd
  presetCustomTags {
    ...PresetCustomTagForDatasourcePage
  }
  presetOrgUnits {
    ...PresetOrgUnitForDatasourcePage
  }
}
    ${InstructionsBundleForImportInstructionsFragmentDoc}
${CanonicalDatasetFieldsForImportFragmentDoc}
${CanonicalDatasourceForDatasourcePageFragmentDoc}
${UserUploadTaskFieldsForDatasourcePageFragmentDoc}
${PresetCustomTagForDatasourcePageFragmentDoc}
${PresetOrgUnitForDatasourcePageFragmentDoc}`;
export const UserUploadTaskWithDatasourceFragmentDoc = gql`
    fragment UserUploadTaskWithDatasource on UserUploadTask {
  ...UserUploadTaskFieldsForDatasourcePage
  datasource {
    ...DatasourceFieldsForDatasourcePage
  }
  facilitiesPreview {
    totalCount
  }
  hasIncompleteBuildings
  ... @defer {
    hasStaleSnapshot
  }
}
    ${UserUploadTaskFieldsForDatasourcePageFragmentDoc}
${DatasourceFieldsForDatasourcePageFragmentDoc}`;
export const ActivitySummaryActivityDatasetFragmentDoc = gql`
    fragment ActivitySummaryActivityDataset on ActivityDataset {
  businessActivityType
  updatedAt
  datasets {
    id
    name
  }
  lastUpdater {
    id
    displayName
  }
}
    `;
export const LegacyActivityRecordFragmentDoc = gql`
    fragment LegacyActivityRecord on ActivityRecord {
  stableId
  displayName
  datasets {
    id
    name
  }
  activityItemCreatedAt
}
    `;
export const CsrdDataRequirementsConfigFieldsFragmentDoc = gql`
    fragment CsrdDataRequirementsConfigFields on CsrdDataRequirementsConfig {
  id
  deletedAt
  createdAt
  configJson
  reportId
  editedByUser {
    id
    name
  }
}
    `;
export const MeasurementProjectForDataGovernanceFragmentDoc = gql`
    fragment MeasurementProjectForDataGovernance on MeasurementProject {
  id
  name
}
    `;
export const MeasurementProjectFieldsFragmentDoc = gql`
    fragment MeasurementProjectFields on MeasurementProject {
  id
  name
  active
  status
  createdAt
  completedAt
  coverageInterval
  coverageStartDate
  coverageEndDate
  deadline
}
    `;
export const MeasurementProjectsPageProjectFragmentDoc = gql`
    fragment MeasurementProjectsPageProject on MeasurementProject {
  ...MeasurementProjectFields
  setupBy {
    id
    name
    displayName
  }
  datasetsWithStages {
    id
    areAllTasksComplete
  }
}
    ${MeasurementProjectFieldsFragmentDoc}`;
export const SharedFileForDashboardPageFragmentDoc = gql`
    fragment SharedFileForDashboardPage on SharedFile {
  id
  expiresAt
  fileMetadata {
    id
    name
    size
    createdAt
    uploader {
      id
      name
    }
  }
  datasource {
    id
    name
    dataset {
      id
      name
    }
  }
}
    `;
export const UserForOrgStructureEditsFragmentDoc = gql`
    fragment UserForOrgStructureEdits on User {
  id
  displayName
  isWatershedEmployee
}
    `;
export const OrgStructureVersionFragmentDoc = gql`
    fragment OrgStructureVersion on OrgStructureVersion {
  id
  name
  isPublished
  updatedAt
  updatedBy {
    ...UserForOrgStructureEdits
  }
  effectiveFromDate
  effectiveToDate
}
    ${UserForOrgStructureEditsFragmentDoc}`;
export const OrgUnitTypeNameFragmentDoc = gql`
    fragment OrgUnitTypeName on OrgUnitType {
  id
  name
}
    `;
export const OrgUnitTypeFragmentDoc = gql`
    fragment OrgUnitType on OrgUnitType {
  id
  name
  externalId
  updatedAt
  updatedBy {
    ...UserForOrgStructureEdits
  }
  isLegalEntity
  enabledForAllDatasets
  requiredForAllDatasets
  enabledDatasets {
    id
    datasetId
    name
    isRequired
  }
  orgUnits {
    id
    name
    externalId
  }
  relationships {
    ...OrgUnitTypeRelationship
  }
  relatedOrgUnitTypes {
    ...OrgUnitTypeName
  }
}
    ${UserForOrgStructureEditsFragmentDoc}
${OrgUnitTypeRelationshipFragmentDoc}
${OrgUnitTypeNameFragmentDoc}`;
export const OrgUnitFragmentDoc = gql`
    fragment OrgUnit on OrgUnit {
  id
  name
  externalId
  description
  orgUnitTypeId
  relatedOrgUnits {
    id
    name
    orgUnitTypeId
  }
  orgUnitDatasources {
    id
    datasourceId
    orgUnitTypeStableId
    orgUnitStableId
    createdAt
    updatedAt
  }
}
    `;
export const OrganizationForOrgUnitTypePageFragmentDoc = gql`
    fragment OrganizationForOrgUnitTypePage on Organization {
  id
  datasets {
    id
    name
    explainer
  }
}
    `;
export const OrgUnitTypeWithOrgUnitsFragmentDoc = gql`
    fragment OrgUnitTypeWithOrgUnits on OrgUnitType {
  id
  name
  stableId
  orgUnits {
    id
    name
    stableId
  }
}
    `;
export const OrgStructureVersionWithTypesAndUnitsFragmentDoc = gql`
    fragment OrgStructureVersionWithTypesAndUnits on OrgStructureVersion {
  id
  orgUnitTypes {
    ...OrgUnitTypeWithOrgUnits
  }
}
    ${OrgUnitTypeWithOrgUnitsFragmentDoc}`;
export const ProductionGraphNodeFieldsFragmentDoc = gql`
    fragment ProductionGraphNodeFields on ProductionGraphNode {
  identifier
  name
  nodeType
  lifecycleStage
  downstreamNodeIdentifier
  autobomTier
  assumptions
  supplierName
  facilityName
  facilityLocation
  facilityCompany
  facilityDescription
  facilityAssumptions
  countryCodes
  inputRates {
    childNodeIdentifier
    rate
    unitChildPerUnit
  }
  outputMaterialName
  outputAmount
  outputAmountUnit
  roadDistance
  railDistance
  waterDistance
  airDistance
  roadDistanceUnit
  railDistanceUnit
  waterDistanceUnit
  airDistanceUnit
  emissionsKgco2e
  cumulativeEmissionsKgco2e
  biogenicEmissionsKgco2e
  cumulativeBiogenicEmissionsKgco2e
  flagEmissionsKgco2e
  cumulativeFlagEmissionsKgco2e
  ecoinventActivity
  ecoinventUnit
  ecoinventReferenceProductName
  ecoinventGeography
  ecoinventEmissionsFactor
  ecoinventIsicClassification
  ecoinventCpcClassification
  ecoinventHsCodeClassification
  ecoinventActivitiesRaw
  mappedEcoinventActivity
  unitConversionResponse
  rawProcessInputsResponse
  edits
  tags
  conversionFactorValue
  conversionFactorUnit
  conversionFactorCitation
  conversionFactorList
}
    `;
export const MaterialVariantDetailsFieldsFragmentDoc = gql`
    fragment MaterialVariantDetailsFields on MaterialVariant {
  id
  name
  materialName
  lifecycleAssessment {
    id
    productionGraphData {
      id
      nodes {
        ...ProductionGraphNodeFields
      }
    }
  }
}
    ${ProductionGraphNodeFieldsFragmentDoc}`;
export const UserInputtedForecastPeriodFieldsFragmentDoc = gql`
    fragment UserInputtedForecastPeriodFields on UserInputtedForecastPeriodPoint {
  date
  customGrowthFactors {
    customIntensityId
    value
  }
}
    `;
export const CreateEditCustomIntensityDialog_CustomIntensityConfigFragmentDoc = gql`
    fragment CreateEditCustomIntensityDialog_customIntensityConfig on CustomIntensityConfig {
  id
  name
  description
  unit
  humanReadable
  quantityType
  isSbtValidated
}
    `;
export const ManageCustomIntensities_CustomIntensityConfigFragmentDoc = gql`
    fragment manageCustomIntensities_customIntensityConfig on CustomIntensityConfig {
  ...CreateEditCustomIntensityDialog_customIntensityConfig
  id
  name
  description
  unit
  humanReadable
  quantityType
  isSbtValidated
}
    ${CreateEditCustomIntensityDialog_CustomIntensityConfigFragmentDoc}`;
export const BusinessCategoryForForecastingFragmentDoc = gql`
    fragment BusinessCategoryForForecasting on CategorizedEmissionData {
  businessCategory
}
    `;
export const ForecastFieldsFragmentDoc = gql`
    fragment ForecastFields on Forecast {
  id
  interval
  footprintSnapshotId
  footprintInterval
  scenarios {
    ...ForecastScenarioFields
  }
  referencePeriodInterval
  referencePeriod {
    ...ForecastPeriodFields
  }
  historicalPeriod {
    ...ForecastPeriodFields
  }
  overrideFootprintKind
  validYearsForIntervalEnd
  validReferenceInterval
  planTargets {
    id
    intensityType
    customIntensityConfigId
  }
  customIntensityConfigs {
    ...manageCustomIntensities_customIntensityConfig
    ...CustomIntensityConfigFields
  }
  businessCategories {
    ...BusinessCategoryForForecasting
  }
}
    ${ForecastScenarioFieldsFragmentDoc}
${ForecastPeriodFieldsFragmentDoc}
${ManageCustomIntensities_CustomIntensityConfigFragmentDoc}
${CustomIntensityConfigFieldsFragmentDoc}
${BusinessCategoryForForecastingFragmentDoc}`;
export const ReductionsMetricsPage_ForecastFragmentDoc = gql`
    fragment ReductionsMetricsPage_forecast on Forecast {
  id
  scenarios {
    id
    name
    growthForecasts {
      id
      customIntensityConfigId
    }
  }
  customIntensityConfigs {
    ...manageCustomIntensities_customIntensityConfig
    id
    name
    quantityType
    description
    unit
    humanReadable
    isSbtValidated
  }
}
    ${ManageCustomIntensities_CustomIntensityConfigFragmentDoc}`;
export const PlanForPlanSettingsFragmentDoc = gql`
    fragment PlanForPlanSettings on Plan {
  id
  name
  baselineYearStart
  isSbtValidated
  variables {
    commitmentsSBTSubmissionDate
    commitmentsSBTTermLength
    commitmentsSBTScope12
    commitmentsSBTScope3
  }
  forecast {
    id
    interval
    referencePeriod {
      interval
    }
    historicalPeriod {
      interval
    }
    validReferenceInterval
  }
  fiscalMonth
}
    `;
export const PublicDisclosureDisplayFragmentDoc = gql`
    fragment PublicDisclosureDisplay on PublicDisclosure {
  id
  companyId
  reportType
  publishingYear
  gsUrl
  publicUrl
  sectionUrls {
    id
    sectionKind
    urlFragment
  }
  company {
    id
    name
    fullLogoUrl
  }
}
    `;
export const PeerCompanyReportsFragmentDoc = gql`
    fragment PeerCompanyReports on PeerCompanyGroup {
  id
  members {
    id
    company {
      id
      disclosuresV2 {
        id
        publicDisclosure {
          ...PublicDisclosureDisplay
        }
      }
    }
  }
}
    ${PublicDisclosureDisplayFragmentDoc}`;
export const PermissionDetailsFragmentDoc = gql`
    fragment PermissionDetails on PermissionItem {
  id
  permission
  object {
    __typename
    id
  }
}
    `;
export const ActiveOrganizationFundFragmentDoc = gql`
    fragment ActiveOrganizationFund on Fund {
  id
  name
  nameSortable
  fundGroup
  fundCategory
  excludeAsSandbox
}
    `;
export const UserPermissionFieldsFragmentDoc = gql`
    fragment UserPermissionFields on UserPermission {
  id
  permission
  objectId
  objectType
}
    `;
export const FundAllFieldsFragmentDoc = gql`
    fragment FundAllFields on Fund {
  __typename
  id
  name
  nameSortable
  fundGroup
  fundCategory
  excludeAsSandbox
  anonymousFundUuid
  createdAt
  orgId
  updatedAt
  tagData
  externalId
}
    `;
export const ActiveOrganizationSavedViewFragmentDoc = gql`
    fragment ActiveOrganizationSavedView on FinanceSavedView {
  id
  name
}
    `;
export const ActiveOrganizationFieldsFragmentDoc = gql`
    fragment ActiveOrganizationFields on ActiveOrganization {
  id
  name
  helpChannel
  demoOrg
  testOrg
  stagingOrg
  region
  watershedPlan
  watershedPlanLegacy
  canAccessFinance
  canAccessCorporate
  fiscalYearStartMonth
  currency
  country
  companyId
  domains
  logoUrl
  iconUrl
  sessionId
  sessionTimeoutMinutes
  user {
    id
    createdAt
    name
    accessibleOrgs {
      id
      name
      companyId
    }
    email
    isWatershedEmployee
    isWatershedContractor
    isE2ETester
  }
  loginAsUser {
    id
    name
    isWatershedEmployee
    isWatershedContractor
    isE2ETester
  }
  userOnboardingsCompleted
  userPermissionsV2 {
    id
    ...UserPermissionFields
  }
  funds {
    id
    ...FundAllFields
  }
  financeSavedViews {
    id
    ...ActiveOrganizationSavedView
  }
}
    ${UserPermissionFieldsFragmentDoc}
${FundAllFieldsFragmentDoc}
${ActiveOrganizationSavedViewFragmentDoc}`;
export const FlagsInitFieldsFragmentDoc = gql`
    fragment FlagsInitFields on FeatureFlag {
  id
  name
  description
  team
  featureTags
  activated
}
    `;
export const PublicDisclosureFieldsFragmentDoc = gql`
    fragment PublicDisclosureFields on PublicDisclosure {
  id
  companyId
  reportType
  publishingYear
  publicUrl
  gsUrl
  qualityScore
  cdpVendorData {
    ...CdpVendorDataFields
  }
}
    ${CdpVendorDataFieldsFragmentDoc}`;
export const CompanyRelationshipFieldsFragmentDoc = gql`
    fragment CompanyRelationshipFields on CompanyRelationship {
  id
  companyA {
    id
    name
  }
  companyB {
    id
    name
  }
  relationshipType
  relationshipStartDate
  relationshipEndDate
}
    `;
export const CompanyFieldsForCcisWithDisclosuresFragmentDoc = gql`
    fragment CompanyFieldsForCCISWithDisclosures on Company {
  ...CompanyFieldsForCCIS
  publicDisclosures: disclosuresV2(
    opts: {publicDisclosureFilters: {}, historicalYearFilters: {allScopes: true}, climateCommitmentFilters: {}}
  ) {
    id
    publicDisclosure {
      ...CompanyPublicDisclosureFields
    }
    historicalEmissionsYears {
      ...CompanyDisclosureHistoricalEmissionsYearFields
    }
    climateCommitments {
      ...CompanyClimateCommitmentFieldsForCCIS
    }
  }
}
    ${CompanyFieldsForCcisFragmentDoc}
${CompanyPublicDisclosureFieldsFragmentDoc}
${CompanyDisclosureHistoricalEmissionsYearFieldsFragmentDoc}
${CompanyClimateCommitmentFieldsForCcisFragmentDoc}`;
export const EngagementTaskAnswerFieldsFragmentDoc = gql`
    fragment EngagementTaskAnswerFields on EngagementTaskAnswer {
  id
  questionKey
  answer
  skipped
  questionIndex
  attachments {
    originalFilename
    signedDownloadUrl
    questionKey
    fileMetadata {
      id
      createdAt
    }
  }
}
    `;
export const CompanyFootprintEstimateOutputFieldsFragmentDoc = gql`
    fragment CompanyFootprintEstimateOutputFields on FootprintEstimateOutput {
  id
  output {
    scope1
    scope2Market
    scope2Location
    scope3
    scope301
    scope302
    scope303
    scope304
    scope305
    scope306
    scope307
    scope308
    scope309
    scope310
    scope311
    scope312
    scope313
    scope314
    scope315
    scope316
    scope317
  }
}
    `;
export const EngagementTaskContentsFragmentDoc = gql`
    fragment EngagementTaskContents on EngagementTask {
  ...EngagementTaskFields
  id
  submittedAnswers {
    ...EngagementTaskAnswerFields
  }
  comments {
    id
    createdAt
    userEditorId
    userEditorName
    creatorOrgId
    comment
  }
  edits {
    id
    createdAt
    userEditorId
    userEditorName
    creatorOrgId
    columnName
    newValue
    valueType
  }
  footprintEstimate {
    ...CompanyFootprintEstimateOutputFields
  }
}
    ${EngagementTaskFieldsFragmentDoc}
${EngagementTaskAnswerFieldsFragmentDoc}
${CompanyFootprintEstimateOutputFieldsFragmentDoc}`;
export const MeasurementProjectForDemoPlatformFragmentDoc = gql`
    fragment MeasurementProjectForDemoPlatform on MeasurementProject {
  id
  name
  coverageStartDate
  coverageEndDate
  active
}
    `;
export const DemoPlatformRunForPageFragmentDoc = gql`
    fragment DemoPlatformRunForPage on DemoPlatformRun {
  id
  config
  statusJson
  user {
    id
    name
  }
  createdAt
}
    `;
export const DemoPlatformRunForGridFragmentDoc = gql`
    fragment DemoPlatformRunForGrid on DemoPlatformRun {
  id
  config
  statusJson
  user {
    id
    name
  }
  createdAt
}
    `;
export const FinanceSnapshotIsStaleFragmentDoc = gql`
    fragment FinanceSnapshotIsStale on FinanceSnapshot {
  id
  isStale
}
    `;
export const FinanceSnapshotForContextFragmentDoc = gql`
    fragment FinanceSnapshotForContext on FinanceSnapshot {
  id
  snapshotName
  note
  config
  createdAt
  createdBy {
    id
    name
    displayName
  }
  executionStatus
  executionStatusMessage
  completedAt
  isStale
  flagsPopulated
  footprintSnapshotId
  footprintInterval
}
    `;
export const SupplierContactAllFieldsFragmentDoc = gql`
    fragment SupplierContactAllFields on SupplierContact {
  id
  name
  email
  role
  companyId
  userId
}
    `;
export const FootprintSnapshotInfoForStatusFragmentDoc = gql`
    fragment FootprintSnapshotInfoForStatus on FootprintSnapshot {
  id
  createdAt
  status
  footprint {
    id
    latestPublishedFootprintSnapshot {
      id
      createdAt
    }
    latestDraftFootprintSnapshot {
      id
      createdAt
    }
  }
}
    `;
export const CompanyAutocompleteResultWithSourceFragmentDoc = gql`
    fragment CompanyAutocompleteResultWithSource on Company {
  ...CompanyAutocompleteResult
  source
}
    ${CompanyAutocompleteResultFragmentDoc}`;
export const EmailPreview_EmailContentsFragmentDoc = gql`
    fragment EmailPreview_emailContents on EmailContents {
  recipients {
    email
    name
  }
  ccs {
    email
    name
  }
  sender {
    email
    name
  }
  subject
  bodyHtml
  bccs {
    email
    name
  }
}
    `;
export const PageInfoFragmentDoc = gql`
    fragment PageInfo on PageInfo {
  hasNextPage
  hasPreviousPage
  startCursor
  endCursor
}
    `;
export const PageInfoWithCountFragmentDoc = gql`
    fragment PageInfoWithCount on PageInfo {
  hasNextPage
  hasPreviousPage
  startCursor
  endCursor
  totalRowCount
}
    `;
export const ActivityTypeSchemaFragmentDoc = gql`
    fragment ActivityTypeSchema on ActivityTypeSchema {
  id
  activityType
  schema {
    id
    jsonSchema
  }
}
    `;
export const BuildingDiffFragmentDoc = gql`
    fragment BuildingDiff on CadtBuildingDiff {
  old {
    ...BuildingInfo
  }
  new {
    ...BuildingInfo
  }
  errors {
    message
    adminMessage
    code
  }
  buildingPresentInBart
  warnings {
    adminMessage
    message
    code
  }
}
    ${BuildingInfoFragmentDoc}`;
export const CustomFieldForBuldingDiffFragmentDoc = gql`
    fragment CustomFieldForBuldingDiff on IngestionCustomField {
  id
  name
}
    `;
export const OrgUnitTypeForBuildingDiffFragmentDoc = gql`
    fragment OrgUnitTypeForBuildingDiff on OrgUnitType {
  id
  name
  stableId
}
    `;
export const OrgUnitForBuildingDiffFragmentDoc = gql`
    fragment OrgUnitForBuildingDiff on OrgUnit {
  id
  name
  stableId
}
    `;
export const ReviewDocumentParseAttemptFragmentDoc = gql`
    fragment ReviewDocumentParseAttempt on DocumentParseAttempt {
  id
  userUploadId
  modelType
  createdAt
  updatedAt
  utilityUsageData {
    energyConsumptionAmount
    energyConsumptionUnit
    receiverAddress
    serviceAddress
    serviceStartDate
    serviceEndDate
  }
}
    `;
export const FinanceTagForGridFragmentDoc = gql`
    fragment FinanceTagForGrid on FinanceTag {
  id
  name
  description
  columnFormat
  columnConfiguration
  tagType
}
    `;
export const UserUploadTaskFieldsFragmentDoc = gql`
    fragment UserUploadTaskFields on UserUploadTask {
  id
  assignee {
    id
    name
  }
  datasource {
    id
    dataset {
      id
      name
    }
  }
  measurementProject {
    id
    name
    active
    coverageStartDate
    coverageEndDate
    completedAt
  }
}
    `;
export const AssignableUserFieldsFragmentDoc = gql`
    fragment AssignableUserFields on User {
  id
  name
  displayName
}
    `;
export const ReportItemUiFragmentDoc = gql`
    fragment ReportItemUI on IReportItem {
  id
  itemNumber
  label
  sublabel
  optional
  optionalMessage
}
    `;
export const EngagementTaskConfigForTaskCreationFragmentDoc = gql`
    fragment EngagementTaskConfigForTaskCreation on EngagementTaskConfig {
  id
  name
  orgId
  order
  category
  rootFacingDescription
  defaultSupplierFacingDescription
  crossOrgModelId
  engagementTaskInputs
  type
  optionalEngagementTaskInputs
  timeNeeded
  type
}
    `;
export const BusinessActivityTypeLiteFragmentDoc = gql`
    fragment BusinessActivityTypeLite on BusinessActivityType {
  id
  versionName
  versionId
  name
  schemaJson
  tSchema
}
    `;
export const AllBusinessActivityTypeFieldFieldsFragmentDoc = gql`
    fragment AllBusinessActivityTypeFieldFields on BusinessActivityTypeField {
  id
  typeId
  versionId
  description
  fieldName
  fieldType
  fieldValueOneOf
  isRequired
  isProratable
  unitFieldName
}
    `;
export const AllBusinessActivityTypeFieldsFragmentDoc = gql`
    fragment AllBusinessActivityTypeFields on BusinessActivityType {
  id
  versionId
  name
  description
  fields {
    ...AllBusinessActivityTypeFieldFields
  }
}
    ${AllBusinessActivityTypeFieldFieldsFragmentDoc}`;
export const PermissionOriginForFetchOrgUsersForPermissionFragmentDoc = gql`
    fragment PermissionOriginForFetchOrgUsersForPermission on UserPermissionOrigin {
  id
  permission {
    id
    permission
    object {
      id
      name
    }
  }
  role {
    id
    role {
      id
      name
    }
  }
}
    `;
export const UserForAllOrgUsersForPermissionFragmentDoc = gql`
    fragment UserForAllOrgUsersForPermission on User {
  id
  name
  orgAccessId(orgId: $orgId)
  permissionOrigin(permissions: $permissions, orgId: $orgId) {
    ...PermissionOriginForFetchOrgUsersForPermission
  }
}
    ${PermissionOriginForFetchOrgUsersForPermissionFragmentDoc}`;
export const UserForOrgUsersWithPermissionsFragmentDoc = gql`
    fragment UserForOrgUsersWithPermissions on User {
  id
  name
  permissions(orgId: $orgId) {
    id
    permission
  }
  roles(orgId: $orgId) {
    id
    role {
      id
      permissions {
        id
        permission
      }
    }
  }
}
    `;
export const SerializableErrorFieldsFragmentDoc = gql`
    fragment SerializableErrorFields on SerializableError {
  message
  code
  stackTrace
  errorType
  details
}
    `;
export const OverviewReportConfigFieldsFragmentDoc = gql`
    fragment OverviewReportConfigFields on ReportConfig {
  id
  reportType
  shortName
  longName
  description
  brandColor
  status
  isHiddenFromOverview
  isCreationBlocked
  isRegulatoryExposureBlocked
  isCorporateReport
  isFinanceReport
  requiredPermissions
  reportObjectives
}
    `;
export const GetBetterCompanyDocument = gql`
    query getBetterCompany($companyId: ID!) {
  companyForDashboard(id: $companyId, disableBetterCompanyUpgrade: true) {
    id
    betterCompanyId
  }
}
    `;

export function useGetBetterCompanyQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetBetterCompanyQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetBetterCompanyQuery,
    GQTypes.GQGetBetterCompanyQueryVariables
  >({ query: GetBetterCompanyDocument, ...options });
}
export const ForbiddenPageDataDocument = gql`
    query ForbiddenPageData($missingPermission: PermissionType!) @withOwner(owner: EnterpriseFoundations) {
  adminUsers {
    ...UserContactFields
  }
  pendingUserPermissionRequestsForUser(permission: $missingPermission) {
    ...UserPermissionRequestFields
  }
}
    ${UserContactFieldsFragmentDoc}
${UserPermissionRequestFieldsFragmentDoc}`;

export function useForbiddenPageDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQForbiddenPageDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQForbiddenPageDataQuery,
    GQTypes.GQForbiddenPageDataQueryVariables
  >({ query: ForbiddenPageDataDocument, ...options });
}
export const CreateUserPermissionRequestDocument = gql`
    mutation CreateUserPermissionRequest($input: CreateUserPermissionRequestInput!) @withOwner(owner: EnterpriseFoundations) {
  createUserPermissionRequest(input: $input) {
    userPermissionRequest {
      ...UserPermissionRequestFields
    }
  }
}
    ${UserPermissionRequestFieldsFragmentDoc}`;

export function useCreateUserPermissionRequestMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateUserPermissionRequestMutation,
    GQTypes.GQCreateUserPermissionRequestMutationVariables
  >(CreateUserPermissionRequestDocument);
}
export const TypeaheadUserDocument = gql`
    query TypeaheadUser($search: String!) {
  usersFuzzySearch(q: $search) {
    ...UserSearchResult
  }
}
    ${UserSearchResultFragmentDoc}`;

export function useTypeaheadUserQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQTypeaheadUserQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQTypeaheadUserQuery,
    GQTypes.GQTypeaheadUserQueryVariables
  >({ query: TypeaheadUserDocument, ...options });
}
export const ActivityDataTabDataIngestionDocument = gql`
    query ActivityDataTabDataIngestion($activityType: String!) @withOwner(owner: DataIngestion) {
  uploadsForActivityType(activityType: $activityType) {
    edges {
      node {
        ...ActivityDataTabDataIngestion_Upload
      }
    }
  }
}
    ${ActivityDataTabDataIngestion_UploadFragmentDoc}`;

export function useActivityDataTabDataIngestionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQActivityDataTabDataIngestionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQActivityDataTabDataIngestionQuery,
    GQTypes.GQActivityDataTabDataIngestionQueryVariables
  >({ query: ActivityDataTabDataIngestionDocument, ...options });
}
export const ArchiveUploadDocument = gql`
    mutation ArchiveUpload($uploadVersionId: ID!) @withOwner(owner: DataIngestion) {
  archiveUpload(input: {uploadVersionId: $uploadVersionId}) {
    upload {
      ...ActivityDataTabDataIngestion_Upload
    }
  }
}
    ${ActivityDataTabDataIngestion_UploadFragmentDoc}`;

export function useArchiveUploadMutation() {
  return Urql.useMutation<
    GQTypes.GQArchiveUploadMutation,
    GQTypes.GQArchiveUploadMutationVariables
  >(ArchiveUploadDocument);
}
export const ActivityDatasetsForUploadsTabDocument = gql`
    query ActivityDatasetsForUploadsTab($input: BiActivityQueryContextInput!) @withOwner(owner: DatasetsAndObjects) {
  activityContextMetadata(input: $input) {
    activityDatasets {
      businessActivityType
      uploads {
        ...ActivityDataUserUpload
      }
    }
  }
  activeMeasurementProjects(limit: 1) {
    id
  }
}
    ${ActivityDataUserUploadFragmentDoc}`;

export function useActivityDatasetsForUploadsTabQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQActivityDatasetsForUploadsTabQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQActivityDatasetsForUploadsTabQuery,
    GQTypes.GQActivityDatasetsForUploadsTabQueryVariables
  >({ query: ActivityDatasetsForUploadsTabDocument, ...options });
}
export const AiAgentProcessMessageDocument = gql`
    query AIAgentProcessMessage($message: String!, $featureId: String!, $context: JSONString!, $chatHistory: JSONString, $commandResults: JSONString) @withOwner(owner: SupplyChain) {
  aiAgentProcessMessage(
    message: $message
    featureId: $featureId
    context: $context
    chatHistory: $chatHistory
    commandResults: $commandResults
  ) {
    sender
    content
    commandRequests {
      command {
        id
        actionName
        args
      }
      status
    }
  }
}
    `;

export function useAiAgentProcessMessageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAiAgentProcessMessageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAiAgentProcessMessageQuery,
    GQTypes.GQAiAgentProcessMessageQueryVariables
  >({ query: AiAgentProcessMessageDocument, ...options });
}
export const GenerateTemplateDownloadUrlForOrgDocument = gql`
    mutation GenerateTemplateDownloadUrlForOrg($input: GenerateTemplateDownloadUrlForOrgDatasetsInput!) @withOwner(owner: DataIngestion) {
  generateTemplateDownloadUrlForOrgDatasets(input: $input) {
    templateDownloadUrl
  }
}
    `;

export function useGenerateTemplateDownloadUrlForOrgMutation() {
  return Urql.useMutation<
    GQTypes.GQGenerateTemplateDownloadUrlForOrgMutation,
    GQTypes.GQGenerateTemplateDownloadUrlForOrgMutationVariables
  >(GenerateTemplateDownloadUrlForOrgDocument);
}
export const ExportSidebarPermissionsDocument = gql`
    query ExportSidebarPermissions @withOwner(owner: Calcprint) {
  showLinkToDataGovernance: userHasPermission(permission: ManageMeasurement)
  showLinkToPermissions: userHasPermission(permission: Admin)
}
    `;

export function useExportSidebarPermissionsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQExportSidebarPermissionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQExportSidebarPermissionsQuery,
    GQTypes.GQExportSidebarPermissionsQueryVariables
  >({ query: ExportSidebarPermissionsDocument, ...options });
}
export const AuditReportMetadataDocument = gql`
    query AuditReportMetadata($input: AuditReportMetadataInput!) @withOwner(owner: DataIngestion) {
  auditReportMetadata(input: $input) {
    columns {
      column
      title
      description
    }
  }
}
    `;

export function useAuditReportMetadataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAuditReportMetadataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAuditReportMetadataQuery,
    GQTypes.GQAuditReportMetadataQueryVariables
  >({ query: AuditReportMetadataDocument, ...options });
}
export const FootprintExportListDocument = gql`
    query FootprintExportList($after: String, $before: String, $first: Int, $last: Int) @withOwner(owner: Calcprint) {
  footprintExports(after: $after, before: $before, first: $first, last: $last) {
    edges {
      cursor
      node {
        ...FootprintExportForList
      }
    }
    pageInfo {
      totalRowCount
    }
  }
}
    ${FootprintExportForListFragmentDoc}`;

export function useFootprintExportListQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFootprintExportListQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFootprintExportListQuery,
    GQTypes.GQFootprintExportListQueryVariables
  >({ query: FootprintExportListDocument, ...options });
}
export const DeleteFootprintExportDocument = gql`
    mutation DeleteFootprintExport($input: DeleteFootprintExportInput!) @withOwner(owner: Calcprint) {
  deleteFootprintExport(input: $input) {
    auditBundleId
  }
}
    `;

export function useDeleteFootprintExportMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteFootprintExportMutation,
    GQTypes.GQDeleteFootprintExportMutationVariables
  >(DeleteFootprintExportDocument);
}
export const CanViewAnyFootprintDocument = gql`
    query CanViewAnyFootprint @withOwner(owner: Calcprint) {
  userHasPermission(permission: ViewFootprint, allowAnyObject: true)
}
    `;

export function useCanViewAnyFootprintQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCanViewAnyFootprintQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCanViewAnyFootprintQuery,
    GQTypes.GQCanViewAnyFootprintQueryVariables
  >({ query: CanViewAnyFootprintDocument, ...options });
}
export const CanViewFootprintExportsDocument = gql`
    query CanViewFootprintExports @withOwner(owner: Calcprint) {
  userHasPermission(permission: ViewAuditDetail)
}
    `;

export function useCanViewFootprintExportsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCanViewFootprintExportsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCanViewFootprintExportsQuery,
    GQTypes.GQCanViewFootprintExportsQueryVariables
  >({ query: CanViewFootprintExportsDocument, ...options });
}
export const AddUserUploadCandidatesToFileFeedEmbeddedSessionDocument = gql`
    mutation AddUserUploadCandidatesToFileFeedEmbeddedSession($input: AddUserUploadCandidatesToFileFeedEmbeddedSessionInput!) @withOwner(owner: DataIngestion) {
  addUserUploadCandidatesToFileFeedEmbeddedSession(input: $input)
}
    `;

export function useAddUserUploadCandidatesToFileFeedEmbeddedSessionMutation() {
  return Urql.useMutation<
    GQTypes.GQAddUserUploadCandidatesToFileFeedEmbeddedSessionMutation,
    GQTypes.GQAddUserUploadCandidatesToFileFeedEmbeddedSessionMutationVariables
  >(AddUserUploadCandidatesToFileFeedEmbeddedSessionDocument);
}
export const StartFileFeedEmbeddedSessionDocument = gql`
    mutation StartFileFeedEmbeddedSession($input: StartFileFeedEmbeddedSessionInput!) @withOwner(owner: DataIngestion) {
  startFileFeedEmbeddedSession(input: $input) {
    oneSchemaEmbedUserJwt
    embeddedSessionToken
  }
}
    `;

export function useStartFileFeedEmbeddedSessionMutation() {
  return Urql.useMutation<
    GQTypes.GQStartFileFeedEmbeddedSessionMutation,
    GQTypes.GQStartFileFeedEmbeddedSessionMutationVariables
  >(StartFileFeedEmbeddedSessionDocument);
}
export const ValidateUserUploadCandidateDocument = gql`
    mutation ValidateUserUploadCandidate($id: ID!) @withOwner(owner: DataIngestion) {
  validateUserUploadCandidate(userUploadCandidateId: $id) {
    ...UserUploadCandidate
  }
}
    ${UserUploadCandidateFragmentDoc}`;

export function useValidateUserUploadCandidateMutation() {
  return Urql.useMutation<
    GQTypes.GQValidateUserUploadCandidateMutation,
    GQTypes.GQValidateUserUploadCandidateMutationVariables
  >(ValidateUserUploadCandidateDocument);
}
export const DeleteCtsvOneSchemaFileFeedDocument = gql`
    mutation DeleteCtsvOneSchemaFileFeed($id: ID!) @withOwner(owner: DataIngestion) {
  deleteCtsvOneSchemaFileFeed(ctsvOneSchemaFileFeedId: $id) {
    ...UploadSchemaDetailVersion
  }
}
    ${UploadSchemaDetailVersionFragmentDoc}`;

export function useDeleteCtsvOneSchemaFileFeedMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCtsvOneSchemaFileFeedMutation,
    GQTypes.GQDeleteCtsvOneSchemaFileFeedMutationVariables
  >(DeleteCtsvOneSchemaFileFeedDocument);
}
export const UploadSchemaDetailDocument = gql`
    query UploadSchemaDetail($datasetId: ID!, $schemaId: ID!) @withOwner(owner: DataIngestion) {
  dataset(id: $datasetId) {
    id
    name
    canonicalDataset {
      id
      instructions {
        sdiInstructionsIntroMd
      }
    }
  }
  customerTargetSchema(id: $schemaId) {
    id
    name
    versions {
      id
      ...UploadSchemaDetailVersion
    }
    publishedVersions {
      id
      ...UploadSchemaDetailVersion
    }
  }
}
    ${UploadSchemaDetailVersionFragmentDoc}`;

export function useUploadSchemaDetailQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQUploadSchemaDetailQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQUploadSchemaDetailQuery,
    GQTypes.GQUploadSchemaDetailQueryVariables
  >({ query: UploadSchemaDetailDocument, ...options });
}
export const UploadSchemaExplorerDocument = gql`
    query UploadSchemaExplorer @withOwner(owner: DataIngestion) {
  datasets(sortBy: "name") {
    id
    ...UploadSchemaExplorerDataset
  }
}
    ${UploadSchemaExplorerDatasetFragmentDoc}`;

export function useUploadSchemaExplorerQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQUploadSchemaExplorerQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQUploadSchemaExplorerQuery,
    GQTypes.GQUploadSchemaExplorerQueryVariables
  >({ query: UploadSchemaExplorerDocument, ...options });
}
export const UserUploadCandidateDocument = gql`
    query UserUploadCandidate($id: ID!) @withOwner(owner: DataIngestion) {
  userUploadCandidate(userUploadCandidateId: $id) {
    ...UserUploadCandidate
  }
}
    ${UserUploadCandidateFragmentDoc}`;

export function useUserUploadCandidateQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQUserUploadCandidateQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQUserUploadCandidateQuery,
    GQTypes.GQUserUploadCandidateQueryVariables
  >({ query: UserUploadCandidateDocument, ...options });
}
export const CreateOneSchemaSessionForApiUploadDocument = gql`
    mutation CreateOneSchemaSessionForApiUpload($input: CreateOneSchemaSessionForApiUploadInput!) @withOwner(owner: DataIngestion) {
  createOneSchemaSessionForApiUpload(input: $input) {
    oneSchemaEmbedId
    oneSchemaSessionToken
    oneSchemaEmbedStatus
    oneSchemaEmbedUserJwt
    fileMetadataId
    fileName
    signedUrl
    userUploadTaskId
    measurementProjectId
  }
}
    `;

export function useCreateOneSchemaSessionForApiUploadMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateOneSchemaSessionForApiUploadMutation,
    GQTypes.GQCreateOneSchemaSessionForApiUploadMutationVariables
  >(CreateOneSchemaSessionForApiUploadDocument);
}
export const CreateCtsvOneSchemaFileFeedDocument = gql`
    mutation CreateCtsvOneSchemaFileFeed($ctsVersionId: ID!, $userUploadCandidateId: ID!) @withOwner(owner: DataIngestion) {
  createCtsvOneSchemaFileFeed(
    input: {ctsVersionId: $ctsVersionId, userUploadCandidateId: $userUploadCandidateId}
  ) {
    ...UploadSchemaDetailVersion
  }
}
    ${UploadSchemaDetailVersionFragmentDoc}`;

export function useCreateCtsvOneSchemaFileFeedMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCtsvOneSchemaFileFeedMutation,
    GQTypes.GQCreateCtsvOneSchemaFileFeedMutationVariables
  >(CreateCtsvOneSchemaFileFeedDocument);
}
export const GroupedAuditLogsDocument = gql`
    query GroupedAuditLogs($eventKinds: [String!]!, $after: String, $before: String, $first: Int, $last: Int, $filters: UntypedData) @withOwner(owner: EnterpriseFoundations) {
  groupedAuditLogs(
    eventKinds: $eventKinds
    after: $after
    before: $before
    first: $first
    last: $last
    filters: $filters
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        ...GroupedAuditLogEntryFields
      }
    }
  }
}
    ${GroupedAuditLogEntryFieldsFragmentDoc}`;

export function useGroupedAuditLogsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGroupedAuditLogsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGroupedAuditLogsQuery,
    GQTypes.GQGroupedAuditLogsQueryVariables
  >({ query: GroupedAuditLogsDocument, ...options });
}
export const AddCompaniesToPeerCompanyGroupDashboardDocument = gql`
    mutation AddCompaniesToPeerCompanyGroupDashboard($input: AddCompaniesToPeerCompanyGroupsInput!) @withOwner(owner: SupplyChain) {
  addCompaniesToPeerCompanyGroups(input: $input) {
    peerCompanyGroups {
      ...PeerCompanyGroupForBenchmarks
    }
  }
}
    ${PeerCompanyGroupForBenchmarksFragmentDoc}`;

export function useAddCompaniesToPeerCompanyGroupDashboardMutation() {
  return Urql.useMutation<
    GQTypes.GQAddCompaniesToPeerCompanyGroupDashboardMutation,
    GQTypes.GQAddCompaniesToPeerCompanyGroupDashboardMutationVariables
  >(AddCompaniesToPeerCompanyGroupDashboardDocument);
}
export const PeerCompanyGroupsForAddMemberDocument = gql`
    query PeerCompanyGroupsForAddMember @withOwner(owner: SupplyChain) {
  peerCompanyGroups {
    id
    name
  }
}
    `;

export function usePeerCompanyGroupsForAddMemberQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQPeerCompanyGroupsForAddMemberQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPeerCompanyGroupsForAddMemberQuery,
    GQTypes.GQPeerCompanyGroupsForAddMemberQueryVariables
  >({ query: PeerCompanyGroupsForAddMemberDocument, ...options });
}
export const CompanyBenchmarkDocument = gql`
    query CompanyBenchmark($id: ID!) @withOwner(owner: SupplyChain) {
  company(id: $id) {
    ...CompanyForBenchmarks
  }
}
    ${CompanyForBenchmarksFragmentDoc}`;

export function useCompanyBenchmarkQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCompanyBenchmarkQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCompanyBenchmarkQuery,
    GQTypes.GQCompanyBenchmarkQueryVariables
  >({ query: CompanyBenchmarkDocument, ...options });
}
export const DeletePeerCompanyGroupDocument = gql`
    mutation DeletePeerCompanyGroup($input: DeletePeerCompanyGroupInput!) @withOwner(owner: SupplyChain) {
  deletePeerCompanyGroup(input: $input) {
    id
  }
}
    `;

export function useDeletePeerCompanyGroupMutation() {
  return Urql.useMutation<
    GQTypes.GQDeletePeerCompanyGroupMutation,
    GQTypes.GQDeletePeerCompanyGroupMutationVariables
  >(DeletePeerCompanyGroupDocument);
}
export const UpdatePeerCompanyGroupDocument = gql`
    mutation UpdatePeerCompanyGroup($input: UpdatePeerCompanyGroupInput!) @withOwner(owner: SupplyChain) {
  updatePeerCompanyGroup(input: $input) {
    id
    peerCompanyGroup {
      id
      name
      shouldHideFromFootprintOverview
      revenueForRevenueAtRisk
    }
  }
}
    `;

export function useUpdatePeerCompanyGroupMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdatePeerCompanyGroupMutation,
    GQTypes.GQUpdatePeerCompanyGroupMutationVariables
  >(UpdatePeerCompanyGroupDocument);
}
export const CreatePeerCompanyGroupDocument = gql`
    mutation CreatePeerCompanyGroup($input: CreatePeerCompanyGroupInput!) @withOwner(owner: SupplyChain) {
  createPeerCompanyGroup(input: $input) {
    id
    peerCompanyGroup {
      ...PeerCompanyGroupForBenchmarks
    }
  }
}
    ${PeerCompanyGroupForBenchmarksFragmentDoc}`;

export function useCreatePeerCompanyGroupMutation() {
  return Urql.useMutation<
    GQTypes.GQCreatePeerCompanyGroupMutation,
    GQTypes.GQCreatePeerCompanyGroupMutationVariables
  >(CreatePeerCompanyGroupDocument);
}
export const GetBiMethodologyExplainerContentDocument = gql`
    query GetBiMethodologyExplainerContent($footprintSnapshotId: String!, $measureSelector: BiQuerySingleMeasureSelectorInput!, $companyId: ID, $footprintId: ID) @withOwner(owner: DatasetsAndObjects) {
  userHasPermission(permission: ViewFootprint, objectId: $footprintId)
  companyForDashboard(id: $companyId) {
    ...CompanyForMethodologyExplainer
  }
  methodologyExplainer(footprintSnapshotId: $footprintSnapshotId) {
    id
    biGhgCategoryIds(singleMeasureSelector: $measureSelector)
    biCalculationChains(singleMeasureSelector: $measureSelector) {
      ...CalculationChainForMethodologyExplainer
    }
    biCalculationTraces(singleMeasureSelector: $measureSelector)
    biExampleTracesForFingerprints(singleMeasureSelector: $measureSelector) {
      tracesByFingerprint
      referenceValueDetails {
        refDataCellPath
        description
        citation {
          displayName
          notes
          url
          publicDisclosureId
          isCustomerProvided
          org {
            orgName
            orgId
          }
        }
      }
      emissionsModelVariableDetails {
        emissionsModelEvaluationId
        variableName
        displayName
        description
      }
      bartColumnDetails {
        emissionsModelEvaluationId
        columnName
        displayName
        description
      }
    }
  }
}
    ${CompanyForMethodologyExplainerFragmentDoc}
${CalculationChainForMethodologyExplainerFragmentDoc}`;

export function useGetBiMethodologyExplainerContentQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetBiMethodologyExplainerContentQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetBiMethodologyExplainerContentQuery,
    GQTypes.GQGetBiMethodologyExplainerContentQueryVariables
  >({ query: GetBiMethodologyExplainerContentDocument, ...options });
}
export const ExecuteBiAiQueryDocument = gql`
    query ExecuteBiAiQuery($input: BiAiQueryInput!) @withOwner(owner: DatasetsAndObjects) {
  biAiQuery(input: $input) {
    payload {
      dimensions
      filters {
        dimension
        operator
        value
      }
      normalizedMeasureId
      timeIntervals
    }
  }
}
    `;

export function useExecuteBiAiQueryQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQExecuteBiAiQueryQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQExecuteBiAiQueryQuery,
    GQTypes.GQExecuteBiAiQueryQueryVariables
  >({ query: ExecuteBiAiQueryDocument, ...options });
}
export const CreateBiSavedViewAndPropagateToCustomReportsDocument = gql`
    mutation CreateBiSavedViewAndPropagateToCustomReports($input: CreateBiSavedViewAndPropagateToCustomReportsInput!) @withOwner(owner: DatasetsAndObjects) {
  createBiSavedViewAndPropagateToCustomReports(input: $input) {
    ...BiSavedView
  }
}
    ${BiSavedViewFragmentDoc}`;

export function useCreateBiSavedViewAndPropagateToCustomReportsMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateBiSavedViewAndPropagateToCustomReportsMutation,
    GQTypes.GQCreateBiSavedViewAndPropagateToCustomReportsMutationVariables
  >(CreateBiSavedViewAndPropagateToCustomReportsDocument);
}
export const UpdateBiSavedViewDocument = gql`
    mutation UpdateBiSavedView($input: UpdateBiSavedViewInput!) @withOwner(owner: DatasetsAndObjects) {
  updateBiSavedView(input: $input) {
    ...BiSavedView
  }
}
    ${BiSavedViewFragmentDoc}`;

export function useUpdateBiSavedViewMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateBiSavedViewMutation,
    GQTypes.GQUpdateBiSavedViewMutationVariables
  >(UpdateBiSavedViewDocument);
}
export const RenameBiSavedViewDocument = gql`
    mutation RenameBiSavedView($input: RenameBiSavedViewInput!) @withOwner(owner: DatasetsAndObjects) {
  renameBiSavedView(input: $input) {
    ...BiSavedView
  }
}
    ${BiSavedViewFragmentDoc}`;

export function useRenameBiSavedViewMutation() {
  return Urql.useMutation<
    GQTypes.GQRenameBiSavedViewMutation,
    GQTypes.GQRenameBiSavedViewMutationVariables
  >(RenameBiSavedViewDocument);
}
export const DeleteBiSavedViewDocument = gql`
    mutation DeleteBiSavedView($input: DeleteBiSavedViewInput!) @withOwner(owner: DatasetsAndObjects) {
  deleteBiSavedView(input: $input) {
    ...BiSavedView
  }
}
    ${BiSavedViewFragmentDoc}`;

export function useDeleteBiSavedViewMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteBiSavedViewMutation,
    GQTypes.GQDeleteBiSavedViewMutationVariables
  >(DeleteBiSavedViewDocument);
}
export const BiMetadataDocument = gql`
    query BiMetadata($input: BiQueryMetadataInput!) @withOwner(owner: DatasetsAndObjects) {
  biQueryMetadata(input: $input) {
    id
    ...BiQueryMetadataFields
  }
}
    ${BiQueryMetadataFieldsFragmentDoc}`;

export function useBiMetadataQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQBiMetadataQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQBiMetadataQuery,
    GQTypes.GQBiMetadataQueryVariables
  >({ query: BiMetadataDocument, ...options });
}
export const BiDataV2ParityDocument = gql`
    query BiDataV2Parity($input: BiQueryDataInputLegacy!) @withOwner(owner: DatasetsAndObjects) {
  biQueryDataV2Parity(input: $input) {
    ...BiQueryDataResultFields
  }
}
    ${BiQueryDataResultFieldsFragmentDoc}`;

export function useBiDataV2ParityQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBiDataV2ParityQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBiDataV2ParityQuery,
    GQTypes.GQBiDataV2ParityQueryVariables
  >({ query: BiDataV2ParityDocument, ...options });
}
export const BiDataV2Document = gql`
    query BiDataV2($input: BiQueryDataInput!) @withOwner(owner: DatasetsAndObjects) {
  biQueryDataV2(input: $input) {
    ...BiQueryDataResultFields
  }
}
    ${BiQueryDataResultFieldsFragmentDoc}`;

export function useBiDataV2Query(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQBiDataV2QueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQBiDataV2Query,
    GQTypes.GQBiDataV2QueryVariables
  >({ query: BiDataV2Document, ...options });
}
export const BiDataCombinedDocument = gql`
    query BiDataCombined($inputV1: BiQueryDataInputLegacy!, $inputV2: BiQueryDataInput!, $queryV1: Boolean!, $queryV2Parity: Boolean!, $queryV2: Boolean!) @withOwner(owner: DatasetsAndObjects) {
  biQueryData(input: $inputV1) @include(if: $queryV1) {
    ...BiQueryDataResultFields
  }
  biQueryDataV2Parity(input: $inputV1) @include(if: $queryV2Parity) {
    ...BiQueryDataResultFields
  }
  biQueryDataV2(input: $inputV2) @include(if: $queryV2) {
    ...BiQueryDataResultFields
  }
}
    ${BiQueryDataResultFieldsFragmentDoc}`;

export function useBiDataCombinedQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBiDataCombinedQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBiDataCombinedQuery,
    GQTypes.GQBiDataCombinedQueryVariables
  >({ query: BiDataCombinedDocument, ...options });
}
export const BiSchemasDocument = gql`
    query BiSchemas($input: BiSchemasInput!) @withOwner(owner: DatasetsAndObjects) {
  biSchemas(input: $input) {
    schemas {
      slug
      schema
    }
    tables {
      tableRef {
        __typename
        ... on BaseTableReference {
          ref
          alias
          tableType
        }
        ... on FootprintSnapshotTableReference {
          footprintSnapshotIds
          includeDedupedRows
          footprintKind
        }
        ... on FootprintBartTableReference {
          footprintSnapshotIds
          businessActivityType
        }
        ... on FootprintSidecarTableReference {
          footprintSnapshotIds
          tableName
        }
        ... on BartTableReference {
          datasourceIds
          measurementProjectIds
        }
      }
      slug
    }
  }
}
    `;

export function useBiSchemasQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQBiSchemasQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQBiSchemasQuery,
    GQTypes.GQBiSchemasQueryVariables
  >({ query: BiSchemasDocument, ...options });
}
export const BiDataMultipleDocument = gql`
    query BiDataMultiple($input: BiQueryDataMultipleInput!) @withOwner(owner: DatasetsAndObjects) {
  biQueryDataMultiple(input: $input) {
    id
    data
    displayValueMappings {
      dimension
      listOptionItems {
        value
        label
      }
    }
  }
}
    `;

export function useBiDataMultipleQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBiDataMultipleQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBiDataMultipleQuery,
    GQTypes.GQBiDataMultipleQueryVariables
  >({ query: BiDataMultipleDocument, ...options });
}
export const BiDimensionListOptionsDocument = gql`
    query BiDimensionListOptions($input: BiDimensionListOptionsInput!, $queryV1: Boolean!, $queryV2: Boolean!) @withOwner(owner: DatasetsAndObjects) {
  biDimensionListOptions(input: $input) @include(if: $queryV1) {
    id
    listOptionItems {
      value
      label
    }
  }
  biDimensionListOptionsV2Parity(input: $input) @include(if: $queryV2) {
    id
    listOptionItems {
      value
      label
    }
  }
}
    `;

export function useBiDimensionListOptionsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBiDimensionListOptionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBiDimensionListOptionsQuery,
    GQTypes.GQBiDimensionListOptionsQueryVariables
  >({ query: BiDimensionListOptionsDocument, ...options });
}
export const BiFilterDisplayValueMappingsDocument = gql`
    query BiFilterDisplayValueMappings($input: BiFilterDisplayValueMappingsInput!) @withOwner(owner: DatasetsAndObjects) {
  biFilterDisplayValueMappings(input: $input) {
    dimension
    listOptionItems {
      value
      label
    }
  }
}
    `;

export function useBiFilterDisplayValueMappingsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBiFilterDisplayValueMappingsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBiFilterDisplayValueMappingsQuery,
    GQTypes.GQBiFilterDisplayValueMappingsQueryVariables
  >({ query: BiFilterDisplayValueMappingsDocument, ...options });
}
export const BiDisplayValueMappingsDocument = gql`
    query BiDisplayValueMappings($input: [BiDisplayValueMappingRequestInput!]!) @withOwner(owner: DatasetsAndObjects) {
  biDisplayValueMappings(input: $input) {
    dimension
    listOptionItems {
      label
      value
    }
  }
}
    `;

export function useBiDisplayValueMappingsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBiDisplayValueMappingsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBiDisplayValueMappingsQuery,
    GQTypes.GQBiDisplayValueMappingsQueryVariables
  >({ query: BiDisplayValueMappingsDocument, ...options });
}
export const GetBiSavedViewsDocument = gql`
    query GetBiSavedViews($input: BiSavedViewsInput!) @withOwner(owner: DatasetsAndObjects) {
  biSavedViews(input: $input) {
    ...BiSavedView
  }
}
    ${BiSavedViewFragmentDoc}`;

export function useGetBiSavedViewsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetBiSavedViewsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetBiSavedViewsQuery,
    GQTypes.GQGetBiSavedViewsQueryVariables
  >({ query: GetBiSavedViewsDocument, ...options });
}
export const CustomReportsWithSavedViewDocument = gql`
    query CustomReportsWithSavedView($input: CustomReportsWithBiSavedViewInput!) @withOwner(owner: DatasetsAndObjects) {
  customReportsWithBiSavedView(input: $input) {
    id
    name
    config {
      id
      updatedAt
    }
  }
}
    `;

export function useCustomReportsWithSavedViewQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCustomReportsWithSavedViewQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCustomReportsWithSavedViewQuery,
    GQTypes.GQCustomReportsWithSavedViewQueryVariables
  >({ query: CustomReportsWithSavedViewDocument, ...options });
}
export const BiCustomMetricsDocument = gql`
    query BiCustomMetrics @withOwner(owner: DatasetsAndObjects) {
  biCustomMetrics {
    id
    stableId
    creator {
      id
      displayName
    }
    name
    expression
  }
}
    `;

export function useBiCustomMetricsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQBiCustomMetricsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBiCustomMetricsQuery,
    GQTypes.GQBiCustomMetricsQueryVariables
  >({ query: BiCustomMetricsDocument, ...options });
}
export const CreateBiCustomMetricDocument = gql`
    mutation CreateBiCustomMetric($input: CreateBiCustomMetricInput!) @withOwner(owner: DatasetsAndObjects) {
  createBiCustomMetric(input: $input) {
    id
    stableId
    name
    expression
  }
}
    `;

export function useCreateBiCustomMetricMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateBiCustomMetricMutation,
    GQTypes.GQCreateBiCustomMetricMutationVariables
  >(CreateBiCustomMetricDocument);
}
export const UpdateBiCustomMetricDocument = gql`
    mutation UpdateBiCustomMetric($input: UpdateBiCustomMetricInput!) @withOwner(owner: DatasetsAndObjects) {
  updateBiCustomMetric(input: $input) {
    id
    stableId
    name
    expression
  }
}
    `;

export function useUpdateBiCustomMetricMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateBiCustomMetricMutation,
    GQTypes.GQUpdateBiCustomMetricMutationVariables
  >(UpdateBiCustomMetricDocument);
}
export const DeleteBiCustomMetricDocument = gql`
    mutation DeleteBiCustomMetric($input: DeleteBiCustomMetricInput!) @withOwner(owner: DatasetsAndObjects) {
  deleteBiCustomMetric(input: $input) {
    id
    stableId
    name
    expression
  }
}
    `;

export function useDeleteBiCustomMetricMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteBiCustomMetricMutation,
    GQTypes.GQDeleteBiCustomMetricMutationVariables
  >(DeleteBiCustomMetricDocument);
}
export const GetFootprintKindsForSnapshotDocument = gql`
    query GetFootprintKindsForSnapshot($footprintSnapshotId: String!) @withOwner(owner: DatasetsAndObjects) {
  footprintAnalysis(footprintSnapshotId: $footprintSnapshotId) {
    id
    snapshotFootprintKindsMeta {
      ...FootprintKindMetaForDrilldown
    }
  }
}
    ${FootprintKindMetaForDrilldownFragmentDoc}`;

export function useGetFootprintKindsForSnapshotQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintKindsForSnapshotQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintKindsForSnapshotQuery,
    GQTypes.GQGetFootprintKindsForSnapshotQueryVariables
  >({ query: GetFootprintKindsForSnapshotDocument, ...options });
}
export const EmissionsModelsOverviewDocument = gql`
    query EmissionsModelsOverview($footprintSnapshotId: String!) @withOwner(owner: Calcprint) {
  emissionsModelVersionsForFootprint(footprintSnapshotId: $footprintSnapshotId) {
    id
    emv {
      id
      title
      description
      createdAt
    }
    releaseIds
  }
}
    `;

export function useEmissionsModelsOverviewQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEmissionsModelsOverviewQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEmissionsModelsOverviewQuery,
    GQTypes.GQEmissionsModelsOverviewQueryVariables
  >({ query: EmissionsModelsOverviewDocument, ...options });
}
export const MethodologiesOverviewDocument = gql`
    query MethodologiesOverview($footprintSnapshotId: String!) @withOwner(owner: Calcprint) {
  calculationMethodVersionsForFootprint(footprintSnapshotId: $footprintSnapshotId) {
    id
    businessActivityTypeName
    displayName
    externalNotes
    updatedAt
  }
}
    `;

export function useMethodologiesOverviewQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMethodologiesOverviewQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMethodologiesOverviewQuery,
    GQTypes.GQMethodologiesOverviewQueryVariables
  >({ query: MethodologiesOverviewDocument, ...options });
}
export const CreateClimateProgramProjectDocument = gql`
    mutation CreateClimateProgramProject($input: CreateClimateProgramProjectInput!) @withOwner(owner: EnterpriseFoundations) {
  createClimateProgramProject(input: $input) {
    climateProgramProject {
      ...ClimateProgramProjectFields
    }
  }
}
    ${ClimateProgramProjectFieldsFragmentDoc}`;

export function useCreateClimateProgramProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateClimateProgramProjectMutation,
    GQTypes.GQCreateClimateProgramProjectMutationVariables
  >(CreateClimateProgramProjectDocument);
}
export const CreateClimateProgramCalendarEventDocument = gql`
    mutation CreateClimateProgramCalendarEvent($input: CreateClimateProgramCalendarEventInput!) @withOwner(owner: EnterpriseFoundations) {
  createClimateProgramCalendarEvent(input: $input) {
    climateProgramCalendarEvent {
      ...ClimateProgramCalendarEventFields
    }
  }
}
    ${ClimateProgramCalendarEventFieldsFragmentDoc}`;

export function useCreateClimateProgramCalendarEventMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateClimateProgramCalendarEventMutation,
    GQTypes.GQCreateClimateProgramCalendarEventMutationVariables
  >(CreateClimateProgramCalendarEventDocument);
}
export const UpdateClimateProgramCalendarEventDocument = gql`
    mutation UpdateClimateProgramCalendarEvent($input: UpdateClimateProgramCalendarEventInput!) @withOwner(owner: EnterpriseFoundations) {
  updateClimateProgramCalendarEvent(input: $input) {
    climateProgramCalendarEvent {
      ...ClimateProgramCalendarEventFields
    }
  }
}
    ${ClimateProgramCalendarEventFieldsFragmentDoc}`;

export function useUpdateClimateProgramCalendarEventMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateClimateProgramCalendarEventMutation,
    GQTypes.GQUpdateClimateProgramCalendarEventMutationVariables
  >(UpdateClimateProgramCalendarEventDocument);
}
export const DeleteClimateProgramCalendarEventDocument = gql`
    mutation DeleteClimateProgramCalendarEvent($input: DeleteClimateProgramCalendarEventInput!) @withOwner(owner: EnterpriseFoundations) {
  deleteClimateProgramCalendarEvent(input: $input) {
    id
  }
}
    `;

export function useDeleteClimateProgramCalendarEventMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteClimateProgramCalendarEventMutation,
    GQTypes.GQDeleteClimateProgramCalendarEventMutationVariables
  >(DeleteClimateProgramCalendarEventDocument);
}
export const UpdateClimateProgramProjectDocument = gql`
    mutation UpdateClimateProgramProject($input: UpdateClimateProgramProjectInput!) @withOwner(owner: EnterpriseFoundations) {
  updateClimateProgramProject(input: $input) {
    climateProgramProject {
      ...ClimateProgramProjectFields
    }
  }
}
    ${ClimateProgramProjectFieldsFragmentDoc}`;

export function useUpdateClimateProgramProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateClimateProgramProjectMutation,
    GQTypes.GQUpdateClimateProgramProjectMutationVariables
  >(UpdateClimateProgramProjectDocument);
}
export const DeleteClimateProgramProjectDocument = gql`
    mutation DeleteClimateProgramProject($input: DeleteClimateProgramProjectInput!) @withOwner(owner: EnterpriseFoundations) {
  deleteClimateProgramProject(input: $input) {
    id
  }
}
    `;

export function useDeleteClimateProgramProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteClimateProgramProjectMutation,
    GQTypes.GQDeleteClimateProgramProjectMutationVariables
  >(DeleteClimateProgramProjectDocument);
}
export const UpdateClimateProgramTimelineProjectDocument = gql`
    mutation UpdateClimateProgramTimelineProject($input: UpdateClimateProgramTimelineProjectInput!) @withOwner(owner: EnterpriseFoundations) {
  updateClimateProgramTimelineProject(input: $input) {
    climateProgramTimelineProject {
      ...ClimateProgramTimelineProjectFields
    }
  }
}
    ${ClimateProgramTimelineProjectFieldsFragmentDoc}`;

export function useUpdateClimateProgramTimelineProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateClimateProgramTimelineProjectMutation,
    GQTypes.GQUpdateClimateProgramTimelineProjectMutationVariables
  >(UpdateClimateProgramTimelineProjectDocument);
}
export const DeleteClimateProgramTimelineProjectDocument = gql`
    mutation DeleteClimateProgramTimelineProject($input: DeleteClimateProgramTimelineProjectInput!) @withOwner(owner: EnterpriseFoundations) {
  deleteClimateProgramTimelineProject(input: $input) {
    id
    objectType
  }
}
    `;

export function useDeleteClimateProgramTimelineProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteClimateProgramTimelineProjectMutation,
    GQTypes.GQDeleteClimateProgramTimelineProjectMutationVariables
  >(DeleteClimateProgramTimelineProjectDocument);
}
export const GetClimateProgramProjectDocument = gql`
    query getClimateProgramProject($cppId: ID!) @withOwner(owner: EnterpriseFoundations) {
  climateProgramProject(id: $cppId) {
    ...ClimateProgramProjectFields
  }
}
    ${ClimateProgramProjectFieldsFragmentDoc}`;

export function useGetClimateProgramProjectQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetClimateProgramProjectQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetClimateProgramProjectQuery,
    GQTypes.GQGetClimateProgramProjectQueryVariables
  >({ query: GetClimateProgramProjectDocument, ...options });
}
export const DatasetIndexPageDocument = gql`
    query DatasetIndexPage($id: ID!, $measurementProjectId: ID) @withOwner(owner: DataIngestion) {
  organization {
    id
    measurementProjects {
      ...MeasurementProjectFields
    }
  }
  dataset(id: $id) {
    ...DatasetForSpecificDatasetPage
  }
}
    ${MeasurementProjectFieldsFragmentDoc}
${DatasetForSpecificDatasetPageFragmentDoc}`;

export function useDatasetIndexPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDatasetIndexPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDatasetIndexPageQuery,
    GQTypes.GQDatasetIndexPageQueryVariables
  >({ query: DatasetIndexPageDocument, ...options });
}
export const InputDataByExtBartIdsDocument = gql`
    query InputDataByExtBartIds($extBartIds: [ID!]!) @withOwner(owner: DataIngestion) {
  inputDataByExtBartIds(extBartIds: $extBartIds) {
    totalRows
    rows
  }
}
    `;

export function useInputDataByExtBartIdsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQInputDataByExtBartIdsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQInputDataByExtBartIdsQuery,
    GQTypes.GQInputDataByExtBartIdsQueryVariables
  >({ query: InputDataByExtBartIdsDocument, ...options });
}
export const ProductWaitlistTypesDocument = gql`
    query ProductWaitlistTypes @withOwner(owner: SupplyChain) {
  productWaitlistTypes
}
    `;

export function useProductWaitlistTypesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQProductWaitlistTypesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQProductWaitlistTypesQuery,
    GQTypes.GQProductWaitlistTypesQueryVariables
  >({ query: ProductWaitlistTypesDocument, ...options });
}
export const JoinProductWaitlistDocument = gql`
    mutation JoinProductWaitlist($type: ProductWaitlistType!) @withOwner(owner: SupplyChain) {
  joinWaitlist(type: $type) {
    id
    type
  }
}
    `;

export function useJoinProductWaitlistMutation() {
  return Urql.useMutation<
    GQTypes.GQJoinProductWaitlistMutation,
    GQTypes.GQJoinProductWaitlistMutationVariables
  >(JoinProductWaitlistDocument);
}
export const CreateMarketplaceEacPurchaseDocument = gql`
    mutation CreateMarketplaceEacPurchase($input: CreateMarketplaceEacPurchaseInput!) @withOwner(owner: SupplyChain) {
  createMarketplaceEacPurchase(input: $input) {
    marketplacePurchaseId
  }
}
    `;

export function useCreateMarketplaceEacPurchaseMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMarketplaceEacPurchaseMutation,
    GQTypes.GQCreateMarketplaceEacPurchaseMutationVariables
  >(CreateMarketplaceEacPurchaseDocument);
}
export const EacPurchaseJoinProductWaitlistDocument = gql`
    mutation EacPurchaseJoinProductWaitlist($type: ProductWaitlistType!) @withOwner(owner: SupplyChain) {
  joinWaitlist(type: $type) {
    id
    type
  }
}
    `;

export function useEacPurchaseJoinProductWaitlistMutation() {
  return Urql.useMutation<
    GQTypes.GQEacPurchaseJoinProductWaitlistMutation,
    GQTypes.GQEacPurchaseJoinProductWaitlistMutationVariables
  >(EacPurchaseJoinProductWaitlistDocument);
}
export const GetCleanPowerLandingPageDocument = gql`
    query GetCleanPowerLandingPage($footprintSnapshotId: String) @withOwner(owner: SupplyChain) {
  footprintAnalysis(footprintSnapshotId: $footprintSnapshotId) {
    id
    customFields
  }
  marketplacePurchases {
    edges {
      node {
        id
        customerStatus
        eacMetadata {
          id
        }
      }
    }
  }
}
    `;

export function useGetCleanPowerLandingPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCleanPowerLandingPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCleanPowerLandingPageQuery,
    GQTypes.GQGetCleanPowerLandingPageQueryVariables
  >({ query: GetCleanPowerLandingPageDocument, ...options });
}
export const GetFootprintEnergyConsumptionBreakdownDocument = gql`
    query GetFootprintEnergyConsumptionBreakdown($footprintSnapshotId: String, $interval: YMInterval!, $groupBy: [String!]!, $isGridOnly: Boolean) @withOwner(owner: SupplyChain) {
  footprintAnalysis(footprintSnapshotId: $footprintSnapshotId) {
    id
    energyConsumptionBreakdown(
      interval: $interval
      groupBy: $groupBy
      isGridOnly: $isGridOnly
    ) {
      ...EnergyBreakdownFields
    }
  }
}
    ${EnergyBreakdownFieldsFragmentDoc}`;

export function useGetFootprintEnergyConsumptionBreakdownQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintEnergyConsumptionBreakdownQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintEnergyConsumptionBreakdownQuery,
    GQTypes.GQGetFootprintEnergyConsumptionBreakdownQueryVariables
  >({ query: GetFootprintEnergyConsumptionBreakdownDocument, ...options });
}
export const GetEacPriceEstimatesForDashboardDocument = gql`
    query GetEacPriceEstimatesForDashboard($targetCurrency: String!) @withOwner(owner: SupplyChain) {
  eacPriceEstimates {
    ...EacPriceEstimateFieldsForDashboard
  }
  latestCurrencyExchangeRate(targetCurrency: $targetCurrency) {
    id
    updatedAt
    currency
    value
  }
}
    ${EacPriceEstimateFieldsForDashboardFragmentDoc}`;

export function useGetEacPriceEstimatesForDashboardQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEacPriceEstimatesForDashboardQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEacPriceEstimatesForDashboardQuery,
    GQTypes.GQGetEacPriceEstimatesForDashboardQueryVariables
  >({ query: GetEacPriceEstimatesForDashboardDocument, ...options });
}
export const OrgPeerClimateProgramsDocument = gql`
    query OrgPeerClimatePrograms @withOwner(owner: SupplyChain) {
  peerCompanyGroups {
    id
    members {
      id
      company {
        ...CompanyWithClimateCommitments
      }
    }
  }
}
    ${CompanyWithClimateCommitmentsFragmentDoc}`;

export function useOrgPeerClimateProgramsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgPeerClimateProgramsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgPeerClimateProgramsQuery,
    GQTypes.GQOrgPeerClimateProgramsQueryVariables
  >({ query: OrgPeerClimateProgramsDocument, ...options });
}
export const GetYearMeasurementProjectsDocument = gql`
    query GetYearMeasurementProjects($reportingYear: Int!) @withOwner(owner: SupplyChain) {
  yearMeasurementProjects(reportingYear: $reportingYear) {
    ...MeasurementProjectFieldsForShareStatusEngagementTask
  }
}
    ${MeasurementProjectFieldsForShareStatusEngagementTaskFragmentDoc}`;

export function useGetYearMeasurementProjectsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetYearMeasurementProjectsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetYearMeasurementProjectsQuery,
    GQTypes.GQGetYearMeasurementProjectsQueryVariables
  >({ query: GetYearMeasurementProjectsDocument, ...options });
}
export const GlobalFacilitiesPageDocument = gql`
    query GlobalFacilitiesPage @withOwner(owner: DataIngestion) {
  organization {
    id
    facilitiesMetadata {
      buildingsDataset {
        id
      }
    }
  }
}
    `;

export function useGlobalFacilitiesPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGlobalFacilitiesPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGlobalFacilitiesPageQuery,
    GQTypes.GQGlobalFacilitiesPageQueryVariables
  >({ query: GlobalFacilitiesPageDocument, ...options });
}
export const FacilitiesPageDocument = gql`
    query FacilitiesPage($after: String, $before: String, $first: Int, $last: Int, $filterWithOperator: FacilitiesFilter, $sort: [FacilitiesSortInput!], $search: String) @withOwner(owner: DataIngestion) {
  organization {
    ...OrganizationForCommonFacilitiesColumns
  }
  latestOrgStructureVersion {
    id
    orgUnitTypes {
      ...OrgUnitTypesForCommonFacilitiesColumns
    }
  }
  facilities(
    after: $after
    before: $before
    first: $first
    last: $last
    filterWithOperator: $filterWithOperator
    sort: $sort
    search: $search
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
    edges {
      node {
        id
        ...CommonFacilitiesColumns
      }
    }
  }
}
    ${OrganizationForCommonFacilitiesColumnsFragmentDoc}
${OrgUnitTypesForCommonFacilitiesColumnsFragmentDoc}
${CommonFacilitiesColumnsFragmentDoc}`;

export function useFacilitiesPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFacilitiesPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFacilitiesPageQuery,
    GQTypes.GQFacilitiesPageQueryVariables
  >({ query: FacilitiesPageDocument, ...options });
}
export const FinanceAssetDocument = gql`
    query FinanceAsset($assetId: ID!, $year: Int!) @withOwner(owner: WinFinance) {
  asset(id: $assetId) {
    ...AssetForAssetPage
  }
}
    ${AssetForAssetPageFragmentDoc}`;

export function useFinanceAssetQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceAssetQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceAssetQuery,
    GQTypes.GQFinanceAssetQueryVariables
  >({ query: FinanceAssetDocument, ...options });
}
export const FinanceSettingsDocument = gql`
    query FinanceSettings @withOwner(owner: WinFinance) {
  funds {
    id
    ...FundAllFields
  }
  financeTags(tagType: FundTag) {
    id
    ...FinanceTagForGrid
  }
}
    ${FundAllFieldsFragmentDoc}
${FinanceTagForGridFragmentDoc}`;

export function useFinanceSettingsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceSettingsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceSettingsQuery,
    GQTypes.GQFinanceSettingsQueryVariables
  >({ query: FinanceSettingsDocument, ...options });
}
export const DashboardUpdateFundDocument = gql`
    mutation DashboardUpdateFund($input: UpdateFundInput!) @withOwner(owner: WinFinance) {
  updateFund(input: $input) {
    fund {
      id
      ...FundAllFields
    }
  }
}
    ${FundAllFieldsFragmentDoc}`;

export function useDashboardUpdateFundMutation() {
  return Urql.useMutation<
    GQTypes.GQDashboardUpdateFundMutation,
    GQTypes.GQDashboardUpdateFundMutationVariables
  >(DashboardUpdateFundDocument);
}
export const DashboardDeleteFundsDocument = gql`
    mutation DashboardDeleteFunds($input: DeleteFundsInput!) @withOwner(owner: WinFinance) {
  deleteFunds(input: $input) {
    ids
    invalidatedSnapshots {
      id
      isStale
    }
  }
}
    `;

export function useDashboardDeleteFundsMutation() {
  return Urql.useMutation<
    GQTypes.GQDashboardDeleteFundsMutation,
    GQTypes.GQDashboardDeleteFundsMutationVariables
  >(DashboardDeleteFundsDocument);
}
export const SnapshotAssemblyCreateSnapshotDocument = gql`
    mutation SnapshotAssemblyCreateSnapshot($input: CreateSnapshotInput!) @withOwner(owner: WinFinance) {
  createSnapshot(input: $input) {
    snapshot {
      id
    }
  }
}
    `;

export function useSnapshotAssemblyCreateSnapshotMutation() {
  return Urql.useMutation<
    GQTypes.GQSnapshotAssemblyCreateSnapshotMutation,
    GQTypes.GQSnapshotAssemblyCreateSnapshotMutationVariables
  >(SnapshotAssemblyCreateSnapshotDocument);
}
export const SnapshotAssemblyFlowDocument = gql`
    query SnapshotAssemblyFlow @withOwner(owner: WinFinance) {
  financeSettings {
    id
    estimateDownstreamScope3
  }
  financeMetadata {
    anyAssetYearHasEstimationMethodology
    hasSomeAssetCorporate
  }
}
    `;

export function useSnapshotAssemblyFlowQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQSnapshotAssemblyFlowQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSnapshotAssemblyFlowQuery,
    GQTypes.GQSnapshotAssemblyFlowQueryVariables
  >({ query: SnapshotAssemblyFlowDocument, ...options });
}
export const GenerateFinanceAuditReportsForAssetDocument = gql`
    mutation GenerateFinanceAuditReportsForAsset($input: GenerateFinanceAuditReportsForAssetInput!) @withOwner(owner: WinFinance) {
  generateFinanceAuditReportsForAsset(input: $input) {
    jobId
  }
}
    `;

export function useGenerateFinanceAuditReportsForAssetMutation() {
  return Urql.useMutation<
    GQTypes.GQGenerateFinanceAuditReportsForAssetMutation,
    GQTypes.GQGenerateFinanceAuditReportsForAssetMutationVariables
  >(GenerateFinanceAuditReportsForAssetDocument);
}
export const FinanceLogEventsDocument = gql`
    query FinanceLogEvents($filters: [BiQueryFilterInput!], $after: String, $before: String, $first: Int, $last: Int) @withOwner(owner: WinFinance) {
  financeChangelog(
    filters: $filters
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    edges {
      node {
        id
        ...FinanceChangelogEntry
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
  }
}
    ${FinanceChangelogEntryFragmentDoc}`;

export function useFinanceLogEventsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceLogEventsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceLogEventsQuery,
    GQTypes.GQFinanceLogEventsQueryVariables
  >({ query: FinanceLogEventsDocument, ...options });
}
export const UpdateAssetCorporateDocument = gql`
    mutation UpdateAssetCorporate($input: UpdateAssetCorporateInput!) @withOwner(owner: WinFinance) {
  updateAssetCorporate(input: $input) {
    asset {
      id
      ...AssetCorporateAllFields
      company {
        id
        alternateNames
        betterCompanyId
        country
        createdAt
        deletedAt
        description
        isDemo
        logoUrl
        naicsCode
        naicsCodeSource
        name
        sfdcOpportunityId
        sfdcStage
        source
        sustainabilityWebsiteUrl
        updatedAt
        url
        userEditorId
        logoUrlId
        sustainabilityWebsiteUrlId
        companyWebsiteUrlId
        companyIngestionRecordUpdateId
        sourceId
        isHidden
      }
    }
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${AssetCorporateAllFieldsFragmentDoc}
${FinanceSnapshotIsStaleFragmentDoc}`;

export function useUpdateAssetCorporateMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateAssetCorporateMutation,
    GQTypes.GQUpdateAssetCorporateMutationVariables
  >(UpdateAssetCorporateDocument);
}
export const UpdateAssetGroupDocument = gql`
    mutation UpdateAssetGroup($input: UpdateAssetGroupInput!) @withOwner(owner: WinFinance) {
  updateAssetGroup(input: $input) {
    asset {
      id
      ...AssetGroupAllFields
    }
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${AssetGroupAllFieldsFragmentDoc}
${FinanceSnapshotIsStaleFragmentDoc}`;

export function useUpdateAssetGroupMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateAssetGroupMutation,
    GQTypes.GQUpdateAssetGroupMutationVariables
  >(UpdateAssetGroupDocument);
}
export const CreateAssetHoldingDocument = gql`
    mutation CreateAssetHolding($input: CreateAssetHoldingNewInput!, $year: Int) @withOwner(owner: WinFinance) {
  createAssetHolding(input: $input) {
    assetHolding {
      id
      ...AssetHoldingAllFields
      asset {
        id
        assetHoldings(year: $year) {
          id
        }
      }
    }
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${AssetHoldingAllFieldsFragmentDoc}
${FinanceSnapshotIsStaleFragmentDoc}`;

export function useCreateAssetHoldingMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateAssetHoldingMutation,
    GQTypes.GQCreateAssetHoldingMutationVariables
  >(CreateAssetHoldingDocument);
}
export const DeleteAssetHoldingDocument = gql`
    mutation DeleteAssetHolding($input: DeleteAssetHoldingInput!, $year: Int) @withOwner(owner: WinFinance) {
  deleteAssetHolding(input: $input) {
    id
    asset {
      id
      assetHoldings(year: $year) {
        id
      }
    }
    deletedFinanceDataFlagIds
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${FinanceSnapshotIsStaleFragmentDoc}`;

export function useDeleteAssetHoldingMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteAssetHoldingMutation,
    GQTypes.GQDeleteAssetHoldingMutationVariables
  >(DeleteAssetHoldingDocument);
}
export const UpdateAssetHoldingDocument = gql`
    mutation UpdateAssetHolding($input: UpdateAssetHoldingNewInput!) @withOwner(owner: WinFinance) {
  updateAssetHolding(input: $input) {
    assetHolding {
      id
      ...AssetHoldingAllFields
    }
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${AssetHoldingAllFieldsFragmentDoc}
${FinanceSnapshotIsStaleFragmentDoc}`;

export function useUpdateAssetHoldingMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateAssetHoldingMutation,
    GQTypes.GQUpdateAssetHoldingMutationVariables
  >(UpdateAssetHoldingDocument);
}
export const AssetInfoPageDocument = gql`
    query AssetInfoPage($assetId: ID!) @withOwner(owner: WinFinance) {
  asset(id: $assetId) {
    ...AssetAllFields
    assetGroupAssets {
      ...AssetGroupAssetAllFields
    }
    ... on AssetCorporate {
      company {
        ...CompanyFieldsForCCIS
        parentRelationships(onlyGetActiveRelationships: true) {
          ...ParentRelationshipFields
        }
        parentRelationshipsForSidebar: parentRelationships {
          ...ParentRelationshipForSidebarFields
        }
        contacts {
          ...SupplierContactFields
        }
      }
      engagementTasks {
        ...EngagementTaskFields
      }
    }
  }
  funds {
    id
    ...FundAllFields
  }
}
    ${AssetAllFieldsFragmentDoc}
${AssetGroupAssetAllFieldsFragmentDoc}
${CompanyFieldsForCcisFragmentDoc}
${ParentRelationshipFieldsFragmentDoc}
${ParentRelationshipForSidebarFieldsFragmentDoc}
${SupplierContactFieldsFragmentDoc}
${EngagementTaskFieldsFragmentDoc}
${FundAllFieldsFragmentDoc}`;

export function useAssetInfoPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAssetInfoPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAssetInfoPageQuery,
    GQTypes.GQAssetInfoPageQueryVariables
  >({ query: AssetInfoPageDocument, ...options });
}
export const AssetInfoPageEditableDocument = gql`
    query AssetInfoPageEditable($assetId: ID!, $year: Int) @withOwner(owner: WinFinance) {
  asset(id: $assetId) {
    ...AssetAllFields
    footprintSourceOptions(year: $year) {
      id
      label
      year
      source
      externalScore
      pcafScore
    }
    revenueSourceOptions(year: $year) {
      id
      label
      year
      revenue
      currencyCode
    }
    assetYears(year: $year) {
      ...AssetYearAllFields
    }
    assetHoldings(year: $year) {
      ...AssetHoldingAllFields
    }
    assetGroupAssets {
      ...AssetGroupAssetAllFields
    }
    ... on AssetCorporate {
      company {
        ...CompanyFieldsForCCIS
        parentRelationships(onlyGetActiveRelationships: true) {
          ...ParentRelationshipFields
        }
        parentRelationshipsForSidebar: parentRelationships {
          ...ParentRelationshipForSidebarFields
        }
        contacts {
          ...SupplierContactFields
        }
      }
      engagementTasks {
        ...EngagementTaskFields
      }
    }
  }
  funds {
    id
    ...FundAllFields
  }
}
    ${AssetAllFieldsFragmentDoc}
${AssetYearAllFieldsFragmentDoc}
${AssetHoldingAllFieldsFragmentDoc}
${AssetGroupAssetAllFieldsFragmentDoc}
${CompanyFieldsForCcisFragmentDoc}
${ParentRelationshipFieldsFragmentDoc}
${ParentRelationshipForSidebarFieldsFragmentDoc}
${SupplierContactFieldsFragmentDoc}
${EngagementTaskFieldsFragmentDoc}
${FundAllFieldsFragmentDoc}`;

export function useAssetInfoPageEditableQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAssetInfoPageEditableQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAssetInfoPageEditableQuery,
    GQTypes.GQAssetInfoPageEditableQueryVariables
  >({ query: AssetInfoPageEditableDocument, ...options });
}
export const UpdateAssetPersonalMotorVehicleInsuranceDocument = gql`
    mutation UpdateAssetPersonalMotorVehicleInsurance($input: UpdateAssetPersonalMotorVehicleInsuranceInput!) @withOwner(owner: WinFinance) {
  updateAssetPersonalMotorVehicleInsurance(input: $input) {
    asset {
      id
      ...AssetPersonalMotorVehicleInsuranceAllFields
      assetYears {
        id
        currencyCode
        valueNative
        emissionsYearId
      }
      footprintSourceOptions {
        id
      }
    }
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${AssetPersonalMotorVehicleInsuranceAllFieldsFragmentDoc}
${FinanceSnapshotIsStaleFragmentDoc}`;

export function useUpdateAssetPersonalMotorVehicleInsuranceMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateAssetPersonalMotorVehicleInsuranceMutation,
    GQTypes.GQUpdateAssetPersonalMotorVehicleInsuranceMutationVariables
  >(UpdateAssetPersonalMotorVehicleInsuranceDocument);
}
export const UpdateAssetRealEstateDocument = gql`
    mutation UpdateAssetRealEstate($input: UpdateAssetRealEstateInput!) @withOwner(owner: WinFinance) {
  updateAssetRealEstate(input: $input) {
    asset {
      id
      ...AssetRealEstateAllFields
    }
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${AssetRealEstateAllFieldsFragmentDoc}
${FinanceSnapshotIsStaleFragmentDoc}`;

export function useUpdateAssetRealEstateMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateAssetRealEstateMutation,
    GQTypes.GQUpdateAssetRealEstateMutationVariables
  >(UpdateAssetRealEstateDocument);
}
export const UpdateAssetSovereignBondDocument = gql`
    mutation UpdateAssetSovereignBond($input: UpdateAssetSovereignBondInput!) @withOwner(owner: WinFinance) {
  updateAssetSovereignBond(input: $input) {
    asset {
      id
      ...AssetSovereignBondAllFields
      assetYears {
        id
        currencyCode
        valueNative
        emissionsYearId
      }
      footprintSourceOptions {
        id
      }
    }
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${AssetSovereignBondAllFieldsFragmentDoc}
${FinanceSnapshotIsStaleFragmentDoc}`;

export function useUpdateAssetSovereignBondMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateAssetSovereignBondMutation,
    GQTypes.GQUpdateAssetSovereignBondMutationVariables
  >(UpdateAssetSovereignBondDocument);
}
export const CreateAssetYearDocument = gql`
    mutation CreateAssetYear($input: CreateAssetYearNewInput!, $year: Int) @withOwner(owner: WinFinance) {
  createAssetYear(input: $input) {
    assetYear {
      id
      ...AssetYearAllFields
      asset {
        id
        assetYears(year: $year) {
          ...AssetYearAllFields
        }
      }
    }
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${AssetYearAllFieldsFragmentDoc}
${FinanceSnapshotIsStaleFragmentDoc}`;

export function useCreateAssetYearMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateAssetYearMutation,
    GQTypes.GQCreateAssetYearMutationVariables
  >(CreateAssetYearDocument);
}
export const UpdateAssetYearDocument = gql`
    mutation UpdateAssetYear($input: UpdateAssetYearNewInput!) @withOwner(owner: WinFinance) {
  updateAssetYear(input: $input) {
    assetYear {
      id
      ...AssetYearAllFields
    }
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${AssetYearAllFieldsFragmentDoc}
${FinanceSnapshotIsStaleFragmentDoc}`;

export function useUpdateAssetYearMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateAssetYearMutation,
    GQTypes.GQUpdateAssetYearMutationVariables
  >(UpdateAssetYearDocument);
}
export const DeleteFinanceSavedViewDocument = gql`
    mutation DeleteFinanceSavedView($input: DeleteFinanceSavedViewInput!) @withOwner(owner: WinFinance) {
  deleteFinanceSavedView(input: $input) {
    id
  }
}
    `;

export function useDeleteFinanceSavedViewMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteFinanceSavedViewMutation,
    GQTypes.GQDeleteFinanceSavedViewMutationVariables
  >(DeleteFinanceSavedViewDocument);
}
export const FinanceSavedViewFormDocument = gql`
    query FinanceSavedViewForm @withOwner(owner: WinFinance) {
  financeTags {
    id
    ...FinanceTagForChartSelector
  }
}
    ${FinanceTagForChartSelectorFragmentDoc}`;

export function useFinanceSavedViewFormQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceSavedViewFormQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceSavedViewFormQuery,
    GQTypes.GQFinanceSavedViewFormQueryVariables
  >({ query: FinanceSavedViewFormDocument, ...options });
}
export const AssetFinancialDataDocument = gql`
    query AssetFinancialData($assetId: ID!, $year: Int) @withOwner(owner: WinFinance) {
  assetCorporate(assetCorporateId: $assetId) {
    id
    assetFinancialData(year: $year) {
      id
      usedSAndPRevenue
      isPrivate
    }
  }
}
    `;

export function useAssetFinancialDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAssetFinancialDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAssetFinancialDataQuery,
    GQTypes.GQAssetFinancialDataQueryVariables
  >({ query: AssetFinancialDataDocument, ...options });
}
export const GetFinanceAuditReportOptionsForAssetDocument = gql`
    query GetFinanceAuditReportOptionsForAsset($assetCorporateId: ID!, $year: Int!) @withOwner(owner: WinFinance) {
  financeAssetAuditReportOptions(assetCorporateId: $assetCorporateId, year: $year) {
    orgId
    emissionsYearId
    label
    timestamp
  }
}
    `;

export function useGetFinanceAuditReportOptionsForAssetQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFinanceAuditReportOptionsForAssetQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFinanceAuditReportOptionsForAssetQuery,
    GQTypes.GQGetFinanceAuditReportOptionsForAssetQueryVariables
  >({ query: GetFinanceAuditReportOptionsForAssetDocument, ...options });
}
export const FinanceMetricsAndChartsDocument = gql`
    query FinanceMetricsAndCharts($financeSnapshotId: ID, $year: Int, $isTotalEmissions: Boolean, $filters: UntypedData, $standardView: FinanceStandardView, $viewId: ID) @withOwner(owner: WinFinance) {
  financeHoldingView(
    financeSnapshotId: $financeSnapshotId
    year: $year
    isTotalEmissions: $isTotalEmissions
    filters: $filters
    standardView: $standardView
    viewId: $viewId
  ) {
    metricBar {
      id
      ...FinanceMetricBarMinimal
    }
    charts {
      id
      ...FinanceChartAllFields
    }
  }
}
    ${FinanceMetricBarMinimalFragmentDoc}
${FinanceChartAllFieldsFragmentDoc}`;

export function useFinanceMetricsAndChartsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceMetricsAndChartsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceMetricsAndChartsQuery,
    GQTypes.GQFinanceMetricsAndChartsQueryVariables
  >({ query: FinanceMetricsAndChartsDocument, ...options });
}
export const FinanceMeasurementProgressTabDocument = gql`
    query FinanceMeasurementProgressTab($year: Int!, $filters: UntypedData!) @withOwner(owner: WinFinance) {
  financeMeasurementProgressRows(year: $year, filters: $filters) {
    ...FinanceMeasurementProgressRowAllFields
  }
  funds {
    id
    name
  }
}
    ${FinanceMeasurementProgressRowAllFieldsFragmentDoc}`;

export function useFinanceMeasurementProgressTabQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceMeasurementProgressTabQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceMeasurementProgressTabQuery,
    GQTypes.GQFinanceMeasurementProgressTabQueryVariables
  >({ query: FinanceMeasurementProgressTabDocument, ...options });
}
export const FinanceAutomaticRemindersDocument = gql`
    query FinanceAutomaticReminders @withOwner(owner: WinFinance) {
  financeAutomaticReminders {
    ...FinanceAutomaticReminderAllFields
  }
}
    ${FinanceAutomaticReminderAllFieldsFragmentDoc}`;

export function useFinanceAutomaticRemindersQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceAutomaticRemindersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceAutomaticRemindersQuery,
    GQTypes.GQFinanceAutomaticRemindersQueryVariables
  >({ query: FinanceAutomaticRemindersDocument, ...options });
}
export const UpdateFinanceAutomaticRemindersDocument = gql`
    mutation UpdateFinanceAutomaticReminders($input: UpdateFinanceAutomaticRemindersInput!) @withOwner(owner: WinFinance) {
  updateFinanceAutomaticReminders(input: $input) {
    financeAutomaticReminders {
      ...FinanceAutomaticReminderAllFields
    }
  }
}
    ${FinanceAutomaticReminderAllFieldsFragmentDoc}`;

export function useUpdateFinanceAutomaticRemindersMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateFinanceAutomaticRemindersMutation,
    GQTypes.GQUpdateFinanceAutomaticRemindersMutationVariables
  >(UpdateFinanceAutomaticRemindersDocument);
}
export const UpdatePortalSettingsDocument = gql`
    mutation UpdatePortalSettings($portalSettingsInput: UpsertCompanyPortalSettingsInput!) @withOwner(owner: WinFinance) {
  upsertCompanyPortalSettings(input: $portalSettingsInput) {
    companyPortalSettings {
      ...CompanyPortalSettingsFields
    }
  }
}
    ${CompanyPortalSettingsFieldsFragmentDoc}`;

export function useUpdatePortalSettingsMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdatePortalSettingsMutation,
    GQTypes.GQUpdatePortalSettingsMutationVariables
  >(UpdatePortalSettingsDocument);
}
export const GetPortalSettingsDocument = gql`
    query GetPortalSettings($rootCompanyId: ID!) @withOwner(owner: WinFinance) {
  companyPortalSettings(rootCompanyId: $rootCompanyId) {
    ...CompanyPortalSettingsFields
  }
}
    ${CompanyPortalSettingsFieldsFragmentDoc}`;

export function useGetPortalSettingsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPortalSettingsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPortalSettingsQuery,
    GQTypes.GQGetPortalSettingsQueryVariables
  >({ query: GetPortalSettingsDocument, ...options });
}
export const FinanceAssetsForDataTasksDocument = gql`
    query FinanceAssetsForDataTasks @withOwner(owner: WinFinance) {
  assetsCorporate {
    ...AssetCorporateDataForTasks
  }
}
    ${AssetCorporateDataForTasksFragmentDoc}`;

export function useFinanceAssetsForDataTasksQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceAssetsForDataTasksQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceAssetsForDataTasksQuery,
    GQTypes.GQFinanceAssetsForDataTasksQueryVariables
  >({ query: FinanceAssetsForDataTasksDocument, ...options });
}
export const AssetAutoMatchDataDocument = gql`
    query AssetAutoMatchData($assetIds: [ID!]!) @withOwner(owner: WinFinance) {
  assetsAutoMatchData(assetIds: $assetIds) {
    ...AssetAutoMatchData
  }
}
    ${AssetAutoMatchDataFragmentDoc}`;

export function useAssetAutoMatchDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAssetAutoMatchDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAssetAutoMatchDataQuery,
    GQTypes.GQAssetAutoMatchDataQueryVariables
  >({ query: AssetAutoMatchDataDocument, ...options });
}
export const FinanceCompanyMatchingFinancialDataDocument = gql`
    query FinanceCompanyMatchingFinancialData($assetIds: [ID!]!, $year: Int) @withOwner(owner: WinFinance) {
  assetsCorporateFinancialData(assetIds: $assetIds, year: $year) {
    ...AssetFinancialData
  }
}
    ${AssetFinancialDataFragmentDoc}`;

export function useFinanceCompanyMatchingFinancialDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceCompanyMatchingFinancialDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceCompanyMatchingFinancialDataQuery,
    GQTypes.GQFinanceCompanyMatchingFinancialDataQueryVariables
  >({ query: FinanceCompanyMatchingFinancialDataDocument, ...options });
}
export const FinanceCompanyMatchingIndustryCodeDataDocument = gql`
    query FinanceCompanyMatchingIndustryCodeData($assetIds: [ID!]!) @withOwner(owner: WinFinance) {
  assetsIndustryCodeData(assetIds: $assetIds) {
    ...AssetIndustryCodeData
  }
}
    ${AssetIndustryCodeDataFragmentDoc}`;

export function useFinanceCompanyMatchingIndustryCodeDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceCompanyMatchingIndustryCodeDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceCompanyMatchingIndustryCodeDataQuery,
    GQTypes.GQFinanceCompanyMatchingIndustryCodeDataQueryVariables
  >({ query: FinanceCompanyMatchingIndustryCodeDataDocument, ...options });
}
export const FinanceCompanyMatchingTabDocument = gql`
    query FinanceCompanyMatchingTab($search: String, $after: String, $before: String, $first: Int, $last: Int) @withOwner(owner: WinFinance) {
  assetsCorporatePaginated(
    search: $search
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    edges {
      node {
        id
        ...AssetCorporateForMatching
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${AssetCorporateForMatchingFragmentDoc}`;

export function useFinanceCompanyMatchingTabQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceCompanyMatchingTabQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceCompanyMatchingTabQuery,
    GQTypes.GQFinanceCompanyMatchingTabQueryVariables
  >({ query: FinanceCompanyMatchingTabDocument, ...options });
}
export const UpdateAssetYearsDocument = gql`
    mutation UpdateAssetYears($input: UpdateAssetYearsNewInput!) @withOwner(owner: WinFinance) {
  updateAssetYears(input: $input) {
    assetYears {
      id
      ...AssetYearAllFields
    }
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${AssetYearAllFieldsFragmentDoc}
${FinanceSnapshotIsStaleFragmentDoc}`;

export function useUpdateAssetYearsMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateAssetYearsMutation,
    GQTypes.GQUpdateAssetYearsMutationVariables
  >(UpdateAssetYearsDocument);
}
export const FinanceDataFlagsDocument = gql`
    query FinanceDataFlags($financeSnapshotId: ID, $fundId: ID, $assetId: ID, $year: Int) @withOwner(owner: WinFinance) {
  financeDataFlags(
    financeSnapshotId: $financeSnapshotId
    fundId: $fundId
    assetId: $assetId
    year: $year
  ) {
    ...FinanceDataFlagForFlagView
  }
}
    ${FinanceDataFlagForFlagViewFragmentDoc}`;

export function useFinanceDataFlagsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceDataFlagsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceDataFlagsQuery,
    GQTypes.GQFinanceDataFlagsQueryVariables
  >({ query: FinanceDataFlagsDocument, ...options });
}
export const GetFinanceImportDiffDocument = gql`
    query GetFinanceImportDiff($financeImportId: ID!) @withOwner(owner: WinFinance) {
  financeImport(id: $financeImportId) {
    id
    diff
  }
}
    `;

export function useGetFinanceImportDiffQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFinanceImportDiffQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFinanceImportDiffQuery,
    GQTypes.GQGetFinanceImportDiffQueryVariables
  >({ query: GetFinanceImportDiffDocument, ...options });
}
export const AcceptFinanceImportDiffDocument = gql`
    mutation AcceptFinanceImportDiff($input: AcceptFinanceImportDiffInput!) @withOwner(owner: WinFinance) {
  acceptFinanceImportDiff(input: $input) {
    financeImport {
      id
      accepted
    }
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${FinanceSnapshotIsStaleFragmentDoc}`;

export function useAcceptFinanceImportDiffMutation() {
  return Urql.useMutation<
    GQTypes.GQAcceptFinanceImportDiffMutation,
    GQTypes.GQAcceptFinanceImportDiffMutationVariables
  >(AcceptFinanceImportDiffDocument);
}
export const CreateFinanceImportDocument = gql`
    mutation CreateFinanceImport($input: CreateFinanceImportInput!) @withOwner(owner: WinFinance) {
  createFinanceImport(input: $input) {
    financeImport {
      id
    }
    backgroundJobId
  }
}
    `;

export function useCreateFinanceImportMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFinanceImportMutation,
    GQTypes.GQCreateFinanceImportMutationVariables
  >(CreateFinanceImportDocument);
}
export const CreateFinanceUploadTemplateDocument = gql`
    mutation CreateFinanceUploadTemplate($input: CreateFinanceUploadTemplateInput!) @withOwner(owner: WinFinance) {
  createFinanceUploadTemplate(input: $input) {
    oneSchemaTemplateKey
  }
}
    `;

export function useCreateFinanceUploadTemplateMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFinanceUploadTemplateMutation,
    GQTypes.GQCreateFinanceUploadTemplateMutationVariables
  >(CreateFinanceUploadTemplateDocument);
}
export const CreateFinanceHoldingsExportDocument = gql`
    mutation CreateFinanceHoldingsExport($input: CreateFinanceHoldingsExportInput!) @withOwner(owner: WinFinance) {
  createFinanceHoldingsExport(input: $input) {
    signedDownloadUrl
  }
}
    `;

export function useCreateFinanceHoldingsExportMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFinanceHoldingsExportMutation,
    GQTypes.GQCreateFinanceHoldingsExportMutationVariables
  >(CreateFinanceHoldingsExportDocument);
}
export const CreateFinanceAssetYearsExportDocument = gql`
    mutation CreateFinanceAssetYearsExport($input: CreateFinanceExportInput!) @withOwner(owner: WinFinance) {
  createFinanceAssetYearsExport(input: $input) {
    signedDownloadUrl
  }
}
    `;

export function useCreateFinanceAssetYearsExportMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFinanceAssetYearsExportMutation,
    GQTypes.GQCreateFinanceAssetYearsExportMutationVariables
  >(CreateFinanceAssetYearsExportDocument);
}
export const CreateFinanceCorporateAssetsExportDocument = gql`
    mutation CreateFinanceCorporateAssetsExport @withOwner(owner: WinFinance) {
  createFinanceCorporateAssetsExport {
    signedDownloadUrl
  }
}
    `;

export function useCreateFinanceCorporateAssetsExportMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFinanceCorporateAssetsExportMutation,
    GQTypes.GQCreateFinanceCorporateAssetsExportMutationVariables
  >(CreateFinanceCorporateAssetsExportDocument);
}
export const FinanceModelCardMetadataDocument = gql`
    query FinanceModelCardMetadata($year: Int!) @withOwner(owner: WinFinance) {
  financeModelCardMetadata(year: $year) {
    id
    numFunds
    numAssets
    numAnnualData
    numHoldings
    numContacts
  }
}
    `;

export function useFinanceModelCardMetadataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceModelCardMetadataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceModelCardMetadataQuery,
    GQTypes.GQFinanceModelCardMetadataQueryVariables
  >({ query: FinanceModelCardMetadataDocument, ...options });
}
export const DeleteFinanceThingsDocument = gql`
    mutation DeleteFinanceThings($input: DeleteFinanceThingsInput!) @withOwner(owner: WinFinance) {
  deleteFinanceThings(input: $input) {
    backgroundJobId
  }
}
    `;

export function useDeleteFinanceThingsMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteFinanceThingsMutation,
    GQTypes.GQDeleteFinanceThingsMutationVariables
  >(DeleteFinanceThingsDocument);
}
export const DeleteAllFinanceThingsDocument = gql`
    mutation DeleteAllFinanceThings($input: DeleteAllFinanceThingsInput!) @withOwner(owner: WinFinance) {
  deleteAllFinanceThings(input: $input) {
    backgroundJobId
  }
}
    `;

export function useDeleteAllFinanceThingsMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteAllFinanceThingsMutation,
    GQTypes.GQDeleteAllFinanceThingsMutationVariables
  >(DeleteAllFinanceThingsDocument);
}
export const FinanceInputDataFlagsDocument = gql`
    query FinanceInputDataFlags($financeSnapshotId: ID!, $year: Int!, $fundId: ID, $assetId: ID) @withOwner(owner: WinFinance) {
  financeInputDataFlags(
    financeSnapshotId: $financeSnapshotId
    year: $year
    fundId: $fundId
    assetId: $assetId
  ) {
    ...FinanceInputDataFlagForFlagView
  }
}
    ${FinanceInputDataFlagForFlagViewFragmentDoc}`;

export function useFinanceInputDataFlagsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceInputDataFlagsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceInputDataFlagsQuery,
    GQTypes.GQFinanceInputDataFlagsQueryVariables
  >({ query: FinanceInputDataFlagsDocument, ...options });
}
export const FinanceDataPreviousImportsDocument = gql`
    query FinanceDataPreviousImports @withOwner(owner: WinFinance) {
  financeImports {
    ...financeImportsForRender
  }
}
    ${FinanceImportsForRenderFragmentDoc}`;

export function useFinanceDataPreviousImportsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceDataPreviousImportsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceDataPreviousImportsQuery,
    GQTypes.GQFinanceDataPreviousImportsQueryVariables
  >({ query: FinanceDataPreviousImportsDocument, ...options });
}
export const CreateCorporateSandboxEstimationDocument = gql`
    mutation CreateCorporateSandboxEstimation($input: CreateCorporateSandboxEstimationInput!) @withOwner(owner: WinFinance) {
  createCorporateSandboxEstimation(input: $input) {
    backgroundJobId
  }
}
    `;

export function useCreateCorporateSandboxEstimationMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCorporateSandboxEstimationMutation,
    GQTypes.GQCreateCorporateSandboxEstimationMutationVariables
  >(CreateCorporateSandboxEstimationDocument);
}
export const FinanceEmissionsYearDocument = gql`
    query FinanceEmissionsYear($emissionsYearId: ID!) @withOwner(owner: WinFinance) {
  emissionsYear(emissionsYearId: $emissionsYearId) {
    ...EmissionsYearWithGhgCategory
  }
}
    ${EmissionsYearWithGhgCategoryFragmentDoc}`;

export function useFinanceEmissionsYearQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceEmissionsYearQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceEmissionsYearQuery,
    GQTypes.GQFinanceEmissionsYearQueryVariables
  >({ query: FinanceEmissionsYearDocument, ...options });
}
export const CreateRealEstateSandboxEstimationDocument = gql`
    mutation CreateRealEstateSandboxEstimation($input: CreateRealEstateSandboxEstimationInput!) @withOwner(owner: WinFinance) {
  createRealEstateSandboxEstimation(input: $input) {
    backgroundJobId
  }
}
    `;

export function useCreateRealEstateSandboxEstimationMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateRealEstateSandboxEstimationMutation,
    GQTypes.GQCreateRealEstateSandboxEstimationMutationVariables
  >(CreateRealEstateSandboxEstimationDocument);
}
export const GetFinanceSandboxHistoryDocument = gql`
    query GetFinanceSandboxHistory($before: String, $after: String, $first: Int, $last: Int) @withOwner(owner: WinFinance) {
  sandboxEmissionsYears(
    before: $before
    after: $after
    first: $first
    last: $last
  ) {
    edges {
      node {
        ...FinanceSandboxHistoryFields
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
  }
}
    ${FinanceSandboxHistoryFieldsFragmentDoc}`;

export function useGetFinanceSandboxHistoryQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFinanceSandboxHistoryQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFinanceSandboxHistoryQuery,
    GQTypes.GQGetFinanceSandboxHistoryQueryVariables
  >({ query: GetFinanceSandboxHistoryDocument, ...options });
}
export const FinanceSettingsTabDocument = gql`
    query FinanceSettingsTab @withOwner(owner: WinFinance) {
  financeSettings {
    ...FinanceSettingsFields
  }
}
    ${FinanceSettingsFieldsFragmentDoc}`;

export function useFinanceSettingsTabQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceSettingsTabQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceSettingsTabQuery,
    GQTypes.GQFinanceSettingsTabQueryVariables
  >({ query: FinanceSettingsTabDocument, ...options });
}
export const UpdateFinanceSettingsDocument = gql`
    mutation UpdateFinanceSettings($input: UpdateFinanceSettingsInput!) @withOwner(owner: WinFinance) {
  updateFinanceSettings(input: $input) {
    financeSettings {
      ...FinanceSettingsFields
    }
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${FinanceSettingsFieldsFragmentDoc}
${FinanceSnapshotIsStaleFragmentDoc}`;

export function useUpdateFinanceSettingsMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateFinanceSettingsMutation,
    GQTypes.GQUpdateFinanceSettingsMutationVariables
  >(UpdateFinanceSettingsDocument);
}
export const FinanceShowYourWorkDocument = gql`
    query FinanceShowYourWork($financeSnapshotId: ID!, $year: Int!, $filters: UntypedData!, $financeMetric: FinanceMetric!) @withOwner(owner: WinFinance) {
  financeShowYourWork(
    financeSnapshotId: $financeSnapshotId
    year: $year
    filters: $filters
    financeMetric: $financeMetric
  ) {
    subtitle
    aggregateRows
    rowsWithOnlyFinancedEmissions
  }
}
    `;

export function useFinanceShowYourWorkQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceShowYourWorkQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceShowYourWorkQuery,
    GQTypes.GQFinanceShowYourWorkQueryVariables
  >({ query: FinanceShowYourWorkDocument, ...options });
}
export const AssetSummaryPageCorporateDocument = gql`
    query AssetSummaryPageCorporate($assetCorporateId: ID!, $year: Int!) @withOwner(owner: WinFinance) {
  assetCorporate(assetCorporateId: $assetCorporateId) {
    id
    benchmarks {
      ...BenchmarkFieldsForExplorer
    }
    selfBenchmark(year: $year) {
      ...BenchmarkFieldsForExplorer
    }
    company {
      ...CompanyFieldsForCCISAndParents
    }
    disclosures {
      ...SupplierDisclosureFields
    }
  }
}
    ${BenchmarkFieldsForExplorerFragmentDoc}
${CompanyFieldsForCcisAndParentsFragmentDoc}
${SupplierDisclosureFieldsFragmentDoc}`;

export function useAssetSummaryPageCorporateQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAssetSummaryPageCorporateQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAssetSummaryPageCorporateQuery,
    GQTypes.GQAssetSummaryPageCorporateQueryVariables
  >({ query: AssetSummaryPageCorporateDocument, ...options });
}
export const FinanceAssetsYearOverYearDocument = gql`
    query FinanceAssetsYearOverYear($financeSnapshotId: ID!, $baselineSnapshotId: ID!, $baselineYear: Int!, $comparisonYear: Int!, $filters: UntypedData!, $search: String, $sortField: String, $sortDirection: String, $after: String, $before: String, $first: Int, $last: Int) @withOwner(owner: WinFinance) {
  financeAssetsYearOverYear(
    financeSnapshotId: $financeSnapshotId
    baselineSnapshotId: $baselineSnapshotId
    baselineYear: $baselineYear
    comparisonYear: $comparisonYear
    filters: $filters
    search: $search
    sortField: $sortField
    sortDirection: $sortDirection
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
    edges {
      node
    }
  }
}
    `;

export function useFinanceAssetsYearOverYearQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceAssetsYearOverYearQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceAssetsYearOverYearQuery,
    GQTypes.GQFinanceAssetsYearOverYearQueryVariables
  >({ query: FinanceAssetsYearOverYearDocument, ...options });
}
export const FinanceFundsYearOverYearDocument = gql`
    query FinanceFundsYearOverYear($financeSnapshotId: ID!, $baselineSnapshotId: ID!, $baselineYear: Int!, $comparisonYear: Int!, $filters: UntypedData!, $search: String, $sortField: String, $sortDirection: String, $after: String, $before: String, $first: Int, $last: Int) @withOwner(owner: WinFinance) {
  financeFundsYearOverYear(
    financeSnapshotId: $financeSnapshotId
    baselineSnapshotId: $baselineSnapshotId
    baselineYear: $baselineYear
    comparisonYear: $comparisonYear
    filters: $filters
    search: $search
    sortField: $sortField
    sortDirection: $sortDirection
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
    edges {
      node
    }
  }
}
    `;

export function useFinanceFundsYearOverYearQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceFundsYearOverYearQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceFundsYearOverYearQuery,
    GQTypes.GQFinanceFundsYearOverYearQueryVariables
  >({ query: FinanceFundsYearOverYearDocument, ...options });
}
export const FinanceSnapshotWaterfallChartDocument = gql`
    query FinanceSnapshotWaterfallChart($financeSnapshotId: ID!, $baselineSnapshotId: ID!, $baselineYear: Int!, $comparisonYear: Int!, $filters: UntypedData!, $financeWaterfallChartType: FinanceWaterfallChartType!) @withOwner(owner: WinFinance) {
  financeSnapshotWaterfallChart(
    financeSnapshotId: $financeSnapshotId
    baselineSnapshotId: $baselineSnapshotId
    baselineYear: $baselineYear
    comparisonYear: $comparisonYear
    filters: $filters
    financeWaterfallChartType: $financeWaterfallChartType
  ) {
    waterfallBlocks {
      id
      ...WaterfallBlockAllFields
    }
  }
}
    ${WaterfallBlockAllFieldsFragmentDoc}`;

export function useFinanceSnapshotWaterfallChartQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceSnapshotWaterfallChartQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceSnapshotWaterfallChartQuery,
    GQTypes.GQFinanceSnapshotWaterfallChartQueryVariables
  >({ query: FinanceSnapshotWaterfallChartDocument, ...options });
}
export const FinanceGridWorksheetDocument = gql`
    query FinanceGridWorksheet($standardView: FinanceStandardView!) @withOwner(owner: WinFinance) {
  financeGridWorksheet(standardView: $standardView) {
    id
    ...FinanceWorksheetAssetGrid
  }
}
    ${FinanceWorksheetAssetGridFragmentDoc}`;

export function useFinanceGridWorksheetQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceGridWorksheetQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceGridWorksheetQuery,
    GQTypes.GQFinanceGridWorksheetQueryVariables
  >({ query: FinanceGridWorksheetDocument, ...options });
}
export const GetFootprintMetadataForSnapshotDocument = gql`
    query GetFootprintMetadataForSnapshot($footprintSnapshotId: String!) @withOwner(owner: Calcprint) {
  footprintAnalysis(footprintSnapshotId: $footprintSnapshotId) {
    id
    footprintInterval
    footprintSnapshot {
      ...FootprintSnapshotForDrilldown
    }
    snapshotFootprintKindsMeta {
      ...FootprintKindMetaForDrilldown
    }
  }
}
    ${FootprintSnapshotForDrilldownFragmentDoc}
${FootprintKindMetaForDrilldownFragmentDoc}`;

export function useGetFootprintMetadataForSnapshotQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintMetadataForSnapshotQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintMetadataForSnapshotQuery,
    GQTypes.GQGetFootprintMetadataForSnapshotQueryVariables
  >({ query: GetFootprintMetadataForSnapshotDocument, ...options });
}
export const NextStepsSectionPermissionsDocument = gql`
    query NextStepsSectionPermissions @withOwner(owner: Calcprint) {
  canAccessSuppliers: userHasPermission(
    permission: ManageSuppliers
    allowAnyObject: true
  )
  canAccessReports: userHasPermission(
    permission: ViewReport
    allowAnyObject: true
  )
}
    `;

export function useNextStepsSectionPermissionsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQNextStepsSectionPermissionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQNextStepsSectionPermissionsQuery,
    GQTypes.GQNextStepsSectionPermissionsQueryVariables
  >({ query: NextStepsSectionPermissionsDocument, ...options });
}
export const PublishDraftFootprintDocument = gql`
    mutation PublishDraftFootprint($input: PublishDraftFootprintInput!) @withOwner(owner: Calcprint) {
  publishDraftFootprint(input: $input) {
    footprintSnapshot {
      id
    }
  }
}
    `;

export function usePublishDraftFootprintMutation() {
  return Urql.useMutation<
    GQTypes.GQPublishDraftFootprintMutation,
    GQTypes.GQPublishDraftFootprintMutationVariables
  >(PublishDraftFootprintDocument);
}
export const SetPeerCompanyGroupMemberFeatureStatusDocument = gql`
    mutation SetPeerCompanyGroupMemberFeatureStatus($input: SetPeerCompanyGroupMemberFeatureStatusInput!) @withOwner(owner: Calcprint) {
  setPeerCompanyGroupMemberFeatureStatus(input: $input) {
    peerCompanyGroupMember {
      id
      shouldHideFromFootprintOverview
      revenueAtRisk
    }
  }
}
    `;

export function useSetPeerCompanyGroupMemberFeatureStatusMutation() {
  return Urql.useMutation<
    GQTypes.GQSetPeerCompanyGroupMemberFeatureStatusMutation,
    GQTypes.GQSetPeerCompanyGroupMemberFeatureStatusMutationVariables
  >(SetPeerCompanyGroupMemberFeatureStatusDocument);
}
export const CategorySubcategoriesQueryDocument = gql`
    query CategorySubcategoriesQuery($filters: FootprintAnalysisFilters!, $footprintSnapshotId: String) @withOwner(owner: Calcprint) {
  footprintAnalysis(footprintSnapshotId: $footprintSnapshotId) {
    id
    timeseries(kind: Total, grouping: "businessSubcategory", filters: $filters) {
      id
      series {
        label
        value
        data {
          date
          value
        }
      }
    }
  }
}
    `;

export function useCategorySubcategoriesQueryQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCategorySubcategoriesQueryQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCategorySubcategoriesQueryQuery,
    GQTypes.GQCategorySubcategoriesQueryQueryVariables
  >({ query: CategorySubcategoriesQueryDocument, ...options });
}
export const FootprintConfigurationForSnapshotDocument = gql`
    query FootprintConfigurationForSnapshot($footprintSnapshotId: String!) @withOwner(owner: Calcprint) {
  footprintSnapshotInDashboard(footprintSnapshotId: $footprintSnapshotId) {
    id
    name
    identifier
    reviewStatus
    description
    footprintInterval
    createdAt
    createdBy {
      id
      displayName
    }
    footprintConfiguration {
      ...FootprintConfigurationForSnapshotFields
    }
    isUserVisible
  }
}
    ${FootprintConfigurationForSnapshotFieldsFragmentDoc}`;

export function useFootprintConfigurationForSnapshotQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFootprintConfigurationForSnapshotQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFootprintConfigurationForSnapshotQuery,
    GQTypes.GQFootprintConfigurationForSnapshotQueryVariables
  >({ query: FootprintConfigurationForSnapshotDocument, ...options });
}
export const CleanPowerAllocationDetailDocument = gql`
    query CleanPowerAllocationDetail @withOwner(owner: Calcprint) {
  marketplaceAllocationInstructionsInDashboard {
    edges {
      node {
        ...MarketplaceAllocationInstructionForConfiguration
      }
    }
  }
}
    ${MarketplaceAllocationInstructionForConfigurationFragmentDoc}`;

export function useCleanPowerAllocationDetailQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCleanPowerAllocationDetailQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCleanPowerAllocationDetailQuery,
    GQTypes.GQCleanPowerAllocationDetailQueryVariables
  >({ query: CleanPowerAllocationDetailDocument, ...options });
}
export const DeduplicationRuleSuggestionsForIndexPageDocument = gql`
    query DeduplicationRuleSuggestionsForIndexPage($footprintSnapshotId: ID!) @withOwner(owner: Calcprint) {
  organization {
    id
    footprintExclusionSuggestions(footprintSnapshotId: $footprintSnapshotId) {
      id
      suggestion {
        __typename
        kind
        ... on FootprintExclusionSuggestionSuggestionConflict {
          beaCode
          beaCodeDescription
          conflictingSources {
            __typename
            source
            ... on FootprintExclusionSuggestionSuggestionConflictSpend {
              financialAccountId
              financialAccountName
              vendorEntity
              tCO2e
            }
            ... on FootprintExclusionSuggestionSuggestionConflictActivity {
              dataset {
                id
                name
              }
              tCO2e
            }
          }
        }
        ... on FootprintExclusionSuggestionSuggestionUnmappedActivity {
          dataset {
            id
            name
          }
          tCO2e
        }
      }
    }
  }
}
    `;

export function useDeduplicationRuleSuggestionsForIndexPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDeduplicationRuleSuggestionsForIndexPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDeduplicationRuleSuggestionsForIndexPageQuery,
    GQTypes.GQDeduplicationRuleSuggestionsForIndexPageQueryVariables
  >({ query: DeduplicationRuleSuggestionsForIndexPageDocument, ...options });
}
export const FootprintUsagesForExclusionRuleStablesDocument = gql`
    query FootprintUsagesForExclusionRuleStables($stableIds: [ID!]!, $includeNonUserVisible: Boolean) @withOwner(owner: Calcprint) {
  footprintUsagesForExclusionRuleStables(exclusionRuleStableIds: $stableIds) {
    ...FootprintUsagesForExclusionRuleStable
  }
}
    ${FootprintUsagesForExclusionRuleStableFragmentDoc}`;

export function useFootprintUsagesForExclusionRuleStablesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFootprintUsagesForExclusionRuleStablesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFootprintUsagesForExclusionRuleStablesQuery,
    GQTypes.GQFootprintUsagesForExclusionRuleStablesQueryVariables
  >({ query: FootprintUsagesForExclusionRuleStablesDocument, ...options });
}
export const ExclusionEditorWithDefaultFootprintDocument = gql`
    query ExclusionEditorWithDefaultFootprint($status: FootprintStatus) @withOwner(owner: Calcprint) {
  userHasPermission(permission: ViewFootprintDetail)
  organization {
    id
    footprintExclusionRules {
      ...FootprintExclusionRuleForEditorV2
    }
  }
  defaultFootprintWithFallback(status: $status) {
    ...FootprintFields
  }
  measurementProjectsInterval
}
    ${FootprintExclusionRuleForEditorV2FragmentDoc}
${FootprintFieldsFragmentDoc}`;

export function useExclusionEditorWithDefaultFootprintQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQExclusionEditorWithDefaultFootprintQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQExclusionEditorWithDefaultFootprintQuery,
    GQTypes.GQExclusionEditorWithDefaultFootprintQueryVariables
  >({ query: ExclusionEditorWithDefaultFootprintDocument, ...options });
}
export const UpsertFootprintExclusionRulesForEditorV2Document = gql`
    mutation UpsertFootprintExclusionRulesForEditorV2($input: UpsertFootprintExclusionRulesV2Input!) @withOwner(owner: Calcprint) {
  upsertFootprintExclusionRulesV2(input: $input) {
    organization {
      id
      footprintExclusionRules {
        ...FootprintExclusionRuleForEditorV2
      }
    }
  }
}
    ${FootprintExclusionRuleForEditorV2FragmentDoc}`;

export function useUpsertFootprintExclusionRulesForEditorV2Mutation() {
  return Urql.useMutation<
    GQTypes.GQUpsertFootprintExclusionRulesForEditorV2Mutation,
    GQTypes.GQUpsertFootprintExclusionRulesForEditorV2MutationVariables
  >(UpsertFootprintExclusionRulesForEditorV2Document);
}
export const ExclusionRulesDetailDocument = gql`
    query ExclusionRulesDetail($footprintSnapshotId: ID) @withOwner(owner: Calcprint) {
  organization {
    id
    footprintExclusionRules(footprintSnapshotId: $footprintSnapshotId) {
      ...FootprintExclusionRuleForEditorV2
    }
  }
}
    ${FootprintExclusionRuleForEditorV2FragmentDoc}`;

export function useExclusionRulesDetailQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQExclusionRulesDetailQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQExclusionRulesDetailQuery,
    GQTypes.GQExclusionRulesDetailQueryVariables
  >({ query: ExclusionRulesDetailDocument, ...options });
}
export const CategoryRulesDetailDocument = gql`
    query CategoryRulesDetail($footprintSnapshotId: ID) @withOwner(owner: Calcprint) {
  organization {
    id
    footprintCategoryRules(footprintSnapshotId: $footprintSnapshotId) {
      ...FootprintCategoryRuleForEditor
    }
  }
}
    ${FootprintCategoryRuleForEditorFragmentDoc}`;

export function useCategoryRulesDetailQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCategoryRulesDetailQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCategoryRulesDetailQuery,
    GQTypes.GQCategoryRulesDetailQueryVariables
  >({ query: CategoryRulesDetailDocument, ...options });
}
export const UpsertFootprintCategoryRulesForEditorDocument = gql`
    mutation UpsertFootprintCategoryRulesForEditor($input: UpsertFootprintCategoryRulesInput!) @withOwner(owner: Calcprint) {
  upsertFootprintCategoryRules(input: $input) {
    organization {
      id
      footprintCategoryRules {
        ...FootprintCategoryRuleForEditor
      }
    }
  }
}
    ${FootprintCategoryRuleForEditorFragmentDoc}`;

export function useUpsertFootprintCategoryRulesForEditorMutation() {
  return Urql.useMutation<
    GQTypes.GQUpsertFootprintCategoryRulesForEditorMutation,
    GQTypes.GQUpsertFootprintCategoryRulesForEditorMutationVariables
  >(UpsertFootprintCategoryRulesForEditorDocument);
}
export const SaveCategoryRuleGhgCategoryNoteDocument = gql`
    mutation SaveCategoryRuleGhgCategoryNote($input: SaveCategoryRuleGhgCategoryNoteInput!) @withOwner(owner: Calcprint) {
  saveCategoryRuleGhgCategoryNote(input: $input) {
    note
  }
}
    `;

export function useSaveCategoryRuleGhgCategoryNoteMutation() {
  return Urql.useMutation<
    GQTypes.GQSaveCategoryRuleGhgCategoryNoteMutation,
    GQTypes.GQSaveCategoryRuleGhgCategoryNoteMutationVariables
  >(SaveCategoryRuleGhgCategoryNoteDocument);
}
export const RecategorizationEditorWithDefaultFootprintDocument = gql`
    query RecategorizationEditorWithDefaultFootprint($status: FootprintStatus) @withOwner(owner: Calcprint) {
  userHasPermission(permission: ViewFootprintDetail)
  organization {
    id
    footprintCategoryRules {
      ...FootprintCategoryRuleForEditor
    }
  }
  defaultFootprintWithFallback(status: $status) {
    ...FootprintFields
  }
  measurementProjectsInterval
}
    ${FootprintCategoryRuleForEditorFragmentDoc}
${FootprintFieldsFragmentDoc}`;

export function useRecategorizationEditorWithDefaultFootprintQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQRecategorizationEditorWithDefaultFootprintQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQRecategorizationEditorWithDefaultFootprintQuery,
    GQTypes.GQRecategorizationEditorWithDefaultFootprintQueryVariables
  >({ query: RecategorizationEditorWithDefaultFootprintDocument, ...options });
}
export const FootprintDetailDefaultSnapshotIdDocument = gql`
    query FootprintDetailDefaultSnapshotId($footprintId: String!, $footprintIdAsId: ID!) @withOwner(owner: Calcprint) {
  userHasPermission(permission: ViewFootprint, objectId: $footprintIdAsId)
  footprint(footprintId: $footprintId) {
    id
    name
    defaultFootprintSnapshot {
      id
    }
  }
}
    `;

export function useFootprintDetailDefaultSnapshotIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFootprintDetailDefaultSnapshotIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFootprintDetailDefaultSnapshotIdQuery,
    GQTypes.GQFootprintDetailDefaultSnapshotIdQueryVariables
  >({ query: FootprintDetailDefaultSnapshotIdDocument, ...options });
}
export const FootprintDetailDocument = gql`
    query FootprintDetail($footprintId: String!, $footprintSnapshotId: String!, $footprintIdAsId: ID!) @withOwner(owner: Calcprint) {
  userHasPermission(permission: ViewFootprint, objectId: $footprintIdAsId)
  footprint(footprintId: $footprintId) {
    ...FootprintDetail
  }
  footprintSnapshotInDashboard(footprintSnapshotId: $footprintSnapshotId) {
    ...FootprintDetailSnapshot
  }
}
    ${FootprintDetailFragmentDoc}
${FootprintDetailSnapshotFragmentDoc}`;

export function useFootprintDetailQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFootprintDetailQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFootprintDetailQuery,
    GQTypes.GQFootprintDetailQueryVariables
  >({ query: FootprintDetailDocument, ...options });
}
export const NumGeneratingFootprintsForOrgDocument = gql`
    query NumGeneratingFootprintsForOrg($includeHidden: Boolean!) @withOwner(owner: Calcprint) {
  userHasPermission(permission: ViewFootprint, allowAnyObject: true)
  numGeneratingFootprintsForOrg(includeHidden: $includeHidden)
}
    `;

export function useNumGeneratingFootprintsForOrgQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQNumGeneratingFootprintsForOrgQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQNumGeneratingFootprintsForOrgQuery,
    GQTypes.GQNumGeneratingFootprintsForOrgQueryVariables
  >({ query: NumGeneratingFootprintsForOrgDocument, ...options });
}
export const GetFootprintInsightsPageDataDocument = gql`
    query GetFootprintInsightsPageData($oneYearFilters: FootprintSummaryFilters!, $oneYearScope1Filters: FootprintSummaryFilters!, $oneYearScope2Filters: FootprintSummaryFilters!, $oneYearScope3Filters: FootprintSummaryFilters!, $oneYearNetFilters: FootprintSummaryFilters!, $twoYearNetFilters: FootprintSummaryFilters!, $allYearFilters: FootprintSummaryFilters!, $allYearRemovalFilters: FootprintSummaryFilters!, $footprintSnapshotId: String!, $footprintId: ID!, $kind: String!) @withOwner(owner: Calcprint) {
  userHasPermission(
    permission: ViewFootprint
    allowAnyObject: false
    objectId: $footprintId
  )
  organization {
    id
  }
  footprint: legacyFootprint(footprintSnapshotId: $footprintSnapshotId) {
    id
    footprintKinds
    summary(footprintKind: $kind) {
      id
      oneYearNet: total(filters: $oneYearNetFilters)
      twoYearNet: total(filters: $twoYearNetFilters)
      oneYearGross: total(filters: $oneYearFilters)
      oneYearScope1: total(filters: $oneYearScope1Filters)
      oneYearScope2: total(filters: $oneYearScope2Filters)
      oneYearScope3: total(filters: $oneYearScope3Filters)
      timeseries(filters: $allYearFilters, grouping: "businessCategory") {
        ...TimeseriesFields
      }
      removalTimeseries: timeseries(
        filters: $allYearRemovalFilters
        grouping: "businessCategory"
      ) {
        ...TimeseriesFields
      }
      heavyHitters(filters: $oneYearFilters, first: 12) {
        businessCategory
        businessSubcategory
        proportion
      }
      topCategories(filters: $oneYearFilters, first: 12) {
        businessCategory
        businessSubcategory
        proportion
      }
    }
    intensityDenominators {
      aggregateKind
      intensityKind
      data {
        yearMonth
        value
      }
    }
  }
  footprintAnalysis(footprintSnapshotId: $footprintSnapshotId) {
    id
    peerCompanyGroups {
      ...PeerCompanyGroupFieldsForInsights
    }
  }
}
    ${TimeseriesFieldsFragmentDoc}
${PeerCompanyGroupFieldsForInsightsFragmentDoc}`;

export function useGetFootprintInsightsPageDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintInsightsPageDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintInsightsPageDataQuery,
    GQTypes.GQGetFootprintInsightsPageDataQueryVariables
  >({ query: GetFootprintInsightsPageDataDocument, ...options });
}
export const FootprintExportPermissionDocument = gql`
    query FootprintExportPermission($footprintSnapshotId: ID!) @withOwner(owner: Calcprint) {
  canViewFootprintExports: userHasPermission(permission: ViewAuditDetail)
  canExportFootprintForSnapshot(footprintSnapshotId: $footprintSnapshotId)
}
    `;

export function useFootprintExportPermissionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFootprintExportPermissionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFootprintExportPermissionQuery,
    GQTypes.GQFootprintExportPermissionQueryVariables
  >({ query: FootprintExportPermissionDocument, ...options });
}
export const FootprintConfigDrawerDocument = gql`
    query FootprintConfigDrawer($footprintSnapshotId: String!) @withOwner(owner: Calcprint) {
  footprintAnalysis(footprintSnapshotId: $footprintSnapshotId) {
    id
    footprintSnapshot {
      id
      createdAt
      createdBy {
        id
        displayName
      }
    }
  }
}
    `;

export function useFootprintConfigDrawerQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFootprintConfigDrawerQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFootprintConfigDrawerQuery,
    GQTypes.GQFootprintConfigDrawerQueryVariables
  >({ query: FootprintConfigDrawerDocument, ...options });
}
export const GetDrilldownSavedViewsDocument = gql`
    query GetDrilldownSavedViews @withOwner(owner: Calcprint) {
  drilldownSavedViews {
    ...DrilldownSavedViewFields
  }
}
    ${DrilldownSavedViewFieldsFragmentDoc}`;

export function useGetDrilldownSavedViewsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetDrilldownSavedViewsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetDrilldownSavedViewsQuery,
    GQTypes.GQGetDrilldownSavedViewsQueryVariables
  >({ query: GetDrilldownSavedViewsDocument, ...options });
}
export const GetFootprintTagsDocument = gql`
    query GetFootprintTags @withOwner(owner: Calcprint) {
  footprintTags {
    id
    tagName
    description
  }
}
    `;

export function useGetFootprintTagsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintTagsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintTagsQuery,
    GQTypes.GQGetFootprintTagsQueryVariables
  >({ query: GetFootprintTagsDocument, ...options });
}
export const GetFootprintIntervalDocument = gql`
    query GetFootprintInterval($footprintSnapshotId: String!) @withOwner(owner: Calcprint) {
  footprintAnalysis(footprintSnapshotId: $footprintSnapshotId) {
    id
    footprintInterval
  }
}
    `;

export function useGetFootprintIntervalQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintIntervalQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintIntervalQuery,
    GQTypes.GQGetFootprintIntervalQueryVariables
  >({ query: GetFootprintIntervalDocument, ...options });
}
export const GenerateAuditReportDocument = gql`
    mutation GenerateAuditReport($input: GenerateAuditReportInput!) @withOwner(owner: Calcprint) {
  generateAuditReport(input: $input) {
    jobId
  }
}
    `;

export function useGenerateAuditReportMutation() {
  return Urql.useMutation<
    GQTypes.GQGenerateAuditReportMutation,
    GQTypes.GQGenerateAuditReportMutationVariables
  >(GenerateAuditReportDocument);
}
export const RegenerateAuditReportDocument = gql`
    mutation RegenerateAuditReport($input: RegenerateAuditReportInput!) @withOwner(owner: Calcprint) {
  regenerateAuditReport(input: $input) {
    jobId
  }
}
    `;

export function useRegenerateAuditReportMutation() {
  return Urql.useMutation<
    GQTypes.GQRegenerateAuditReportMutation,
    GQTypes.GQRegenerateAuditReportMutationVariables
  >(RegenerateAuditReportDocument);
}
export const FootprintsForFootprintListDocument = gql`
    query FootprintsForFootprintList($after: String, $before: String, $first: Int, $last: Int, $search: String) @withOwner(owner: Calcprint) {
  userHasPermission(permission: ViewFootprint, allowAnyObject: true)
  canEditFootprints: userHasPermission(
    permission: ManageMeasurement
    allowAnyObject: true
  )
  canManageMeasurement: userHasPermission(
    permission: ManageMeasurement
    allowAnyObject: true
  )
  canEditRules: userHasPermission(
    permission: ViewFootprintDetail
    allowAnyObject: true
  )
  canViewExports: userHasPermission(
    permission: ViewAuditDetail
    allowAnyObject: true
  )
  measurementProjectsInterval
  footprints(
    after: $after
    before: $before
    first: $first
    last: $last
    search: $search
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
    edges {
      node {
        ...FootprintFieldsForFootprintList
      }
    }
  }
}
    ${FootprintFieldsForFootprintListFragmentDoc}`;

export function useFootprintsForFootprintListQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFootprintsForFootprintListQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFootprintsForFootprintListQuery,
    GQTypes.GQFootprintsForFootprintListQueryVariables
  >({ query: FootprintsForFootprintListDocument, ...options });
}
export const GetPublicDisclosureDocument = gql`
    query GetPublicDisclosure($id: ID!) @withOwner(owner: Calcprint) {
  publicDisclosure(id: $id) {
    id
    companyId
    reportType
    publishingYear
    publicUrl
    gsUrl
    createdAt
    externalScore
    source
    sourceRecordId
    updatedAt
    qualityScore
    deletedAt
    recordStartAt
    recordEndAt
  }
}
    `;

export function useGetPublicDisclosureQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPublicDisclosureQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPublicDisclosureQuery,
    GQTypes.GQGetPublicDisclosureQueryVariables
  >({ query: GetPublicDisclosureDocument, ...options });
}
export const SubmitDataLineageFeedbackDocument = gql`
    mutation SubmitDataLineageFeedback($input: SubmitDataLineageFeedbackInput!) @withOwner(owner: Calcprint) {
  submitDataLineageFeedback(input: $input) {
    status
  }
}
    `;

export function useSubmitDataLineageFeedbackMutation() {
  return Urql.useMutation<
    GQTypes.GQSubmitDataLineageFeedbackMutation,
    GQTypes.GQSubmitDataLineageFeedbackMutationVariables
  >(SubmitDataLineageFeedbackDocument);
}
export const UserUploadsByIdForParsedFilePreviewDocument = gql`
    query UserUploadsByIdForParsedFilePreview($ids: [ID!]!) @withOwner(owner: Calcprint) {
  userUploadsById(ids: $ids) {
    ...UserUploadForDatasetPage
  }
}
    ${UserUploadForDatasetPageFragmentDoc}`;

export function useUserUploadsByIdForParsedFilePreviewQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQUserUploadsByIdForParsedFilePreviewQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQUserUploadsByIdForParsedFilePreviewQuery,
    GQTypes.GQUserUploadsByIdForParsedFilePreviewQueryVariables
  >({ query: UserUploadsByIdForParsedFilePreviewDocument, ...options });
}
export const BiCalculationDataLineageDocument = gql`
    query BiCalculationDataLineage($footprintSnapshotId: String!, $singleMeasureSelector: BiQuerySingleMeasureSelectorInput!, $fingerprint: String, $emissionsModelEvaluationId: String, $conversionFactorId: String, $footprintId: ID) @withOwner(owner: Calcprint) {
  userHasPermission(permission: ViewFootprint, objectId: $footprintId)
  biCalculationDataLineage(
    footprintSnapshotId: $footprintSnapshotId
    singleMeasureSelector: $singleMeasureSelector
    fingerprint: $fingerprint
    emissionsModelEvaluationId: $emissionsModelEvaluationId
    conversionFactorId: $conversionFactorId
  ) {
    ...SelectedCalculationDataLineage
  }
}
    ${SelectedCalculationDataLineageFragmentDoc}`;

export function useBiCalculationDataLineageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBiCalculationDataLineageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBiCalculationDataLineageQuery,
    GQTypes.GQBiCalculationDataLineageQueryVariables
  >({ query: BiCalculationDataLineageDocument, ...options });
}
export const ActivityDataDataLineageDocument = gql`
    query ActivityDataDataLineage($singleMeasureSelector: BiQuerySingleMeasureSelectorInput!, $queryContext: BiQueryContextInput!) @withOwner(owner: Calcprint) {
  activityDataDataLineage(
    singleMeasureSelector: $singleMeasureSelector
    queryContext: $queryContext
  ) {
    ...SelectedCalculationDataLineage
  }
}
    ${SelectedCalculationDataLineageFragmentDoc}`;

export function useActivityDataDataLineageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQActivityDataDataLineageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQActivityDataDataLineageQuery,
    GQTypes.GQActivityDataDataLineageQueryVariables
  >({ query: ActivityDataDataLineageDocument, ...options });
}
export const CreateFootprintDocument = gql`
    mutation CreateFootprint($input: CreateFootprintInput!) @withOwner(owner: Calcprint) {
  createFootprint(input: $input) {
    footprint {
      ...FootprintFields
    }
  }
}
    ${FootprintFieldsFragmentDoc}`;

export function useCreateFootprintMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFootprintMutation,
    GQTypes.GQCreateFootprintMutationVariables
  >(CreateFootprintDocument);
}
export const RenameFootprintDocument = gql`
    mutation RenameFootprint($input: RenameFootprintInput!) @withOwner(owner: Calcprint) {
  renameFootprint(input: $input) {
    footprint {
      ...FootprintFields
    }
  }
}
    ${FootprintFieldsFragmentDoc}`;

export function useRenameFootprintMutation() {
  return Urql.useMutation<
    GQTypes.GQRenameFootprintMutation,
    GQTypes.GQRenameFootprintMutationVariables
  >(RenameFootprintDocument);
}
export const NewVersionNoticeDialogContentDocument = gql`
    query NewVersionNoticeDialogContent($footprintId: String!) @withOwner(owner: Calcprint) {
  footprint(footprintId: $footprintId) {
    id
    name
    locked
    defaultFootprintSnapshot {
      id
    }
    footprintSnapshots {
      ...FootprintSnapshotForFootprintSelector
    }
  }
}
    ${FootprintSnapshotForFootprintSelectorFragmentDoc}`;

export function useNewVersionNoticeDialogContentQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQNewVersionNoticeDialogContentQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQNewVersionNoticeDialogContentQuery,
    GQTypes.GQNewVersionNoticeDialogContentQueryVariables
  >({ query: NewVersionNoticeDialogContentDocument, ...options });
}
export const ProductsAndMaterialFootprintDocument = gql`
    query ProductsAndMaterialFootprint($footprintSnapshotId: String) @withOwner(owner: Calcprint) {
  footprintAnalysis(footprintSnapshotId: $footprintSnapshotId) {
    id
    footprintInterval
    footprintSnapshot {
      ...FootprintSnapshotForProducts
    }
    footprintKinds
  }
}
    ${FootprintSnapshotForProductsFragmentDoc}`;

export function useProductsAndMaterialFootprintQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQProductsAndMaterialFootprintQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQProductsAndMaterialFootprintQuery,
    GQTypes.GQProductsAndMaterialFootprintQueryVariables
  >({ query: ProductsAndMaterialFootprintDocument, ...options });
}
export const GetFootprintKindsWithProductsOrMaterialsDocument = gql`
    query GetFootprintKindsWithProductsOrMaterials($footprintSnapshotId: String!) @withOwner(owner: Calcprint) {
  footprintKindsWithProductsOrMaterials(footprintSnapshotId: $footprintSnapshotId) {
    footprintKind
  }
}
    `;

export function useGetFootprintKindsWithProductsOrMaterialsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintKindsWithProductsOrMaterialsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintKindsWithProductsOrMaterialsQuery,
    GQTypes.GQGetFootprintKindsWithProductsOrMaterialsQueryVariables
  >({ query: GetFootprintKindsWithProductsOrMaterialsDocument, ...options });
}
export const MaterialsUpdatedTaxonomyDocument = gql`
    query MaterialsUpdatedTaxonomy($footprintSnapshotId: String!, $orgMaterial: String, $orgRawMaterial: String, $groupByMonth: Boolean!, $dateInterval: YMInterval, $footprintKind: String) @withOwner(owner: Calcprint) {
  materialsUpdatedTaxonomy(
    footprintSnapshotId: $footprintSnapshotId
    orgMaterial: $orgMaterial
    orgRawMaterial: $orgRawMaterial
    groupByMonth: $groupByMonth
    dateInterval: $dateInterval
    footprintKind: $footprintKind
  ) {
    id
    orgMaterial
    orgRawMaterial
    product {
      id
      product
      totalEmissions
    }
    emissionsPerKg
    inputQuantity
    inputQuantityPercentage
    totalEmissions
    totalEmissionsPercent
    vectors {
      vectorName
      vectorValues
    }
  }
}
    `;

export function useMaterialsUpdatedTaxonomyQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMaterialsUpdatedTaxonomyQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMaterialsUpdatedTaxonomyQuery,
    GQTypes.GQMaterialsUpdatedTaxonomyQueryVariables
  >({ query: MaterialsUpdatedTaxonomyDocument, ...options });
}
export const ProductsUpdatedTaxonomyDocument = gql`
    query ProductsUpdatedTaxonomy($footprintSnapshotId: String!, $product: String, $groupByMonth: Boolean!, $dateInterval: YMInterval, $footprintKind: String) @withOwner(owner: Calcprint) {
  productsUpdatedTaxonomy(
    footprintSnapshotId: $footprintSnapshotId
    product: $product
    groupByMonth: $groupByMonth
    dateInterval: $dateInterval
    footprintKind: $footprintKind
  ) {
    id
    product
    vectors {
      vectorName
      vectorValues
    }
    emissionsPerUnit
    unitsProduced
    unitsProducedPercentage
    totalEmissions
    totalEmissionsPercent
    categoryEmissionsPercent
    relatedMaterials {
      id
      material
    }
  }
}
    `;

export function useProductsUpdatedTaxonomyQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQProductsUpdatedTaxonomyQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQProductsUpdatedTaxonomyQuery,
    GQTypes.GQProductsUpdatedTaxonomyQueryVariables
  >({ query: ProductsUpdatedTaxonomyDocument, ...options });
}
export const EmissionsSliceMaterialPageDataUpdatedTaxonomyDocument = gql`
    query EmissionsSliceMaterialPageDataUpdatedTaxonomy($footprintSnapshotId: String!, $footprintKind: String, $orgMaterial: String!, $orgRawMaterial: String) @withOwner(owner: Calcprint) {
  items: materialsUpdatedTaxonomy(
    footprintSnapshotId: $footprintSnapshotId
    footprintKind: $footprintKind
    orgMaterial: $orgMaterial
    orgRawMaterial: $orgRawMaterial
    groupByMonth: false
  ) {
    ...EmissionsSliceMaterialFootprintRowUpdatedTaxonomy
  }
  footprintAnalysis(footprintSnapshotId: $footprintSnapshotId) {
    id
    footprintInterval
  }
}
    ${EmissionsSliceMaterialFootprintRowUpdatedTaxonomyFragmentDoc}`;

export function useEmissionsSliceMaterialPageDataUpdatedTaxonomyQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEmissionsSliceMaterialPageDataUpdatedTaxonomyQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEmissionsSliceMaterialPageDataUpdatedTaxonomyQuery,
    GQTypes.GQEmissionsSliceMaterialPageDataUpdatedTaxonomyQueryVariables
  >({
    query: EmissionsSliceMaterialPageDataUpdatedTaxonomyDocument,
    ...options,
  });
}
export const EmissionsSliceProductPageDataUpdatedTaxonomyDocument = gql`
    query EmissionsSliceProductPageDataUpdatedTaxonomy($footprintSnapshotId: String!, $footprintKind: String, $productId: String!) @withOwner(owner: Calcprint) {
  items: productsUpdatedTaxonomy(
    footprintSnapshotId: $footprintSnapshotId
    footprintKind: $footprintKind
    product: $productId
    groupByMonth: false
  ) {
    ...EmissionsSliceProductFootprintRowUpdatedTaxonomy
  }
  footprintAnalysis(
    footprintSnapshotId: $footprintSnapshotId
    footprintKind: $footprintKind
  ) {
    id
    footprintInterval
  }
}
    ${EmissionsSliceProductFootprintRowUpdatedTaxonomyFragmentDoc}`;

export function useEmissionsSliceProductPageDataUpdatedTaxonomyQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEmissionsSliceProductPageDataUpdatedTaxonomyQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEmissionsSliceProductPageDataUpdatedTaxonomyQuery,
    GQTypes.GQEmissionsSliceProductPageDataUpdatedTaxonomyQueryVariables
  >({
    query: EmissionsSliceProductPageDataUpdatedTaxonomyDocument,
    ...options,
  });
}
export const EvaluatedEmissionsSliceDataDocument = gql`
    query EvaluatedEmissionsSliceData($footprintSnapshotId: String!, $footprintKind: String, $orgMaterial: String, $orgRawMaterial: String, $productId: String) @withOwner(owner: Calcprint) {
  evaluatedEmissionsModels(
    footprintSnapshotId: $footprintSnapshotId
    footprintKind: $footprintKind
    materialId: $orgMaterial
    orgRawMaterial: $orgRawMaterial
    productId: $productId
  ) {
    emissionsModelVersions {
      id
      description
      emissionsModelJson
      importedDescendants {
        id
        title
        description
        changelog
        createdAt
        schemaVersion
        emissionsModelJson
        parameterResolverJson
        stableModel {
          id
          latestPublishedVersion {
            id
            changelog
            createdAt
          }
        }
      }
    }
  }
}
    `;

export function useEvaluatedEmissionsSliceDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEvaluatedEmissionsSliceDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEvaluatedEmissionsSliceDataQuery,
    GQTypes.GQEvaluatedEmissionsSliceDataQueryVariables
  >({ query: EvaluatedEmissionsSliceDataDocument, ...options });
}
export const DefaultFootprintWithFallbackDocument = gql`
    query DefaultFootprintWithFallback($status: FootprintStatus) @withOwner(owner: Calcprint) {
  userHasPermission(permission: ViewFootprint, allowAnyObject: true)
  defaultFootprintWithFallback(status: $status) {
    ...FootprintFields
  }
}
    ${FootprintFieldsFragmentDoc}`;

export function useDefaultFootprintWithFallbackQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQDefaultFootprintWithFallbackQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDefaultFootprintWithFallbackQuery,
    GQTypes.GQDefaultFootprintWithFallbackQueryVariables
  >({ query: DefaultFootprintWithFallbackDocument, ...options });
}
export const DefaultFootprintDocument = gql`
    query DefaultFootprint($status: FootprintStatus) @withOwner(owner: Calcprint) {
  defaultFootprint(status: $status) {
    ...FootprintFields
  }
}
    ${FootprintFieldsFragmentDoc}`;

export function useDefaultFootprintQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQDefaultFootprintQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDefaultFootprintQuery,
    GQTypes.GQDefaultFootprintQueryVariables
  >({ query: DefaultFootprintDocument, ...options });
}
export const CancelFootprintReviewRequestDocument = gql`
    mutation CancelFootprintReviewRequest($input: DeleteWatershedFootprintReviewRequestInput!) @withOwner(owner: Calcprint) {
  deleteWatershedFootprintReviewRequest(input: $input) {
    footprintSnapshot {
      id
      watershedFootprintReviewRequest {
        id
      }
    }
  }
}
    `;

export function useCancelFootprintReviewRequestMutation() {
  return Urql.useMutation<
    GQTypes.GQCancelFootprintReviewRequestMutation,
    GQTypes.GQCancelFootprintReviewRequestMutationVariables
  >(CancelFootprintReviewRequestDocument);
}
export const CreateWatershedFootprintReviewRequestDocument = gql`
    mutation CreateWatershedFootprintReviewRequest($input: CreateWatershedFootprintReviewRequestInput!) @withOwner(owner: Calcprint) {
  createWatershedFootprintReviewRequest(input: $input) {
    footprintSnapshot {
      id
      status
      reviewStatus
      watershedFootprintReviewRequest {
        ...WatershedFootprintReviewRequestForCreate
      }
    }
  }
}
    ${WatershedFootprintReviewRequestForCreateFragmentDoc}`;

export function useCreateWatershedFootprintReviewRequestMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateWatershedFootprintReviewRequestMutation,
    GQTypes.GQCreateWatershedFootprintReviewRequestMutationVariables
  >(CreateWatershedFootprintReviewRequestDocument);
}
export const UpdateFootprintReviewRequestStatusDocument = gql`
    mutation UpdateFootprintReviewRequestStatus($input: UpdateWatershedFootprintReviewRequestStatusInput!) @withOwner(owner: Calcprint) {
  updateWatershedFootprintReviewRequestStatus(input: $input) {
    watershedFootprintReviewRequest {
      id
      status
    }
  }
}
    `;

export function useUpdateFootprintReviewRequestStatusMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateFootprintReviewRequestStatusMutation,
    GQTypes.GQUpdateFootprintReviewRequestStatusMutationVariables
  >(UpdateFootprintReviewRequestStatusDocument);
}
export const FootprintsForFootprintSelectorDocument = gql`
    query FootprintsForFootprintSelector($after: String, $before: String, $first: Int, $last: Int, $search: String, $includeNonUserVisible: Boolean) @withOwner(owner: Calcprint) {
  userHasPermission(permission: ViewFootprint, allowAnyObject: true)
  footprints(
    after: $after
    before: $before
    first: $first
    last: $last
    search: $search
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
    edges {
      node {
        ...FootprintFieldsForFootprintSelector
      }
    }
  }
}
    ${FootprintFieldsForFootprintSelectorFragmentDoc}`;

export function useFootprintsForFootprintSelectorQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFootprintsForFootprintSelectorQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFootprintsForFootprintSelectorQuery,
    GQTypes.GQFootprintsForFootprintSelectorQueryVariables
  >({ query: FootprintsForFootprintSelectorDocument, ...options });
}
export const GetFootprintSnapshotTimelineEntriesDocument = gql`
    query GetFootprintSnapshotTimelineEntries($footprintId: String!, $footprintIdAsId: ID!, $includeNonUserVisible: Boolean) @withOwner(owner: Calcprint) {
  userHasPermission(permission: ViewFootprint, objectId: $footprintIdAsId)
  footprint(footprintId: $footprintId) {
    id
    footprintSnapshots(includeNonUserVisible: $includeNonUserVisible) {
      ...FootprintSnapshotForFootprintSelector
    }
  }
  latestFootprintAssemblyRun {
    ...FootprintAssemblyRunForChangelog
  }
}
    ${FootprintSnapshotForFootprintSelectorFragmentDoc}
${FootprintAssemblyRunForChangelogFragmentDoc}`;

export function useGetFootprintSnapshotTimelineEntriesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintSnapshotTimelineEntriesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintSnapshotTimelineEntriesQuery,
    GQTypes.GQGetFootprintSnapshotTimelineEntriesQueryVariables
  >({ query: GetFootprintSnapshotTimelineEntriesDocument, ...options });
}
export const DeletePrivateDisclosureTargetByIdDocument = gql`
    mutation deletePrivateDisclosureTargetById($input: IdInput!) {
  deletePrivateDisclosureTargetById(input: $input) {
    disclosure {
      ...SupplierDisclosureFields
    }
    targetId
  }
}
    ${SupplierDisclosureFieldsFragmentDoc}`;

export function useDeletePrivateDisclosureTargetByIdMutation() {
  return Urql.useMutation<
    GQTypes.GQDeletePrivateDisclosureTargetByIdMutation,
    GQTypes.GQDeletePrivateDisclosureTargetByIdMutationVariables
  >(DeletePrivateDisclosureTargetByIdDocument);
}
export const CreateSupplierContactDocument = gql`
    mutation CreateSupplierContact($input: CreateSupplierContactInput!, $supplierId: String!) @withOwner(owner: SupplyChain) {
  createSupplierContact(input: $input, supplierId: $supplierId) {
    contact {
      id
      name
      email
      role
      userId
      companyId
      company {
        id
        contacts {
          id
        }
      }
    }
  }
}
    `;

export function useCreateSupplierContactMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateSupplierContactMutation,
    GQTypes.GQCreateSupplierContactMutationVariables
  >(CreateSupplierContactDocument);
}
export const UpdateSupplierContactDocument = gql`
    mutation UpdateSupplierContact($input: UpdateSupplierContactInput!, $supplierId: String!) @withOwner(owner: SupplyChain) {
  updateSupplierContact(input: $input, supplierId: $supplierId) {
    contact {
      id
      name
      email
      role
      userId
      companyId
    }
  }
}
    `;

export function useUpdateSupplierContactMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateSupplierContactMutation,
    GQTypes.GQUpdateSupplierContactMutationVariables
  >(UpdateSupplierContactDocument);
}
export const DeleteSupplierContactDocument = gql`
    mutation DeleteSupplierContact($input: DeleteSupplierContactInput!, $supplierId: String!) @withOwner(owner: SupplyChain) {
  deleteSupplierContact(input: $input, supplierId: $supplierId) {
    numberDeleted
    id
    company {
      id
      contacts {
        id
      }
    }
  }
}
    `;

export function useDeleteSupplierContactMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteSupplierContactMutation,
    GQTypes.GQDeleteSupplierContactMutationVariables
  >(DeleteSupplierContactDocument);
}
export const RequestSupplyChainHelpDocument = gql`
    mutation RequestSupplyChainHelp($input: RequestHelpInput!) @withOwner(owner: SupplyChain) {
  requestSupplyChainHelp(input: $input) {
    status
  }
}
    `;

export function useRequestSupplyChainHelpMutation() {
  return Urql.useMutation<
    GQTypes.GQRequestSupplyChainHelpMutation,
    GQTypes.GQRequestSupplyChainHelpMutationVariables
  >(RequestSupplyChainHelpDocument);
}
export const EngagementTaskQuestionsForBaseConfigIdDocument = gql`
    query EngagementTaskQuestionsForBaseConfigId($baseConfigId: ID!, $excludeComplex: Boolean) @withOwner(owner: SupplyChain) {
  engagementTaskQuestionsForBaseConfigId(
    baseConfigId: $baseConfigId
    excludeComplex: $excludeComplex
  ) {
    questionKey
    description
    engagementTaskConfigId
    engagementTaskConfigName
    taskConfigsWithQuestion
    componentId
  }
}
    `;

export function useEngagementTaskQuestionsForBaseConfigIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEngagementTaskQuestionsForBaseConfigIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEngagementTaskQuestionsForBaseConfigIdQuery,
    GQTypes.GQEngagementTaskQuestionsForBaseConfigIdQueryVariables
  >({ query: EngagementTaskQuestionsForBaseConfigIdDocument, ...options });
}
export const GetSupplierAttachmentsDocument = gql`
    query GetSupplierAttachments($companyId: ID!) @withOwner(owner: SupplyChain) {
  supplierAttachments(companyId: $companyId) {
    ...SupplierAttachmentFields
  }
}
    ${SupplierAttachmentFieldsFragmentDoc}`;

export function useGetSupplierAttachmentsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSupplierAttachmentsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSupplierAttachmentsQuery,
    GQTypes.GQGetSupplierAttachmentsQueryVariables
  >({ query: GetSupplierAttachmentsDocument, ...options });
}
export const GetSupplierAttachmentsByCrossOrgIdsDocument = gql`
    query GetSupplierAttachmentsByCrossOrgIds($crossOrgModelIds: [String!]!) @withOwner(owner: SupplyChain) {
  supplierAttachmentsByCrossOrgIds(crossOrgModelIds: $crossOrgModelIds) {
    ...SupplierAttachmentFields
  }
}
    ${SupplierAttachmentFieldsFragmentDoc}`;

export function useGetSupplierAttachmentsByCrossOrgIdsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSupplierAttachmentsByCrossOrgIdsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSupplierAttachmentsByCrossOrgIdsQuery,
    GQTypes.GQGetSupplierAttachmentsByCrossOrgIdsQueryVariables
  >({ query: GetSupplierAttachmentsByCrossOrgIdsDocument, ...options });
}
export const CreateSupplierAttachmentsDocument = gql`
    mutation CreateSupplierAttachments($input: CreateSupplierAttachmentsInput!) @withOwner(owner: SupplyChain) {
  createSupplierAttachments(input: $input) {
    fileUrls {
      url
      attachment {
        ...SupplierAttachmentFields
      }
    }
  }
}
    ${SupplierAttachmentFieldsFragmentDoc}`;

export function useCreateSupplierAttachmentsMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateSupplierAttachmentsMutation,
    GQTypes.GQCreateSupplierAttachmentsMutationVariables
  >(CreateSupplierAttachmentsDocument);
}
export const UpdateSupplierAttachmentDocument = gql`
    mutation UpdateSupplierAttachment($input: UpdateSupplierAttachmentInput!) @withOwner(owner: SupplyChain) {
  updateSupplierAttachment(input: $input) {
    attachment {
      ...SupplierAttachmentFields
    }
  }
}
    ${SupplierAttachmentFieldsFragmentDoc}`;

export function useUpdateSupplierAttachmentMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateSupplierAttachmentMutation,
    GQTypes.GQUpdateSupplierAttachmentMutationVariables
  >(UpdateSupplierAttachmentDocument);
}
export const DeleteSupplierAttachmentDocument = gql`
    mutation DeleteSupplierAttachment($input: DeleteSupplierAttachmentInput!) @withOwner(owner: SupplyChain) {
  deleteSupplierAttachment(input: $input) {
    numberDeleted
  }
}
    `;

export function useDeleteSupplierAttachmentMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteSupplierAttachmentMutation,
    GQTypes.GQDeleteSupplierAttachmentMutationVariables
  >(DeleteSupplierAttachmentDocument);
}
export const GetFileDownloadUrlDocument = gql`
    query GetFileDownloadUrl($fileMetadataId: ID!) @withOwner(owner: SupplyChain) {
  supplierAttachmentDownloadUrl(fileMetadataId: $fileMetadataId)
}
    `;

export function useGetFileDownloadUrlQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFileDownloadUrlQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFileDownloadUrlQuery,
    GQTypes.GQGetFileDownloadUrlQueryVariables
  >({ query: GetFileDownloadUrlDocument, ...options });
}
export const GetUsersWithSupplierPermissionDocument = gql`
    query GetUsersWithSupplierPermission($companyId: ID!) @withOwner(owner: SupplyChain) {
  usersWithSupplierPermission(companyId: $companyId) {
    id
    name
    displayName
    email
  }
}
    `;

export function useGetUsersWithSupplierPermissionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetUsersWithSupplierPermissionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetUsersWithSupplierPermissionQuery,
    GQTypes.GQGetUsersWithSupplierPermissionQueryVariables
  >({ query: GetUsersWithSupplierPermissionDocument, ...options });
}
export const GetDisclosureEmissionsFactorsDocument = gql`
    query GetDisclosureEmissionsFactors($publicDisclosureIds: [ID!]!, $privateDisclosureIds: [ID!]!) @withOwner(owner: SupplyChain) {
  disclosureEmissionsFactors(
    publicDisclosureIds: $publicDisclosureIds
    privateDisclosureIds: $privateDisclosureIds
  ) {
    publicDisclosureId
    privateDisclosureId
    emissionsFactorRow {
      id
      rowFields
      citationName
      citationNotes
      citationUrl
      citationSlug
      isCustomerProvided
    }
  }
}
    `;

export function useGetDisclosureEmissionsFactorsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetDisclosureEmissionsFactorsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetDisclosureEmissionsFactorsQuery,
    GQTypes.GQGetDisclosureEmissionsFactorsQueryVariables
  >({ query: GetDisclosureEmissionsFactorsDocument, ...options });
}
export const SupplierEmissionsFactorsTableRowsDocument = gql`
    query SupplierEmissionsFactorsTableRows($companyId: String!) @withOwner(owner: SupplyChain) {
  supplierEmissionsFactorsTableRows(companyId: $companyId) {
    referenceDataRevisionRows {
      id
      rowFields
      citationName
      citationNotes
      citationUrl
      citationSlug
      isCustomerProvided
    }
  }
}
    `;

export function useSupplierEmissionsFactorsTableRowsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQSupplierEmissionsFactorsTableRowsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSupplierEmissionsFactorsTableRowsQuery,
    GQTypes.GQSupplierEmissionsFactorsTableRowsQueryVariables
  >({ query: SupplierEmissionsFactorsTableRowsDocument, ...options });
}
export const SupplierEmissionsFactorsTableMetadataDocument = gql`
    query SupplierEmissionsFactorsTableMetadata @withOwner(owner: SupplyChain) {
  supplierEmissionsFactorsTableMetadata {
    displayColumns {
      parquetColumnName
      displayName
      type
      description
    }
    quantityColumnName
  }
}
    `;

export function useSupplierEmissionsFactorsTableMetadataQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQSupplierEmissionsFactorsTableMetadataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSupplierEmissionsFactorsTableMetadataQuery,
    GQTypes.GQSupplierEmissionsFactorsTableMetadataQueryVariables
  >({ query: SupplierEmissionsFactorsTableMetadataDocument, ...options });
}
export const HasAccessToFootprintsDocument = gql`
    query HasAccessToFootprints @withOwner(owner: Calcprint) {
  userHasPermission(permission: ViewFootprint, allowAnyObject: true)
}
    `;

export function useHasAccessToFootprintsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQHasAccessToFootprintsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQHasAccessToFootprintsQuery,
    GQTypes.GQHasAccessToFootprintsQueryVariables
  >({ query: HasAccessToFootprintsDocument, ...options });
}
export const SupplierDataExportDocument = gql`
    mutation supplierDataExport($input: SupplierDataExportInput!) @withOwner(owner: SupplyChain) {
  supplierDataExport(input: $input) {
    workflowId
  }
}
    `;

export function useSupplierDataExportMutation() {
  return Urql.useMutation<
    GQTypes.GQSupplierDataExportMutation,
    GQTypes.GQSupplierDataExportMutationVariables
  >(SupplierDataExportDocument);
}
export const SupplierFilterOptionsDocument = gql`
    query SupplierFilterOptions($dimension: String!, $nestedDimensions: [String!], $footprintSnapshotId: String) @withOwner(owner: SupplyChain) {
  supplierFilterOptions(
    dimension: $dimension
    nestedDimensions: $nestedDimensions
    footprintSnapshotId: $footprintSnapshotId
  ) {
    rawValue
    formattedValue
  }
}
    `;

export function useSupplierFilterOptionsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQSupplierFilterOptionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSupplierFilterOptionsQuery,
    GQTypes.GQSupplierFilterOptionsQueryVariables
  >({ query: SupplierFilterOptionsDocument, ...options });
}
export const CreateSupplierChartDocument = gql`
    mutation CreateSupplierChart($input: CreateSupplierChartInput!) @withOwner(owner: SupplyChain) {
  createSupplierChart(input: $input) {
    views {
      id
      reductionPlan {
        ...PlanWithForecastFields
      }
      charts: chartsV2 {
        ...SupplierChartConfigFields
      }
    }
  }
}
    ${PlanWithForecastFieldsFragmentDoc}
${SupplierChartConfigFieldsFragmentDoc}`;

export function useCreateSupplierChartMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateSupplierChartMutation,
    GQTypes.GQCreateSupplierChartMutationVariables
  >(CreateSupplierChartDocument);
}
export const UpdateSupplierChartDocument = gql`
    mutation UpdateSupplierChart($input: UpdateSupplierChartInput!) @withOwner(owner: SupplyChain) {
  updateSupplierChart(input: $input) {
    views {
      id
      charts: chartsV2 {
        ...SupplierChartConfigFields
      }
    }
  }
}
    ${SupplierChartConfigFieldsFragmentDoc}`;

export function useUpdateSupplierChartMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateSupplierChartMutation,
    GQTypes.GQUpdateSupplierChartMutationVariables
  >(UpdateSupplierChartDocument);
}
export const GetCompanyTargetDisclosuresDocument = gql`
    query GetCompanyTargetDisclosures($companyId: ID!, $opts: DisclosureInput) @withOwner(owner: SupplyChain) {
  companyDisclosures(companyId: $companyId, opts: $opts) {
    ...DisclosureForTargetsDialog
  }
}
    ${DisclosureForTargetsDialogFragmentDoc}`;

export function useGetCompanyTargetDisclosuresQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCompanyTargetDisclosuresQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCompanyTargetDisclosuresQuery,
    GQTypes.GQGetCompanyTargetDisclosuresQueryVariables
  >({ query: GetCompanyTargetDisclosuresDocument, ...options });
}
export const CreatePrivateDisclosureDocument = gql`
    mutation CreatePrivateDisclosure($input: CreatePrivateDisclosureInput!) @withOwner(owner: SupplyChain) {
  createPrivateDisclosure(input: $input) {
    disclosure {
      ...SupplierDisclosureFields
    }
  }
}
    ${SupplierDisclosureFieldsFragmentDoc}`;

export function useCreatePrivateDisclosureMutation() {
  return Urql.useMutation<
    GQTypes.GQCreatePrivateDisclosureMutation,
    GQTypes.GQCreatePrivateDisclosureMutationVariables
  >(CreatePrivateDisclosureDocument);
}
export const UpdatePrivateDisclosureDocument = gql`
    mutation UpdatePrivateDisclosure($input: UpdatePrivateDisclosureInput!) @withOwner(owner: SupplyChain) {
  updatePrivateDisclosure(input: $input) {
    disclosure {
      ...SupplierDisclosureFields
    }
  }
}
    ${SupplierDisclosureFieldsFragmentDoc}`;

export function useUpdatePrivateDisclosureMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdatePrivateDisclosureMutation,
    GQTypes.GQUpdatePrivateDisclosureMutationVariables
  >(UpdatePrivateDisclosureDocument);
}
export const GetCompanyPrivateDisclosuresDocument = gql`
    query GetCompanyPrivateDisclosures($companyId: ID!, $orgId: ID!) @withOwner(owner: SupplyChain) {
  companyDisclosures(
    companyId: $companyId
    opts: {privateDisclosureFilters: {orgId: $orgId}, historicalYearFilters: {}, targetFilters: {}}
  ) {
    ...SupplierDisclosureFields
  }
}
    ${SupplierDisclosureFieldsFragmentDoc}`;

export function useGetCompanyPrivateDisclosuresQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCompanyPrivateDisclosuresQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCompanyPrivateDisclosuresQuery,
    GQTypes.GQGetCompanyPrivateDisclosuresQueryVariables
  >({ query: GetCompanyPrivateDisclosuresDocument, ...options });
}
export const GetOrgEngagementTasksForOverviewDocument = gql`
    query GetOrgEngagementTasksForOverview($rootOnly: Boolean, $ignoreLearningTasks: Boolean) @withOwner(owner: SupplyChain) {
  engagementTasks(rootOnly: $rootOnly, ignoreLearningTasks: $ignoreLearningTasks) {
    edges {
      node {
        ...CompanyEngagementTaskFieldsForOverview
      }
    }
  }
}
    ${CompanyEngagementTaskFieldsForOverviewFragmentDoc}`;

export function useGetOrgEngagementTasksForOverviewQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetOrgEngagementTasksForOverviewQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetOrgEngagementTasksForOverviewQuery,
    GQTypes.GQGetOrgEngagementTasksForOverviewQueryVariables
  >({ query: GetOrgEngagementTasksForOverviewDocument, ...options });
}
export const GetCompanyAndOrgEngagementTasksForOverviewDocument = gql`
    query GetCompanyAndOrgEngagementTasksForOverview($companyId: ID!, $rootOnly: Boolean) @withOwner(owner: SupplyChain) {
  engagementTasksForCompanyAndOrgV1(companyId: $companyId, rootOnly: $rootOnly) {
    ...CompanyEngagementTaskFieldsForOverview
  }
}
    ${CompanyEngagementTaskFieldsForOverviewFragmentDoc}`;

export function useGetCompanyAndOrgEngagementTasksForOverviewQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCompanyAndOrgEngagementTasksForOverviewQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCompanyAndOrgEngagementTasksForOverviewQuery,
    GQTypes.GQGetCompanyAndOrgEngagementTasksForOverviewQueryVariables
  >({ query: GetCompanyAndOrgEngagementTasksForOverviewDocument, ...options });
}
export const GetCompanyAndOrgEngagementTasksForDetailsDocument = gql`
    query GetCompanyAndOrgEngagementTasksForDetails($companyId: ID!, $rootOnly: Boolean) @withOwner(owner: SupplyChain) {
  engagementTasksForCompanyAndOrgV1(companyId: $companyId, rootOnly: $rootOnly) {
    ...EngagementTaskFields
  }
}
    ${EngagementTaskFieldsFragmentDoc}`;

export function useGetCompanyAndOrgEngagementTasksForDetailsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCompanyAndOrgEngagementTasksForDetailsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCompanyAndOrgEngagementTasksForDetailsQuery,
    GQTypes.GQGetCompanyAndOrgEngagementTasksForDetailsQueryVariables
  >({ query: GetCompanyAndOrgEngagementTasksForDetailsDocument, ...options });
}
export const GetEngagementTasksAssignedTypesDocument = gql`
    query GetEngagementTasksAssignedTypes @withOwner(owner: SupplyChain) {
  userHasPermission(permission: ManageDisclosures, allowAnyObject: true)
  engagementTasksAssignedToOrg {
    ...EngagementTaskTypeAndStatusFields
  }
}
    ${EngagementTaskTypeAndStatusFieldsFragmentDoc}`;

export function useGetEngagementTasksAssignedTypesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEngagementTasksAssignedTypesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEngagementTasksAssignedTypesQuery,
    GQTypes.GQGetEngagementTasksAssignedTypesQueryVariables
  >({ query: GetEngagementTasksAssignedTypesDocument, ...options });
}
export const GetEngagementTasksPageDataDocument = gql`
    query GetEngagementTasksPageData($companyId: ID!) @withOwner(owner: SupplyChain) {
  companyForDashboard(id: $companyId) {
    ...CompanyFieldsForEngagementTasks
  }
  engagementTasksAssignedToOrg {
    ...EngagementTaskContents
  }
  organization {
    id
    company {
      ...CompanyFieldsForEngagementTasks
    }
    users {
      edges {
        node {
          id
          name
          displayName
        }
      }
    }
  }
  companyPortalSettings(rootCompanyId: $companyId) {
    ...CompanyPortalSettingsFields
  }
}
    ${CompanyFieldsForEngagementTasksFragmentDoc}
${EngagementTaskContentsFragmentDoc}
${CompanyPortalSettingsFieldsFragmentDoc}`;

export function useGetEngagementTasksPageDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEngagementTasksPageDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEngagementTasksPageDataQuery,
    GQTypes.GQGetEngagementTasksPageDataQueryVariables
  >({ query: GetEngagementTasksPageDataDocument, ...options });
}
export const UpsertNonFootprintSupplierDocument = gql`
    mutation UpsertNonFootprintSupplier($footprintSnapshotId: String, $footprintInterval: YMInterval!, $input: UpsertNonFootprintSupplierInput!) @withOwner(owner: SupplyChain) {
  upsertNonFootprintSupplier(
    footprintSnapshotId: $footprintSnapshotId
    footprintInterval: $footprintInterval
    input: $input
  ) {
    supplier {
      ...SupplierFields
    }
  }
}
    ${SupplierFieldsFragmentDoc}`;

export function useUpsertNonFootprintSupplierMutation() {
  return Urql.useMutation<
    GQTypes.GQUpsertNonFootprintSupplierMutation,
    GQTypes.GQUpsertNonFootprintSupplierMutationVariables
  >(UpsertNonFootprintSupplierDocument);
}
export const DeleteNonFootprintSupplierDocument = gql`
    mutation DeleteNonFootprintSupplier($input: DeleteNonFootprintSupplierInput!, $supplierId: String!) @withOwner(owner: SupplyChain) {
  deleteNonFootprintSupplier(input: $input, supplierId: $supplierId) {
    success
  }
}
    `;

export function useDeleteNonFootprintSupplierMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteNonFootprintSupplierMutation,
    GQTypes.GQDeleteNonFootprintSupplierMutationVariables
  >(DeleteNonFootprintSupplierDocument);
}
export const GetEngagementNotificationSettingsForUserDocument = gql`
    query GetEngagementNotificationSettingsForUser($side: EngagementNotificationSide!) @withOwner(owner: SupplyChain) {
  activeOrganization {
    id
    user {
      id
      engagementNotificationSettings(side: $side) {
        ...EngagementNotificationSettingFields
      }
    }
  }
}
    ${EngagementNotificationSettingFieldsFragmentDoc}`;

export function useGetEngagementNotificationSettingsForUserQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEngagementNotificationSettingsForUserQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEngagementNotificationSettingsForUserQuery,
    GQTypes.GQGetEngagementNotificationSettingsForUserQueryVariables
  >({ query: GetEngagementNotificationSettingsForUserDocument, ...options });
}
export const SetEngagementNotificationSettingsForUserDocument = gql`
    mutation setEngagementNotificationSettingsForUser($input: SetEngagementNotificationSettingsForUserInput!, $side: EngagementNotificationSide!) @withOwner(owner: SupplyChain) {
  setEngagementNotificationSettingsForUser(input: $input) {
    engagementNotificationSettings(side: $side) {
      ...EngagementNotificationSettingFields
    }
  }
}
    ${EngagementNotificationSettingFieldsFragmentDoc}`;

export function useSetEngagementNotificationSettingsForUserMutation() {
  return Urql.useMutation<
    GQTypes.GQSetEngagementNotificationSettingsForUserMutation,
    GQTypes.GQSetEngagementNotificationSettingsForUserMutationVariables
  >(SetEngagementNotificationSettingsForUserDocument);
}
export const GetSupplierActivityTagsDocument = gql`
    query GetSupplierActivityTags($footprintInterval: YMInterval!, $footprintSnapshotId: String) @withOwner(owner: SupplyChain) {
  supplierActivityTags(
    footprintInterval: $footprintInterval
    footprintSnapshotId: $footprintSnapshotId
  ) {
    tags {
      key
      value
    }
  }
}
    `;

export function useGetSupplierActivityTagsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSupplierActivityTagsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSupplierActivityTagsQuery,
    GQTypes.GQGetSupplierActivityTagsQueryVariables
  >({ query: GetSupplierActivityTagsDocument, ...options });
}
export const CreateEngagementCohortDocument = gql`
    mutation CreateEngagementCohort($input: CreateEngagementCohortInput!) @withOwner(owner: SupplyChain) {
  createEngagementCohort(input: $input) {
    ...EngagementCohortFields
  }
}
    ${EngagementCohortFieldsFragmentDoc}`;

export function useCreateEngagementCohortMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateEngagementCohortMutation,
    GQTypes.GQCreateEngagementCohortMutationVariables
  >(CreateEngagementCohortDocument);
}
export const DeleteCompanyEngagementCohortDocument = gql`
    mutation DeleteCompanyEngagementCohort($input: DeleteCompanyEngagementCohortInput!) @withOwner(owner: SupplyChain) {
  deleteCompanyEngagementCohort(input: $input) {
    affectedCompanyIds
  }
}
    `;

export function useDeleteCompanyEngagementCohortMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCompanyEngagementCohortMutation,
    GQTypes.GQDeleteCompanyEngagementCohortMutationVariables
  >(DeleteCompanyEngagementCohortDocument);
}
export const SetEngagementCohortAndMembersDocument = gql`
    mutation SetEngagementCohortAndMembers($input: SetEngagementCohortAndMembersInput!) @withOwner(owner: SupplyChain) {
  setEngagementCohortAndMembers(input: $input) {
    oldCompanyIds
    engagementCohortWithCompanyIds {
      ...EngagementCohortWithCompanyIdsFields
    }
  }
}
    ${EngagementCohortWithCompanyIdsFieldsFragmentDoc}`;

export function useSetEngagementCohortAndMembersMutation() {
  return Urql.useMutation<
    GQTypes.GQSetEngagementCohortAndMembersMutation,
    GQTypes.GQSetEngagementCohortAndMembersMutationVariables
  >(SetEngagementCohortAndMembersDocument);
}
export const PostMeasurementSupplierMappingTableRowsDocument = gql`
    query PostMeasurementSupplierMappingTableRows($search: String, $sortModel: [DataGridSortItemInput!]!, $filters: [BiQueryFilterInput!]!, $footprintSnapshotId: String, $footprintInterval: YMInterval!, $after: String, $before: String, $first: Int, $last: Int, $paginationArgs: OffsetPaginationArgsInput) @withOwner(owner: SupplyChain) {
  postMeasurementSupplierMappingTableRows(
    search: $search
    sortModel: $sortModel
    filters: $filters
    footprintSnapshotId: $footprintSnapshotId
    footprintInterval: $footprintInterval
    after: $after
    before: $before
    first: $first
    last: $last
    paginationArgs: $paginationArgs
  ) {
    ...SupplierMappingTableRowsPayloadFields
  }
}
    ${SupplierMappingTableRowsPayloadFieldsFragmentDoc}`;

export function usePostMeasurementSupplierMappingTableRowsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQPostMeasurementSupplierMappingTableRowsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPostMeasurementSupplierMappingTableRowsQuery,
    GQTypes.GQPostMeasurementSupplierMappingTableRowsQueryVariables
  >({ query: PostMeasurementSupplierMappingTableRowsDocument, ...options });
}
export const ValueMappingDocument = gql`
    query ValueMapping($valueMappingConfigId: String!) @withOwner(owner: Calcprint) {
  valueMapping(valueMappingConfigId: $valueMappingConfigId) {
    ...ValueMappingPageValueMapping
  }
}
    ${ValueMappingPageValueMappingFragmentDoc}`;

export function useValueMappingQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQValueMappingQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQValueMappingQuery,
    GQTypes.GQValueMappingQueryVariables
  >({ query: ValueMappingDocument, ...options });
}
export const DeleteSupplierScoreCriteriaDocument = gql`
    mutation DeleteSupplierScoreCriteria($input: DeleteSupplierScoreCriteriaInput!) @withOwner(owner: SupplyChain) {
  deleteSupplierScoreCriteria(input: $input) {
    supplierScorecard {
      ...SupplierScorecardFields
    }
  }
}
    ${SupplierScorecardFieldsFragmentDoc}`;

export function useDeleteSupplierScoreCriteriaMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteSupplierScoreCriteriaMutation,
    GQTypes.GQDeleteSupplierScoreCriteriaMutationVariables
  >(DeleteSupplierScoreCriteriaDocument);
}
export const CreateSupplierScoreCriteriaDocument = gql`
    mutation CreateSupplierScoreCriteria($input: CreateSupplierScoreCriteriaInput!) @withOwner(owner: SupplyChain) {
  createSupplierScoreCriteria(input: $input) {
    supplierScoreCriteria {
      ...SupplierScoreCriteriaFields
    }
  }
}
    ${SupplierScoreCriteriaFieldsFragmentDoc}`;

export function useCreateSupplierScoreCriteriaMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateSupplierScoreCriteriaMutation,
    GQTypes.GQCreateSupplierScoreCriteriaMutationVariables
  >(CreateSupplierScoreCriteriaDocument);
}
export const UpdateSupplierScoreCriteriaDocument = gql`
    mutation UpdateSupplierScoreCriteria($input: UpdateSupplierScoreCriteriaInput!) @withOwner(owner: SupplyChain) {
  updateSupplierScoreCriteria(input: $input) {
    supplierScoreCriteria {
      ...SupplierScoreCriteriaFields
    }
  }
}
    ${SupplierScoreCriteriaFieldsFragmentDoc}`;

export function useUpdateSupplierScoreCriteriaMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateSupplierScoreCriteriaMutation,
    GQTypes.GQUpdateSupplierScoreCriteriaMutationVariables
  >(UpdateSupplierScoreCriteriaDocument);
}
export const SupplyChainUpdateSuppliersSettingsDocument = gql`
    mutation SupplyChainUpdateSuppliersSettings($suppliersSettingsInput: UpdateSuppliersSettingsInput!) @withOwner(owner: SupplyChain) {
  updateSuppliersSettings(input: $suppliersSettingsInput) {
    suppliersSettings {
      ...SuppliersSettingsFields
    }
  }
}
    ${SuppliersSettingsFieldsFragmentDoc}`;

export function useSupplyChainUpdateSuppliersSettingsMutation() {
  return Urql.useMutation<
    GQTypes.GQSupplyChainUpdateSuppliersSettingsMutation,
    GQTypes.GQSupplyChainUpdateSuppliersSettingsMutationVariables
  >(SupplyChainUpdateSuppliersSettingsDocument);
}
export const SupplyChainUpdatePortalSettingsDocument = gql`
    mutation SupplyChainUpdatePortalSettings($portalSettingsInput: UpsertCompanyPortalSettingsInput!) @withOwner(owner: SupplyChain) {
  upsertCompanyPortalSettings(input: $portalSettingsInput) {
    companyPortalSettings {
      ...CompanyPortalSettingsFields
    }
  }
}
    ${CompanyPortalSettingsFieldsFragmentDoc}`;

export function useSupplyChainUpdatePortalSettingsMutation() {
  return Urql.useMutation<
    GQTypes.GQSupplyChainUpdatePortalSettingsMutation,
    GQTypes.GQSupplyChainUpdatePortalSettingsMutationVariables
  >(SupplyChainUpdatePortalSettingsDocument);
}
export const EngagementTaskConfigsForEditingDocument = gql`
    query EngagementTaskConfigsForEditing($orgId: ID!, $includeUnpublishedGlobal: Boolean, $includeUnpublishedOrgSpecific: Boolean, $configGroup: EngagementTaskConfigGroup) @withOwner(owner: SupplyChain) {
  engagementTaskConfigs(
    orgId: $orgId
    includeUnpublishedGlobal: $includeUnpublishedGlobal
    includeUnpublishedOrgSpecific: $includeUnpublishedOrgSpecific
    configGroup: $configGroup
  ) {
    ...EngagementTaskConfigForEditing
  }
}
    ${EngagementTaskConfigForEditingFragmentDoc}`;

export function useEngagementTaskConfigsForEditingQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEngagementTaskConfigsForEditingQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEngagementTaskConfigsForEditingQuery,
    GQTypes.GQEngagementTaskConfigsForEditingQueryVariables
  >({ query: EngagementTaskConfigsForEditingDocument, ...options });
}
export const DeleteEngagementTaskConfigDocument = gql`
    mutation DeleteEngagementTaskConfig($input: IdInput!) @withOwner(owner: SupplyChain) {
  deleteEngagementTaskConfig(input: $input) {
    message
    success
  }
}
    `;

export function useDeleteEngagementTaskConfigMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteEngagementTaskConfigMutation,
    GQTypes.GQDeleteEngagementTaskConfigMutationVariables
  >(DeleteEngagementTaskConfigDocument);
}
export const SetPublishEngagementTaskConfigDocument = gql`
    mutation SetPublishEngagementTaskConfig($input: SetPublishEngagementTaskConfigInput!) @withOwner(owner: SupplyChain) {
  setPublishEngagementTaskConfig(input: $input) {
    engagementTaskConfig {
      ...EngagementTaskConfigForEditing
    }
  }
}
    ${EngagementTaskConfigForEditingFragmentDoc}`;

export function useSetPublishEngagementTaskConfigMutation() {
  return Urql.useMutation<
    GQTypes.GQSetPublishEngagementTaskConfigMutation,
    GQTypes.GQSetPublishEngagementTaskConfigMutationVariables
  >(SetPublishEngagementTaskConfigDocument);
}
export const SetArchiveEngagementTaskConfigDocument = gql`
    mutation SetArchiveEngagementTaskConfig($input: SetArchiveEngagementTaskConfigInput!) @withOwner(owner: SupplyChain) {
  setArchiveEngagementTaskConfig(input: $input) {
    engagementTaskConfig {
      ...EngagementTaskConfigForEditing
    }
  }
}
    ${EngagementTaskConfigForEditingFragmentDoc}`;

export function useSetArchiveEngagementTaskConfigMutation() {
  return Urql.useMutation<
    GQTypes.GQSetArchiveEngagementTaskConfigMutation,
    GQTypes.GQSetArchiveEngagementTaskConfigMutationVariables
  >(SetArchiveEngagementTaskConfigDocument);
}
export const SupplierSurveyResponseExportDocument = gql`
    mutation supplierSurveyResponseExport($input: SupplierSurveyResponseExportInput!) @withOwner(owner: SupplyChain) {
  supplierSurveyResponseExport(input: $input) {
    workflowId
  }
}
    `;

export function useSupplierSurveyResponseExportMutation() {
  return Urql.useMutation<
    GQTypes.GQSupplierSurveyResponseExportMutation,
    GQTypes.GQSupplierSurveyResponseExportMutationVariables
  >(SupplierSurveyResponseExportDocument);
}
export const CreateSupplierSavedViewDocument = gql`
    mutation CreateSupplierSavedView($input: CreateSupplierSavedViewInput!) @withOwner(owner: SupplyChain) {
  createSupplierSavedView(input: $input) {
    view {
      ...SupplierViewFields
    }
  }
}
    ${SupplierViewFieldsFragmentDoc}`;

export function useCreateSupplierSavedViewMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateSupplierSavedViewMutation,
    GQTypes.GQCreateSupplierSavedViewMutationVariables
  >(CreateSupplierSavedViewDocument);
}
export const UpdateSupplierSavedViewDocument = gql`
    mutation UpdateSupplierSavedView($input: UpdateSupplierSavedViewInput!) @withOwner(owner: SupplyChain) {
  updateSupplierSavedView(input: $input) {
    view {
      ...SupplierViewFields
    }
  }
}
    ${SupplierViewFieldsFragmentDoc}`;

export function useUpdateSupplierSavedViewMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateSupplierSavedViewMutation,
    GQTypes.GQUpdateSupplierSavedViewMutationVariables
  >(UpdateSupplierSavedViewDocument);
}
export const DeleteSupplierSavedViewDocument = gql`
    mutation DeleteSupplierSavedView($input: DeleteSupplierSavedViewInput!) @withOwner(owner: SupplyChain) {
  deleteSupplierSavedView(input: $input) {
    id
  }
}
    `;

export function useDeleteSupplierSavedViewMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteSupplierSavedViewMutation,
    GQTypes.GQDeleteSupplierSavedViewMutationVariables
  >(DeleteSupplierSavedViewDocument);
}
export const GetSupplierColumnsForCustomFieldDocument = gql`
    query GetSupplierColumnsForCustomField @withOwner(owner: SupplyChain) {
  supplierTableColumnsDashboard {
    ...SelfServeSupplierColumnFields
  }
}
    ${SelfServeSupplierColumnFieldsFragmentDoc}`;

export function useGetSupplierColumnsForCustomFieldQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSupplierColumnsForCustomFieldQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSupplierColumnsForCustomFieldQuery,
    GQTypes.GQGetSupplierColumnsForCustomFieldQueryVariables
  >({ query: GetSupplierColumnsForCustomFieldDocument, ...options });
}
export const GetEngagementTaskQuestionsDocument = gql`
    query GetEngagementTaskQuestions @withOwner(owner: SupplyChain) {
  engagementTaskQuestions {
    id
    questionKey
    engagementTaskConfigId
    componentId
  }
}
    `;

export function useGetEngagementTaskQuestionsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEngagementTaskQuestionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEngagementTaskQuestionsQuery,
    GQTypes.GQGetEngagementTaskQuestionsQueryVariables
  >({ query: GetEngagementTaskQuestionsDocument, ...options });
}
export const DeleteSupplierChartDocument = gql`
    mutation DeleteSupplierChart($input: DeleteSupplierChartInput!) @withOwner(owner: SupplyChain) {
  deleteSupplierChart(input: $input) {
    views {
      id
      charts: chartsV2 {
        ...SupplierChartConfigFields
      }
    }
  }
}
    ${SupplierChartConfigFieldsFragmentDoc}`;

export function useDeleteSupplierChartMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteSupplierChartMutation,
    GQTypes.GQDeleteSupplierChartMutationVariables
  >(DeleteSupplierChartDocument);
}
export const EngagementTaskBatchForBulkDeployStatusDocument = gql`
    query EngagementTaskBatchForBulkDeployStatus($engagementTaskBatchId: ID!) @withOwner(owner: SupplyChain) {
  engagementTaskBatch(engagementTaskBatchId: $engagementTaskBatchId) {
    ...EngagementTaskBatchFieldsForBulkDeployStatus
  }
}
    ${EngagementTaskBatchFieldsForBulkDeployStatusFragmentDoc}`;

export function useEngagementTaskBatchForBulkDeployStatusQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEngagementTaskBatchForBulkDeployStatusQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEngagementTaskBatchForBulkDeployStatusQuery,
    GQTypes.GQEngagementTaskBatchForBulkDeployStatusQueryVariables
  >({ query: EngagementTaskBatchForBulkDeployStatusDocument, ...options });
}
export const GetOrSendEmailsDocument = gql`
    mutation GetOrSendEmails($input: GetOrSendEmailsInput!) @withOwner(owner: SupplyChain) {
  getOrSendEmails(input: $input) {
    defaultSubject
    defaultBlurb
    sampleEmail {
      ...EmailPreview_emailContents
    }
  }
}
    ${EmailPreview_EmailContentsFragmentDoc}`;

export function useGetOrSendEmailsMutation() {
  return Urql.useMutation<
    GQTypes.GQGetOrSendEmailsMutation,
    GQTypes.GQGetOrSendEmailsMutationVariables
  >(GetOrSendEmailsDocument);
}
export const NotifyEngagementTaskCreationDocument = gql`
    mutation NotifyEngagementTaskCreation($input: NotifyEngagementTaskCreationInput!) @withOwner(owner: SupplyChain) {
  notifyEngagementTaskCreation(input: $input) {
    engagementTaskBatch {
      id
      deployStatuses {
        id
        latestEmailSent {
          id
          users {
            id
            latestEmailStatus
          }
        }
      }
    }
  }
}
    `;

export function useNotifyEngagementTaskCreationMutation() {
  return Urql.useMutation<
    GQTypes.GQNotifyEngagementTaskCreationMutation,
    GQTypes.GQNotifyEngagementTaskCreationMutationVariables
  >(NotifyEngagementTaskCreationDocument);
}
export const BulkDeployToSuppliersDocument = gql`
    mutation BulkDeployToSuppliers($input: BulkStartCompanySurveyEngagementInput!) @withOwner(owner: SupplyChain) {
  bulkStartCompanySurveyEngagement(input: $input) {
    engagementTaskBatchIds
  }
}
    `;

export function useBulkDeployToSuppliersMutation() {
  return Urql.useMutation<
    GQTypes.GQBulkDeployToSuppliersMutation,
    GQTypes.GQBulkDeployToSuppliersMutationVariables
  >(BulkDeployToSuppliersDocument);
}
export const SetEngagementCohortsForCompanyDocument = gql`
    mutation SetEngagementCohortsForCompany($input: SetEngagementCohortsForCompanyInput!) @withOwner(owner: SupplyChain) {
  setEngagementCohortsForCompany(input: $input) {
    cohortIds
    companyId
  }
}
    `;

export function useSetEngagementCohortsForCompanyMutation() {
  return Urql.useMutation<
    GQTypes.GQSetEngagementCohortsForCompanyMutation,
    GQTypes.GQSetEngagementCohortsForCompanyMutationVariables
  >(SetEngagementCohortsForCompanyDocument);
}
export const CreateEngagementCohortsForCompanyDocument = gql`
    mutation CreateEngagementCohortsForCompany($input: CreateEngagementCohortsForCompanyInput!) @withOwner(owner: SupplyChain) {
  createEngagementCohortsForCompany(input: $input) {
    ...EngagementCohortFields
  }
}
    ${EngagementCohortFieldsFragmentDoc}`;

export function useCreateEngagementCohortsForCompanyMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateEngagementCohortsForCompanyMutation,
    GQTypes.GQCreateEngagementCohortsForCompanyMutationVariables
  >(CreateEngagementCohortsForCompanyDocument);
}
export const UpdateEngagementTaskConfigDocument = gql`
    mutation UpdateEngagementTaskConfig($input: UpdateEngagementTaskConfigInput!) @withOwner(owner: SupplyChain) {
  updateEngagementTaskConfig(input: $input) {
    engagementTaskConfig {
      ...EngagementTaskConfigFields
    }
  }
}
    ${EngagementTaskConfigFieldsFragmentDoc}`;

export function useUpdateEngagementTaskConfigMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateEngagementTaskConfigMutation,
    GQTypes.GQUpdateEngagementTaskConfigMutationVariables
  >(UpdateEngagementTaskConfigDocument);
}
export const TaskConfigurationPageDocument = gql`
    query TaskConfigurationPage($engagementTaskConfigId: ID!, $companyId: ID!) @withOwner(owner: SupplyChain) {
  engagementTaskConfig(engagementTaskConfigId: $engagementTaskConfigId) {
    ...EngagementTaskConfigFields
  }
  companyForDashboard(id: $companyId) {
    ...CompanyFieldsForEngagementTasks
  }
}
    ${EngagementTaskConfigFieldsFragmentDoc}
${CompanyFieldsForEngagementTasksFragmentDoc}`;

export function useTaskConfigurationPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQTaskConfigurationPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQTaskConfigurationPageQuery,
    GQTypes.GQTaskConfigurationPageQueryVariables
  >({ query: TaskConfigurationPageDocument, ...options });
}
export const GetSupplierDocument = gql`
    query GetSupplier($companyId: ID, $vendorName: String, $footprintSnapshotId: String, $footprintInterval: YMInterval!, $viewFilters: FilterExpressionGroupWithNewFiltersInput, $enableExperimentalCache: Boolean) @withOwner(owner: SupplyChain) {
  supplier(
    footprintSnapshotId: $footprintSnapshotId
    footprintInterval: $footprintInterval
    companyId: $companyId
    vendorName: $vendorName
    viewFilters: $viewFilters
    enableExperimentalCache: $enableExperimentalCache
  ) @_required {
    ...SupplierFields
  }
  organization {
    id
    engagementFunnels {
      ...EngagementFunnelsChartFields
      ...EngagementFunnelsProgressTrackerFields
    }
  }
}
    ${SupplierFieldsFragmentDoc}
${EngagementFunnelsChartFieldsFragmentDoc}
${EngagementFunnelsProgressTrackerFieldsFragmentDoc}`;

export function useGetSupplierQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQGetSupplierQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQGetSupplierQuery,
    GQTypes.GQGetSupplierQueryVariables
  >({ query: GetSupplierDocument, ...options });
}
export const GetSupplierDetailedEmissionsDocument = gql`
    query GetSupplierDetailedEmissions($companyId: ID, $vendorName: String, $footprintSnapshotId: String, $footprintInterval: YMInterval!, $viewFilters: FilterExpressionGroupWithNewFiltersInput) @withOwner(owner: SupplyChain) {
  supplierDetailedEmissions(
    footprintSnapshotId: $footprintSnapshotId
    footprintInterval: $footprintInterval
    companyId: $companyId
    vendorName: $vendorName
    viewFilters: $viewFilters
  ) {
    id
    rows {
      ...SupplierRowFields
    }
    emissionsByCategory {
      ...SupplierCategorizedEmissionDataFields
    }
    emissionsByMonth(groupBy: businessCategory) {
      ...SupplierEmissionsByMonthFields
    }
    emissionsFactors {
      ...SupplierEmissionsFactors
    }
    emissionsModelEmissionsFactors {
      ...SupplierEmissionsModelEmissionsFactors
    }
  }
}
    ${SupplierRowFieldsFragmentDoc}
${SupplierCategorizedEmissionDataFieldsFragmentDoc}
${SupplierEmissionsByMonthFieldsFragmentDoc}
${SupplierEmissionsFactorsFragmentDoc}
${SupplierEmissionsModelEmissionsFactorsFragmentDoc}`;

export function useGetSupplierDetailedEmissionsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSupplierDetailedEmissionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSupplierDetailedEmissionsQuery,
    GQTypes.GQGetSupplierDetailedEmissionsQueryVariables
  >({ query: GetSupplierDetailedEmissionsDocument, ...options });
}
export const GetSupplierDetailsDataDocument = gql`
    query GetSupplierDetailsData($companyId: ID!, $orgId: ID!) @withOwner(owner: SupplyChain) {
  allDisclosures: companyDisclosures(
    companyId: $companyId
    opts: {publicDisclosureFilters: {}, privateDisclosureFilters: {orgId: $orgId}, excludeArchivedDisclosures: true}
  ) {
    ...SupplierDisclosureFields
  }
  historicalYears: companyDisclosures(
    companyId: $companyId
    opts: {publicDisclosureFilters: {}, privateDisclosureFilters: {orgId: $orgId}, historicalYearFilters: {allScopes: true}}
  ) {
    ...SupplierDisclosureFields
  }
  targets: companyDisclosures(
    companyId: $companyId
    opts: {publicDisclosureFilters: {excludeCdp: true}, privateDisclosureFilters: {orgId: $orgId}, targetFilters: {}}
  ) {
    ...SupplierDisclosureFields
  }
  initiatives: companyDisclosures(
    companyId: $companyId
    opts: {publicDisclosureFilters: {excludeCdp: true}, privateDisclosureFilters: {orgId: $orgId}, initiativeFilters: {}}
  ) {
    ...SupplierDisclosureFields
  }
  climateCommitments: companyDisclosures(
    companyId: $companyId
    opts: {publicDisclosureFilters: {excludeCdp: true}, privateDisclosureFilters: {orgId: $orgId}, climateCommitmentFilters: {}}
  ) {
    ...SupplierDisclosureFields
  }
}
    ${SupplierDisclosureFieldsFragmentDoc}`;

export function useGetSupplierDetailsDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSupplierDetailsDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSupplierDetailsDataQuery,
    GQTypes.GQGetSupplierDetailsDataQueryVariables
  >({ query: GetSupplierDetailsDataDocument, ...options });
}
export const GetSuppliersPageDataV2Document = gql`
    query GetSuppliersPageDataV2($footprintSnapshotId: String, $footprintInterval: YMInterval!, $includedGhgCategories: [String!], $viewFilters: FilterExpressionGroupWithNewFiltersInput, $tableFilters: [BiQueryFilterInput!], $enableExperimentalCache: Boolean) @withOwner(owner: SupplyChain) {
  suppliersV2(
    footprintSnapshotId: $footprintSnapshotId
    footprintInterval: $footprintInterval
    includedGhgCategories: $includedGhgCategories
    viewFilters: $viewFilters
    tableFilters: $tableFilters
    enableExperimentalCache: $enableExperimentalCache
  ) {
    ...SuppliersDataFields
  }
}
    ${SuppliersDataFieldsFragmentDoc}`;

export function useGetSuppliersPageDataV2Query(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSuppliersPageDataV2QueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSuppliersPageDataV2Query,
    GQTypes.GQGetSuppliersPageDataV2QueryVariables
  >({ query: GetSuppliersPageDataV2Document, ...options });
}
export const GetEngagementCohortsDocument = gql`
    query GetEngagementCohorts @withOwner(owner: SupplyChain) {
  engagementCohorts {
    ...EngagementCohortFields
  }
}
    ${EngagementCohortFieldsFragmentDoc}`;

export function useGetEngagementCohortsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEngagementCohortsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEngagementCohortsQuery,
    GQTypes.GQGetEngagementCohortsQueryVariables
  >({ query: GetEngagementCohortsDocument, ...options });
}
export const UpdateSupplierCustomDataDocument = gql`
    mutation UpdateSupplierCustomData($input: UpdateSupplierCustomDataInput!) @withOwner(owner: SupplyChain) {
  updateSupplierCustomData(input: $input) {
    supplierId
    supplierCustomData {
      ...SupplierCustomDataFields
    }
  }
}
    ${SupplierCustomDataFieldsFragmentDoc}`;

export function useUpdateSupplierCustomDataMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateSupplierCustomDataMutation,
    GQTypes.GQUpdateSupplierCustomDataMutationVariables
  >(UpdateSupplierCustomDataDocument);
}
export const UpdateSupplierColumnVisibilityBatchDocument = gql`
    mutation UpdateSupplierColumnVisibilityBatch($input: UpdateSupplierColumnVisibilityBatchInput!) @withOwner(owner: SupplyChain) {
  updateSupplierColumnVisibilityBatch(input: $input) {
    view {
      ...SupplierViewFields
    }
  }
}
    ${SupplierViewFieldsFragmentDoc}`;

export function useUpdateSupplierColumnVisibilityBatchMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateSupplierColumnVisibilityBatchMutation,
    GQTypes.GQUpdateSupplierColumnVisibilityBatchMutationVariables
  >(UpdateSupplierColumnVisibilityBatchDocument);
}
export const UpdateSupplierColumnWidthDocument = gql`
    mutation UpdateSupplierColumnWidth($input: UpdateSupplierColumnWidthInput!) @withOwner(owner: SupplyChain) {
  updateSupplierColumnWidth(input: $input) {
    view {
      ...SupplierViewFields
    }
  }
}
    ${SupplierViewFieldsFragmentDoc}`;

export function useUpdateSupplierColumnWidthMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateSupplierColumnWidthMutation,
    GQTypes.GQUpdateSupplierColumnWidthMutationVariables
  >(UpdateSupplierColumnWidthDocument);
}
export const UpdateSupplierColumnOrderDocument = gql`
    mutation UpdateSupplierColumnOrder($input: UpdateSupplierColumnOrderInput!) @withOwner(owner: SupplyChain) {
  updateSupplierColumnOrder(input: $input) {
    view {
      ...SupplierViewFields
    }
  }
}
    ${SupplierViewFieldsFragmentDoc}`;

export function useUpdateSupplierColumnOrderMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateSupplierColumnOrderMutation,
    GQTypes.GQUpdateSupplierColumnOrderMutationVariables
  >(UpdateSupplierColumnOrderDocument);
}
export const CreateSupplierTableColumnDocument = gql`
    mutation CreateSupplierTableColumn($input: CreateSupplierTableColumnInput!) @withOwner(owner: SupplyChain) {
  createSupplierTableColumn(input: $input) {
    views {
      ...SupplierViewFields
    }
  }
}
    ${SupplierViewFieldsFragmentDoc}`;

export function useCreateSupplierTableColumnMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateSupplierTableColumnMutation,
    GQTypes.GQCreateSupplierTableColumnMutationVariables
  >(CreateSupplierTableColumnDocument);
}
export const UpdateSupplierTableColumnDocument = gql`
    mutation UpdateSupplierTableColumn($input: UpdateSupplierTableColumnInput!) @withOwner(owner: SupplyChain) {
  updateSupplierTableColumn(input: $input) {
    views {
      ...SupplierViewFields
    }
  }
}
    ${SupplierViewFieldsFragmentDoc}`;

export function useUpdateSupplierTableColumnMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateSupplierTableColumnMutation,
    GQTypes.GQUpdateSupplierTableColumnMutationVariables
  >(UpdateSupplierTableColumnDocument);
}
export const DeleteSupplierTableColumnDocument = gql`
    mutation DeleteSupplierTableColumn($input: DeleteSupplierTableColumnInput!) @withOwner(owner: SupplyChain) {
  deleteSupplierTableColumn(input: $input) {
    views {
      ...SupplierViewFields
    }
  }
}
    ${SupplierViewFieldsFragmentDoc}`;

export function useDeleteSupplierTableColumnMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteSupplierTableColumnMutation,
    GQTypes.GQDeleteSupplierTableColumnMutationVariables
  >(DeleteSupplierTableColumnDocument);
}
export const GetSuppliersSettingsDocument = gql`
    query GetSuppliersSettings($rootCompanyId: ID!) @withOwner(owner: SupplyChain) {
  suppliersSettings {
    ...SuppliersSettingsFields
  }
  companyPortalSettings(rootCompanyId: $rootCompanyId) {
    ...CompanyPortalSettingsFields
  }
}
    ${SuppliersSettingsFieldsFragmentDoc}
${CompanyPortalSettingsFieldsFragmentDoc}`;

export function useGetSuppliersSettingsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSuppliersSettingsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSuppliersSettingsQuery,
    GQTypes.GQGetSuppliersSettingsQueryVariables
  >({ query: GetSuppliersSettingsDocument, ...options });
}
export const GetVariousSuppliersDocument = gql`
    query GetVariousSuppliers($footprintSnapshotId: String, $footprintInterval: YMInterval!, $includedGhgCategories: [String!]) @withOwner(owner: SupplyChain) {
  variousSuppliers(
    footprintSnapshotId: $footprintSnapshotId
    footprintInterval: $footprintInterval
    includedGhgCategories: $includedGhgCategories
  ) {
    variousSuppliers {
      vendor
      companyId
      totalKgco2e
      totalSpendUsd
    }
  }
}
    `;

export function useGetVariousSuppliersQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetVariousSuppliersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetVariousSuppliersQuery,
    GQTypes.GQGetVariousSuppliersQueryVariables
  >({ query: GetVariousSuppliersDocument, ...options });
}
export const GetBulkDeployStatusDocument = gql`
    query GetBulkDeployStatus($id: ID!) @withOwner(owner: SupplyChain) {
  engagementTaskBatchDeployStatus(engagementTaskBatchId: $id) {
    id
    successfulDeployCompanies {
      ...BulkDeployStatusCompanyFields
    }
    failedDeployCompanies {
      ...BulkDeployStatusCompanyFields
    }
  }
}
    ${BulkDeployStatusCompanyFieldsFragmentDoc}`;

export function useGetBulkDeployStatusQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetBulkDeployStatusQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetBulkDeployStatusQuery,
    GQTypes.GQGetBulkDeployStatusQueryVariables
  >({ query: GetBulkDeployStatusDocument, ...options });
}
export const GetExpenseCategoriesForSuppliersDocument = gql`
    query GetExpenseCategoriesForSuppliers($footprintSnapshotId: String, $footprintInterval: YMInterval!, $companyIds: [ID!]!, $useDuckdbPostgresConnection: Boolean) @withOwner(owner: SupplyChain) {
  expenseCategoriesForSuppliers(
    footprintSnapshotId: $footprintSnapshotId
    footprintInterval: $footprintInterval
    companyIds: $companyIds
    useDuckdbPostgresConnection: $useDuckdbPostgresConnection
  ) {
    ...SupplierExpenseCategoryFields
  }
}
    ${SupplierExpenseCategoryFieldsFragmentDoc}`;

export function useGetExpenseCategoriesForSuppliersQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetExpenseCategoriesForSuppliersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetExpenseCategoriesForSuppliersQuery,
    GQTypes.GQGetExpenseCategoriesForSuppliersQueryVariables
  >({ query: GetExpenseCategoriesForSuppliersDocument, ...options });
}
export const GetContactsForCompanyDocument = gql`
    query GetContactsForCompany($companyId: ID!) @withOwner(owner: SupplyChain) {
  contactsForCompany(companyId: $companyId) {
    ...SupplierContactFields
  }
}
    ${SupplierContactFieldsFragmentDoc}`;

export function useGetContactsForCompanyQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetContactsForCompanyQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetContactsForCompanyQuery,
    GQTypes.GQGetContactsForCompanyQueryVariables
  >({ query: GetContactsForCompanyDocument, ...options });
}
export const GetContactsForOrgDocument = gql`
    query GetContactsForOrg @withOwner(owner: SupplyChain) {
  contacts {
    ...SupplierContactFields
  }
}
    ${SupplierContactFieldsFragmentDoc}`;

export function useGetContactsForOrgQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetContactsForOrgQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetContactsForOrgQuery,
    GQTypes.GQGetContactsForOrgQueryVariables
  >({ query: GetContactsForOrgDocument, ...options });
}
export const SupplierScorecardsDocument = gql`
    query SupplierScorecards @withOwner(owner: SupplyChain) {
  supplierScorecards {
    ...SupplierScorecardFields
  }
}
    ${SupplierScorecardFieldsFragmentDoc}`;

export function useSupplierScorecardsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQSupplierScorecardsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSupplierScorecardsQuery,
    GQTypes.GQSupplierScorecardsQueryVariables
  >({ query: SupplierScorecardsDocument, ...options });
}
export const GetSupplierViewsDocument = gql`
    query GetSupplierViews @withOwner(owner: SupplyChain) {
  supplierViews {
    ...SupplierViewFields
  }
}
    ${SupplierViewFieldsFragmentDoc}`;

export function useGetSupplierViewsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSupplierViewsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSupplierViewsQuery,
    GQTypes.GQGetSupplierViewsQueryVariables
  >({ query: GetSupplierViewsDocument, ...options });
}
export const SuppliersReductionsForecastDocument = gql`
    query SuppliersReductionsForecast($footprintSnapshotId: String, $suppliersQueryInterval: YMInterval!, $orgId: ID!, $viewFilters: FilterExpressionGroupWithNewFiltersInput) @withOwner(owner: SupplyChain) {
  suppliersWithTargetDisclosures(
    footprintSnapshotId: $footprintSnapshotId
    footprintInterval: $suppliersQueryInterval
    viewFilters: $viewFilters
  ) {
    suppliers {
      ...SupplierForReductionsForecast
    }
  }
}
    ${SupplierForReductionsForecastFragmentDoc}`;

export function useSuppliersReductionsForecastQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQSuppliersReductionsForecastQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSuppliersReductionsForecastQuery,
    GQTypes.GQSuppliersReductionsForecastQueryVariables
  >({ query: SuppliersReductionsForecastDocument, ...options });
}
export const GetBiSavedViewsForLandingPageDocument = gql`
    query GetBiSavedViewsForLandingPage($input: BiSavedViewsInput!, $status: FootprintStatus) @withOwner(owner: Calcprint) {
  userHasPermission(permission: ViewFootprint, allowAnyObject: true)
  biSavedViews(input: $input) {
    ...BiSavedViewForLandingPage
  }
  defaultFootprintWithFallback(status: $status) {
    ...FootprintFields
  }
}
    ${BiSavedViewForLandingPageFragmentDoc}
${FootprintFieldsFragmentDoc}`;

export function useGetBiSavedViewsForLandingPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetBiSavedViewsForLandingPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetBiSavedViewsForLandingPageQuery,
    GQTypes.GQGetBiSavedViewsForLandingPageQueryVariables
  >({ query: GetBiSavedViewsForLandingPageDocument, ...options });
}
export const GetDeprecatedBiSavedViewsForLandingPageDocument = gql`
    query GetDeprecatedBiSavedViewsForLandingPage($input: BiSavedViewsInput!) @withOwner(owner: Calcprint) {
  biSavedViews(input: $input) {
    ...BiSavedViewForLandingPage
  }
}
    ${BiSavedViewForLandingPageFragmentDoc}`;

export function useGetDeprecatedBiSavedViewsForLandingPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetDeprecatedBiSavedViewsForLandingPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetDeprecatedBiSavedViewsForLandingPageQuery,
    GQTypes.GQGetDeprecatedBiSavedViewsForLandingPageQueryVariables
  >({ query: GetDeprecatedBiSavedViewsForLandingPageDocument, ...options });
}
export const SelectAssetBenchmarksDocument = gql`
    mutation SelectAssetBenchmarks($input: SetAssetBenchmarksInput!) @withOwner(owner: WinFinance) {
  setAssetBenchmarks(input: $input) {
    status
  }
}
    `;

export function useSelectAssetBenchmarksMutation() {
  return Urql.useMutation<
    GQTypes.GQSelectAssetBenchmarksMutation,
    GQTypes.GQSelectAssetBenchmarksMutationVariables
  >(SelectAssetBenchmarksDocument);
}
export const UpdateFinanceWorksheetDocument = gql`
    mutation UpdateFinanceWorksheet($input: UpdateFinanceWorksheetInput!) @withOwner(owner: WinFinance) {
  updateFinanceWorksheet(input: $input) {
    worksheet {
      id
      ...FinanceWorksheetAssetGrid
    }
  }
}
    ${FinanceWorksheetAssetGridFragmentDoc}`;

export function useUpdateFinanceWorksheetMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateFinanceWorksheetMutation,
    GQTypes.GQUpdateFinanceWorksheetMutationVariables
  >(UpdateFinanceWorksheetDocument);
}
export const DeleteAssetsDocument = gql`
    mutation DeleteAssets($input: DeleteAssetsInput!) @withOwner(owner: WinFinance) {
  deleteAssets(input: $input) {
    assetYearIds
    assetHoldingIds
    privateDisclosureIds
    emissionsYearIds
    fundHoldingBenchmarkIds
    footprintEstimateOutputIds
    assetCorporateIds
    assetRealEstateIds
    assetGroupIds
    assetSovereignBondIds
    assetPersonalMotorVehicleInsuranceIds
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${FinanceSnapshotIsStaleFragmentDoc}`;

export function useDeleteAssetsMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteAssetsMutation,
    GQTypes.GQDeleteAssetsMutationVariables
  >(DeleteAssetsDocument);
}
export const FinanceSnapshotHoldingsDocument = gql`
    query FinanceSnapshotHoldings($financeSnapshotId: ID!, $year: Int!, $filters: UntypedData!, $search: String, $sortField: String, $sortDirection: String, $after: String, $before: String, $first: Int, $last: Int) @withOwner(owner: WinFinance) {
  financeSnapshotHoldings(
    financeSnapshotId: $financeSnapshotId
    year: $year
    filters: $filters
    search: $search
    sortField: $sortField
    sortDirection: $sortDirection
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    id
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
    edges {
      node
    }
  }
}
    `;

export function useFinanceSnapshotHoldingsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceSnapshotHoldingsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceSnapshotHoldingsQuery,
    GQTypes.GQFinanceSnapshotHoldingsQueryVariables
  >({ query: FinanceSnapshotHoldingsDocument, ...options });
}
export const FinanceSnapshotAssetsDocument = gql`
    query FinanceSnapshotAssets($financeSnapshotId: ID!, $year: Int!, $filters: UntypedData!, $search: String, $sortField: String, $sortDirection: String, $after: String, $before: String, $first: Int, $last: Int) @withOwner(owner: WinFinance) {
  financeSnapshotAssets(
    financeSnapshotId: $financeSnapshotId
    year: $year
    filters: $filters
    search: $search
    sortField: $sortField
    sortDirection: $sortDirection
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    id
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
    edges {
      node
    }
  }
}
    `;

export function useFinanceSnapshotAssetsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceSnapshotAssetsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceSnapshotAssetsQuery,
    GQTypes.GQFinanceSnapshotAssetsQueryVariables
  >({ query: FinanceSnapshotAssetsDocument, ...options });
}
export const FinanceSnapshotAssetsGroupedByCompanyDocument = gql`
    query FinanceSnapshotAssetsGroupedByCompany($financeSnapshotId: ID!, $year: Int!, $filters: UntypedData!, $search: String, $sortField: String, $sortDirection: String, $after: String, $before: String, $first: Int, $last: Int) @withOwner(owner: WinFinance) {
  financeSnapshotAssetsGroupedByCompany(
    financeSnapshotId: $financeSnapshotId
    year: $year
    filters: $filters
    search: $search
    sortField: $sortField
    sortDirection: $sortDirection
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    id
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
    edges {
      node
    }
  }
}
    `;

export function useFinanceSnapshotAssetsGroupedByCompanyQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceSnapshotAssetsGroupedByCompanyQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceSnapshotAssetsGroupedByCompanyQuery,
    GQTypes.GQFinanceSnapshotAssetsGroupedByCompanyQueryVariables
  >({ query: FinanceSnapshotAssetsGroupedByCompanyDocument, ...options });
}
export const FinanceSnapshotFundsDocument = gql`
    query FinanceSnapshotFunds($financeSnapshotId: ID!, $year: Int!, $filters: UntypedData!, $search: String, $sortField: String, $sortDirection: String, $after: String, $before: String, $first: Int, $last: Int) @withOwner(owner: WinFinance) {
  financeSnapshotFunds(
    financeSnapshotId: $financeSnapshotId
    year: $year
    filters: $filters
    search: $search
    sortField: $sortField
    sortDirection: $sortDirection
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    id
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
    edges {
      node
    }
  }
}
    `;

export function useFinanceSnapshotFundsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceSnapshotFundsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceSnapshotFundsQuery,
    GQTypes.GQFinanceSnapshotFundsQueryVariables
  >({ query: FinanceSnapshotFundsDocument, ...options });
}
export const FinanceSnapshotGridTagsDocument = gql`
    query FinanceSnapshotGridTags($financeSnapshotId: ID!) @withOwner(owner: WinFinance) {
  financeSnapshotTags(financeSnapshotId: $financeSnapshotId) {
    id
    financeTag {
      ...FinanceTagForGrid
    }
  }
}
    ${FinanceTagForGridFragmentDoc}`;

export function useFinanceSnapshotGridTagsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceSnapshotGridTagsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceSnapshotGridTagsQuery,
    GQTypes.GQFinanceSnapshotGridTagsQueryVariables
  >({ query: FinanceSnapshotGridTagsDocument, ...options });
}
export const FinanceBenchmarkOptionsDocument = gql`
    query FinanceBenchmarkOptions($search: String) @withOwner(owner: WinFinance) {
  publicDisclosureSearch(
    search: $search
    allScopes: true
    onlyMarketBased: true
    revenueMustExist: true
  ) {
    edges {
      node {
        id
        reportingYears
        company {
          id
          name
        }
      }
    }
  }
}
    `;

export function useFinanceBenchmarkOptionsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceBenchmarkOptionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceBenchmarkOptionsQuery,
    GQTypes.GQFinanceBenchmarkOptionsQueryVariables
  >({ query: FinanceBenchmarkOptionsDocument, ...options });
}
export const CreateAssetCorporateDocument = gql`
    mutation CreateAssetCorporate($input: CreateAssetCorporateInput!) @withOwner(owner: WinFinance) {
  createAssetCorporate(input: $input) {
    asset {
      id
      ...AssetCorporateAllFields
    }
  }
}
    ${AssetCorporateAllFieldsFragmentDoc}`;

export function useCreateAssetCorporateMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateAssetCorporateMutation,
    GQTypes.GQCreateAssetCorporateMutationVariables
  >(CreateAssetCorporateDocument);
}
export const CreateAssetGroupDocument = gql`
    mutation CreateAssetGroup($input: CreateAssetGroupInput!) @withOwner(owner: WinFinance) {
  createAssetGroup(input: $input) {
    asset {
      id
      ...AssetGroupAllFields
    }
  }
}
    ${AssetGroupAllFieldsFragmentDoc}`;

export function useCreateAssetGroupMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateAssetGroupMutation,
    GQTypes.GQCreateAssetGroupMutationVariables
  >(CreateAssetGroupDocument);
}
export const CreateAssetPersonalMotorVehicleInsuranceDocument = gql`
    mutation CreateAssetPersonalMotorVehicleInsurance($input: CreateAssetPersonalMotorVehicleInsuranceInput!) @withOwner(owner: WinFinance) {
  createAssetPersonalMotorVehicleInsurance(input: $input) {
    asset {
      id
      ...AssetPersonalMotorVehicleInsuranceAllFields
    }
  }
}
    ${AssetPersonalMotorVehicleInsuranceAllFieldsFragmentDoc}`;

export function useCreateAssetPersonalMotorVehicleInsuranceMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateAssetPersonalMotorVehicleInsuranceMutation,
    GQTypes.GQCreateAssetPersonalMotorVehicleInsuranceMutationVariables
  >(CreateAssetPersonalMotorVehicleInsuranceDocument);
}
export const CreateAssetRealEstateDocument = gql`
    mutation CreateAssetRealEstate($input: CreateAssetRealEstateInput!) @withOwner(owner: WinFinance) {
  createAssetRealEstate(input: $input) {
    asset {
      id
      ...AssetRealEstateAllFields
    }
  }
}
    ${AssetRealEstateAllFieldsFragmentDoc}`;

export function useCreateAssetRealEstateMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateAssetRealEstateMutation,
    GQTypes.GQCreateAssetRealEstateMutationVariables
  >(CreateAssetRealEstateDocument);
}
export const CreateAssetSovereignBondDocument = gql`
    mutation CreateAssetSovereignBond($input: CreateAssetSovereignBondInput!) @withOwner(owner: WinFinance) {
  createAssetSovereignBond(input: $input) {
    asset {
      id
      ...AssetSovereignBondAllFields
    }
  }
}
    ${AssetSovereignBondAllFieldsFragmentDoc}`;

export function useCreateAssetSovereignBondMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateAssetSovereignBondMutation,
    GQTypes.GQCreateAssetSovereignBondMutationVariables
  >(CreateAssetSovereignBondDocument);
}
export const CreateFinanceSavedViewDocument = gql`
    mutation CreateFinanceSavedView($input: CreateFinanceSavedViewInput!) @withOwner(owner: WinFinance) {
  createFinanceSavedView(input: $input) {
    financeSavedView {
      id
      ...FinanceSavedViewAllFields
    }
  }
}
    ${FinanceSavedViewAllFieldsFragmentDoc}`;

export function useCreateFinanceSavedViewMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFinanceSavedViewMutation,
    GQTypes.GQCreateFinanceSavedViewMutationVariables
  >(CreateFinanceSavedViewDocument);
}
export const CreateFinanceTagDocument = gql`
    mutation CreateFinanceTag($input: CreateFinanceTagInput!) @withOwner(owner: WinFinance) {
  createFinanceTag(input: $input) {
    financeTag {
      ...FinanceTagAllFields
    }
  }
}
    ${FinanceTagAllFieldsFragmentDoc}`;

export function useCreateFinanceTagMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFinanceTagMutation,
    GQTypes.GQCreateFinanceTagMutationVariables
  >(CreateFinanceTagDocument);
}
export const CreateFundDocument = gql`
    mutation CreateFund($input: CreateFundInput!) @withOwner(owner: WinFinance) {
  createFund(input: $input) {
    fund {
      id
      ...FundAllFields
    }
  }
}
    ${FundAllFieldsFragmentDoc}`;

export function useCreateFundMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFundMutation,
    GQTypes.GQCreateFundMutationVariables
  >(CreateFundDocument);
}
export const CreateFinanceReportingTagsDocument = gql`
    mutation CreateFinanceReportingTags($input: CreateFinanceReportingTagsInput!) @withOwner(owner: WinFinance) {
  createFinanceReportingTags(input: $input) {
    financeTags {
      ...FinanceTagAllFields
    }
  }
}
    ${FinanceTagAllFieldsFragmentDoc}`;

export function useCreateFinanceReportingTagsMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFinanceReportingTagsMutation,
    GQTypes.GQCreateFinanceReportingTagsMutationVariables
  >(CreateFinanceReportingTagsDocument);
}
export const DeleteFinanceTagDocument = gql`
    mutation DeleteFinanceTag($input: DeleteFinanceTagInput!) @withOwner(owner: WinFinance) {
  deleteFinanceTag(input: $input) {
    id
  }
}
    `;

export function useDeleteFinanceTagMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteFinanceTagMutation,
    GQTypes.GQDeleteFinanceTagMutationVariables
  >(DeleteFinanceTagDocument);
}
export const FinanceTagsDocument = gql`
    query FinanceTags @withOwner(owner: WinFinance) {
  financeTags {
    ...FinanceTagAllFields
  }
}
    ${FinanceTagAllFieldsFragmentDoc}`;

export function useFinanceTagsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceTagsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceTagsQuery,
    GQTypes.GQFinanceTagsQueryVariables
  >({ query: FinanceTagsDocument, ...options });
}
export const DeleteFinanceTagsDocument = gql`
    mutation DeleteFinanceTags($input: DeleteFinanceTagsInput!) @withOwner(owner: WinFinance) {
  deleteFinanceTags(input: $input) {
    ids
  }
}
    `;

export function useDeleteFinanceTagsMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteFinanceTagsMutation,
    GQTypes.GQDeleteFinanceTagsMutationVariables
  >(DeleteFinanceTagsDocument);
}
export const UpdateFinanceTagDocument = gql`
    mutation UpdateFinanceTag($input: UpdateFinanceTagInput!) @withOwner(owner: WinFinance) {
  updateFinanceTag(input: $input) {
    financeTag {
      ...FinanceTagAllFields
    }
  }
}
    ${FinanceTagAllFieldsFragmentDoc}`;

export function useUpdateFinanceTagMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateFinanceTagMutation,
    GQTypes.GQUpdateFinanceTagMutationVariables
  >(UpdateFinanceTagDocument);
}
export const CreateAssetCommentDocument = gql`
    mutation CreateAssetComment($input: CreateAssetCommentInput!) @withOwner(owner: WinFinance) {
  createAssetComment(input: $input) {
    assetComment {
      ...AssetCommentAllFields
      asset {
        id
        assetComments {
          id
        }
      }
    }
    attachmentMetadata {
      signedUrl
      fileMetadataId
    }
  }
}
    ${AssetCommentAllFieldsFragmentDoc}`;

export function useCreateAssetCommentMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateAssetCommentMutation,
    GQTypes.GQCreateAssetCommentMutationVariables
  >(CreateAssetCommentDocument);
}
export const AssetCommentsDocument = gql`
    query AssetComments($assetId: ID!) @withOwner(owner: WinFinance) {
  asset(id: $assetId) {
    id
    assetComments {
      ...AssetCommentAllFields
    }
  }
}
    ${AssetCommentAllFieldsFragmentDoc}`;

export function useAssetCommentsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAssetCommentsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAssetCommentsQuery,
    GQTypes.GQAssetCommentsQueryVariables
  >({ query: AssetCommentsDocument, ...options });
}
export const UpdateAssetCommentDocument = gql`
    mutation UpdateAssetComment($input: UpdateAssetCommentInput!) @withOwner(owner: WinFinance) {
  updateAssetComment(input: $input) {
    assetComment {
      ...AssetCommentAllFields
    }
  }
}
    ${AssetCommentAllFieldsFragmentDoc}`;

export function useUpdateAssetCommentMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateAssetCommentMutation,
    GQTypes.GQUpdateAssetCommentMutationVariables
  >(UpdateAssetCommentDocument);
}
export const DeleteAssetCommentDocument = gql`
    mutation DeleteAssetComment($input: DeleteAssetCommentInput!) @withOwner(owner: WinFinance) {
  deleteAssetComment(input: $input) {
    id
    asset {
      id
      assetComments {
        id
      }
    }
  }
}
    `;

export function useDeleteAssetCommentMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteAssetCommentMutation,
    GQTypes.GQDeleteAssetCommentMutationVariables
  >(DeleteAssetCommentDocument);
}
export const FinanceSnapshotFilterOptionsDocument = gql`
    query FinanceSnapshotFilterOptions($financeSnapshotId: ID!, $assetYearId: ID) @withOwner(owner: WinFinance) {
  financeSnapshotFilterOptions(
    financeSnapshotId: $financeSnapshotId
    assetYearId: $assetYearId
  )
}
    `;

export function useFinanceSnapshotFilterOptionsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceSnapshotFilterOptionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceSnapshotFilterOptionsQuery,
    GQTypes.GQFinanceSnapshotFilterOptionsQueryVariables
  >({ query: FinanceSnapshotFilterOptionsDocument, ...options });
}
export const OrgFundsV3PageDocument = gql`
    query OrgFundsV3Page($financeSnapshotId: ID, $year: Int, $isTotalEmissions: Boolean, $filters: UntypedData, $standardView: FinanceStandardView, $viewId: ID) @withOwner(owner: WinFinance) {
  financeView(
    financeSnapshotId: $financeSnapshotId
    year: $year
    isTotalEmissions: $isTotalEmissions
    filters: $filters
    standardView: $standardView
    viewId: $viewId
  ) {
    standardView
    savedView {
      id
      ...FinanceSavedViewAllFields
    }
    header {
      ...FinanceHeaderCurrent
    }
    gridWorksheet {
      ...FinanceWorksheetAssetGrid
    }
  }
}
    ${FinanceSavedViewAllFieldsFragmentDoc}
${FinanceHeaderCurrentFragmentDoc}
${FinanceWorksheetAssetGridFragmentDoc}`;

export function useOrgFundsV3PageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgFundsV3PageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgFundsV3PageQuery,
    GQTypes.GQOrgFundsV3PageQueryVariables
  >({ query: OrgFundsV3PageDocument, ...options });
}
export const DeleteSnapshotDocument = gql`
    mutation DeleteSnapshot($input: DeleteSnapshotInput!) @withOwner(owner: WinFinance) {
  deleteSnapshot(input: $input) {
    id
  }
}
    `;

export function useDeleteSnapshotMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteSnapshotMutation,
    GQTypes.GQDeleteSnapshotMutationVariables
  >(DeleteSnapshotDocument);
}
export const UpdateSnapshotNameDocument = gql`
    mutation UpdateSnapshotName($input: UpdateSnapshotNameInput!) @withOwner(owner: WinFinance) {
  updateSnapshotName(input: $input) {
    snapshot {
      id
      ...FinanceSnapshotForContext
    }
  }
}
    ${FinanceSnapshotForContextFragmentDoc}`;

export function useUpdateSnapshotNameMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateSnapshotNameMutation,
    GQTypes.GQUpdateSnapshotNameMutationVariables
  >(UpdateSnapshotNameDocument);
}
export const UpdateFinanceSavedViewDocument = gql`
    mutation UpdateFinanceSavedView($input: UpdateFinanceSavedViewInput!) @withOwner(owner: WinFinance) {
  updateFinanceSavedView(input: $input) {
    financeSavedView {
      id
      ...FinanceSavedViewAllFields
    }
  }
}
    ${FinanceSavedViewAllFieldsFragmentDoc}`;

export function useUpdateFinanceSavedViewMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateFinanceSavedViewMutation,
    GQTypes.GQUpdateFinanceSavedViewMutationVariables
  >(UpdateFinanceSavedViewDocument);
}
export const OnboardingHomePageDocument = gql`
    query OnboardingHomePage @withOwner(owner: EnterpriseFoundations) {
  climateProgramTimelineProjects {
    ...ClimateProgramTimelineProjectFields
  }
  activeMeasurementProjects {
    ...ActiveMeasurementProjectForOnboardingHomePage
  }
}
    ${ClimateProgramTimelineProjectFieldsFragmentDoc}
${ActiveMeasurementProjectForOnboardingHomePageFragmentDoc}`;

export function useOnboardingHomePageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOnboardingHomePageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOnboardingHomePageQuery,
    GQTypes.GQOnboardingHomePageQueryVariables
  >({ query: OnboardingHomePageDocument, ...options });
}
export const OnboardingNavCardDocument = gql`
    query OnboardingNavCard @withOwner(owner: EnterpriseFoundations) {
  organization {
    ...OrganizationForOnboardingHomePage
  }
}
    ${OrganizationForOnboardingHomePageFragmentDoc}`;

export function useOnboardingNavCardQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOnboardingNavCardQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOnboardingNavCardQuery,
    GQTypes.GQOnboardingNavCardQueryVariables
  >({ query: OnboardingNavCardDocument, ...options });
}
export const GetValueMappingOutputOptionsDocument = gql`
    query GetValueMappingOutputOptions($columnName: String!, $searchTerm: String, $selectedValue: String, $suggestedValues: [String!], $configId: String!) @withOwner(owner: DatasetsAndObjects) {
  getValueMappingOutputOptions(
    columnName: $columnName
    searchTerm: $searchTerm
    selectedValue: $selectedValue
    suggestedValues: $suggestedValues
    configId: $configId
  ) {
    ...ValueMappingOutputOptionFields
  }
}
    ${ValueMappingOutputOptionFieldsFragmentDoc}`;

export function useGetValueMappingOutputOptionsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetValueMappingOutputOptionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetValueMappingOutputOptionsQuery,
    GQTypes.GQGetValueMappingOutputOptionsQueryVariables
  >({ query: GetValueMappingOutputOptionsDocument, ...options });
}
export const ApproveAllValueMappingRecordsDocument = gql`
    mutation ApproveAllValueMappingRecords($input: ApproveAllValueMappingRecordsInput!) @withOwner(owner: DatasetsAndObjects) {
  approveAllValueMappingRecords(input: $input) {
    success
    workflowId
  }
}
    `;

export function useApproveAllValueMappingRecordsMutation() {
  return Urql.useMutation<
    GQTypes.GQApproveAllValueMappingRecordsMutation,
    GQTypes.GQApproveAllValueMappingRecordsMutationVariables
  >(ApproveAllValueMappingRecordsDocument);
}
export const ValueMappingRowsDocument = gql`
    query ValueMappingRows($valueMappingConfigId: ID!, $after: String, $before: String, $first: Int, $last: Int, $measurementProjectId: ID, $filter: ValueMappingFilterType, $searchText: String, $sortModel: [DataGridSortItemInput!]) @withOwner(owner: DatasetsAndObjects) {
  valueMappingRows(
    valueMappingConfigId: $valueMappingConfigId
    after: $after
    before: $before
    first: $first
    last: $last
    measurementProjectId: $measurementProjectId
    filter: $filter
    searchText: $searchText
    sortModel: $sortModel
  ) {
    edges {
      node {
        ...ValueMappingPageValueMappingRow
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
    metadata {
      id
      allRowsAreFilled
      totalRowCount
      unmappedRowCount
      flaggedRowCount
      suggestionsRowCount
    }
  }
}
    ${ValueMappingPageValueMappingRowFragmentDoc}`;

export function useValueMappingRowsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQValueMappingRowsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQValueMappingRowsQuery,
    GQTypes.GQValueMappingRowsQueryVariables
  >({ query: ValueMappingRowsDocument, ...options });
}
export const GetManageValueMappingPermissionDocument = gql`
    query GetManageValueMappingPermission($valueMappingRuleId: String, $orgId: ID!) @withOwner(owner: DatasetsAndObjects) {
  userHasManageValueMappingPermission(
    valueMappingRuleId: $valueMappingRuleId
    orgId: $orgId
  )
}
    `;

export function useGetManageValueMappingPermissionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetManageValueMappingPermissionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetManageValueMappingPermissionQuery,
    GQTypes.GQGetManageValueMappingPermissionQueryVariables
  >({ query: GetManageValueMappingPermissionDocument, ...options });
}
export const GetValueMappingCommentsDocument = gql`
    query GetValueMappingComments($orgId: ID!, $valueMappingConfigId: ID!, $subjectId: ID!) @withOwner(owner: DatasetsAndObjects) {
  valueMappingCommentsForSubject(
    orgId: $orgId
    valueMappingConfigId: $valueMappingConfigId
    subjectId: $subjectId
  ) {
    id
    message
    creator {
      id
      isWatershedEmployee
      isWatershedContractor
      name
    }
    lastEditor {
      id
      isWatershedEmployee
      isWatershedContractor
      name
    }
    createdAt
    lastEditedAt
  }
}
    `;

export function useGetValueMappingCommentsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetValueMappingCommentsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetValueMappingCommentsQuery,
    GQTypes.GQGetValueMappingCommentsQueryVariables
  >({ query: GetValueMappingCommentsDocument, ...options });
}
export const CreateValueMappingCommentDocument = gql`
    mutation CreateValueMappingComment($input: CreateValueMappingCommentInput!) @withOwner(owner: DatasetsAndObjects) {
  createValueMappingComment(input: $input) {
    id
    message
  }
}
    `;

export function useCreateValueMappingCommentMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateValueMappingCommentMutation,
    GQTypes.GQCreateValueMappingCommentMutationVariables
  >(CreateValueMappingCommentDocument);
}
export const GetValueMappingHistoryForGroupDocument = gql`
    query GetValueMappingHistoryForGroup($orgId: ID!, $valueMappingConfigId: ID!, $groupId: ID!) @withOwner(owner: DatasetsAndObjects) {
  valueMappingHistoryForGroup(
    orgId: $orgId
    valueMappingConfigId: $valueMappingConfigId
    groupId: $groupId
  ) {
    id
    entries {
      id
      rowId
      revision {
        id
        isValueMappingLegacyMigration
      }
      columnName
      operation
      oldValue
      newValue
      changedAt
      changedBy {
        id
        name
        isWatershedEmployee
        isWatershedContractor
      }
    }
  }
}
    `;

export function useGetValueMappingHistoryForGroupQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetValueMappingHistoryForGroupQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetValueMappingHistoryForGroupQuery,
    GQTypes.GQGetValueMappingHistoryForGroupQueryVariables
  >({ query: GetValueMappingHistoryForGroupDocument, ...options });
}
export const UserUploadsByIdDocument = gql`
    query UserUploadsById($ids: [ID!]!) @withOwner(owner: DatasetsAndObjects) {
  userUploadsById(ids: $ids) {
    id
    userVisibleAttempt {
      id
      ...UserUploadAttemptFieldsWithDataPreview
    }
  }
}
    ${UserUploadAttemptFieldsWithDataPreviewFragmentDoc}`;

export function useUserUploadsByIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQUserUploadsByIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQUserUploadsByIdQuery,
    GQTypes.GQUserUploadsByIdQueryVariables
  >({ query: UserUploadsByIdDocument, ...options });
}
export const ValueMappingFileRowDataDocument = gql`
    query ValueMappingFileRowData($userUploadIds: [ID!]!, $filter: [ValueMappingFileDataFilter!]!, $sortColumn: String!) @withOwner(owner: DatasetsAndObjects) {
  valueMappingFileRowData(
    userUploadIds: $userUploadIds
    filter: $filter
    sortColumn: $sortColumn
  ) {
    id
    fileData {
      id
      userVisibleAttempt {
        id
        createdAt
        name
        uploader {
          id
          name
        }
      }
    }
    matchedRows
  }
}
    `;

export function useValueMappingFileRowDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQValueMappingFileRowDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQValueMappingFileRowDataQuery,
    GQTypes.GQValueMappingFileRowDataQueryVariables
  >({ query: ValueMappingFileRowDataDocument, ...options });
}
export const ValueMappingTableSourcesDocument = gql`
    query ValueMappingTableSources($valueMappingConfigId: String!) @withOwner(owner: DatasetsAndObjects) {
  valueMappingTableSourceData(valueMappingConfigId: $valueMappingConfigId) {
    id
    outputOptionPickers {
      id
      columnName
      placeholder
      options {
        ...ValueMappingOutputOptionFields
      }
    }
  }
}
    ${ValueMappingOutputOptionFieldsFragmentDoc}`;

export function useValueMappingTableSourcesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQValueMappingTableSourcesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQValueMappingTableSourcesQuery,
    GQTypes.GQValueMappingTableSourcesQueryVariables
  >({ query: ValueMappingTableSourcesDocument, ...options });
}
export const DownloadValueMappingDocument = gql`
    mutation DownloadValueMapping($valueMappingConfigId: ID!, $withComments: Boolean, $measurementProjectId: ID) @withOwner(owner: DatasetsAndObjects) {
  generateValueMappingDownloadUrl(
    valueMappingConfigId: $valueMappingConfigId
    withComments: $withComments
    measurementProjectId: $measurementProjectId
  )
}
    `;

export function useDownloadValueMappingMutation() {
  return Urql.useMutation<
    GQTypes.GQDownloadValueMappingMutation,
    GQTypes.GQDownloadValueMappingMutationVariables
  >(DownloadValueMappingDocument);
}
export const SuggestMappingsDocument = gql`
    mutation SuggestMappings($valueMappingConfigId: ID!, $measurementProjectId: ID) @withOwner(owner: DatasetsAndObjects) {
  suggestMappings(
    valueMappingConfigId: $valueMappingConfigId
    measurementProjectId: $measurementProjectId
  ) {
    jobId
  }
}
    `;

export function useSuggestMappingsMutation() {
  return Urql.useMutation<
    GQTypes.GQSuggestMappingsMutation,
    GQTypes.GQSuggestMappingsMutationVariables
  >(SuggestMappingsDocument);
}
export const ValueMappingConfigDocument = gql`
    query ValueMappingConfig($id: String!) @withOwner(owner: DatasetsAndObjects) {
  valueMappingConfig(valueMappingConfigId: $id)
}
    `;

export function useValueMappingConfigQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQValueMappingConfigQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQValueMappingConfigQuery,
    GQTypes.GQValueMappingConfigQueryVariables
  >({ query: ValueMappingConfigDocument, ...options });
}
export const ValueMappingAggregatedQuantitiesDocument = gql`
    query ValueMappingAggregatedQuantities($configId: ID!, $orgId: ID!, $contextColumn: String!) @withOwner(owner: DatasetsAndObjects) {
  valueMappingAggregatedQuantities(
    configId: $configId
    orgId: $orgId
    contextColumn: $contextColumn
  ) {
    totalQuantity
    mappedQuantity
  }
}
    `;

export function useValueMappingAggregatedQuantitiesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQValueMappingAggregatedQuantitiesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQValueMappingAggregatedQuantitiesQuery,
    GQTypes.GQValueMappingAggregatedQuantitiesQueryVariables
  >({ query: ValueMappingAggregatedQuantitiesDocument, ...options });
}
export const SaveDeltaProgressValueMappingDocument = gql`
    mutation SaveDeltaProgressValueMapping($input: SaveDeltaProgressValueMappingInput!) @withOwner(owner: DatasetsAndObjects) {
  saveDeltaProgressValueMapping(input: $input) {
    revisionData {
      id
      revisionState
    }
    updatedRows {
      ...ValueMappingPageValueMappingRow
    }
    metadata {
      id
      allRowsAreFilled
    }
  }
}
    ${ValueMappingPageValueMappingRowFragmentDoc}`;

export function useSaveDeltaProgressValueMappingMutation() {
  return Urql.useMutation<
    GQTypes.GQSaveDeltaProgressValueMappingMutation,
    GQTypes.GQSaveDeltaProgressValueMappingMutationVariables
  >(SaveDeltaProgressValueMappingDocument);
}
export const ConfirmMarketplacePurchaseDocument = gql`
    mutation ConfirmMarketplacePurchase($input: ConfirmMarketplacePurchaseInput!) @withOwner(owner: SupplyChain) {
  confirmMarketplacePurchase(input: $input) {
    purchase {
      ...MarketplacePurchaseFieldsForPurchasesPage
    }
  }
}
    ${MarketplacePurchaseFieldsForPurchasesPageFragmentDoc}`;

export function useConfirmMarketplacePurchaseMutation() {
  return Urql.useMutation<
    GQTypes.GQConfirmMarketplacePurchaseMutation,
    GQTypes.GQConfirmMarketplacePurchaseMutationVariables
  >(ConfirmMarketplacePurchaseDocument);
}
export const GetVisibleMarketplaceProjectArchetypesDocument = gql`
    query GetVisibleMarketplaceProjectArchetypes @withOwner(owner: SupplyChain) {
  visibleMarketplaceProjectArchetypes {
    edges {
      node {
        ...VisibleMarketplaceProjectArchetypeFields
      }
    }
  }
}
    ${VisibleMarketplaceProjectArchetypeFieldsFragmentDoc}`;

export function useGetVisibleMarketplaceProjectArchetypesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetVisibleMarketplaceProjectArchetypesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetVisibleMarketplaceProjectArchetypesQuery,
    GQTypes.GQGetVisibleMarketplaceProjectArchetypesQueryVariables
  >({ query: GetVisibleMarketplaceProjectArchetypesDocument, ...options });
}
export const GetMarketplaceVoluntaryCarbonMarketDisclosuresDocument = gql`
    query GetMarketplaceVoluntaryCarbonMarketDisclosures @withOwner(owner: SupplyChain) {
  marketplaceVoluntaryCarbonMarketDisclosures {
    year
    url
  }
}
    `;

export function useGetMarketplaceVoluntaryCarbonMarketDisclosuresQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetMarketplaceVoluntaryCarbonMarketDisclosuresQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetMarketplaceVoluntaryCarbonMarketDisclosuresQuery,
    GQTypes.GQGetMarketplaceVoluntaryCarbonMarketDisclosuresQueryVariables
  >({
    query: GetMarketplaceVoluntaryCarbonMarketDisclosuresDocument,
    ...options,
  });
}
export const RefreshMarketplacePurchaseDocument = gql`
    mutation RefreshMarketplacePurchase($input: RefreshMarketplacePurchaseInput!) @withOwner(owner: SupplyChain) {
  refreshMarketplacePurchase(input: $input) {
    purchase {
      ...MarketplacePurchaseFieldsForPurchasesPage
    }
  }
}
    ${MarketplacePurchaseFieldsForPurchasesPageFragmentDoc}`;

export function useRefreshMarketplacePurchaseMutation() {
  return Urql.useMutation<
    GQTypes.GQRefreshMarketplacePurchaseMutation,
    GQTypes.GQRefreshMarketplacePurchaseMutationVariables
  >(RefreshMarketplacePurchaseDocument);
}
export const GetMaterialsForComparisonViewDocument = gql`
    query GetMaterialsForComparisonView @withOwner(owner: SupplyChain) {
  materialComparisonView {
    ...MaterialTableFields
  }
}
    ${MaterialTableFieldsFragmentDoc}`;

export function useGetMaterialsForComparisonViewQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetMaterialsForComparisonViewQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetMaterialsForComparisonViewQuery,
    GQTypes.GQGetMaterialsForComparisonViewQueryVariables
  >({ query: GetMaterialsForComparisonViewDocument, ...options });
}
export const SearchProductionGraphProcessesDocument = gql`
    query SearchProductionGraphProcesses($query: String!) @withOwner(owner: SupplyChain) {
  productionGraphProcess(query: $query) {
    id
    processName
    referenceProductName
    referenceProductDescription
    geography
  }
}
    `;

export function useSearchProductionGraphProcessesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQSearchProductionGraphProcessesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSearchProductionGraphProcessesQuery,
    GQTypes.GQSearchProductionGraphProcessesQueryVariables
  >({ query: SearchProductionGraphProcessesDocument, ...options });
}
export const SaveProductionGraphDocument = gql`
    mutation SaveProductionGraph($input: SaveProductionGraphInput!) @withOwner(owner: SupplyChain) {
  saveProductionGraph(input: $input) {
    id
    nodes {
      ...ProductionGraphNodeFields
    }
  }
}
    ${ProductionGraphNodeFieldsFragmentDoc}`;

export function useSaveProductionGraphMutation() {
  return Urql.useMutation<
    GQTypes.GQSaveProductionGraphMutation,
    GQTypes.GQSaveProductionGraphMutationVariables
  >(SaveProductionGraphDocument);
}
export const CreateMaterialUploadTemplateDocument = gql`
    mutation CreateMaterialUploadTemplate @withOwner(owner: ProductionGraph) {
  createMaterialUploadTemplate {
    oneSchemaTemplateKey
  }
}
    `;

export function useCreateMaterialUploadTemplateMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMaterialUploadTemplateMutation,
    GQTypes.GQCreateMaterialUploadTemplateMutationVariables
  >(CreateMaterialUploadTemplateDocument);
}
export const FetchDatasetsSimpleDocument = gql`
    query FetchDatasetsSimple @withOwner(owner: DataIngestion) {
  datasets(sortBy: "name") {
    id
    name
    canonicalDataset {
      id
      kind
      selfServeDisabledReason
    }
  }
}
    `;

export function useFetchDatasetsSimpleQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFetchDatasetsSimpleQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFetchDatasetsSimpleQuery,
    GQTypes.GQFetchDatasetsSimpleQueryVariables
  >({ query: FetchDatasetsSimpleDocument, ...options });
}
export const FetchDatasetsForPlanDocument = gql`
    query FetchDatasetsForPlan @withOwner(owner: DataIngestion) {
  availableCanonicalDatasetsForPlan {
    id
    kind
    name
    selfServeDisabledReason
    canonicalDatasources {
      id
      name
      activeIntegrationConnection {
        id
      }
    }
  }
}
    `;

export function useFetchDatasetsForPlanQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFetchDatasetsForPlanQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFetchDatasetsForPlanQuery,
    GQTypes.GQFetchDatasetsForPlanQueryVariables
  >({ query: FetchDatasetsForPlanDocument, ...options });
}
export const CreateClimateProgramProjectDatasetRequirementDocument = gql`
    mutation CreateClimateProgramProjectDatasetRequirement($input: CreateClimateProgramProjectDatasetRequirementInput!) @withOwner(owner: DataIngestion) {
  createClimateProgramProjectDatasetRequirement(input: $input) {
    climateProgramProject {
      ...ClimateProgramProjectWithMeasurementConcernsFields
    }
  }
}
    ${ClimateProgramProjectWithMeasurementConcernsFieldsFragmentDoc}`;

export function useCreateClimateProgramProjectDatasetRequirementMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateClimateProgramProjectDatasetRequirementMutation,
    GQTypes.GQCreateClimateProgramProjectDatasetRequirementMutationVariables
  >(CreateClimateProgramProjectDatasetRequirementDocument);
}
export const GetOrCreateDatasetFromCanonicalDatasetKindDocument = gql`
    mutation GetOrCreateDatasetFromCanonicalDatasetKind($input: GetOrCreateDatasetFromCanonicalDatasetKindInput!) @withOwner(owner: DataIngestion) {
  getOrCreateDatasetFromCanonicalDatasetKind(input: $input) {
    dataset {
      id
      name
    }
  }
}
    `;

export function useGetOrCreateDatasetFromCanonicalDatasetKindMutation() {
  return Urql.useMutation<
    GQTypes.GQGetOrCreateDatasetFromCanonicalDatasetKindMutation,
    GQTypes.GQGetOrCreateDatasetFromCanonicalDatasetKindMutationVariables
  >(GetOrCreateDatasetFromCanonicalDatasetKindDocument);
}
export const CreateMeasurementProjectFromCanonicalClimateProgramProjectDocument = gql`
    mutation CreateMeasurementProjectFromCanonicalClimateProgramProject($input: CreateMeasurementProjectFromCanonicalClimateProgramProjectInput!) @withOwner(owner: DataIngestion) {
  createMeasurementProjectFromCanonicalClimateProgramProject(input: $input) {
    measurementProject {
      ...MeasurementProjectForMeasurementPage
      ...MeasurementProjectFields
    }
    climateProgramProjects {
      id
      measurementProject {
        id
        name
      }
    }
  }
}
    ${MeasurementProjectForMeasurementPageFragmentDoc}
${MeasurementProjectFieldsFragmentDoc}`;

export function useCreateMeasurementProjectFromCanonicalClimateProgramProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMeasurementProjectFromCanonicalClimateProgramProjectMutation,
    GQTypes.GQCreateMeasurementProjectFromCanonicalClimateProgramProjectMutationVariables
  >(CreateMeasurementProjectFromCanonicalClimateProgramProjectDocument);
}
export const DeleteClimateProgramProjectDatasetRequirementDocument = gql`
    mutation DeleteClimateProgramProjectDatasetRequirement($input: IdInput!) @withOwner(owner: DataIngestion) {
  deleteClimateProgramProjectDatasetRequirement(input: $input) {
    climateProgramProject {
      ...ClimateProgramProjectWithMeasurementConcernsFields
    }
  }
}
    ${ClimateProgramProjectWithMeasurementConcernsFieldsFragmentDoc}`;

export function useDeleteClimateProgramProjectDatasetRequirementMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteClimateProgramProjectDatasetRequirementMutation,
    GQTypes.GQDeleteClimateProgramProjectDatasetRequirementMutationVariables
  >(DeleteClimateProgramProjectDatasetRequirementDocument);
}
export const GetUserUploadsByIdFilePreviewDialogDocument = gql`
    query GetUserUploadsByIdFilePreviewDialog($ids: [ID!]!) @withOwner(owner: DataIngestion) {
  userUploadsById(ids: $ids) {
    ...UserUploadFieldsForFilePreview
  }
}
    ${UserUploadFieldsForFilePreviewFragmentDoc}`;

export function useGetUserUploadsByIdFilePreviewDialogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetUserUploadsByIdFilePreviewDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetUserUploadsByIdFilePreviewDialogQuery,
    GQTypes.GQGetUserUploadsByIdFilePreviewDialogQueryVariables
  >({ query: GetUserUploadsByIdFilePreviewDialogDocument, ...options });
}
export const UpdateClimateProgramProjectDatasetRequirementDocument = gql`
    mutation UpdateClimateProgramProjectDatasetRequirement($input: UpdateClimateProgramProjectDatasetRequirementInput!) @withOwner(owner: DataIngestion) {
  updateClimateProgramProjectDatasetRequirement(input: $input) {
    climateProgramProjectRequirementDataset {
      ...ClimateProgramProjectRequirementDatasetFields
    }
  }
}
    ${ClimateProgramProjectRequirementDatasetFieldsFragmentDoc}`;

export function useUpdateClimateProgramProjectDatasetRequirementMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateClimateProgramProjectDatasetRequirementMutation,
    GQTypes.GQUpdateClimateProgramProjectDatasetRequirementMutationVariables
  >(UpdateClimateProgramProjectDatasetRequirementDocument);
}
export const UserUploadedTableByIdDocument = gql`
    query UserUploadedTableById($id: ID!) @withOwner(owner: DataIngestion) {
  userUploadedTableById(id: $id) {
    ...UserUploadedTableForTablePreviewDialog
  }
}
    ${UserUploadedTableForTablePreviewDialogFragmentDoc}`;

export function useUserUploadedTableByIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQUserUploadedTableByIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQUserUploadedTableByIdQuery,
    GQTypes.GQUserUploadedTableByIdQueryVariables
  >({ query: UserUploadedTableByIdDocument, ...options });
}
export const GetAllBuildingNamesDocument = gql`
    query GetAllBuildingNames @withOwner(owner: DataIngestion) {
  organization {
    id
    buildingNames
  }
}
    `;

export function useGetAllBuildingNamesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetAllBuildingNamesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetAllBuildingNamesQuery,
    GQTypes.GQGetAllBuildingNamesQueryVariables
  >({ query: GetAllBuildingNamesDocument, ...options });
}
export const SubmitFacilitiesBulkAdditionDocument = gql`
    mutation SubmitFacilitiesBulkAddition($input: SubmitFacilitiesBulkAdditionInput!) @withOwner(owner: DataIngestion) {
  submitFacilitiesBulkAddition(input: $input) {
    numFacilitiesCreated
    org {
      ...OrganizationFacilitiesUntyped
    }
    measurementProject {
      id
      measurementTasks {
        ...MeasurementParentTaskForMeasurementPage
      }
      ...MeasurementProjectForManageFacilitiesPage
    }
  }
}
    ${OrganizationFacilitiesUntypedFragmentDoc}
${MeasurementParentTaskForMeasurementPageFragmentDoc}
${MeasurementProjectForManageFacilitiesPageFragmentDoc}`;

export function useSubmitFacilitiesBulkAdditionMutation() {
  return Urql.useMutation<
    GQTypes.GQSubmitFacilitiesBulkAdditionMutation,
    GQTypes.GQSubmitFacilitiesBulkAdditionMutationVariables
  >(SubmitFacilitiesBulkAdditionDocument);
}
export const CreateSingleUploaderFacilitiesTaskDocument = gql`
    mutation CreateSingleUploaderFacilitiesTask($input: CreateSingleUploaderFacilitiesTaskInput!) @withOwner(owner: DataIngestion) {
  createSingleUploaderFacilitiesTask(input: $input) {
    task {
      ...FacilitiesUserUploadTask
    }
    measurementProject {
      ...MeasurementProjectForManageFacilitiesPage
    }
  }
}
    ${FacilitiesUserUploadTaskFragmentDoc}
${MeasurementProjectForManageFacilitiesPageFragmentDoc}`;

export function useCreateSingleUploaderFacilitiesTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateSingleUploaderFacilitiesTaskMutation,
    GQTypes.GQCreateSingleUploaderFacilitiesTaskMutationVariables
  >(CreateSingleUploaderFacilitiesTaskDocument);
}
export const BulkUploadTaskImporterDocument = gql`
    query BulkUploadTaskImporter @withOwner(owner: DataIngestion) {
  organization {
    id
    datasets {
      id
      name
      canonicalDataset {
        id
        kind
      }
    }
    users {
      edges {
        node {
          id
          email
        }
      }
    }
  }
}
    `;

export function useBulkUploadTaskImporterQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQBulkUploadTaskImporterQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBulkUploadTaskImporterQuery,
    GQTypes.GQBulkUploadTaskImporterQueryVariables
  >({ query: BulkUploadTaskImporterDocument, ...options });
}
export const BulkCreateUploadTasksDocument = gql`
    mutation BulkCreateUploadTasks($input: BulkCreateUploadTasksInput!) @withOwner(owner: DataIngestion) {
  bulkCreateUploadTasks(input: $input) {
    success
  }
}
    `;

export function useBulkCreateUploadTasksMutation() {
  return Urql.useMutation<
    GQTypes.GQBulkCreateUploadTasksMutation,
    GQTypes.GQBulkCreateUploadTasksMutationVariables
  >(BulkCreateUploadTasksDocument);
}
export const AssignMeasurementTaskDocument = gql`
    mutation AssignMeasurementTask($input: AssignMeasurementTaskInput!, $permissions: [HasPermissionInput!], $withAllPermissions: [HasPermissionInput!]) @withOwner(owner: DataIngestion) {
  assignMeasurementTask(input: $input) {
    measurementTask {
      ...MeasurementParentTaskForMeasurementPage
    }
    organization {
      id
      usersWithPermissions: users(
        filters: {withSomePermissions: $permissions, withAllPermissions: $withAllPermissions, includeWatershedEmployees: true}
      ) {
        edges {
          node {
            id
          }
        }
      }
    }
  }
}
    ${MeasurementParentTaskForMeasurementPageFragmentDoc}`;

export function useAssignMeasurementTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQAssignMeasurementTaskMutation,
    GQTypes.GQAssignMeasurementTaskMutationVariables
  >(AssignMeasurementTaskDocument);
}
export const SetApproversDocument = gql`
    mutation SetApprovers($input: SetApproversInput!) @withOwner(owner: DataIngestion) {
  setApprovers(input: $input) {
    target {
      id
      approvers {
        ...ApproverFieldsForMeasurementPage
      }
    }
  }
}
    ${ApproverFieldsForMeasurementPageFragmentDoc}`;

export function useSetApproversMutation() {
  return Urql.useMutation<
    GQTypes.GQSetApproversMutation,
    GQTypes.GQSetApproversMutationVariables
  >(SetApproversDocument);
}
export const BulkAssignMeasurementTasksDocument = gql`
    mutation BulkAssignMeasurementTasks($input: [AssignMeasurementTaskInput!]!, $withAllPermissions: [HasPermissionInput!]) @withOwner(owner: DataIngestion) {
  bulkAssignMeasurementTasks(input: $input) {
    measurementTasks {
      ...MeasurementParentTaskForMeasurementPage
    }
    organization {
      id
      usersWithPermissions: users(
        filters: {withSomePermissions: [], withAllPermissions: $withAllPermissions, includeWatershedEmployees: true}
      ) {
        edges {
          node {
            id
          }
        }
      }
    }
  }
}
    ${MeasurementParentTaskForMeasurementPageFragmentDoc}`;

export function useBulkAssignMeasurementTasksMutation() {
  return Urql.useMutation<
    GQTypes.GQBulkAssignMeasurementTasksMutation,
    GQTypes.GQBulkAssignMeasurementTasksMutationVariables
  >(BulkAssignMeasurementTasksDocument);
}
export const BulkSetApproversDocument = gql`
    mutation BulkSetApprovers($input: [SetApproversInput!]!, $withAllPermissions: [HasPermissionInput!]) @withOwner(owner: DataIngestion) {
  bulkSetApprovers(input: $input) {
    targets {
      id
      approvers {
        ...ApproverFieldsForMeasurementPage
      }
    }
    organization {
      id
      usersWithPermissions: users(
        filters: {withSomePermissions: [], withAllPermissions: $withAllPermissions, includeWatershedEmployees: true}
      ) {
        edges {
          node {
            id
          }
        }
      }
    }
  }
}
    ${ApproverFieldsForMeasurementPageFragmentDoc}`;

export function useBulkSetApproversMutation() {
  return Urql.useMutation<
    GQTypes.GQBulkSetApproversMutation,
    GQTypes.GQBulkSetApproversMutationVariables
  >(BulkSetApproversDocument);
}
export const BatchSetCustomFieldForDatasourcesDocument = gql`
    mutation BatchSetCustomFieldForDatasources($input: BatchSetCustomFieldForDatasourcesInput!) @withOwner(owner: DataIngestion) {
  batchSetCustomFieldForDatasources(input: $input) {
    datasources {
      id
      presetCustomTags {
        ...PresetCustomTagForMeasurementPage
      }
    }
  }
}
    ${PresetCustomTagForMeasurementPageFragmentDoc}`;

export function useBatchSetCustomFieldForDatasourcesMutation() {
  return Urql.useMutation<
    GQTypes.GQBatchSetCustomFieldForDatasourcesMutation,
    GQTypes.GQBatchSetCustomFieldForDatasourcesMutationVariables
  >(BatchSetCustomFieldForDatasourcesDocument);
}
export const BatchUnsetCustomFieldForDatasourcesDocument = gql`
    mutation BatchUnsetCustomFieldForDatasources($input: BatchUnsetCustomFieldForDatasourcesInput!) @withOwner(owner: DataIngestion) {
  batchUnsetCustomFieldForDatasources(input: $input) {
    datasources {
      id
      presetCustomTags {
        ...PresetCustomTagForMeasurementPage
      }
    }
  }
}
    ${PresetCustomTagForMeasurementPageFragmentDoc}`;

export function useBatchUnsetCustomFieldForDatasourcesMutation() {
  return Urql.useMutation<
    GQTypes.GQBatchUnsetCustomFieldForDatasourcesMutation,
    GQTypes.GQBatchUnsetCustomFieldForDatasourcesMutationVariables
  >(BatchUnsetCustomFieldForDatasourcesDocument);
}
export const DataCollectionCommandPaletteCustomFieldUsedValuesDocument = gql`
    query DataCollectionCommandPaletteCustomFieldUsedValues($id: ID!) @withOwner(owner: DataIngestion) {
  ingestionCustomField(id: $id) {
    id
    usedValues
  }
}
    `;

export function useDataCollectionCommandPaletteCustomFieldUsedValuesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDataCollectionCommandPaletteCustomFieldUsedValuesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDataCollectionCommandPaletteCustomFieldUsedValuesQuery,
    GQTypes.GQDataCollectionCommandPaletteCustomFieldUsedValuesQueryVariables
  >({
    query: DataCollectionCommandPaletteCustomFieldUsedValuesDocument,
    ...options,
  });
}
export const GetCustomEmissionsFactorsTaskDocument = gql`
    query GetCustomEmissionsFactorsTask($measurementProjectId: ID!) @withOwner(owner: DataIngestion) {
  customEmissionsFactorsTask(measurementProjectId: $measurementProjectId) {
    id
    statusSimplified
  }
}
    `;

export function useGetCustomEmissionsFactorsTaskQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCustomEmissionsFactorsTaskQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCustomEmissionsFactorsTaskQuery,
    GQTypes.GQGetCustomEmissionsFactorsTaskQueryVariables
  >({ query: GetCustomEmissionsFactorsTaskDocument, ...options });
}
export const MethodologyCustomizationOneSchemaTemplateDocument = gql`
    query MethodologyCustomizationOneSchemaTemplate($customizationTypeId: String!) @withOwner(owner: DataIngestion) {
  methodologyCustomizationOneSchemaTemplateForType(
    customizationTypeId: $customizationTypeId
  )
}
    `;

export function useMethodologyCustomizationOneSchemaTemplateQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMethodologyCustomizationOneSchemaTemplateQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMethodologyCustomizationOneSchemaTemplateQuery,
    GQTypes.GQMethodologyCustomizationOneSchemaTemplateQueryVariables
  >({ query: MethodologyCustomizationOneSchemaTemplateDocument, ...options });
}
export const GenerateCustomerUploadedReferenceDataRevisionDocument = gql`
    mutation GenerateCustomerUploadedReferenceDataRevision($input: GenerateCustomerUploadedReferenceDataRevisionInput!) @withOwner(owner: DataIngestion) {
  generateCustomerUploadedReferenceDataRevision(input: $input) {
    jobId
  }
}
    `;

export function useGenerateCustomerUploadedReferenceDataRevisionMutation() {
  return Urql.useMutation<
    GQTypes.GQGenerateCustomerUploadedReferenceDataRevisionMutation,
    GQTypes.GQGenerateCustomerUploadedReferenceDataRevisionMutationVariables
  >(GenerateCustomerUploadedReferenceDataRevisionDocument);
}
export const CustomEmissionsFactorForDashboardDocument = gql`
    query CustomEmissionsFactorForDashboard($measurementProjectId: ID) @withOwner(owner: Calcprint) {
  customEmissionsFactorsForOrg(measurementProjectId: $measurementProjectId) {
    __typename
    id
    startDate
    endDate
    emissionsSpecifier
    kgco2e
    unit
    description
    citationUrl
    citationTitle
    citationNotes
  }
}
    `;

export function useCustomEmissionsFactorForDashboardQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCustomEmissionsFactorForDashboardQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCustomEmissionsFactorForDashboardQuery,
    GQTypes.GQCustomEmissionsFactorForDashboardQueryVariables
  >({ query: CustomEmissionsFactorForDashboardDocument, ...options });
}
export const CreateCustomEmissionsFactorsReferenceDataRevisionDocument = gql`
    mutation CreateCustomEmissionsFactorsReferenceDataRevision($input: CreateCustomEmissionsFactorsReferenceDataRevisionInput!) @withOwner(owner: Calcprint) {
  createCustomEmissionsFactorsReferenceDataRevision(input: $input) {
    jobId
  }
}
    `;

export function useCreateCustomEmissionsFactorsReferenceDataRevisionMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCustomEmissionsFactorsReferenceDataRevisionMutation,
    GQTypes.GQCreateCustomEmissionsFactorsReferenceDataRevisionMutationVariables
  >(CreateCustomEmissionsFactorsReferenceDataRevisionDocument);
}
export const ActivityForCustomEmissionsFactorsDocument = gql`
    query ActivityForCustomEmissionsFactors($measurementProjectId: ID!) @withOwner(owner: DataIngestion) {
  customEmissionsFactorActivityUserUploadTasks(
    measurementProjectId: $measurementProjectId
  ) {
    ...CustomActivityUserUploadTasksFields
  }
}
    ${CustomActivityUserUploadTasksFieldsFragmentDoc}`;

export function useActivityForCustomEmissionsFactorsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQActivityForCustomEmissionsFactorsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQActivityForCustomEmissionsFactorsQuery,
    GQTypes.GQActivityForCustomEmissionsFactorsQueryVariables
  >({ query: ActivityForCustomEmissionsFactorsDocument, ...options });
}
export const DeleteCustomEmissionsFactorsDocument = gql`
    mutation DeleteCustomEmissionsFactors($input: DeleteCustomEmissionsFactorsInput!) @withOwner(owner: Calcprint) {
  deleteCustomEmissionsFactors(input: $input) {
    success
    jobId
    __typename
  }
}
    `;

export function useDeleteCustomEmissionsFactorsMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCustomEmissionsFactorsMutation,
    GQTypes.GQDeleteCustomEmissionsFactorsMutationVariables
  >(DeleteCustomEmissionsFactorsDocument);
}
export const CreateCustomFieldForEditDialogDocument = gql`
    mutation CreateCustomFieldForEditDialog($input: CreateIngestionCustomFieldInput!) @withOwner(owner: DataIngestion) {
  createIngestionCustomField(input: $input) {
    ingestionCustomField {
      ...CustomFieldForEditDialog
    }
    organization {
      ...OrganizationCustomFieldsForManagePage
      ...OrganizationCustomFieldsForImporterPage
    }
  }
}
    ${CustomFieldForEditDialogFragmentDoc}
${OrganizationCustomFieldsForManagePageFragmentDoc}
${OrganizationCustomFieldsForImporterPageFragmentDoc}`;

export function useCreateCustomFieldForEditDialogMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCustomFieldForEditDialogMutation,
    GQTypes.GQCreateCustomFieldForEditDialogMutationVariables
  >(CreateCustomFieldForEditDialogDocument);
}
export const EditCustomFieldForEditDialogDocument = gql`
    mutation EditCustomFieldForEditDialog($input: EditIngestionCustomFieldInput!) @withOwner(owner: DataIngestion) {
  editIngestionCustomField(input: $input) {
    ingestionCustomField {
      ...CustomFieldForEditDialog
    }
  }
}
    ${CustomFieldForEditDialogFragmentDoc}`;

export function useEditCustomFieldForEditDialogMutation() {
  return Urql.useMutation<
    GQTypes.GQEditCustomFieldForEditDialogMutation,
    GQTypes.GQEditCustomFieldForEditDialogMutationVariables
  >(EditCustomFieldForEditDialogDocument);
}
export const DeleteCustomFieldForEditDialogDocument = gql`
    mutation DeleteCustomFieldForEditDialog($input: DeleteIngestionCustomFieldInput!) @withOwner(owner: DataIngestion) {
  deleteIngestionCustomField(input: $input) {
    organization {
      ...OrganizationCustomFieldsForManagePage
      ...OrganizationCustomFieldsForImporterPage
    }
    ingestionCustomField {
      ...CustomFieldForEditDialog
    }
  }
}
    ${OrganizationCustomFieldsForManagePageFragmentDoc}
${OrganizationCustomFieldsForImporterPageFragmentDoc}
${CustomFieldForEditDialogFragmentDoc}`;

export function useDeleteCustomFieldForEditDialogMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCustomFieldForEditDialogMutation,
    GQTypes.GQDeleteCustomFieldForEditDialogMutationVariables
  >(DeleteCustomFieldForEditDialogDocument);
}
export const OrganizationAuditLogsDocument = gql`
    query OrganizationAuditLogs($interval: YMInterval!, $eventKind: String, $after: String, $before: String, $first: Int, $last: Int) @withOwner(owner: DataIngestion) {
  auditLogs(
    interval: $interval
    eventKind: $eventKind
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        ...AuditLogEntryFields
      }
    }
  }
}
    ${AuditLogEntryFieldsFragmentDoc}`;

export function useOrganizationAuditLogsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationAuditLogsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationAuditLogsQuery,
    GQTypes.GQOrganizationAuditLogsQueryVariables
  >({ query: OrganizationAuditLogsDocument, ...options });
}
export const AddDatasourceDialogDocument = gql`
    query AddDatasourceDialog($measurementProjectId: ID!) @withOwner(owner: DataIngestion) {
  datasets(sortBy: "name") {
    id
    name
    canonicalDataset {
      id
      kind
      selfServeDisabledReason
      canonicalDatasources {
        id
        name
        activeIntegrationConnection {
          id
        }
      }
    }
  }
  measurementProject(id: $measurementProjectId) {
    id
    userUploadTasks {
      edges {
        node {
          id
          datasource {
            id
            dataset {
              id
            }
          }
        }
      }
    }
  }
}
    `;

export function useAddDatasourceDialogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAddDatasourceDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAddDatasourceDialogQuery,
    GQTypes.GQAddDatasourceDialogQueryVariables
  >({ query: AddDatasourceDialogDocument, ...options });
}
export const CreateDatasourceDocument = gql`
    mutation CreateDatasource($input: CreateDatasourceInput!, $measurementProjectId: ID, $userUploadLimit: Int) @withOwner(owner: DataIngestion) {
  createDatasource(input: $input) {
    datasource {
      id
      ...DatasourceFieldsForDatasourcePage
      dataset {
        id
        datasources {
          id
        }
      }
    }
  }
}
    ${DatasourceFieldsForDatasourcePageFragmentDoc}`;

export function useCreateDatasourceMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateDatasourceMutation,
    GQTypes.GQCreateDatasourceMutationVariables
  >(CreateDatasourceDocument);
}
export const FacilitiesForAddFacilitiesGridDocument = gql`
    query FacilitiesForAddFacilitiesGrid($measurementProjectId: ID!, $after: String, $before: String, $first: Int, $last: Int, $filterWithOperator: FacilitiesFilter, $sort: [FacilitiesSortInput!], $search: String, $canonicalDatasetKind: CanonicalDatasetKind!, $orgStructureEnabled: Boolean!, $includeDeleted: Boolean) @withOwner(owner: DataIngestion) {
  facilities(
    after: $after
    before: $before
    first: $first
    last: $last
    filterWithOperator: $filterWithOperator
    sort: $sort
    search: $search
    measurementProjectId: $measurementProjectId
    includeDeleted: $includeDeleted
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
    edges {
      node {
        ...FacilitiesForAddFacilitiesGrid
      }
    }
  }
  latestOrgUnitTypesForCanonicalDatasetKind(
    canonicalDatasetKind: $canonicalDatasetKind
  ) @include(if: $orgStructureEnabled) {
    ...OrgUnitTypeWithOrgUnitsForFacilities
  }
}
    ${FacilitiesForAddFacilitiesGridFragmentDoc}
${OrgUnitTypeWithOrgUnitsForFacilitiesFragmentDoc}`;

export function useFacilitiesForAddFacilitiesGridQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFacilitiesForAddFacilitiesGridQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFacilitiesForAddFacilitiesGridQuery,
    GQTypes.GQFacilitiesForAddFacilitiesGridQueryVariables
  >({ query: FacilitiesForAddFacilitiesGridDocument, ...options });
}
export const AddFacilitiesGridDocument = gql`
    query AddFacilitiesGrid($userUploadTaskId: ID!) @withOwner(owner: DataIngestion) {
  organization {
    ...OrganizationForConfigureFacilitiesTasksPage
  }
  userUploadTask(id: $userUploadTaskId) {
    id
    facilitiesPreview {
      totalCount
    }
  }
}
    ${OrganizationForConfigureFacilitiesTasksPageFragmentDoc}`;

export function useAddFacilitiesGridQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAddFacilitiesGridQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAddFacilitiesGridQuery,
    GQTypes.GQAddFacilitiesGridQueryVariables
  >({ query: AddFacilitiesGridDocument, ...options });
}
export const UnassignFacilitiesFromTaskDocument = gql`
    mutation UnassignFacilitiesFromTask($input: UnassignFacilitiesFromTaskInput!) @withOwner(owner: DataIngestion) {
  unassignFacilitiesFromTask(input: $input) {
    measurementProject {
      ...MeasurementProjectForConfigureFacilitiesTasksPage
    }
  }
}
    ${MeasurementProjectForConfigureFacilitiesTasksPageFragmentDoc}`;

export function useUnassignFacilitiesFromTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQUnassignFacilitiesFromTaskMutation,
    GQTypes.GQUnassignFacilitiesFromTaskMutationVariables
  >(UnassignFacilitiesFromTaskDocument);
}
export const GetCustomerTargetSchemasForCanonicalDatasetDocument = gql`
    query GetCustomerTargetSchemasForCanonicalDataset($id: ID!) @withOwner(owner: DataIngestion) {
  canonicalDataset(id: $id) {
    id
    name
    customerTargetSchemas(hasPublishedVersion: true) {
      id
      name
      descriptionMd
    }
  }
}
    `;

export function useGetCustomerTargetSchemasForCanonicalDatasetQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCustomerTargetSchemasForCanonicalDatasetQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCustomerTargetSchemasForCanonicalDatasetQuery,
    GQTypes.GQGetCustomerTargetSchemasForCanonicalDatasetQueryVariables
  >({ query: GetCustomerTargetSchemasForCanonicalDatasetDocument, ...options });
}
export const UpdateMeasurementProjectDashboardDocument = gql`
    mutation UpdateMeasurementProjectDashboard($input: UpdateMeasurementProjectInput!) @withOwner(owner: DataIngestion) {
  updateMeasurementProjectDashboard(input: $input) {
    measurementProject {
      ...DashboardMeasurementProjectFieldsForOrgPage
    }
  }
}
    ${DashboardMeasurementProjectFieldsForOrgPageFragmentDoc}`;

export function useUpdateMeasurementProjectDashboardMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateMeasurementProjectDashboardMutation,
    GQTypes.GQUpdateMeasurementProjectDashboardMutationVariables
  >(UpdateMeasurementProjectDashboardDocument);
}
export const MeasurementProjectForEditDocument = gql`
    query MeasurementProjectForEdit($measurementProjectId: ID!) @withOwner(owner: DataIngestion) {
  measurementProject(id: $measurementProjectId) {
    id
    canEditCoverageInterval
    coverageInterval
    name
    isApprovalFlowEnabled
    measurementTimeline {
      id
      kickoff
      deadline
    }
  }
}
    `;

export function useMeasurementProjectForEditQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMeasurementProjectForEditQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMeasurementProjectForEditQuery,
    GQTypes.GQMeasurementProjectForEditQueryVariables
  >({ query: MeasurementProjectForEditDocument, ...options });
}
export const DeleteFileDocument = gql`
    mutation DeleteFile($fileId: ID!) @withOwner(owner: DataIngestion) {
  deleteFile(input: {fileId: $fileId}) {
    file {
      id
    }
    datasource {
      id
      summaryWithMetadata {
        id
        summaries {
          id
          signedUrl
        }
      }
    }
    measurementProject {
      id
    }
  }
}
    `;

export function useDeleteFileMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteFileMutation,
    GQTypes.GQDeleteFileMutationVariables
  >(DeleteFileDocument);
}
export const FileCustomTransformEditorDocument = gql`
    query FileCustomTransformEditor($id: ID!) @withOwner(owner: DataIngestion) {
  organization {
    ...OrganizationCustomFieldsForImporterPage
  }
  userUpload(id: $id) {
    ...FileCustomTransformEditor_userUpload
  }
}
    ${OrganizationCustomFieldsForImporterPageFragmentDoc}
${FileCustomTransformEditor_UserUploadFragmentDoc}`;

export function useFileCustomTransformEditorQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFileCustomTransformEditorQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFileCustomTransformEditorQuery,
    GQTypes.GQFileCustomTransformEditorQueryVariables
  >({ query: FileCustomTransformEditorDocument, ...options });
}
export const PublishCustomTransformForUserUploadDocument = gql`
    mutation PublishCustomTransformForUserUpload($input: PublishCustomTransformForUserUploadInput!) @withOwner(owner: DataIngestion) {
  publishCustomTransformForUserUpload(input: $input) {
    jobId
  }
}
    `;

export function usePublishCustomTransformForUserUploadMutation() {
  return Urql.useMutation<
    GQTypes.GQPublishCustomTransformForUserUploadMutation,
    GQTypes.GQPublishCustomTransformForUserUploadMutationVariables
  >(PublishCustomTransformForUserUploadDocument);
}
export const RunCtsTransformEditorDocument = gql`
    mutation RunCtsTransformEditor($input: RunCtsTransformEditorDashboardInput!) @withOwner(owner: DataIngestion) {
  runCtsTransformEditor(input: $input) {
    workflowId
  }
}
    `;

export function useRunCtsTransformEditorMutation() {
  return Urql.useMutation<
    GQTypes.GQRunCtsTransformEditorMutation,
    GQTypes.GQRunCtsTransformEditorMutationVariables
  >(RunCtsTransformEditorDocument);
}
export const CreateOneSchemaSessionForUserUploadDocument = gql`
    mutation CreateOneSchemaSessionForUserUpload($input: CreateOneSchemaSessionForUserUploadInput!) @withOwner(owner: DataIngestion) {
  createOneSchemaSessionForUserUpload(input: $input) {
    ...EditSessionFields
  }
}
    ${EditSessionFieldsFragmentDoc}`;

export function useCreateOneSchemaSessionForUserUploadMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateOneSchemaSessionForUserUploadMutation,
    GQTypes.GQCreateOneSchemaSessionForUserUploadMutationVariables
  >(CreateOneSchemaSessionForUserUploadDocument);
}
export const GetValueMappingRulesForMeasurementProjectDocument = gql`
    query GetValueMappingRulesForMeasurementProject($measurementProjectId: ID!) @withOwner(owner: DataIngestion) {
  measurementProject(id: $measurementProjectId) {
    id
    supportedValueMappingRules
  }
}
    `;

export function useGetValueMappingRulesForMeasurementProjectQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetValueMappingRulesForMeasurementProjectQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetValueMappingRulesForMeasurementProjectQuery,
    GQTypes.GQGetValueMappingRulesForMeasurementProjectQueryVariables
  >({ query: GetValueMappingRulesForMeasurementProjectDocument, ...options });
}
export const MarkUserUploadTaskAsCompleteDocument = gql`
    mutation MarkUserUploadTaskAsComplete($userUploadTaskId: ID!) @withOwner(owner: DataIngestion) {
  completeUserUploadTask(input: {userUploadTaskId: $userUploadTaskId}) {
    userUploadTask {
      id
      state
      userUploads {
        edges {
          node {
            id
            status
            processingMode
          }
        }
      }
      measurementTask {
        id
        statusSimplified
      }
    }
  }
}
    `;

export function useMarkUserUploadTaskAsCompleteMutation() {
  return Urql.useMutation<
    GQTypes.GQMarkUserUploadTaskAsCompleteMutation,
    GQTypes.GQMarkUserUploadTaskAsCompleteMutationVariables
  >(MarkUserUploadTaskAsCompleteDocument);
}
export const BuildingsTaskFacilitiesDataGridDocument = gql`
    query BuildingsTaskFacilitiesDataGrid @withOwner(owner: DataIngestion) {
  organization @_required {
    id
    ingestionCustomFields {
      ...BuildingsTaskFacilitiesDataGrid_ingestionCustomField
    }
  }
}
    ${BuildingsTaskFacilitiesDataGrid_IngestionCustomFieldFragmentDoc}`;

export function useBuildingsTaskFacilitiesDataGridQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQBuildingsTaskFacilitiesDataGridQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBuildingsTaskFacilitiesDataGridQuery,
    GQTypes.GQBuildingsTaskFacilitiesDataGridQueryVariables
  >({ query: BuildingsTaskFacilitiesDataGridDocument, ...options });
}
export const DeleteUserUploadTaskDocument = gql`
    mutation DeleteUserUploadTask($input: DeleteUserUploadTaskInput!) @withOwner(owner: DataIngestion) {
  deleteUserUploadTask(input: $input) {
    measurementProject {
      ...DashboardMeasurementProjectFieldsForOrgPage
    }
  }
}
    ${DashboardMeasurementProjectFieldsForOrgPageFragmentDoc}`;

export function useDeleteUserUploadTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteUserUploadTaskMutation,
    GQTypes.GQDeleteUserUploadTaskMutationVariables
  >(DeleteUserUploadTaskDocument);
}
export const CompleteUserUploadTaskDashboardDocument = gql`
    mutation CompleteUserUploadTaskDashboard($input: CompleteUserUploadTaskInput!) @withOwner(owner: DataIngestion) {
  completeUserUploadTask(input: $input) {
    userUploadTask {
      id
      state
    }
    measurementProject {
      ...MeasurementProjectFieldsForDatasourcePage
    }
  }
}
    ${MeasurementProjectFieldsForDatasourcePageFragmentDoc}`;

export function useCompleteUserUploadTaskDashboardMutation() {
  return Urql.useMutation<
    GQTypes.GQCompleteUserUploadTaskDashboardMutation,
    GQTypes.GQCompleteUserUploadTaskDashboardMutationVariables
  >(CompleteUserUploadTaskDashboardDocument);
}
export const UncompleteUserUploadTaskDashboardDocument = gql`
    mutation UncompleteUserUploadTaskDashboard($input: UncompleteUserUploadTaskInput!) @withOwner(owner: DataIngestion) {
  uncompleteUserUploadTask(input: $input) {
    userUploadTask {
      id
      state
    }
    measurementProject {
      ...MeasurementProjectFieldsForDatasourcePage
    }
  }
}
    ${MeasurementProjectFieldsForDatasourcePageFragmentDoc}`;

export function useUncompleteUserUploadTaskDashboardMutation() {
  return Urql.useMutation<
    GQTypes.GQUncompleteUserUploadTaskDashboardMutation,
    GQTypes.GQUncompleteUserUploadTaskDashboardMutationVariables
  >(UncompleteUserUploadTaskDashboardDocument);
}
export const UserUploadTaskFacilitiesDocument = gql`
    query UserUploadTaskFacilities($after: String, $before: String, $first: Int, $last: Int, $filterWithOperator: FacilitiesFilter, $sort: [FacilitiesSortInput!], $userUploadTaskId: ID!, $measurementProjectId: ID!, $includeDeleted: Boolean) @withOwner(owner: DataIngestion) {
  facilities(
    after: $after
    before: $before
    first: $first
    last: $last
    filterWithOperator: $filterWithOperator
    sort: $sort
    includeDeleted: $includeDeleted
  ) @_required {
    edges {
      node {
        id
        ...UtilitiesTaskFacilitiesDataGrid_facilities
        ...BuildingsTaskFacilitiesDataGrid_facilities
      }
    }
    pageInfo {
      ...UtilitiesTaskFacilitiesDataGrid_pageInfo
      ...BuildingsTaskFacilitiesDataGrid_pageInfo
    }
  }
}
    ${UtilitiesTaskFacilitiesDataGrid_FacilitiesFragmentDoc}
${BuildingsTaskFacilitiesDataGrid_FacilitiesFragmentDoc}
${UtilitiesTaskFacilitiesDataGrid_PageInfoFragmentDoc}
${BuildingsTaskFacilitiesDataGrid_PageInfoFragmentDoc}`;

export function useUserUploadTaskFacilitiesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQUserUploadTaskFacilitiesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQUserUploadTaskFacilitiesQuery,
    GQTypes.GQUserUploadTaskFacilitiesQueryVariables
  >({ query: UserUploadTaskFacilitiesDocument, ...options });
}
export const DatasourceDialogDocument = gql`
    query DatasourceDialog($datasourceId: ID!, $measurementProjectId: ID!, $userUploadLimit: Int) @withOwner(owner: DataIngestion) {
  userUploadTaskForDatasource(
    datasourceId: $datasourceId
    measurementProjectId: $measurementProjectId
  ) {
    ...UserUploadTaskWithDatasource
    ...DatasourceSettingsDialog_userUploadTask
  }
}
    ${UserUploadTaskWithDatasourceFragmentDoc}
${DatasourceSettingsDialog_UserUploadTaskFragmentDoc}`;

export function useDatasourceDialogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDatasourceDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDatasourceDialogQuery,
    GQTypes.GQDatasourceDialogQueryVariables
  >({ query: DatasourceDialogDocument, ...options });
}
export const ApproveDataDocument = gql`
    mutation ApproveData($input: ApproveDataInput!) @withOwner(owner: DataIngestion) {
  approveData(input: $input) {
    target {
      ...ApprovalTargetFieldsForChangingStatus
    }
    newChangelogEvents {
      ...ChangelogEventParts
    }
  }
}
    ${ApprovalTargetFieldsForChangingStatusFragmentDoc}
${ChangelogEventPartsFragmentDoc}`;

export function useApproveDataMutation() {
  return Urql.useMutation<
    GQTypes.GQApproveDataMutation,
    GQTypes.GQApproveDataMutationVariables
  >(ApproveDataDocument);
}
export const RejectDataDocument = gql`
    mutation RejectData($input: RejectDataInput!) @withOwner(owner: DataIngestion) {
  rejectData(input: $input) {
    target {
      ...ApprovalTargetFieldsForChangingStatus
    }
    newChangelogEvents {
      ...ChangelogEventParts
    }
  }
}
    ${ApprovalTargetFieldsForChangingStatusFragmentDoc}
${ChangelogEventPartsFragmentDoc}`;

export function useRejectDataMutation() {
  return Urql.useMutation<
    GQTypes.GQRejectDataMutation,
    GQTypes.GQRejectDataMutationVariables
  >(RejectDataDocument);
}
export const RequestDataApprovalDocument = gql`
    mutation RequestDataApproval($input: RequestDataApprovalInput!) @withOwner(owner: DataIngestion) {
  requestDataApproval(input: $input) {
    target {
      ...ApprovalTargetFieldsForChangingStatus
    }
    newChangelogEvents {
      ...ChangelogEventParts
    }
  }
}
    ${ApprovalTargetFieldsForChangingStatusFragmentDoc}
${ChangelogEventPartsFragmentDoc}`;

export function useRequestDataApprovalMutation() {
  return Urql.useMutation<
    GQTypes.GQRequestDataApprovalMutation,
    GQTypes.GQRequestDataApprovalMutationVariables
  >(RequestDataApprovalDocument);
}
export const DataApprovalUserUploadTaskChangelogDocument = gql`
    query DataApprovalUserUploadTaskChangelog($input: DataApprovalUserUploadTaskChangelogInput!) @withOwner(owner: DataIngestion) {
  dataApprovalUserUploadTaskChangelog(input: $input) {
    changelogEvents {
      ...ChangelogEventParts
    }
  }
}
    ${ChangelogEventPartsFragmentDoc}`;

export function useDataApprovalUserUploadTaskChangelogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDataApprovalUserUploadTaskChangelogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDataApprovalUserUploadTaskChangelogQuery,
    GQTypes.GQDataApprovalUserUploadTaskChangelogQueryVariables
  >({ query: DataApprovalUserUploadTaskChangelogDocument, ...options });
}
export const DataApprovalObjectChangelogDocument = gql`
    query DataApprovalObjectChangelog($input: DataApprovalObjectChangelogInput!) @withOwner(owner: DataIngestion) {
  dataApprovalObjectChangelog(input: $input) {
    changelogEvents {
      ...ChangelogEventParts
    }
  }
}
    ${ChangelogEventPartsFragmentDoc}`;

export function useDataApprovalObjectChangelogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDataApprovalObjectChangelogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDataApprovalObjectChangelogQuery,
    GQTypes.GQDataApprovalObjectChangelogQueryVariables
  >({ query: DataApprovalObjectChangelogDocument, ...options });
}
export const DataApprovalMeasurementProjectChangelogDocument = gql`
    query DataApprovalMeasurementProjectChangelog($input: DataApprovalMeasurementProjectChangelogInput!) @withOwner(owner: DataIngestion) {
  dataApprovalMeasurementProjectChangelog(input: $input) {
    changelogEvents {
      ...ChangelogEventParts
      datasourceName
      datasetName
    }
  }
}
    ${ChangelogEventPartsFragmentDoc}`;

export function useDataApprovalMeasurementProjectChangelogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDataApprovalMeasurementProjectChangelogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDataApprovalMeasurementProjectChangelogQuery,
    GQTypes.GQDataApprovalMeasurementProjectChangelogQueryVariables
  >({ query: DataApprovalMeasurementProjectChangelogDocument, ...options });
}
export const AddDataApprovalCommentDocument = gql`
    mutation AddDataApprovalComment($input: AddDataApprovalCommentInput!) @withOwner(owner: DataIngestion) {
  addDataApprovalComment(input: $input) {
    dataIssue {
      id
    }
    newChangelogEvents {
      ...ChangelogEventParts
    }
  }
}
    ${ChangelogEventPartsFragmentDoc}`;

export function useAddDataApprovalCommentMutation() {
  return Urql.useMutation<
    GQTypes.GQAddDataApprovalCommentMutation,
    GQTypes.GQAddDataApprovalCommentMutationVariables
  >(AddDataApprovalCommentDocument);
}
export const UnlockDataDocument = gql`
    mutation UnlockData($input: UnlockDataInput!) @withOwner(owner: DataIngestion) {
  unlockData(input: $input) {
    target {
      ...ApprovalTargetFieldsForChangingStatus
    }
    newChangelogEvents {
      ...ChangelogEventParts
    }
  }
}
    ${ApprovalTargetFieldsForChangingStatusFragmentDoc}
${ChangelogEventPartsFragmentDoc}`;

export function useUnlockDataMutation() {
  return Urql.useMutation<
    GQTypes.GQUnlockDataMutation,
    GQTypes.GQUnlockDataMutationVariables
  >(UnlockDataDocument);
}
export const RequestUnlockDataDocument = gql`
    mutation RequestUnlockData($input: RequestUnlockDataInput!) @withOwner(owner: DataIngestion) {
  requestUnlockData(input: $input) {
    target {
      ...ApprovalTargetFieldsForChangingStatus
    }
    newChangelogEvents {
      ...ChangelogEventParts
    }
  }
}
    ${ApprovalTargetFieldsForChangingStatusFragmentDoc}
${ChangelogEventPartsFragmentDoc}`;

export function useRequestUnlockDataMutation() {
  return Urql.useMutation<
    GQTypes.GQRequestUnlockDataMutation,
    GQTypes.GQRequestUnlockDataMutationVariables
  >(RequestUnlockDataDocument);
}
export const BuildingFormDocument = gql`
    query BuildingForm @withOwner(owner: DataIngestion) {
  organization {
    id
    buildingNames
    ...OrganizationWithIngestionCustomFields
  }
}
    ${OrganizationWithIngestionCustomFieldsFragmentDoc}`;

export function useBuildingFormQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQBuildingFormQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBuildingFormQuery,
    GQTypes.GQBuildingFormQueryVariables
  >({ query: BuildingFormDocument, ...options });
}
export const CreateBuildingDocument = gql`
    mutation CreateBuilding($input: CreateBuildingInput!) @withOwner(owner: DataIngestion) {
  createBuilding(input: $input) {
    updatedUserUploadTasks {
      ...UserUploadTaskFieldsForBuildingMutationResponse
    }
    organization {
      ...OrganizationWithIngestionCustomFields
      ...OrganizationFacilitiesUntyped
    }
    measurementProject {
      ...MeasurementProjectForManageFacilitiesPage
    }
  }
}
    ${UserUploadTaskFieldsForBuildingMutationResponseFragmentDoc}
${OrganizationWithIngestionCustomFieldsFragmentDoc}
${OrganizationFacilitiesUntypedFragmentDoc}
${MeasurementProjectForManageFacilitiesPageFragmentDoc}`;

export function useCreateBuildingMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateBuildingMutation,
    GQTypes.GQCreateBuildingMutationVariables
  >(CreateBuildingDocument);
}
export const UpdateBuildingDocument = gql`
    mutation UpdateBuilding($input: UpdateBuildingInput!, $measurementProjectId: ID!) @withOwner(owner: DataIngestion) {
  updateBuilding(input: $input) {
    buildingUntyped {
      id
      data
    }
    facility {
      ...SingleFacilityBuildingProperties_facility
    }
    updatedUserUploadTasks {
      ...UserUploadTaskFieldsForBuildingMutationResponse
    }
    organization {
      ...OrganizationWithIngestionCustomFields
    }
  }
}
    ${SingleFacilityBuildingProperties_FacilityFragmentDoc}
${UserUploadTaskFieldsForBuildingMutationResponseFragmentDoc}
${OrganizationWithIngestionCustomFieldsFragmentDoc}`;

export function useUpdateBuildingMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateBuildingMutation,
    GQTypes.GQUpdateBuildingMutationVariables
  >(UpdateBuildingDocument);
}
export const UpdateBuildingUtilityDocument = gql`
    mutation UpdateBuildingUtility($input: UpdateBuildingUtilityInput!) @withOwner(owner: DataIngestion) {
  updateBuildingUtility(input: $input) {
    buildingUtility {
      ...BuildingUtilityFieldsForUtilitiesGridPage
    }
    buildingWithUtilities {
      id
      completionIntervals(separateOnsiteCleanElectricity: true) {
        ...CompletionIntervalFields
      }
    }
    updatedUserUploadTask {
      ...UserUploadTaskForUtilitiesMutationResponse
    }
  }
}
    ${BuildingUtilityFieldsForUtilitiesGridPageFragmentDoc}
${CompletionIntervalFieldsFragmentDoc}
${UserUploadTaskForUtilitiesMutationResponseFragmentDoc}`;

export function useUpdateBuildingUtilityMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateBuildingUtilityMutation,
    GQTypes.GQUpdateBuildingUtilityMutationVariables
  >(UpdateBuildingUtilityDocument);
}
export const CreateBuildingUtilityDocument = gql`
    mutation CreateBuildingUtility($input: CreateBuildingUtilityInput!, $utilityType: BuildingUtilityType, $sortModel: [DataGridSortItemInput!], $before: String, $after: String, $first: Int, $last: Int) @withOwner(owner: DataIngestion) {
  createBuildingUtility(input: $input) {
    buildingUtility {
      id
    }
    buildingWithUtilities {
      id
      utilitiesPaginated(
        utilityType: $utilityType
        sortModel: $sortModel
        before: $before
        after: $after
        first: $first
        last: $last
      ) {
        ...BuildingUtilityConnectionFrago
      }
      completionIntervals(separateOnsiteCleanElectricity: true) {
        ...CompletionIntervalFields
      }
    }
    buildingUntyped {
      id
      data
    }
    updatedUserUploadTask {
      ...UserUploadTaskForUtilitiesMutationResponse
    }
  }
}
    ${BuildingUtilityConnectionFragoFragmentDoc}
${CompletionIntervalFieldsFragmentDoc}
${UserUploadTaskForUtilitiesMutationResponseFragmentDoc}`;

export function useCreateBuildingUtilityMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateBuildingUtilityMutation,
    GQTypes.GQCreateBuildingUtilityMutationVariables
  >(CreateBuildingUtilityDocument);
}
export const DeleteBuildingUtilityDocument = gql`
    mutation DeleteBuildingUtility($input: DeleteBuildingUtilityInput!, $utilityType: BuildingUtilityType, $sortModel: [DataGridSortItemInput!], $before: String, $after: String, $first: Int, $last: Int) @withOwner(owner: DataIngestion) {
  deleteBuildingUtility(input: $input) {
    buildingWithUtilities {
      id
      utilitiesPaginated(
        utilityType: $utilityType
        sortModel: $sortModel
        before: $before
        after: $after
        first: $first
        last: $last
      ) {
        ...BuildingUtilityConnectionFrago
      }
      completionIntervals(separateOnsiteCleanElectricity: true) {
        ...CompletionIntervalFields
      }
    }
    buildingUntyped {
      id
      data
    }
    updatedUserUploadTask {
      ...UserUploadTaskForUtilitiesMutationResponse
    }
  }
}
    ${BuildingUtilityConnectionFragoFragmentDoc}
${CompletionIntervalFieldsFragmentDoc}
${UserUploadTaskForUtilitiesMutationResponseFragmentDoc}`;

export function useDeleteBuildingUtilityMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteBuildingUtilityMutation,
    GQTypes.GQDeleteBuildingUtilityMutationVariables
  >(DeleteBuildingUtilityDocument);
}
export const BuildingUtilitiesDialogDocument = gql`
    query BuildingUtilitiesDialog($buildingId: ID!) @withOwner(owner: DataIngestion) {
  buildingUntyped(id: $buildingId) {
    id
    data
  }
  buildingWithUtilities(id: $buildingId) {
    id
    completionIntervals(separateOnsiteCleanElectricity: true) {
      ...CompletionIntervalFields
    }
  }
}
    ${CompletionIntervalFieldsFragmentDoc}`;

export function useBuildingUtilitiesDialogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBuildingUtilitiesDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBuildingUtilitiesDialogQuery,
    GQTypes.GQBuildingUtilitiesDialogQueryVariables
  >({ query: BuildingUtilitiesDialogDocument, ...options });
}
export const PaginatedUtilityRowsDocument = gql`
    query PaginatedUtilityRows($buildingId: ID!, $utilityType: BuildingUtilityType, $sortModel: [DataGridSortItemInput!], $before: String, $after: String, $first: Int, $last: Int, $userUploadTaskId: ID, $notUserUploadTaskId: ID) @withOwner(owner: DataIngestion) {
  buildingWithUtilities(id: $buildingId) {
    id
    utilitiesPaginated(
      utilityType: $utilityType
      userUploadTaskId: $userUploadTaskId
      notUserUploadTaskId: $notUserUploadTaskId
      sortModel: $sortModel
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      ...BuildingUtilityConnectionFrago
    }
  }
}
    ${BuildingUtilityConnectionFragoFragmentDoc}`;

export function usePaginatedUtilityRowsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQPaginatedUtilityRowsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPaginatedUtilityRowsQuery,
    GQTypes.GQPaginatedUtilityRowsQueryVariables
  >({ query: PaginatedUtilityRowsDocument, ...options });
}
export const SubmitBuildingsDocument = gql`
    mutation SubmitBuildings($input: SubmitFacilitiesInput!) @withOwner(owner: DataIngestion) {
  submitBuildings(input: $input) {
    measurementTask {
      ...MeasurementParentTaskForMeasurementPage
    }
    submittedUserUploadTask {
      ...UserUploadTaskFieldsForBuildingMutationResponse
    }
  }
}
    ${MeasurementParentTaskForMeasurementPageFragmentDoc}
${UserUploadTaskFieldsForBuildingMutationResponseFragmentDoc}`;

export function useSubmitBuildingsMutation() {
  return Urql.useMutation<
    GQTypes.GQSubmitBuildingsMutation,
    GQTypes.GQSubmitBuildingsMutationVariables
  >(SubmitBuildingsDocument);
}
export const SubmitUtilitiesDocument = gql`
    mutation SubmitUtilities($input: SubmitFacilitiesInput!) @withOwner(owner: DataIngestion) {
  submitUtilities(input: $input) {
    measurementTask {
      ...MeasurementParentTaskForMeasurementPage
    }
    submittedUserUploadTask {
      ...UserUploadTaskFieldsForBuildingMutationResponse
    }
  }
}
    ${MeasurementParentTaskForMeasurementPageFragmentDoc}
${UserUploadTaskFieldsForBuildingMutationResponseFragmentDoc}`;

export function useSubmitUtilitiesMutation() {
  return Urql.useMutation<
    GQTypes.GQSubmitUtilitiesMutation,
    GQTypes.GQSubmitUtilitiesMutationVariables
  >(SubmitUtilitiesDocument);
}
export const GetBuildingSupportingDocumentsDocument = gql`
    query GetBuildingSupportingDocuments($id: ID!) @withOwner(owner: DataIngestion) {
  buildingUntyped(id: $id) {
    id
    data
  }
}
    `;

export function useGetBuildingSupportingDocumentsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetBuildingSupportingDocumentsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetBuildingSupportingDocumentsQuery,
    GQTypes.GQGetBuildingSupportingDocumentsQueryVariables
  >({ query: GetBuildingSupportingDocumentsDocument, ...options });
}
export const CreateBuildingSupportingDocumentsDocument = gql`
    mutation CreateBuildingSupportingDocuments($input: CreateSupportingDocumentsInput!) @withOwner(owner: DataIngestion) {
  createSupportingDocuments(input: $input) {
    supportingDocuments {
      ...BuildingFormSupportingDocument
    }
    buildingUntyped {
      id
      data
    }
    files {
      url
      fileId
      filename
    }
    buildingUtility {
      id
      supportingDocuments {
        ...BuildingFormSupportingDocument
      }
    }
  }
}
    ${BuildingFormSupportingDocumentFragmentDoc}`;

export function useCreateBuildingSupportingDocumentsMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateBuildingSupportingDocumentsMutation,
    GQTypes.GQCreateBuildingSupportingDocumentsMutationVariables
  >(CreateBuildingSupportingDocumentsDocument);
}
export const DeleteBuildingSupportingDocumentDocument = gql`
    mutation DeleteBuildingSupportingDocument($input: DeleteSupportingDocumentInput!) @withOwner(owner: DataIngestion) {
  deleteSupportingDocument(input: $input) {
    buildingUntyped {
      id
      data
    }
    supportingDocument {
      id
    }
  }
}
    `;

export function useDeleteBuildingSupportingDocumentMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteBuildingSupportingDocumentMutation,
    GQTypes.GQDeleteBuildingSupportingDocumentMutationVariables
  >(DeleteBuildingSupportingDocumentDocument);
}
export const ActivityDataForDataReviewDocument = gql`
    query ActivityDataForDataReview($input: BiActivityQueryContextInput!) @withOwner(owner: DataIngestion) {
  activityContextMetadata(input: $input) {
    activityDatasets {
      ...ActivityDatasetForDataReview
    }
  }
}
    ${ActivityDatasetForDataReviewFragmentDoc}`;

export function useActivityDataForDataReviewQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQActivityDataForDataReviewQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQActivityDataForDataReviewQuery,
    GQTypes.GQActivityDataForDataReviewQueryVariables
  >({ query: ActivityDataForDataReviewDocument, ...options });
}
export const CustomerTargetSchemaVersionForDirectEntryDocument = gql`
    query CustomerTargetSchemaVersionForDirectEntry($customerTargetSchemaId: ID!, $ctsVersionId: ID!, $datasetId: ID!, $datasourceId: ID, $measurementProjectId: ID, $buildingId: ID) @withOwner(owner: DataIngestion) {
  customerTargetSchemaVersion(
    customerTargetSchemaId: $customerTargetSchemaId
    ctsVersionId: $ctsVersionId
  ) {
    id
    orgSchemaJson(
      datasetId: $datasetId
      datasourceId: $datasourceId
      measurementProjectId: $measurementProjectId
      buildingId: $buildingId
    )
  }
}
    `;

export function useCustomerTargetSchemaVersionForDirectEntryQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCustomerTargetSchemaVersionForDirectEntryQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCustomerTargetSchemaVersionForDirectEntryQuery,
    GQTypes.GQCustomerTargetSchemaVersionForDirectEntryQueryVariables
  >({ query: CustomerTargetSchemaVersionForDirectEntryDocument, ...options });
}
export const CreateAndSubmitUserUploadCandidateDocument = gql`
    mutation CreateAndSubmitUserUploadCandidate($input: CreateAndSubmitUserUploadCandidateInput!) @withOwner(owner: DataIngestion) {
  createAndSubmitUserUploadCandidate(input: $input) {
    userUploadCandidate {
      id
      errorCount
      errorData
    }
    userUploadCandidateSubmission {
      id
      processFileWorkflowId
    }
  }
}
    `;

export function useCreateAndSubmitUserUploadCandidateMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateAndSubmitUserUploadCandidateMutation,
    GQTypes.GQCreateAndSubmitUserUploadCandidateMutationVariables
  >(CreateAndSubmitUserUploadCandidateDocument);
}
export const FacilitiesForDirectEntryDialogDocument = gql`
    query FacilitiesForDirectEntryDialog($measurementProjectId: ID!, $filter: [FacilitiesFilterInput!], $orgId: ID!, $userUploadTaskId: ID) @withOwner(owner: DataIngestion) {
  facilities(filter: $filter, measurementProjectId: $measurementProjectId) {
    edges {
      node {
        ...FacilityForDirectEntryDialog
      }
    }
  }
  measurementProject(id: $measurementProjectId) {
    id
    coverageInterval
  }
  organization(id: $orgId) {
    id
    ingestionCustomFields {
      ...CustomColumnsForDirectEntry
    }
  }
}
    ${FacilityForDirectEntryDialogFragmentDoc}
${CustomColumnsForDirectEntryFragmentDoc}`;

export function useFacilitiesForDirectEntryDialogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFacilitiesForDirectEntryDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFacilitiesForDirectEntryDialogQuery,
    GQTypes.GQFacilitiesForDirectEntryDialogQueryVariables
  >({ query: FacilitiesForDirectEntryDialogDocument, ...options });
}
export const DirectEntryDialogPrimaryFacilityDocument = gql`
    query DirectEntryDialogPrimaryFacility($facilityId: ID!) @withOwner(owner: DataIngestion) {
  facility(id: $facilityId) {
    id
    buildingName
    streetAddress
  }
}
    `;

export function useDirectEntryDialogPrimaryFacilityQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDirectEntryDialogPrimaryFacilityQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDirectEntryDialogPrimaryFacilityQuery,
    GQTypes.GQDirectEntryDialogPrimaryFacilityQueryVariables
  >({ query: DirectEntryDialogPrimaryFacilityDocument, ...options });
}
export const DirectEntryDialogDocument = gql`
    query DirectEntryDialog($measurementProjectId: ID!, $datasetId: ID!) @withOwner(owner: DataIngestion) {
  measurementProject(id: $measurementProjectId) {
    id
    coverageInterval
  }
  dataset(id: $datasetId) {
    ...DirectEntryDialog_dataset
  }
}
    ${DirectEntryDialog_DatasetFragmentDoc}`;

export function useDirectEntryDialogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDirectEntryDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDirectEntryDialogQuery,
    GQTypes.GQDirectEntryDialogQueryVariables
  >({ query: DirectEntryDialogDocument, ...options });
}
export const GenerateTemplateDownloadUrlDocument = gql`
    mutation GenerateTemplateDownloadUrl($input: GenerateTemplateDownloadUrlForDatasetInput!) @withOwner(owner: DataIngestion) {
  generateTemplateDownloadUrlForDataset(input: $input) {
    templateDownloadUrl
  }
}
    `;

export function useGenerateTemplateDownloadUrlMutation() {
  return Urql.useMutation<
    GQTypes.GQGenerateTemplateDownloadUrlMutation,
    GQTypes.GQGenerateTemplateDownloadUrlMutationVariables
  >(GenerateTemplateDownloadUrlDocument);
}
export const UpdateUserIngestionReviewDocument = gql`
    mutation UpdateUserIngestionReview($input: UpdateUserIngestionReviewInput!) @withOwner(owner: DataIngestion) {
  updateUserIngestionReview(input: $input) {
    userUploadedTable {
      id
      ...UserUploadedTableWithDataPreview
    }
  }
}
    ${UserUploadedTableWithDataPreviewFragmentDoc}`;

export function useUpdateUserIngestionReviewMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateUserIngestionReviewMutation,
    GQTypes.GQUpdateUserIngestionReviewMutationVariables
  >(UpdateUserIngestionReviewDocument);
}
export const UpdateUserUploadDocument = gql`
    mutation UpdateUserUpload($fileId: ID!, $description: String) @withOwner(owner: DataIngestion) {
  updateUserUpload(input: {fileId: $fileId, description: $description}) {
    userUpload {
      id
      description
    }
  }
}
    `;

export function useUpdateUserUploadMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateUserUploadMutation,
    GQTypes.GQUpdateUserUploadMutationVariables
  >(UpdateUserUploadDocument);
}
export const SubmitEarlyUploadHelpRequestDocument = gql`
    mutation SubmitEarlyUploadHelpRequest($input: SubmitEarlyUploadHelpRequestInput!, $measurementProjectId: ID, $userUploadLimit: Int) @withOwner(owner: DataIngestion) {
  submitEarlyUploadHelpRequest(input: $input) {
    userUploads {
      id
      userUploadTask {
        ...UserUploadTaskWithDatasource
      }
    }
  }
}
    ${UserUploadTaskWithDatasourceFragmentDoc}`;

export function useSubmitEarlyUploadHelpRequestMutation() {
  return Urql.useMutation<
    GQTypes.GQSubmitEarlyUploadHelpRequestMutation,
    GQTypes.GQSubmitEarlyUploadHelpRequestMutationVariables
  >(SubmitEarlyUploadHelpRequestDocument);
}
export const ImportGetUserUploadsByIdsDocument = gql`
    query ImportGetUserUploadsByIds($userUploadIds: [ID!]!) @withOwner(owner: DataIngestion) {
  userUploadsById(ids: $userUploadIds) {
    ...ImportImport_UserUpload
    userUploadTask {
      ...UserUploadTaskStatus
    }
  }
}
    ${ImportImport_UserUploadFragmentDoc}
${UserUploadTaskStatusFragmentDoc}`;

export function useImportGetUserUploadsByIdsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQImportGetUserUploadsByIdsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQImportGetUserUploadsByIdsQuery,
    GQTypes.GQImportGetUserUploadsByIdsQueryVariables
  >({ query: ImportGetUserUploadsByIdsDocument, ...options });
}
export const ImportCreateUserUploadsDocument = gql`
    mutation ImportCreateUserUploads($input: CreateUserUploadsInput!) @withOwner(owner: DataIngestion) {
  createUserUploads(input: $input) {
    files {
      url
      fileId
      filename
    }
    userUploads {
      ...ImportImport_UserUpload
    }
    failedToUpload {
      filename
      reason
    }
  }
}
    ${ImportImport_UserUploadFragmentDoc}`;

export function useImportCreateUserUploadsMutation() {
  return Urql.useMutation<
    GQTypes.GQImportCreateUserUploadsMutation,
    GQTypes.GQImportCreateUserUploadsMutationVariables
  >(ImportCreateUserUploadsDocument);
}
export const ImportDeleteFileDocument = gql`
    mutation ImportDeleteFile($fileId: ID!) @withOwner(owner: DataIngestion) {
  deleteFile(input: {fileId: $fileId}) {
    userUploadTask {
      ...UserUploadTaskStatus
    }
  }
}
    ${UserUploadTaskStatusFragmentDoc}`;

export function useImportDeleteFileMutation() {
  return Urql.useMutation<
    GQTypes.GQImportDeleteFileMutation,
    GQTypes.GQImportDeleteFileMutationVariables
  >(ImportDeleteFileDocument);
}
export const SetUserUploadedTableSheetsNotIngestedDocument = gql`
    mutation SetUserUploadedTableSheetsNotIngested($input: SetUserUploadedTableSheetsNotIngestedInput!) @withOwner(owner: DataIngestion) {
  setUserUploadedTableSheetsNotIngested(input: $input) {
    userUploadAttempt {
      id
      userUploadedTables {
        id
      }
    }
  }
}
    `;

export function useSetUserUploadedTableSheetsNotIngestedMutation() {
  return Urql.useMutation<
    GQTypes.GQSetUserUploadedTableSheetsNotIngestedMutation,
    GQTypes.GQSetUserUploadedTableSheetsNotIngestedMutationVariables
  >(SetUserUploadedTableSheetsNotIngestedDocument);
}
export const ImportSetupDocument = gql`
    query ImportSetup($datasetId: ID!) @withOwner(owner: DataIngestion) {
  dataset(id: $datasetId) {
    id
    name
    canonicalDataset {
      id
      canonicalDatasources {
        id
        name
        activeIntegrationConnection {
          id
        }
      }
    }
  }
}
    `;

export function useImportSetupQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQImportSetupQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQImportSetupQuery,
    GQTypes.GQImportSetupQueryVariables
  >({ query: ImportSetupDocument, ...options });
}
export const ImportGetSharedFilesDocument = gql`
    query ImportGetSharedFiles($datasourceId: ID!, $currentUserId: ID!) @withOwner(owner: DataIngestion) {
  datasource(id: $datasourceId) {
    id
    sharedFiles(forUserId: $currentUserId) {
      edges {
        node {
          ...SharedFileForImportImport
        }
      }
    }
  }
}
    ${SharedFileForImportImportFragmentDoc}`;

export function useImportGetSharedFilesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQImportGetSharedFilesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQImportGetSharedFilesQuery,
    GQTypes.GQImportGetSharedFilesQueryVariables
  >({ query: ImportGetSharedFilesDocument, ...options });
}
export const ImportMarkUserUploadAsProcessingDocument = gql`
    mutation ImportMarkUserUploadAsProcessing($fileId: ID!, $shouldSubmitUserUploadTask: Boolean!) @withOwner(owner: DataIngestion) {
  markUserUploadAsProcessing(
    input: {fileId: $fileId, shouldSubmitUserUploadTask: $shouldSubmitUserUploadTask}
  ) {
    userUpload {
      id
      status
    }
  }
}
    `;

export function useImportMarkUserUploadAsProcessingMutation() {
  return Urql.useMutation<
    GQTypes.GQImportMarkUserUploadAsProcessingMutation,
    GQTypes.GQImportMarkUserUploadAsProcessingMutationVariables
  >(ImportMarkUserUploadAsProcessingDocument);
}
export const GenerateBartFromFileUploadDocument = gql`
    mutation GenerateBartFromFileUpload($input: GenerateBartFromFileUploadInput!, $userUploadLimit: Int) @withOwner(owner: DataIngestion) {
  generateBartFromFileUpload(input: $input) {
    jobId
    userUploadTask {
      ...UserUploadTaskFieldsForDatasourcePage
    }
  }
}
    ${UserUploadTaskFieldsForDatasourcePageFragmentDoc}`;

export function useGenerateBartFromFileUploadMutation() {
  return Urql.useMutation<
    GQTypes.GQGenerateBartFromFileUploadMutation,
    GQTypes.GQGenerateBartFromFileUploadMutationVariables
  >(GenerateBartFromFileUploadDocument);
}
export const SampledFinancialAccountIdsDocument = gql`
    query SampledFinancialAccountIds @withOwner(owner: DataIngestion) {
  sampledFinancialAccountIds
}
    `;

export function useSampledFinancialAccountIdsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQSampledFinancialAccountIdsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSampledFinancialAccountIdsQuery,
    GQTypes.GQSampledFinancialAccountIdsQueryVariables
  >({ query: SampledFinancialAccountIdsDocument, ...options });
}
export const CustomEmissionsSpecifiersForDashboardDocument = gql`
    query CustomEmissionsSpecifiersForDashboard($measurementProjectId: ID!) @withOwner(owner: DataIngestion) {
  customEmissionsSpecifiersForOrg(measurementProjectId: $measurementProjectId)
}
    `;

export function useCustomEmissionsSpecifiersForDashboardQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCustomEmissionsSpecifiersForDashboardQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCustomEmissionsSpecifiersForDashboardQuery,
    GQTypes.GQCustomEmissionsSpecifiersForDashboardQueryVariables
  >({ query: CustomEmissionsSpecifiersForDashboardDocument, ...options });
}
export const GetOneSchemaEmbedStatusDocument = gql`
    query GetOneSchemaEmbedStatus($embedId: String!) @withOwner(owner: DataIngestion) {
  getOneSchemaEmbedStatus(embedId: $embedId)
}
    `;

export function useGetOneSchemaEmbedStatusQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetOneSchemaEmbedStatusQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetOneSchemaEmbedStatusQuery,
    GQTypes.GQGetOneSchemaEmbedStatusQueryVariables
  >({ query: GetOneSchemaEmbedStatusDocument, ...options });
}
export const SaveIncompleteOneSchemaImportDocument = gql`
    mutation SaveIncompleteOneSchemaImport($input: SaveIncompleteOneSchemaImportInput!, $userUploadLimit: Int) @withOwner(owner: DataIngestion) {
  saveIncompleteOneSchemaImport(input: $input) {
    jobId
    userUploadTask {
      ...UserUploadTaskFieldsForDatasourcePage
    }
  }
}
    ${UserUploadTaskFieldsForDatasourcePageFragmentDoc}`;

export function useSaveIncompleteOneSchemaImportMutation() {
  return Urql.useMutation<
    GQTypes.GQSaveIncompleteOneSchemaImportMutation,
    GQTypes.GQSaveIncompleteOneSchemaImportMutationVariables
  >(SaveIncompleteOneSchemaImportDocument);
}
export const CreateUtilityDocsDocument = gql`
    mutation CreateUtilityDocs($input: CreateUtilityDocsInput!) @withOwner(owner: DataIngestion) {
  createUtilityDocs(input: $input) {
    supportingDocuments {
      ...BuildingFormSupportingDocument
    }
    files {
      url
      fileId
      filename
    }
    failedToUpload {
      filename
      reason
    }
    candidateIds
  }
}
    ${BuildingFormSupportingDocumentFragmentDoc}`;

export function useCreateUtilityDocsMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateUtilityDocsMutation,
    GQTypes.GQCreateUtilityDocsMutationVariables
  >(CreateUtilityDocsDocument);
}
export const ExecuteExtractSchemasDocument = gql`
    mutation ExecuteExtractSchemas($input: ExecuteExtractSchemasInput!) @withOwner(owner: DataIngestion) {
  executeExtractSchemas(input: $input) {
    success
  }
}
    `;

export function useExecuteExtractSchemasMutation() {
  return Urql.useMutation<
    GQTypes.GQExecuteExtractSchemasMutation,
    GQTypes.GQExecuteExtractSchemasMutationVariables
  >(ExecuteExtractSchemasDocument);
}
export const PdfDocumentReviewFacilitiesDocument = gql`
    query PDFDocumentReviewFacilities($filter: [FacilitiesFilterInput!]) @withOwner(owner: DataIngestion) {
  facilities(filter: $filter) @_required {
    edges {
      node {
        id
        buildingName
        streetAddress
        streetAddress2
        city
        country
      }
    }
  }
}
    `;

export function usePdfDocumentReviewFacilitiesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQPdfDocumentReviewFacilitiesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPdfDocumentReviewFacilitiesQuery,
    GQTypes.GQPdfDocumentReviewFacilitiesQueryVariables
  >({ query: PdfDocumentReviewFacilitiesDocument, ...options });
}
export const GetUserUploadCandidatesDocument = gql`
    query getUserUploadCandidates($userUploadCandidateIds: [ID!]!) @withOwner(owner: DataIngestion) {
  userUploadCandidates(userUploadCandidateIds: $userUploadCandidateIds) {
    candidateEntries {
      ...UserUploadCandidateFields
    }
  }
  documentCandidates(userUploadCandidateIds: $userUploadCandidateIds) {
    documentEntries {
      ...DocumentCandidateFields
    }
  }
}
    ${UserUploadCandidateFieldsFragmentDoc}
${DocumentCandidateFieldsFragmentDoc}`;

export function useGetUserUploadCandidatesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetUserUploadCandidatesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetUserUploadCandidatesQuery,
    GQTypes.GQGetUserUploadCandidatesQueryVariables
  >({ query: GetUserUploadCandidatesDocument, ...options });
}
export const CreateCandidateRowEntryDocument = gql`
    mutation createCandidateRowEntry($input: CreateCandidateRowInput!) @withOwner(owner: DataIngestion) {
  createCandidateRowEntry(input: $input) {
    ...UserUploadCandidateFields
  }
}
    ${UserUploadCandidateFieldsFragmentDoc}`;

export function useCreateCandidateRowEntryMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCandidateRowEntryMutation,
    GQTypes.GQCreateCandidateRowEntryMutationVariables
  >(CreateCandidateRowEntryDocument);
}
export const UpdateCandidateRowEntryDocument = gql`
    mutation updateCandidateRowEntry($input: UpdateCandidateRowInput!) @withOwner(owner: DataIngestion) {
  updateCandidateRowEntry(input: $input) {
    ...UserUploadCandidateFields
  }
}
    ${UserUploadCandidateFieldsFragmentDoc}`;

export function useUpdateCandidateRowEntryMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateCandidateRowEntryMutation,
    GQTypes.GQUpdateCandidateRowEntryMutationVariables
  >(UpdateCandidateRowEntryDocument);
}
export const DeleteCandidateRowEntryDocument = gql`
    mutation deleteCandidateRowEntry($input: DeleteCandidateRowInput!) @withOwner(owner: DataIngestion) {
  deleteCandidateRowEntry(input: $input) {
    ...UserUploadCandidateFields
  }
}
    ${UserUploadCandidateFieldsFragmentDoc}`;

export function useDeleteCandidateRowEntryMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCandidateRowEntryMutation,
    GQTypes.GQDeleteCandidateRowEntryMutationVariables
  >(DeleteCandidateRowEntryDocument);
}
export const ConfirmCandidateDocument = gql`
    mutation confirmCandidate($input: ConfirmUtilitySchemaInput!) @withOwner(owner: DataIngestion) {
  confirmUtilitySchema(input: $input) {
    success
    error
  }
}
    `;

export function useConfirmCandidateMutation() {
  return Urql.useMutation<
    GQTypes.GQConfirmCandidateMutation,
    GQTypes.GQConfirmCandidateMutationVariables
  >(ConfirmCandidateDocument);
}
export const DeleteUserUploadCandidateAndRowsDocument = gql`
    mutation deleteUserUploadCandidateAndRows($input: DeleteUserUploadCandidateAndRowsInput!) @withOwner(owner: DataIngestion) {
  deleteUserUploadCandidateAndRows(input: $input) {
    id
  }
}
    `;

export function useDeleteUserUploadCandidateAndRowsMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteUserUploadCandidateAndRowsMutation,
    GQTypes.GQDeleteUserUploadCandidateAndRowsMutationVariables
  >(DeleteUserUploadCandidateAndRowsDocument);
}
export const ProductPreviewBartBuildingsPushDocument = gql`
    query ProductPreviewBartBuildingsPush($activityDataExtractorId: ID!) @withOwner(owner: DataIngestion) {
  previewCadtBuildingsPush(activityDataExtractorId: $activityDataExtractorId) {
    overwriteDuplicatesDiffUntyped
    mergePeriodsDiffUntyped
    customFields {
      ...CustomFieldForBuldingDiff
    }
    orgUnitTypes {
      ...OrgUnitTypeForBuildingDiff
    }
    orgUnits {
      ...OrgUnitForBuildingDiff
    }
  }
}
    ${CustomFieldForBuldingDiffFragmentDoc}
${OrgUnitTypeForBuildingDiffFragmentDoc}
${OrgUnitForBuildingDiffFragmentDoc}`;

export function useProductPreviewBartBuildingsPushQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQProductPreviewBartBuildingsPushQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQProductPreviewBartBuildingsPushQuery,
    GQTypes.GQProductPreviewBartBuildingsPushQueryVariables
  >({ query: ProductPreviewBartBuildingsPushDocument, ...options });
}
export const PushBartBuildingDocument = gql`
    mutation PushBartBuilding($input: PushBartBuildingInput!) @withOwner(owner: DataIngestion) {
  pushBartBuilding(input: $input) {
    workflowId
  }
}
    `;

export function usePushBartBuildingMutation() {
  return Urql.useMutation<
    GQTypes.GQPushBartBuildingMutation,
    GQTypes.GQPushBartBuildingMutationVariables
  >(PushBartBuildingDocument);
}
export const GetBuildingTemplateDataForTaskDocument = gql`
    query GetBuildingTemplateDataForTask($userUploadTaskId: ID) @withOwner(owner: DataIngestion) {
  buildingTemplateDataUntyped(userUploadTaskId: $userUploadTaskId)
}
    `;

export function useGetBuildingTemplateDataForTaskQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetBuildingTemplateDataForTaskQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetBuildingTemplateDataForTaskQuery,
    GQTypes.GQGetBuildingTemplateDataForTaskQueryVariables
  >({ query: GetBuildingTemplateDataForTaskDocument, ...options });
}
export const PushCtsVersionToOneSchemaInProductDocument = gql`
    mutation PushCtsVersionToOneSchemaInProduct($input: PushCtsVersionToOneSchemaInProductInput!) @withOwner(owner: DataIngestion) {
  pushCtsVersionToOneSchemaInProduct(input: $input) {
    oneSchemaTemplateKey
  }
}
    `;

export function usePushCtsVersionToOneSchemaInProductMutation() {
  return Urql.useMutation<
    GQTypes.GQPushCtsVersionToOneSchemaInProductMutation,
    GQTypes.GQPushCtsVersionToOneSchemaInProductMutationVariables
  >(PushCtsVersionToOneSchemaInProductDocument);
}
export const PreviewUtilitiesDiffDocument = gql`
    mutation PreviewUtilitiesDiff($input: PreviewUtilitiesDiffInput!) @withOwner(owner: DataIngestion) {
  previewUtilitiesDiff(input: $input) {
    workflowId
  }
}
    `;

export function usePreviewUtilitiesDiffMutation() {
  return Urql.useMutation<
    GQTypes.GQPreviewUtilitiesDiffMutation,
    GQTypes.GQPreviewUtilitiesDiffMutationVariables
  >(PreviewUtilitiesDiffDocument);
}
export const PushUtilitiesDiffDocument = gql`
    mutation PushUtilitiesDiff($input: PushUtilitiesDiffInput!) @withOwner(owner: DataIngestion) {
  pushUtilitiesDiff(input: $input) {
    workflowId
  }
}
    `;

export function usePushUtilitiesDiffMutation() {
  return Urql.useMutation<
    GQTypes.GQPushUtilitiesDiffMutation,
    GQTypes.GQPushUtilitiesDiffMutationVariables
  >(PushUtilitiesDiffDocument);
}
export const UtilitiesDiffDialogDocument = gql`
    query UtilitiesDiffDialog @withOwner(owner: DataIngestion) {
  organization @_required {
    ...UtilitiesDiffDialog_organization
  }
}
    ${UtilitiesDiffDialog_OrganizationFragmentDoc}`;

export function useUtilitiesDiffDialogQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQUtilitiesDiffDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQUtilitiesDiffDialogQuery,
    GQTypes.GQUtilitiesDiffDialogQueryVariables
  >({ query: UtilitiesDiffDialogDocument, ...options });
}
export const ImportFlowV3SchemaContextDocument = gql`
    query ImportFlowV3SchemaContext($businessActivityType: String!) @withOwner(owner: DataIngestion) {
  latestCustomerTargetSchemaVersionsForActivityType(
    businessActivityType: $businessActivityType
  ) {
    ...ImportFlowV3_CustomerTargetSchemaVersion
  }
}
    ${ImportFlowV3_CustomerTargetSchemaVersionFragmentDoc}`;

export function useImportFlowV3SchemaContextQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQImportFlowV3SchemaContextQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQImportFlowV3SchemaContextQuery,
    GQTypes.GQImportFlowV3SchemaContextQueryVariables
  >({ query: ImportFlowV3SchemaContextDocument, ...options });
}
export const ImportFlowV3TransformAndSubmitUploadDocument = gql`
    mutation ImportFlowV3TransformAndSubmitUpload($input: TransformAndSubmitUploadInput!) @withOwner(owner: DataIngestion) {
  transformAndSubmitUpload(input: $input) {
    jobId
  }
}
    `;

export function useImportFlowV3TransformAndSubmitUploadMutation() {
  return Urql.useMutation<
    GQTypes.GQImportFlowV3TransformAndSubmitUploadMutation,
    GQTypes.GQImportFlowV3TransformAndSubmitUploadMutationVariables
  >(ImportFlowV3TransformAndSubmitUploadDocument);
}
export const ImportFlowV3CreateOneSchemaSessionDocument = gql`
    mutation ImportFlowV3CreateOneSchemaSession($input: CreateOneSchemaSessionForTargetSchemaInput!) @withOwner(owner: DataIngestion) {
  createOneSchemaSessionForTargetSchema(input: $input) {
    oneSchemaEmbedId
    oneSchemaSessionToken
    oneSchemaEmbedStatus
    oneSchemaEmbedUserJwt
    uploadId
    fileMetadataId
  }
}
    `;

export function useImportFlowV3CreateOneSchemaSessionMutation() {
  return Urql.useMutation<
    GQTypes.GQImportFlowV3CreateOneSchemaSessionMutation,
    GQTypes.GQImportFlowV3CreateOneSchemaSessionMutationVariables
  >(ImportFlowV3CreateOneSchemaSessionDocument);
}
export const ImportFlowV3DatasetContextDocument = gql`
    query ImportFlowV3DatasetContext($datasetId: ID!) @withOwner(owner: DataIngestion) {
  dataset(id: $datasetId) {
    id
    name
    canonicalDataset {
      id
      kind
      customerTargetSchemas {
        id
        latestPublishedVersion {
          ...ImportFlowV3_CustomerTargetSchemaVersion
        }
      }
    }
  }
}
    ${ImportFlowV3_CustomerTargetSchemaVersionFragmentDoc}`;

export function useImportFlowV3DatasetContextQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQImportFlowV3DatasetContextQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQImportFlowV3DatasetContextQuery,
    GQTypes.GQImportFlowV3DatasetContextQueryVariables
  >({ query: ImportFlowV3DatasetContextDocument, ...options });
}
export const ImportFlowV3GenerateBartFromFileUploadDocument = gql`
    mutation ImportFlowV3GenerateBartFromFileUpload($input: GenerateBartFromFileUploadInput!) @withOwner(owner: DataIngestion) {
  generateBartFromFileUpload(input: $input) {
    jobId
  }
}
    `;

export function useImportFlowV3GenerateBartFromFileUploadMutation() {
  return Urql.useMutation<
    GQTypes.GQImportFlowV3GenerateBartFromFileUploadMutation,
    GQTypes.GQImportFlowV3GenerateBartFromFileUploadMutationVariables
  >(ImportFlowV3GenerateBartFromFileUploadDocument);
}
export const GetValueMappingTaskPublishStatusDocument = gql`
    query GetValueMappingTaskPublishStatus($id: ID!) @withOwner(owner: DataIngestion) {
  valueMappingTask(id: $id) {
    id
    publishStatus
  }
}
    `;

export function useGetValueMappingTaskPublishStatusQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetValueMappingTaskPublishStatusQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetValueMappingTaskPublishStatusQuery,
    GQTypes.GQGetValueMappingTaskPublishStatusQueryVariables
  >({ query: GetValueMappingTaskPublishStatusDocument, ...options });
}
export const FinancialsReviewItemDetailPageDocument = gql`
    query FinancialsReviewItemDetailPage($reviewItemId: ID!, $measurementProjectId: ID!) @withOwner(owner: DataIngestion) {
  userHasPermission: userHasManageFinancialsPermission
  financialsReviewItems(measurementProjectId: $measurementProjectId) {
    edges {
      node {
        id
        status
        kind
      }
    }
  }
  financialsReviewItem(id: $reviewItemId) {
    ...FinancialsReviewItemDetails
  }
}
    ${FinancialsReviewItemDetailsFragmentDoc}`;

export function useFinancialsReviewItemDetailPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinancialsReviewItemDetailPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinancialsReviewItemDetailPageQuery,
    GQTypes.GQFinancialsReviewItemDetailPageQueryVariables
  >({ query: FinancialsReviewItemDetailPageDocument, ...options });
}
export const SubmitReviewItemReviewDocument = gql`
    mutation SubmitReviewItemReview($input: ReviewFinancialsReviewItemInput!) @withOwner(owner: DataIngestion) {
  reviewFinancialsReviewItem(input: $input) {
    reviewItem {
      ...FinancialsReviewItemDetails
    }
    measurementTask {
      ...MeasurementParentTaskForMeasurementPage
    }
    financialsUserUploadTasks {
      id
      state
    }
  }
}
    ${FinancialsReviewItemDetailsFragmentDoc}
${MeasurementParentTaskForMeasurementPageFragmentDoc}`;

export function useSubmitReviewItemReviewMutation() {
  return Urql.useMutation<
    GQTypes.GQSubmitReviewItemReviewMutation,
    GQTypes.GQSubmitReviewItemReviewMutationVariables
  >(SubmitReviewItemReviewDocument);
}
export const MappingsReviewIndexPageDocument = gql`
    query MappingsReviewIndexPage($id: ID!) @withOwner(owner: DataIngestion) {
  userHasPermission: userHasManageFinancialsPermission
  measurementProject(id: $id) {
    id
    name
  }
  financialsReviewItems(measurementProjectId: $id) {
    edges {
      node {
        ...FinancialsReviewInboxItem
      }
    }
  }
}
    ${FinancialsReviewInboxItemFragmentDoc}`;

export function useMappingsReviewIndexPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMappingsReviewIndexPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMappingsReviewIndexPageQuery,
    GQTypes.GQMappingsReviewIndexPageQueryVariables
  >({ query: MappingsReviewIndexPageDocument, ...options });
}
export const DeleteUserUploadTasksDocument = gql`
    mutation DeleteUserUploadTasks($input: DeleteUserUploadTasksInput!) @withOwner(owner: DataIngestion) {
  deleteUserUploadTasks(input: $input) {
    measurementProject {
      ...DashboardMeasurementProjectFieldsForOrgPage
    }
  }
}
    ${DashboardMeasurementProjectFieldsForOrgPageFragmentDoc}`;

export function useDeleteUserUploadTasksMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteUserUploadTasksMutation,
    GQTypes.GQDeleteUserUploadTasksMutationVariables
  >(DeleteUserUploadTasksDocument);
}
export const GetUploadsForUserUploadTaskDocument = gql`
    query GetUploadsForUserUploadTask($userUploadTaskId: ID!) @withOwner(owner: DataIngestion) {
  userUploadTask(id: $userUploadTaskId) {
    id
    measurementTask {
      id
      statusSimplified
    }
    userUploads {
      edges {
        node {
          ...UserUploadForDatasource
        }
      }
    }
  }
}
    ${UserUploadForDatasourceFragmentDoc}`;

export function useGetUploadsForUserUploadTaskQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetUploadsForUserUploadTaskQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetUploadsForUserUploadTaskQuery,
    GQTypes.GQGetUploadsForUserUploadTaskQueryVariables
  >({ query: GetUploadsForUserUploadTaskDocument, ...options });
}
export const GetCustomizationTypeSelectorSectionsDocument = gql`
    query GetCustomizationTypeSelectorSections @withOwner(owner: Calcprint) {
  methodologyCustomizationDisplaySections {
    sectionName
    sectionRows {
      customizationTypeId
      typeConfig {
        id
        displayName
        description
        iconName
      }
    }
  }
}
    `;

export function useGetCustomizationTypeSelectorSectionsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCustomizationTypeSelectorSectionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCustomizationTypeSelectorSectionsQuery,
    GQTypes.GQGetCustomizationTypeSelectorSectionsQueryVariables
  >({ query: GetCustomizationTypeSelectorSectionsDocument, ...options });
}
export const MethodologyCustomizationRowsForLibraryDocument = gql`
    query MethodologyCustomizationRowsForLibrary($customizationTypeId: String!) @withOwner(owner: Calcprint) {
  methodologyCustomizationRowsForOrg(customizationTypeId: $customizationTypeId) {
    id
    revisionCreatedAt
    orgName
    referenceDataRevisionRows {
      id
      rowFields
      citationName
      citationNotes
      citationUrl
      citationSlug
      isCustomerProvided
    }
  }
}
    `;

export function useMethodologyCustomizationRowsForLibraryQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMethodologyCustomizationRowsForLibraryQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMethodologyCustomizationRowsForLibraryQuery,
    GQTypes.GQMethodologyCustomizationRowsForLibraryQueryVariables
  >({ query: MethodologyCustomizationRowsForLibraryDocument, ...options });
}
export const MethodologyCustomizationTypeConfigDocument = gql`
    query MethodologyCustomizationTypeConfig($customizationTypeId: String!) @withOwner(owner: Calcprint) {
  methodologyCustomizationTypeConfig(customizationTypeId: $customizationTypeId) {
    id
    displayName
    description
    iconName
    globalReferenceDataSources
    quantityColumnName
    displayColumns {
      parquetColumnName
      displayName
      description
      type
    }
    globalColumnsToCustomerUploadedColumns
  }
}
    `;

export function useMethodologyCustomizationTypeConfigQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMethodologyCustomizationTypeConfigQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMethodologyCustomizationTypeConfigQuery,
    GQTypes.GQMethodologyCustomizationTypeConfigQueryVariables
  >({ query: MethodologyCustomizationTypeConfigDocument, ...options });
}
export const MethodologyCustomizationValueMappingRowsDocument = gql`
    query MethodologyCustomizationValueMappingRows($valueMappingConfigId: String!) @withOwner(owner: Calcprint) {
  valueMappingWithLatestStatus(valueMappingConfigId: $valueMappingConfigId) {
    ...ValueMappingPageValueMapping
  }
}
    ${ValueMappingPageValueMappingFragmentDoc}`;

export function useMethodologyCustomizationValueMappingRowsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMethodologyCustomizationValueMappingRowsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMethodologyCustomizationValueMappingRowsQuery,
    GQTypes.GQMethodologyCustomizationValueMappingRowsQueryVariables
  >({ query: MethodologyCustomizationValueMappingRowsDocument, ...options });
}
export const PublishMethodologyCustomizationValueMappingArtifactDocument = gql`
    mutation PublishMethodologyCustomizationValueMappingArtifact($valueMappingConfigId: ID!) @withOwner(owner: Calcprint) {
  publishValueMappingArtifact(
    input: {valueMappingConfigId: $valueMappingConfigId}
  ) {
    publishedArtifactRevisionId
  }
}
    `;

export function usePublishMethodologyCustomizationValueMappingArtifactMutation() {
  return Urql.useMutation<
    GQTypes.GQPublishMethodologyCustomizationValueMappingArtifactMutation,
    GQTypes.GQPublishMethodologyCustomizationValueMappingArtifactMutationVariables
  >(PublishMethodologyCustomizationValueMappingArtifactDocument);
}
export const GenerateOverrideReferenceDataRevisionDocument = gql`
    mutation GenerateOverrideReferenceDataRevision($input: GenerateOverrideReferenceDataRevisionInput!) @withOwner(owner: Calcprint) {
  generateOverrideReferenceDataRevision(input: $input) {
    jobId
  }
}
    `;

export function useGenerateOverrideReferenceDataRevisionMutation() {
  return Urql.useMutation<
    GQTypes.GQGenerateOverrideReferenceDataRevisionMutation,
    GQTypes.GQGenerateOverrideReferenceDataRevisionMutationVariables
  >(GenerateOverrideReferenceDataRevisionDocument);
}
export const MethodologyCustomizationMissingSourceDataDocument = gql`
    query MethodologyCustomizationMissingSourceData($configId: String!) @withOwner(owner: Calcprint) {
  methodologyCustomizationMappingConfig(configId: $configId) {
    id
    missingSourceDataConfig {
      message
      buttonLabel
      imageName
    }
  }
}
    `;

export function useMethodologyCustomizationMissingSourceDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMethodologyCustomizationMissingSourceDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMethodologyCustomizationMissingSourceDataQuery,
    GQTypes.GQMethodologyCustomizationMissingSourceDataQueryVariables
  >({ query: MethodologyCustomizationMissingSourceDataDocument, ...options });
}
export const GetMappingTabSidebarSectionsDocument = gql`
    query GetMappingTabSidebarSections @withOwner(owner: Calcprint) {
  methodologyCustomizationDisplaySections {
    sectionName
    sectionRows {
      customizationTypeId
      typeConfig {
        id
        displayName
      }
      mappingConfigIds
    }
  }
}
    `;

export function useGetMappingTabSidebarSectionsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetMappingTabSidebarSectionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetMappingTabSidebarSectionsQuery,
    GQTypes.GQGetMappingTabSidebarSectionsQueryVariables
  >({ query: GetMappingTabSidebarSectionsDocument, ...options });
}
export const BulkAddFacilitiesToTasksImporterDocument = gql`
    query BulkAddFacilitiesToTasksImporter @withOwner(owner: DataIngestion) {
  allFacilityIds
}
    `;

export function useBulkAddFacilitiesToTasksImporterQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQBulkAddFacilitiesToTasksImporterQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBulkAddFacilitiesToTasksImporterQuery,
    GQTypes.GQBulkAddFacilitiesToTasksImporterQueryVariables
  >({ query: BulkAddFacilitiesToTasksImporterDocument, ...options });
}
export const BulkAssignFacilitiesToTasksDocument = gql`
    mutation BulkAssignFacilitiesToTasks($input: BulkAssignFacilitiesToTasksInput!) @withOwner(owner: DataIngestion) {
  bulkAssignFacilitiesToTasks(input: $input) {
    success
  }
}
    `;

export function useBulkAssignFacilitiesToTasksMutation() {
  return Urql.useMutation<
    GQTypes.GQBulkAssignFacilitiesToTasksMutation,
    GQTypes.GQBulkAssignFacilitiesToTasksMutationVariables
  >(BulkAssignFacilitiesToTasksDocument);
}
export const ConfigureFacilitiesTasksPageDocument = gql`
    query ConfigureFacilitiesTasksPage($measurementProjectId: ID!, $after: String, $before: String, $first: Int, $last: Int, $filterWithOperator: FacilitiesFilter, $sort: [FacilitiesSortInput!], $search: String, $canonicalDatasetKind: CanonicalDatasetKind!, $orgStructureEnabled: Boolean!, $projectIntervalStart: String!, $projectIntervalEndExclusive: String!) @withOwner(owner: DataIngestion) {
  measurementProject(id: $measurementProjectId) {
    ...MeasurementProjectForConfigureFacilitiesTasksPage
  }
  organization {
    ...OrganizationForConfigureFacilitiesTasksPage
  }
  facilities(
    after: $after
    before: $before
    first: $first
    last: $last
    filterWithOperator: $filterWithOperator
    sort: $sort
    search: $search
    measurementProjectId: $measurementProjectId
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
    edges {
      node {
        ...FacilitiesForTasksPage
      }
    }
  }
  facilitiesWithMissingFields: facilities(
    first: 0
    filterWithOperator: {filter: {field: {type: Completion}, operator: equals, value: "incomplete"}, filterLogicOperator: And}
  ) {
    pageInfo {
      totalRowCount
    }
  }
  facilitiesOutsideProjectInterval: facilities(
    first: 0
    filterWithOperator: {filter: [{field: {type: StartYearMonth}, operator: greaterThanOrEqual, value: [$projectIntervalEndExclusive]}, {field: {type: EndYearMonth}, operator: lessThanOrEqual, value: [$projectIntervalStart]}], filterLogicOperator: Or}
  ) {
    pageInfo {
      totalRowCount
    }
  }
  latestOrgUnitTypesForCanonicalDatasetKind(
    canonicalDatasetKind: $canonicalDatasetKind
  ) @include(if: $orgStructureEnabled) {
    ...OrgUnitTypeWithOrgUnitsForFacilities
  }
}
    ${MeasurementProjectForConfigureFacilitiesTasksPageFragmentDoc}
${OrganizationForConfigureFacilitiesTasksPageFragmentDoc}
${FacilitiesForTasksPageFragmentDoc}
${OrgUnitTypeWithOrgUnitsForFacilitiesFragmentDoc}`;

export function useConfigureFacilitiesTasksPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQConfigureFacilitiesTasksPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQConfigureFacilitiesTasksPageQuery,
    GQTypes.GQConfigureFacilitiesTasksPageQueryVariables
  >({ query: ConfigureFacilitiesTasksPageDocument, ...options });
}
export const AssignFacilitiesToTasksDocument = gql`
    mutation AssignFacilitiesToTasks($input: AssignFacilitiesToTasksInput!) @withOwner(owner: DataIngestion) {
  assignFacilitiesToTasks(input: $input) {
    measurementProject {
      ...MeasurementProjectForConfigureFacilitiesTasksPage
    }
    facilitiesRequiringConfirmation {
      id
      buildingName
    }
    lockedDestinationTasks {
      id
      datasource {
        id
        name
      }
      lockState
    }
    lockedSourceTasks {
      id
      datasource {
        id
        name
      }
      lockState
    }
  }
}
    ${MeasurementProjectForConfigureFacilitiesTasksPageFragmentDoc}`;

export function useAssignFacilitiesToTasksMutation() {
  return Urql.useMutation<
    GQTypes.GQAssignFacilitiesToTasksMutation,
    GQTypes.GQAssignFacilitiesToTasksMutationVariables
  >(AssignFacilitiesToTasksDocument);
}
export const DeleteBuildingsForConfigureFacilitiesDocument = gql`
    mutation DeleteBuildingsForConfigureFacilities($input: DeleteBuildingsInput!) @withOwner(owner: DataIngestion) {
  deleteBuildings(input: $input) {
    measurementProject {
      ...MeasurementProjectForConfigureFacilitiesTasksPage
    }
  }
}
    ${MeasurementProjectForConfigureFacilitiesTasksPageFragmentDoc}`;

export function useDeleteBuildingsForConfigureFacilitiesMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteBuildingsForConfigureFacilitiesMutation,
    GQTypes.GQDeleteBuildingsForConfigureFacilitiesMutationVariables
  >(DeleteBuildingsForConfigureFacilitiesDocument);
}
export const CheckAndGenerateFacilitiesBartsDocument = gql`
    mutation checkAndGenerateFacilitiesBarts($input: CheckAndGenerateFacilitiesBartsInput!) @withOwner(owner: DataIngestion) {
  checkAndGenerateFacilitiesBarts(input: $input) {
    workflowId
  }
}
    `;

export function useCheckAndGenerateFacilitiesBartsMutation() {
  return Urql.useMutation<
    GQTypes.GQCheckAndGenerateFacilitiesBartsMutation,
    GQTypes.GQCheckAndGenerateFacilitiesBartsMutationVariables
  >(CheckAndGenerateFacilitiesBartsDocument);
}
export const IsFacilitiesDataStaleDocument = gql`
    query isFacilitiesDataStale($input: BiActivityQueryContextInput!) @withOwner(owner: DataIngestion) {
  biPostgresToActivityData(input: $input) {
    isFacilitiesDataStale
  }
}
    `;

export function useIsFacilitiesDataStaleQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQIsFacilitiesDataStaleQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQIsFacilitiesDataStaleQuery,
    GQTypes.GQIsFacilitiesDataStaleQueryVariables
  >({ query: IsFacilitiesDataStaleDocument, ...options });
}
export const FacilitiesDataSummaryDocument = gql`
    query facilitiesDataSummary($input: BiActivityQueryContextInput!) @withOwner(owner: DataIngestion) {
  biPostgresToActivityData(input: $input) {
    isFacilitiesDataStale
  }
  organization {
    id
    measurementProjects {
      id
      coverageInterval
    }
  }
}
    `;

export function useFacilitiesDataSummaryQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFacilitiesDataSummaryQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFacilitiesDataSummaryQuery,
    GQTypes.GQFacilitiesDataSummaryQueryVariables
  >({ query: FacilitiesDataSummaryDocument, ...options });
}
export const GetBuildingTemplateDataDocument = gql`
    query GetBuildingTemplateData @withOwner(owner: DataIngestion) {
  buildingTemplateDataUntyped
}
    `;

export function useGetBuildingTemplateDataQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetBuildingTemplateDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetBuildingTemplateDataQuery,
    GQTypes.GQGetBuildingTemplateDataQueryVariables
  >({ query: GetBuildingTemplateDataDocument, ...options });
}
export const DeleteBuildingsForFacilitiesManagementDocument = gql`
    mutation DeleteBuildingsForFacilitiesManagement($input: DeleteBuildingsInput!) @withOwner(owner: DataIngestion) {
  deleteBuildings(input: $input) {
    organization {
      ...OrganizationFacilitiesUntyped
    }
    measurementProject {
      ...MeasurementProjectForManageFacilitiesPage
    }
  }
}
    ${OrganizationFacilitiesUntypedFragmentDoc}
${MeasurementProjectForManageFacilitiesPageFragmentDoc}`;

export function useDeleteBuildingsForFacilitiesManagementMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteBuildingsForFacilitiesManagementMutation,
    GQTypes.GQDeleteBuildingsForFacilitiesManagementMutationVariables
  >(DeleteBuildingsForFacilitiesManagementDocument);
}
export const ReassignFacilitiesTaskDocument = gql`
    mutation ReassignFacilitiesTask($input: ReassignFacilitiesTaskInput!) @withOwner(owner: DataIngestion) {
  reassignFacilitiesTask(input: $input) {
    measurementProject {
      ...MeasurementProjectForManageFacilitiesPage
    }
  }
}
    ${MeasurementProjectForManageFacilitiesPageFragmentDoc}`;

export function useReassignFacilitiesTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQReassignFacilitiesTaskMutation,
    GQTypes.GQReassignFacilitiesTaskMutationVariables
  >(ReassignFacilitiesTaskDocument);
}
export const BatchSetCustomFieldForBuildingsDocument = gql`
    mutation BatchSetCustomFieldForBuildings($input: BatchSetCustomFieldForBuildingsInput!) @withOwner(owner: DataIngestion) {
  batchSetCustomFieldForBuildings(input: $input) {
    buildingsUntyped {
      id
      data
    }
  }
}
    `;

export function useBatchSetCustomFieldForBuildingsMutation() {
  return Urql.useMutation<
    GQTypes.GQBatchSetCustomFieldForBuildingsMutation,
    GQTypes.GQBatchSetCustomFieldForBuildingsMutationVariables
  >(BatchSetCustomFieldForBuildingsDocument);
}
export const BatchUnsetCustomFieldForBuildingsDocument = gql`
    mutation BatchUnsetCustomFieldForBuildings($input: BatchUnsetCustomFieldForBuildingsInput!) @withOwner(owner: DataIngestion) {
  batchUnsetCustomFieldForBuildings(input: $input) {
    buildingsUntyped {
      id
      data
    }
  }
}
    `;

export function useBatchUnsetCustomFieldForBuildingsMutation() {
  return Urql.useMutation<
    GQTypes.GQBatchUnsetCustomFieldForBuildingsMutation,
    GQTypes.GQBatchUnsetCustomFieldForBuildingsMutationVariables
  >(BatchUnsetCustomFieldForBuildingsDocument);
}
export const UpdateBuildingsDocument = gql`
    mutation UpdateBuildings($input: UpdateBuildingsInput!) @withOwner(owner: DataIngestion) {
  updateBuildings(input: $input) {
    buildingsUntyped {
      id
      data
    }
  }
}
    `;

export function useUpdateBuildingsMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateBuildingsMutation,
    GQTypes.GQUpdateBuildingsMutationVariables
  >(UpdateBuildingsDocument);
}
export const OrganizationUsersForFacilitiesImportDocument = gql`
    query OrganizationUsersForFacilitiesImport @withOwner(owner: DataIngestion) {
  organization {
    ...OrganizationFieldsForFacilitiesImport
  }
}
    ${OrganizationFieldsForFacilitiesImportFragmentDoc}`;

export function useOrganizationUsersForFacilitiesImportQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationUsersForFacilitiesImportQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationUsersForFacilitiesImportQuery,
    GQTypes.GQOrganizationUsersForFacilitiesImportQueryVariables
  >({ query: OrganizationUsersForFacilitiesImportDocument, ...options });
}
export const BulkCreateUsersForFacilitiesDocument = gql`
    mutation BulkCreateUsersForFacilities($input: BulkCreateUsersForFacilitiesInput!) @withOwner(owner: DataIngestion) {
  bulkCreateUsersForFacilities(input: $input) {
    users {
      ...UserForFacilitiesImport
    }
    organization {
      ...OrganizationFieldsForFacilitiesImport
    }
  }
}
    ${UserForFacilitiesImportFragmentDoc}
${OrganizationFieldsForFacilitiesImportFragmentDoc}`;

export function useBulkCreateUsersForFacilitiesMutation() {
  return Urql.useMutation<
    GQTypes.GQBulkCreateUsersForFacilitiesMutation,
    GQTypes.GQBulkCreateUsersForFacilitiesMutationVariables
  >(BulkCreateUsersForFacilitiesDocument);
}
export const FacilitiesManagementPageNewDocument = gql`
    query FacilitiesManagementPageNew($measurementProjectId: ID!) @withOwner(owner: DataIngestion) {
  measurementProject(id: $measurementProjectId) {
    ...MeasurementProjectForManageFacilitiesPage
  }
  organization {
    ...OrganizationCustomFieldsForManagePage
  }
}
    ${MeasurementProjectForManageFacilitiesPageFragmentDoc}
${OrganizationCustomFieldsForManagePageFragmentDoc}`;

export function useFacilitiesManagementPageNewQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFacilitiesManagementPageNewQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFacilitiesManagementPageNewQuery,
    GQTypes.GQFacilitiesManagementPageNewQueryVariables
  >({ query: FacilitiesManagementPageNewDocument, ...options });
}
export const BuildingsUntypedDocument = gql`
    query BuildingsUntyped @withOwner(owner: DataIngestion) {
  organization {
    ...OrganizationFacilitiesUntyped
  }
}
    ${OrganizationFacilitiesUntypedFragmentDoc}`;

export function useBuildingsUntypedQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQBuildingsUntypedQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBuildingsUntypedQuery,
    GQTypes.GQBuildingsUntypedQueryVariables
  >({ query: BuildingsUntypedDocument, ...options });
}
export const MeasurementProjectsWithFacilitiesDocument = gql`
    query MeasurementProjectsWithFacilities @withOwner(owner: DataIngestion) {
  measurementProjectsWithFacilities {
    id
  }
}
    `;

export function useMeasurementProjectsWithFacilitiesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQMeasurementProjectsWithFacilitiesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMeasurementProjectsWithFacilitiesQuery,
    GQTypes.GQMeasurementProjectsWithFacilitiesQueryVariables
  >({ query: MeasurementProjectsWithFacilitiesDocument, ...options });
}
export const SingleFacilityUtilitiesDocument = gql`
    query SingleFacilityUtilities($buildingId: ID!, $userUploadTaskId: ID!, $combineFuelKinds: Boolean) @withOwner(owner: DataIngestion) {
  facility(id: $buildingId) {
    id
    startYearMonth
    endYearMonth
    buildingWithUtilities {
      id
      completionIntervals(
        userUploadTaskId: $userUploadTaskId
        combineFuelKinds: $combineFuelKinds
      ) {
        id
        data {
          utilityType
          fuelKind
          periodsWithActuals {
            start
            end
          }
        }
      }
    }
  }
}
    `;

export function useSingleFacilityUtilitiesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQSingleFacilityUtilitiesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSingleFacilityUtilitiesQuery,
    GQTypes.GQSingleFacilityUtilitiesQueryVariables
  >({ query: SingleFacilityUtilitiesDocument, ...options });
}
export const BuildingCompletionIntervalsUntypedDocument = gql`
    query BuildingCompletionIntervalsUntyped @withOwner(owner: DataIngestion) {
  organization {
    id
    buildingCompletionIntervalsUntyped {
      id
      data
    }
  }
}
    `;

export function useBuildingCompletionIntervalsUntypedQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQBuildingCompletionIntervalsUntypedQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBuildingCompletionIntervalsUntypedQuery,
    GQTypes.GQBuildingCompletionIntervalsUntypedQueryVariables
  >({ query: BuildingCompletionIntervalsUntypedDocument, ...options });
}
export const BuildingUtilitiesExportDocument = gql`
    query BuildingUtilitiesExport @withOwner(owner: DataIngestion) {
  buildingUtilitiesExport {
    jobId
  }
}
    `;

export function useBuildingUtilitiesExportQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQBuildingUtilitiesExportQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBuildingUtilitiesExportQuery,
    GQTypes.GQBuildingUtilitiesExportQueryVariables
  >({ query: BuildingUtilitiesExportDocument, ...options });
}
export const BuildingSupportingDocsExportDocument = gql`
    query BuildingSupportingDocsExport($buildingIds: [ID!]) @withOwner(owner: DataIngestion) {
  buildingSupportingDocsExport(buildingIds: $buildingIds) {
    jobId
  }
}
    `;

export function useBuildingSupportingDocsExportQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQBuildingSupportingDocsExportQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBuildingSupportingDocsExportQuery,
    GQTypes.GQBuildingSupportingDocsExportQueryVariables
  >({ query: BuildingSupportingDocsExportDocument, ...options });
}
export const FacilitiesExportForMeasurementProjectDocument = gql`
    query FacilitiesExportForMeasurementProject($measurementProjectId: ID!) @withOwner(owner: DataIngestion) {
  facilitiesExportForMeasurementProject(
    measurementProjectId: $measurementProjectId
  ) {
    jobId
  }
}
    `;

export function useFacilitiesExportForMeasurementProjectQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFacilitiesExportForMeasurementProjectQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFacilitiesExportForMeasurementProjectQuery,
    GQTypes.GQFacilitiesExportForMeasurementProjectQueryVariables
  >({ query: FacilitiesExportForMeasurementProjectDocument, ...options });
}
export const CleanPowerAllocationSummaryDocument = gql`
    query CleanPowerAllocationSummary($selectedAllocationInstructionIds: [ID!]!) @withOwner(owner: Calcprint) {
  filterSelectedAllocationInstructions(
    selectedAllocationInstructionIds: $selectedAllocationInstructionIds
  ) {
    numTotalAllocationInstructions
    activeSelectedAllocationInstructions {
      ...MarketplaceAllocationInstructionForConfiguration
    }
    deletedAllocationInstructionIds
  }
}
    ${MarketplaceAllocationInstructionForConfigurationFragmentDoc}`;

export function useCleanPowerAllocationSummaryQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCleanPowerAllocationSummaryQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCleanPowerAllocationSummaryQuery,
    GQTypes.GQCleanPowerAllocationSummaryQueryVariables
  >({ query: CleanPowerAllocationSummaryDocument, ...options });
}
export const GenerateFootprintStepRendererDocument = gql`
    query GenerateFootprintStepRenderer($footprintId: String!) @withOwner(owner: Calcprint) {
  userHasPermission(permission: ManageMeasurement)
  footprint(footprintId: $footprintId) {
    id
    name
    nextFootprintVersionIdentifier
    latestFootprintSnapshot {
      id
      createdAt
      footprintConfiguration {
        ...FootprintConfigurationForGenerateFootprint
      }
    }
  }
  utilitiesMetadata(filterInterval: null) {
    id
    utilityTypes {
      utilityType
      containingInterval
    }
  }
  organization {
    id
    facilitiesMetadata {
      buildingsDataset {
        id
      }
      utilitiesDataset {
        id
      }
    }
    measurementProjects {
      ...MeasurementProjectForGenerateFootprint
    }
  }
}
    ${FootprintConfigurationForGenerateFootprintFragmentDoc}
${MeasurementProjectForGenerateFootprintFragmentDoc}`;

export function useGenerateFootprintStepRendererQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGenerateFootprintStepRendererQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGenerateFootprintStepRendererQuery,
    GQTypes.GQGenerateFootprintStepRendererQueryVariables
  >({ query: GenerateFootprintStepRendererDocument, ...options });
}
export const PrepareFootprintGenerationParamsDocument = gql`
    mutation PrepareFootprintGenerationParams($input: PrepareFootprintGenerationParamsInput!) @withOwner(owner: Calcprint) {
  prepareFootprintGenerationParams(input: $input) {
    workflowId
  }
}
    `;

export function usePrepareFootprintGenerationParamsMutation() {
  return Urql.useMutation<
    GQTypes.GQPrepareFootprintGenerationParamsMutation,
    GQTypes.GQPrepareFootprintGenerationParamsMutationVariables
  >(PrepareFootprintGenerationParamsDocument);
}
export const GenerateFootprintV2Document = gql`
    mutation GenerateFootprintV2($input: GenerateFootprintV2Input!) @withOwner(owner: Calcprint) {
  generateFootprintV2(input: $input) {
    jobId
    footprintAssemblyRun {
      id
    }
  }
}
    `;

export function useGenerateFootprintV2Mutation() {
  return Urql.useMutation<
    GQTypes.GQGenerateFootprintV2Mutation,
    GQTypes.GQGenerateFootprintV2MutationVariables
  >(GenerateFootprintV2Document);
}
export const AvailableReleasesForSelectedDatasetsDocument = gql`
    query AvailableReleasesForSelectedDatasets($datasetIdsPerMeasurementProjectId: [DatasetIdsPerMeasurementProjectId!]!) @withOwner(owner: Calcprint) {
  availableReleasesForSelectedDatasets(
    datasetIdsPerMeasurementProjectId: $datasetIdsPerMeasurementProjectId
  ) {
    businessActivityType
    displayName
    releases {
      id
      displayName
      businessActivityTypeName
      externalNotes
      lifecyclePhase
      updatedAt
      orgId
      isCustomToUserOrg
      latestCompatibleBat {
        id
        versionName
      }
      lifecyclePhaseHistory {
        id
        recordStartAt
        recordEndAt
        lifecyclePhase
      }
    }
  }
  selectedDatasetsByBatWithMetadata(
    datasetIdsPerMeasurementProjectId: $datasetIdsPerMeasurementProjectId
  ) {
    id
    datasetsByBat {
      id
      datasets {
        id
        datasetName
      }
      businessActivityType
      businessActivityTypeDisplayName
    }
    ctsUserUploads {
      id
      datasourceId
      userUploadId
      userUploadFileName
      batvName
      businessActivityType
      measurementProjectId
    }
  }
}
    `;

export function useAvailableReleasesForSelectedDatasetsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAvailableReleasesForSelectedDatasetsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAvailableReleasesForSelectedDatasetsQuery,
    GQTypes.GQAvailableReleasesForSelectedDatasetsQueryVariables
  >({ query: AvailableReleasesForSelectedDatasetsDocument, ...options });
}
export const ApplyIntegrationConnectionDashboardDocument = gql`
    mutation ApplyIntegrationConnectionDashboard($input: ApplyIntegrationConnectionInput!) @withOwner(owner: DataIngestion) {
  applyIntegrationConnection(input: $input) {
    integrationConnection {
      id
      datasources {
        edges {
          node {
            id
            name
            dataset {
              id
              name
            }
          }
        }
      }
    }
  }
}
    `;

export function useApplyIntegrationConnectionDashboardMutation() {
  return Urql.useMutation<
    GQTypes.GQApplyIntegrationConnectionDashboardMutation,
    GQTypes.GQApplyIntegrationConnectionDashboardMutationVariables
  >(ApplyIntegrationConnectionDashboardDocument);
}
export const MaybeCreateIntegrationConnectionDoNotLogDocument = gql`
    mutation MaybeCreateIntegrationConnectionDoNotLog($input: MaybeCreateIntegrationConnectionInput!) @withOwner(owner: DataIngestion) {
  maybeCreateIntegrationConnection(input: $input) {
    datasources {
      id
      activeIntegrationConnection {
        id
      }
      hasIntegration
    }
    integrationConnection {
      id
      integrationPartner {
        id
        activeIntegrationConnections {
          id
        }
      }
    }
  }
}
    `;

export function useMaybeCreateIntegrationConnectionDoNotLogMutation() {
  return Urql.useMutation<
    GQTypes.GQMaybeCreateIntegrationConnectionDoNotLogMutation,
    GQTypes.GQMaybeCreateIntegrationConnectionDoNotLogMutationVariables
  >(MaybeCreateIntegrationConnectionDoNotLogDocument);
}
export const DisconnectIntegrationDocument = gql`
    mutation DisconnectIntegration($input: DisconnectIntegrationConnectionInput!) @withOwner(owner: DataIngestion) {
  disconnectIntegrationConnection(input: $input) {
    integrationPartner {
      id
      activeIntegrationConnections {
        id
        additionalIdentifier
      }
    }
    revoked
  }
}
    `;

export function useDisconnectIntegrationMutation() {
  return Urql.useMutation<
    GQTypes.GQDisconnectIntegrationMutation,
    GQTypes.GQDisconnectIntegrationMutationVariables
  >(DisconnectIntegrationDocument);
}
export const AssignUserUploadTaskDocument = gql`
    mutation AssignUserUploadTask($input: AssignUserUploadTaskInput!) @withOwner(owner: DataIngestion) {
  assignUserUploadTask(input: $input) {
    userUploadTask {
      ...UserUploadTaskFields
    }
  }
}
    ${UserUploadTaskFieldsFragmentDoc}`;

export function useAssignUserUploadTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQAssignUserUploadTaskMutation,
    GQTypes.GQAssignUserUploadTaskMutationVariables
  >(AssignUserUploadTaskDocument);
}
export const GetIntegrationInviteDocument = gql`
    mutation GetIntegrationInvite($input: SendIntegrationInviteInput!) @withOwner(owner: DataIngestion) {
  sendIntegrationInvite(input: $input) {
    sampleEmail {
      ...EmailPreview_emailContents
    }
    numberOfEmails
  }
}
    ${EmailPreview_EmailContentsFragmentDoc}`;

export function useGetIntegrationInviteMutation() {
  return Urql.useMutation<
    GQTypes.GQGetIntegrationInviteMutation,
    GQTypes.GQGetIntegrationInviteMutationVariables
  >(GetIntegrationInviteDocument);
}
export const SendIntegrationInviteDocument = gql`
    mutation SendIntegrationInvite($input: SendIntegrationInviteInput!) @withOwner(owner: DataIngestion) {
  sendIntegrationInvite(input: $input) {
    numberOfEmails
  }
}
    `;

export function useSendIntegrationInviteMutation() {
  return Urql.useMutation<
    GQTypes.GQSendIntegrationInviteMutation,
    GQTypes.GQSendIntegrationInviteMutationVariables
  >(SendIntegrationInviteDocument);
}
export const OrganizationIntegrationPartnersDocument = gql`
    query OrganizationIntegrationPartners @withOwner(owner: DataIngestion) {
  userHasPermission(permission: Admin)
  integrationPartners {
    edges {
      node {
        id
        name
        logoUrl
        externalId
        dataType
        isRequestOnly
        activeIntegrationConnections {
          id
          additionalIdentifier
        }
      }
    }
  }
  integrationConnections {
    edges {
      node {
        id
        isActive
        integrationPartner {
          id
          name
        }
      }
    }
  }
}
    `;

export function useOrganizationIntegrationPartnersQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationIntegrationPartnersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationIntegrationPartnersQuery,
    GQTypes.GQOrganizationIntegrationPartnersQueryVariables
  >({ query: OrganizationIntegrationPartnersDocument, ...options });
}
export const MethodologyInProductBundleDocument = gql`
    query MethodologyInProductBundle @withOwner(owner: Calcprint) {
  currentCalculationMethodVersionsInProduct {
    id
    displayName
    externalNotes
    businessActivityTypeName
    lifecyclePhase
    latestCompatibleBat {
      id
      name
      description
    }
    emissionsModels {
      id
      latestPublishedVersion {
        ...EMVersionFieldsForMethodologyInProduct
        importedDescendants {
          ...EMVersionFieldsForMethodologyInProduct
        }
      }
      matchers {
        ...EMMatcherFieldsForMethodologyInProduct
      }
    }
  }
}
    ${EmVersionFieldsForMethodologyInProductFragmentDoc}
${EmMatcherFieldsForMethodologyInProductFragmentDoc}`;

export function useMethodologyInProductBundleQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQMethodologyInProductBundleQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMethodologyInProductBundleQuery,
    GQTypes.GQMethodologyInProductBundleQueryVariables
  >({ query: MethodologyInProductBundleDocument, ...options });
}
export const EmissionsModelStableInProductDocument = gql`
    query EmissionsModelStableInProduct($stableId: ID!) @withOwner(owner: Calcprint) {
  emissionsModelStableInProduct(id: $stableId) {
    id
    title
    description
    matchers {
      id
      kind
      businessActivityType
      businessActivityTypeId
      emissionsModelStableId
      filterExpression
      bindings
      quantitySpecifier
      keepBatVersionAtLatest
    }
    latestPublishedVersion {
      id
      emissionsModelJson
      parameterResolverJson
      parameterResolver {
        id
      }
      importedDescendants {
        id
        title
        description
        changelog
        createdAt
        schemaVersion
        emissionsModelJson
        parameterResolverJson
        stableModel {
          id
          latestPublishedVersion {
            id
            changelog
            createdAt
          }
        }
      }
    }
  }
}
    `;

export function useEmissionsModelStableInProductQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEmissionsModelStableInProductQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEmissionsModelStableInProductQuery,
    GQTypes.GQEmissionsModelStableInProductQueryVariables
  >({ query: EmissionsModelStableInProductDocument, ...options });
}
export const EmissionsModelEditorEvalInProductDocument = gql`
    query EmissionsModelEditorEvalInProduct($input: EmissionsModelEditorEvalInProductInput!) @withOwner(owner: Calcprint) {
  emissionsModelEditorEvalInProduct(input: $input) {
    resultJson
    warnings
    emissionsEquations {
      emissionsVariableName
      traceDetails {
        referenceValueDetails
        emissionsModelVariableDetails
        bartValueDetails
        emissionsModelVersionId
        emissionsModelEvaluationId
      }
      trace
    }
  }
}
    `;

export function useEmissionsModelEditorEvalInProductQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEmissionsModelEditorEvalInProductQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEmissionsModelEditorEvalInProductQuery,
    GQTypes.GQEmissionsModelEditorEvalInProductQueryVariables
  >({ query: EmissionsModelEditorEvalInProductDocument, ...options });
}
export const GetEmissionsModelVersionImportedDescendantsFromDirectImportIdsInProductDocument = gql`
    query GetEmissionsModelVersionImportedDescendantsFromDirectImportIdsInProduct($ids: [ID!]!) @withOwner(owner: Calcprint) {
  emissionsModelVersionImportedDescendantsFromDirectImportIdsInProduct(ids: $ids) {
    id
    title
    description
    changelog
    createdAt
    schemaVersion
    emissionsModelJson
    parameterResolverJson
    stableModel {
      id
      latestPublishedVersion {
        id
        changelog
        createdAt
      }
    }
  }
}
    `;

export function useGetEmissionsModelVersionImportedDescendantsFromDirectImportIdsInProductQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEmissionsModelVersionImportedDescendantsFromDirectImportIdsInProductQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEmissionsModelVersionImportedDescendantsFromDirectImportIdsInProductQuery,
    GQTypes.GQGetEmissionsModelVersionImportedDescendantsFromDirectImportIdsInProductQueryVariables
  >({
    query:
      GetEmissionsModelVersionImportedDescendantsFromDirectImportIdsInProductDocument,
    ...options,
  });
}
export const ReferenceDataVersionBySlugDocument = gql`
    query ReferenceDataVersionBySlug($slug: String!) @withOwner(owner: Calcprint) {
  referenceDataVersionBySlugInProduct(slug: $slug) {
    id
    source {
      id
      externalSourceNotes
    }
  }
}
    `;

export function useReferenceDataVersionBySlugQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataVersionBySlugQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataVersionBySlugQuery,
    GQTypes.GQReferenceDataVersionBySlugQueryVariables
  >({ query: ReferenceDataVersionBySlugDocument, ...options });
}
export const ReferenceDataInProductDocument = gql`
    query ReferenceDataInProduct($versionId: ID!, $orgId: ID!) @withOwner(owner: Calcprint) {
  referenceDataVersionInProduct(id: $versionId) {
    id
    source {
      id
      name
      externalSourceNotes
    }
    versionVintage
    latestPublishedRevisionByVersionId(orgId: $orgId) {
      id
      revisionName
      internalSourceNotes
      signedParquetOutputUrl
      rowsAsJson
    }
    versionName
    slug
    versionVintage
    latestSchema {
      id
      schemaJson
    }
  }
}
    `;

export function useReferenceDataInProductQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataInProductQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataInProductQuery,
    GQTypes.GQReferenceDataInProductQueryVariables
  >({ query: ReferenceDataInProductDocument, ...options });
}
export const GetOrSendMeasurementEmailsDocument = gql`
    mutation GetOrSendMeasurementEmails($input: GetOrSendMeasurementEmailsInput!) @withOwner(owner: DataIngestion) {
  getOrSendMeasurementEmails(input: $input) {
    measurementProject {
      ...MeasurementProjectForSendEmailsButton
    }
    sampleEmail {
      ...EmailPreview_emailContents
    }
    defaultBlurb
  }
}
    ${MeasurementProjectForSendEmailsButtonFragmentDoc}
${EmailPreview_EmailContentsFragmentDoc}`;

export function useGetOrSendMeasurementEmailsMutation() {
  return Urql.useMutation<
    GQTypes.GQGetOrSendMeasurementEmailsMutation,
    GQTypes.GQGetOrSendMeasurementEmailsMutationVariables
  >(GetOrSendMeasurementEmailsDocument);
}
export const AutomapSuppliersDocument = gql`
    mutation AutomapSuppliers($input: AutoMapSuppliersInput!) @withOwner(owner: SupplyChain) {
  autoMapSuppliers(input: $input) {
    workflowId
  }
}
    `;

export function useAutomapSuppliersMutation() {
  return Urql.useMutation<
    GQTypes.GQAutomapSuppliersMutation,
    GQTypes.GQAutomapSuppliersMutationVariables
  >(AutomapSuppliersDocument);
}
export const AutomapAssetsDocument = gql`
    mutation AutomapAssets @withOwner(owner: WinFinance) {
  autoMapAssets {
    workflowId
  }
}
    `;

export function useAutomapAssetsMutation() {
  return Urql.useMutation<
    GQTypes.GQAutomapAssetsMutation,
    GQTypes.GQAutomapAssetsMutationVariables
  >(AutomapAssetsDocument);
}
export const PreMeasurementSupplierMappingTableRowsDocument = gql`
    query PreMeasurementSupplierMappingTableRows($measurementProjectId: ID!, $search: String, $sortModel: [DataGridSortItemInput!]!, $filters: [BiQueryFilterInput!]!, $after: String, $before: String, $first: Int, $last: Int, $paginationArgs: OffsetPaginationArgsInput) @withOwner(owner: SupplyChain) {
  preMeasurementSupplierMappingTableRows(
    measurementProjectId: $measurementProjectId
    search: $search
    sortModel: $sortModel
    filters: $filters
    after: $after
    before: $before
    first: $first
    last: $last
    paginationArgs: $paginationArgs
  ) {
    ...SupplierMappingTableRowsPayloadFields
  }
}
    ${SupplierMappingTableRowsPayloadFieldsFragmentDoc}`;

export function usePreMeasurementSupplierMappingTableRowsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQPreMeasurementSupplierMappingTableRowsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPreMeasurementSupplierMappingTableRowsQuery,
    GQTypes.GQPreMeasurementSupplierMappingTableRowsQueryVariables
  >({ query: PreMeasurementSupplierMappingTableRowsDocument, ...options });
}
export const PreMeasurementSupplierMappingV1AnalyticsDocument = gql`
    query PreMeasurementSupplierMappingV1Analytics($measurementProjectId: ID!) @withOwner(owner: SupplyChain) {
  preMeasurementSupplierMappingV1Analytics(
    measurementProjectId: $measurementProjectId
  ) {
    ...SupplierMappingV1AnalyticsFields
  }
}
    ${SupplierMappingV1AnalyticsFieldsFragmentDoc}`;

export function usePreMeasurementSupplierMappingV1AnalyticsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQPreMeasurementSupplierMappingV1AnalyticsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPreMeasurementSupplierMappingV1AnalyticsQuery,
    GQTypes.GQPreMeasurementSupplierMappingV1AnalyticsQueryVariables
  >({ query: PreMeasurementSupplierMappingV1AnalyticsDocument, ...options });
}
export const VendorMatchingTaskPageDataDocument = gql`
    query VendorMatchingTaskPageData($measurementProjectId: ID!) @withOwner(owner: SupplyChain) {
  userHasPermission(permission: ManageMeasurement)
  vendorMatchingTask(measurementProjectId: $measurementProjectId) {
    ...VendorMatchingTaskFields
  }
}
    ${VendorMatchingTaskFieldsFragmentDoc}`;

export function useVendorMatchingTaskPageDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQVendorMatchingTaskPageDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQVendorMatchingTaskPageDataQuery,
    GQTypes.GQVendorMatchingTaskPageDataQueryVariables
  >({ query: VendorMatchingTaskPageDataDocument, ...options });
}
export const SubmitVendorMatchingTaskDocument = gql`
    mutation SubmitVendorMatchingTask($input: SubmitVendorMatchingTaskInput!) @withOwner(owner: SupplyChain) {
  submitVendorMatchingTask(input: $input) {
    measurementTask {
      id
    }
  }
}
    `;

export function useSubmitVendorMatchingTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQSubmitVendorMatchingTaskMutation,
    GQTypes.GQSubmitVendorMatchingTaskMutationVariables
  >(SubmitVendorMatchingTaskDocument);
}
export const UpsertOrgCompanyForNameDocument = gql`
    mutation UpsertOrgCompanyForName($input: UpsertOrgCompanyForNameInput!) @withOwner(owner: SupplyChain) {
  upsertOrgCompanyForName(input: $input) {
    orgCompany {
      ...OrgCompanyForSupplierMapping
    }
    orgCompanyHasSupplierSpecificEF
  }
}
    ${OrgCompanyForSupplierMappingFragmentDoc}`;

export function useUpsertOrgCompanyForNameMutation() {
  return Urql.useMutation<
    GQTypes.GQUpsertOrgCompanyForNameMutation,
    GQTypes.GQUpsertOrgCompanyForNameMutationVariables
  >(UpsertOrgCompanyForNameDocument);
}
export const DeleteOrgCompanyDocument = gql`
    mutation DeleteOrgCompany($input: DeleteOrgCompanyInput!) @withOwner(owner: SupplyChain) {
  deleteOrgCompany(input: $input) {
    id
  }
}
    `;

export function useDeleteOrgCompanyMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteOrgCompanyMutation,
    GQTypes.GQDeleteOrgCompanyMutationVariables
  >(DeleteOrgCompanyDocument);
}
export const LogUpdateVendorMatchingTaskDocument = gql`
    mutation LogUpdateVendorMatchingTask($input: LogUpdateVendorMatchingTaskInput!) @withOwner(owner: SupplyChain) {
  logUpdateVendorMatchingTask(input: $input) {
    vendorMatchingTaskId
  }
}
    `;

export function useLogUpdateVendorMatchingTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQLogUpdateVendorMatchingTaskMutation,
    GQTypes.GQLogUpdateVendorMatchingTaskMutationVariables
  >(LogUpdateVendorMatchingTaskDocument);
}
export const LatestSupplierAutoMappingRunDocument = gql`
    query LatestSupplierAutoMappingRun($entity: AutoMappingEntity!) @withOwner(owner: SupplyChain) {
  latestSupplierAutoMappingRun(entity: $entity) {
    id
    workflowId
    status
    entity
  }
}
    `;

export function useLatestSupplierAutoMappingRunQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQLatestSupplierAutoMappingRunQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQLatestSupplierAutoMappingRunQuery,
    GQTypes.GQLatestSupplierAutoMappingRunQueryVariables
  >({ query: LatestSupplierAutoMappingRunDocument, ...options });
}
export const SupplierMappingFilterOptionsDocument = gql`
    query SupplierMappingFilterOptions($dimension: String!) @withOwner(owner: SupplyChain) {
  supplierMappingFilterOptions(dimension: $dimension) {
    rawValue
    formattedValue
  }
}
    `;

export function useSupplierMappingFilterOptionsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQSupplierMappingFilterOptionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSupplierMappingFilterOptionsQuery,
    GQTypes.GQSupplierMappingFilterOptionsQueryVariables
  >({ query: SupplierMappingFilterOptionsDocument, ...options });
}
export const ActiveMeasurementProjectsDocument = gql`
    query ActiveMeasurementProjects @withOwner(owner: EnterpriseFoundations) {
  activeMeasurementProjects {
    id
    name
  }
}
    `;

export function useActiveMeasurementProjectsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQActiveMeasurementProjectsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQActiveMeasurementProjectsQuery,
    GQTypes.GQActiveMeasurementProjectsQueryVariables
  >({ query: ActiveMeasurementProjectsDocument, ...options });
}
export const ActiveOrgProfileDocument = gql`
    query ActiveOrgProfile @withOwner(owner: EnterpriseFoundations) {
  organization {
    id
    profile {
      ...OrgProfileForOnboardingFlow
    }
  }
}
    ${OrgProfileForOnboardingFlowFragmentDoc}`;

export function useActiveOrgProfileQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQActiveOrgProfileQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQActiveOrgProfileQuery,
    GQTypes.GQActiveOrgProfileQueryVariables
  >({ query: ActiveOrgProfileDocument, ...options });
}
export const UpdateOrgProfileDocument = gql`
    mutation UpdateOrgProfile($input: UpdateOrgProfileInput!) @withOwner(owner: EnterpriseFoundations) {
  updateOrgProfile(input: $input) {
    orgProfile {
      ...OrgProfileForOnboardingFlow
    }
    organization {
      ...OrganizationForOnboardingFlow
    }
  }
}
    ${OrgProfileForOnboardingFlowFragmentDoc}
${OrganizationForOnboardingFlowFragmentDoc}`;

export function useUpdateOrgProfileMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateOrgProfileMutation,
    GQTypes.GQUpdateOrgProfileMutationVariables
  >(UpdateOrgProfileDocument);
}
export const OrgUnitTypeForOrgUnitDialogDocument = gql`
    query OrgUnitTypeForOrgUnitDialog($versionId: ID!, $orgUnitTypeId: ID!) @withOwner(owner: EnterpriseFoundations) {
  orgUnitType(versionId: $versionId, orgUnitTypeId: $orgUnitTypeId) {
    ...OrgUnitTypeFieldsForOrgUnitDialog
  }
}
    ${OrgUnitTypeFieldsForOrgUnitDialogFragmentDoc}`;

export function useOrgUnitTypeForOrgUnitDialogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgUnitTypeForOrgUnitDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgUnitTypeForOrgUnitDialogQuery,
    GQTypes.GQOrgUnitTypeForOrgUnitDialogQueryVariables
  >({ query: OrgUnitTypeForOrgUnitDialogDocument, ...options });
}
export const AllOrgUnitTypesForRelationshipsDocument = gql`
    query AllOrgUnitTypesForRelationships($versionId: ID!, $orgUnitTypeId: ID!) @withOwner(owner: EnterpriseFoundations) {
  orgUnitTypes(versionId: $versionId) {
    ...OrgUnitTypeForRelationshipDialog
  }
  orgUnitType(versionId: $versionId, orgUnitTypeId: $orgUnitTypeId) {
    ...OrgUnitType
  }
}
    ${OrgUnitTypeForRelationshipDialogFragmentDoc}
${OrgUnitTypeFragmentDoc}`;

export function useAllOrgUnitTypesForRelationshipsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAllOrgUnitTypesForRelationshipsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAllOrgUnitTypesForRelationshipsQuery,
    GQTypes.GQAllOrgUnitTypesForRelationshipsQueryVariables
  >({ query: AllOrgUnitTypesForRelationshipsDocument, ...options });
}
export const CreateDefaultOrgStructureVersionDocument = gql`
    mutation CreateDefaultOrgStructureVersion @withOwner(owner: EnterpriseFoundations) {
  createDefaultOrgStructureVersion {
    orgStructureVersion {
      ...OrgStructureVersion
    }
  }
}
    ${OrgStructureVersionFragmentDoc}`;

export function useCreateDefaultOrgStructureVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateDefaultOrgStructureVersionMutation,
    GQTypes.GQCreateDefaultOrgStructureVersionMutationVariables
  >(CreateDefaultOrgStructureVersionDocument);
}
export const OrgStructureVersionForPageDocument = gql`
    query OrgStructureVersionForPage($versionId: ID!) @withOwner(owner: EnterpriseFoundations) {
  orgStructureVersion(versionId: $versionId) {
    id
    name
    isPublished
    ...OrgStructureVersionForSidebar
  }
}
    ${OrgStructureVersionForSidebarFragmentDoc}`;

export function useOrgStructureVersionForPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgStructureVersionForPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgStructureVersionForPageQuery,
    GQTypes.GQOrgStructureVersionForPageQueryVariables
  >({ query: OrgStructureVersionForPageDocument, ...options });
}
export const OrgStructureUnitTypePageDocument = gql`
    query OrgStructureUnitTypePage($versionId: ID!, $orgUnitTypeId: ID!) @withOwner(owner: EnterpriseFoundations) {
  orgUnitType(versionId: $versionId, orgUnitTypeId: $orgUnitTypeId) {
    id
    name
    ...OrgUnitType
  }
  orgStructureVersion(versionId: $versionId) {
    id
    name
    updatedBy {
      id
      ...UserForOrgStructureEdits
    }
    isPublished
  }
}
    ${OrgUnitTypeFragmentDoc}
${UserForOrgStructureEditsFragmentDoc}`;

export function useOrgStructureUnitTypePageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgStructureUnitTypePageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgStructureUnitTypePageQuery,
    GQTypes.GQOrgStructureUnitTypePageQueryVariables
  >({ query: OrgStructureUnitTypePageDocument, ...options });
}
export const PaginatedOrgUnitsDocument = gql`
    query PaginatedOrgUnits($after: String, $before: String, $first: Int, $last: Int, $search: String, $sortModel: [DataGridSortItemInput!], $versionId: ID!, $orgUnitTypeId: ID!) @withOwner(owner: EnterpriseFoundations) {
  orgUnitsPaginated(
    after: $after
    before: $before
    first: $first
    last: $last
    search: $search
    sortModel: $sortModel
    versionId: $versionId
    orgUnitTypeId: $orgUnitTypeId
  ) {
    edges {
      cursor
      node {
        ...OrgUnitForGrid
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
      totalRowCount
    }
  }
}
    ${OrgUnitForGridFragmentDoc}`;

export function usePaginatedOrgUnitsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQPaginatedOrgUnitsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPaginatedOrgUnitsQuery,
    GQTypes.GQPaginatedOrgUnitsQueryVariables
  >({ query: PaginatedOrgUnitsDocument, ...options });
}
export const AllOrgUnitsDocument = gql`
    query AllOrgUnits($versionId: ID!, $orgUnitTypeId: ID!) @withOwner(owner: EnterpriseFoundations) {
  orgUnits(versionId: $versionId, orgUnitTypeId: $orgUnitTypeId) {
    ...OrgUnitForGrid
  }
}
    ${OrgUnitForGridFragmentDoc}`;

export function useAllOrgUnitsQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQAllOrgUnitsQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQAllOrgUnitsQuery,
    GQTypes.GQAllOrgUnitsQueryVariables
  >({ query: AllOrgUnitsDocument, ...options });
}
export const PaginatedOrgUnitTypesDocument = gql`
    query PaginatedOrgUnitTypes($after: String, $before: String, $first: Int, $last: Int, $search: String, $sortModel: [DataGridSortItemInput!], $versionId: ID!) @withOwner(owner: EnterpriseFoundations) {
  orgUnitTypesPaginated(
    after: $after
    before: $before
    first: $first
    last: $last
    search: $search
    sortModel: $sortModel
    versionId: $versionId
  ) {
    edges {
      cursor
      node {
        ...OrgUnitType
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
      totalRowCount
    }
  }
}
    ${OrgUnitTypeFragmentDoc}`;

export function usePaginatedOrgUnitTypesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQPaginatedOrgUnitTypesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPaginatedOrgUnitTypesQuery,
    GQTypes.GQPaginatedOrgUnitTypesQueryVariables
  >({ query: PaginatedOrgUnitTypesDocument, ...options });
}
export const AllOrgUnitTypesDocument = gql`
    query AllOrgUnitTypes($versionId: ID!) @withOwner(owner: EnterpriseFoundations) {
  orgUnitTypes(versionId: $versionId) {
    ...OrgUnitType
  }
}
    ${OrgUnitTypeFragmentDoc}`;

export function useAllOrgUnitTypesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAllOrgUnitTypesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAllOrgUnitTypesQuery,
    GQTypes.GQAllOrgUnitTypesQueryVariables
  >({ query: AllOrgUnitTypesDocument, ...options });
}
export const OrgUnitRelationshipExplorerPageDocument = gql`
    query OrgUnitRelationshipExplorerPage($versionId: ID!, $orgUnitTypeId: ID) @withOwner(owner: EnterpriseFoundations) {
  orgStructureVersion(versionId: $versionId) {
    id
    isPublished
  }
  orgUnits(versionId: $versionId, orgUnitTypeId: $orgUnitTypeId) {
    ...OrgUnit
  }
  orgUnitTypeRelationships(versionId: $versionId, orgUnitTypeId: $orgUnitTypeId) {
    ...OrgUnitTypeRelationship
  }
  orgUnitTypes(versionId: $versionId) {
    ...OrgUnitTypeForExplorer
  }
}
    ${OrgUnitFragmentDoc}
${OrgUnitTypeRelationshipFragmentDoc}
${OrgUnitTypeForExplorerFragmentDoc}`;

export function useOrgUnitRelationshipExplorerPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgUnitRelationshipExplorerPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgUnitRelationshipExplorerPageQuery,
    GQTypes.GQOrgUnitRelationshipExplorerPageQueryVariables
  >({ query: OrgUnitRelationshipExplorerPageDocument, ...options });
}
export const CreateOrgUnitRelationshipDocument = gql`
    mutation CreateOrgUnitRelationship($input: CreateOrgUnitRelationshipInput!) @withOwner(owner: EnterpriseFoundations) {
  createOrgUnitRelationship(input: $input) {
    orgUnitRelationship {
      id
      orgUnitIdFrom
      orgUnitIdTo
    }
  }
}
    `;

export function useCreateOrgUnitRelationshipMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateOrgUnitRelationshipMutation,
    GQTypes.GQCreateOrgUnitRelationshipMutationVariables
  >(CreateOrgUnitRelationshipDocument);
}
export const GetFootprintAnalysisTopVendorsDataDocument = gql`
    query GetFootprintAnalysisTopVendorsData($footprintSnapshotId: String!, $oneYearAnalysisFilters: FootprintAnalysisFilters!) @withOwner(owner: Calcprint) {
  userHasPermission(permission: ViewFootprintDetail)
  footprintAnalysis(footprintSnapshotId: $footprintSnapshotId) {
    id
    topVendors(filters: $oneYearAnalysisFilters, first: 10) {
      vendors {
        ...FootprintAnalysisTopVendorFields
      }
      summarySentences {
        all
        sbt
        netZero
        other
      }
    }
  }
}
    ${FootprintAnalysisTopVendorFieldsFragmentDoc}`;

export function useGetFootprintAnalysisTopVendorsDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintAnalysisTopVendorsDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintAnalysisTopVendorsDataQuery,
    GQTypes.GQGetFootprintAnalysisTopVendorsDataQueryVariables
  >({ query: GetFootprintAnalysisTopVendorsDataDocument, ...options });
}
export const GetPendingUserPermissionRequestsDocument = gql`
    query GetPendingUserPermissionRequests @withOwner(owner: EnterpriseFoundations) {
  pendingUserPermissionRequests {
    ...UserPermissionRequestFields
  }
}
    ${UserPermissionRequestFieldsFragmentDoc}`;

export function useGetPendingUserPermissionRequestsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPendingUserPermissionRequestsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPendingUserPermissionRequestsQuery,
    GQTypes.GQGetPendingUserPermissionRequestsQueryVariables
  >({ query: GetPendingUserPermissionRequestsDocument, ...options });
}
export const UpdateUserPermissionRequestDocument = gql`
    mutation UpdateUserPermissionRequest($input: UpdateUserPermissionRequestInput!) @withOwner(owner: EnterpriseFoundations) {
  updateUserPermissionRequest(input: $input) {
    userPermissionRequest {
      ...UserPermissionRequestFields
    }
  }
}
    ${UserPermissionRequestFieldsFragmentDoc}`;

export function useUpdateUserPermissionRequestMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateUserPermissionRequestMutation,
    GQTypes.GQUpdateUserPermissionRequestMutationVariables
  >(UpdateUserPermissionRequestDocument);
}
export const PlanAndInitiativesDocument = gql`
    query PlanAndInitiatives($planId: ID!, $suppliersQueryInterval: YMInterval!, $orgId: ID!) @withOwner(owner: Reductions) {
  userCanViewReductions: userHasPermission(permission: ViewReductions)
  userCanManageReductions: userHasPermission(permission: ManageReductionPlans)
  plan(id: $planId) {
    ...PlanWithForecastFields
  }
  suppliersWithTargetDisclosures(footprintInterval: $suppliersQueryInterval) {
    suppliers {
      ...SupplierForReductionsForecast
    }
  }
}
    ${PlanWithForecastFieldsFragmentDoc}
${SupplierForReductionsForecastFragmentDoc}`;

export function usePlanAndInitiativesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQPlanAndInitiativesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPlanAndInitiativesQuery,
    GQTypes.GQPlanAndInitiativesQueryVariables
  >({ query: PlanAndInitiativesDocument, ...options });
}
export const ReduxEnterprisePlanAndInitiativesDocument = gql`
    query ReduxEnterprisePlanAndInitiatives($planId: ID!) @withOwner(owner: Reductions) {
  userCanViewReductions: userHasPermission(permission: ViewReductions)
  userCanManageReductions: userHasPermission(permission: ManageReductionPlans)
  organization {
    id
    sbtiTargetExclusions {
      ...SbtiTargetExclusionFields
    }
  }
  plan(id: $planId) {
    ...PlanWithForecastFields
  }
}
    ${SbtiTargetExclusionFieldsFragmentDoc}
${PlanWithForecastFieldsFragmentDoc}`;

export function useReduxEnterprisePlanAndInitiativesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReduxEnterprisePlanAndInitiativesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReduxEnterprisePlanAndInitiativesQuery,
    GQTypes.GQReduxEnterprisePlanAndInitiativesQueryVariables
  >({ query: ReduxEnterprisePlanAndInitiativesDocument, ...options });
}
export const UpdatePlanDocument = gql`
    mutation UpdatePlan($input: UpdatePlanAndArtifactsInput!) @withOwner(owner: Reductions) {
  updatePlan(input: $input) {
    plan {
      ...PlanWithForecastFields
    }
  }
}
    ${PlanWithForecastFieldsFragmentDoc}`;

export function useUpdatePlanMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdatePlanMutation,
    GQTypes.GQUpdatePlanMutationVariables
  >(UpdatePlanDocument);
}
export const DuplicatePlanDocument = gql`
    mutation DuplicatePlan($input: DuplicatePlanInput!) @withOwner(owner: Reductions) {
  duplicatePlan(input: $input) {
    plan {
      ...PlanWithForecastFields
    }
  }
}
    ${PlanWithForecastFieldsFragmentDoc}`;

export function useDuplicatePlanMutation() {
  return Urql.useMutation<
    GQTypes.GQDuplicatePlanMutation,
    GQTypes.GQDuplicatePlanMutationVariables
  >(DuplicatePlanDocument);
}
export const UpdatePlanTargetCategorySetDescriptionDocument = gql`
    mutation UpdatePlanTargetCategorySetDescription($input: UpdatePlanTargetInput!) @withOwner(owner: Reductions) {
  updatePlanTarget(input: $input) {
    planTarget {
      ...PlanTargetFields
    }
  }
}
    ${PlanTargetFieldsFragmentDoc}`;

export function useUpdatePlanTargetCategorySetDescriptionMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdatePlanTargetCategorySetDescriptionMutation,
    GQTypes.GQUpdatePlanTargetCategorySetDescriptionMutationVariables
  >(UpdatePlanTargetCategorySetDescriptionDocument);
}
export const PeerReductionTargetsDocument = gql`
    query PeerReductionTargets @withOwner(owner: Reductions) {
  peerCompanyGroups {
    id
    name
    members {
      id
      company {
        id
        name
        fullLogoUrl
        disclosuresV2(opts: {targetFilters: {}, climateCommitmentFilters: {}}) {
          id
          targets {
            id
          }
          climateCommitments {
            ...PeerCommitmentFields
          }
        }
      }
    }
  }
}
    ${PeerCommitmentFieldsFragmentDoc}`;

export function usePeerReductionTargetsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQPeerReductionTargetsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPeerReductionTargetsQuery,
    GQTypes.GQPeerReductionTargetsQueryVariables
  >({ query: PeerReductionTargetsDocument, ...options });
}
export const CreatePrecomputedActivityBasedInitiativeDocument = gql`
    mutation CreatePrecomputedActivityBasedInitiative($input: CreatePrecomputedActivityBasedInitiativeInput!) @withOwner(owner: Reductions) {
  createPrecomputedActivityBasedInitiative(input: $input) {
    plan {
      ...TargetAndInitiativeForecastFields
    }
  }
}
    ${TargetAndInitiativeForecastFieldsFragmentDoc}`;

export function useCreatePrecomputedActivityBasedInitiativeMutation() {
  return Urql.useMutation<
    GQTypes.GQCreatePrecomputedActivityBasedInitiativeMutation,
    GQTypes.GQCreatePrecomputedActivityBasedInitiativeMutationVariables
  >(CreatePrecomputedActivityBasedInitiativeDocument);
}
export const UpdatePrecomputedActivityBasedInitiativeDocument = gql`
    mutation UpdatePrecomputedActivityBasedInitiative($input: UpdatePrecomputedActivityBasedInitiativeInput!) @withOwner(owner: Reductions) {
  updatePrecomputedActivityBasedInitiative(input: $input) {
    plan {
      ...TargetAndInitiativeForecastFields
    }
  }
}
    ${TargetAndInitiativeForecastFieldsFragmentDoc}`;

export function useUpdatePrecomputedActivityBasedInitiativeMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdatePrecomputedActivityBasedInitiativeMutation,
    GQTypes.GQUpdatePrecomputedActivityBasedInitiativeMutationVariables
  >(UpdatePrecomputedActivityBasedInitiativeDocument);
}
export const CreateFootprintSliceInitiativeDocument = gql`
    mutation CreateFootprintSliceInitiative($input: CreateFootprintSliceInitiativeInput!) @withOwner(owner: Reductions) {
  createFootprintSliceInitiative(input: $input) {
    plan {
      ...TargetAndInitiativeForecastFields
    }
  }
}
    ${TargetAndInitiativeForecastFieldsFragmentDoc}`;

export function useCreateFootprintSliceInitiativeMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFootprintSliceInitiativeMutation,
    GQTypes.GQCreateFootprintSliceInitiativeMutationVariables
  >(CreateFootprintSliceInitiativeDocument);
}
export const UpdateFootprintSliceInitiativeDocument = gql`
    mutation UpdateFootprintSliceInitiative($input: UpdateFootprintSliceInitiativeInput!) @withOwner(owner: Reductions) {
  updateFootprintSliceInitiative(input: $input) {
    initiative {
      ...ReduxInitiativeFields
    }
    plan {
      ...TargetAndInitiativeForecastFields
    }
  }
}
    ${ReduxInitiativeFieldsFragmentDoc}
${TargetAndInitiativeForecastFieldsFragmentDoc}`;

export function useUpdateFootprintSliceInitiativeMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateFootprintSliceInitiativeMutation,
    GQTypes.GQUpdateFootprintSliceInitiativeMutationVariables
  >(UpdateFootprintSliceInitiativeDocument);
}
export const CreatePlanTargetReduxDocument = gql`
    mutation CreatePlanTargetRedux($input: CreatePlanTargetReduxInput!) @withOwner(owner: Reductions) {
  createPlanTargetRedux(input: $input) {
    plan {
      id
      targets {
        ...PlanTargetFields
      }
    }
  }
}
    ${PlanTargetFieldsFragmentDoc}`;

export function useCreatePlanTargetReduxMutation() {
  return Urql.useMutation<
    GQTypes.GQCreatePlanTargetReduxMutation,
    GQTypes.GQCreatePlanTargetReduxMutationVariables
  >(CreatePlanTargetReduxDocument);
}
export const CreateRenewableElectricityTargetDocument = gql`
    mutation CreateRenewableElectricityTarget($input: CreateRenewableElectricityTargetInput!) @withOwner(owner: Reductions) {
  createRenewableElectricityTarget(input: $input) {
    plan {
      id
      targets {
        ...PlanTargetFields
      }
    }
  }
}
    ${PlanTargetFieldsFragmentDoc}`;

export function useCreateRenewableElectricityTargetMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateRenewableElectricityTargetMutation,
    GQTypes.GQCreateRenewableElectricityTargetMutationVariables
  >(CreateRenewableElectricityTargetDocument);
}
export const DeleteInitiativeDocument = gql`
    mutation DeleteInitiative($initiativeId: ID!) @withOwner(owner: Reductions) {
  deleteInitiative(input: {initiativeId: $initiativeId}) {
    initiative {
      ...ReduxInitiativeFields
    }
    plan {
      ...TargetAndInitiativeForecastFields
    }
  }
}
    ${ReduxInitiativeFieldsFragmentDoc}
${TargetAndInitiativeForecastFieldsFragmentDoc}`;

export function useDeleteInitiativeMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteInitiativeMutation,
    GQTypes.GQDeleteInitiativeMutationVariables
  >(DeleteInitiativeDocument);
}
export const DuplicateInitiativeDocument = gql`
    mutation DuplicateInitiative($duplicatedInitiativeId: ID!, $kind: InitiativeKind!, $planId: ID!) @withOwner(owner: Reductions) {
  duplicateInitiative(
    input: {duplicatedInitiativeId: $duplicatedInitiativeId, kind: $kind, planId: $planId}
  ) {
    initiative {
      id
    }
    plan {
      ...TargetAndInitiativeForecastFields
    }
  }
}
    ${TargetAndInitiativeForecastFieldsFragmentDoc}`;

export function useDuplicateInitiativeMutation() {
  return Urql.useMutation<
    GQTypes.GQDuplicateInitiativeMutation,
    GQTypes.GQDuplicateInitiativeMutationVariables
  >(DuplicateInitiativeDocument);
}
export const AllPlansAndForecastScenariosDocument = gql`
    query AllPlansAndForecastScenarios @withOwner(owner: Reductions) {
  plans(includeHidden: true) {
    edges {
      node {
        ...PlanForPlanTab
      }
    }
  }
  forecast {
    ...ForecastForScenarioSelector
  }
}
    ${PlanForPlanTabFragmentDoc}
${ForecastForScenarioSelectorFragmentDoc}`;

export function useAllPlansAndForecastScenariosQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQAllPlansAndForecastScenariosQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAllPlansAndForecastScenariosQuery,
    GQTypes.GQAllPlansAndForecastScenariosQueryVariables
  >({ query: AllPlansAndForecastScenariosDocument, ...options });
}
export const CreatePlanDashboardDocument = gql`
    mutation CreatePlanDashboard($termLength: SBTTermLength) @withOwner(owner: Reductions) {
  createPlanWithDefaults(input: {termLength: $termLength}) {
    plan {
      ...PlanWithForecastFields
    }
  }
}
    ${PlanWithForecastFieldsFragmentDoc}`;

export function useCreatePlanDashboardMutation() {
  return Urql.useMutation<
    GQTypes.GQCreatePlanDashboardMutation,
    GQTypes.GQCreatePlanDashboardMutationVariables
  >(CreatePlanDashboardDocument);
}
export const RenamePlanFromPlanTabDocument = gql`
    mutation RenamePlanFromPlanTab($planId: ID!, $newName: String!) @withOwner(owner: Reductions) {
  updatePlanSettings(input: {planId: $planId, name: $newName}) {
    plan {
      id
      name
    }
  }
}
    `;

export function useRenamePlanFromPlanTabMutation() {
  return Urql.useMutation<
    GQTypes.GQRenamePlanFromPlanTabMutation,
    GQTypes.GQRenamePlanFromPlanTabMutationVariables
  >(RenamePlanFromPlanTabDocument);
}
export const DuplicatePlanFromPlanTabDocument = gql`
    mutation DuplicatePlanFromPlanTab($input: DuplicatePlanInput!) @withOwner(owner: Reductions) {
  duplicatePlan(input: $input) {
    plan {
      id
      name
    }
  }
}
    `;

export function useDuplicatePlanFromPlanTabMutation() {
  return Urql.useMutation<
    GQTypes.GQDuplicatePlanFromPlanTabMutation,
    GQTypes.GQDuplicatePlanFromPlanTabMutationVariables
  >(DuplicatePlanFromPlanTabDocument);
}
export const DeletePlanFromPlanTabDocument = gql`
    mutation DeletePlanFromPlanTab($input: DeletePlanInput!) @withOwner(owner: Reductions) {
  deletePlan(input: $input)
}
    `;

export function useDeletePlanFromPlanTabMutation() {
  return Urql.useMutation<
    GQTypes.GQDeletePlanFromPlanTabMutation,
    GQTypes.GQDeletePlanFromPlanTabMutationVariables
  >(DeletePlanFromPlanTabDocument);
}
export const ElectricityConsumptionBreakdownDocument = gql`
    query ElectricityConsumptionBreakdown($planId: ID!) @withOwner(owner: Reductions) {
  plan(id: $planId) {
    id
    forecast {
      id
      electricityConsumptionBreakdown {
        yearStart
        renewableEnergyFraction
      }
    }
  }
}
    `;

export function useElectricityConsumptionBreakdownQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQElectricityConsumptionBreakdownQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQElectricityConsumptionBreakdownQuery,
    GQTypes.GQElectricityConsumptionBreakdownQueryVariables
  >({ query: ElectricityConsumptionBreakdownDocument, ...options });
}
export const UpdatePlanTargetReduxDocument = gql`
    mutation UpdatePlanTargetRedux($input: UpdatePlanTargetInput!) @withOwner(owner: Reductions) {
  updatePlanTarget(input: $input) {
    planTarget {
      ...PlanTargetFields
    }
    forecast {
      id
      validYearsForIntervalEnd
    }
  }
}
    ${PlanTargetFieldsFragmentDoc}`;

export function useUpdatePlanTargetReduxMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdatePlanTargetReduxMutation,
    GQTypes.GQUpdatePlanTargetReduxMutationVariables
  >(UpdatePlanTargetReduxDocument);
}
export const DeletePlanTargetReduxDocument = gql`
    mutation DeletePlanTargetRedux($input: DeletePlanTargetInput!) @withOwner(owner: Reductions) {
  deletePlanTarget(input: $input) {
    plan {
      id
      targets {
        id
        deletedAt
      }
    }
  }
}
    `;

export function useDeletePlanTargetReduxMutation() {
  return Urql.useMutation<
    GQTypes.GQDeletePlanTargetReduxMutation,
    GQTypes.GQDeletePlanTargetReduxMutationVariables
  >(DeletePlanTargetReduxDocument);
}
export const PlansForCompareSheetDocument = gql`
    query PlansForCompareSheet @withOwner(owner: Reductions) {
  plans(includeHidden: true) {
    edges {
      node {
        id
        isHidden
        ...PlanWithForecastFields
      }
    }
  }
  organization {
    id
    sbtiTargetExclusions {
      ...SbtiTargetExclusionFields
    }
  }
}
    ${PlanWithForecastFieldsFragmentDoc}
${SbtiTargetExclusionFieldsFragmentDoc}`;

export function usePlansForCompareSheetQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQPlansForCompareSheetQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPlansForCompareSheetQuery,
    GQTypes.GQPlansForCompareSheetQueryVariables
  >({ query: PlansForCompareSheetDocument, ...options });
}
export const GenerateCustomIntensityConfigIdDocument = gql`
    mutation GenerateCustomIntensityConfigId($input: GenerateCustomIntensityConfigIdInput!) @withOwner(owner: Reductions) {
  generateCustomIntensityConfigId(input: $input) {
    id
  }
}
    `;

export function useGenerateCustomIntensityConfigIdMutation() {
  return Urql.useMutation<
    GQTypes.GQGenerateCustomIntensityConfigIdMutation,
    GQTypes.GQGenerateCustomIntensityConfigIdMutationVariables
  >(GenerateCustomIntensityConfigIdDocument);
}
export const GenerateForecastScenarioIdDocument = gql`
    mutation GenerateForecastScenarioId($input: GenerateForecastScenarioIdInput!) @withOwner(owner: Reductions) {
  generateForecastScenarioId(input: $input) {
    id
  }
}
    `;

export function useGenerateForecastScenarioIdMutation() {
  return Urql.useMutation<
    GQTypes.GQGenerateForecastScenarioIdMutation,
    GQTypes.GQGenerateForecastScenarioIdMutationVariables
  >(GenerateForecastScenarioIdDocument);
}
export const CreateUpdateAndDeleteForecastScenariosDocument = gql`
    mutation CreateUpdateAndDeleteForecastScenarios($input: CreateUpdateAndDeleteForecastScenariosInput!) @withOwner(owner: Reductions) {
  createUpdateAndDeleteForecastScenarios(input: $input) {
    forecast {
      id
      scenarios {
        ...ForecastScenarioFields
      }
    }
  }
}
    ${ForecastScenarioFieldsFragmentDoc}`;

export function useCreateUpdateAndDeleteForecastScenariosMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateUpdateAndDeleteForecastScenariosMutation,
    GQTypes.GQCreateUpdateAndDeleteForecastScenariosMutationVariables
  >(CreateUpdateAndDeleteForecastScenariosDocument);
}
export const GetReductionsContributionsDocument = gql`
    query GetReductionsContributions($footprintKind: String!, $footprintSnapshotId: String, $timeseriesKind: ColumnDimension!, $columns: [String!], $enableReduxContributionsIntervalOptimization: Boolean) @withOwner(owner: Reductions) {
  footprint: legacyFootprint(
    shouldConvertCurrency: false
    version: $footprintSnapshotId
  ) {
    id
    summary(footprintKind: $footprintKind) {
      id
      timeseries {
        id
        series {
          data {
            date
            value
            headcount
            revenue
            customIntensities {
              customIntensityId
              value
            }
          }
        }
      }
      removalTimeseries: timeseries(filters: {inclusionKind: Removal}) {
        id
        series {
          data {
            date
            value
            headcount
            revenue
            customIntensities {
              customIntensityId
              value
            }
          }
        }
      }
      customIntensityMetadata {
        id
      }
    }
  }
  footprintAnalysis(footprintSnapshotId: $footprintSnapshotId) {
    id
    reductionsContributionsData(
      timeseriesKind: $timeseriesKind
      footprintKind: $footprintKind
      columns: $columns
      enableReduxContributionsIntervalOptimization: $enableReduxContributionsIntervalOptimization
    ) {
      id
      data
    }
  }
}
    `;

export function useGetReductionsContributionsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReductionsContributionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReductionsContributionsQuery,
    GQTypes.GQGetReductionsContributionsQueryVariables
  >({ query: GetReductionsContributionsDocument, ...options });
}
export const GetForecastFootprintSnapshotIdDocument = gql`
    query GetForecastFootprintSnapshotId @withOwner(owner: Reductions) {
  forecast {
    id
    footprintSnapshotId
  }
}
    `;

export function useGetForecastFootprintSnapshotIdQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetForecastFootprintSnapshotIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetForecastFootprintSnapshotIdQuery,
    GQTypes.GQGetForecastFootprintSnapshotIdQueryVariables
  >({ query: GetForecastFootprintSnapshotIdDocument, ...options });
}
export const AssignToContainerDocument = gql`
    mutation AssignToContainer($input: AssignToContainerInput!, $reportId: ID!) @withOwner(owner: Reporting) {
  assignToContainer(input: $input) {
    container {
      id
      assignees(reportId: $reportId) {
        id
        displayName
        name
      }
      items {
        ...AssigneeInfoForContainedQuestions
      }
    }
  }
}
    ${AssigneeInfoForContainedQuestionsFragmentDoc}`;

export function useAssignToContainerMutation() {
  return Urql.useMutation<
    GQTypes.GQAssignToContainerMutation,
    GQTypes.GQAssignToContainerMutationVariables
  >(AssignToContainerDocument);
}
export const GetReportQuestionContainerAssignmentEmailDocument = gql`
    query GetReportQuestionContainerAssignmentEmail($input: AssignReportQuestionContainerPreviewEmailInput!) @withOwner(owner: Reporting) {
  assignReportQuestionContainerPreviewEmail(input: $input) {
    ...EmailPreview_emailContents
  }
}
    ${EmailPreview_EmailContentsFragmentDoc}`;

export function useGetReportQuestionContainerAssignmentEmailQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportQuestionContainerAssignmentEmailQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportQuestionContainerAssignmentEmailQuery,
    GQTypes.GQGetReportQuestionContainerAssignmentEmailQueryVariables
  >({ query: GetReportQuestionContainerAssignmentEmailDocument, ...options });
}
export const CreateReportWithCustomConfigDocument = gql`
    mutation CreateReportWithCustomConfig($input: CreateReportWithCustomConfigInput!) @withOwner(owner: Reporting) {
  createReportWithCustomConfig(input: $input) {
    report {
      id
      name
      reportKind
      reportStatus
    }
  }
}
    `;

export function useCreateReportWithCustomConfigMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateReportWithCustomConfigMutation,
    GQTypes.GQCreateReportWithCustomConfigMutationVariables
  >(CreateReportWithCustomConfigDocument);
}
export const RenameReportDocument = gql`
    mutation RenameReport($input: RenameReportInput!) @withOwner(owner: Reporting) {
  renameReport(input: $input) {
    report {
      id
      name
    }
  }
}
    `;

export function useRenameReportMutation() {
  return Urql.useMutation<
    GQTypes.GQRenameReportMutation,
    GQTypes.GQRenameReportMutationVariables
  >(RenameReportDocument);
}
export const CreateReportWithConfigDocument = gql`
    mutation CreateReportWithConfig($input: CreateReportWithConfigInput!) @withOwner(owner: Reporting) {
  createReportWithConfig(input: $input) {
    id
  }
}
    `;

export function useCreateReportWithConfigMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateReportWithConfigMutation,
    GQTypes.GQCreateReportWithConfigMutationVariables
  >(CreateReportWithConfigDocument);
}
export const ReportsPageDocument = gql`
    query ReportsPage($reportMode: ReportMode!) @withOwner(owner: Reporting) {
  formalReports(reportMode: $reportMode) {
    edges {
      node {
        id
        ...ReportPageReport
      }
    }
  }
  userHasPermission(permission: Admin)
  organization {
    id
    currency
  }
}
    ${ReportPageReportFragmentDoc}`;

export function useReportsPageQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQReportsPageQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQReportsPageQuery,
    GQTypes.GQReportsPageQueryVariables
  >({ query: ReportsPageDocument, ...options });
}
export const ArchiveReportDocument = gql`
    mutation ArchiveReport($reportId: ID!) @withOwner(owner: Reporting) {
  archiveReport(input: {reportId: $reportId}) {
    report {
      id
      deletedAt
    }
  }
}
    `;

export function useArchiveReportMutation() {
  return Urql.useMutation<
    GQTypes.GQArchiveReportMutation,
    GQTypes.GQArchiveReportMutationVariables
  >(ArchiveReportDocument);
}
export const ReportAttachmentItemsDocument = gql`
    query ReportAttachmentItems($kinds: [ReportAttachmentItemKind!]!) @withOwner(owner: Reporting) {
  reportAttachmentItems(kinds: $kinds) {
    ...ReportFileAttachmentItemFields
  }
}
    ${ReportFileAttachmentItemFieldsFragmentDoc}`;

export function useReportAttachmentItemsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportAttachmentItemsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportAttachmentItemsQuery,
    GQTypes.GQReportAttachmentItemsQueryVariables
  >({ query: ReportAttachmentItemsDocument, ...options });
}
export const CreateReportUrlAttachmentItemDocument = gql`
    mutation CreateReportURLAttachmentItem($input: CreateReportURLAttachmentItemInput!) @withOwner(owner: Reporting) {
  createReportURLAttachmentItem(input: $input) {
    url
    attachment {
      ...ReportFileAttachmentItemFields
    }
  }
}
    ${ReportFileAttachmentItemFieldsFragmentDoc}`;

export function useCreateReportUrlAttachmentItemMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateReportUrlAttachmentItemMutation,
    GQTypes.GQCreateReportUrlAttachmentItemMutationVariables
  >(CreateReportUrlAttachmentItemDocument);
}
export const CreateReportFileAttachmentsDocument = gql`
    mutation CreateReportFileAttachments($input: CreateReportFileAttachmentsInput!) @withOwner(owner: Reporting) {
  createReportFileAttachments(input: $input) {
    items {
      url
      attachment {
        ...ReportFileAttachmentItemFields
      }
    }
  }
}
    ${ReportFileAttachmentItemFieldsFragmentDoc}`;

export function useCreateReportFileAttachmentsMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateReportFileAttachmentsMutation,
    GQTypes.GQCreateReportFileAttachmentsMutationVariables
  >(CreateReportFileAttachmentsDocument);
}
export const CreateReportAttachmentsDocument = gql`
    mutation CreateReportAttachments($input: CreateReportAttachmentsInput!) @withOwner(owner: Reporting) {
  createReportAttachments(input: $input) {
    attachmentItems {
      ...ReportAttachmentForList
    }
  }
}
    ${ReportAttachmentForListFragmentDoc}`;

export function useCreateReportAttachmentsMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateReportAttachmentsMutation,
    GQTypes.GQCreateReportAttachmentsMutationVariables
  >(CreateReportAttachmentsDocument);
}
export const DeleteReportAttachmentItemDocument = gql`
    mutation DeleteReportAttachmentItem($input: DeleteReportAttachmentItemInput!) @withOwner(owner: Reporting) {
  deleteReportAttachmentItem(input: $input) {
    success
  }
}
    `;

export function useDeleteReportAttachmentItemMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteReportAttachmentItemMutation,
    GQTypes.GQDeleteReportAttachmentItemMutationVariables
  >(DeleteReportAttachmentItemDocument);
}
export const DeleteReportAttachmentDocument = gql`
    mutation DeleteReportAttachment($input: DeleteReportAttachmentInput!) @withOwner(owner: Reporting) {
  deleteReportAttachment(input: $input) {
    success
  }
}
    `;

export function useDeleteReportAttachmentMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteReportAttachmentMutation,
    GQTypes.GQDeleteReportAttachmentMutationVariables
  >(DeleteReportAttachmentDocument);
}
export const UpdateApproversForReportQuestionInstancesDocument = gql`
    mutation UpdateApproversForReportQuestionInstances($input: UpdateApproversForReportQuestionInstancesInput!) @withOwner(owner: Reporting) {
  updateApproversForReportQuestionInstances(input: $input) {
    success
    reportQuestionInstances {
      id
      approvalInfo {
        ...QuestionApprovalInfo
      }
    }
  }
}
    ${QuestionApprovalInfoFragmentDoc}`;

export function useUpdateApproversForReportQuestionInstancesMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateApproversForReportQuestionInstancesMutation,
    GQTypes.GQUpdateApproversForReportQuestionInstancesMutationVariables
  >(UpdateApproversForReportQuestionInstancesDocument);
}
export const ReportCommandPaletteAddUsersPageDocument = gql`
    query ReportCommandPaletteAddUsersPage($ids: [ID!]!) @withOwner(owner: Reporting) {
  reportQuestionInstancesAggregate(ids: $ids) {
    userAccesses {
      user {
        id
      }
      edit {
        count
        originsWithMetadata {
          isGlobal
          permission
        }
      }
      view {
        count
        originsWithMetadata {
          isGlobal
          permission
        }
      }
    }
  }
}
    `;

export function useReportCommandPaletteAddUsersPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportCommandPaletteAddUsersPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportCommandPaletteAddUsersPageQuery,
    GQTypes.GQReportCommandPaletteAddUsersPageQueryVariables
  >({ query: ReportCommandPaletteAddUsersPageDocument, ...options });
}
export const ReportManagePermissionsPageDocument = gql`
    query ReportManagePermissionsPage($id: ID!) @withOwner(owner: Reporting) {
  allUsers {
    id
    displayName
    email
  }
  formalReport(id: $id) {
    id
    name
    userAccess {
      edit {
        originsWithMetadata @_required {
          permission
          isGlobal
        }
      }
      view {
        originsWithMetadata @_required {
          permission
          isGlobal
        }
      }
      user {
        id
      }
    }
  }
}
    `;

export function useReportManagePermissionsPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportManagePermissionsPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportManagePermissionsPageQuery,
    GQTypes.GQReportManagePermissionsPageQueryVariables
  >({ query: ReportManagePermissionsPageDocument, ...options });
}
export const UpsertReportsAccessDocument = gql`
    mutation upsertReportsAccess($input: UpsertReportsAccessInput!) @withOwner(owner: Reporting) {
  upsertReportsAccess(input: $input) {
    success
  }
}
    `;

export function useUpsertReportsAccessMutation() {
  return Urql.useMutation<
    GQTypes.GQUpsertReportsAccessMutation,
    GQTypes.GQUpsertReportsAccessMutationVariables
  >(UpsertReportsAccessDocument);
}
export const BatchUpdateReportQuestionAssigneesDocument = gql`
    mutation batchUpdateReportQuestionAssignees($input: BatchUpdateReportQuestionAssigneesInput!, $reportId: ID!) @withOwner(owner: Reporting) {
  batchUpdateReportQuestionAssignees(input: $input) {
    questions {
      id
      assignees(reportId: $reportId) {
        id
        displayName
        name
      }
      instance(reportId: $reportId) {
        id
      }
      parentContainer {
        __typename
        ... on ReportQuestionContainer {
          ...ContainerAssignees
          parentContainer {
            ...ContainerAssignees
            parentContainer {
              ...ContainerAssignees
              parentContainer {
                ...ContainerAssignees
                parentContainer {
                  ...ContainerAssignees
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${ContainerAssigneesFragmentDoc}`;

export function useBatchUpdateReportQuestionAssigneesMutation() {
  return Urql.useMutation<
    GQTypes.GQBatchUpdateReportQuestionAssigneesMutation,
    GQTypes.GQBatchUpdateReportQuestionAssigneesMutationVariables
  >(BatchUpdateReportQuestionAssigneesDocument);
}
export const ReportCommandPaletteCheckAccessPageDocument = gql`
    query ReportCommandPaletteCheckAccessPage($ids: [ID!]!) @withOwner(owner: Reporting) {
  reportQuestionInstancesAggregate(ids: $ids) {
    userAccesses {
      user {
        id
      }
      edit {
        count
        originsWithMetadata {
          isGlobal
          permission
        }
      }
      view {
        count
        originsWithMetadata {
          isGlobal
          permission
        }
      }
    }
  }
}
    `;

export function useReportCommandPaletteCheckAccessPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportCommandPaletteCheckAccessPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportCommandPaletteCheckAccessPageQuery,
    GQTypes.GQReportCommandPaletteCheckAccessPageQueryVariables
  >({ query: ReportCommandPaletteCheckAccessPageDocument, ...options });
}
export const UpsertReportQuestionInstancesAccessDocument = gql`
    mutation upsertReportQuestionInstancesAccess($input: UpsertReportQuestionInstancesAccessInput!) @withOwner(owner: Reporting) {
  upsertReportQuestionInstancesAccess(input: $input) {
    success
  }
}
    `;

export function useUpsertReportQuestionInstancesAccessMutation() {
  return Urql.useMutation<
    GQTypes.GQUpsertReportQuestionInstancesAccessMutation,
    GQTypes.GQUpsertReportQuestionInstancesAccessMutationVariables
  >(UpsertReportQuestionInstancesAccessDocument);
}
export const ReportQuestionCommandPaletteDocument = gql`
    query ReportQuestionCommandPalette @withOwner(owner: Reporting) {
  allUsers {
    id
    displayName
    email
  }
}
    `;

export function useReportQuestionCommandPaletteQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportQuestionCommandPaletteQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportQuestionCommandPaletteQuery,
    GQTypes.GQReportQuestionCommandPaletteQueryVariables
  >({ query: ReportQuestionCommandPaletteDocument, ...options });
}
export const GetDrilldownForReportQuestionDocument = gql`
    query GetDrilldownForReportQuestion($reportId: ID!, $questionId: ID!) @withOwner(owner: Reporting) {
  drilldownForReportQuestion(
    input: {reportId: $reportId, questionId: $questionId}
  ) {
    report {
      ...ReportWithConfigFields
      id
      name
    }
    question {
      ...ReportItem_question
      ...ReportQuestionUsingSavedViewFields
    }
    mergedControlsJson
    savedViewControlsJson
    reportControlsJson
    cardControlsJson
  }
}
    ${ReportWithConfigFieldsFragmentDoc}
${ReportItem_QuestionFragmentDoc}
${ReportQuestionUsingSavedViewFieldsFragmentDoc}`;

export function useGetDrilldownForReportQuestionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetDrilldownForReportQuestionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetDrilldownForReportQuestionQuery,
    GQTypes.GQGetDrilldownForReportQuestionQueryVariables
  >({ query: GetDrilldownForReportQuestionDocument, ...options });
}
export const UpdateReportConfigDocument = gql`
    mutation UpdateReportConfig($reportId: ID!, $input: UpdateReportConfigDashboardInput!) @withOwner(owner: Reporting) {
  updateReportConfigDashboard(input: $input) {
    reportConfig {
      ...ReportConfigFields
    }
  }
}
    ${ReportConfigFieldsFragmentDoc}`;

export function useUpdateReportConfigMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateReportConfigMutation,
    GQTypes.GQUpdateReportConfigMutationVariables
  >(UpdateReportConfigDocument);
}
export const GetReportQuestionsListDocument = gql`
    query GetReportQuestionsList($reportId: ID!) @withOwner(owner: Reporting) {
  reportWithConfig(id: $reportId) {
    ...ReportWithConfigForTable
  }
}
    ${ReportWithConfigForTableFragmentDoc}`;

export function useGetReportQuestionsListQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportQuestionsListQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportQuestionsListQuery,
    GQTypes.GQGetReportQuestionsListQueryVariables
  >({ query: GetReportQuestionsListDocument, ...options });
}
export const ReportPermissionsTabContentDocument = gql`
    query ReportPermissionsTabContent($id: ID!) @withOwner(owner: Reporting) {
  formalReport(id: $id) {
    id
    userAccess {
      edit {
        __typename
      }
      view {
        __typename
      }
      user {
        id
        displayName
      }
    }
  }
}
    `;

export function useReportPermissionsTabContentQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportPermissionsTabContentQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportPermissionsTabContentQuery,
    GQTypes.GQReportPermissionsTabContentQueryVariables
  >({ query: ReportPermissionsTabContentDocument, ...options });
}
export const GetContainerAndQuestionsForHistoryDocument = gql`
    query GetContainerAndQuestionsForHistory($reportId: ID!, $id: ID!, $questionStrategy: Boolean!) @withOwner(owner: Reporting) {
  reportQuestion(id: $id) @include(if: $questionStrategy) {
    id
    parentContainer {
      id
      allVisibleQuestions(reportId: $reportId) {
        ...ReportQuestionForHistoryNodeFields
      }
    }
  }
  reportQuestionContainer(id: $id) @skip(if: $questionStrategy) {
    id
    allVisibleQuestions(reportId: $reportId) {
      ...ReportQuestionForHistoryNodeFields
    }
  }
}
    ${ReportQuestionForHistoryNodeFieldsFragmentDoc}`;

export function useGetContainerAndQuestionsForHistoryQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetContainerAndQuestionsForHistoryQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetContainerAndQuestionsForHistoryQuery,
    GQTypes.GQGetContainerAndQuestionsForHistoryQueryVariables
  >({ query: GetContainerAndQuestionsForHistoryDocument, ...options });
}
export const PeerReportsDocument = gql`
    query PeerReports($reportType: ExternalReportType, $limit: Int) @withOwner(owner: Reporting) {
  publicDisclosures(mustHaveURL: true, limit: $limit, reportType: $reportType) {
    ...PublicDisclosureDisplay
  }
  organization {
    id
    company {
      id
    }
  }
}
    ${PublicDisclosureDisplayFragmentDoc}`;

export function usePeerReportsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQPeerReportsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPeerReportsQuery,
    GQTypes.GQPeerReportsQueryVariables
  >({ query: PeerReportsDocument, ...options });
}
export const GetContainerAndQuestionsForClimateIntelligencePanelDocument = gql`
    query GetContainerAndQuestionsForClimateIntelligencePanel($containerId: ID!, $reportId: ID!) @withOwner(owner: Reporting) {
  reportQuestionContainer(id: $containerId) {
    id
    guidanceJson
    allVisibleQuestions(reportId: $reportId) {
      __typename
      ... on ReportQuestion {
        ...ReportQuestionForBenchmark
      }
    }
  }
}
    ${ReportQuestionForBenchmarkFragmentDoc}`;

export function useGetContainerAndQuestionsForClimateIntelligencePanelQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetContainerAndQuestionsForClimateIntelligencePanelQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetContainerAndQuestionsForClimateIntelligencePanelQuery,
    GQTypes.GQGetContainerAndQuestionsForClimateIntelligencePanelQueryVariables
  >({
    query: GetContainerAndQuestionsForClimateIntelligencePanelDocument,
    ...options,
  });
}
export const AddHeaderToCustomReportDocument = gql`
    mutation AddHeaderToCustomReport($input: AddHeaderToCustomReportInput!, $reportId: ID!) @withOwner(owner: Reporting) {
  addHeaderToCustomReport(input: $input) {
    report {
      ...CustomReportContent
    }
  }
}
    ${CustomReportContentFragmentDoc}`;

export function useAddHeaderToCustomReportMutation() {
  return Urql.useMutation<
    GQTypes.GQAddHeaderToCustomReportMutation,
    GQTypes.GQAddHeaderToCustomReportMutationVariables
  >(AddHeaderToCustomReportDocument);
}
export const GetCustomReportControlsDocument = gql`
    query GetCustomReportControls($reportId: ID!) @withOwner(owner: Reporting) {
  customReportControls(input: {reportId: $reportId}) {
    customReportControlsQuestion {
      ...ReportItem_question
      id
    }
  }
}
    ${ReportItem_QuestionFragmentDoc}`;

export function useGetCustomReportControlsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCustomReportControlsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCustomReportControlsQuery,
    GQTypes.GQGetCustomReportControlsQueryVariables
  >({ query: GetCustomReportControlsDocument, ...options });
}
export const RestoreDeletedSavedViewDocument = gql`
    mutation RestoreDeletedSavedView($input: RestoreDeletedSavedViewInput!) @withOwner(owner: Reporting) {
  restoreDeletedSavedView(input: $input) {
    ...BiSavedView
  }
}
    ${BiSavedViewFragmentDoc}`;

export function useRestoreDeletedSavedViewMutation() {
  return Urql.useMutation<
    GQTypes.GQRestoreDeletedSavedViewMutation,
    GQTypes.GQRestoreDeletedSavedViewMutationVariables
  >(RestoreDeletedSavedViewDocument);
}
export const NotifyReportAssigneesDocument = gql`
    mutation NotifyReportAssignees($input: NotifyReportAssigneesInput!) @withOwner(owner: Reporting) {
  notifyReportAssignees(input: $input) {
    notifiedUserIds
  }
}
    `;

export function useNotifyReportAssigneesMutation() {
  return Urql.useMutation<
    GQTypes.GQNotifyReportAssigneesMutation,
    GQTypes.GQNotifyReportAssigneesMutationVariables
  >(NotifyReportAssigneesDocument);
}
export const SubmitReportQuestionInstanceDocument = gql`
    mutation SubmitReportQuestionInstance($input: SubmitReportQuestionInstanceInput!) @withOwner(owner: Reporting) {
  submitReportQuestionInstance(input: $input) {
    success
    reportQuestionInstance {
      id
      approvalInfo {
        ...QuestionApprovalInfo
      }
    }
  }
}
    ${QuestionApprovalInfoFragmentDoc}`;

export function useSubmitReportQuestionInstanceMutation() {
  return Urql.useMutation<
    GQTypes.GQSubmitReportQuestionInstanceMutation,
    GQTypes.GQSubmitReportQuestionInstanceMutationVariables
  >(SubmitReportQuestionInstanceDocument);
}
export const ApproveReportQuestionInstanceDocument = gql`
    mutation ApproveReportQuestionInstance($input: ApproveReportQuestionInstanceInput!) @withOwner(owner: Reporting) {
  approveReportQuestionInstance(input: $input) {
    success
    reportQuestionInstance {
      id
      approvalInfo {
        ...QuestionApprovalInfo
      }
    }
  }
}
    ${QuestionApprovalInfoFragmentDoc}`;

export function useApproveReportQuestionInstanceMutation() {
  return Urql.useMutation<
    GQTypes.GQApproveReportQuestionInstanceMutation,
    GQTypes.GQApproveReportQuestionInstanceMutationVariables
  >(ApproveReportQuestionInstanceDocument);
}
export const RejectReportQuestionInstanceDocument = gql`
    mutation RejectReportQuestionInstance($input: RejectReportQuestionInstanceInput!) @withOwner(owner: Reporting) {
  rejectReportQuestionInstance(input: $input) {
    success
    reportQuestionInstance {
      id
      approvalInfo {
        ...QuestionApprovalInfo
      }
    }
  }
}
    ${QuestionApprovalInfoFragmentDoc}`;

export function useRejectReportQuestionInstanceMutation() {
  return Urql.useMutation<
    GQTypes.GQRejectReportQuestionInstanceMutation,
    GQTypes.GQRejectReportQuestionInstanceMutationVariables
  >(RejectReportQuestionInstanceDocument);
}
export const UpdateReportQuestionAssigneesDocument = gql`
    mutation UpdateReportQuestionAssignees($input: UpdateReportQuestionAssigneesInput!, $reportId: ID!) @withOwner(owner: Reporting) {
  updateReportQuestionAssignees(input: $input) {
    questions {
      id
      assignees(reportId: $reportId) {
        id
        displayName
        name
      }
      parentContainer {
        __typename
        ... on ReportQuestionContainer {
          ...ContainerAssignees
          parentContainer {
            ...ContainerAssignees
            parentContainer {
              ...ContainerAssignees
              parentContainer {
                ...ContainerAssignees
                parentContainer {
                  ...ContainerAssignees
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${ContainerAssigneesFragmentDoc}`;

export function useUpdateReportQuestionAssigneesMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateReportQuestionAssigneesMutation,
    GQTypes.GQUpdateReportQuestionAssigneesMutationVariables
  >(UpdateReportQuestionAssigneesDocument);
}
export const GetReportQuestionForMissingInputsDocument = gql`
    query GetReportQuestionForMissingInputs($id: ID!) @withOwner(owner: Reporting) {
  reportQuestion(id: $id) {
    id
    parentContainer {
      id
      label
    }
  }
}
    `;

export function useGetReportQuestionForMissingInputsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportQuestionForMissingInputsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportQuestionForMissingInputsQuery,
    GQTypes.GQGetReportQuestionForMissingInputsQueryVariables
  >({ query: GetReportQuestionForMissingInputsDocument, ...options });
}
export const AcknowledgeReportAnswerWarningDocument = gql`
    mutation AcknowledgeReportAnswerWarning($input: AcknowledgeReportAnswerWarningInput!) @withOwner(owner: Reporting) {
  acknowledgeReportAnswerWarning(input: $input) {
    ...ReportAnswerWarningForCard
  }
}
    ${ReportAnswerWarningForCardFragmentDoc}`;

export function useAcknowledgeReportAnswerWarningMutation() {
  return Urql.useMutation<
    GQTypes.GQAcknowledgeReportAnswerWarningMutation,
    GQTypes.GQAcknowledgeReportAnswerWarningMutationVariables
  >(AcknowledgeReportAnswerWarningDocument);
}
export const GetReportQuestionFieldsForDebugDocument = gql`
    query GetReportQuestionFieldsForDebug($id: ID!, $reportId: ID!) @withOwner(owner: Reporting) {
  reportQuestion(id: $id) {
    ...ReportQuestionFieldsForDebug
  }
}
    ${ReportQuestionFieldsForDebugFragmentDoc}`;

export function useGetReportQuestionFieldsForDebugQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportQuestionFieldsForDebugQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportQuestionFieldsForDebugQuery,
    GQTypes.GQGetReportQuestionFieldsForDebugQueryVariables
  >({ query: GetReportQuestionFieldsForDebugDocument, ...options });
}
export const GetReportQuestionIdentifyingFieldsDocument = gql`
    query GetReportQuestionIdentifyingFields($id: ID!) @withOwner(owner: Reporting) {
  reportQuestion(id: $id) {
    ...ReportQuestionFields
  }
}
    ${ReportQuestionFieldsFragmentDoc}`;

export function useGetReportQuestionIdentifyingFieldsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportQuestionIdentifyingFieldsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportQuestionIdentifyingFieldsQuery,
    GQTypes.GQGetReportQuestionIdentifyingFieldsQueryVariables
  >({ query: GetReportQuestionIdentifyingFieldsDocument, ...options });
}
export const GetContainerAndQuestionsForChangelogDocument = gql`
    query GetContainerAndQuestionsForChangelog($reportId: ID!, $id: ID!, $questionStrategy: Boolean!) @withOwner(owner: Reporting) {
  reportQuestion(id: $id) @include(if: $questionStrategy) {
    id
    parentContainer {
      id
      allVisibleQuestions(reportId: $reportId) {
        ...ReportQuestionActivityTimeline_question
      }
    }
  }
  reportQuestionContainer(id: $id) @skip(if: $questionStrategy) {
    id
    allVisibleQuestions(reportId: $reportId) {
      ...ReportQuestionActivityTimeline_question
    }
  }
}
    ${ReportQuestionActivityTimeline_QuestionFragmentDoc}`;

export function useGetContainerAndQuestionsForChangelogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetContainerAndQuestionsForChangelogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetContainerAndQuestionsForChangelogQuery,
    GQTypes.GQGetContainerAndQuestionsForChangelogQueryVariables
  >({ query: GetContainerAndQuestionsForChangelogDocument, ...options });
}
export const UnassignFromContainerDocument = gql`
    mutation UnassignFromContainer($input: UnassignFromContainerInput!, $reportId: ID!) @withOwner(owner: Reporting) {
  unassignFromContainer(input: $input) {
    container {
      id
      assignees(reportId: $reportId) {
        id
        displayName
        name
      }
      items {
        ...AssigneeInfoForContainedQuestions
      }
    }
  }
}
    ${AssigneeInfoForContainedQuestionsFragmentDoc}`;

export function useUnassignFromContainerMutation() {
  return Urql.useMutation<
    GQTypes.GQUnassignFromContainerMutation,
    GQTypes.GQUnassignFromContainerMutationVariables
  >(UnassignFromContainerDocument);
}
export const OverrideContainerProgressDocument = gql`
    mutation OverrideContainerProgress($input: OverrideContainerProgressInput!, $reportId: ID!) @withOwner(owner: Reporting) {
  overrideContainerProgress(input: $input) {
    id
    overriddenProgress(reportId: $reportId)
  }
}
    `;

export function useOverrideContainerProgressMutation() {
  return Urql.useMutation<
    GQTypes.GQOverrideContainerProgressMutation,
    GQTypes.GQOverrideContainerProgressMutationVariables
  >(OverrideContainerProgressDocument);
}
export const GetContainerAndQuestionsForSheetContentsPanelDocument = gql`
    query GetContainerAndQuestionsForSheetContentsPanel($containerId: ID!, $reportId: ID!) @withOwner(owner: Reporting) {
  reportQuestionContainer(id: $containerId) {
    id
    guidanceJson
    fullWidth
    allVisibleQuestions(reportId: $reportId) {
      ...ReportItem_question
      id
      identifier
      resolvedStatus(reportId: $reportId) {
        id
        status
      }
    }
  }
}
    ${ReportItem_QuestionFragmentDoc}`;

export function useGetContainerAndQuestionsForSheetContentsPanelQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetContainerAndQuestionsForSheetContentsPanelQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetContainerAndQuestionsForSheetContentsPanelQuery,
    GQTypes.GQGetContainerAndQuestionsForSheetContentsPanelQueryVariables
  >({
    query: GetContainerAndQuestionsForSheetContentsPanelDocument,
    ...options,
  });
}
export const WithdrawReportQuestionInstanceDocument = gql`
    mutation WithdrawReportQuestionInstance($input: WithdrawReportQuestionInstanceInput!) @withOwner(owner: Reporting) {
  withdrawReportQuestionInstance(input: $input) {
    success
    reportQuestionInstance {
      id
      approvalInfo {
        ...QuestionApprovalInfo
      }
    }
  }
}
    ${QuestionApprovalInfoFragmentDoc}`;

export function useWithdrawReportQuestionInstanceMutation() {
  return Urql.useMutation<
    GQTypes.GQWithdrawReportQuestionInstanceMutation,
    GQTypes.GQWithdrawReportQuestionInstanceMutationVariables
  >(WithdrawReportQuestionInstanceDocument);
}
export const AddMetricsToCustomReportDocument = gql`
    mutation AddMetricsToCustomReport($input: AddMetricsToCustomReportInput!, $reportId: ID!) @withOwner(owner: Reporting) {
  addMetricsToCustomReport(input: $input) {
    report {
      ...CustomReportContent
    }
  }
}
    ${CustomReportContentFragmentDoc}`;

export function useAddMetricsToCustomReportMutation() {
  return Urql.useMutation<
    GQTypes.GQAddMetricsToCustomReportMutation,
    GQTypes.GQAddMetricsToCustomReportMutationVariables
  >(AddMetricsToCustomReportDocument);
}
export const SetReportingIntentionForQuestionDocument = gql`
    mutation SetReportingIntentionForQuestion($reportId: ID!, $questionIds: [ID!]!, $intention: ReportingIntention!, $note: String) @withOwner(owner: Reporting) {
  updateReportingIntentions(
    input: {reportId: $reportId, reportQuestionIds: $questionIds, intention: $intention, reason: $note}
  ) {
    reportQuestions {
      id
      resolvedReportingIntention(reportId: $reportId) {
        id
        intention
        event {
          createdAt
          createdBy {
            id
            displayName
            name
          }
          reason
        }
      }
    }
  }
}
    `;

export function useSetReportingIntentionForQuestionMutation() {
  return Urql.useMutation<
    GQTypes.GQSetReportingIntentionForQuestionMutation,
    GQTypes.GQSetReportingIntentionForQuestionMutationVariables
  >(SetReportingIntentionForQuestionDocument);
}
export const GetCdpWorkflowSyncStatusDocument = gql`
    query GetCdpWorkflowSyncStatus($workflowId: String!, $reportId: ID!) @withOwner(owner: Reporting) {
  cdpSyncWorkflowStatus(workflowId: $workflowId) {
    id
    workflowId
    questions {
      id
      ...QuestionStatusForCdpSyncDialog
    }
  }
}
    ${QuestionStatusForCdpSyncDialogFragmentDoc}`;

export function useGetCdpWorkflowSyncStatusQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCdpWorkflowSyncStatusQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCdpWorkflowSyncStatusQuery,
    GQTypes.GQGetCdpWorkflowSyncStatusQueryVariables
  >({ query: GetCdpWorkflowSyncStatusDocument, ...options });
}
export const StartCdpSyncAnswersDocument = gql`
    mutation StartCdpSyncAnswers($input: StartCdpSyncAnswersInput!) @withOwner(owner: Reporting) {
  startCdpSyncAnswers(input: $input) {
    id
    workflowId
  }
}
    `;

export function useStartCdpSyncAnswersMutation() {
  return Urql.useMutation<
    GQTypes.GQStartCdpSyncAnswersMutation,
    GQTypes.GQStartCdpSyncAnswersMutationVariables
  >(StartCdpSyncAnswersDocument);
}
export const AddReportQuestionNoteDocument = gql`
    mutation AddReportQuestionNote($note: AddReportQuestionNoteInput!, $reportId: ID!) @withOwner(owner: Reporting) {
  addReportQuestionNote(input: $note) {
    reportQuestion {
      id
      notes(reportId: $reportId) {
        id
        ...ReportQuestionNoteFields
      }
    }
  }
}
    ${ReportQuestionNoteFieldsFragmentDoc}`;

export function useAddReportQuestionNoteMutation() {
  return Urql.useMutation<
    GQTypes.GQAddReportQuestionNoteMutation,
    GQTypes.GQAddReportQuestionNoteMutationVariables
  >(AddReportQuestionNoteDocument);
}
export const UpdateReportQuestionNotesDocument = gql`
    mutation UpdateReportQuestionNotes($input: UpdateReportQuestionNoteInput!, $reportId: ID!) @withOwner(owner: Reporting) {
  updateReportQuestionNote(input: $input) {
    reportQuestion {
      id
      notes(reportId: $reportId) {
        id
        ...ReportQuestionNoteFields
      }
    }
  }
}
    ${ReportQuestionNoteFieldsFragmentDoc}`;

export function useUpdateReportQuestionNotesMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateReportQuestionNotesMutation,
    GQTypes.GQUpdateReportQuestionNotesMutationVariables
  >(UpdateReportQuestionNotesDocument);
}
export const UpdateReportQuestionNoteItemDocument = gql`
    mutation UpdateReportQuestionNoteItem($input: UpdateReportQuestionNoteItemInput!, $reportId: ID!) @withOwner(owner: Reporting) {
  updateReportQuestionNoteItem(input: $input) {
    reportQuestion {
      id
      notes(reportId: $reportId) {
        id
        ...ReportQuestionNoteFields
      }
    }
  }
}
    ${ReportQuestionNoteFieldsFragmentDoc}`;

export function useUpdateReportQuestionNoteItemMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateReportQuestionNoteItemMutation,
    GQTypes.GQUpdateReportQuestionNoteItemMutationVariables
  >(UpdateReportQuestionNoteItemDocument);
}
export const DeleteReportQuestionNoteItemDocument = gql`
    mutation DeleteReportQuestionNoteItem($input: DeleteReportQuestionNoteItemInput!, $reportId: ID!) @withOwner(owner: Reporting) {
  deleteReportQuestionNoteItem(input: $input) {
    reportQuestion {
      id
      notes(reportId: $reportId) {
        id
        ...ReportQuestionNoteFields
      }
    }
  }
}
    ${ReportQuestionNoteFieldsFragmentDoc}`;

export function useDeleteReportQuestionNoteItemMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteReportQuestionNoteItemMutation,
    GQTypes.GQDeleteReportQuestionNoteItemMutationVariables
  >(DeleteReportQuestionNoteItemDocument);
}
export const UpdateOverrideStatesDocument = gql`
    mutation UpdateOverrideStates($reportId: ID!, $reportQuestionIds: [ID!]!, $overrideState: ReportAnswerOverrideState!) @withOwner(owner: Reporting) {
  updateReportAnswerOverrideStates(
    input: {reportId: $reportId, reportQuestionIds: $reportQuestionIds, overrideState: $overrideState}
  ) {
    reportQuestions {
      id
      overrideState(reportId: $reportId)
      overrideStateHistory(reportId: $reportId, first: 1) {
        edges {
          node {
            id
            createdAt
            createdBy {
              id
              displayName
            }
            overrideState
          }
        }
      }
      instance(reportId: $reportId) {
        id
        approvalInfo {
          ...QuestionApprovalInfo
        }
      }
    }
  }
}
    ${QuestionApprovalInfoFragmentDoc}`;

export function useUpdateOverrideStatesMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateOverrideStatesMutation,
    GQTypes.GQUpdateOverrideStatesMutationVariables
  >(UpdateOverrideStatesDocument);
}
export const DeleteMetricFromCustomReportDocument = gql`
    mutation DeleteMetricFromCustomReport($reportId: ID!, $input: DeleteMetricFromCustomReportInput!) @withOwner(owner: Reporting) {
  deleteMetricFromCustomReport(input: $input) {
    report {
      id
      config {
        id
        allVisibleItems(reportId: $reportId) {
          __typename
          ... on ReportQuestionContainer {
            id
            label
            description
            items {
              ... on ReportQuestion {
                ...ReportQuestionForReportItemFields
              }
            }
          }
        }
      }
    }
  }
}
    ${ReportQuestionForReportItemFieldsFragmentDoc}`;

export function useDeleteMetricFromCustomReportMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteMetricFromCustomReportMutation,
    GQTypes.GQDeleteMetricFromCustomReportMutationVariables
  >(DeleteMetricFromCustomReportDocument);
}
export const DrilldownSavedViewsForReportDocument = gql`
    query DrilldownSavedViewsForReport @withOwner(owner: Reporting) {
  drilldownSavedViews {
    ...DrilldownSavedViewsForSelector
  }
}
    ${DrilldownSavedViewsForSelectorFragmentDoc}`;

export function useDrilldownSavedViewsForReportQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQDrilldownSavedViewsForReportQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDrilldownSavedViewsForReportQuery,
    GQTypes.GQDrilldownSavedViewsForReportQueryVariables
  >({ query: DrilldownSavedViewsForReportDocument, ...options });
}
export const GetFundsDocument = gql`
    query GetFunds @withOwner(owner: Reporting) {
  funds {
    id
    ...FundAllFields
  }
}
    ${FundAllFieldsFragmentDoc}`;

export function useGetFundsQuery(
  options?: Omit<Urql.UseQueryArgs<GQTypes.GQGetFundsQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQGetFundsQuery,
    GQTypes.GQGetFundsQueryVariables
  >({ query: GetFundsDocument, ...options });
}
export const NewZeroTargetYearComponentDocument = gql`
    query NewZeroTargetYearComponent($id: ID!) @withOwner(owner: Reporting) {
  plan(id: $id) {
    id
    variables {
      commitmentsNetZeroYear
    }
  }
}
    `;

export function useNewZeroTargetYearComponentQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQNewZeroTargetYearComponentQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQNewZeroTargetYearComponentQuery,
    GQTypes.GQNewZeroTargetYearComponentQueryVariables
  >({ query: NewZeroTargetYearComponentDocument, ...options });
}
export const ReductionPlanSelectorDocument = gql`
    query ReductionPlanSelector @withOwner(owner: Reporting) {
  plans {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

export function useReductionPlanSelectorQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQReductionPlanSelectorQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReductionPlanSelectorQuery,
    GQTypes.GQReductionPlanSelectorQueryVariables
  >({ query: ReductionPlanSelectorDocument, ...options });
}
export const ReviewSectionReportQuestionsDocument = gql`
    query ReviewSectionReportQuestions($reportId: ID!, $ids: [ID!]!) @withOwner(owner: Reporting) {
  reportQuestions(ids: $ids) {
    ...ReportItem_question
  }
}
    ${ReportItem_QuestionFragmentDoc}`;

export function useReviewSectionReportQuestionsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReviewSectionReportQuestionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReviewSectionReportQuestionsQuery,
    GQTypes.GQReviewSectionReportQuestionsQueryVariables
  >({ query: ReviewSectionReportQuestionsDocument, ...options });
}
export const ReportContainerProgressForSurveyDocument = gql`
    query ReportContainerProgressForSurvey($reportId: ID!) @withOwner(owner: Reporting) {
  formalReport(id: $reportId) {
    id
    config {
      id
      containers {
        id
        automaticProgress(reportId: $reportId)
        overriddenProgress(reportId: $reportId)
      }
    }
  }
}
    `;

export function useReportContainerProgressForSurveyQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportContainerProgressForSurveyQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportContainerProgressForSurveyQuery,
    GQTypes.GQReportContainerProgressForSurveyQueryVariables
  >({ query: ReportContainerProgressForSurveyDocument, ...options });
}
export const MaterialityAssessmentsDocument = gql`
    query MaterialityAssessments @withOwner(owner: Reporting) {
  allMaterialityAssessments {
    id
    name
    updatedAt
  }
}
    `;

export function useMaterialityAssessmentsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQMaterialityAssessmentsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMaterialityAssessmentsQuery,
    GQTypes.GQMaterialityAssessmentsQueryVariables
  >({ query: MaterialityAssessmentsDocument, ...options });
}
export const GetMaterialityDataPointsDocument = gql`
    query GetMaterialityDataPoints($reportId: ID!) @withOwner(owner: Reporting) {
  reportWithConfig(id: $reportId) {
    id
    config {
      id
      allVisibleQuestions(reportId: $reportId) {
        id
        materiality(reportId: $reportId)
      }
    }
  }
  allMaterialityAssessments {
    id
    reports {
      id
      name
    }
  }
}
    `;

export function useGetMaterialityDataPointsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetMaterialityDataPointsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetMaterialityDataPointsQuery,
    GQTypes.GQGetMaterialityDataPointsQueryVariables
  >({ query: GetMaterialityDataPointsDocument, ...options });
}
export const GetAllUserVisibleFootprintSnapshotsForCustomReportsDocument = gql`
    query GetAllUserVisibleFootprintSnapshotsForCustomReports($orgId: ID!) @withOwner(owner: Reporting) {
  getAllUserVisibleFootprintSnapshots(orgId: $orgId) {
    ...FootprintSnapshotMetadataForCustomReports
  }
}
    ${FootprintSnapshotMetadataForCustomReportsFragmentDoc}`;

export function useGetAllUserVisibleFootprintSnapshotsForCustomReportsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetAllUserVisibleFootprintSnapshotsForCustomReportsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetAllUserVisibleFootprintSnapshotsForCustomReportsQuery,
    GQTypes.GQGetAllUserVisibleFootprintSnapshotsForCustomReportsQueryVariables
  >({
    query: GetAllUserVisibleFootprintSnapshotsForCustomReportsDocument,
    ...options,
  });
}
export const GetCustomReportFootprintSnapshotIntervalDocument = gql`
    query GetCustomReportFootprintSnapshotInterval($footprintSnapshotId: String!) @withOwner(owner: Reporting) {
  footprintSnapshotInDashboard(footprintSnapshotId: $footprintSnapshotId) {
    ...FootprintSnapshotMetadataForCustomReports
  }
}
    ${FootprintSnapshotMetadataForCustomReportsFragmentDoc}`;

export function useGetCustomReportFootprintSnapshotIntervalQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCustomReportFootprintSnapshotIntervalQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCustomReportFootprintSnapshotIntervalQuery,
    GQTypes.GQGetCustomReportFootprintSnapshotIntervalQueryVariables
  >({ query: GetCustomReportFootprintSnapshotIntervalDocument, ...options });
}
export const GetArchetypeOpportunitiesForTcfdDocument = gql`
    query GetArchetypeOpportunitiesForTcfd @withOwner(owner: Reporting) {
  tcfdArchetypeOpportunities {
    ...TcfdArchetypeOpportunityFields
  }
}
    ${TcfdArchetypeOpportunityFieldsFragmentDoc}`;

export function useGetArchetypeOpportunitiesForTcfdQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetArchetypeOpportunitiesForTcfdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetArchetypeOpportunitiesForTcfdQuery,
    GQTypes.GQGetArchetypeOpportunitiesForTcfdQueryVariables
  >({ query: GetArchetypeOpportunitiesForTcfdDocument, ...options });
}
export const TcfdRisksLibrarySelectorDocument = gql`
    query TcfdRisksLibrarySelector @withOwner(owner: Reporting) {
  tcfdArchetypeRisks {
    id
    title
    userDescription
    kind
    possibleImpactDescription
    lowTrajectoryImpact
    mediumTrajectoryImpact
    highTrajectoryImpact
    physicalRiskSeverity
    geographies
    sectors
    distributionModel
    whyWeChoseThisMd
  }
}
    `;

export function useTcfdRisksLibrarySelectorQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQTcfdRisksLibrarySelectorQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQTcfdRisksLibrarySelectorQuery,
    GQTypes.GQTcfdRisksLibrarySelectorQueryVariables
  >({ query: TcfdRisksLibrarySelectorDocument, ...options });
}
export const FootprintSnapshotAndFootprintForReportWarningDocument = gql`
    query FootprintSnapshotAndFootprintForReportWarning($footprintSnapshotId: String!) @withOwner(owner: Reporting) {
  footprintSnapshotInDashboard(footprintSnapshotId: $footprintSnapshotId) {
    id
    footprint {
      id
      name
      latestPublishedFootprintSnapshot {
        id
        createdAt
      }
    }
  }
}
    `;

export function useFootprintSnapshotAndFootprintForReportWarningQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFootprintSnapshotAndFootprintForReportWarningQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFootprintSnapshotAndFootprintForReportWarningQuery,
    GQTypes.GQFootprintSnapshotAndFootprintForReportWarningQueryVariables
  >({
    query: FootprintSnapshotAndFootprintForReportWarningDocument,
    ...options,
  });
}
export const GetExcludingReportQuestionDocument = gql`
    query GetExcludingReportQuestion($questionId: ID!) @withOwner(owner: Reporting) {
  reportQuestion(id: $questionId) {
    id
    isComputed
    parentContainer {
      id
      label
    }
  }
}
    `;

export function useGetExcludingReportQuestionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetExcludingReportQuestionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetExcludingReportQuestionQuery,
    GQTypes.GQGetExcludingReportQuestionQueryVariables
  >({ query: GetExcludingReportQuestionDocument, ...options });
}
export const GetContainerStatusDocument = gql`
    query GetContainerStatus($containerId: ID!, $reportId: ID!) @withOwner(owner: Reporting) {
  reportQuestionContainer(id: $containerId) {
    ...ReportQuestionContainerStatusFields
    allVisibleContainers(reportId: $reportId) {
      ...ReportQuestionContainerStatusFields
      allVisibleContainers(reportId: $reportId) {
        ...ReportQuestionContainerStatusFields
      }
    }
  }
}
    ${ReportQuestionContainerStatusFieldsFragmentDoc}`;

export function useGetContainerStatusQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetContainerStatusQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetContainerStatusQuery,
    GQTypes.GQGetContainerStatusQueryVariables
  >({ query: GetContainerStatusDocument, ...options });
}
export const GetReportWithConfigAndOrgUsersDocument = gql`
    query GetReportWithConfigAndOrgUsers($orgId: ID!, $reportId: ID!) @withOwner(owner: Reporting) {
  formalReport(id: $reportId) @_required {
    ...CdpSyncRegistrationDialog_report
    ...EditReportPage_ReportWithoutContainers_report
    ...EditReportPage_ReportWithContainers_report
    ...ReportQuestionContainerCard_report
    ...ReportWithConfigFields
    ...ReportPageReport
  }
  organization {
    id
    users {
      edges {
        node {
          ...UserForReportQuestionAssignment
        }
      }
    }
  }
}
    ${CdpSyncRegistrationDialog_ReportFragmentDoc}
${EditReportPage_ReportWithoutContainers_ReportFragmentDoc}
${EditReportPage_ReportWithContainers_ReportFragmentDoc}
${ReportQuestionContainerCard_ReportFragmentDoc}
${ReportWithConfigFieldsFragmentDoc}
${ReportPageReportFragmentDoc}
${UserForReportQuestionAssignmentFragmentDoc}`;

export function useGetReportWithConfigAndOrgUsersQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportWithConfigAndOrgUsersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportWithConfigAndOrgUsersQuery,
    GQTypes.GQGetReportWithConfigAndOrgUsersQueryVariables
  >({ query: GetReportWithConfigAndOrgUsersDocument, ...options });
}
export const CompanyReportingFactsDocument = gql`
    query CompanyReportingFacts($companyId: ID!) @withOwner(owner: Reporting) {
  companyReportingFacts(companyId: $companyId) {
    ...CompanyReportingFactsFields
  }
}
    ${CompanyReportingFactsFieldsFragmentDoc}`;

export function useCompanyReportingFactsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCompanyReportingFactsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCompanyReportingFactsQuery,
    GQTypes.GQCompanyReportingFactsQueryVariables
  >({ query: CompanyReportingFactsDocument, ...options });
}
export const CurrencyExchangeRateFromUsdAsOfMonthDocument = gql`
    query CurrencyExchangeRateFromUsdAsOfMonth($targetCurrency: String!, $month: YearMonth!) @withOwner(owner: Reporting) {
  currencyExchangeRateFromUsdAsOfMonth(
    targetCurrency: $targetCurrency
    month: $month
  ) {
    id
    currency
    value
  }
}
    `;

export function useCurrencyExchangeRateFromUsdAsOfMonthQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCurrencyExchangeRateFromUsdAsOfMonthQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCurrencyExchangeRateFromUsdAsOfMonthQuery,
    GQTypes.GQCurrencyExchangeRateFromUsdAsOfMonthQueryVariables
  >({ query: CurrencyExchangeRateFromUsdAsOfMonthDocument, ...options });
}
export const UpsertCompanyReportingFactsDocument = gql`
    mutation UpsertCompanyReportingFacts($input: UpsertCompanyReportingFactsInput!) @withOwner(owner: Reporting) {
  upsertCompanyReportingFacts(input: $input) {
    companyReportingFacts {
      ...CompanyReportingFactsFields
    }
  }
}
    ${CompanyReportingFactsFieldsFragmentDoc}`;

export function useUpsertCompanyReportingFactsMutation() {
  return Urql.useMutation<
    GQTypes.GQUpsertCompanyReportingFactsMutation,
    GQTypes.GQUpsertCompanyReportingFactsMutationVariables
  >(UpsertCompanyReportingFactsDocument);
}
export const RecommendedReportKindsDocument = gql`
    query RecommendedReportKinds($companyId: ID!) @withOwner(owner: Reporting) {
  recommendedReportKinds(companyId: $companyId) {
    id
    state
    required {
      id
      kind
      reason
    }
    recommended {
      id
      kind
      reason
    }
  }
}
    `;

export function useRecommendedReportKindsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQRecommendedReportKindsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQRecommendedReportKindsQuery,
    GQTypes.GQRecommendedReportKindsQueryVariables
  >({ query: RecommendedReportKindsDocument, ...options });
}
export const PeerExternalReportAnswersForIdentifiersDocument = gql`
    query PeerExternalReportAnswersForIdentifiers($reportQuestionIdentifiers: [String!]!) @withOwner(owner: Reporting) {
  peerExternalReportAnswersForIdentifiers(
    reportQuestionIdentifiers: $reportQuestionIdentifiers
  ) {
    peerCompanies {
      id
      name
    }
    answers {
      ...PeerExternalReportAnswerFields
    }
    questions {
      ...PeerExternalReportQuestionFields
    }
  }
}
    ${PeerExternalReportAnswerFieldsFragmentDoc}
${PeerExternalReportQuestionFieldsFragmentDoc}`;

export function usePeerExternalReportAnswersForIdentifiersQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQPeerExternalReportAnswersForIdentifiersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPeerExternalReportAnswersForIdentifiersQuery,
    GQTypes.GQPeerExternalReportAnswersForIdentifiersQueryVariables
  >({ query: PeerExternalReportAnswersForIdentifiersDocument, ...options });
}
export const ComputeSeriesMetricDocument = gql`
    query ComputeSeriesMetric($input: ComputeReportQuestionMetricInput!) @withOwner(owner: Reporting) {
  computeReportQuestionSeriesMetric(input: $input) {
    series {
      label
      value
    }
  }
}
    `;

export function useComputeSeriesMetricQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQComputeSeriesMetricQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQComputeSeriesMetricQuery,
    GQTypes.GQComputeSeriesMetricQueryVariables
  >({ query: ComputeSeriesMetricDocument, ...options });
}
export const GetOrganizationReductionPlanIdsDocument = gql`
    query GetOrganizationReductionPlanIds @withOwner(owner: Reporting) {
  plans {
    edges {
      node {
        id
      }
    }
  }
}
    `;

export function useGetOrganizationReductionPlanIdsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetOrganizationReductionPlanIdsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetOrganizationReductionPlanIdsQuery,
    GQTypes.GQGetOrganizationReductionPlanIdsQueryVariables
  >({ query: GetOrganizationReductionPlanIdsDocument, ...options });
}
export const ComputeSingleValueMetricDocument = gql`
    query ComputeSingleValueMetric($input: ComputeReportQuestionMetricInput!) @withOwner(owner: Reporting) {
  computeReportQuestionSingleValueMetric(input: $input) {
    value
  }
}
    `;

export function useComputeSingleValueMetricQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQComputeSingleValueMetricQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQComputeSingleValueMetricQuery,
    GQTypes.GQComputeSingleValueMetricQueryVariables
  >({ query: ComputeSingleValueMetricDocument, ...options });
}
export const CreateMaterialityAssessmentDocument = gql`
    mutation CreateMaterialityAssessment($input: CreateMaterialityAssessmentInput!) @withOwner(owner: Reporting) {
  createMaterialityAssessment(input: $input) {
    materialityAssessment {
      id
    }
  }
}
    `;

export function useCreateMaterialityAssessmentMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMaterialityAssessmentMutation,
    GQTypes.GQCreateMaterialityAssessmentMutationVariables
  >(CreateMaterialityAssessmentDocument);
}
export const MaterialityPageDocument = gql`
    query MaterialityPage @withOwner(owner: Reporting) {
  allMaterialityAssessments {
    id
    name
    createdAt
    updatedAt
    updatedBy {
      id
      displayName
    }
    reports {
      id
      name
    }
  }
}
    `;

export function useMaterialityPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQMaterialityPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMaterialityPageQuery,
    GQTypes.GQMaterialityPageQueryVariables
  >({ query: MaterialityPageDocument, ...options });
}
export const DeleteMaterialityAssessmentDocument = gql`
    mutation deleteMaterialityAssessment($input: DeleteMaterialityAssessmentInput!) @withOwner(owner: Reporting) {
  deleteMaterialityAssessment(input: $input) {
    success
    materialityAssessments {
      id
      name
      createdAt
      updatedAt
      updatedBy {
        id
        displayName
      }
      reports {
        id
        name
      }
    }
  }
}
    `;

export function useDeleteMaterialityAssessmentMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteMaterialityAssessmentMutation,
    GQTypes.GQDeleteMaterialityAssessmentMutationVariables
  >(DeleteMaterialityAssessmentDocument);
}
export const UpdateMaterialityAssessmentDocument = gql`
    mutation updateMaterialityAssessment($input: UpdateMaterialityAssessmentInput!) @withOwner(owner: Reporting) {
  updateMaterialityAssessment(input: $input) {
    materialityAssessments {
      id
      name
      createdAt
      updatedAt
      updatedBy {
        id
        displayName
      }
      reports {
        id
        name
      }
    }
  }
}
    `;

export function useUpdateMaterialityAssessmentMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateMaterialityAssessmentMutation,
    GQTypes.GQUpdateMaterialityAssessmentMutationVariables
  >(UpdateMaterialityAssessmentDocument);
}
export const ViewMaterialityAssessmentDocument = gql`
    query ViewMaterialityAssessment($materialityAssessmentId: ID!) @withOwner(owner: Reporting) {
  materialityAssessment(materialityAssessmentId: $materialityAssessmentId) {
    id
    name
    dataPoints {
      id
      frameworkDataPoint {
        id
        identifier
      }
      status
    }
  }
}
    `;

export function useViewMaterialityAssessmentQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQViewMaterialityAssessmentQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQViewMaterialityAssessmentQuery,
    GQTypes.GQViewMaterialityAssessmentQueryVariables
  >({ query: ViewMaterialityAssessmentDocument, ...options });
}
export const DownloadDocxReportDocument = gql`
    query DownloadDocxReport($reportId: ID!) @withOwner(owner: Reporting) {
  formalReport(id: $reportId) {
    id
    downloadableDocxUrl
  }
}
    `;

export function useDownloadDocxReportQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDownloadDocxReportQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDownloadDocxReportQuery,
    GQTypes.GQDownloadDocxReportQueryVariables
  >({ query: DownloadDocxReportDocument, ...options });
}
export const DownloadAiJoinedNarrativeDocxReportDocument = gql`
    query DownloadAiJoinedNarrativeDocxReport($reportId: ID!) @withOwner(owner: Reporting) {
  formalReport(id: $reportId) {
    id
    downloadableAiJoinedNarrativeDocxUrl
  }
}
    `;

export function useDownloadAiJoinedNarrativeDocxReportQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDownloadAiJoinedNarrativeDocxReportQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDownloadAiJoinedNarrativeDocxReportQuery,
    GQTypes.GQDownloadAiJoinedNarrativeDocxReportQueryVariables
  >({ query: DownloadAiJoinedNarrativeDocxReportDocument, ...options });
}
export const GenerateAiNarrativeDocxReportDocument = gql`
    mutation GenerateAiNarrativeDocxReport($input: GenerateAiNarrativeDocxReportWorkflowInput!) @withOwner(owner: WinCsrd) {
  generateAiNarrativeDocxReport(input: $input) {
    workflowId
  }
}
    `;

export function useGenerateAiNarrativeDocxReportMutation() {
  return Urql.useMutation<
    GQTypes.GQGenerateAiNarrativeDocxReportMutation,
    GQTypes.GQGenerateAiNarrativeDocxReportMutationVariables
  >(GenerateAiNarrativeDocxReportDocument);
}
export const DownloadXlsxReportDocument = gql`
    query DownloadXlsxReport($reportId: ID!) @withOwner(owner: Reporting) {
  formalReport(id: $reportId) {
    id
    reportKind
    downloadableXlsxUrl
  }
}
    `;

export function useDownloadXlsxReportQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDownloadXlsxReportQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDownloadXlsxReportQuery,
    GQTypes.GQDownloadXlsxReportQueryVariables
  >({ query: DownloadXlsxReportDocument, ...options });
}
export const InProgressReportsDocument = gql`
    query InProgressReports($reportMode: ReportMode!) @withOwner(owner: Reporting) {
  formalReports(reportMode: $reportMode) {
    edges {
      node {
        __typename
        id
        ...ReportPageReport
      }
    }
  }
}
    ${ReportPageReportFragmentDoc}`;

export function useInProgressReportsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQInProgressReportsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQInProgressReportsQuery,
    GQTypes.GQInProgressReportsQueryVariables
  >({ query: InProgressReportsDocument, ...options });
}
export const ReportSummariesDocument = gql`
    query ReportSummaries($reportMode: ReportMode!) @withOwner(owner: Reporting) {
  formalReports(last: 5, reportMode: $reportMode) {
    edges {
      node {
        ...ReportSummary
      }
    }
  }
}
    ${ReportSummaryFragmentDoc}`;

export function useReportSummariesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportSummariesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportSummariesQuery,
    GQTypes.GQReportSummariesQueryVariables
  >({ query: ReportSummariesDocument, ...options });
}
export const ReportWaitlistTypesDocument = gql`
    query ReportWaitlistTypes @withOwner(owner: Reporting) {
  reportWaitlistTypes
}
    `;

export function useReportWaitlistTypesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportWaitlistTypesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportWaitlistTypesQuery,
    GQTypes.GQReportWaitlistTypesQueryVariables
  >({ query: ReportWaitlistTypesDocument, ...options });
}
export const JoinReportWaitlistDocument = gql`
    mutation JoinReportWaitlist($kind: String!) @withOwner(owner: Reporting) {
  joinReportWaitlist(kind: $kind) {
    id
    kind
  }
}
    `;

export function useJoinReportWaitlistMutation() {
  return Urql.useMutation<
    GQTypes.GQJoinReportWaitlistMutation,
    GQTypes.GQJoinReportWaitlistMutationVariables
  >(JoinReportWaitlistDocument);
}
export const LeaveReportWaitlistDocument = gql`
    mutation LeaveReportWaitlist($kind: String!) @withOwner(owner: Reporting) {
  leaveReportWaitlist(kind: $kind) {
    kind
  }
}
    `;

export function useLeaveReportWaitlistMutation() {
  return Urql.useMutation<
    GQTypes.GQLeaveReportWaitlistMutation,
    GQTypes.GQLeaveReportWaitlistMutationVariables
  >(LeaveReportWaitlistDocument);
}
export const GetCdpApiRegistrationDocument = gql`
    query GetCdpApiRegistration($reportId: ID!) @withOwner(owner: Reporting) {
  cdpApiRegistrationForReport(reportId: $reportId) {
    id
    isReportRegistered
    cdpOrganizationId
    registeredReportId
  }
  cdpLatestSyncWorkflowStatus {
    workflowStatus {
      id
      workflowId
      questions {
        id
        syncTime
        isSuccess
      }
    }
  }
}
    `;

export function useGetCdpApiRegistrationQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCdpApiRegistrationQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCdpApiRegistrationQuery,
    GQTypes.GQGetCdpApiRegistrationQueryVariables
  >({ query: GetCdpApiRegistrationDocument, ...options });
}
export const CreateCdpApiRegistrationDocument = gql`
    mutation CreateCdpApiRegistration($input: CreateCdpApiRegistrationInput!) @withOwner(owner: Reporting) {
  createCdpApiRegistration(input: $input) {
    id
  }
}
    `;

export function useCreateCdpApiRegistrationMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCdpApiRegistrationMutation,
    GQTypes.GQCreateCdpApiRegistrationMutationVariables
  >(CreateCdpApiRegistrationDocument);
}
export const DeleteCdpApiRegistrationDocument = gql`
    mutation DeleteCdpApiRegistration($input: DeleteCdpApiRegistrationInput!) @withOwner(owner: Reporting) {
  deleteCdpApiRegistration(input: $input) {
    id
  }
}
    `;

export function useDeleteCdpApiRegistrationMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCdpApiRegistrationMutation,
    GQTypes.GQDeleteCdpApiRegistrationMutationVariables
  >(DeleteCdpApiRegistrationDocument);
}
export const UpsertReportAnswerDocument = gql`
    mutation UpsertReportAnswer($input: UpsertReportAnswerInput!, $reportId: ID!) @withOwner(owner: Reporting) {
  upsertReportAnswerWithMetadata(input: $input) {
    doesConflict
    updatedAnswer {
      id
      question {
        ...ReportQuestionDependentFields
      }
      editorName
    }
  }
}
    ${ReportQuestionDependentFieldsFragmentDoc}`;

export function useUpsertReportAnswerMutation() {
  return Urql.useMutation<
    GQTypes.GQUpsertReportAnswerMutation,
    GQTypes.GQUpsertReportAnswerMutationVariables
  >(UpsertReportAnswerDocument);
}
export const UpsertReportAnswerOverrideDocument = gql`
    mutation UpsertReportAnswerOverride($input: UpsertReportAnswerOverrideInput!, $reportId: ID!) @withOwner(owner: Reporting) {
  upsertReportAnswerOverride(input: $input) {
    doesConflict
    updatedAnswer {
      id
      question {
        ...ReportQuestionOverrideDependentFields
      }
      editorName
    }
  }
}
    ${ReportQuestionOverrideDependentFieldsFragmentDoc}`;

export function useUpsertReportAnswerOverrideMutation() {
  return Urql.useMutation<
    GQTypes.GQUpsertReportAnswerOverrideMutation,
    GQTypes.GQUpsertReportAnswerOverrideMutationVariables
  >(UpsertReportAnswerOverrideDocument);
}
export const DeleteReportAnswerDocument = gql`
    mutation DeleteReportAnswer($input: DeleteReportAnswerInput!, $reportId: ID!) @withOwner(owner: Reporting) {
  deleteReportAnswer(input: $input) {
    numDeleted
    question {
      ...ReportQuestionDependentFields
    }
  }
}
    ${ReportQuestionDependentFieldsFragmentDoc}`;

export function useDeleteReportAnswerMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteReportAnswerMutation,
    GQTypes.GQDeleteReportAnswerMutationVariables
  >(DeleteReportAnswerDocument);
}
export const GetWorkivaContributionsDocument = gql`
    query GetWorkivaContributions($footprintKind: String!, $footprintSnapshotId: String!, $reportingInterval: YMInterval!) @withOwner(owner: Reporting) {
  footprintAnalysis {
    id
    workivaContributions(
      footprintKind: $footprintKind
      footprintSnapshotId: $footprintSnapshotId
      reportingInterval: $reportingInterval
    ) {
      id
      interval
      data
    }
  }
}
    `;

export function useGetWorkivaContributionsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetWorkivaContributionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetWorkivaContributionsQuery,
    GQTypes.GQGetWorkivaContributionsQueryVariables
  >({ query: GetWorkivaContributionsDocument, ...options });
}
export const AccessAndSecuritySettingsDocument = gql`
    query AccessAndSecuritySettings @withOwner(owner: EnterpriseFoundations) {
  organization {
    id
    ...SessionTimeoutSettingsSection
    ...SsoSettingsSection
    ...ScimSettingsSection
    ...AuthenticationSettingsSection
  }
}
    ${SessionTimeoutSettingsSectionFragmentDoc}
${SsoSettingsSectionFragmentDoc}
${ScimSettingsSectionFragmentDoc}
${AuthenticationSettingsSectionFragmentDoc}`;

export function useAccessAndSecuritySettingsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQAccessAndSecuritySettingsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAccessAndSecuritySettingsQuery,
    GQTypes.GQAccessAndSecuritySettingsQueryVariables
  >({ query: AccessAndSecuritySettingsDocument, ...options });
}
export const UpdateUserNameDocument = gql`
    mutation UpdateUserName($input: UpdateUserNameInput!) @withOwner(owner: EnterpriseFoundations) {
  updateUserName(input: $input) {
    user {
      id
      name
    }
  }
}
    `;

export function useUpdateUserNameMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateUserNameMutation,
    GQTypes.GQUpdateUserNameMutationVariables
  >(UpdateUserNameDocument);
}
export const ResetLockedUserDocument = gql`
    mutation ResetLockedUser($input: ResetLockedUserInput!) @withOwner(owner: EnterpriseFoundations) {
  resetLockedUser(input: $input) {
    user {
      id
      consecutiveLoginFailures
    }
  }
}
    `;

export function useResetLockedUserMutation() {
  return Urql.useMutation<
    GQTypes.GQResetLockedUserMutation,
    GQTypes.GQResetLockedUserMutationVariables
  >(ResetLockedUserDocument);
}
export const RevokeUserAppSessionsDocument = gql`
    mutation RevokeUserAppSessions($input: RevokeUserAppSessionsInput!) @withOwner(owner: EnterpriseFoundations) {
  revokeUserAppSessions(input: $input) {
    user {
      id
      hasValidAppSessions
    }
  }
}
    `;

export function useRevokeUserAppSessionsMutation() {
  return Urql.useMutation<
    GQTypes.GQRevokeUserAppSessionsMutation,
    GQTypes.GQRevokeUserAppSessionsMutationVariables
  >(RevokeUserAppSessionsDocument);
}
export const ResendUserInviteLinkDocument = gql`
    mutation ResendUserInviteLink($input: ResendUserInviteLinkInput!) @withOwner(owner: EnterpriseFoundations) {
  resendUserInviteLink(input: $input) {
    user {
      id
    }
  }
}
    `;

export function useResendUserInviteLinkMutation() {
  return Urql.useMutation<
    GQTypes.GQResendUserInviteLinkMutation,
    GQTypes.GQResendUserInviteLinkMutationVariables
  >(ResendUserInviteLinkDocument);
}
export const GetOrganizationMembersDocument = gql`
    query GetOrganizationMembers($orgId: ID!, $after: String, $before: String, $first: Int, $last: Int, $search: String, $sortModel: [DataGridSortItemInput!]) @withOwner(owner: EnterpriseFoundations) {
  orgUsers(
    after: $after
    before: $before
    first: $first
    last: $last
    search: $search
    sortModel: $sortModel
  ) {
    edges {
      node {
        id
        ...OrganizationMemberFields
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
  }
}
    ${OrganizationMemberFieldsFragmentDoc}`;

export function useGetOrganizationMembersQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetOrganizationMembersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetOrganizationMembersQuery,
    GQTypes.GQGetOrganizationMembersQueryVariables
  >({ query: GetOrganizationMembersDocument, ...options });
}
export const GetOrganizationSettingsDocument = gql`
    query GetOrganizationSettings @withOwner(owner: EnterpriseFoundations) {
  organization {
    id
    workosOrgId
    domains
    passwordAuthDisabled
    hasActiveWorkosConnection
    workosDirectories {
      id
    }
  }
}
    `;

export function useGetOrganizationSettingsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetOrganizationSettingsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetOrganizationSettingsQuery,
    GQTypes.GQGetOrganizationSettingsQueryVariables
  >({ query: GetOrganizationSettingsDocument, ...options });
}
export const AllUsersDocument = gql`
    query allUsers($orgId: ID!) @withOwner(owner: EnterpriseFoundations) {
  allUsers {
    ...OrganizationMemberFields
  }
}
    ${OrganizationMemberFieldsFragmentDoc}`;

export function useAllUsersQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQAllUsersQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQAllUsersQuery,
    GQTypes.GQAllUsersQueryVariables
  >({ query: AllUsersDocument, ...options });
}
export const AllRolesDocument = gql`
    query AllRoles @withOwner(owner: EnterpriseFoundations) {
  allRoles {
    id
    name
    description
    createdAt
    createdBy {
      id
      name
    }
    permissions {
      id
      ...PermissionFields
    }
    users {
      id
      user {
        id
        displayName
        name
      }
    }
    apiKeys {
      id
      apiKey {
        id
        name
        description
      }
    }
  }
}
    ${PermissionFieldsFragmentDoc}`;

export function useAllRolesQuery(
  options?: Omit<Urql.UseQueryArgs<GQTypes.GQAllRolesQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQAllRolesQuery,
    GQTypes.GQAllRolesQueryVariables
  >({ query: AllRolesDocument, ...options });
}
export const PreferenceSettingsDocument = gql`
    query PreferenceSettings @withOwner(owner: EnterpriseFoundations) {
  organization {
    id
    ...WorkspaceSection
    ...PointsOfContactSection
  }
}
    ${WorkspaceSectionFragmentDoc}
${PointsOfContactSectionFragmentDoc}`;

export function usePreferenceSettingsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQPreferenceSettingsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPreferenceSettingsQuery,
    GQTypes.GQPreferenceSettingsQueryVariables
  >({ query: PreferenceSettingsDocument, ...options });
}
export const OrgUsersDocument = gql`
    query OrgUsers($orgId: ID!) @withOwner(owner: EnterpriseFoundations) {
  organization {
    id
    users {
      edges {
        node {
          id
          displayName
          name
          orgAccessId(orgId: $orgId)
        }
      }
    }
  }
}
    `;

export function useOrgUsersQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQOrgUsersQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQOrgUsersQuery,
    GQTypes.GQOrgUsersQueryVariables
  >({ query: OrgUsersDocument, ...options });
}
export const PaginateRolesDocument = gql`
    query PaginateRoles($after: String, $before: String, $first: Int, $last: Int, $search: String) @withOwner(owner: EnterpriseFoundations) {
  roles(
    after: $after
    before: $before
    first: $first
    last: $last
    search: $search
  ) {
    edges {
      node {
        id
        ...RolesTableRole
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
      totalRowCount
    }
  }
}
    ${RolesTableRoleFragmentDoc}`;

export function usePaginateRolesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQPaginateRolesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPaginateRolesQuery,
    GQTypes.GQPaginateRolesQueryVariables
  >({ query: PaginateRolesDocument, ...options });
}
export const DashboardDeleteRoleDocument = gql`
    mutation DashboardDeleteRole($roleId: ID!) @withOwner(owner: EnterpriseFoundations) {
  deleteRole(input: {roleId: $roleId}) {
    orgId
    roleId
  }
}
    `;

export function useDashboardDeleteRoleMutation() {
  return Urql.useMutation<
    GQTypes.GQDashboardDeleteRoleMutation,
    GQTypes.GQDashboardDeleteRoleMutationVariables
  >(DashboardDeleteRoleDocument);
}
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) @withOwner(owner: EnterpriseFoundations) {
  updateUser(input: $input) {
    user {
      id
    }
  }
}
    `;

export function useUpdateUserMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateUserMutation,
    GQTypes.GQUpdateUserMutationVariables
  >(UpdateUserDocument);
}
export const OrgRolesDocument = gql`
    query OrgRoles @withOwner(owner: EnterpriseFoundations) {
  roles {
    edges {
      node {
        ...RoleForUserSettings
      }
    }
  }
}
    ${RoleForUserSettingsFragmentDoc}`;

export function useOrgRolesQuery(
  options?: Omit<Urql.UseQueryArgs<GQTypes.GQOrgRolesQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQOrgRolesQuery,
    GQTypes.GQOrgRolesQueryVariables
  >({ query: OrgRolesDocument, ...options });
}
export const OrgUsersWithPermissionDocument = gql`
    query OrgUsersWithPermission($orgId: ID!, $permission: PermissionType!, $objectType: PermissionObjectType, $objectId: ID) @withOwner(owner: EnterpriseFoundations) {
  orgUsersWithPermission(
    permission: $permission
    objectType: $objectType
    objectId: $objectId
  ) {
    id
    name
    permissionOrigin(
      orgId: $orgId
      permissions: [{permissionType: $permission, objectType: $objectType, objectId: $objectId}]
    ) {
      id
      permission {
        id
        permission
        object {
          id
          name
        }
      }
      role {
        id
        role {
          id
          name
        }
      }
    }
  }
  orgRolesWithPermission(
    permission: $permission
    objectType: $objectType
    objectId: $objectId
  ) {
    id
    name
    permissionOrigin(
      permission: {permissionType: $permission, objectType: $objectType, objectId: $objectId}
    ) {
      id
      permission {
        id
        permission
        object {
          id
          name
        }
      }
    }
  }
}
    `;

export function useOrgUsersWithPermissionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgUsersWithPermissionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgUsersWithPermissionQuery,
    GQTypes.GQOrgUsersWithPermissionQueryVariables
  >({ query: OrgUsersWithPermissionDocument, ...options });
}
export const CreateRoleDocument = gql`
    mutation CreateRole($input: CreateRoleInput!) @withOwner(owner: EnterpriseFoundations) {
  createRole(input: $input) {
    role {
      id
      name
      description
      createdAt
      createdBy {
        id
        name
      }
      permissions {
        id
        objectId
        permission
        object {
          __typename
          id
          name
        }
        revokedAt
      }
      users {
        id
        user {
          id
          displayName
          name
        }
      }
      apiKeys {
        id
        apiKey {
          id
          name
          description
        }
      }
    }
  }
}
    `;

export function useCreateRoleMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateRoleMutation,
    GQTypes.GQCreateRoleMutationVariables
  >(CreateRoleDocument);
}
export const CreateUserDashboardVersionDocument = gql`
    mutation CreateUserDashboardVersion($input: CreateUserInput!) @withOwner(owner: EnterpriseFoundations) {
  createUser(input: $input) {
    user {
      ...AssignableUserFields
    }
  }
}
    ${AssignableUserFieldsFragmentDoc}`;

export function useCreateUserDashboardVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateUserDashboardVersionMutation,
    GQTypes.GQCreateUserDashboardVersionMutationVariables
  >(CreateUserDashboardVersionDocument);
}
export const OrganizationPermissionResourceListDocument = gql`
    query OrganizationPermissionResourceList($permissionObjectType: PermissionObjectType!, $after: String, $before: String, $first: Int, $last: Int, $search: String, $sortModel: [DataGridSortItemInput!], $userOrRoleMetadata: UserOrRoleMetadata) @withOwner(owner: EnterpriseFoundations) {
  permissionObjectsPaginated(
    permissionObjectType: $permissionObjectType
    after: $after
    before: $before
    first: $first
    last: $last
    search: $search
    sortModel: $sortModel
    userOrRoleMetadata: $userOrRoleMetadata
  ) {
    edges {
      node {
        ...PermissionObject
        ...PermissionObjectWithChildren
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
      totalRowCount
    }
  }
}
    ${PermissionObjectFragmentDoc}
${PermissionObjectWithChildrenFragmentDoc}`;

export function useOrganizationPermissionResourceListQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationPermissionResourceListQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationPermissionResourceListQuery,
    GQTypes.GQOrganizationPermissionResourceListQueryVariables
  >({ query: OrganizationPermissionResourceListDocument, ...options });
}
export const OrganizationPermissionResourceListUsersAndRolesDocument = gql`
    query OrganizationPermissionResourceListUsersAndRoles($permissions: [HasPermissionInput!]!) @withOwner(owner: EnterpriseFoundations) {
  usersAndRolesForPermissions(permissions: $permissions)
}
    `;

export function useOrganizationPermissionResourceListUsersAndRolesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationPermissionResourceListUsersAndRolesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationPermissionResourceListUsersAndRolesQuery,
    GQTypes.GQOrganizationPermissionResourceListUsersAndRolesQueryVariables
  >({
    query: OrganizationPermissionResourceListUsersAndRolesDocument,
    ...options,
  });
}
export const RolePageDocument = gql`
    query RolePage($roleId: ID!) @withOwner(owner: EnterpriseFoundations) {
  role(id: $roleId) {
    id
    name
    description
    permissions {
      id
      ...PermissionFields
    }
    createdBy {
      id
      name
    }
    createdAt
  }
}
    ${PermissionFieldsFragmentDoc}`;

export function useRolePageQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQRolePageQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQRolePageQuery,
    GQTypes.GQRolePageQueryVariables
  >({ query: RolePageDocument, ...options });
}
export const DashboardUpdateRoleDocument = gql`
    mutation DashboardUpdateRole($input: UpdateRoleInput!) @withOwner(owner: EnterpriseFoundations) {
  updateRole(input: $input) {
    role {
      id
      name
      description
      createdAt
      createdBy {
        id
        name
      }
      permissions {
        id
        objectId
        permission
        object {
          __typename
          id
          name
        }
        revokedAt
      }
      users {
        id
        user {
          id
          displayName
          name
        }
      }
      apiKeys {
        id
        apiKey {
          id
          name
          description
        }
      }
    }
  }
}
    `;

export function useDashboardUpdateRoleMutation() {
  return Urql.useMutation<
    GQTypes.GQDashboardUpdateRoleMutation,
    GQTypes.GQDashboardUpdateRoleMutationVariables
  >(DashboardUpdateRoleDocument);
}
export const UpdateAuthSettingsDocument = gql`
    mutation UpdateAuthSettings($input: UpdateOrgInput!) @withOwner(owner: EnterpriseFoundations) {
  updateOrg(input: $input) {
    organization {
      id
      passwordAuthDisabled
      magicLinkDisabled
    }
  }
}
    `;

export function useUpdateAuthSettingsMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateAuthSettingsMutation,
    GQTypes.GQUpdateAuthSettingsMutationVariables
  >(UpdateAuthSettingsDocument);
}
export const CreateRoleV2Document = gql`
    mutation CreateRoleV2($input: CreateRoleInput!) @withOwner(owner: EnterpriseFoundations) {
  createRole(input: $input) {
    role {
      id
      name
      description
    }
  }
}
    `;

export function useCreateRoleV2Mutation() {
  return Urql.useMutation<
    GQTypes.GQCreateRoleV2Mutation,
    GQTypes.GQCreateRoleV2MutationVariables
  >(CreateRoleV2Document);
}
export const OrganizationPermissionTableDocument = gql`
    query OrganizationPermissionTable($permissionObjectType: PermissionObjectType!, $after: String, $before: String, $first: Int, $last: Int, $search: String, $sortModel: [DataGridSortItemInput!], $userOrRoleMetadata: UserOrRoleMetadata) @withOwner(owner: EnterpriseFoundations) {
  permissionObjectsPaginated(
    permissionObjectType: $permissionObjectType
    after: $after
    before: $before
    first: $first
    last: $last
    search: $search
    sortModel: $sortModel
    userOrRoleMetadata: $userOrRoleMetadata
  ) {
    edges {
      node {
        ...PermissionObject
        ...PermissionObjectWithChildren
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
      totalRowCount
    }
  }
}
    ${PermissionObjectFragmentDoc}
${PermissionObjectWithChildrenFragmentDoc}`;

export function useOrganizationPermissionTableQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationPermissionTableQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationPermissionTableQuery,
    GQTypes.GQOrganizationPermissionTableQueryVariables
  >({ query: OrganizationPermissionTableDocument, ...options });
}
export const OrganizationPermissionTableUsersAndRolesDocument = gql`
    query OrganizationPermissionTableUsersAndRoles($permissions: [HasPermissionInput!]!) @withOwner(owner: EnterpriseFoundations) {
  usersAndRolesForPermissions(permissions: $permissions)
}
    `;

export function useOrganizationPermissionTableUsersAndRolesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationPermissionTableUsersAndRolesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationPermissionTableUsersAndRolesQuery,
    GQTypes.GQOrganizationPermissionTableUsersAndRolesQueryVariables
  >({ query: OrganizationPermissionTableUsersAndRolesDocument, ...options });
}
export const GetOrganizationUsersDocument = gql`
    query GetOrganizationUsers($orgId: ID!, $after: String, $before: String, $first: Int, $last: Int, $search: String, $sortModel: [DataGridSortItemInput!]) @withOwner(owner: EnterpriseFoundations) {
  orgUsers(
    after: $after
    before: $before
    first: $first
    last: $last
    search: $search
    sortModel: $sortModel
  ) {
    edges {
      node {
        ...UserFieldsForUsersList
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
  }
}
    ${UserFieldsForUsersListFragmentDoc}`;

export function useGetOrganizationUsersQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetOrganizationUsersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetOrganizationUsersQuery,
    GQTypes.GQGetOrganizationUsersQueryVariables
  >({ query: GetOrganizationUsersDocument, ...options });
}
export const GetOrganizationSettingsForUsersListDocument = gql`
    query GetOrganizationSettingsForUsersList @withOwner(owner: EnterpriseFoundations) {
  organization {
    id
    passwordAuthDisabled
    workosDirectories {
      id
    }
  }
}
    `;

export function useGetOrganizationSettingsForUsersListQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetOrganizationSettingsForUsersListQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetOrganizationSettingsForUsersListQuery,
    GQTypes.GQGetOrganizationSettingsForUsersListQueryVariables
  >({ query: GetOrganizationSettingsForUsersListDocument, ...options });
}
export const GetAllUsersDocument = gql`
    query GetAllUsers($orgId: ID!) @withOwner(owner: EnterpriseFoundations) {
  allUsers {
    ...OrganizationMemberFields
  }
}
    ${OrganizationMemberFieldsFragmentDoc}`;

export function useGetAllUsersQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQGetAllUsersQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQGetAllUsersQuery,
    GQTypes.GQGetAllUsersQueryVariables
  >({ query: GetAllUsersDocument, ...options });
}
export const RemoveUserDocument = gql`
    mutation RemoveUser($input: RemoveUserInput!) @withOwner(owner: EnterpriseFoundations) {
  removeUser(input: $input) {
    user {
      id
    }
  }
}
    `;

export function useRemoveUserMutation() {
  return Urql.useMutation<
    GQTypes.GQRemoveUserMutation,
    GQTypes.GQRemoveUserMutationVariables
  >(RemoveUserDocument);
}
export const UpdateSessionTimeoutSettingsDocument = gql`
    mutation UpdateSessionTimeoutSettings($input: UpdateOrgInput!) @withOwner(owner: EnterpriseFoundations) {
  updateOrg(input: $input) {
    organization {
      id
      cookieTimeoutDays
      sessionTimeoutMinutes
    }
  }
}
    `;

export function useUpdateSessionTimeoutSettingsMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateSessionTimeoutSettingsMutation,
    GQTypes.GQUpdateSessionTimeoutSettingsMutationVariables
  >(UpdateSessionTimeoutSettingsDocument);
}
export const UpdateWorkspaceSectionSettingsDocument = gql`
    mutation UpdateWorkspaceSectionSettings($input: UpdateOrgInput!) @withOwner(owner: EnterpriseFoundations) {
  updateOrg(input: $input) {
    organization {
      id
      ...WorkspaceSection
    }
  }
}
    ${WorkspaceSectionFragmentDoc}`;

export function useUpdateWorkspaceSectionSettingsMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateWorkspaceSectionSettingsMutation,
    GQTypes.GQUpdateWorkspaceSectionSettingsMutationVariables
  >(UpdateWorkspaceSectionSettingsDocument);
}
export const DustBotQueryDocument = gql`
    query DustBotQuery($input: DustBotQueryInput!) @withOwner(owner: EnterpriseFoundations) {
  dustBotQuery(input: $input) {
    response
  }
}
    `;

export function useDustBotQueryQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDustBotQueryQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDustBotQueryQuery,
    GQTypes.GQDustBotQueryQueryVariables
  >({ query: DustBotQueryDocument, ...options });
}
export const CreateSupportCaseDocument = gql`
    mutation CreateSupportCase($input: CreateSupportCaseInput!, $after: String, $before: String, $first: Int, $last: Int) @withOwner(owner: EnterpriseFoundations) {
  createSupportCase(input: $input) {
    supportCase {
      ...SupportCaseForSupportCasePage
    }
  }
}
    ${SupportCaseForSupportCasePageFragmentDoc}`;

export function useCreateSupportCaseMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateSupportCaseMutation,
    GQTypes.GQCreateSupportCaseMutationVariables
  >(CreateSupportCaseDocument);
}
export const SupportCaseNotificationCountDocument = gql`
    query SupportCaseNotificationCount($objectId: ID, $issueType: SupportCaseIssueType) @withOwner(owner: EnterpriseFoundations) {
  supportCaseNotificationCount(objectId: $objectId, issueType: $issueType)
}
    `;

export function useSupportCaseNotificationCountQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQSupportCaseNotificationCountQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSupportCaseNotificationCountQuery,
    GQTypes.GQSupportCaseNotificationCountQueryVariables
  >({ query: SupportCaseNotificationCountDocument, ...options });
}
export const SupportCaseListDocument = gql`
    query SupportCaseList($hasUnreadNotifications: Boolean, $issueType: SupportCaseIssueType, $priority: SupportCasePriority, $status: SupportCaseStatus, $userId: ID, $onlyOpen: Boolean, $search: String, $sortModel: [DataGridSortItemInput!], $after: String, $before: String, $first: Int, $last: Int) @withOwner(owner: EnterpriseFoundations) {
  supportCases(
    hasUnreadNotifications: $hasUnreadNotifications
    issueType: $issueType
    priority: $priority
    status: $status
    userId: $userId
    onlyOpen: $onlyOpen
    search: $search
    sortModel: $sortModel
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    edges {
      cursor
      node {
        ...SupportCaseForSupportCasesPage
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
      totalRowCount
    }
  }
}
    ${SupportCaseForSupportCasesPageFragmentDoc}`;

export function useSupportCaseListQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQSupportCaseListQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSupportCaseListQuery,
    GQTypes.GQSupportCaseListQueryVariables
  >({ query: SupportCaseListDocument, ...options });
}
export const AllSupportCasesDocument = gql`
    query AllSupportCases @withOwner(owner: EnterpriseFoundations) {
  allSupportCases {
    id
    ...SupportCaseForSupportCasesPage
  }
}
    ${SupportCaseForSupportCasesPageFragmentDoc}`;

export function useAllSupportCasesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQAllSupportCasesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAllSupportCasesQuery,
    GQTypes.GQAllSupportCasesQueryVariables
  >({ query: AllSupportCasesDocument, ...options });
}
export const SupportCasePageDocument = gql`
    query SupportCasePage($id: ID!, $after: String, $before: String, $first: Int, $last: Int) @withOwner(owner: EnterpriseFoundations) {
  supportCase(id: $id) {
    ...SupportCaseForSupportCasePage
  }
}
    ${SupportCaseForSupportCasePageFragmentDoc}`;

export function useSupportCasePageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQSupportCasePageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSupportCasePageQuery,
    GQTypes.GQSupportCasePageQueryVariables
  >({ query: SupportCasePageDocument, ...options });
}
export const CreateSupportCaseCommentDocument = gql`
    mutation CreateSupportCaseComment($input: CreateSupportCaseCommentInput!, $after: String, $before: String, $first: Int, $last: Int) @withOwner(owner: EnterpriseFoundations) {
  createSupportCaseComment(input: $input) {
    supportCase {
      ...SupportCaseForSupportCasePage
    }
  }
}
    ${SupportCaseForSupportCasePageFragmentDoc}`;

export function useCreateSupportCaseCommentMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateSupportCaseCommentMutation,
    GQTypes.GQCreateSupportCaseCommentMutationVariables
  >(CreateSupportCaseCommentDocument);
}
export const MarkSupportCaseNotificationReadDocument = gql`
    mutation MarkSupportCaseNotificationRead($input: MarkSupportCaseNotificationReadInput!, $after: String, $before: String, $first: Int, $last: Int) @withOwner(owner: EnterpriseFoundations) {
  markSupportCaseNotificationRead(input: $input) {
    supportCase {
      ...SupportCaseForSupportCasePage
    }
  }
}
    ${SupportCaseForSupportCasePageFragmentDoc}`;

export function useMarkSupportCaseNotificationReadMutation() {
  return Urql.useMutation<
    GQTypes.GQMarkSupportCaseNotificationReadMutation,
    GQTypes.GQMarkSupportCaseNotificationReadMutationVariables
  >(MarkSupportCaseNotificationReadDocument);
}
export const UpdateSupportCaseDocument = gql`
    mutation UpdateSupportCase($input: UpdateSupportCaseInput!, $after: String, $before: String, $first: Int, $last: Int) @withOwner(owner: EnterpriseFoundations) {
  updateSupportCase(input: $input) {
    supportCase {
      ...SupportCaseForSupportCasePage
    }
  }
}
    ${SupportCaseForSupportCasePageFragmentDoc}`;

export function useUpdateSupportCaseMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateSupportCaseMutation,
    GQTypes.GQUpdateSupportCaseMutationVariables
  >(UpdateSupportCaseDocument);
}
export const SupportCaseListForPanelDocument = gql`
    query SupportCaseListForPanel($after: String, $before: String, $first: Int, $last: Int, $objectId: String) @withOwner(owner: EnterpriseFoundations) {
  supportCases(
    after: $after
    before: $before
    first: $first
    last: $last
    objectId: $objectId
  ) {
    edges {
      cursor
      node {
        ...SupportCaseForSupportPanel
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
      totalRowCount
    }
  }
}
    ${SupportCaseForSupportPanelFragmentDoc}`;

export function useSupportCaseListForPanelQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQSupportCaseListForPanelQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSupportCaseListForPanelQuery,
    GQTypes.GQSupportCaseListForPanelQueryVariables
  >({ query: SupportCaseListForPanelDocument, ...options });
}
export const CreateSupportCaseAttachmentsDocument = gql`
    mutation CreateSupportCaseAttachments($input: CreateSupportCaseAttachmentsInput!) @withOwner(owner: EnterpriseFoundations) {
  createSupportCaseAttachments(input: $input) {
    files {
      signedUrl
      fileMetadataId
      fileName
    }
  }
}
    `;

export function useCreateSupportCaseAttachmentsMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateSupportCaseAttachmentsMutation,
    GQTypes.GQCreateSupportCaseAttachmentsMutationVariables
  >(CreateSupportCaseAttachmentsDocument);
}
export const FetchSupportCaseAttachmentDownloadUrlDocument = gql`
    mutation FetchSupportCaseAttachmentDownloadUrl($input: FetchSupportCaseAttachmentDownloadUrlInput!) @withOwner(owner: EnterpriseFoundations) {
  fetchSupportCaseAttachmentDownloadUrl(input: $input) {
    downloadUrl
  }
}
    `;

export function useFetchSupportCaseAttachmentDownloadUrlMutation() {
  return Urql.useMutation<
    GQTypes.GQFetchSupportCaseAttachmentDownloadUrlMutation,
    GQTypes.GQFetchSupportCaseAttachmentDownloadUrlMutationVariables
  >(FetchSupportCaseAttachmentDownloadUrlDocument);
}
export const DeleteSupportCaseAttachmentsDocument = gql`
    mutation DeleteSupportCaseAttachments($input: DeleteSupportCaseAttachmentsInput!) @withOwner(owner: EnterpriseFoundations) {
  deleteSupportCaseAttachments(input: $input) {
    files {
      id
    }
  }
}
    `;

export function useDeleteSupportCaseAttachmentsMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteSupportCaseAttachmentsMutation,
    GQTypes.GQDeleteSupportCaseAttachmentsMutationVariables
  >(DeleteSupportCaseAttachmentsDocument);
}
export const GiveFeedbackDocument = gql`
    mutation GiveFeedback($input: GiveFeedbackInput!) {
  giveFeedback(input: $input) {
    status
  }
}
    `;

export function useGiveFeedbackMutation() {
  return Urql.useMutation<
    GQTypes.GQGiveFeedbackMutation,
    GQTypes.GQGiveFeedbackMutationVariables
  >(GiveFeedbackDocument);
}
export const GetCustomerHubDataDocument = gql`
    query GetCustomerHubData($companyId: ID!) @withOwner(owner: SupplyChain) {
  companyForDashboard(id: $companyId) {
    ...CompanyFieldsForEngagementTasks
  }
  engagementTasks {
    edges {
      node {
        ...EngagementTaskContents
      }
    }
  }
  organization {
    id
    company {
      ...CompanyFieldsForEngagementTasks
    }
    users {
      edges {
        node {
          id
          name
          displayName
        }
      }
    }
  }
  companyPortalSettings(rootCompanyId: $companyId) {
    ...CompanyPortalSettingsFields
  }
}
    ${CompanyFieldsForEngagementTasksFragmentDoc}
${EngagementTaskContentsFragmentDoc}
${CompanyPortalSettingsFieldsFragmentDoc}`;

export function useGetCustomerHubDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCustomerHubDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCustomerHubDataQuery,
    GQTypes.GQGetCustomerHubDataQueryVariables
  >({ query: GetCustomerHubDataDocument, ...options });
}
export const LogCustomerHubViewedDocument = gql`
    mutation LogCustomerHubViewed($input: LogCustomerHubViewedInput!) @withOwner(owner: SupplyChain) {
  logCustomerHubViewed(input: $input) {
    customerCompanyId
  }
}
    `;

export function useLogCustomerHubViewedMutation() {
  return Urql.useMutation<
    GQTypes.GQLogCustomerHubViewedMutation,
    GQTypes.GQLogCustomerHubViewedMutationVariables
  >(LogCustomerHubViewedDocument);
}
export const GetPreviewAsCompanyDocument = gql`
    query GetPreviewAsCompany($previewAsCompanyId: ID!) @withOwner(owner: SupplyChain) {
  companyForDashboard(id: $previewAsCompanyId) {
    ...CompanyFieldsForEngagementTasks
  }
}
    ${CompanyFieldsForEngagementTasksFragmentDoc}`;

export function useGetPreviewAsCompanyQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPreviewAsCompanyQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPreviewAsCompanyQuery,
    GQTypes.GQGetPreviewAsCompanyQueryVariables
  >({ query: GetPreviewAsCompanyDocument, ...options });
}
export const GetEngagementTasksPortalDataDocument = gql`
    query GetEngagementTasksPortalData @withOwner(owner: SupplyChain) {
  userHasPermission(permission: ManageDisclosures, allowAnyObject: true)
  engagementTasks {
    edges {
      node {
        ...EngagementTaskFields
      }
    }
  }
}
    ${EngagementTaskFieldsFragmentDoc}`;

export function useGetEngagementTasksPortalDataQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEngagementTasksPortalDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEngagementTasksPortalDataQuery,
    GQTypes.GQGetEngagementTasksPortalDataQueryVariables
  >({ query: GetEngagementTasksPortalDataDocument, ...options });
}
export const OrgUsersForSurveyDocument = gql`
    query OrgUsersForSurvey @withOwner(owner: SupplyChain) {
  organization {
    id
    name
    users {
      edges {
        node {
          id
          name
          displayName
        }
      }
    }
  }
}
    `;

export function useOrgUsersForSurveyQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgUsersForSurveyQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgUsersForSurveyQuery,
    GQTypes.GQOrgUsersForSurveyQueryVariables
  >({ query: OrgUsersForSurveyDocument, ...options });
}
export const LogSupplyChainSurveyEventDocument = gql`
    mutation LogSupplyChainSurveyEvent($input: LogSupplyChainSurveyEventInput!) @withOwner(owner: SupplyChain) {
  logSupplyChainSurveyEvent(input: $input) {
    success
  }
}
    `;

export function useLogSupplyChainSurveyEventMutation() {
  return Urql.useMutation<
    GQTypes.GQLogSupplyChainSurveyEventMutation,
    GQTypes.GQLogSupplyChainSurveyEventMutationVariables
  >(LogSupplyChainSurveyEventDocument);
}
export const GetEngagementTaskByCrossOrgModelIdDocument = gql`
    query GetEngagementTaskByCrossOrgModelId($crossOrgModelId: String!) @withOwner(owner: SupplyChain) {
  engagementTaskByCrossOrgModelId(crossOrgModelId: $crossOrgModelId) {
    ...EngagementTaskContents
  }
}
    ${EngagementTaskContentsFragmentDoc}`;

export function useGetEngagementTaskByCrossOrgModelIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEngagementTaskByCrossOrgModelIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEngagementTaskByCrossOrgModelIdQuery,
    GQTypes.GQGetEngagementTaskByCrossOrgModelIdQueryVariables
  >({ query: GetEngagementTaskByCrossOrgModelIdDocument, ...options });
}
export const SubmitEngagementTaskDocument = gql`
    mutation SubmitEngagementTask($input: SubmitEngagementTaskInput!) @withOwner(owner: SupplyChain) {
  submitEngagementTask(input: $input) {
    engagementTask {
      ...EngagementTaskContents
    }
  }
}
    ${EngagementTaskContentsFragmentDoc}`;

export function useSubmitEngagementTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQSubmitEngagementTaskMutation,
    GQTypes.GQSubmitEngagementTaskMutationVariables
  >(SubmitEngagementTaskDocument);
}
export const UpsertEngagementTaskAnswersDocument = gql`
    mutation UpsertEngagementTaskAnswers($input: UpsertEngagementTaskAnswersInput!) @withOwner(owner: SupplyChain) {
  upsertEngagementTaskAnswers(input: $input) {
    engagementTask {
      ...EngagementTaskContents
    }
  }
}
    ${EngagementTaskContentsFragmentDoc}`;

export function useUpsertEngagementTaskAnswersMutation() {
  return Urql.useMutation<
    GQTypes.GQUpsertEngagementTaskAnswersMutation,
    GQTypes.GQUpsertEngagementTaskAnswersMutationVariables
  >(UpsertEngagementTaskAnswersDocument);
}
export const CreateEngagementTaskAttachmentsDocument = gql`
    mutation CreateEngagementTaskAttachments($input: CreateEngagementTaskAttachmentsInput!) @withOwner(owner: SupplyChain) {
  createEngagementTaskAttachments(input: $input) {
    attachmentMetadata {
      signedUploadUrl
      fileMetadataId
      supplierAttachmentCrossOrgId
    }
  }
}
    `;

export function useCreateEngagementTaskAttachmentsMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateEngagementTaskAttachmentsMutation,
    GQTypes.GQCreateEngagementTaskAttachmentsMutationVariables
  >(CreateEngagementTaskAttachmentsDocument);
}
export const TraceDetailsForEvaluationDocument = gql`
    query TraceDetailsForEvaluation($evaluationId: ID!, $tracesByFingerprint: [TraceByFingerprintInput!]!) @withOwner(owner: Calcprint) {
  traceDetailsForEvaluation(
    evaluationId: $evaluationId
    tracesByFingerprint: $tracesByFingerprint
  ) {
    fingerprint
    details {
      ...TraceDetailsFragment
    }
  }
}
    ${TraceDetailsFragmentFragmentDoc}`;

export function useTraceDetailsForEvaluationQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQTraceDetailsForEvaluationQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQTraceDetailsForEvaluationQuery,
    GQTypes.GQTraceDetailsForEvaluationQueryVariables
  >({ query: TraceDetailsForEvaluationDocument, ...options });
}
export const RulesDetailDocument = gql`
    query RulesDetail($exclusionRuleIds: [ID!]!, $categoryRuleIds: [ID!]!) @withOwner(owner: Calcprint) {
  footprintExclusionRules(ids: $exclusionRuleIds) {
    ...FootprintExclusionRuleForEditorV2
  }
  footprintCategoryRules(ids: $categoryRuleIds) {
    ...FootprintCategoryRuleForEditor
  }
}
    ${FootprintExclusionRuleForEditorV2FragmentDoc}
${FootprintCategoryRuleForEditorFragmentDoc}`;

export function useRulesDetailQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQRulesDetailQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQRulesDetailQuery,
    GQTypes.GQRulesDetailQueryVariables
  >({ query: RulesDetailDocument, ...options });
}
export const LineageRunDetailsDocument = gql`
    query LineageRunDetails($id: ID!) @withOwner(owner: Calcprint) {
  lineageRun(id: $id) {
    id
    ...LineageRunDetails
  }
}
    ${LineageRunDetailsFragmentDoc}`;

export function useLineageRunDetailsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQLineageRunDetailsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQLineageRunDetailsQuery,
    GQTypes.GQLineageRunDetailsQueryVariables
  >({ query: LineageRunDetailsDocument, ...options });
}
export const GetFingerprintLineageGraphDocument = gql`
    query GetFingerprintLineageGraph($footprintSnapshotId: String!, $fingerprint: String!, $singleMeasureSelector: BiQuerySingleMeasureSelectorInput!, $emissionsModelEvaluationId: String!) @withOwner(owner: Calcprint) {
  emEvalFingerprintLineageGraph(
    footprintSnapshotId: $footprintSnapshotId
    fingerprint: $fingerprint
    singleMeasureSelector: $singleMeasureSelector
    emissionsModelEvaluationId: $emissionsModelEvaluationId
  ) {
    serializedGraph
  }
}
    `;

export function useGetFingerprintLineageGraphQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFingerprintLineageGraphQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFingerprintLineageGraphQuery,
    GQTypes.GQGetFingerprintLineageGraphQueryVariables
  >({ query: GetFingerprintLineageGraphDocument, ...options });
}
export const GetCustomerTargetSchemaVersionDocument = gql`
    query GetCustomerTargetSchemaVersion($ctsVersionId: ID!, $fmdId: ID!) @withOwner(owner: Calcprint) {
  customerTargetSchemaVersion(ctsVersionId: $ctsVersionId) {
    id
    schema {
      id
      name
    }
  }
  userUploadTaskByFileMetadataId(fileMetadataId: $fmdId) {
    id
    datasource {
      id
      name
      dataset {
        id
        name
      }
    }
  }
}
    `;

export function useGetCustomerTargetSchemaVersionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCustomerTargetSchemaVersionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCustomerTargetSchemaVersionQuery,
    GQTypes.GQGetCustomerTargetSchemaVersionQueryVariables
  >({ query: GetCustomerTargetSchemaVersionDocument, ...options });
}
export const GetUserUploadsByIdsDocument = gql`
    query GetUserUploadsByIds($ids: [ID!]!) @withOwner(owner: Calcprint) {
  userUploadsById(ids: $ids) {
    ...UserUploadForFileDownload
  }
}
    ${UserUploadForFileDownloadFragmentDoc}`;

export function useGetUserUploadsByIdsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetUserUploadsByIdsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetUserUploadsByIdsQuery,
    GQTypes.GQGetUserUploadsByIdsQueryVariables
  >({ query: GetUserUploadsByIdsDocument, ...options });
}
export const RemoveCompanyFromPeerCompanyGroupDocument = gql`
    mutation RemoveCompanyFromPeerCompanyGroup($input: RemoveCompanyFromPeerCompanyGroupInput!) @withOwner(owner: SupplyChain) {
  removeCompanyFromPeerCompanyGroup(input: $input) {
    peerCompanyGroup {
      ...PeerCompanyGroupForBenchmarks
    }
  }
}
    ${PeerCompanyGroupForBenchmarksFragmentDoc}`;

export function useRemoveCompanyFromPeerCompanyGroupMutation() {
  return Urql.useMutation<
    GQTypes.GQRemoveCompanyFromPeerCompanyGroupMutation,
    GQTypes.GQRemoveCompanyFromPeerCompanyGroupMutationVariables
  >(RemoveCompanyFromPeerCompanyGroupDocument);
}
export const CompanySearchForBenchmarksDocument = gql`
    query CompanySearchForBenchmarks($search: String, $naicsCode: String, $after: String, $before: String, $first: Int, $last: Int) @withOwner(owner: SupplyChain) {
  benchmarkCompanies(
    search: $search
    naicsCode: $naicsCode
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    edges {
      node {
        ...CompanyForBenchmarks
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
  benchmarkNaicsCodes(search: $search)
}
    ${CompanyForBenchmarksFragmentDoc}`;

export function useCompanySearchForBenchmarksQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCompanySearchForBenchmarksQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCompanySearchForBenchmarksQuery,
    GQTypes.GQCompanySearchForBenchmarksQueryVariables
  >({ query: CompanySearchForBenchmarksDocument, ...options });
}
export const CompanyGroupsForBenchmarksDocument = gql`
    query CompanyGroupsForBenchmarks @withOwner(owner: SupplyChain) {
  peerCompanyGroups {
    ...PeerCompanyGroupForBenchmarks
  }
}
    ${PeerCompanyGroupForBenchmarksFragmentDoc}`;

export function useCompanyGroupsForBenchmarksQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCompanyGroupsForBenchmarksQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCompanyGroupsForBenchmarksQuery,
    GQTypes.GQCompanyGroupsForBenchmarksQueryVariables
  >({ query: CompanyGroupsForBenchmarksDocument, ...options });
}
export const AccessibleOrgsAndEngagementTasksDocument = gql`
    query AccessibleOrgsAndEngagementTasks @withOwner(owner: EnterpriseFoundations) {
  activeOrganization {
    ...AccessibleOrgsAndEngagementTasks
  }
}
    ${AccessibleOrgsAndEngagementTasksFragmentDoc}`;

export function useAccessibleOrgsAndEngagementTasksQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQAccessibleOrgsAndEngagementTasksQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAccessibleOrgsAndEngagementTasksQuery,
    GQTypes.GQAccessibleOrgsAndEngagementTasksQueryVariables
  >({ query: AccessibleOrgsAndEngagementTasksDocument, ...options });
}
export const CanonicalClimateProgramProjectsForAddProjectDocument = gql`
    query CanonicalClimateProgramProjectsForAddProject @withOwner(owner: EnterpriseFoundations) {
  canonicalClimateProgramProjects {
    ...CanonicalClimateProgramProjectForAddProject
  }
  peerCompanyGroups {
    ...PeerCompanyGroupFields
  }
}
    ${CanonicalClimateProgramProjectForAddProjectFragmentDoc}
${PeerCompanyGroupFieldsFragmentDoc}`;

export function useCanonicalClimateProgramProjectsForAddProjectQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCanonicalClimateProgramProjectsForAddProjectQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCanonicalClimateProgramProjectsForAddProjectQuery,
    GQTypes.GQCanonicalClimateProgramProjectsForAddProjectQueryVariables
  >({
    query: CanonicalClimateProgramProjectsForAddProjectDocument,
    ...options,
  });
}
export const ClimateProgramProjectsForHomepageDocument = gql`
    query ClimateProgramProjectsForHomepage @withOwner(owner: EnterpriseFoundations) {
  climateProgramTimelineProjects {
    ...ClimateProgramTimelineProjectFields
  }
  climateProgramCalendarEvents {
    ...ClimateProgramCalendarEventFields
  }
}
    ${ClimateProgramTimelineProjectFieldsFragmentDoc}
${ClimateProgramCalendarEventFieldsFragmentDoc}`;

export function useClimateProgramProjectsForHomepageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQClimateProgramProjectsForHomepageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQClimateProgramProjectsForHomepageQuery,
    GQTypes.GQClimateProgramProjectsForHomepageQueryVariables
  >({ query: ClimateProgramProjectsForHomepageDocument, ...options });
}
export const FinanceAssetIdForNameDocument = gql`
    query FinanceAssetIdForName($name: String!) @withOwner(owner: WinFinance) {
  assetIdForName(name: $name)
}
    `;

export function useFinanceAssetIdForNameQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceAssetIdForNameQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceAssetIdForNameQuery,
    GQTypes.GQFinanceAssetIdForNameQueryVariables
  >({ query: FinanceAssetIdForNameDocument, ...options });
}
export const GetSingleRowMethodologyForEmissionsYearDocument = gql`
    query GetSingleRowMethodologyForEmissionsYear($emissionsYearId: String!, $rowId: Int!) @withOwner(owner: WinFinance) {
  singleRowMethodologyExplainerForEmissionsYear(
    emissionsYearId: $emissionsYearId
    rowId: $rowId
  ) {
    ...SingleRowMethodologyExplainerFields
  }
}
    ${SingleRowMethodologyExplainerFieldsFragmentDoc}`;

export function useGetSingleRowMethodologyForEmissionsYearQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSingleRowMethodologyForEmissionsYearQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSingleRowMethodologyForEmissionsYearQuery,
    GQTypes.GQGetSingleRowMethodologyForEmissionsYearQueryVariables
  >({ query: GetSingleRowMethodologyForEmissionsYearDocument, ...options });
}
export const FootprintDocument = gql`
    query Footprint($footprintId: String!) @withOwner(owner: Calcprint) {
  footprint(footprintId: $footprintId) {
    id
    name
    defaultFootprintSnapshot {
      id
    }
    latestPublishedFootprintSnapshot {
      id
    }
    latestDraftFootprintSnapshot {
      id
    }
  }
}
    `;

export function useFootprintQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQFootprintQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQFootprintQuery,
    GQTypes.GQFootprintQueryVariables
  >({ query: FootprintDocument, ...options });
}
export const GetDefaultFootprintSnapshotForLegacyDrilldownRedirectDocument = gql`
    query GetDefaultFootprintSnapshotForLegacyDrilldownRedirect($viewDraft: Boolean!) @withOwner(owner: Calcprint) {
  defaultFootprintSnapshotForLegacyDrilldownRedirect(viewDraft: $viewDraft) {
    id
  }
}
    `;

export function useGetDefaultFootprintSnapshotForLegacyDrilldownRedirectQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetDefaultFootprintSnapshotForLegacyDrilldownRedirectQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetDefaultFootprintSnapshotForLegacyDrilldownRedirectQuery,
    GQTypes.GQGetDefaultFootprintSnapshotForLegacyDrilldownRedirectQueryVariables
  >({
    query: GetDefaultFootprintSnapshotForLegacyDrilldownRedirectDocument,
    ...options,
  });
}
export const GetSingleRowMethodologyDocument = gql`
    query getSingleRowMethodology($bundleId: String!, $rowId: Int!) @withOwner(owner: Calcprint) {
  singleRowMethodologyExplainer(bundleId: $bundleId, rowId: $rowId) {
    ...SingleRowMethodologyExplainerFields
  }
  footprintAuditBundle(bundleId: $bundleId) {
    id
    createdAt
    inputJson
  }
}
    ${SingleRowMethodologyExplainerFieldsFragmentDoc}`;

export function useGetSingleRowMethodologyQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSingleRowMethodologyQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSingleRowMethodologyQuery,
    GQTypes.GQGetSingleRowMethodologyQueryVariables
  >({ query: GetSingleRowMethodologyDocument, ...options });
}
export const GetFootprintAuditBundleDocument = gql`
    query getFootprintAuditBundle($bundleId: String!) @withOwner(owner: Calcprint) {
  footprintAuditBundle(bundleId: $bundleId) {
    id
    createdAt
    downloadUrl
    footprintEmissionsKind
    downloadSize
  }
  footprintAuditVerificationTemplateUrl
}
    `;

export function useGetFootprintAuditBundleQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintAuditBundleQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintAuditBundleQuery,
    GQTypes.GQGetFootprintAuditBundleQueryVariables
  >({ query: GetFootprintAuditBundleDocument, ...options });
}
export const HomePageDocument = gql`
    query HomePage @withOwner(owner: EnterpriseFoundations) {
  organization {
    id
    shouldShowOnboarding
  }
}
    `;

export function useHomePageQuery(
  options?: Omit<Urql.UseQueryArgs<GQTypes.GQHomePageQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQHomePageQuery,
    GQTypes.GQHomePageQueryVariables
  >({ query: HomePageDocument, ...options });
}
export const HomePageExpensiveDocument = gql`
    query HomePageExpensive($hasViewReductionsPermissions: Boolean!, $hasManageAnyDatasourcePermissions: Boolean!) @withOwner(owner: EnterpriseFoundations) {
  measurementProjectsRelevantToViewer(limit: 1) @include(if: $hasManageAnyDatasourcePermissions) {
    ...NextMeasurementProjectData
  }
  organization {
    id
    lifecycle {
      nextStage
    }
  }
  committedPlans: plans(state: Committed) @include(if: $hasViewReductionsPermissions) {
    edges {
      node {
        ...PlanFieldsForHomepage
      }
    }
  }
  visiblePlans: plans(state: Draft, first: 3) @include(if: $hasViewReductionsPermissions) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    ${NextMeasurementProjectDataFragmentDoc}
${PlanFieldsForHomepageFragmentDoc}`;

export function useHomePageExpensiveQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQHomePageExpensiveQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQHomePageExpensiveQuery,
    GQTypes.GQHomePageExpensiveQueryVariables
  >({ query: HomePageExpensiveDocument, ...options });
}
export const I18nSandboxPageDocument = gql`
    query I18nSandboxPage @withOwner(owner: EnterpriseFoundations) {
  i18nSandbox {
    name
    name2
    name3
  }
}
    `;

export function useI18nSandboxPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQI18nSandboxPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQI18nSandboxPageQuery,
    GQTypes.GQI18nSandboxPageQueryVariables
  >({ query: I18nSandboxPageDocument, ...options });
}
export const EacPurchaseProductWaitlistDocument = gql`
    query EacPurchaseProductWaitlist @withOwner(owner: SupplyChain) {
  productWaitlistTypes
}
    `;

export function useEacPurchaseProductWaitlistQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQEacPurchaseProductWaitlistQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEacPurchaseProductWaitlistQuery,
    GQTypes.GQEacPurchaseProductWaitlistQueryVariables
  >({ query: EacPurchaseProductWaitlistDocument, ...options });
}
export const MarketplacePurchasesPageDocument = gql`
    query MarketplacePurchasesPage($targetCurrency: String!) @withOwner(owner: SupplyChain) {
  userHasPermission(permission: ManageMarketplacePurchases)
  marketplacePurchases {
    edges {
      node {
        ...MarketplacePurchaseFieldsForPurchasesPage
      }
    }
  }
  latestCurrencyExchangeRate(targetCurrency: $targetCurrency) {
    ...CurrencyExchangeRateForPurchasesPage
  }
}
    ${MarketplacePurchaseFieldsForPurchasesPageFragmentDoc}
${CurrencyExchangeRateForPurchasesPageFragmentDoc}`;

export function useMarketplacePurchasesPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplacePurchasesPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplacePurchasesPageQuery,
    GQTypes.GQMarketplacePurchasesPageQueryVariables
  >({ query: MarketplacePurchasesPageDocument, ...options });
}
export const MeasurementProjectPageDocument = gql`
    query MeasurementProjectPage($id: ID!) @withOwner(owner: DataIngestion) {
  measurementProject(id: $id) {
    ...MeasurementProjectForMeasurementPage
  }
  userHasPermission: userHasViewDatasourcePermission(allowAnyObject: true)
  userHasManageMeasurement: userHasPermission(permission: ManageMeasurement)
  organization {
    id
    measurementProjects {
      ...MeasurementProjectFields
    }
    facilitiesMetadata {
      buildingsDataset {
        id
        name
      }
      utilitiesDataset {
        id
        name
      }
    }
    ingestionCustomFields {
      ...MeasurementProjectPageIngestionCustomField
    }
  }
  latestFootprintAssemblyRun {
    id
    status
  }
}
    ${MeasurementProjectForMeasurementPageFragmentDoc}
${MeasurementProjectFieldsFragmentDoc}
${MeasurementProjectPageIngestionCustomFieldFragmentDoc}`;

export function useMeasurementProjectPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMeasurementProjectPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMeasurementProjectPageQuery,
    GQTypes.GQMeasurementProjectPageQueryVariables
  >({ query: MeasurementProjectPageDocument, ...options });
}
export const TotalFacilitiesCountForMeasurementProjectPageDocument = gql`
    query TotalFacilitiesCountForMeasurementProjectPage @withOwner(owner: DataIngestion) {
  facilities {
    pageInfo {
      totalRowCount
    }
  }
}
    `;

export function useTotalFacilitiesCountForMeasurementProjectPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQTotalFacilitiesCountForMeasurementProjectPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQTotalFacilitiesCountForMeasurementProjectPageQuery,
    GQTypes.GQTotalFacilitiesCountForMeasurementProjectPageQueryVariables
  >({
    query: TotalFacilitiesCountForMeasurementProjectPageDocument,
    ...options,
  });
}
export const PresetCustomTagsForMeasurementProjectPageDocument = gql`
    query PresetCustomTagsForMeasurementProjectPage($id: ID!) @withOwner(owner: DataIngestion) {
  measurementProject(id: $id) {
    id
    measurementTasks {
      id
      object {
        __typename
        id
        ... on UserUploadTask {
          datasource {
            id
            presetCustomTags {
              ...PresetCustomTagForMeasurementPage
            }
          }
        }
      }
    }
  }
}
    ${PresetCustomTagForMeasurementPageFragmentDoc}`;

export function usePresetCustomTagsForMeasurementProjectPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQPresetCustomTagsForMeasurementProjectPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPresetCustomTagsForMeasurementProjectPageQuery,
    GQTypes.GQPresetCustomTagsForMeasurementProjectPageQueryVariables
  >({ query: PresetCustomTagsForMeasurementProjectPageDocument, ...options });
}
export const UserUploadCountsForMeasurementProjectPageDocument = gql`
    query UserUploadCountsForMeasurementProjectPage($id: ID!, $enableUserUploadCountsOnMeasurementPage: Boolean!) @withOwner(owner: DataIngestion) {
  measurementProject(id: $id) @include(if: $enableUserUploadCountsOnMeasurementPage) {
    id
    userUploadTasks {
      edges {
        node {
          id
          datasource {
            id
            dataset {
              id
              canonicalDataset {
                id
                kind
              }
            }
          }
          userUploads {
            edges {
              node {
                id
                revisionRoot
                status
                processingMode
                userVisibleAttempt {
                  id
                  name
                  remoteWritten
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

export function useUserUploadCountsForMeasurementProjectPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQUserUploadCountsForMeasurementProjectPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQUserUploadCountsForMeasurementProjectPageQuery,
    GQTypes.GQUserUploadCountsForMeasurementProjectPageQueryVariables
  >({ query: UserUploadCountsForMeasurementProjectPageDocument, ...options });
}
export const DatasourcePageDocument = gql`
    query DatasourcePage($datasourceId: ID!, $measurementProjectId: ID!, $userUploadLimit: Int) @withOwner(owner: DataIngestion) {
  userUploadTaskForDatasource(
    datasourceId: $datasourceId
    measurementProjectId: $measurementProjectId
  ) {
    ...UserUploadTaskWithDatasource
  }
  organization @_required {
    ...OrganizationCustomFieldsForDatasourcePage
  }
  userHasPermission: userHasViewDatasourcePermission(datasourceId: $datasourceId)
}
    ${UserUploadTaskWithDatasourceFragmentDoc}
${OrganizationCustomFieldsForDatasourcePageFragmentDoc}`;

export function useDatasourcePageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDatasourcePageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDatasourcePageQuery,
    GQTypes.GQDatasourcePageQueryVariables
  >({ query: DatasourcePageDocument, ...options });
}
export const UpdateDatasourceNewDocument = gql`
    mutation UpdateDatasourceNew($input: UpdateDatasourceInput!, $measurementProjectId: ID, $userUploadLimit: Int) @withOwner(owner: DataIngestion) {
  updateDatasource(input: $input) {
    datasource {
      ...DatasourceFieldsForDatasourcePage
    }
  }
}
    ${DatasourceFieldsForDatasourcePageFragmentDoc}`;

export function useUpdateDatasourceNewMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateDatasourceNewMutation,
    GQTypes.GQUpdateDatasourceNewMutationVariables
  >(UpdateDatasourceNewDocument);
}
export const SubmitUserUploadTaskDocument = gql`
    mutation SubmitUserUploadTask($input: SubmitUserUploadTaskInput!, $measurementProjectId: ID, $userUploadLimit: Int) @withOwner(owner: DataIngestion) {
  submitUserUploadTask(input: $input) {
    userUploadTask {
      ...UserUploadTaskWithDatasource
    }
  }
}
    ${UserUploadTaskWithDatasourceFragmentDoc}`;

export function useSubmitUserUploadTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQSubmitUserUploadTaskMutation,
    GQTypes.GQSubmitUserUploadTaskMutationVariables
  >(SubmitUserUploadTaskDocument);
}
export const SingleFacilityPageDocument = gql`
    query SingleFacilityPage($datasourceId: ID!, $measurementProjectId: ID!, $buildingId: ID!) @withOwner(owner: DataIngestion) {
  userUploadTaskForDatasource(
    datasourceId: $datasourceId
    measurementProjectId: $measurementProjectId
  ) {
    id
    createdAt
    datasource {
      id
      name
      dataset {
        id
        canonicalDataset {
          id
          kind
          customerTargetSchemas {
            id
          }
        }
        ...DatasetForImporter
        instructions {
          ...InstructionsBundleForImporter
        }
      }
    }
    measurementProject {
      id
      name
      coverageInterval
      active
      isApprovalFlowEnabled
    }
    lockState
    facilitiesPreview {
      totalCount
    }
  }
  facility(id: $buildingId, includeDeleted: true) {
    id
    ...SingleFacilityBuildingProperties_facility
  }
  userHasPermission: userHasViewDatasourcePermission(datasourceId: $datasourceId)
  organization {
    id
    ingestionCustomFields {
      ...CustomFieldForBuildingsGridPage
      bartTagName
      ingestionCustomFieldDatasources {
        id
      }
    }
  }
}
    ${DatasetForImporterFragmentDoc}
${InstructionsBundleForImporterFragmentDoc}
${SingleFacilityBuildingProperties_FacilityFragmentDoc}
${CustomFieldForBuildingsGridPageFragmentDoc}`;

export function useSingleFacilityPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQSingleFacilityPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSingleFacilityPageQuery,
    GQTypes.GQSingleFacilityPageQueryVariables
  >({ query: SingleFacilityPageDocument, ...options });
}
export const DatasourceValueMappingPageDocument = gql`
    query DatasourceValueMappingPage($datasourceId: ID, $measurementProjectId: ID!, $valueMappingRuleId: String!) @withOwner(owner: DataIngestion) {
  datasourceValueMappingRule(
    datasourceId: $datasourceId
    measurementProjectId: $measurementProjectId
    valueMappingRuleId: $valueMappingRuleId
  ) {
    id
    ...DatasourceValueMappingRule
  }
  measurementProject(id: $measurementProjectId) {
    id
    datasetsWithStages {
      id
    }
    ...DatasourceValueMappingMeasurementProject
  }
  userHasPermission: userHasViewDatasourcePermission(datasourceId: $datasourceId)
}
    ${DatasourceValueMappingRuleFragmentDoc}
${DatasourceValueMappingMeasurementProjectFragmentDoc}`;

export function useDatasourceValueMappingPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDatasourceValueMappingPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDatasourceValueMappingPageQuery,
    GQTypes.GQDatasourceValueMappingPageQueryVariables
  >({ query: DatasourceValueMappingPageDocument, ...options });
}
export const ActivitySummaryDocument = gql`
    query ActivitySummary($input: BiActivityQueryContextInput!) @withOwner(owner: DataIngestion) {
  activityContextMetadata(input: $input) {
    activityDatasets {
      ...ActivitySummaryActivityDataset
    }
  }
  legacyActivityRecords {
    stableId
  }
}
    ${ActivitySummaryActivityDatasetFragmentDoc}`;

export function useActivitySummaryQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQActivitySummaryQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQActivitySummaryQuery,
    GQTypes.GQActivitySummaryQueryVariables
  >({ query: ActivitySummaryDocument, ...options });
}
export const LegacyActivityDataTablesDocument = gql`
    query LegacyActivityDataTables @withOwner(owner: DataIngestion) {
  legacyActivityRecords {
    ...LegacyActivityRecord
  }
}
    ${LegacyActivityRecordFragmentDoc}`;

export function useLegacyActivityDataTablesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQLegacyActivityDataTablesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQLegacyActivityDataTablesQuery,
    GQTypes.GQLegacyActivityDataTablesQueryVariables
  >({ query: LegacyActivityDataTablesDocument, ...options });
}
export const PrepareLegacyActivityDataExportDocument = gql`
    mutation PrepareLegacyActivityDataExport($input: PrepareActivityRecordExportInput!) @withOwner(owner: DataIngestion) {
  prepareActivityRecordExport(input: $input) {
    backgroundJobId
  }
}
    `;

export function usePrepareLegacyActivityDataExportMutation() {
  return Urql.useMutation<
    GQTypes.GQPrepareLegacyActivityDataExportMutation,
    GQTypes.GQPrepareLegacyActivityDataExportMutationVariables
  >(PrepareLegacyActivityDataExportDocument);
}
export const ReportsWithCsrdDataRequirementsConfigsDocument = gql`
    query ReportsWithCsrdDataRequirementsConfigs @withOwner(owner: WinCsrd) {
  formalReports(reportMode: Corporate) {
    edges {
      node {
        __typename
        id
        name
        reportKind
        updatedAt
        createdAt
        csrdDataRequirementsConfig
      }
    }
  }
}
    `;

export function useReportsWithCsrdDataRequirementsConfigsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportsWithCsrdDataRequirementsConfigsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportsWithCsrdDataRequirementsConfigsQuery,
    GQTypes.GQReportsWithCsrdDataRequirementsConfigsQueryVariables
  >({ query: ReportsWithCsrdDataRequirementsConfigsDocument, ...options });
}
export const CsrdDataRequirementsConfigPageDocument = gql`
    query CsrdDataRequirementsConfigPage @withOwner(owner: WinCsrd) {
  organization {
    id
    csrdDataRequirementsConfigs {
      ...CsrdDataRequirementsConfigFields
    }
  }
}
    ${CsrdDataRequirementsConfigFieldsFragmentDoc}`;

export function useCsrdDataRequirementsConfigPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCsrdDataRequirementsConfigPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCsrdDataRequirementsConfigPageQuery,
    GQTypes.GQCsrdDataRequirementsConfigPageQueryVariables
  >({ query: CsrdDataRequirementsConfigPageDocument, ...options });
}
export const CreateCsrdDataRequirementsConfigDocument = gql`
    mutation CreateCsrdDataRequirementsConfig($input: CreateCsrdDataRequirementsConfigInput!) @withOwner(owner: WinCsrd) {
  createCsrdDataRequirementsConfig(input: $input) {
    payload {
      ...CsrdDataRequirementsConfigFields
    }
  }
}
    ${CsrdDataRequirementsConfigFieldsFragmentDoc}`;

export function useCreateCsrdDataRequirementsConfigMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCsrdDataRequirementsConfigMutation,
    GQTypes.GQCreateCsrdDataRequirementsConfigMutationVariables
  >(CreateCsrdDataRequirementsConfigDocument);
}
export const AppendOnlyUpdateCsrdDataRequirementsConfigDocument = gql`
    mutation AppendOnlyUpdateCsrdDataRequirementsConfig($input: AppendOnlyUpdateCsrdDataRequirementsConfigInput!) @withOwner(owner: WinCsrd) {
  appendOnlyUpdateCsrdDataRequirementsConfig(input: $input) {
    payload {
      ...CsrdDataRequirementsConfigFields
    }
  }
}
    ${CsrdDataRequirementsConfigFieldsFragmentDoc}`;

export function useAppendOnlyUpdateCsrdDataRequirementsConfigMutation() {
  return Urql.useMutation<
    GQTypes.GQAppendOnlyUpdateCsrdDataRequirementsConfigMutation,
    GQTypes.GQAppendOnlyUpdateCsrdDataRequirementsConfigMutationVariables
  >(AppendOnlyUpdateCsrdDataRequirementsConfigDocument);
}
export const CustomFieldsSettingsDocument = gql`
    query CustomFieldsSettings @withOwner(owner: DataIngestion) {
  organization {
    ...OrganizationCustomFieldsForManagePage
  }
  footprintTags {
    id
    tagName
    rulesList
  }
}
    ${OrganizationCustomFieldsForManagePageFragmentDoc}`;

export function useCustomFieldsSettingsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCustomFieldsSettingsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCustomFieldsSettingsQuery,
    GQTypes.GQCustomFieldsSettingsQueryVariables
  >({ query: CustomFieldsSettingsDocument, ...options });
}
export const UserUploadVerificationLogsDocument = gql`
    query UserUploadVerificationLogs($measurementProjectId: ID!) @withOwner(owner: DataIngestion) {
  measurementProject(id: $measurementProjectId) {
    id
    status
    userUploadDataGovernance {
      id
      fileName
      dataset
      datasource
      uploadedAt
      uploadedBy
      userUploadKind
      approvers {
        user {
          id
          name
        }
        approvalStatus
        approvalLastUpdatedAt
      }
    }
  }
}
    `;

export function useUserUploadVerificationLogsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQUserUploadVerificationLogsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQUserUploadVerificationLogsQuery,
    GQTypes.GQUserUploadVerificationLogsQueryVariables
  >({ query: UserUploadVerificationLogsDocument, ...options });
}
export const DataGovernanceDownloadAllUrlDocument = gql`
    query DataGovernanceDownloadAllUrl($measurementProjectId: ID!) @withOwner(owner: DataIngestion) {
  dataGovernanceDownloadAllUrl(measurementProjectId: $measurementProjectId)
}
    `;

export function useDataGovernanceDownloadAllUrlQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDataGovernanceDownloadAllUrlQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDataGovernanceDownloadAllUrlQuery,
    GQTypes.GQDataGovernanceDownloadAllUrlQueryVariables
  >({ query: DataGovernanceDownloadAllUrlDocument, ...options });
}
export const DataGovernancePageDocument = gql`
    query DataGovernancePage @withOwner(owner: DataIngestion) {
  organization {
    id
    measurementProjects {
      ...MeasurementProjectForDataGovernance
    }
  }
}
    ${MeasurementProjectForDataGovernanceFragmentDoc}`;

export function useDataGovernancePageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQDataGovernancePageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDataGovernancePageQuery,
    GQTypes.GQDataGovernancePageQueryVariables
  >({ query: DataGovernancePageDocument, ...options });
}
export const NewMeasurementWizardDocument = gql`
    query NewMeasurementWizard @withOwner(owner: DataIngestion) {
  climateProgramProjects(includeMeasureWhatMatters: true) {
    __typename
    ...ClimateProgramProjectWithMeasurementConcernsFields
  }
  canonicalClimateProgramProjects(isForMeasurement: true) {
    __typename
    ...CanonicalClimateProgramProjectWithMeasurementConcernsFields
  }
  organization {
    id
    fiscalYearStartMonth
    measurementProjects {
      id
      name
    }
  }
  userHasPermission(permission: ManageMeasurement)
  activeMeasurementProjects(limit: 1) {
    id
  }
}
    ${ClimateProgramProjectWithMeasurementConcernsFieldsFragmentDoc}
${CanonicalClimateProgramProjectWithMeasurementConcernsFieldsFragmentDoc}`;

export function useNewMeasurementWizardQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQNewMeasurementWizardQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQNewMeasurementWizardQuery,
    GQTypes.GQNewMeasurementWizardQueryVariables
  >({ query: NewMeasurementWizardDocument, ...options });
}
export const CreateMeasurementProjectFromWizardDocument = gql`
    mutation CreateMeasurementProjectFromWizard($input: CreateMeasurementProjectFromWizardInput!) @withOwner(owner: DataIngestion) {
  createMeasurementProjectFromWizard(input: $input) {
    measurementProject {
      ...MeasurementProjectForMeasurementPage
      ...MeasurementProjectFields
    }
    climateProgramProjects {
      id
      measurementProject {
        id
        name
      }
    }
  }
}
    ${MeasurementProjectForMeasurementPageFragmentDoc}
${MeasurementProjectFieldsFragmentDoc}`;

export function useCreateMeasurementProjectFromWizardMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMeasurementProjectFromWizardMutation,
    GQTypes.GQCreateMeasurementProjectFromWizardMutationVariables
  >(CreateMeasurementProjectFromWizardDocument);
}
export const CreateClimateProgramProjectForMeasurementDocument = gql`
    mutation CreateClimateProgramProjectForMeasurement($input: CreateClimateProgramProjectInput!) @withOwner(owner: DataIngestion) {
  createClimateProgramProject(input: $input) {
    climateProgramProject {
      ...ClimateProgramProjectWithMeasurementConcernsFields
    }
  }
}
    ${ClimateProgramProjectWithMeasurementConcernsFieldsFragmentDoc}`;

export function useCreateClimateProgramProjectForMeasurementMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateClimateProgramProjectForMeasurementMutation,
    GQTypes.GQCreateClimateProgramProjectForMeasurementMutationVariables
  >(CreateClimateProgramProjectForMeasurementDocument);
}
export const MeasurementProjectsPageDocument = gql`
    query MeasurementProjectsPage @withOwner(owner: DataIngestion) {
  userHasManageMeasurement: userHasPermission(permission: ManageMeasurement)
  organization {
    id
    facilitiesMetadata {
      buildingsDataset {
        id
      }
    }
    measurementProjects {
      ...MeasurementProjectsPageProject
    }
  }
}
    ${MeasurementProjectsPageProjectFragmentDoc}`;

export function useMeasurementProjectsPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQMeasurementProjectsPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMeasurementProjectsPageQuery,
    GQTypes.GQMeasurementProjectsPageQueryVariables
  >({ query: MeasurementProjectsPageDocument, ...options });
}
export const SharedFilesPageDocument = gql`
    query SharedFilesPage($currentUserId: ID!) @withOwner(owner: DataIngestion) {
  organization {
    id
    measurementProjects {
      id
      name
      sharedFiles(forUserId: $currentUserId) {
        edges {
          node {
            ...SharedFileForDashboardPage
          }
        }
      }
      status
      completedAt
      coverageEndDate
      deadline
    }
  }
}
    ${SharedFileForDashboardPageFragmentDoc}`;

export function useSharedFilesPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQSharedFilesPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSharedFilesPageQuery,
    GQTypes.GQSharedFilesPageQueryVariables
  >({ query: SharedFilesPageDocument, ...options });
}
export const GetMeasurementProjectsDataDocument = gql`
    query GetMeasurementProjectsData @withOwner(owner: DataIngestion) {
  organization {
    id
    measurementProjects {
      id
      name
      active
      status
      completedAt
      coverageInterval
      coverageStartDate
      coverageEndDate
      deadline
    }
  }
}
    `;

export function useGetMeasurementProjectsDataQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetMeasurementProjectsDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetMeasurementProjectsDataQuery,
    GQTypes.GQGetMeasurementProjectsDataQueryVariables
  >({ query: GetMeasurementProjectsDataDocument, ...options });
}
export const LatestOrgStructureVersionDocument = gql`
    query LatestOrgStructureVersion @withOwner(owner: EnterpriseFoundations) {
  latestOrgStructureVersion {
    ...OrgStructureVersionWithTypesAndUnits
  }
}
    ${OrgStructureVersionWithTypesAndUnitsFragmentDoc}`;

export function useLatestOrgStructureVersionQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQLatestOrgStructureVersionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQLatestOrgStructureVersionQuery,
    GQTypes.GQLatestOrgStructureVersionQueryVariables
  >({ query: LatestOrgStructureVersionDocument, ...options });
}
export const OrgStructureVersionsDocument = gql`
    query OrgStructureVersions($versionId: ID) @withOwner(owner: EnterpriseFoundations) {
  orgStructureVersions(versionId: $versionId) {
    ...OrgStructureVersion
  }
}
    ${OrgStructureVersionFragmentDoc}`;

export function useOrgStructureVersionsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgStructureVersionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgStructureVersionsQuery,
    GQTypes.GQOrgStructureVersionsQueryVariables
  >({ query: OrgStructureVersionsDocument, ...options });
}
export const OrgUnitTypesDocument = gql`
    query OrgUnitTypes($versionId: ID!) @withOwner(owner: EnterpriseFoundations) {
  orgUnitTypes(versionId: $versionId) {
    ...OrgUnitType
  }
}
    ${OrgUnitTypeFragmentDoc}`;

export function useOrgUnitTypesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgUnitTypesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgUnitTypesQuery,
    GQTypes.GQOrgUnitTypesQueryVariables
  >({ query: OrgUnitTypesDocument, ...options });
}
export const OrgUnitTypeDocument = gql`
    query OrgUnitType($versionId: ID!, $orgUnitTypeId: ID!) @withOwner(owner: EnterpriseFoundations) {
  orgUnitTypes(versionId: $versionId, orgUnitTypeId: $orgUnitTypeId) {
    ...OrgUnitType
  }
}
    ${OrgUnitTypeFragmentDoc}`;

export function useOrgUnitTypeQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQOrgUnitTypeQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQOrgUnitTypeQuery,
    GQTypes.GQOrgUnitTypeQueryVariables
  >({ query: OrgUnitTypeDocument, ...options });
}
export const OrgUnitGridPageDocument = gql`
    query OrgUnitGridPage($versionId: ID!, $orgUnitTypeId: ID!) @withOwner(owner: EnterpriseFoundations) {
  orgUnits(versionId: $versionId, orgUnitTypeId: $orgUnitTypeId) @_required {
    ...OrgUnit
  }
  orgUnitTypes(versionId: $versionId) @_required {
    ...OrgUnitTypeForOrgUnitGrid
  }
}
    ${OrgUnitFragmentDoc}
${OrgUnitTypeForOrgUnitGridFragmentDoc}`;

export function useOrgUnitGridPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgUnitGridPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgUnitGridPageQuery,
    GQTypes.GQOrgUnitGridPageQueryVariables
  >({ query: OrgUnitGridPageDocument, ...options });
}
export const OrganizationForOrgUnitTypePageDocument = gql`
    query OrganizationForOrgUnitTypePage @withOwner(owner: EnterpriseFoundations) {
  organization {
    ...OrganizationForOrgUnitTypePage
  }
}
    ${OrganizationForOrgUnitTypePageFragmentDoc}`;

export function useOrganizationForOrgUnitTypePageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationForOrgUnitTypePageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationForOrgUnitTypePageQuery,
    GQTypes.GQOrganizationForOrgUnitTypePageQueryVariables
  >({ query: OrganizationForOrgUnitTypePageDocument, ...options });
}
export const GetOrgStructureVersionUploadDiffDocument = gql`
    query GetOrgStructureVersionUploadDiff($orgStructureVersionUploadId: ID!) @withOwner(owner: EnterpriseFoundations) {
  orgStructureVersionUpload(
    orgStructureVersionUploadId: $orgStructureVersionUploadId
  ) {
    id
    isInvalid
    diff
  }
}
    `;

export function useGetOrgStructureVersionUploadDiffQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetOrgStructureVersionUploadDiffQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetOrgStructureVersionUploadDiffQuery,
    GQTypes.GQGetOrgStructureVersionUploadDiffQueryVariables
  >({ query: GetOrgStructureVersionUploadDiffDocument, ...options });
}
export const UpsertOrgStructureVersionDocument = gql`
    mutation UpsertOrgStructureVersion($input: UpsertOrgStructureVersionInput!) @withOwner(owner: EnterpriseFoundations) {
  upsertOrgStructureVersion(input: $input) {
    orgStructureVersion {
      ...OrgStructureVersion
    }
  }
}
    ${OrgStructureVersionFragmentDoc}`;

export function useUpsertOrgStructureVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQUpsertOrgStructureVersionMutation,
    GQTypes.GQUpsertOrgStructureVersionMutationVariables
  >(UpsertOrgStructureVersionDocument);
}
export const PublishOrgStructureVersionDocument = gql`
    mutation PublishOrgStructureVersion($input: PublishOrgStructureVersionInput!) @withOwner(owner: EnterpriseFoundations) {
  publishOrgStructureVersion(input: $input) {
    orgStructureVersion {
      ...OrgStructureVersion
    }
  }
}
    ${OrgStructureVersionFragmentDoc}`;

export function usePublishOrgStructureVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQPublishOrgStructureVersionMutation,
    GQTypes.GQPublishOrgStructureVersionMutationVariables
  >(PublishOrgStructureVersionDocument);
}
export const UnpublishOrgStructureVersionDocument = gql`
    mutation UnpublishOrgStructureVersion($input: UnpublishOrgStructureVersionInput!) @withOwner(owner: EnterpriseFoundations) {
  unpublishOrgStructureVersion(input: $input) {
    orgStructureVersion {
      ...OrgStructureVersion
    }
  }
}
    ${OrgStructureVersionFragmentDoc}`;

export function useUnpublishOrgStructureVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQUnpublishOrgStructureVersionMutation,
    GQTypes.GQUnpublishOrgStructureVersionMutationVariables
  >(UnpublishOrgStructureVersionDocument);
}
export const DeleteOrgStructureVersionDocument = gql`
    mutation DeleteOrgStructureVersion($input: DeleteOrgStructureVersionInput!) @withOwner(owner: EnterpriseFoundations) {
  deleteOrgStructureVersion(input: $input) {
    orgStructureVersion {
      ...OrgStructureVersion
    }
  }
}
    ${OrgStructureVersionFragmentDoc}`;

export function useDeleteOrgStructureVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteOrgStructureVersionMutation,
    GQTypes.GQDeleteOrgStructureVersionMutationVariables
  >(DeleteOrgStructureVersionDocument);
}
export const DuplicateOrgStructureVersionDocument = gql`
    mutation DuplicateOrgStructureVersion($input: DuplicateOrgStructureVersionInput!) @withOwner(owner: EnterpriseFoundations) {
  duplicateOrgStructureVersion(input: $input) {
    orgStructureVersion {
      ...OrgStructureVersion
    }
  }
}
    ${OrgStructureVersionFragmentDoc}`;

export function useDuplicateOrgStructureVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQDuplicateOrgStructureVersionMutation,
    GQTypes.GQDuplicateOrgStructureVersionMutationVariables
  >(DuplicateOrgStructureVersionDocument);
}
export const CreateOrgUnitTypeDocument = gql`
    mutation CreateOrgUnitType($input: CreateOrgUnitTypeInput!) @withOwner(owner: EnterpriseFoundations) {
  createOrgUnitType(input: $input) {
    orgUnitType {
      ...OrgUnitType
    }
  }
}
    ${OrgUnitTypeFragmentDoc}`;

export function useCreateOrgUnitTypeMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateOrgUnitTypeMutation,
    GQTypes.GQCreateOrgUnitTypeMutationVariables
  >(CreateOrgUnitTypeDocument);
}
export const UpdateOrgUnitTypeDocument = gql`
    mutation UpdateOrgUnitType($input: UpdateOrgUnitTypeInput!) @withOwner(owner: EnterpriseFoundations) {
  updateOrgUnitType(input: $input) {
    orgUnitType {
      ...OrgUnitType
    }
  }
}
    ${OrgUnitTypeFragmentDoc}`;

export function useUpdateOrgUnitTypeMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateOrgUnitTypeMutation,
    GQTypes.GQUpdateOrgUnitTypeMutationVariables
  >(UpdateOrgUnitTypeDocument);
}
export const DeleteOrgUnitTypeDocument = gql`
    mutation DeleteOrgUnitType($input: DeleteOrgUnitTypeInput!) @withOwner(owner: EnterpriseFoundations) {
  deleteOrgUnitType(input: $input) {
    orgUnitType {
      ...OrgUnitType
    }
  }
}
    ${OrgUnitTypeFragmentDoc}`;

export function useDeleteOrgUnitTypeMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteOrgUnitTypeMutation,
    GQTypes.GQDeleteOrgUnitTypeMutationVariables
  >(DeleteOrgUnitTypeDocument);
}
export const CreateOrgUnitDocument = gql`
    mutation CreateOrgUnit($input: CreateOrgUnitInput!) @withOwner(owner: EnterpriseFoundations) {
  createOrgUnit(input: $input) {
    orgUnit {
      ...OrgUnit
    }
  }
}
    ${OrgUnitFragmentDoc}`;

export function useCreateOrgUnitMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateOrgUnitMutation,
    GQTypes.GQCreateOrgUnitMutationVariables
  >(CreateOrgUnitDocument);
}
export const UpdateOrgUnitsDocument = gql`
    mutation UpdateOrgUnits($input: UpdateOrgUnitsInput!) @withOwner(owner: EnterpriseFoundations) {
  updateOrgUnits(input: $input) {
    orgUnits {
      ...OrgUnit
    }
  }
}
    ${OrgUnitFragmentDoc}`;

export function useUpdateOrgUnitsMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateOrgUnitsMutation,
    GQTypes.GQUpdateOrgUnitsMutationVariables
  >(UpdateOrgUnitsDocument);
}
export const DeleteOrgUnitsDocument = gql`
    mutation DeleteOrgUnits($input: DeleteOrgUnitsInput!) @withOwner(owner: EnterpriseFoundations) {
  deleteOrgUnits(input: $input) {
    orgUnits {
      ...OrgUnit
    }
  }
}
    ${OrgUnitFragmentDoc}`;

export function useDeleteOrgUnitsMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteOrgUnitsMutation,
    GQTypes.GQDeleteOrgUnitsMutationVariables
  >(DeleteOrgUnitsDocument);
}
export const CreateOrgUnitTypeRelationshipDocument = gql`
    mutation CreateOrgUnitTypeRelationship($input: CreateOrgUnitTypeRelationshipInput!) @withOwner(owner: EnterpriseFoundations) {
  createOrgUnitTypeRelationship(input: $input) {
    orgUnitTypeRelationship {
      ...OrgUnitTypeRelationship
    }
    orgUnits {
      ...OrgUnit
    }
  }
}
    ${OrgUnitTypeRelationshipFragmentDoc}
${OrgUnitFragmentDoc}`;

export function useCreateOrgUnitTypeRelationshipMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateOrgUnitTypeRelationshipMutation,
    GQTypes.GQCreateOrgUnitTypeRelationshipMutationVariables
  >(CreateOrgUnitTypeRelationshipDocument);
}
export const UpdateOrgUnitTypeRelationshipDocument = gql`
    mutation UpdateOrgUnitTypeRelationship($input: UpdateOrgUnitTypeRelationshipInput!) @withOwner(owner: EnterpriseFoundations) {
  updateOrgUnitTypeRelationship(input: $input) {
    orgUnitTypeRelationship {
      ...OrgUnitTypeRelationship
    }
    orgUnits {
      ...OrgUnit
    }
  }
}
    ${OrgUnitTypeRelationshipFragmentDoc}
${OrgUnitFragmentDoc}`;

export function useUpdateOrgUnitTypeRelationshipMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateOrgUnitTypeRelationshipMutation,
    GQTypes.GQUpdateOrgUnitTypeRelationshipMutationVariables
  >(UpdateOrgUnitTypeRelationshipDocument);
}
export const DeleteOrgUnitTypeRelationshipDocument = gql`
    mutation DeleteOrgUnitTypeRelationship($input: DeleteOrgUnitTypeRelationshipInput!) @withOwner(owner: EnterpriseFoundations) {
  deleteOrgUnitTypeRelationship(input: $input) {
    orgUnitTypeRelationship {
      ...OrgUnitTypeRelationship
    }
  }
}
    ${OrgUnitTypeRelationshipFragmentDoc}`;

export function useDeleteOrgUnitTypeRelationshipMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteOrgUnitTypeRelationshipMutation,
    GQTypes.GQDeleteOrgUnitTypeRelationshipMutationVariables
  >(DeleteOrgUnitTypeRelationshipDocument);
}
export const CreateOrgStructureVersionUploadDocument = gql`
    mutation CreateOrgStructureVersionUpload($input: CreateOrgStructureVersionUploadInput!) @withOwner(owner: EnterpriseFoundations) {
  createOrgStructureVersionUpload(input: $input) {
    orgStructureVersionUpload {
      id
    }
    backgroundJobId
  }
}
    `;

export function useCreateOrgStructureVersionUploadMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateOrgStructureVersionUploadMutation,
    GQTypes.GQCreateOrgStructureVersionUploadMutationVariables
  >(CreateOrgStructureVersionUploadDocument);
}
export const CreateOrgStructureImportTemplateDocument = gql`
    mutation CreateOrgStructureImportTemplate($input: CreateOrgStructureImportTemplateInput!) @withOwner(owner: EnterpriseFoundations) {
  createOrgStructureImportTemplate(input: $input) {
    oneSchemaTemplateKey
  }
}
    `;

export function useCreateOrgStructureImportTemplateMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateOrgStructureImportTemplateMutation,
    GQTypes.GQCreateOrgStructureImportTemplateMutationVariables
  >(CreateOrgStructureImportTemplateDocument);
}
export const AcceptOrgStructureVersionUploadDocument = gql`
    mutation AcceptOrgStructureVersionUpload($input: AcceptOrgStructureVersionUploadInput!) @withOwner(owner: EnterpriseFoundations) {
  acceptOrgStructureVersionUpload(input: $input) {
    orgStructureVersionUpload {
      id
      uploadStats
    }
  }
}
    `;

export function useAcceptOrgStructureVersionUploadMutation() {
  return Urql.useMutation<
    GQTypes.GQAcceptOrgStructureVersionUploadMutation,
    GQTypes.GQAcceptOrgStructureVersionUploadMutationVariables
  >(AcceptOrgStructureVersionUploadDocument);
}
export const GetMaterialVariantDetailsDocument = gql`
    query GetMaterialVariantDetails($id: ID!) @withOwner(owner: SupplyChain) {
  materialVariant(id: $id) @_required {
    ...MaterialVariantDetailsFields
  }
}
    ${MaterialVariantDetailsFieldsFragmentDoc}`;

export function useGetMaterialVariantDetailsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetMaterialVariantDetailsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetMaterialVariantDetailsQuery,
    GQTypes.GQGetMaterialVariantDetailsQueryVariables
  >({ query: GetMaterialVariantDetailsDocument, ...options });
}
export const ForecastsPageDocument = gql`
    query ForecastsPage @withOwner(owner: Reductions) {
  forecast {
    ...ForecastFields
  }
}
    ${ForecastFieldsFragmentDoc}`;

export function useForecastsPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQForecastsPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQForecastsPageQuery,
    GQTypes.GQForecastsPageQueryVariables
  >({ query: ForecastsPageDocument, ...options });
}
export const UpdateForecastDocument = gql`
    mutation UpdateForecast($input: UpdateForecastInput!) @withOwner(owner: Reductions) {
  updateForecast(input: $input) {
    forecast {
      ...ForecastFields
    }
    plans {
      ...PlanWithForecastFields
    }
  }
}
    ${ForecastFieldsFragmentDoc}
${PlanWithForecastFieldsFragmentDoc}`;

export function useUpdateForecastMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateForecastMutation,
    GQTypes.GQUpdateForecastMutationVariables
  >(UpdateForecastDocument);
}
export const GetFirstPlanDocument = gql`
    query GetFirstPlan @withOwner(owner: Reductions) {
  plansWithDefaults {
    edges {
      node {
        ...PlanForPlanTab
      }
    }
  }
}
    ${PlanForPlanTabFragmentDoc}`;

export function useGetFirstPlanQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFirstPlanQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFirstPlanQuery,
    GQTypes.GQGetFirstPlanQueryVariables
  >({ query: GetFirstPlanDocument, ...options });
}
export const GetForecastWithoutLazilyCreatingDocument = gql`
    query GetForecastWithoutLazilyCreating @withOwner(owner: Reductions) {
  forecast(shouldLazilyCreate: false) {
    id
  }
}
    `;

export function useGetForecastWithoutLazilyCreatingQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetForecastWithoutLazilyCreatingQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetForecastWithoutLazilyCreatingQuery,
    GQTypes.GQGetForecastWithoutLazilyCreatingQueryVariables
  >({ query: GetForecastWithoutLazilyCreatingDocument, ...options });
}
export const CreateForecastDocument = gql`
    mutation CreateForecast($input: CreateForecastInput!) @withOwner(owner: Reductions) {
  createForecast(input: $input) {
    forecast {
      id
    }
  }
}
    `;

export function useCreateForecastMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateForecastMutation,
    GQTypes.GQCreateForecastMutationVariables
  >(CreateForecastDocument);
}
export const ReductionsMetricsPageDocument = gql`
    query ReductionsMetricsPage @withOwner(owner: Reductions) {
  forecast {
    ...ReductionsMetricsPage_forecast
    id
  }
}
    ${ReductionsMetricsPage_ForecastFragmentDoc}`;

export function useReductionsMetricsPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQReductionsMetricsPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReductionsMetricsPageQuery,
    GQTypes.GQReductionsMetricsPageQueryVariables
  >({ query: ReductionsMetricsPageDocument, ...options });
}
export const ReductionsMetricsPage_UpdateForecastDocument = gql`
    mutation ReductionsMetricsPage_updateForecast($input: UpdateForecastInput!) @withOwner(owner: Reductions) {
  updateForecast(input: $input) {
    forecast {
      ...ReductionsMetricsPage_forecast
      id
    }
  }
}
    ${ReductionsMetricsPage_ForecastFragmentDoc}`;

export function useReductionsMetricsPage_UpdateForecastMutation() {
  return Urql.useMutation<
    GQTypes.GQReductionsMetricsPage_UpdateForecastMutation,
    GQTypes.GQReductionsMetricsPage_UpdateForecastMutationVariables
  >(ReductionsMetricsPage_UpdateForecastDocument);
}
export const PlanForPlanSettingsPageDocument = gql`
    query PlanForPlanSettingsPage($planId: ID!) @withOwner(owner: Reductions) {
  plan(id: $planId) {
    ...PlanForPlanSettings
  }
}
    ${PlanForPlanSettingsFragmentDoc}`;

export function usePlanForPlanSettingsPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQPlanForPlanSettingsPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPlanForPlanSettingsPageQuery,
    GQTypes.GQPlanForPlanSettingsPageQueryVariables
  >({ query: PlanForPlanSettingsPageDocument, ...options });
}
export const UpdatePlanSettingsDocument = gql`
    mutation UpdatePlanSettings($input: UpdatePlanSettingsInput!) @withOwner(owner: Reductions) {
  updatePlanSettings(input: $input) {
    plan {
      ...PlanWithForecastFields
    }
  }
}
    ${PlanWithForecastFieldsFragmentDoc}`;

export function useUpdatePlanSettingsMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdatePlanSettingsMutation,
    GQTypes.GQUpdatePlanSettingsMutationVariables
  >(UpdatePlanSettingsDocument);
}
export const ReportQuestionPageDocument = gql`
    query ReportQuestionPage($input: GetReportObjectConfigPathInput!) @withOwner(owner: Reporting) {
  getReportObjectConfigPath(input: $input) {
    path {
      id
      label
      isInput
      isComputed
    }
  }
}
    `;

export function useReportQuestionPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportQuestionPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportQuestionPageQuery,
    GQTypes.GQReportQuestionPageQueryVariables
  >({ query: ReportQuestionPageDocument, ...options });
}
export const PeerCompanyReportsDocument = gql`
    query PeerCompanyReports @withOwner(owner: Reporting) {
  peerCompanyGroups {
    ...PeerCompanyReports
  }
}
    ${PeerCompanyReportsFragmentDoc}`;

export function usePeerCompanyReportsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQPeerCompanyReportsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPeerCompanyReportsQuery,
    GQTypes.GQPeerCompanyReportsQueryVariables
  >({ query: PeerCompanyReportsDocument, ...options });
}
export const PeerReportsPageDocument = gql`
    query PeerReportsPage($reportTypes: [ExternalReportType!], $publishingYears: [Int!], $companyIds: [ID!], $filterLicensed: Boolean, $after: String, $before: String, $first: Int, $last: Int) @withOwner(owner: Reporting) {
  publicDisclosuresPaginated(
    reportTypes: $reportTypes
    publishingYears: $publishingYears
    companyIds: $companyIds
    filterLicensed: $filterLicensed
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    edges {
      node {
        ...PublicDisclosureDisplay
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
  uniqueExternalReportFilterValues {
    reportTypes
    publishingYears
  }
}
    ${PublicDisclosureDisplayFragmentDoc}`;

export function usePeerReportsPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQPeerReportsPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPeerReportsPageQuery,
    GQTypes.GQPeerReportsPageQueryVariables
  >({ query: PeerReportsPageDocument, ...options });
}
export const WatershedRecommendedPeerReportsDocument = gql`
    query WatershedRecommendedPeerReports($after: String, $before: String, $first: Int, $last: Int) @withOwner(owner: Reporting) {
  recommendedPublicDisclosures(
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    edges {
      node {
        ...PublicDisclosureDisplay
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${PublicDisclosureDisplayFragmentDoc}`;

export function useWatershedRecommendedPeerReportsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQWatershedRecommendedPeerReportsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQWatershedRecommendedPeerReportsQuery,
    GQTypes.GQWatershedRecommendedPeerReportsQueryVariables
  >({ query: WatershedRecommendedPeerReportsDocument, ...options });
}
export const CompanyFuzzyMatchForPublicDisclosureWithEfsDocument = gql`
    query CompanyFuzzyMatchForPublicDisclosureWithEfs($q: String!, $filterByPublicDisclosures: Boolean!) @withOwner(owner: Reporting) {
  companyFuzzyMatchWithEfs(
    q: $q
    filterByPublicDisclosures: $filterByPublicDisclosures
  ) {
    results {
      id
      name
      disclosuresV2 {
        id
        publicDisclosure {
          id
        }
      }
    }
  }
}
    `;

export function useCompanyFuzzyMatchForPublicDisclosureWithEfsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCompanyFuzzyMatchForPublicDisclosureWithEfsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCompanyFuzzyMatchForPublicDisclosureWithEfsQuery,
    GQTypes.GQCompanyFuzzyMatchForPublicDisclosureWithEfsQueryVariables
  >({ query: CompanyFuzzyMatchForPublicDisclosureWithEfsDocument, ...options });
}
export const GetSsoConfigPageDocument = gql`
    query GetSsoConfigPage @withOwner(owner: EnterpriseFoundations) {
  userHasPermission(permission: ManageSingleSignOn)
  organization {
    id
    workosOrgId
    users(filters: {isAdmin: true}) {
      edges {
        node {
          id
          name
          email
        }
      }
    }
    hasActiveWorkosConnection
  }
}
    `;

export function useGetSsoConfigPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSsoConfigPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSsoConfigPageQuery,
    GQTypes.GQGetSsoConfigPageQueryVariables
  >({ query: GetSsoConfigPageDocument, ...options });
}
export const GetAllAssignableUsersWithPermissionBatchDocument = gql`
    query GetAllAssignableUsersWithPermissionBatch($permissions: [HasPermissionInput!]!, $withAllPermissions: [HasPermissionInput!], $orgId: ID!) @withOwner(owner: EnterpriseFoundations) {
  organization {
    id
    usersWithPermissions: users(
      filters: {withSomePermissions: $permissions, withAllPermissions: $withAllPermissions, includeWatershedEmployees: true}
    ) {
      edges {
        node {
          id
        }
      }
    }
    users {
      edges {
        node {
          ...AssignableUserFields
          orgAccessId(orgId: $orgId)
        }
      }
    }
  }
}
    ${AssignableUserFieldsFragmentDoc}`;

export function useGetAllAssignableUsersWithPermissionBatchQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetAllAssignableUsersWithPermissionBatchQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetAllAssignableUsersWithPermissionBatchQuery,
    GQTypes.GQGetAllAssignableUsersWithPermissionBatchQueryVariables
  >({ query: GetAllAssignableUsersWithPermissionBatchDocument, ...options });
}
export const GetAllAssignableUsersWithPermissionDocument = gql`
    query GetAllAssignableUsersWithPermission($permissions: [HasPermissionInput!]!, $orgId: ID!) @withOwner(owner: EnterpriseFoundations) {
  organization {
    id
    users {
      edges {
        node {
          hasPermissions(permissions: $permissions, orgId: $orgId)
          hasAnyPermissions(permissions: $permissions, orgId: $orgId)
          ...AssignableUserFields
          orgAccessId(orgId: $orgId)
        }
      }
    }
  }
}
    ${AssignableUserFieldsFragmentDoc}`;

export function useGetAllAssignableUsersWithPermissionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetAllAssignableUsersWithPermissionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetAllAssignableUsersWithPermissionQuery,
    GQTypes.GQGetAllAssignableUsersWithPermissionQueryVariables
  >({ query: GetAllAssignableUsersWithPermissionDocument, ...options });
}
export const ActiveOrganizationDocument = gql`
    query ActiveOrganization @withOwner(owner: EnterpriseFoundations) {
  activeOrganization {
    ...ActiveOrganizationFields
  }
  flags {
    ...FlagsInitFields
  }
  preferredLocale
}
    ${ActiveOrganizationFieldsFragmentDoc}
${FlagsInitFieldsFragmentDoc}`;

export function useActiveOrganizationQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQActiveOrganizationQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQActiveOrganizationQuery,
    GQTypes.GQActiveOrganizationQueryVariables
  >({ query: ActiveOrganizationDocument, ...options });
}
export const ForceRedirectDocument = gql`
    query ForceRedirect($pathname: String) @withOwner(owner: EnterpriseFoundations) {
  forceRedirect(pathname: $pathname)
}
    `;

export function useForceRedirectQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQForceRedirectQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQForceRedirectQuery,
    GQTypes.GQForceRedirectQueryVariables
  >({ query: ForceRedirectDocument, ...options });
}
export const CheckUserAuthenticationDocument = gql`
    query CheckUserAuthentication @withOwner(owner: EnterpriseFoundations) {
  activeOrganization {
    id
  }
}
    `;

export function useCheckUserAuthenticationQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCheckUserAuthenticationQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCheckUserAuthenticationQuery,
    GQTypes.GQCheckUserAuthenticationQueryVariables
  >({ query: CheckUserAuthenticationDocument, ...options });
}
export const ThrowErrorDocument = gql`
    mutation ThrowError @withOwner(owner: EnterpriseFoundations) {
  throwError(input: {fooString: "abcd", password: "1234"}) {
    message
  }
}
    `;

export function useThrowErrorMutation() {
  return Urql.useMutation<
    GQTypes.GQThrowErrorMutation,
    GQTypes.GQThrowErrorMutationVariables
  >(ThrowErrorDocument);
}
export const CompleteOnboardingDocument = gql`
    mutation CompleteOnboarding($userId: ID!, $kind: OnboardingKind!) @withOwner(owner: EnterpriseFoundations) {
  completeOnboarding(input: {userId: $userId, kind: $kind}) {
    user {
      id
    }
    userOnboardingsCompleted
  }
}
    `;

export function useCompleteOnboardingMutation() {
  return Urql.useMutation<
    GQTypes.GQCompleteOnboardingMutation,
    GQTypes.GQCompleteOnboardingMutationVariables
  >(CompleteOnboardingDocument);
}
export const FundListDocument = gql`
    query FundList {
  funds {
    __typename
    id
  }
}
    `;

export function useFundListQuery(
  options?: Omit<Urql.UseQueryArgs<GQTypes.GQFundListQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQFundListQuery,
    GQTypes.GQFundListQueryVariables
  >({ query: FundListDocument, ...options });
}
export const AssetCorporateListDocument = gql`
    query AssetCorporateList {
  assetsCorporate {
    id
  }
}
    `;

export function useAssetCorporateListQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQAssetCorporateListQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAssetCorporateListQuery,
    GQTypes.GQAssetCorporateListQueryVariables
  >({ query: AssetCorporateListDocument, ...options });
}
export const AssetRealEstateListDocument = gql`
    query AssetRealEstateList {
  assetsRealEstate {
    id
  }
}
    `;

export function useAssetRealEstateListQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQAssetRealEstateListQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAssetRealEstateListQuery,
    GQTypes.GQAssetRealEstateListQueryVariables
  >({ query: AssetRealEstateListDocument, ...options });
}
export const AssetGroupListDocument = gql`
    query AssetGroupList {
  assetGroups {
    id
  }
}
    `;

export function useAssetGroupListQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQAssetGroupListQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAssetGroupListQuery,
    GQTypes.GQAssetGroupListQueryVariables
  >({ query: AssetGroupListDocument, ...options });
}
export const AssetSovereignBondListDocument = gql`
    query AssetSovereignBondList {
  assetSovereignBonds {
    id
  }
}
    `;

export function useAssetSovereignBondListQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQAssetSovereignBondListQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAssetSovereignBondListQuery,
    GQTypes.GQAssetSovereignBondListQueryVariables
  >({ query: AssetSovereignBondListDocument, ...options });
}
export const AssetPersonalMotorVehicleInsuranceListDocument = gql`
    query AssetPersonalMotorVehicleInsuranceList {
  assetPersonalMotorVehicleInsurances {
    id
  }
}
    `;

export function useAssetPersonalMotorVehicleInsuranceListQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQAssetPersonalMotorVehicleInsuranceListQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAssetPersonalMotorVehicleInsuranceListQuery,
    GQTypes.GQAssetPersonalMotorVehicleInsuranceListQueryVariables
  >({ query: AssetPersonalMotorVehicleInsuranceListDocument, ...options });
}
export const FinanceTagListDocument = gql`
    query FinanceTagList @withOwner(owner: WinFinance) {
  financeTags {
    id
  }
}
    `;

export function useFinanceTagListQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceTagListQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceTagListQuery,
    GQTypes.GQFinanceTagListQueryVariables
  >({ query: FinanceTagListDocument, ...options });
}
export const AssetHoldingListDocument = gql`
    query AssetHoldingList @withOwner(owner: WinFinance) {
  assetHoldings {
    id
  }
}
    `;

export function useAssetHoldingListQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQAssetHoldingListQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAssetHoldingListQuery,
    GQTypes.GQAssetHoldingListQueryVariables
  >({ query: AssetHoldingListDocument, ...options });
}
export const OrganizationForCacheUpdateDocument = gql`
    query OrganizationForCacheUpdate {
  organization {
    id
    orgCompanies {
      id
      name
    }
  }
}
    `;

export function useOrganizationForCacheUpdateQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationForCacheUpdateQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationForCacheUpdateQuery,
    GQTypes.GQOrganizationForCacheUpdateQueryVariables
  >({ query: OrganizationForCacheUpdateDocument, ...options });
}
export const OrganizationForDeleteOrgCompanyCacheUpdateDocument = gql`
    query OrganizationForDeleteOrgCompanyCacheUpdate {
  organization {
    id
    orgCompanies {
      id
      name
    }
  }
}
    `;

export function useOrganizationForDeleteOrgCompanyCacheUpdateQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationForDeleteOrgCompanyCacheUpdateQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationForDeleteOrgCompanyCacheUpdateQuery,
    GQTypes.GQOrganizationForDeleteOrgCompanyCacheUpdateQueryVariables
  >({ query: OrganizationForDeleteOrgCompanyCacheUpdateDocument, ...options });
}
export const FeatureFlagsForCacheDocument = gql`
    query FeatureFlagsForCache @withOwner(owner: EnterpriseFoundations) {
  flags {
    id
    name
    activated
  }
}
    `;

export function useFeatureFlagsForCacheQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFeatureFlagsForCacheQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFeatureFlagsForCacheQuery,
    GQTypes.GQFeatureFlagsForCacheQueryVariables
  >({ query: FeatureFlagsForCacheDocument, ...options });
}
export const GetEngagementTaskDocument = gql`
    query GetEngagementTask($engagementTaskId: ID!) @withOwner(owner: SupplyChain) {
  engagementTask(engagementTaskId: $engagementTaskId) {
    ...EngagementTaskContents
  }
}
    ${EngagementTaskContentsFragmentDoc}`;

export function useGetEngagementTaskQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEngagementTaskQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEngagementTaskQuery,
    GQTypes.GQGetEngagementTaskQueryVariables
  >({ query: GetEngagementTaskDocument, ...options });
}
export const UpdateEngagementTaskDocument = gql`
    mutation updateEngagementTask($input: UpdateEngagementTaskInput!, $supplierId: ID!) @withOwner(owner: SupplyChain) {
  updateEngagementTask(input: $input, supplierId: $supplierId) {
    engagementTask {
      ...EngagementTaskFields
      ...EngagementTaskContents
    }
    runCeeBackgroundJobId
  }
}
    ${EngagementTaskFieldsFragmentDoc}
${EngagementTaskContentsFragmentDoc}`;

export function useUpdateEngagementTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateEngagementTaskMutation,
    GQTypes.GQUpdateEngagementTaskMutationVariables
  >(UpdateEngagementTaskDocument);
}
export const AddEngagementTaskCommentDocument = gql`
    mutation AddEngagementTaskComment($input: AddEngagementTaskCommentInput!, $engagementTaskId: ID!) @withOwner(owner: SupplyChain) {
  addEngagementTaskComment(input: $input, engagementTaskId: $engagementTaskId) {
    comment {
      id
      createdAt
      userEditorId
      comment
    }
  }
}
    `;

export function useAddEngagementTaskCommentMutation() {
  return Urql.useMutation<
    GQTypes.GQAddEngagementTaskCommentMutation,
    GQTypes.GQAddEngagementTaskCommentMutationVariables
  >(AddEngagementTaskCommentDocument);
}
export const DemoPlatformInputsDocument = gql`
    query DemoPlatformInputs @withOwner(owner: EnterpriseFoundations) {
  organization {
    id
    measurementProjects {
      ...MeasurementProjectForDemoPlatform
    }
  }
}
    ${MeasurementProjectForDemoPlatformFragmentDoc}`;

export function useDemoPlatformInputsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQDemoPlatformInputsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDemoPlatformInputsQuery,
    GQTypes.GQDemoPlatformInputsQueryVariables
  >({ query: DemoPlatformInputsDocument, ...options });
}
export const DemoPlatformRunPageDocument = gql`
    query DemoPlatformRunPage($id: ID!) @withOwner(owner: EnterpriseFoundations) {
  demoPlatformRun(id: $id) {
    id
    ...DemoPlatformRunForPage
  }
}
    ${DemoPlatformRunForPageFragmentDoc}`;

export function useDemoPlatformRunPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDemoPlatformRunPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDemoPlatformRunPageQuery,
    GQTypes.GQDemoPlatformRunPageQueryVariables
  >({ query: DemoPlatformRunPageDocument, ...options });
}
export const DemoPlatformRunGridDocument = gql`
    query DemoPlatformRunGrid($after: String, $before: String, $first: Int, $last: Int) @withOwner(owner: EnterpriseFoundations) {
  demoPlatformRuns(after: $after, before: $before, first: $first, last: $last) {
    edges {
      node {
        ...DemoPlatformRunForGrid
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${DemoPlatformRunForGridFragmentDoc}`;

export function useDemoPlatformRunGridQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQDemoPlatformRunGridQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDemoPlatformRunGridQuery,
    GQTypes.GQDemoPlatformRunGridQueryVariables
  >({ query: DemoPlatformRunGridDocument, ...options });
}
export const MarkDemoPlatformRunAsFailedDocument = gql`
    mutation MarkDemoPlatformRunAsFailed($input: MarkDemoPlatformRunAsFailedInput!) @withOwner(owner: EnterpriseFoundations) {
  markDemoPlatformRunAsFailed(input: $input) {
    demoPlatformRun {
      id
      statusJson
    }
  }
}
    `;

export function useMarkDemoPlatformRunAsFailedMutation() {
  return Urql.useMutation<
    GQTypes.GQMarkDemoPlatformRunAsFailedMutation,
    GQTypes.GQMarkDemoPlatformRunAsFailedMutationVariables
  >(MarkDemoPlatformRunAsFailedDocument);
}
export const RunDemoPlatformDocument = gql`
    mutation RunDemoPlatform($input: RunDemoPlatformInput!) @withOwner(owner: EnterpriseFoundations) {
  runDemoPlatform(input: $input) {
    demoPlatformRunId
    jobId
  }
}
    `;

export function useRunDemoPlatformMutation() {
  return Urql.useMutation<
    GQTypes.GQRunDemoPlatformMutation,
    GQTypes.GQRunDemoPlatformMutationVariables
  >(RunDemoPlatformDocument);
}
export const FinanceSnapshotsDocument = gql`
    query FinanceSnapshots @withOwner(owner: WinFinance) {
  financeSnapshots {
    id
    ...FinanceSnapshotForContext
  }
}
    ${FinanceSnapshotForContextFragmentDoc}`;

export function useFinanceSnapshotsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceSnapshotsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceSnapshotsQuery,
    GQTypes.GQFinanceSnapshotsQueryVariables
  >({ query: FinanceSnapshotsDocument, ...options });
}
export const FinanceSnapshotForStatusDocument = gql`
    query FinanceSnapshotForStatus($id: ID!) @withOwner(owner: WinFinance) {
  financeSnapshot(id: $id) {
    id
    ...FinanceSnapshotForContext
  }
}
    ${FinanceSnapshotForContextFragmentDoc}`;

export function useFinanceSnapshotForStatusQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceSnapshotForStatusQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceSnapshotForStatusQuery,
    GQTypes.GQFinanceSnapshotForStatusQueryVariables
  >({ query: FinanceSnapshotForStatusDocument, ...options });
}
export const FinanceFundsDocument = gql`
    query FinanceFunds @withOwner(owner: WinFinance) {
  funds {
    id
    ...FundAllFields
  }
}
    ${FundAllFieldsFragmentDoc}`;

export function useFinanceFundsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceFundsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceFundsQuery,
    GQTypes.GQFinanceFundsQueryVariables
  >({ query: FinanceFundsDocument, ...options });
}
export const FinanceAssetsCorporateDocument = gql`
    query FinanceAssetsCorporate @withOwner(owner: WinFinance) {
  assetsCorporate {
    id
    ...AssetCorporateAllFields
    assetGroup {
      id
      name
    }
  }
}
    ${AssetCorporateAllFieldsFragmentDoc}`;

export function useFinanceAssetsCorporateQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceAssetsCorporateQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceAssetsCorporateQuery,
    GQTypes.GQFinanceAssetsCorporateQueryVariables
  >({ query: FinanceAssetsCorporateDocument, ...options });
}
export const FinanceAssetsRealEstateDocument = gql`
    query FinanceAssetsRealEstate @withOwner(owner: WinFinance) {
  assetsRealEstate {
    id
    ...AssetRealEstateAllFields
    assetGroup {
      id
      name
    }
  }
}
    ${AssetRealEstateAllFieldsFragmentDoc}`;

export function useFinanceAssetsRealEstateQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceAssetsRealEstateQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceAssetsRealEstateQuery,
    GQTypes.GQFinanceAssetsRealEstateQueryVariables
  >({ query: FinanceAssetsRealEstateDocument, ...options });
}
export const FinanceAssetGroupsDocument = gql`
    query FinanceAssetGroups @withOwner(owner: WinFinance) {
  assetGroups {
    id
    ...AssetGroupAllFields
  }
}
    ${AssetGroupAllFieldsFragmentDoc}`;

export function useFinanceAssetGroupsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceAssetGroupsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceAssetGroupsQuery,
    GQTypes.GQFinanceAssetGroupsQueryVariables
  >({ query: FinanceAssetGroupsDocument, ...options });
}
export const FinanceAssetSovereignBondsDocument = gql`
    query FinanceAssetSovereignBonds @withOwner(owner: WinFinance) {
  assetSovereignBonds {
    id
    ...AssetSovereignBondAllFields
  }
}
    ${AssetSovereignBondAllFieldsFragmentDoc}`;

export function useFinanceAssetSovereignBondsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceAssetSovereignBondsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceAssetSovereignBondsQuery,
    GQTypes.GQFinanceAssetSovereignBondsQueryVariables
  >({ query: FinanceAssetSovereignBondsDocument, ...options });
}
export const FinanceAssetPersonalMotorVehicleInsurancesDocument = gql`
    query FinanceAssetPersonalMotorVehicleInsurances @withOwner(owner: WinFinance) {
  assetPersonalMotorVehicleInsurances {
    id
    ...AssetPersonalMotorVehicleInsuranceAllFields
  }
}
    ${AssetPersonalMotorVehicleInsuranceAllFieldsFragmentDoc}`;

export function useFinanceAssetPersonalMotorVehicleInsurancesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceAssetPersonalMotorVehicleInsurancesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceAssetPersonalMotorVehicleInsurancesQuery,
    GQTypes.GQFinanceAssetPersonalMotorVehicleInsurancesQueryVariables
  >({ query: FinanceAssetPersonalMotorVehicleInsurancesDocument, ...options });
}
export const FinancePersonalMotorVehicleInsuranceAssetYearsDocument = gql`
    query FinancePersonalMotorVehicleInsuranceAssetYears @withOwner(owner: WinFinance) {
  personalMotorVehicleInsuranceAssetYears {
    id
    ...AssetYearAllFields
    asset {
      id
      name
    }
  }
}
    ${AssetYearAllFieldsFragmentDoc}`;

export function useFinancePersonalMotorVehicleInsuranceAssetYearsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinancePersonalMotorVehicleInsuranceAssetYearsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinancePersonalMotorVehicleInsuranceAssetYearsQuery,
    GQTypes.GQFinancePersonalMotorVehicleInsuranceAssetYearsQueryVariables
  >({
    query: FinancePersonalMotorVehicleInsuranceAssetYearsDocument,
    ...options,
  });
}
export const FinanceSovereignBondAssetYearsDocument = gql`
    query FinanceSovereignBondAssetYears @withOwner(owner: WinFinance) {
  sovereignBondAssetYears {
    id
    ...AssetYearAllFields
    asset {
      id
      name
    }
  }
}
    ${AssetYearAllFieldsFragmentDoc}`;

export function useFinanceSovereignBondAssetYearsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceSovereignBondAssetYearsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceSovereignBondAssetYearsQuery,
    GQTypes.GQFinanceSovereignBondAssetYearsQueryVariables
  >({ query: FinanceSovereignBondAssetYearsDocument, ...options });
}
export const FinanceAssetYearsDocument = gql`
    query FinanceAssetYears @withOwner(owner: WinFinance) {
  assetYears {
    id
    ...AssetYearAllFields
    asset {
      id
      name
    }
  }
}
    ${AssetYearAllFieldsFragmentDoc}`;

export function useFinanceAssetYearsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceAssetYearsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceAssetYearsQuery,
    GQTypes.GQFinanceAssetYearsQueryVariables
  >({ query: FinanceAssetYearsDocument, ...options });
}
export const FinanceAssetHoldingsDocument = gql`
    query FinanceAssetHoldings @withOwner(owner: WinFinance) {
  assetHoldings {
    id
    ...AssetHoldingAllFields
    year
    asset {
      id
      name
    }
    fund {
      id
      name
    }
  }
}
    ${AssetHoldingAllFieldsFragmentDoc}`;

export function useFinanceAssetHoldingsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceAssetHoldingsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceAssetHoldingsQuery,
    GQTypes.GQFinanceAssetHoldingsQueryVariables
  >({ query: FinanceAssetHoldingsDocument, ...options });
}
export const FinancePersonalMotorVehicleInsuranceAssetHoldingsDocument = gql`
    query FinancePersonalMotorVehicleInsuranceAssetHoldings @withOwner(owner: WinFinance) {
  personalMotorVehicleInsuranceAssetHoldings {
    id
    ...AssetHoldingAllFields
    year
    asset {
      id
      name
    }
    fund {
      id
      name
    }
  }
}
    ${AssetHoldingAllFieldsFragmentDoc}`;

export function useFinancePersonalMotorVehicleInsuranceAssetHoldingsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinancePersonalMotorVehicleInsuranceAssetHoldingsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinancePersonalMotorVehicleInsuranceAssetHoldingsQuery,
    GQTypes.GQFinancePersonalMotorVehicleInsuranceAssetHoldingsQueryVariables
  >({
    query: FinancePersonalMotorVehicleInsuranceAssetHoldingsDocument,
    ...options,
  });
}
export const FinanceContactsDocument = gql`
    query FinanceContacts @withOwner(owner: WinFinance) {
  contacts {
    id
    ...SupplierContactAllFields
    company {
      id
      names {
        id
        name
      }
    }
  }
}
    ${SupplierContactAllFieldsFragmentDoc}`;

export function useFinanceContactsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceContactsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceContactsQuery,
    GQTypes.GQFinanceContactsQueryVariables
  >({ query: FinanceContactsDocument, ...options });
}
export const SupplierContactsDocument = gql`
    query SupplierContacts @withOwner(owner: WinFinance) {
  organization {
    id
    orgCompanies {
      id
      company {
        id
        name
        contacts {
          email
          id
          name
          role
        }
      }
    }
  }
}
    `;

export function useSupplierContactsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQSupplierContactsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSupplierContactsQuery,
    GQTypes.GQSupplierContactsQueryVariables
  >({ query: SupplierContactsDocument, ...options });
}
export const GenerateExportUrlDocument = gql`
    mutation GenerateExportUrl($input: GenerateExportUrlInput!) @withOwner(owner: SupplyChain) {
  generateExportUrl(input: $input) {
    workflowId
  }
}
    `;

export function useGenerateExportUrlMutation() {
  return Urql.useMutation<
    GQTypes.GQGenerateExportUrlMutation,
    GQTypes.GQGenerateExportUrlMutationVariables
  >(GenerateExportUrlDocument);
}
export const CancelWorkflowDashboardDocument = gql`
    mutation CancelWorkflowDashboard($input: CancelWorkflowInput!) {
  cancelWorkflow(input: $input) {
    success
  }
}
    `;

export function useCancelWorkflowDashboardMutation() {
  return Urql.useMutation<
    GQTypes.GQCancelWorkflowDashboardMutation,
    GQTypes.GQCancelWorkflowDashboardMutationVariables
  >(CancelWorkflowDashboardDocument);
}
export const GetFacilityForRedirectDocument = gql`
    query GetFacilityForRedirect($facilityIdentifier: String!, $measurementProjectId: ID!) @withOwner(owner: DataIngestion) {
  facilities(
    first: 1
    filter: {field: {type: BuildingIdentifier}, operator: equals, value: [$facilityIdentifier]}
  ) {
    edges {
      node {
        id
        tasks(measurementProjectId: $measurementProjectId) {
          id
          datasource {
            id
            dataset {
              id
              canonicalDataset {
                id
                kind
              }
            }
          }
        }
      }
    }
  }
}
    `;

export function useGetFacilityForRedirectQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFacilityForRedirectQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFacilityForRedirectQuery,
    GQTypes.GQGetFacilityForRedirectQueryVariables
  >({ query: GetFacilityForRedirectDocument, ...options });
}
export const FootprintReadyForReduxDocument = gql`
    query FootprintReadyForRedux($orgFiscalYearStartMonth: Int) @withOwner(owner: Calcprint) {
  hasFootprintReadyForRedux(orgFiscalYearStartMonth: $orgFiscalYearStartMonth)
}
    `;

export function useFootprintReadyForReduxQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFootprintReadyForReduxQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFootprintReadyForReduxQuery,
    GQTypes.GQFootprintReadyForReduxQueryVariables
  >({ query: FootprintReadyForReduxDocument, ...options });
}
export const GetAverageCurrencyExchangeRatePairsDocument = gql`
    query GetAverageCurrencyExchangeRatePairs($inputs: [CurrencyExchangeRateInput!]!) @withOwner(owner: SupplyChain) {
  averageCurrencyExchangeRatePairs(inputs: $inputs) {
    id
    value
  }
}
    `;

export function useGetAverageCurrencyExchangeRatePairsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetAverageCurrencyExchangeRatePairsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetAverageCurrencyExchangeRatePairsQuery,
    GQTypes.GQGetAverageCurrencyExchangeRatePairsQueryVariables
  >({ query: GetAverageCurrencyExchangeRatePairsDocument, ...options });
}
export const GetAverageCurrencyExchangeRatesDocument = gql`
    query GetAverageCurrencyExchangeRates($baseCurrency: String!, $targetCurrency: String!, $intervals: [YMInterval!]!) {
  averageCurrencyExchangeRates(
    baseCurrency: $baseCurrency
    targetCurrency: $targetCurrency
    intervals: $intervals
  ) {
    id
    value
  }
}
    `;

export function useGetAverageCurrencyExchangeRatesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetAverageCurrencyExchangeRatesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetAverageCurrencyExchangeRatesQuery,
    GQTypes.GQGetAverageCurrencyExchangeRatesQueryVariables
  >({ query: GetAverageCurrencyExchangeRatesDocument, ...options });
}
export const GetFootprintSnapshotInfoDocument = gql`
    query GetFootprintSnapshotInfo($footprintSnapshotId: String!) @withOwner(owner: Calcprint) {
  footprintSnapshotInDashboard(footprintSnapshotId: $footprintSnapshotId) {
    ...FootprintSnapshotInfoForStatus
  }
}
    ${FootprintSnapshotInfoForStatusFragmentDoc}`;

export function useGetFootprintSnapshotInfoQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintSnapshotInfoQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintSnapshotInfoQuery,
    GQTypes.GQGetFootprintSnapshotInfoQueryVariables
  >({ query: GetFootprintSnapshotInfoDocument, ...options });
}
export const GetLatestCurrencyExchangeRateDocument = gql`
    query GetLatestCurrencyExchangeRate($targetCurrency: String!) {
  latestCurrencyExchangeRate(targetCurrency: $targetCurrency) {
    id
    updatedAt
    currency
    value
  }
}
    `;

export function useGetLatestCurrencyExchangeRateQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetLatestCurrencyExchangeRateQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetLatestCurrencyExchangeRateQuery,
    GQTypes.GQGetLatestCurrencyExchangeRateQueryVariables
  >({ query: GetLatestCurrencyExchangeRateDocument, ...options });
}
export const SignOneSchemaJwtDocument = gql`
    query signOneSchemaJwt($payload: JSONString!) @withOwner(owner: DataIngestion) {
  signOneSchemaJwt(payload: $payload) {
    token
  }
}
    `;

export function useSignOneSchemaJwtQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQSignOneSchemaJwtQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSignOneSchemaJwtQuery,
    GQTypes.GQSignOneSchemaJwtQueryVariables
  >({ query: SignOneSchemaJwtDocument, ...options });
}
export const ImportMarkUserUploadCompletedDocument = gql`
    mutation ImportMarkUserUploadCompleted($input: MarkUserUploadCompletedInput!) @withOwner(owner: DataIngestion) {
  markUserUploadCompleted(input: $input) {
    file {
      id
      remoteWritten
    }
    parseJobId
    userUpload {
      id
      name
      status
    }
  }
}
    `;

export function useImportMarkUserUploadCompletedMutation() {
  return Urql.useMutation<
    GQTypes.GQImportMarkUserUploadCompletedMutation,
    GQTypes.GQImportMarkUserUploadCompletedMutationVariables
  >(ImportMarkUserUploadCompletedDocument);
}
export const SetPreferredLocaleDocument = gql`
    mutation SetPreferredLocale($input: SetPreferredLocaleInput!) @withOwner(owner: EnterpriseFoundations) {
  setPreferredLocale(input: $input) {
    preferredLocale
  }
}
    `;

export function useSetPreferredLocaleMutation() {
  return Urql.useMutation<
    GQTypes.GQSetPreferredLocaleMutation,
    GQTypes.GQSetPreferredLocaleMutationVariables
  >(SetPreferredLocaleDocument);
}
export const TypeaheadCompanyWithEfsDocument = gql`
    query TypeaheadCompanyWithEfs($search: String!, $allowMatchById: Boolean!, $searchDemo: Boolean!, $filterHiddenCompanies: Boolean) @withOwner(owner: SupplyChain) {
  companyFuzzyMatchWithEfs(
    q: $search
    allowMatchById: $allowMatchById
    searchDemo: $searchDemo
    filterHiddenCompanies: $filterHiddenCompanies
  ) {
    results {
      ...CompanyAutocompleteResultWithSource
    }
    companyIdsWithSupplierSpecificEfs
  }
}
    ${CompanyAutocompleteResultWithSourceFragmentDoc}`;

export function useTypeaheadCompanyWithEfsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQTypeaheadCompanyWithEfsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQTypeaheadCompanyWithEfsQuery,
    GQTypes.GQTypeaheadCompanyWithEfsQueryVariables
  >({ query: TypeaheadCompanyWithEfsDocument, ...options });
}
export const GetCompanyDocument = gql`
    query getCompany($id: ID) @withOwner(owner: SupplyChain) {
  companyForDashboard(id: $id) {
    ...CompanyAutocompleteResultWithSource
  }
}
    ${CompanyAutocompleteResultWithSourceFragmentDoc}`;

export function useGetCompanyQuery(
  options?: Omit<Urql.UseQueryArgs<GQTypes.GQGetCompanyQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQGetCompanyQuery,
    GQTypes.GQGetCompanyQueryVariables
  >({ query: GetCompanyDocument, ...options });
}
export const CreateDemoOrgDocument = gql`
    mutation CreateDemoOrg($input: CreateDemoOrgInput!) @withOwner(owner: EnterpriseFoundations) {
  createDemoOrg(input: $input) {
    backgroundJobId
  }
}
    `;

export function useCreateDemoOrgMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateDemoOrgMutation,
    GQTypes.GQCreateDemoOrgMutationVariables
  >(CreateDemoOrgDocument);
}
export const LatestDocumentParseAttemptForReviewDocument = gql`
    query LatestDocumentParseAttemptForReview($fileId: ID!, $modelType: DocumentParseAttemptModelType!) @withOwner(owner: DataIngestion) {
  latestDocumentParseAttempt(fileId: $fileId, modelType: $modelType) {
    ...ReviewDocumentParseAttempt
  }
}
    ${ReviewDocumentParseAttemptFragmentDoc}`;

export function useLatestDocumentParseAttemptForReviewQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQLatestDocumentParseAttemptForReviewQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQLatestDocumentParseAttemptForReviewQuery,
    GQTypes.GQLatestDocumentParseAttemptForReviewQueryVariables
  >({ query: LatestDocumentParseAttemptForReviewDocument, ...options });
}
export const ReviewUserUploadPreviewUrlDocument = gql`
    query ReviewUserUploadPreviewUrl($userUploadId: ID!) @withOwner(owner: DataIngestion) {
  userUpload(id: $userUploadId) {
    userVisibleAttempt {
      previewUrl
    }
  }
}
    `;

export function useReviewUserUploadPreviewUrlQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReviewUserUploadPreviewUrlQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReviewUserUploadPreviewUrlQuery,
    GQTypes.GQReviewUserUploadPreviewUrlQueryVariables
  >({ query: ReviewUserUploadPreviewUrlDocument, ...options });
}
export const UpsertDocumentParseAttemptReviewDocument = gql`
    mutation UpsertDocumentParseAttemptReview($input: UpsertDocumentParseAttemptInput!) @withOwner(owner: DataIngestion) {
  upsertDocumentParseAttempt(input: $input) {
    ...ReviewDocumentParseAttempt
  }
}
    ${ReviewDocumentParseAttemptFragmentDoc}`;

export function useUpsertDocumentParseAttemptReviewMutation() {
  return Urql.useMutation<
    GQTypes.GQUpsertDocumentParseAttemptReviewMutation,
    GQTypes.GQUpsertDocumentParseAttemptReviewMutationVariables
  >(UpsertDocumentParseAttemptReviewDocument);
}
export const OrgDatasetsDocument = gql`
    query OrgDatasets @withOwner(owner: EnterpriseFoundations) {
  organization {
    id
    datasets {
      id
      name
    }
  }
}
    `;

export function useOrgDatasetsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgDatasetsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgDatasetsQuery,
    GQTypes.GQOrgDatasetsQueryVariables
  >({ query: OrgDatasetsDocument, ...options });
}
export const OrgDatasourcesDocument = gql`
    query OrgDatasources @withOwner(owner: EnterpriseFoundations) {
  organization {
    id
    datasets {
      id
      name
      datasources {
        id
        name
      }
    }
  }
}
    `;

export function useOrgDatasourcesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgDatasourcesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgDatasourcesQuery,
    GQTypes.GQOrgDatasourcesQueryVariables
  >({ query: OrgDatasourcesDocument, ...options });
}
export const GetPermissionsForUserDocument = gql`
    query GetPermissionsForUser($userId: ID!, $orgId: ID!) @withOwner(owner: EnterpriseFoundations) {
  user(id: $userId) {
    id
    name
    email
    displayName
    orgAccessId(orgId: $orgId)
    userUploadTasks {
      id
      measurementProject {
        id
        name
        active
      }
      datasource {
        id
        name
        dataset {
          id
          name
        }
      }
    }
    roles(orgId: $orgId) {
      id
      revokedAt
      role {
        id
        name
        description
        permissions {
          id
          permission
          revokedAt
          objectId
          object {
            __typename
            id
            name
          }
        }
      }
    }
    permissions(orgId: $orgId) {
      id
      ...PermissionFields
    }
  }
}
    ${PermissionFieldsFragmentDoc}`;

export function useGetPermissionsForUserQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPermissionsForUserQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPermissionsForUserQuery,
    GQTypes.GQGetPermissionsForUserQueryVariables
  >({ query: GetPermissionsForUserDocument, ...options });
}
export const RevokePermissionDocument = gql`
    mutation RevokePermission($permissionId: ID!) @withOwner(owner: EnterpriseFoundations) {
  revokePermissionItem(input: {permissionItemId: $permissionId}) {
    permissionItem {
      id
      revokedAt
    }
  }
}
    `;

export function useRevokePermissionMutation() {
  return Urql.useMutation<
    GQTypes.GQRevokePermissionMutation,
    GQTypes.GQRevokePermissionMutationVariables
  >(RevokePermissionDocument);
}
export const RevokeRoleForUserDocument = gql`
    mutation RevokeRoleForUser($roleId: ID!, $orgAccessId: ID!, $orgId: ID) @withOwner(owner: EnterpriseFoundations) {
  revokeUserRole(
    input: {roleId: $roleId, orgAccessId: $orgAccessId, orgId: $orgId}
  ) {
    userRoleAssignment {
      id
      revokedAt
    }
  }
}
    `;

export function useRevokeRoleForUserMutation() {
  return Urql.useMutation<
    GQTypes.GQRevokeRoleForUserMutation,
    GQTypes.GQRevokeRoleForUserMutationVariables
  >(RevokeRoleForUserDocument);
}
export const AddPermissionDocument = gql`
    mutation AddPermission($input: CreatePermissionItemInput!) @withOwner(owner: EnterpriseFoundations) {
  createPermissionItem(input: $input) {
    permissionItem {
      id
      user {
        id
      }
      revokedAt
    }
  }
}
    `;

export function useAddPermissionMutation() {
  return Urql.useMutation<
    GQTypes.GQAddPermissionMutation,
    GQTypes.GQAddPermissionMutationVariables
  >(AddPermissionDocument);
}
export const AddUserRoleDocument = gql`
    mutation AddUserRole($input: GrantUserRoleInput!) @withOwner(owner: EnterpriseFoundations) {
  grantUserRole(input: $input) {
    userRoleAssignment {
      id
    }
  }
}
    `;

export function useAddUserRoleMutation() {
  return Urql.useMutation<
    GQTypes.GQAddUserRoleMutation,
    GQTypes.GQAddUserRoleMutationVariables
  >(AddUserRoleDocument);
}
export const GetAllAssignableUsersDocument = gql`
    query GetAllAssignableUsers @withOwner(owner: DataIngestion) {
  organization {
    id
    users {
      edges {
        node {
          ...AssignableUserFields
        }
      }
    }
  }
}
    ${AssignableUserFieldsFragmentDoc}`;

export function useGetAllAssignableUsersQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetAllAssignableUsersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetAllAssignableUsersQuery,
    GQTypes.GQGetAllAssignableUsersQueryVariables
  >({ query: GetAllAssignableUsersDocument, ...options });
}
export const ParquetDataToCsvDocument = gql`
    mutation ParquetDataToCsv($input: ParquetDataToCsvInput!) @withOwner(owner: DataIngestion) {
  parquetDataToCsv(input: $input) {
    jobId
  }
}
    `;

export function useParquetDataToCsvMutation() {
  return Urql.useMutation<
    GQTypes.GQParquetDataToCsvMutation,
    GQTypes.GQParquetDataToCsvMutationVariables
  >(ParquetDataToCsvDocument);
}
export const GetEngagementTaskConfigsForTaskCreationDocument = gql`
    query GetEngagementTaskConfigsForTaskCreation($includeGlobalConfigs: Boolean!, $orgId: ID!, $includeArchived: Boolean!) @withOwner(owner: SupplyChain) {
  engagementTaskConfigs(
    includeGlobalConfigs: $includeGlobalConfigs
    includeArchived: $includeArchived
    orgId: $orgId
  ) {
    ...EngagementTaskConfigForTaskCreation
  }
}
    ${EngagementTaskConfigForTaskCreationFragmentDoc}`;

export function useGetEngagementTaskConfigsForTaskCreationQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEngagementTaskConfigsForTaskCreationQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEngagementTaskConfigsForTaskCreationQuery,
    GQTypes.GQGetEngagementTaskConfigsForTaskCreationQueryVariables
  >({ query: GetEngagementTaskConfigsForTaskCreationDocument, ...options });
}
export const OrderedBusinessActivityTypeInstancesForNameDocument = gql`
    query OrderedBusinessActivityTypeInstancesForName($name: String!) @withOwner(owner: DatasetsAndObjects) {
  orderedBusinessActivityTypeInstancesForName(name: $name) {
    ...BusinessActivityTypeLite
  }
}
    ${BusinessActivityTypeLiteFragmentDoc}`;

export function useOrderedBusinessActivityTypeInstancesForNameQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrderedBusinessActivityTypeInstancesForNameQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrderedBusinessActivityTypeInstancesForNameQuery,
    GQTypes.GQOrderedBusinessActivityTypeInstancesForNameQueryVariables
  >({ query: OrderedBusinessActivityTypeInstancesForNameDocument, ...options });
}
export const LatestBusinessActivityTypeInstancesDocument = gql`
    query LatestBusinessActivityTypeInstances @withOwner(owner: DatasetsAndObjects) {
  latestBusinessActivityTypeInstances {
    ...BusinessActivityTypeLite
  }
}
    ${BusinessActivityTypeLiteFragmentDoc}`;

export function useLatestBusinessActivityTypeInstancesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQLatestBusinessActivityTypeInstancesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQLatestBusinessActivityTypeInstancesQuery,
    GQTypes.GQLatestBusinessActivityTypeInstancesQueryVariables
  >({ query: LatestBusinessActivityTypeInstancesDocument, ...options });
}
export const LatestBusinessActivityTypeInstanceForNameDocument = gql`
    query LatestBusinessActivityTypeInstanceForName($name: String!) @withOwner(owner: DatasetsAndObjects) {
  latestBusinessActivityTypeInstanceForName(name: $name) {
    ...BusinessActivityTypeLite
  }
}
    ${BusinessActivityTypeLiteFragmentDoc}`;

export function useLatestBusinessActivityTypeInstanceForNameQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQLatestBusinessActivityTypeInstanceForNameQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQLatestBusinessActivityTypeInstanceForNameQuery,
    GQTypes.GQLatestBusinessActivityTypeInstanceForNameQueryVariables
  >({ query: LatestBusinessActivityTypeInstanceForNameDocument, ...options });
}
export const BusinessActivityTypeByIdDocument = gql`
    query BusinessActivityTypeById($batId: ID!) @withOwner(owner: DatasetsAndObjects) {
  businessActivityTypeById(batId: $batId) {
    ...BusinessActivityTypeLite
  }
}
    ${BusinessActivityTypeLiteFragmentDoc}`;

export function useBusinessActivityTypeByIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBusinessActivityTypeByIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBusinessActivityTypeByIdQuery,
    GQTypes.GQBusinessActivityTypeByIdQueryVariables
  >({ query: BusinessActivityTypeByIdDocument, ...options });
}
export const BusinessActivityTypeVersionByIdDocument = gql`
    query BusinessActivityTypeVersionById($id: ID!) @withOwner(owner: Calcprint) {
  businessActivityTypeVersionById(id: $id) {
    id
    name
    businessActivityTypes {
      ...AllBusinessActivityTypeFields
    }
  }
}
    ${AllBusinessActivityTypeFieldsFragmentDoc}`;

export function useBusinessActivityTypeVersionByIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBusinessActivityTypeVersionByIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBusinessActivityTypeVersionByIdQuery,
    GQTypes.GQBusinessActivityTypeVersionByIdQueryVariables
  >({ query: BusinessActivityTypeVersionByIdDocument, ...options });
}
export const BusinessActivityTypeByIdsDocument = gql`
    query BusinessActivityTypeByIds($batIds: [ID!]!) @withOwner(owner: Calcprint) {
  businessActivityTypeByIds(batIds: $batIds) {
    version {
      id
      name
    }
    ...AllBusinessActivityTypeFields
  }
}
    ${AllBusinessActivityTypeFieldsFragmentDoc}`;

export function useBusinessActivityTypeByIdsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBusinessActivityTypeByIdsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBusinessActivityTypeByIdsQuery,
    GQTypes.GQBusinessActivityTypeByIdsQueryVariables
  >({ query: BusinessActivityTypeByIdsDocument, ...options });
}
export const LiveBusinessActivityTypeVersionDocument = gql`
    query LiveBusinessActivityTypeVersion @withOwner(owner: Calcprint) {
  businessActivityTypeVersionLive {
    id
    name
    businessActivityTypes {
      ...AllBusinessActivityTypeFields
    }
  }
}
    ${AllBusinessActivityTypeFieldsFragmentDoc}`;

export function useLiveBusinessActivityTypeVersionQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQLiveBusinessActivityTypeVersionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQLiveBusinessActivityTypeVersionQuery,
    GQTypes.GQLiveBusinessActivityTypeVersionQueryVariables
  >({ query: LiveBusinessActivityTypeVersionDocument, ...options });
}
export const BusinessActivityTypeByNameAndVersionIdDocument = gql`
    query BusinessActivityTypeByNameAndVersionId($name: String!, $versionId: ID!) @withOwner(owner: Calcprint) {
  businessActivityTypeByNameAndVersionId(name: $name, versionId: $versionId) {
    ...BusinessActivityTypeLite
  }
}
    ${BusinessActivityTypeLiteFragmentDoc}`;

export function useBusinessActivityTypeByNameAndVersionIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBusinessActivityTypeByNameAndVersionIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBusinessActivityTypeByNameAndVersionIdQuery,
    GQTypes.GQBusinessActivityTypeByNameAndVersionIdQueryVariables
  >({ query: BusinessActivityTypeByNameAndVersionIdDocument, ...options });
}
export const AllOrgUsersForPermissionDocument = gql`
    query AllOrgUsersForPermission($orgId: ID!, $permissions: [HasPermissionInput!]!) @withOwner(owner: EnterpriseFoundations) {
  organization(id: $orgId) {
    id
    users {
      edges {
        node {
          ...UserForAllOrgUsersForPermission
        }
      }
    }
  }
}
    ${UserForAllOrgUsersForPermissionFragmentDoc}`;

export function useAllOrgUsersForPermissionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAllOrgUsersForPermissionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAllOrgUsersForPermissionQuery,
    GQTypes.GQAllOrgUsersForPermissionQueryVariables
  >({ query: AllOrgUsersForPermissionDocument, ...options });
}
export const OrgUsersWithPermissionsDocument = gql`
    query OrgUsersWithPermissions($orgId: ID!, $permissionInfos: [HasPermissionInput!]!) @withOwner(owner: EnterpriseFoundations) {
  organization(id: $orgId) {
    id
    users(filters: {withSomePermissions: $permissionInfos}) {
      edges {
        node {
          ...UserForOrgUsersWithPermissions
        }
      }
    }
  }
}
    ${UserForOrgUsersWithPermissionsFragmentDoc}`;

export function useOrgUsersWithPermissionsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgUsersWithPermissionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgUsersWithPermissionsQuery,
    GQTypes.GQOrgUsersWithPermissionsQueryVariables
  >({ query: OrgUsersWithPermissionsDocument, ...options });
}
export const GetJobResultsDocument = gql`
    query GetJobResults($id: ID!) {
  job: backgroundJob(id: $id) {
    id
    kind
    state
    args
    result
    error {
      ...SerializableErrorFields
    }
  }
}
    ${SerializableErrorFieldsFragmentDoc}`;

export function useGetJobResultsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetJobResultsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetJobResultsQuery,
    GQTypes.GQGetJobResultsQueryVariables
  >({ query: GetJobResultsDocument, ...options });
}
export const GetJobStatusDocument = gql`
    query GetJobStatus($id: ID!) {
  job: backgroundJobStatus(id: $id) {
    id
    orgId
    state
    error {
      ...SerializableErrorFields
    }
  }
}
    ${SerializableErrorFieldsFragmentDoc}`;

export function useGetJobStatusQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetJobStatusQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetJobStatusQuery,
    GQTypes.GQGetJobStatusQueryVariables
  >({ query: GetJobStatusDocument, ...options });
}
export const ListJobsStatusDocument = gql`
    query ListJobsStatus($ids: [ID!]!) {
  backgroundJobStatuses(ids: $ids) {
    id
    orgId
    state
    error {
      ...SerializableErrorFields
    }
  }
}
    ${SerializableErrorFieldsFragmentDoc}`;

export function useListJobsStatusQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQListJobsStatusQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQListJobsStatusQuery,
    GQTypes.GQListJobsStatusQueryVariables
  >({ query: ListJobsStatusDocument, ...options });
}
export const CancelWorkflowDocument = gql`
    mutation CancelWorkflow($input: CancelWorkflowInput!) {
  cancelWorkflow(input: $input) {
    success
  }
}
    `;

export function useCancelWorkflowMutation() {
  return Urql.useMutation<
    GQTypes.GQCancelWorkflowMutation,
    GQTypes.GQCancelWorkflowMutationVariables
  >(CancelWorkflowDocument);
}
export const GetAllReportConfigsDocument = gql`
    query GetAllReportConfigs($filterHidden: Boolean) @withOwner(owner: Reporting) {
  allReportConfigs(filterHidden: $filterHidden) {
    ...OverviewReportConfigFields
  }
}
    ${OverviewReportConfigFieldsFragmentDoc}`;

export function useGetAllReportConfigsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetAllReportConfigsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetAllReportConfigsQuery,
    GQTypes.GQGetAllReportConfigsQueryVariables
  >({ query: GetAllReportConfigsDocument, ...options });
}
