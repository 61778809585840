import { forwardRef } from 'react';
import { Box } from '@mui/material';
import { useFeatureFlag } from '../../../../utils/FeatureFlag';
import { useUserContext } from '../../../../utils/UserContext';
import Button from '@watershed/ui-core/components/Button';
import { Flags } from '@watershed/constants/flags';
import Avatar from '@watershed/ui-core/components/Avatar';
import {
  sharedShadow,
  sharedFocusShadow,
  pulseAnimation,
} from '../utils/styles';
import { HELP_SIDEBAR_WIDTH } from '@watershed/shared-universal/utils/constants';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
export const TRIGGER_POSITION = 16;

interface RightSidebarHelpTriggerProps {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  isOpen: boolean;
}

export const RightSidebarHelpTrigger = forwardRef<
  HTMLButtonElement,
  RightSidebarHelpTriggerProps
>(function RightSidebarHelpTrigger({ onClick, isOpen }, ref) {
  const isEnabled = useFeatureFlag(Flags.DustBotInProductPrototype);
  const isWatershedEmployee = useUserContext().userIsWatershedEmployee;

  if (!isEnabled || !isWatershedEmployee) {
    return null;
  }

  return (
    <Box
      data-testid={TestIds.AITrigger}
      sx={{
        position: 'fixed',
        bottom: TRIGGER_POSITION,
        right: isOpen
          ? `${TRIGGER_POSITION + HELP_SIDEBAR_WIDTH}px`
          : TRIGGER_POSITION,
        zIndex: 2000,
      }}
    >
      <Button
        ref={ref}
        onClick={onClick}
        variant="contained"
        sx={{
          gap: 1,
          height: 'fit-content',
          minWidth: 'fit-content',
          p: 1,
          borderRadius: '100px',
          border: (theme) => `0.5px solid ${theme.palette.grey40}`,
          boxShadow: sharedShadow,
          animation: `${pulseAnimation} 2s infinite`, // Add the pulse animation
          '&:hover': {
            backgroundColor: (theme) => theme.palette.grey05,
            boxShadow: sharedShadow,
            animation: `${pulseAnimation} 2s infinite`, // Add the pulse animation
          },
          '&.active, &:focus': {
            backgroundColor: (theme) => theme.palette.white,
            border: (theme) => `0.5px solid ${theme.palette.cobalt50}`,
            boxShadow: sharedFocusShadow,
            animation: 'none', // Stop the animation when active/focused
          },
        }}
      >
        <Avatar size="small" variant="entity" name="Watershed" />
      </Button>
    </Box>
  );
});
