import { JSONSchema7 } from 'json-schema-to-ts';

const KEY_NODE_PROPERTIES = {
  identifier: {
    type: 'string',
    description: 'The unique identifier of the node. This is always a UUID.',
  },
  name: { type: 'string', description: 'The name of the node' },
  nodeType: {
    type: 'string',
    enum: ['processing', 'transportation', 'material', 'process_input'],
    description: 'The type of the node',
  },
  lifecycleStage: {
    type: 'string',
    enum: ['A1', 'A2', 'A3', 'A4'],
    description: 'The lifecycle stage of the node',
  },
  downstreamNodeIdentifier: {
    type: 'string',
    nullable: true,
    description: 'The unique identifier of the parent node',
  },
  assumptions: {
    type: 'string',
    nullable: true,
    description: 'The assumptions made for the node',
  },
  edits: {
    type: 'string',
    nullable: true,
    description: 'Edit requests by the user to the node',
  },
  supplierName: {
    type: 'string',
    nullable: true,
    description: 'The name of the supplier',
  },
  tags: {
    type: 'array',
    items: { type: 'string' },
    nullable: true,
    description: 'Array of tags associated with the node',
  },
  facilityName: {
    type: 'string',
    nullable: true,
    description: 'The name of the facility',
  },
  facilityLocation: {
    type: 'string',
    nullable: true,
    description: 'The location of the facility',
  },
  facilityCompany: {
    type: 'string',
    nullable: true,
    description: 'The company that owns the facility',
  },
  facilityDescription: {
    type: 'string',
    nullable: true,
    description: 'Description of the facility',
  },
  facilityAssumptions: {
    type: 'string',
    nullable: true,
    description: 'Assumptions made about the facility',
  },
  countryCodes: {
    type: 'string',
    nullable: true,
    description: 'An ISO 3166-1 alpha-2 country code',
  },
  outputMaterialName: {
    type: 'string',
    nullable: true,
    description: 'The name of the output material',
  },
  outputAmount: {
    type: 'number',
    description: 'The absolute amount of material outputted by the node',
  },
  outputAmountUnit: {
    type: 'string',
    description: 'The unit of the output amount',
  },
} as const;

// Define schemas as const for type inference
const addNodeSchema: JSONSchema7 = {
  type: 'object',
  properties: {
    newNode: {
      type: 'object',
      properties: {
        ...KEY_NODE_PROPERTIES,
      },
      required: [
        'name',
        'nodeType',
        'identifier',
        'outputAmount',
        'outputAmountUnit',
        'assumptions',
        'lifecycleStage',
      ],
      description:
        'Data for the new node to be added. Follow the schema for nodes as provided from the information about the state',
    },
    option: {
      type: 'string',
      enum: ['insert', 'new_input'],
      description:
        '"insert" adds a node in the flow, "new_input" adds a node as an input to the base node',
    },
    baseNodeId: {
      type: 'string',
      description:
        'The ID of the node to connect to (either insert after or add input to). IMPORTANT: the new node will be added upstream of the base node ID.',
    },
  },
  required: ['newNode', 'option', 'baseNodeId'],
} as const;

const deleteNodeSchema: JSONSchema7 = {
  type: 'object',
  properties: {
    nodeId: { type: 'string' },
    deleteOption: {
      type: 'string',
      enum: ['delete_upstream', 'pass_through'],
      description:
        'The option to delete the node. "delete_upstream" will delete the node and all upstream nodes. "pass_through" will delete the node and pass its upstream nodes through.',
    },
  },
  required: ['nodeId', 'deleteOption'],
} as const;

const editNodeSchema: JSONSchema7 = {
  type: 'object',
  properties: {
    nodeId: { type: 'string' },
    data: {
      type: 'object',
      properties: {
        ...KEY_NODE_PROPERTIES,
      },
      description:
        'Data for the new node to be added. Follow the schema for nodes as provided from the information about the state',
    },
  },
  required: ['nodeId', 'data'],
} as const;

const exploreGraphSchema: JSONSchema7 = {
  type: 'object',
  properties: {
    subgraphOptions: {
      type: 'object',
      description: 'Options to limit the search to a specific subgraph',
      properties: {
        centerNodeId: {
          type: 'string',
          description: 'The ID of the node at the center of the subgraph',
        },
        direction: {
          type: 'string',
          enum: ['upstream', 'downstream'],
          description: 'The direction to traverse from the center node',
        },
        maxDepth: {
          type: 'number',
          description: 'The maximum depth to traverse from the center node',
        },
      },
      required: ['centerNodeId', 'direction', 'maxDepth'],
    },
  },
  required: ['subgraphOptions'],
} as const;

export const actionSchemas = {
  addNode: addNodeSchema,
  deleteNode: deleteNodeSchema,
  editNode: editNodeSchema,
  exploreGraph: exploreGraphSchema,
};
