import { tInfer } from '@watershed/shared-universal/TSchema/types';
import { ProductionGraphSchema } from '../TSchema/schemas/productionGraph/ProductionGraphSchema';
import { Prettify } from '@watershed/shared-universal/utils/utilTypes';

type SnakeToCamelCase<S extends string> = S extends `${infer T}_${infer U}`
  ? `${T}${Capitalize<SnakeToCamelCase<U>>}`
  : S;
type Mutable<T> = {
  -readonly [P in keyof T]: T[P];
};

// Type for nodes to store information about their input rates, i.e. the inputs to recipes for transforming processes
export type InputRate = {
  childNodeIdentifier: string;
  rate: number;
  unitChildPerUnit: string;
};

// TSchema returns a readonly object, but in general we may modify the data in FE
export type ProductionGraphSchemaType = Prettify<
  Mutable<tInfer<typeof ProductionGraphSchema>>
>;

// Intermediate type to describe the type transformation only. We don't actually use this type anywhere.
type ProductionGraphSchemaTypeCamelCase = {
  [K in keyof ProductionGraphSchemaType as K extends string
    ? SnakeToCamelCase<K>
    : K]: ProductionGraphSchemaType[K];
};

// Input rates are not typed in TSchema, only stored as string. This type exists when we have
// parsed the input rates into an array of InputRate objects.
// Tags are stored as a string in TSchema, but we parse them into an array of strings.
export type ProductionGraphNode = Prettify<
  Omit<ProductionGraphSchemaTypeCamelCase, 'inputRates' | 'tags'> & {
    inputRates: Array<InputRate>;
    tags: Array<string> | null;
  }
>;

// Required fields for all node types
export type RequiredNodeData = {
  identifier: string;
  name: string;
  downstreamNodeIdentifier: string;
  outputMaterialName: string;
  inputRates: Array<InputRate>;
};

export type ProductionGraphEditAction =
  | { type: 'AddNode'; nodeId: string; data: ProductionGraphNode }
  | { type: 'DeleteNode'; nodeId: string }
  | { type: 'EditNode'; nodeId: string; data: Partial<ProductionGraphNode> };

// This type replaces instances of ProductionGraphNode with Partial<ProductionGraphNode> because
// data coming in from GQL is not validated against the discriminated union, so we don't know
// that we have full ProductionGraphNode data. Useful for still using the discriminated union
// while leaving the final data validation to TSchema. Eventually maybe we move to JSONSchema
// to validate the data coming in from GQL, but currently would be redundant with TSchema.
export type ProductionGraphEditActionWithUnvalidatedData = Prettify<
  {
    [K in ProductionGraphEditAction['type']]: Extract<
      ProductionGraphEditAction,
      { type: K }
    > extends { data: unknown }
      ? Omit<Extract<ProductionGraphEditAction, { type: K }>, 'data'> & {
          data: Partial<ProductionGraphNode>;
        }
      : Extract<ProductionGraphEditAction, { type: K }>;
  }[ProductionGraphEditAction['type']]
>;

export type ProductionGraphData = {
  id: string;
  createdAt: Date;
  deletedAt: null | Date;
  lastVersionId: null | string;
  dataRegistryTableName: string;
  nodes: Array<ProductionGraphNode>;
  lifecycleAssessmentId: string;
};

export const enum ImpactCategory {
  FossilEmissions = 'Fossil Emissions',
  BiogenicEmissions = 'Biogenic Emissions',
  FlagEmissions = 'Flag Emissions',
}

/*
 * Type assertions
 */

// Type assertion to ensure inputRates exists in ProductionGraphSchemaTypeCamelCase
// We add this since we are overwriting of inputRates into the ProductionGraphNode type
// so if input rates is removed or renamed, we don't want FE to incorrectly continue to compile
type EnsureInputRatesExistsInProductionGraphSchemaTypeCamelCase = {
  [K in keyof ProductionGraphSchemaTypeCamelCase]: K extends 'inputRates'
    ? true
    : never;
};
// This will fail if inputRates doesn't exist in ProductionGraphSchemaTypeCamelCase
type _TypeCheckInputRates =
  EnsureInputRatesExistsInProductionGraphSchemaTypeCamelCase['inputRates'] extends true
    ? true
    : never;

// Type assertion to ensure tags exists in ProductionGraphSchemaTypeCamelCase
// We add this since we are overwriting of tags into the ProductionGraphNode type
// so if tags is removed or renamed, we don't want FE to incorrectly continue to compile
type EnsureTagsExistsInProductionGraphSchemaTypeCamelCase = {
  [K in keyof ProductionGraphSchemaTypeCamelCase]: K extends 'tags'
    ? true
    : never;
};
// This will fail if tags doesn't exist in ProductionGraphSchemaTypeCamelCase
type _TypeCheckTags =
  EnsureTagsExistsInProductionGraphSchemaTypeCamelCase['tags'] extends true
    ? true
    : never;
