/* @skip-file-for-translation */

import React, { useState, useRef, useEffect } from 'react';
import lowerCase from 'lodash/lowerCase';
import { useLingui } from '@lingui/react/macro';
import { Box, Typography, Paper } from '@mui/material';
import Button from '@watershed/ui-core/components/Button';
import CircularProgress from '@watershed/ui-core/components/CircularProgress';
import {
  AIChatOptions,
  CommandRequest,
} from '@watershed/shared-universal/aiAgent/types';
import { Trans } from '@lingui/react/macro';
import { useAIAgentChat } from './useAIAgentChat';
import { useAIAgentRegistry } from './AIAgentContext';
import { HelpHeader } from '../support/HelpPanel/shared/Header';
import { QuestionInput } from '../support/HelpPanel/shared/components/QuestionInput';
import { MessageBubble } from '../support/HelpPanel/shared/components/MessageBubble';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useUserContext } from '@watershed/app-dashboard/utils/UserContext';
import { ThinkingTime } from '../support/HelpPanel/shared/components/ThinkingTime';
import CheckmarkIcon from '@watershed/icons/components/Checkmark';
import CloseIcon from '@watershed/icons/components/Close';
import { Pill } from '@watershed/ui-core/components/Pill';
import { CODE_FONT_FAMILY } from '@watershed/style/styleUtils';
import CollapsibleBlock from '@watershed/ui-core/components/CollapsibleBlock';
import { PrecannedMessageSuggestions } from '../support/HelpPanel/shared/components/PrecannedMessageSuggestions';
import {
  PrecannedMessage,
  RichHiddenContext,
} from '../support/HelpPanel/utils/types';
import StatusCompleteIcon from '@watershed/icons/components/StatusComplete';
import StatusInformationIcon from '@watershed/icons/components/StatusInformation';
import StatusMissingIcon from '@watershed/icons/components/StatusMissing';

// Styles for the timeline indicator (copied from HelpChat)
const STYLES = {
  timelineIndicator: {
    flexShrink: 0,
    ml: 2.5,
    width: '0.5px',
    height: '16px',
    backgroundColor: 'grey30',
  },
};

interface CommandItemProps {
  commandRequest: CommandRequest;
  onApprove: () => Promise<void>;
  onReject: () => void;
  expanded?: boolean;
  onToggleExpand?: () => void;
}

// Helper function to format command arguments for display
const formatCommandArgs = (args: Record<string, unknown>): string => {
  try {
    return JSON.stringify(args, null, 2);
  } catch (error) {
    return 'Unable to display arguments';
  }
};

// Helper function to format command results for display
const formatCommandResult = (result: unknown): string => {
  if (result === undefined || result === null) {
    return 'No result';
  }

  try {
    return JSON.stringify(result, null, 2);
  } catch (error) {
    return 'Not a valid JSON object. Unable to display.';
  }
};

// Component to display a single command with approval/rejection options
function CommandItem({
  commandRequest,
  onApprove,
  onReject,
  expanded = false,
  onToggleExpand,
}: CommandItemProps) {
  const { status } = commandRequest;
  // Determine the status color using theme
  const getStatusColor = (
    status: string
  ):
    | 'default'
    | 'error'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning' => {
    if (status === 'EXECUTING') {
      return 'info';
    } else if (status === 'EXECUTED') {
      return 'success';
    } else if (status === 'FAILED') {
      return 'error';
    } else if (status === 'REJECTED') {
      return 'warning';
    } else {
      return 'primary'; // Default color
    }
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'EXECUTING':
        return <CircularProgress size={12} />;
      case 'EXECUTED':
        return <StatusCompleteIcon color="grass" size={12} />;
      case 'FAILED':
      case 'REJECTED':
        return <StatusInformationIcon color="error.dark" size={12} />;
      default:
        return <StatusMissingIcon color="grey50" size={12} />;
    }
  };

  const command = commandRequest.command;

  return (
    <Box
      sx={{
        marginTop: 0.5,
        backgroundColor: (theme) => theme.palette.white,
        borderRadius: '6px',
        border: (theme) => `0.5px solid ${theme.palette.grey30}`,
        overflow: 'clip',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
          p: 1,
          boxShadow: (theme) =>
            `inset 0px -0.5px 0px 0px ${theme.palette.grey30}`,
        }}
        onClick={onToggleExpand}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="h4">{command.actionName}</Typography>
          <Pill
            variant="outlined"
            size="small"
            sx={{
              color: 'grey50',
              fontSize: '12px',
              textTransform: 'capitalize',
            }}
            label={lowerCase(status)}
            icon={getStatusIcon(status)}
            color={getStatusColor(status)}
          />
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          {status === 'PENDING' && (
            <>
              <Button
                isIcon
                variant="text"
                aria-label="Approve"
                onClick={async (e) => {
                  e.stopPropagation();
                  await onApprove();
                }}
              >
                <CheckmarkIcon color="grass" size={16} />
              </Button>
              <Button
                isIcon
                variant="text"
                color="error"
                aria-label="Reject"
                onClick={(e) => {
                  e.stopPropagation();
                  onReject();
                }}
              >
                <CloseIcon color="error.dark" size={16} />
              </Button>
            </>
          )}
        </Box>
      </Box>

      <CollapsibleBlock
        title={
          <Typography
            variant="body3"
            component="div"
            sx={{
              fontSize: '12px',
              color: 'text.secondary',
              fontFamily: CODE_FONT_FAMILY,
              width: '100%',
            }}
          >
            <Trans context="Arguments message for tool use requested by AI">
              Arguments:
            </Trans>
          </Typography>
        }
        sx={{
          px: 1,
          '& .MuiCollapse-wrapperInner > div': { pt: 0 },
          '& .MuiButtonBase-root': {
            justifyContent: 'space-between',
            pr: 1,
          },
        }}
        isIconOnRight
        iconProps={{
          size: 16,
          color: 'grey50',
        }}
        expanded={expanded}
        onToggle={onToggleExpand}
      >
        <Box
          sx={{
            p: 1,
            backgroundColor: (theme) => theme.palette.white,
            border: (theme) => `0.5px solid ${theme.palette.grey30}`,
            borderRadius: '4px',
            maxHeight: '256px',
            overflow: 'auto',
            fontFamily: CODE_FONT_FAMILY,
            color: (theme) => theme.palette.grey50,
            fontSize: '12px',
          }}
        >
          {formatCommandArgs(command.args)}
        </Box>

        {(status === 'EXECUTED' || status === 'FAILED') && (
          <>
            <Typography
              variant="body3"
              sx={{
                color: 'text.secondary',
                mt: 1,
                display: 'block',
                fontSize: '12px',
                fontFamily: CODE_FONT_FAMILY,
                width: '100%',
              }}
            >
              {status === 'FAILED' ? (
                <Trans context="Error message for failed command">Error:</Trans>
              ) : (
                <Trans context="Result message for executed command">
                  Result:
                </Trans>
              )}
            </Typography>
            <Box
              sx={{
                p: 1,
                backgroundColor: (theme) => theme.palette.white,
                border: (theme) => `0.5px solid ${theme.palette.grey30}`,
                borderRadius: '4px',
                maxHeight: '256px',
                overflow: 'auto',
                fontFamily: CODE_FONT_FAMILY,
                color: (theme) => theme.palette.grey50,
                fontSize: '12px',
              }}
            >
              {formatCommandResult(commandRequest.result)}
            </Box>
          </>
        )}
      </CollapsibleBlock>
    </Box>
  );
}

// Update the AIChatOptions interface to include precannedMessages
interface ExtendedAIChatOptions extends AIChatOptions {
  precannedMessages?: Array<PrecannedMessage>;
  precannedMessagesIntro?: string;
}

// Main AI Chat component
const AIAgentChatInner = ({
  featureId,
  initialMessage,
  onCollapsedChange,
  precannedMessages,
  precannedMessagesIntro,
}: ExtendedAIChatOptions) => {
  // Use the hook for chat functionality
  const { t } = useLingui();
  const { userName } = useUserContext();
  const {
    messages,
    isLoading,
    error,
    sendMessage,
    clearMessages,
    approveCommandRequest,
    rejectCommandRequest,
  } = useAIAgentChat({
    featureId,
  });

  const [expandedCommands, setExpandedCommands] = useState<
    Record<string, boolean>
  >({});
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [showPrecannedMessages, setShowPrecannedMessages] = useState(true);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  // Scroll to bottom when messages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  // Toggle command expansion
  const toggleCommandExpand = (commandId: string) => {
    setExpandedCommands((prev) => ({
      ...prev,
      [commandId]: !prev[commandId],
    }));
  };

  // Handle approving a command
  const handleApproveCommand = async (commandRequest: CommandRequest) => {
    if (isLoading) return;
    await approveCommandRequest(commandRequest);
  };

  // Handle rejecting a command
  const handleRejectCommand = (commandRequest: CommandRequest) => {
    if (isLoading) return;
    rejectCommandRequest(commandRequest);
  };

  // Handle pre-canned messages being passed to the input.
  const handlePrecannedMessageSelect = async (
    content: string,
    hiddenContext?: string | RichHiddenContext
  ) => {
    // If we have hidden context, incorporate it into the message content.
    let messageToSend = content;

    if (hiddenContext) {
      // Always use a consistent format for hidden context
      // If it's already a string, pass it directly
      // If it's an object, stringify it.
      const contextValue =
        typeof hiddenContext === 'string'
          ? hiddenContext
          : JSON.stringify(hiddenContext);

      messageToSend = `${content}\n\n[Hidden context: ${contextValue}]`;
    }

    // Send the combined message.
    await sendMessage(messageToSend);
    setShowPrecannedMessages(false);
  };

  // Wrapper to handle submission and re-throw errors for QuestionInput
  const handleSubmit = async (question: string) => {
    await sendMessage(question);
    setShowPrecannedMessages(false);
  };

  // Always include initial message if provided
  const displayMessages = [...messages];
  if (
    initialMessage &&
    (displayMessages.length === 0 ||
      !displayMessages.some(
        (msg) =>
          msg.sender === 'ai' &&
          msg.content ===
            (typeof initialMessage === 'string'
              ? initialMessage
              : 'Hello! How can I help you today?')
      ))
  ) {
    displayMessages.unshift({
      sender: 'ai',
      content:
        typeof initialMessage === 'string'
          ? initialMessage
          : 'Hello! How can I help you today?',
      timestamp: new Date(),
      commandRequests: [],
    });
  }

  // Helper to adapt ChatMessage to MessageBubble props
  const adaptMessageForBubble = (msg: (typeof messages)[0]) => ({
    isUser: msg.sender === 'user',
    content: msg.content,
    timestamp: msg.timestamp.getTime(),
    complete: true, // Assuming messages are complete once received from hook
  });

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
        borderRadius: '0px',
        borderLeft: (theme) => `0.5px solid ${theme.palette.grey30}`,
        backgroundColor: 'transparent',
        boxShadow: 'none',
      }}
    >
      <HelpHeader
        isContained={true}
        title={t({
          message: `Ask WaterBot`,
          context: 'Title for the AI chat panel',
        })}
        isCollapsed={false}
        onCollapsedChange={onCollapsedChange || (() => {})}
        onResetChat={() => {
          clearMessages();
          setShowPrecannedMessages(true);
        }}
        showResetButton={messages.length > 0}
        scrollContainerRef={scrollContainerRef}
      />

      <Box
        sx={{
          flexGrow: 1,
          overflow: 'auto',
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
        ref={scrollContainerRef}
      >
        {displayMessages.map((message, index) => {
          const messageId = `${message.sender}-${message.timestamp.getTime()}-${index}`;
          const previousMessage = index > 0 ? displayMessages[index - 1] : null;

          if (message.sender === 'system') {
            return (
              <React.Fragment key={messageId}>
                <Box
                  component="span"
                  sx={STYLES.timelineIndicator}
                  key={`timeline-${messageId}`}
                />
                <MessageBubble
                  key={messageId}
                  message={adaptMessageForBubble(message)}
                  userName={userName}
                />
              </React.Fragment>
            );
          } else {
            const bubbleMessage = adaptMessageForBubble(message);
            const showThinkingTime =
              message.sender === 'ai' && previousMessage?.sender === 'user';
            const showTimelineIndicator =
              message.sender === 'user' &&
              (previousMessage?.sender === 'ai' ||
                previousMessage?.sender === 'system');

            return (
              <React.Fragment key={messageId}>
                {showTimelineIndicator && (
                  <Box
                    sx={STYLES.timelineIndicator}
                    key={`timeline-${messageId}`}
                  />
                )}
                {showThinkingTime && (
                  <ThinkingTime
                    startTime={previousMessage.timestamp.getTime()}
                    endTime={message.timestamp.getTime()}
                  />
                )}
                <Box
                  sx={{
                    width: '100%',
                  }}
                >
                  <MessageBubble message={bubbleMessage} userName={userName} />

                  {/* Render CommandItems after the AI message bubble */}
                  {message.sender === 'ai' &&
                    message.commandRequests &&
                    message.commandRequests.length > 0 && (
                      <Box
                        sx={{
                          pb: 1,
                          pt: 1.25,
                          px: 1,
                          width: 'auto',
                          marginTop: '-6px',
                          zIndex: 1,
                          position: 'relative',
                          backgroundColor: (theme) =>
                            `${theme.palette.grey10}80`,
                          border: (theme) =>
                            `0.5px solid ${theme.palette.grey30}`,
                          borderBottomRightRadius: '10px',
                          borderBottomLeftRadius: '10px',
                        }}
                      >
                        <Typography variant="body3" color="grey50">
                          <Trans context="Suggested action the AI agent would like to take for user to approve">
                            Suggested actions:
                          </Trans>
                        </Typography>
                        {message.commandRequests.map((commandRequest) => {
                          return (
                            <CommandItem
                              key={commandRequest.command.id}
                              commandRequest={commandRequest}
                              onApprove={() =>
                                handleApproveCommand(commandRequest)
                              }
                              onReject={() =>
                                handleRejectCommand(commandRequest)
                              }
                              expanded={
                                expandedCommands[commandRequest.command.id]
                              }
                              onToggleExpand={() =>
                                toggleCommandExpand(commandRequest.command.id)
                              }
                            />
                          );
                        })}
                      </Box>
                    )}
                </Box>
              </React.Fragment>
            );
          }
        })}

        {isLoading && <ThinkingTime startTime={Date.now()} />}

        {/* Show precanned messages if applicable */}
        {showPrecannedMessages &&
          precannedMessages &&
          precannedMessages.length > 0 && (
            <Box sx={{ mt: 2 }}>
              <PrecannedMessageSuggestions
                intro={precannedMessagesIntro}
                messages={precannedMessages}
                onSelect={handlePrecannedMessageSelect}
              />
            </Box>
          )}

        <div ref={messagesEndRef} />
      </Box>

      <QuestionInput
        onSubmit={handleSubmit}
        isSubmitting={isLoading}
        errorMessage={error?.message}
      />
    </Paper>
  );
};

// Wrapper for the actual chat agent. Here we check for feature registration.
// Mostly an ergonomic thing so that developer doesn't have to stress about
// feature registration happening before the component is mounted.
export const AIAgentChat = (props: ExtendedAIChatOptions) => {
  const { featureId } = props;
  const registry = useAIAgentRegistry();
  const [isRegistered, setIsRegistered] = useState(
    () => registry.getRegisteredFeature(featureId) !== undefined
  );

  useEffect(() => {
    const unsubscribe = registry.subscribeToRegistry(
      featureId,
      (isRegistered) => {
        setIsRegistered(isRegistered);
      }
    );

    return unsubscribe;
  }, [featureId, registry]);

  if (!isRegistered) {
    return (
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflow: 'hidden',
          borderRadius: 0,
          boxShadow: (theme) => theme.shadows[2],
        }}
      >
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.grey05,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            flexDirection: 'column',
          }}
        >
          <CircularProgress size={24} />
        </Box>
      </Paper>
    );
  }

  return <AIAgentChatInner {...props} />;
};
