import create, { StateCreator, useStore as useZustandStore } from 'zustand';
import { Draft } from 'immer';
import { immer } from 'zustand/middleware/immer';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import type {} from '@redux-devtools/extension'; // required for devtools typing
import {
  createContext,
  useContext,
  useRef,
  ReactNode,
  createElement,
} from 'react';

export type SetState<State> = (
  updater: ((draft: Draft<State>) => void) | Partial<State>,
  shouldReplace?: boolean | undefined,
  actionName?: string
) => void;

/**
 * makeImmerAction
 * A helper function to create an action that uses immer to update the store.
 *
 * @param set - The set function from the zustand store.
 * @param action - The action to be performed.
 * @param onActionComplete - An optional callback that is called after the action
 *   has been applied.
 */
export type ImmerActionFn<
  State,
  Updater extends (...updaterArgs: Array<any>) => void,
> = (draft: Draft<State>) => Updater;
export function makeImmerAction<
  State,
  Updater extends (...updaterArgs: Array<any>) => void,
>(
  set: SetState<State>,
  action: ImmerActionFn<State, Updater>,
  onActionComplete?: (
    draft: Draft<State>,
    actionName: string,
    actionArgs: Array<any>
  ) => void
): (...actionArgs: Parameters<Updater>) => void {
  return (...actionArgs: Parameters<Updater>) => {
    return set(
      (draft) => {
        const result = action(draft)(...actionArgs);
        onActionComplete?.(draft, action.name, actionArgs);
        return result;
      },
      undefined,
      action.name
    );
  };
}

/**
 * Creates a zustand-immer store with redux-devtools, if in dev mode.
 */
export type ImmerStateCreator<StateWithActions> = StateCreator<
  StateWithActions,
  [
    ['zustand/devtools', never],
    ['zustand/immer', never],
    ['zustand/subscribeWithSelector', never],
  ],
  [
    ['zustand/subscribeWithSelector', never],
    ['zustand/immer', never],
    ['zustand/devtools', never],
  ],
  StateWithActions
>;

export function createImmerStore<StateWithActions>(
  stateCreator: ImmerStateCreator<StateWithActions>
) {
  return create<StateWithActions>()(
    devtools(immer(subscribeWithSelector(stateCreator)), {
      enabled: process.env.NODE_ENV === 'development',
    })
  );
}

/**
 * Creates a vanilla Zustand store with immer middleware that can be
 * scoped to a part of the React tree using React Context.
 *
 * @example
 * ```
 * // store.ts
 * const { useStore, StoreProvider } = createScopedImmerStore((set) => ({
 *   count: 0,
 *   increment: set((state) => { state.count += 1 }),
 * }));
 *
 * // app.tsx
 * const App = () => (
 *   <StoreProvider>
 *     <Counter />
 *   </StoreProvider>
 * );
 *
 * // counter.tsx
 * const Counter = () => {
 *   const count = useStore((state) => state.count);
 *   const increment = useStore((state) => state.increment);
 *
 *   return (
 *     <button onClick={increment}>Count: {count}</button>
 *   );
 * };
 * ```
 */
export function createScopedImmerStore<StateWithActions>(
  stateCreator: ImmerStateCreator<StateWithActions>
) {
  // Create the vanilla store with the same middleware configuration
  const createStore = () =>
    create<StateWithActions>()(
      devtools(immer(subscribeWithSelector(stateCreator)), {
        enabled: process.env.NODE_ENV === 'development',
      })
    );

  // Create a context to hold the store
  const StoreContext = createContext<ReturnType<typeof createStore> | null>(
    null
  );

  // Provider component that creates the store instance and provides it via context
  const StoreProvider = ({ children }: { children: ReactNode }) => {
    // Create the store on first render
    const storeRef = useRef<ReturnType<typeof createStore>>();
    if (!storeRef.current) {
      storeRef.current = createStore();
    }

    return createElement(
      StoreContext.Provider,
      { value: storeRef.current },
      children
    );
  };

  // Hook to use the store from context with a selector
  const useStore = <T>(
    selector: (state: StateWithActions) => T,
    equalityFn?: (a: T, b: T) => boolean
  ): T => {
    const store = useContext(StoreContext);
    if (!store) {
      throw new Error('useStore must be used within a StoreProvider');
    }

    return useZustandStore(store, selector, equalityFn);
  };

  return {
    useStore,
    StoreProvider,
    StoreContext,
  };
}
