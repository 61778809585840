import React, { useState, useEffect } from 'react';
import { Stack, Box, SxProps, Theme, Typography } from '@mui/material';
import Button from '@watershed/ui-core/components/Button';
import Tooltip from '@mui/material/Tooltip';
import { useLingui } from '@lingui/react/macro';
import { tooltipDarkStyleProps } from '@watershed/style/styleUtils';
import CloseIcon from '@watershed/icons/components/Close';
import BrowserRefreshIcon from '@watershed/icons/components/BrowserRefresh';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import { InternalUseBanner } from './components/InternalUseBanner';

export interface HelpHeaderProps {
  title?: string;
  internalOnly?: boolean;
  isCollapsed: boolean;
  onCollapsedChange: (collapsed: boolean) => void;
  onResetChat?: () => void;
  showResetButton?: boolean;
  sx?: SxProps<Theme>;
  isContained?: boolean;
  scrollContainerRef?: React.RefObject<HTMLDivElement> | null;
}

const headerSx = (isContained: boolean, showBlur: boolean): SxProps<Theme> => ({
  position: 'relative',
  zIndex: 1000,
  paddingY: 1,
  pl: 2,
  pr: 1.5,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderTopRightRadius: isContained ? '0px' : '10px',
  borderTopLeftRadius: isContained ? '0px' : '10px',
  borderTop: 0,
  borderRight: 0,
  borderLeft: 0,
  gap: 1,
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: -40,
    left: 0,
    right: 0,
    height: '40px',
    background: (theme) =>
      showBlur
        ? `linear-gradient(to bottom, ${theme.palette.grey05}, ${theme.palette.grey05}00)`
        : 'transparent',
    pointerEvents: 'none',
    zIndex: -1,
    // More intense mask that starts completely opaque
    WebkitMaskImage:
      'linear-gradient(to bottom, rgba(0,0,0,1) 20%, rgba(0,0,0,0.7) 50%, rgba(0,0,0,0))',
    maskImage:
      'linear-gradient(to bottom, rgba(0,0,0,1) 20%, rgba(0,0,0,0.7) 50%, rgba(0,0,0,0))',
    backdropFilter: showBlur ? 'blur(4px)' : 'none', // Increased blur intensity
  },
});

// Separate static styles
const STYLES = {
  title: {
    color: 'grey70',
    flexGrow: 1,
    gap: 1,
    display: 'flex',
    alignItems: 'center',
  },

  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  },

  actionButton: {
    color: 'grey50',
    '&:hover': {
      color: 'grey70',
    },
  },
};

export function HelpHeader({
  title,
  onCollapsedChange,
  onResetChat,
  showResetButton = false,
  isContained = false,
  scrollContainerRef,
}: HelpHeaderProps) {
  const { t } = useLingui();
  const [showBlur, setShowBlur] = useState(false);

  useEffect(() => {
    if (!scrollContainerRef?.current) return;

    const handleScroll = () => {
      if (scrollContainerRef.current) {
        // Show shadow when scrolled down at all
        setShowBlur(scrollContainerRef.current.scrollTop > 0);
      }
    };

    // Add scroll event listener to the container
    const scrollContainer = scrollContainerRef.current;
    scrollContainer.addEventListener('scroll', handleScroll);

    // Check initial scroll position
    handleScroll();

    // Clean up
    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, [scrollContainerRef]);

  const handleReset = () => {
    if (onResetChat) {
      onResetChat();
    }
  };

  return (
    <Box ref={scrollContainerRef} sx={headerSx(isContained, showBlur)}>
      <Stack direction="row" gap={1} alignItems="center">
        {title && (
          <Typography variant="h4" sx={STYLES.title}>
            {title}
          </Typography>
        )}
      </Stack>

      <InternalUseBanner />

      <Box sx={STYLES.buttonsContainer}>
        {showResetButton && onResetChat && (
          <Tooltip
            slotProps={tooltipDarkStyleProps()}
            title={t({
              message: 'Reset',
              context: 'Tooltip text to reset the help chat conversation',
            })}
            arrow={false}
            placement="top"
          >
            <Button
              isIcon
              variant="text"
              onClick={handleReset}
              sx={STYLES.actionButton}
              aria-label="Reset"
              data-testid={TestIds.AIResetButton}
            >
              <BrowserRefreshIcon size={14} />
            </Button>
          </Tooltip>
        )}

        <Tooltip
          slotProps={tooltipDarkStyleProps()}
          title={t({
            message: 'Close',
            context: 'Tooltip text to close help panel',
          })}
          arrow={false}
          placement="top"
        >
          <Button
            isIcon
            variant="text"
            onClick={() => onCollapsedChange(true)}
            sx={STYLES.actionButton}
            aria-label="Close"
            data-testid={TestIds.AICloseButton}
          >
            <CloseIcon size={14} color="grey50" />
          </Button>
        </Tooltip>
      </Box>
    </Box>
  );
}
