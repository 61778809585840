import Typography from '@mui/material/Typography';
import { Trans } from '@lingui/react/macro';

import { CODE_FONT_FAMILY } from '@watershed/style/styleUtils';

export const InternalUseBanner = ({}) => {
  return (
    <Typography
      variant="caption"
      fontFamily={CODE_FONT_FAMILY}
      fontSize={11}
      textAlign="center"
      textTransform="uppercase"
      sx={{
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        top: '13px',
        backgroundColor: (theme) => theme.palette.grey100,
        width: 'fit-content',
        borderRadius: '100px',
        color: (theme) => theme.palette.white,
        px: 1,
        py: 0.25,
        userSelect: 'none',
      }}
    >
      <Trans comment="Label indicating that container is for internal use only">
        Internal use only
      </Trans>
    </Typography>
  );
};
