import gql from 'graphql-tag';
import { useState, useCallback, useEffect } from 'react';
import { useAiAgentProcessMessageQuery } from '../../generated/urql';
import {
  CommandExecutionResult,
  ChatMessage,
  CommandRequest,
} from '@watershed/shared-universal/aiAgent/types';
import { useAIAgentRegistry } from './AIAgentContext';
import { UnexpectedError } from '@watershed/errors/UnexpectedError';

// GraphQL query for AI agent message processing
gql`
  query AIAgentProcessMessage(
    $message: String!
    $featureId: String!
    $context: JSONString!
    $chatHistory: JSONString
    $commandResults: JSONString
  ) @withOwner(owner: SupplyChain) {
    aiAgentProcessMessage(
      message: $message
      featureId: $featureId
      context: $context
      chatHistory: $chatHistory
      commandResults: $commandResults
    ) {
      sender
      content
      commandRequests {
        command {
          id
          actionName
          args
        }
        status
      }
    }
  }
`;

interface UseAIAgentChatOptions {
  featureId: string;
}

interface UseAIAgentChatResult {
  messages: Array<ChatMessage>;
  isLoading: boolean;
  error: Error | null;
  sendMessage: (message: string) => Promise<void>;
  clearMessages: () => void;
  approveCommandRequest: (commandRequest: CommandRequest) => Promise<void>;
  rejectCommandRequest: (commandRequest: CommandRequest) => void;
}

export function useAIAgentChat({
  featureId,
}: UseAIAgentChatOptions): UseAIAgentChatResult {
  const [messages, setMessages] = useState<Array<ChatMessage>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // This is for system-level error, vs we may also have message-specific errors handled in the message processing
  const [error, setError] = useState<Error | null>(null);

  const [pendingMessage, setPendingMessage] = useState<string | null>(null);
  const [pendingCommandResults, setPendingCommandResults] =
    useState<Array<CommandExecutionResult> | null>(null);

  const aiAgentRegistry = useAIAgentRegistry();

  const approveCommandRequest = useCallback(
    async (commandRequest: CommandRequest) => {
      setIsLoading(true);

      const commandToExecute = commandRequest.command;

      if (!commandToExecute) {
        throw new UnexpectedError(
          'Command not found. Should not happen that a command request has no command'
        );
      }

      // Add a system message for the command execution
      const commandExecutionMessage: ChatMessage = {
        sender: 'system',
        content: `Executing command: ${commandToExecute.actionName}`,
        timestamp: new Date(),
        commandRequests: [commandRequest],
        isCommandResult: false,
        commandStatus: 'EXECUTING',
      };

      setMessages((prev) => [...prev, commandExecutionMessage]);

      // Execute the command
      const result = await aiAgentRegistry.executeCommands(
        [commandToExecute],
        featureId
      );
      commandRequest.status = 'EXECUTED';

      // Add a system message for the command result
      const commandResultMessage: ChatMessage = {
        sender: 'user',
        content: `${JSON.stringify(result[0].result, null, 2)}`,
        timestamp: new Date(),
        commandRequests: [commandRequest],
        commandId: commandRequest.command.id,
        isCommandResult: true,
        commandStatus: 'EXECUTED',
      };

      setMessages((prev) => [...prev, commandResultMessage]);

      commandRequest.result = {
        command: commandToExecute,
        status: 'EXECUTED',
        result,
      };

      // Send the command result back to the AI
      setPendingCommandResults([
        {
          command: commandToExecute,
          status: 'EXECUTED',
          result: result[0].result,
        },
      ]);
    },
    [setIsLoading, featureId, aiAgentRegistry]
  );

  const rejectCommandRequest = useCallback(
    (commandRequest: CommandRequest) => {
      commandRequest.status = 'REJECTED';

      // Add a system message for the command rejection
      const rejectionMessage: ChatMessage = {
        sender: 'system',
        content: `Command rejected: ${commandRequest.command.actionName}`,
        timestamp: new Date(),
        commandRequests: [commandRequest],
        commandId: commandRequest.command.id,
        isCommandResult: true,
        commandStatus: 'REJECTED',
      };

      setMessages((prev) => [...prev, rejectionMessage]);
    },
    [setMessages]
  );

  const [{ fetching, data, error: queryError }] = useAiAgentProcessMessageQuery(
    {
      pause: pendingMessage === null && pendingCommandResults === null,
      variables: {
        message: pendingMessage || '',
        featureId,
        context: JSON.stringify(aiAgentRegistry.prepareAIContext(featureId)),
        chatHistory: JSON.stringify(
          messages.filter((msg) => msg.sender !== 'system')
        ),
        commandResults: JSON.stringify(pendingCommandResults),
      },
    }
  );

  // Process AI agent responses when they come in
  useEffect(() => {
    const processQueryResult = async () => {
      if (!fetching && data && (pendingMessage || pendingCommandResults)) {
        const aiResponse = {
          ...data.aiAgentProcessMessage,
          timestamp: new Date(),
        };

        setMessages((prev) => {
          const newMessages = [...prev, aiResponse];
          return newMessages;
        });

        setPendingMessage(null);
        setPendingCommandResults(null);
        setIsLoading(false);
      }
    };

    void processQueryResult();
  }, [data, fetching, featureId, pendingMessage, pendingCommandResults]);

  // Handle query errors
  useEffect(() => {
    if (queryError) {
      setError(new Error(queryError.message));
      setPendingMessage(null);
      setPendingCommandResults(null);
      setIsLoading(false);
    }
  }, [queryError]);

  const sendMessage = useCallback(async (message: string) => {
    setIsLoading(true);
    setError(null);

    // Add user message to chat
    const userMessage: ChatMessage = {
      sender: 'user',
      content: message,
      timestamp: new Date(),
      commandRequests: [],
    };
    setMessages((prev) => [...prev, userMessage]);

    // Set the pending message to trigger the query
    setPendingMessage(message);
  }, []);

  const clearMessages = useCallback(() => {
    setMessages([]);
  }, []);

  if (pendingMessage !== null && pendingCommandResults !== null) {
    throw new UnexpectedError(
      'User entered a message and completed a tool command at the same time. Should not happen and is not supported.'
    );
  }

  return {
    messages,
    isLoading: isLoading || fetching,
    error,
    sendMessage,
    clearMessages,
    approveCommandRequest,
    rejectCommandRequest,
  };
}
