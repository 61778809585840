/* @skip-file-for-translation */

import invariant from 'invariant';
import { Draft, Immutable } from 'immer';
import {
  createImmerStore,
  ImmerActionFn,
  ImmerStateCreator,
  makeImmerAction,
} from '@watershed/shared-frontend/components/zustand/utils';
import {
  ProductionGraphNode,
  ImpactCategory,
  ProductionGraphEditAction,
  InputRate,
} from '@watershed/shared-universal/productionGraph/types';
import { recalculateEmissionsAndOutputAmountsInPlace } from '@watershed/shared-universal/productionGraph/utils';
import assertNever from '@watershed/shared-util/assertNever';
import { v4 as uuidv4 } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';
import { BadInputError } from '@watershed/errors/BadInputError';
import { BadDataError } from '@watershed/errors/BadDataError';
import {
  getCumulativeImpactsForNode,
  getImpactForNode,
} from '@watershed/shared-universal/productionGraph/utils';
import { getSubgraphForNode } from '@watershed/shared-universal/productionGraph/utils';

// Include all nodes or omit process inputs and transportation nodes
export enum ViewMode {
  Simple = 'simple',
  Detailed = 'detailed',
}

// Same as flowchart node type enum at the moment, but we may want to diverge
// in the future
export enum ViewNodeTypeSelection {
  Facility = 'facility',
  Process = 'process',
}

// New type for view-specific node properties
export type ProductionGraphNodeView = {
  isExpanded: boolean;
  isVisible: boolean;
};

export type NodeEditStatus = {
  countOfModifiedFields: number;
  isDeleted: boolean;
  isNew: boolean;
};

export type ViewSettings = {
  viewMode: ViewMode;
  viewNodeType: ViewNodeTypeSelection;
  cumulativeEmissionsCutoffRatio: number;
  impactCategory: ImpactCategory;
  useBoxShadow: boolean;
};

// Required fields for all node types
export type RequiredNodeData = {
  name: string;
  downstream_node_identifier: string;
  output_material_name: string;
  input_rates: Array<{
    child_node_identifier: string;
    rate: number;
    unit_child_per_unit: string;
  }>;
};

export type AddNodeData = Omit<ProductionGraphNode, 'inputRates'> & {
  outputAmount: number;
  outputAmountUnit: string;
};

type ActionGroup = {
  id: string;
  shouldApply: boolean;
  actions: Array<ProductionGraphEditAction>;
  agent: ActionGroupAgent;
};

type ActionGroupAgent = {
  type: 'human' | 'ai';
};

export type State = {
  productionGraphData: Array<ProductionGraphNode>;
  originalProductionGraphData: Array<ProductionGraphNode>;
  selectedNode: ProductionGraphNode | null;
  nodeMap: Record<string, ProductionGraphNode>;
  nodeEditStatusMap: Record<string, NodeEditStatus>;
  nodeViewMap: Record<string, ProductionGraphNodeView>; // New map for view-specific node data
  viewSettings: ViewSettings;
  maxAutoExpandTier: number;
  isEditing: boolean;
  isSidebarOpen: boolean;
  latestAction: {
    name: string;
    payload?: unknown;
  };
  stagedEdits: Array<ActionGroup>;
  lifecycleAssessmentId: string | null;
  setLifecycleAssessmentId: (id: string) => void;
} & Immutable<{
  latestAction: {
    name: string;
    args: Array<unknown>;
    triggers: {};
  };
}>;

type Actions = {
  // Initialization
  initializeProductionGraphData: (data: Array<ProductionGraphNode>) => void;
  // UI actions
  selectNodeById: (id: string | null) => void;
  applyViewSettings: (viewSettings: ViewSettings) => void;
  setIsEditing: (isEditing: boolean) => void;
  setSidebarOpen: (isOpen: boolean) => void;

  // View-related actions
  expandNode: (nodeId: string) => void;
  collapseNode: (nodeId: string) => void;
  resetExpandedNodesToTier: (tier: number) => void;

  // Editing actions
  stageActions: (
    action: Array<ProductionGraphEditAction>,
    agent: ActionGroupAgent
  ) => void;
  deleteNode: (
    nodeId: string,
    option: DeleteOption,
    agent: ActionGroupAgent
  ) => void;
  addNode: (
    newNode: AddNodeData,
    option: 'insert' | 'new_input',
    baseNodeId: string,
    agent: ActionGroupAgent
  ) => void;
  removeActionGroup: (groupId: string) => void;
  revertToActionGroup: (groupId: string) => void;
  replaceNodeWithTree: (
    nodeId: string,
    tree: Array<ProductionGraphNode>,
    agent: ActionGroupAgent
  ) => void;

  // Tagging actions
  tagSubgraph: (nodeId: string, tag: string, mode: 'add' | 'remove') => void;
};

type StateWithActions = State & Actions;

export type DeleteOption = 'delete_upstream' | 'pass_through';

/**
 * ===---===---===---===---===---===---===---===---===---===
 * START action definitions
 * ===---===---===---===---===---===---===---===---===---===
 */
/**
 * Updates the visibility of nodes based on tier and expansion state
 */
const filterNodesByCumulativeEmissionsCutoff = (state: Draft<State>) => {
  // Apply cumulative emissions cutoff if set
  if (state.viewSettings.cumulativeEmissionsCutoffRatio > 0) {
    const totalImpact = state.productionGraphData.reduce((acc, node) => {
      return (
        acc + (getImpactForNode(node, state.viewSettings.impactCategory) ?? 0)
      );
    }, 0);

    // Hide nodes with cumulative emissions below the cutoff
    Object.entries(state.nodeViewMap).forEach(([nodeId, viewNode]) => {
      if (viewNode.isVisible) {
        const node = state.nodeMap[nodeId];
        const nodeCumulativeImpact =
          getCumulativeImpactsForNode(
            node,
            state.viewSettings.impactCategory
          ) ?? 0;
        const impactRatio =
          totalImpact > 0 ? nodeCumulativeImpact / totalImpact : 0;

        if (impactRatio < state.viewSettings.cumulativeEmissionsCutoffRatio) {
          viewNode.isVisible = false;
        }
      }
    });
  }
};

const initializeProductionGraphData =
  (state: Draft<State>) => (data: Array<ProductionGraphNode>) => {
    // Store original data for reverting
    state.originalProductionGraphData = data;
    state.isSidebarOpen = false;
    state.isEditing = false;
    state.stagedEdits = [];
    state.selectedNode = null;
    state.nodeViewMap = {};
    resetToProductionGraphData(state)(data);
  };

const applyViewSettings =
  (state: Draft<State>) => (viewSettings: ViewSettings) => {
    // Update the view settings
    state.viewSettings = viewSettings;

    // Update node visibility based on the new settings
    // This will respect user-defined expansions/collapses
    updateNodeVisibility(state)();
  };

const setIsEditing = (state: Draft<State>) => (isEditing: boolean) => {
  state.isEditing = isEditing;
  if (!isEditing) {
    // Revert back to the original state when exiting edit mode
    resetToProductionGraphData(state)(state.originalProductionGraphData);
    state.stagedEdits = [];
  }
};

const selectNodeById = (state: Draft<State>) => (id: string | null) => {
  if (id === null) {
    state.selectedNode = null;
    return;
  }

  state.selectedNode = state.nodeMap[id] ?? null;
};

const updateCurrentStateWithStagedEdits = (state: Draft<State>) => {
  // Start fresh from original data
  state.productionGraphData = cloneDeep(state.originalProductionGraphData);
  state.nodeMap = {};
  state.nodeEditStatusMap = {};

  // Initialize maps with original data
  state.productionGraphData.forEach((node) => {
    state.nodeMap[node.identifier] = node;
    state.nodeEditStatusMap[node.identifier] = {
      countOfModifiedFields: 0,
      isDeleted: false,
      isNew: false,
    };
  });

  const deletedNodeIds: Set<string> = new Set();

  // Apply each staged edit group in order
  state.stagedEdits.forEach((group) => {
    group.actions.forEach((action) => {
      const actionType = action.type;
      switch (actionType) {
        case 'DeleteNode': {
          // Note we don't actually delete it so that user can still see it and potentially revert the delete
          // Once they save, it will be actually deleted
          if (state.nodeEditStatusMap[action.nodeId]) {
            state.nodeEditStatusMap[action.nodeId].isDeleted = true;
          }
          deletedNodeIds.add(action.nodeId);
          break;
        }
        case 'EditNode': {
          if (!state.nodeMap[action.nodeId]) {
            throw new BadInputError(
              `Node ${action.nodeId} not found in nodeMap. Cannot edit a non-existent node.`
            );
          }
          // Node was deleted, so it doesn't make sense to update it
          if (state.nodeEditStatusMap[action.nodeId].isDeleted) {
            return;
          }
          const nodeIndex = state.productionGraphData.findIndex(
            (node) => node.identifier === action.nodeId
          );
          if (nodeIndex !== -1) {
            const newNode = {
              ...state.productionGraphData[nodeIndex],
              ...action.data,
            };

            state.productionGraphData[nodeIndex] = newNode;
            state.nodeMap[action.nodeId] = newNode;

            // Compare against original data to track number of modified fields
            const originalNode = state.originalProductionGraphData.find(
              (n) => n.identifier === action.nodeId
            );

            if (originalNode) {
              const modifiedFieldCount = Object.keys(newNode).filter(
                (key) =>
                  newNode[key as keyof ProductionGraphNode] !==
                  originalNode[key as keyof ProductionGraphNode]
              ).length;

              // Update edit status
              const editStatus = state.nodeEditStatusMap[action.nodeId];
              editStatus.countOfModifiedFields = modifiedFieldCount;
            }
          }
          break;
        }
        case 'AddNode': {
          const newNode = {
            ...action.data,
          };

          state.productionGraphData.push(newNode);
          state.nodeMap[newNode.identifier] = newNode;

          // Initialize edit status for new node
          state.nodeEditStatusMap[newNode.identifier] = {
            countOfModifiedFields: 0,
            isDeleted: false,
            isNew: true,
          };
          state.nodeViewMap[newNode.identifier] = {
            isExpanded: false,
            isVisible: true,
          };
          break;
        }
        default:
          assertNever(actionType);
      }
    });
  });

  // Recalculate all output amounts and emissions
  recalculateEmissionsAndOutputAmountsInPlace(state.nodeMap, deletedNodeIds);
};

const stageActions =
  (state: Draft<State>) =>
  (actions: Array<ProductionGraphEditAction>, agent: ActionGroupAgent) => {
    const actionGroup = {
      id: uuidv4(),
      shouldApply: true,
      actions,
      agent,
    };
    state.stagedEdits.push(actionGroup);
    updateCurrentStateWithStagedEdits(state);
  };

const setLifecycleAssessmentId =
  (state: Draft<State>) => (id: string | null) => {
    state.lifecycleAssessmentId = id;
  };

const deleteNode =
  (state: Draft<State>) =>
  (nodeId: string, option: DeleteOption, agent: ActionGroupAgent) => {
    const nodesToDelete = new Set<string>();
    const actions: Array<ProductionGraphEditAction> = [];
    const nodeToDelete = state.nodeMap[nodeId];

    switch (option) {
      case 'delete_upstream': {
        // Add the subgraph to the set of nodes to delete
        getSubgraphForNode(nodeId, state.nodeMap).forEach((node) => {
          nodesToDelete.add(node.identifier);
        });

        // Remove the target node from downstream node input rates
        if (nodeToDelete.downstreamNodeIdentifier) {
          const downstreamNode =
            state.nodeMap[nodeToDelete.downstreamNodeIdentifier];

          invariant(
            downstreamNode,
            `Graph pointers must be valid, received null downstream node for identifier: ${nodeToDelete.downstreamNodeIdentifier}`
          );

          actions.push({
            type: 'EditNode',
            nodeId: downstreamNode.identifier,
            data: {
              inputRates: downstreamNode.inputRates.filter(
                (inputRate) => inputRate.childNodeIdentifier !== nodeId
              ),
            },
          });
        }
        break;
      }

      case 'pass_through': {
        // Pass through mode: only delete the target node
        nodesToDelete.add(nodeId);

        // Update downstream connections for upstream nodes
        const nodeToDelete = state.productionGraphData.find(
          (node) => node.identifier === nodeId
        );

        if (!nodeToDelete) {
          throw new BadInputError(
            `Node ${nodeId} not found in production graph data. Cannot delete a non-existent node.`
          );
        }

        const upstreamNodes = nodeToDelete.inputRates.map(
          (inputRate) => state.nodeMap[inputRate.childNodeIdentifier]
        );
        upstreamNodes.forEach((node) => {
          actions.push({
            type: 'EditNode',
            nodeId: node.identifier,
            data: {
              downstreamNodeIdentifier: nodeToDelete.downstreamNodeIdentifier,
            },
          });
        });

        if (!nodeToDelete.downstreamNodeIdentifier) {
          throw new BadInputError(
            `Node ${nodeId} has no downstream node. Cannot delete the root node.`
          );
        }

        const downstreamNode =
          state.nodeMap[nodeToDelete.downstreamNodeIdentifier];

        if (!downstreamNode) {
          throw new BadDataError(
            `Graph pointers must be valid, received null downstream node for identifier: ${nodeToDelete.downstreamNodeIdentifier}`
          );
        }

        // Remove the input rate for the node being deleted
        const filteredInputRates = downstreamNode.inputRates.filter(
          (inputRate) => inputRate.childNodeIdentifier !== nodeId
        );

        // Find the input rate for the node being deleted
        const deletedNodeInputRate = downstreamNode.inputRates.find(
          (inputRate) => inputRate.childNodeIdentifier === nodeId
        );

        invariant(
          deletedNodeInputRate,
          'Given graph passed validation, input rate for downstream node must include node'
        );

        const rateOfDeletedNode = deletedNodeInputRate.rate;
        // Now propagate downwards all of the input rates for the upstream nodes
        const propagatedInputRates = nodeToDelete.inputRates.map(
          (upstreamRate) => {
            const upstreamNode =
              state.nodeMap[upstreamRate.childNodeIdentifier];
            if (!upstreamNode) {
              throw new BadDataError(
                `Graph pointers must be valid, received null upstream node for identifier: ${upstreamRate.childNodeIdentifier}`
              );
            }
            return {
              childNodeIdentifier: upstreamRate.childNodeIdentifier,
              rate: upstreamRate.rate * rateOfDeletedNode,
              unitChildPerUnit: `${upstreamNode.outputAmountUnit}/${downstreamNode.outputAmountUnit}`,
            };
          }
        );

        actions.push({
          type: 'EditNode',
          nodeId: downstreamNode.identifier,
          data: {
            inputRates: filteredInputRates.concat(propagatedInputRates),
          },
        });
        break;
      }
      default:
        assertNever(option);
    }

    // Stage deletion of nodes
    nodesToDelete.forEach((nodeId) => {
      actions.push({
        type: 'DeleteNode',
        nodeId,
      });
    });

    stageActions(state)(actions, agent);
  };

const removeActionGroup = (state: Draft<State>) => (groupId: string) => {
  state.stagedEdits = state.stagedEdits.filter((group) => group.id !== groupId);
  updateCurrentStateWithStagedEdits(state);
};

const revertToActionGroup = (state: Draft<State>) => (groupId: string) => {
  const index = state.stagedEdits.findIndex((group) => group.id === groupId);
  if (index !== -1) {
    state.stagedEdits = state.stagedEdits.slice(0, index + 1);
    updateCurrentStateWithStagedEdits(state);
  }
};

const setSidebarOpen = (state: Draft<State>) => (isOpen: boolean) => {
  state.isSidebarOpen = isOpen;
};

const editActionsForNodeInsertion = (
  state: Draft<State>,
  newNode: AddNodeData,
  baseNodeId: string
): Array<ProductionGraphEditAction> => {
  const actions: Array<ProductionGraphEditAction> = [];

  const downstreamNode = state.nodeMap[baseNodeId];

  // Modify the downstream node to replace all its input rates with only the new node
  if (downstreamNode) {
    const downstreamNodeInputRateForNewNode = {
      childNodeIdentifier: newNode.identifier,
      rate: newNode.outputAmount / downstreamNode.outputAmount,
      unitChildPerUnit: `${newNode.outputAmountUnit}/${downstreamNode.outputAmountUnit}`,
    };

    actions.push({
      type: 'EditNode',
      nodeId: downstreamNode.identifier,
      data: {
        inputRates: [downstreamNodeInputRateForNewNode],
      },
    });
  }

  // Iterate over all our upstream nodes
  const newNodeInputRates: Array<InputRate> = [];
  state.productionGraphData.forEach((n) => {
    if (n.downstreamNodeIdentifier === baseNodeId) {
      // Repoint their downstream node identifier to the new node
      actions.push({
        type: 'EditNode',
        nodeId: n.identifier,
        data: {
          downstreamNodeIdentifier: newNode.identifier,
        },
      });

      // And also infer the new node's input rates based on the upstream node's output amount
      newNodeInputRates.push({
        childNodeIdentifier: n.identifier,
        rate: n.outputAmount / newNode.outputAmount,
        unitChildPerUnit: `${n.outputAmountUnit}/${newNode.outputAmountUnit}`,
      });
    }
  });

  // Add the new node and include its connections upstream and downstream
  const nodeAction = {
    type: 'AddNode' as const,
    nodeId: newNode.identifier,
    data: {
      ...newNode,
      inputRates: newNodeInputRates,
      downstreamNodeIdentifier: baseNodeId,
    },
  };
  actions.unshift(nodeAction);

  return actions;
};

const editActionsForNewInput = (
  state: Draft<State>,
  newNode: AddNodeData,
  baseNodeId: string
): Array<ProductionGraphEditAction> => {
  const actions: Array<ProductionGraphEditAction> = [];

  const downstreamNode = state.nodeMap[baseNodeId];
  // Modify the downstream node to include the new node as an input
  if (downstreamNode) {
    const downstreamNodeInputRateForNewNode = {
      childNodeIdentifier: newNode.identifier,
      rate: newNode.outputAmount / downstreamNode.outputAmount,
      unitChildPerUnit: `${newNode.outputAmountUnit}/${downstreamNode.outputAmountUnit}`,
    };

    actions.push({
      type: 'EditNode',
      nodeId: downstreamNode.identifier,
      data: {
        inputRates: [
          ...downstreamNode.inputRates,
          downstreamNodeInputRateForNewNode,
        ],
      },
    });
  }

  // Add the new node and include its connections upstream and downstream
  const nodeAction = {
    type: 'AddNode' as const,
    nodeId: newNode.identifier,
    data: {
      ...newNode,
      inputRates: [],
      downstreamNodeIdentifier: baseNodeId,
    },
  };
  actions.unshift(nodeAction);

  return actions;
};

const addNode =
  (state: Draft<State>) =>
  (
    newNode: AddNodeData,
    option: 'insert' | 'new_input',
    baseNodeId: string,
    agent: ActionGroupAgent
  ) => {
    let actions: Array<ProductionGraphEditAction>;
    switch (option) {
      case 'insert':
        actions = editActionsForNodeInsertion(state, newNode, baseNodeId);
        break;
      case 'new_input':
        actions = editActionsForNewInput(state, newNode, baseNodeId);
        break;
      default:
        assertNever(option);
    }

    stageActions(state)(actions, agent);
  };

const expandNode = (state: Draft<State>) => (nodeId: string) => {
  // Set isExpanded in nodeViewMap
  state.nodeViewMap[nodeId].isExpanded = true;
  updateNodeVisibility(state)();
};

const collapseNode = (state: Draft<State>) => (nodeId: string) => {
  // Set isExpanded to false in nodeViewMap
  state.nodeViewMap[nodeId].isExpanded = false;

  const subgraph = getSubgraphForNode(nodeId, state.nodeMap);
  subgraph.forEach((node) => {
    state.nodeViewMap[node.identifier].isExpanded = false;
  });

  updateNodeVisibility(state)();
};

const resetExpandedNodesToTier = (state: Draft<State>) => (tier: number) => {
  // Reset expansion state for all nodes
  Object.values(state.nodeViewMap).forEach((viewNode) => {
    viewNode.isExpanded = false;
  });

  state.maxAutoExpandTier = tier;

  // Find the root node (node without a downstream node)
  const rootNode = state.productionGraphData.find(
    (node) => !node.downstreamNodeIdentifier
  );

  if (rootNode) {
    // Recursively expand nodes up to target tier
    const expandNodeAtTier = (nodeId: string, currentTier: number) => {
      if (currentTier >= tier) {
        // Stop any further expansion
        return;
      }

      // Set this node as expanded
      if (state.nodeViewMap[nodeId]) {
        state.nodeViewMap[nodeId].isExpanded = true;
      }

      // Find and expand upstream nodes
      const currentNode = state.nodeMap[nodeId];
      if (currentNode && currentNode.inputRates) {
        currentNode.inputRates.forEach((inputRate) => {
          expandNodeAtTier(inputRate.childNodeIdentifier, currentTier + 1);
        });
      }
    };

    // Start recursive expansion from root node
    expandNodeAtTier(rootNode.identifier, 0);
  }

  updateNodeVisibility(state)();
};

/**
 * Replaces a node and its upstream nodes with a new tree of nodes.
 * The root of the new tree will be connected to the downstream node of the replaced node.
 */
const replaceNodeWithTree =
  (state: Draft<State>) =>
  (
    nodeId: string,
    tree: Array<ProductionGraphNode>,
    agent: ActionGroupAgent
  ) => {
    const rootNodes = tree.filter((node) => !node.downstreamNodeIdentifier);
    if (rootNodes.length !== 1) {
      throw new BadInputError('Passed in tree should have exactly 1 root');
    }
    const rootNodeForNewTree = rootNodes[0];
    const nodeToReplace = state.nodeMap[nodeId];

    if (!nodeToReplace) {
      throw new BadInputError(
        `Node ${nodeId} not found in nodeMap. Cannot replace a non-existent node.`
      );
    }

    const nodeIdToPlantTreeOn = nodeToReplace.downstreamNodeIdentifier;

    // 1. Delete the existing node and its upstream nodes
    deleteNode(state)(nodeId, 'delete_upstream', agent);

    // 2. Stitch the node in
    rootNodeForNewTree.downstreamNodeIdentifier = nodeIdToPlantTreeOn;
    const actions: Array<ProductionGraphEditAction> = tree.map((node) => {
      return {
        type: 'AddNode',
        nodeId: node.identifier,
        data: node,
      };
    });

    if (nodeIdToPlantTreeOn) {
      const nodeToPlantTreeOn = state.nodeMap[nodeIdToPlantTreeOn];
      actions.push({
        type: 'EditNode',
        nodeId: nodeIdToPlantTreeOn,
        data: {
          inputRates: [
            ...nodeToPlantTreeOn.inputRates,
            {
              rate:
                rootNodeForNewTree.outputAmount /
                nodeToPlantTreeOn.outputAmount,
              childNodeIdentifier: rootNodeForNewTree.identifier,
              unitChildPerUnit: `${rootNodeForNewTree.outputAmountUnit}/${nodeToPlantTreeOn.outputAmountUnit}`,
            },
          ],
        },
      });
    }

    stageActions(state)(actions, agent);
  };

/**
 * ===---===---===---===---===---===---===---===---===---===
 * END action definitions
 * ===---===---===---===---===---===---===---===---===---===
 */

/**
 * ===---===---===---===---===---===---===---===---===---===
 * START helper functions
 * ===---===---===---===---===---===---===---===---===---===
 */
function resetToProductionGraphData(state: Draft<State>) {
  return function (data: Array<ProductionGraphNode>) {
    state.productionGraphData = cloneDeep(data);

    // Reset maps
    const nodeMap: Record<string, ProductionGraphNode> = {};
    const nodeEditStatusMap: Record<string, NodeEditStatus> = {};

    // Initialize maps with data
    state.productionGraphData.map((node) => {
      nodeMap[node.identifier] = node;
      nodeEditStatusMap[node.identifier] = {
        countOfModifiedFields: 0,
        isDeleted: false,
        isNew: false,
      };
    });

    // Recalculate all output amounts and emissions
    recalculateEmissionsAndOutputAmountsInPlace(nodeMap, new Set());

    state.nodeMap = nodeMap;
    state.nodeEditStatusMap = nodeEditStatusMap;

    // Initialize the view model
    // Create initial nodeViewMap with all nodes
    state.productionGraphData.forEach((node) => {
      state.nodeViewMap[node.identifier] = {
        isExpanded: false,
        isVisible: false,
      };
    });
    resetExpandedNodesToTier(state)(state.maxAutoExpandTier);
    // Update node visibility based on the current settings
    updateNodeVisibility(state)();
  };
}

function updateNodeVisibility(state: Draft<State>) {
  return function () {
    // Reset visibility
    Object.values(state.nodeViewMap).forEach((node) => {
      node.isVisible = false;
    });

    // Find the root node (node without a downstream node)
    const rootNode = state.productionGraphData.find(
      (node) => !node.downstreamNodeIdentifier
    );
    if (!rootNode) return;

    // Mark nodes as visible based on tier and expansion state
    const markVisible = (nodeId: string, currentTier: number) => {
      const node = state.nodeMap[nodeId];
      if (!node) return;

      const shouldBeExpanded = state.nodeViewMap[nodeId].isExpanded;

      // Update or create the view node
      if (!state.nodeViewMap[nodeId]) {
        state.nodeViewMap[nodeId] = {
          isExpanded: shouldBeExpanded,
          isVisible: true,
        };
      } else {
        state.nodeViewMap[nodeId].isVisible = true;
      }
      // If node is expanded, recurse upstream
      if (shouldBeExpanded) {
        // Process input rates to find upstream nodes
        if (node.inputRates && node.inputRates.length > 0) {
          node.inputRates.forEach((inputRate: InputRate) => {
            markVisible(inputRate.childNodeIdentifier, currentTier + 1);
          });
        }
      }
    };

    markVisible(rootNode.identifier, 0);
    filterNodesByCumulativeEmissionsCutoff(state);
  };
}

/* Returns the number of edits that have not been reviewed
 * We assume that an edit has been reviewed if there is an autogenerated action group
 * after it.
 * We care about this value being 0 to determine if we need to have AI review the edits
 * before saving.
 */
export function numberOfUnreviewedEdits(data: Array<ActionGroup>) {
  // Find the index of the last autogenerated action group
  const lastAutoIndex = data.findLastIndex(
    (action) => action.agent.type === 'ai'
  );

  // If no AI actions found, count all human (unreviewed) actions
  if (lastAutoIndex === -1) {
    return data.filter((action) => action.agent.type === 'human').length;
  }

  // Count human (unreviewed) actions that occur after the last AI action
  return data
    .slice(lastAutoIndex + 1)
    .filter((action) => action.agent.type === 'human').length;
}

export function getInitialStateData(): State {
  return {
    latestAction: { name: '', args: [], triggers: {} },
    productionGraphData: [],
    originalProductionGraphData: [],
    selectedNode: null,
    nodeMap: {},
    nodeEditStatusMap: {},
    nodeViewMap: {}, // Initialize empty nodeViewMap
    viewSettings: {
      viewMode: ViewMode.Detailed,
      viewNodeType: ViewNodeTypeSelection.Process,
      cumulativeEmissionsCutoffRatio: 0, // Default to 0 to show all nodes
      impactCategory: ImpactCategory.FossilEmissions,
      useBoxShadow: true,
    },
    maxAutoExpandTier: 2, // Default to showing 2 tiers (now as a separate property)
    stagedEdits: [],
    isEditing: false,
    isSidebarOpen: false,
    lifecycleAssessmentId: null,
    setLifecycleAssessmentId: () => {},
  } as const;
}

/**
 * Tags a node and all its upstream nodes with the specified tag
 */
const tagSubgraph =
  (state: Draft<State>) =>
  (nodeId: string, tag: string, mode: 'add' | 'remove' = 'add') => {
    // Find all upstream nodes recursively
    const nodesToTag = getSubgraphForNode(nodeId, state.nodeMap);

    // Add or remove the tag to all collected nodes and stage the edits
    const actions: Array<ProductionGraphEditAction> = [];
    nodesToTag.forEach((node) => {
      if (node) {
        const currentTags = node.tags || [];

        if (mode === 'add' && !currentTags.includes(tag)) {
          // Create a new tags array with the new tag
          const newTags = [...currentTags, tag];

          actions.push({
            type: 'EditNode',
            nodeId: node.identifier,
            data: {
              tags: newTags,
            },
          });
        } else if (mode === 'remove' && currentTags.includes(tag)) {
          // Create a new tags array without the specified tag
          const newTags = currentTags.filter((t) => t !== tag);

          actions.push({
            type: 'EditNode',
            nodeId: node.identifier,
            data: {
              tags: newTags,
            },
          });
        }
      }
    });

    // Stage all actions at once if there are any
    if (actions.length > 0) {
      stageActions(state)(actions, { type: 'human' });
    }
  };

const stateCreator: ImmerStateCreator<StateWithActions> = (set) => {
  const data = getInitialStateData();
  const makeAction = <Updater extends (...a: Array<any>) => void>(
    action: ImmerActionFn<State, Updater>
  ) =>
    makeImmerAction(set, action, (draft, actionName, actionArgs) => {
      draft.latestAction = {
        name: actionName,
        args: actionArgs,
        triggers: {},
      };
    });

  return {
    // Initial data.
    ...data,

    // Data initialization actions
    initializeProductionGraphData: makeAction(initializeProductionGraphData),

    // Edit actions
    setIsEditing: makeAction(setIsEditing),
    stageActions: makeAction(stageActions),
    deleteNode: makeAction(deleteNode),
    removeActionGroup: makeAction(removeActionGroup),
    revertToActionGroup: makeAction(revertToActionGroup),
    addNode: makeAction(addNode),
    replaceNodeWithTree: makeAction(replaceNodeWithTree),

    // View-related actions
    applyViewSettings: makeAction(applyViewSettings),
    expandNode: makeAction(expandNode),
    collapseNode: makeAction(collapseNode),
    resetExpandedNodesToTier: makeAction(resetExpandedNodesToTier),

    // Browsing actions
    selectNodeById: makeAction(selectNodeById),
    setSidebarOpen: makeAction(setSidebarOpen),

    // Tagging actions
    tagSubgraph: makeAction(tagSubgraph),

    // Additional actions.
    setLifecycleAssessmentId: makeAction(setLifecycleAssessmentId),
  };
};

export const useProductionGraphStore =
  createImmerStore<StateWithActions>(stateCreator);

export const dependenciesForTest = {
  stateCreator,
};
