import { useEffect } from 'react';
import { useLingui } from '@lingui/react/macro';
import {
  AIAgentChat,
  createAction,
  useAIAgentRegistry,
} from '../../../ai-service';
import {
  useProductionGraphStore,
  ViewMode,
  ViewNodeTypeSelection,
} from '../productionGraphStore';
import { createNodeWithRequiredData } from '@watershed/shared-universal/productionGraph/helpers';
import {
  queryGraph,
  getSubgraphForNode,
} from '@watershed/shared-universal/productionGraph/utils';
import { actionSchemas } from './actions';
import {
  ImpactCategory,
  ProductionGraphNode,
} from '@watershed/shared-universal/productionGraph/types';
import { PRODUCTION_GRAPH_AI_AGENT_PROMPT } from './productionGraphAIAgentPrompt';

const FEATURE_ID = 'production-graph';

export const ProductionGraphAIAgentWrapper = ({
  enabled,
  onCollapsedChange,
}: {
  enabled: boolean;
  onCollapsedChange?: (collapsed: boolean) => void;
}) => {
  const { t } = useLingui();
  const store = useProductionGraphStore();
  const productionGraphData = store.productionGraphData;
  const registry = useAIAgentRegistry();

  useEffect(() => {
    if (!enabled) return;

    const actions = [
      createAction({
        name: 'addNode',
        schema: actionSchemas.addNode,
        func: (args) => {
          store.addNode(
            createNodeWithRequiredData(args.newNode),
            args.option,
            args.baseNodeId,
            { type: 'ai' }
          );
          return {
            message: `Added new ${args.newNode.nodeType} node "${args.newNode.name}" ${args.option === 'insert' ? 'after' : 'as input to'} node ${args.baseNodeId}`,
            data: {
              success: true,
              nodeData: args.newNode,
            },
          };
        },
        comments:
          'Add a new node to the production graph. This can either insert a node in the flow (option: "insert") or add a new input to an existing node (option: "new_input"). The baseNodeId specifies the reference node to connect to. The newNode object should contain at minimum a name and nodeType, but can include other properties relevant to the node type. This will stage an add node action that can be committed or reverted. Follow the schema for nodes as provided from the information about the state. An example of using insert flow might be inserting a transporation node just above a node. An example of a new_input might be adding a new material input to a processing node.',
      }),
      createAction({
        name: 'deleteNode',
        schema: actionSchemas.deleteNode,
        func: (args) => {
          store.deleteNode(args.nodeId, args.deleteOption, { type: 'ai' });
          return {
            message: `Deleted node ${args.nodeId}`,
            data: { success: true },
          };
        },
        comments:
          'Delete a node by its identifier. This will stage a delete node action that can be committed or reverted. When deleting a node, its critical to consider all of its fields, and edit the fields in a way that would leave the node internally consistent. For example, we dont want a node with a facility address in Wisconsin but a country code in Canada. If you are unsure, you can ask the user for more information',
      }),
      createAction({
        name: 'editNode',
        schema: actionSchemas.editNode,
        func: (args) => {
          store.stageActions(
            [
              {
                type: 'EditNode',
                nodeId: args.nodeId,
                data: args.data,
              },
            ],
            { type: 'ai' }
          );
          return {
            message: `Edited node ${args.nodeId}`,
            data: { success: true },
          };
        },
        comments:
          'Edit a node by its identifier. Provide a partial node object with the properties you want to update. This will stage an edit action that can be committed or reverted. When editing a node, its critical to consider all of its fields, and edit the fields in a way that would leave the node internally consistent. For example, we dont want a node with a facility address in Wisconsin but a country code in Canada. If you are unsure, you can ask the user for more information',
      }),
      createAction({
        name: 'exploreGraph',
        schema: actionSchemas.exploreGraph,
        func: (args) => {
          const results = queryGraph(
            productionGraphData,
            {},
            args.subgraphOptions
          );
          return {
            message: `Found ${results.length} nodes matching the query`,
            data: results,
          };
        },
        comments:
          'Search the production graph for nodes matching specific criteria. Use this to explore the graph in the vicinity of the process you are working on.',
      }),
    ];

    // Define the state documentation
    const stateSummaryDocumentation = {
      productionGraphData: {
        description:
          'Array of nodes in the production graph (trimmed to essential properties) and also filtered to only include the subgraph of the selected node. If there is a selected node, and you cannot find what the user is asking about, you can try to use exploreGraph looking downstream to get more context.',
        schemaDescription: `Array<{
          identifier: string; // The unique identifier of the node
          name: string; // The name of the node
          nodeType: 'processing' | 'transportation' | 'material' | 'process_input'; // The type of the node
          lifecycleStage: 'A1' | 'A2' | 'A3' | 'A4'; // The lifecycle stage of the node
          downstreamNodeIdentifier: string | null; // The unique identifier of the parent node
          autobomTier: number | null; // Automatic bill of materials tier
          assumptions: string | null; // The assumptions made for the node
          edits: string | null; // Edit requests by the user to the node
          supplierName: string | null; // The name of the supplier
          tags: string[] | null; // Array of tags associated with the node
          
          // Facility information
          facilityName: string | null; // The name of the facility
          facilityLocation: string | null; // The location of the facility
          facilityCompany: string | null; // The company that owns the facility
          facilityDescription: string | null; // Description of the facility
          facilityAssumptions: string | null; // Assumptions made about the facility
          
          // Geographic information
          countryCodes: string[] | null; // Array of the country codes of the node
          
          // Input/output specifications
          inputRates: Array<{childNodeIdentifier: string, rate: number, unitChildPerUnit: string}>; // Array of the input rates
          outputMaterialName: string | null; // The name of the output material
          outputAmount: number; // The absolute amount of material outputted by the node
          outputAmountUnit: string; // The unit of the output amount
          
          // Transportation distances and units
          roadDistance: number | null; // Distance traveled by road
          railDistance: number | null; // Distance traveled by rail
          waterDistance: number | null; // Distance traveled by water
          airDistance: number | null; // Distance traveled by air
          roadDistanceUnit: string | null; // Unit for road distance
          railDistanceUnit: string | null; // Unit for rail distance
          waterDistanceUnit: string | null; // Unit for water distance
          airDistanceUnit: string | null; // Unit for air distance
          
          // Emissions data
          emissionsKgco2e: number | null; // The amount of fossil emissions attributed to the node
          cumulativeEmissionsKgco2e: number | null; // The amount of fossil emissions attributed to the node and all upstream nodes
          biogenicEmissionsKgco2e: number | null; // The amount of biogenic emissions attributed to the node
          cumulativeBiogenicEmissionsKgco2e: number | null; // The amount of biogenic emissions attributed to the node and all upstream nodes
          flagEmissionsKgco2e: number | null; // The amount of FLAG emissions attributed to the node
          cumulativeFlagEmissionsKgco2e: number | null; // The amount of FLAG emissions attributed to the node and all upstream nodes
          
          // Ecoinvent mappings
          ecoinventActivity: string | null; // The name of the ecoinvent activity that a material node is mapped to
          ecoinventUnit: string | null; // The unit of the ecoinvent activity
          ecoinventReferenceProductName: string | null; // The name of the ecoinvent reference product that a material node is mapped to
          ecoinventGeography: string | null; // The geography of the ecoinvent activity that a material node is mapped to
          ecoinventEmissionsFactor: number | null; // The emissions factor of the ecoinvent activity that a material node is mapped to
          ecoinventIsicClassification: string | null; // The ISIC classification of the ecoinvent activity that a material node is mapped to
          ecoinventCpcClassification: string | null; // The CPC classification of the ecoinvent activity that a material node is mapped to
          ecoinventHsCodeClassification: string | null; // The HS code classification of the ecoinvent activity that a material node is mapped to
          
          // Unit conversion
          conversionFactorValue: number | null; // The value of the conversion factor
          conversionFactorUnit: string | null; // The unit of the conversion factor
          conversionFactorCitation: string | null; // The explanation for how the conversion factor was derived
          conversionFactorList: string | null; // JSON string containing a list of conversion factors
          
          // Raw data and responses
          ecoinventActivitiesRaw: string | null; // Raw ecoinvent activities data
          mappedEcoinventActivity: string | null; // Mapped ecoinvent activity
          unitConversionResponse: string | null; // Response from unit conversion
          rawProcessInputsResponse: string | null; // Raw response for process inputs
        }>`,
        sampleValue: [
          {
            identifier: '27c0187b959e4b8bb7d13f504b975295',
            name: 'virgin_stabilizers_and_additives_transport',
            nodeType: 'transportation' as const,
            lifecycleStage: 'A2' as const,
            inputRates: [
              {
                childNodeIdentifier: 'a389bac1f38b43578344bdea870879d0',
                rate: 0.43,
                unitChildPerUnit: 'kg/kg',
              },
            ],
            downstreamNodeIdentifier: 'a389bac1f38b43578344bdea870879d0',
            autobomTier: 4,
            assumptions:
              'The transportation is done by truck over a distance of 1,800 km. The mass transported is 0.05kg of virgin stabilizers and additives.',
            supplierName: null,
            facilityName: null,
            facilityLocation: null,
            facilityCompany: null,
            facilityDescription: null,
            facilityAssumptions:
              'The transportation is from the BASF Florham Park Plant to the Revolution Stuttgart Arkansas Plant as stated in the transportation section.',
            countryCodes: 'US',
            outputMaterialName: null,
            outputAmount: 0.05,
            outputAmountUnit: 'kg',
            roadDistance: 1800,
            roadDistanceUnit: 'km',
            emissionsKgco2e: 0.012479588716014107,
            cumulativeEmissionsKgco2e: 0.012479588716014107,
            ecoinventActivity: 'transport, freight, lorry, unspecified',
            ecoinventIsicClassification: 'H: Transportation and storage',
            ecoinventCpcClassification: '65: Freight transport services',
            ecoinventEmissionsFactor: 0.24959177432028214,
          },
        ],
      },
      selectedNode: {
        description:
          'Currently selected node (trimmed to identifier and name), or null if no node is selected',
        schemaDescription: 'ProductionGraphNode | null',
        sampleValue: {
          identifier: 'node-1',
          name: 'Example Node',
        },
      },
      viewSettings: {
        description: 'Settings for how the graph is displayed',
        schemaDescription: `{
  viewMode: ViewMode; // 'simple' or 'detailed'
  viewNodeType: ViewNodeTypeSelection; // 'facility' or 'process'
  cumulativeEmissionsCutoffRatio: number; // 0-1 value for filtering nodes by emissions
  impactCategory: ImpactCategory; // Category of impact to visualize
  useBoxShadow: boolean; // Whether to use box shadows in the visualization
}`,
        sampleValue: {
          viewMode: ViewMode.Detailed,
          viewNodeType: ViewNodeTypeSelection.Process,
          cumulativeEmissionsCutoffRatio: 0,
          impactCategory: ImpactCategory.FossilEmissions,
          useBoxShadow: true,
        },
      },
      isEditing: {
        description: 'Whether the graph is in editing mode',
        schemaDescription: 'boolean',
        sampleValue: false,
      },
      stagedEdits: {
        description:
          'Array of edit action groups that have been staged but not yet saved',
        schemaDescription: `Array<{
  id: string; // Unique identifier for the action group
  shouldApply: boolean; // Whether the action group should be applied
  actions: Array<ProductionGraphEditAction>; // The actions in this group
  agent: { type: 'human' | 'ai' }; // Who created this action group
}>`,
        sampleValue: [],
      },
      nodeMap: {
        description: 'Map of node identifiers to nodes for quick lookup',
        schemaDescription: 'Record<string, ProductionGraphNode>',
        sampleValue: {
          'node-1': {
            identifier: 'node-1',
            name: 'Example Node',
            // ... other node properties
          },
        },
      },
      nodeViewMap: {
        description: 'Map of node identifiers to view-specific properties',
        schemaDescription: `Record<string, {
  isExpanded: boolean; // Whether the node is expanded to show its inputs
  isVisible: boolean; // Whether the node is visible in the current view
}>`,
        sampleValue: {
          'node-1': {
            isExpanded: true,
            isVisible: true,
          },
        },
      },
    };

    // Function to get a summary of the store to only include relevant data
    const getStateSummary = (fullStore: unknown) => {
      const typedStore = fullStore as typeof store;

      // Get the selected node ID
      const selectedNodeId = typedStore.selectedNode?.identifier;
      const nodesToInclude = selectedNodeId
        ? getSubgraphForNode(selectedNodeId, typedStore.nodeMap)
        : typedStore.productionGraphData;

      // Trim each node to only include essential properties
      const trimmedNodes = nodesToInclude.map((node) => {
        // Create a trimmed node with only the essential properties
        const trimmedNode: Partial<ProductionGraphNode> = {
          identifier: node.identifier,
          name: node.name,
          nodeType: node.nodeType,
          inputRates: node.inputRates,
          tags: node.tags || [],
        };
        return trimmedNode;
      });

      // Create the trimmed store
      const trimmedStore = {
        productionGraphData: trimmedNodes,
        selectedNode: typedStore.selectedNode
          ? {
              identifier: typedStore.selectedNode.identifier,
              name: typedStore.selectedNode.name,
            }
          : null,
        viewSettings: typedStore.viewSettings,
        isEditing: typedStore.isEditing,
        stagedEdits: typedStore.stagedEdits,
      };

      return trimmedStore;
    };

    registry.registerFeature({
      featureId: FEATURE_ID,
      stateSummaryDocumentation,
      actions,
      getStateSummary,
      store,
      genericComments: PRODUCTION_GRAPH_AI_AGENT_PROMPT,
    });

    return () => {
      registry.unregisterFeature(FEATURE_ID);
    };
  }, [enabled, store, productionGraphData, registry]);

  // TODO: Add precanned messages — need to work with the Prod. Graph team.
  return (
    <AIAgentChat
      featureId={FEATURE_ID}
      initialMessage={t({
        message:
          'Hi! I can help you navigate and edit the production graph. What would you like to do?',
        context: 'Initial message for the AI chat',
      })}
      onCollapsedChange={onCollapsedChange}
    />
  );
};
