import { TSchema } from '../../meta';
import { tInfer } from '../../types';

const NODE_TYPE_OPTIONS = [
  'processing',
  'transportation',
  'material',
  'process_input',
] as const;

const LIFECYCLE_STAGE_OPTIONS = ['A1', 'A2', 'A3', 'A4'] as const;

export const PRODUCTION_GRAPH_SCHEMA_NAME = 'production_graph';
export type ProductionGraphNodeRaw = tInfer<typeof ProductionGraphSchema>;
export type ProductionGraphNodeType = (typeof NODE_TYPE_OPTIONS)[number];

export const ProductionGraphSchema = {
  slug: `${PRODUCTION_GRAPH_SCHEMA_NAME}:6.2`,
  schema: {
    properties: {
      // Core node metadata
      identifier: {
        type: 'string',
        nullable: false,
        description: 'The unique identifier of the node',
      },
      name: {
        type: 'string',
        nullable: false,
        description: 'The name of the node',
        examples: ['Sorbitol production', 'Glucose transportation', 'Aluminum'],
      },
      node_type: {
        type: 'enum',
        nullable: false,
        description: 'The type of the node',
        options: NODE_TYPE_OPTIONS,
      },
      lifecycle_stage: {
        type: 'enum',
        nullable: false,
        description: 'The lifecycle stage of the node',
        options: LIFECYCLE_STAGE_OPTIONS,
      },
      downstream_node_identifier: {
        type: 'string',
        nullable: true,
        description: 'The unique identifier of the parent node',
      },
      autobom_tier: {
        type: 'number',
        nullable: true,
        description: 'Automatic bill of materials tier',
      },
      assumptions: {
        type: 'string',
        nullable: true,
        description: 'The assumptions made for the node',
      },
      edits: {
        type: 'string',
        nullable: true,
        description: 'Edit requests by the user to the node',
      },
      supplier_name: {
        type: 'string',
        nullable: true,
        description: 'The name of the supplier',
        examples: ['Acme Corp'],
      },
      tags: {
        type: 'string',
        nullable: true,
        description: 'JSON string of array of tags associated with the node',
        examples: ['["component1", "component2"]'],
      },

      // Facility information
      facility_name: {
        type: 'string',
        nullable: true,
        description: 'The name of the facility',
        examples: ["Tom's factory"],
      },
      facility_location: {
        type: 'string',
        nullable: true,
        description: 'The location of the facility',
      },
      facility_company: {
        type: 'string',
        nullable: true,
        description: 'The company that owns the facility',
      },
      facility_description: {
        type: 'string',
        nullable: true,
        description: 'Description of the facility',
      },
      facility_assumptions: {
        type: 'string',
        nullable: true,
        description: 'Assumptions made about the facility',
      },

      // Geographic information
      country_codes: {
        type: 'string',
        nullable: true,
        description: 'A JSON array of the country codes of the node',
        examples: ['["US", "CA"]'],
      },

      // Input/output specifications
      input_rates: {
        type: 'string',
        nullable: false,
        description:
          'A JSON array of the input rates. The schema is child_node_identifier: string, rate: number, unit_child_per_unit: string',
        examples: [
          '[{"child_node_identifier": "a1b2c3d4-e5f6-7890-abcd-ef1234567890", "rate": 10, "unit_child_per_unit": "kg/kg"}]',
        ],
      },
      output_material_name: {
        type: 'string',
        nullable: true,
        description: 'The name of the output material',
        examples: ['Sorbitol'],
      },

      // Output specifications are now present mainly for legacy reasons. Input rate should be the source of truth moving forward
      // and all other values can be derived from the input rates.
      output_amount: {
        type: 'number',
        nullable: false,
        description: 'The absolute amount of material outputted by the node',
        examples: [100],
      },
      output_amount_unit: {
        type: 'string',
        nullable: false,
        description: 'The unit of the output amount',
        examples: ['kg', 'tonne'],
      },

      // Transportation distances
      road_distance: {
        type: 'number',
        nullable: true,
        description: 'Distance traveled by road',
      },
      rail_distance: {
        type: 'number',
        nullable: true,
        description: 'Distance traveled by rail',
      },
      water_distance: {
        type: 'number',
        nullable: true,
        description: 'Distance traveled by water',
      },
      air_distance: {
        type: 'number',
        nullable: true,
        description: 'Distance traveled by air',
      },

      // Transportation units
      road_distance_unit: {
        type: 'string',
        nullable: true,
        description: 'Unit for road distance',
        examples: ['km', 'mi'],
      },
      rail_distance_unit: {
        type: 'string',
        nullable: true,
        description: 'Unit for rail distance',
        examples: ['km', 'mi'],
      },
      water_distance_unit: {
        type: 'string',
        nullable: true,
        description: 'Unit for water distance',
        examples: ['km', 'mi'],
      },
      air_distance_unit: {
        type: 'string',
        nullable: true,
        description: 'Unit for air distance',
        examples: ['km', 'mi'],
      },

      // Emissions data
      emissions_kgco2e: {
        type: 'number',
        nullable: true,
        description: 'The amount of fossil emissions attributed to the node',
        examples: [100],
      },
      cumulative_emissions_kgco2e: {
        type: 'number',
        nullable: true,
        description:
          'The amount of fossil emissions attributed to the node and all upstream nodes',
        examples: [100],
      },
      biogenic_emissions_kgco2e: {
        type: 'number',
        nullable: true,
        description: 'The amount of biogenic emissions attributed to the node',
        examples: [100],
      },
      cumulative_biogenic_emissions_kgco2e: {
        type: 'number',
        nullable: true,
        description:
          'The amount of biogenic emissions attributed to the node and all upstream nodes',
        examples: [100],
      },
      flag_emissions_kgco2e: {
        type: 'number',
        nullable: true,
        description: 'The amount of FLAG emissions attributed to the node',
        examples: [100],
      },
      cumulative_flag_emissions_kgco2e: {
        type: 'number',
        nullable: true,
        description:
          'The amount of FLAG emissions attributed to the node and all upstream nodes',
        examples: [100],
      },

      // Ecoinvent mappings
      ecoinvent_activity: {
        type: 'string',
        nullable: true,
        description:
          'The name of the ecoinvent activity that a material node is mapped to',
      },
      ecoinvent_unit: {
        type: 'string',
        nullable: true,
        description: 'The unit of the ecoinvent activity',
      },
      ecoinvent_reference_product_name: {
        type: 'string',
        nullable: true,
        description:
          'The name of the ecoinvent reference product that a material node is mapped to',
      },
      ecoinvent_geography: {
        type: 'string',
        nullable: true,
        description:
          'The geography of the ecoinvent activity that a material node is mapped to',
      },
      ecoinvent_emissions_factor: {
        type: 'number',
        nullable: true,
        description:
          'The emissions factor of the ecoinvent activity that a material node is mapped to',
      },
      ecoinvent_isic_classification: {
        type: 'string',
        nullable: true,
        description:
          'The ISIC classification of the ecoinvent activity that a material node is mapped to',
      },
      ecoinvent_cpc_classification: {
        type: 'string',
        nullable: true,
        description:
          'The CPC classification of the ecoinvent activity that a material node is mapped to',
      },
      ecoinvent_hs_code_classification: {
        type: 'string',
        nullable: true,
        description:
          'The HS code classification of the ecoinvent activity that a material node is mapped to',
      },

      // Unit conversion
      conversion_factor_value: {
        type: 'number',
        nullable: true,
        description: 'The value of the conversion factor',
      },
      conversion_factor_unit: {
        type: 'string',
        nullable: true,
        description: 'The unit of the conversion factor',
      },
      conversion_factor_citation: {
        type: 'string',
        nullable: true,
        description:
          'The explanation for how the conversion factor was derived',
      },
      conversion_factor_list: {
        type: 'string',
        nullable: true,
        description: 'JSON string containing a list of conversion factors',
      },

      // Raw data and responses
      ecoinvent_activities_raw: {
        type: 'string',
        nullable: true,
        description: 'Raw ecoinvent activities data',
      },
      mapped_ecoinvent_activity: {
        type: 'string',
        nullable: true,
        description: 'Mapped ecoinvent activity',
      },
      unit_conversion_response: {
        type: 'string',
        nullable: true,
        description: 'Response from unit conversion',
      },
      raw_process_inputs_response: {
        type: 'string',
        nullable: true,
        description: 'Raw response for process inputs',
      },
    },
  },
} as const satisfies TSchema;
