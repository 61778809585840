import { ReactNode, useState } from 'react';
import {
  Box,
  Collapse,
  Stack,
  SxProps,
  Theme,
  TypographyProps,
} from '@mui/material';
import CollapseButton from './CollapseButton';
import { mixinSx } from '@watershed/style/styleUtils';
import { CollapseIconProps } from './CollapseIcon';

export default function CollapsibleBlock({
  title,
  initiallyExpanded,
  children,
  expanded,
  onToggle = () => {},
  sx,
  isIconOnRight,
  buttonSx,
  iconProps,
  typographyProps = {},
  rightComponent,
}: {
  title: LocalizedString | JSX.Element;
  expanded?: boolean;
  onToggle?: (expanded: boolean) => void;
  initiallyExpanded?: boolean;
  isIconOnRight?: boolean;
  children: ReactNode;
  sx?: SxProps<Theme>;
  buttonSx?: SxProps<Theme>;
  iconProps?: CollapseIconProps;
  typographyProps?: TypographyProps<'span'>;
  rightComponent?: ReactNode;
}) {
  const [isExpanded, setIsExpanded] = useState(initiallyExpanded ?? true);

  const isControlled = expanded !== undefined;

  return (
    <Box sx={sx}>
      <CollapseButton
        isExpanded={isControlled ? expanded : isExpanded}
        setIsExpanded={isControlled ? onToggle : setIsExpanded}
        isIconOnRight={isIconOnRight}
        sx={mixinSx(
          {
            width: `100%`,
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: isIconOnRight ? 'flex-end' : 'flex-start',
          },
          buttonSx
        )}
        typographyProps={{
          ...typographyProps,
        }}
        iconProps={iconProps}
      >
        <Stack direction="row" gap={1} alignItems="center">
          {title}
          {/* Add a flexGrow box to make these slide to fill the space. */}
          <Box sx={{ flexGrow: 1 }} />
          {rightComponent}
        </Stack>
      </CollapseButton>

      <Collapse
        in={isControlled ? expanded : isExpanded}
        sx={{ gridColumnStart: 2 }}
      >
        <Box sx={{ paddingY: 1 }}>{children}</Box>
      </Collapse>
    </Box>
  );
}
