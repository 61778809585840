import { ReactNode, useId } from 'react';
import { SxProps, Typography, TypographyProps, Theme } from '@mui/material';
import Button from './Button';
import { mixinSx } from '@watershed/style/styleUtils';
import CollapseIcon, { CollapseIconProps } from './CollapseIcon';

export default function CollapseButton({
  children,
  isExpanded,
  setIsExpanded,
  sx,
  iconProps,
  isIconOnRight,
  typographyProps,
  onClick,
}: {
  isExpanded: boolean;
  setIsExpanded: (expanded: boolean) => void;
  children: ReactNode;
  sx?: SxProps<Theme>;
  iconProps?: CollapseIconProps;
  typographyProps?: TypographyProps<'span'>;
  isIconOnRight?: boolean;
  onClick?: (event: React.MouseEvent) => void;
}) {
  const contentDomId = useId();
  return (
    <Button
      aria-expanded={isExpanded}
      aria-controls={contentDomId}
      variant="text"
      onClick={(event) => {
        onClick?.(event);
        setIsExpanded(!isExpanded);
      }}
      sx={mixinSx(
        {
          padding: 0,
          display: 'flex',
          gap: 0.5,
          flexDirection: isIconOnRight ? 'row-reverse' : 'row',
          textAlign: 'left',
          '&:hover, &:focus': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        },
        sx
      )}
    >
      <CollapseIcon size={16} {...iconProps} sx={mixinSx(iconProps?.sx)} />
      <Typography
        variant="body2"
        component="span"
        {...typographyProps}
        sx={mixinSx(
          {
            textAlign: 'left',
          },
          typographyProps?.sx
        )}
      >
        {children}
      </Typography>
    </Button>
  );
}
