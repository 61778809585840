/*
    This file is semi-automatically generated from
    cliq/taxonomy/scripts/generate_gics_industry_sector_2023__ceda_5_weighted_mapping.py in the cliq repo
    Do not manually modify this file
*/
import type { WeightedBeaCodes } from './industryCodeUtils';

export const GICS_SECTORS_CODES: {
  [key: string]: {
    label: string;
    BEA_CODES: WeightedBeaCodes;
  };
} = {
  ['10000000']: {
    label: 'Energy',
    BEA_CODES: {
      '211000': 0.28618845,
      '212100': 0.02296834,
      '213111': 0.01744538,
      '324110': 0.51327549,
      '324121': 0.00889313,
      '324122': 0.00704289,
      '324190': 0.02375632,
      '333130': 0.01400242,
      '424700': 0.08402368,
      '486000': 0.02240389,
    },
  },
  ['10100000']: {
    label: 'Energy',
    BEA_CODES: {
      '211000': 0.28618845,
      '212100': 0.02296834,
      '213111': 0.01744538,
      '324110': 0.51327549,
      '324121': 0.00889313,
      '324122': 0.00704289,
      '324190': 0.02375632,
      '333130': 0.01400242,
      '424700': 0.08402368,
      '486000': 0.02240389,
    },
  },
  ['10101000']: {
    label: 'Energy Equipment & Services',
    BEA_CODES: { '213111': 0.55474076, '333130': 0.44525924 },
  },
  ['10102000']: {
    label: 'Oil, Gas & Consumable Fuels',
    BEA_CODES: {
      '211000': 0.29548067,
      '212100': 0.02371409,
      '324110': 0.52994097,
      '324121': 0.00918188,
      '324122': 0.00727157,
      '324190': 0.02452766,
      '424700': 0.08675184,
      '486000': 0.02313132,
    },
  },
  ['15000000']: {
    label: 'Materials',
    BEA_CODES: {
      '2122A0': 0.02248315,
      '321100': 0.03159076,
      '3219A0': 0.02311347,
      '322120': 0.04056197,
      '322130': 0.02376303,
      '322210': 0.04382099,
      '325110': 0.10055594,
      '325180': 0.03642098,
      '325190': 0.12649576,
      '325211': 0.08353706,
      '325310': 0.02557458,
      '325414': 0.02331182,
      '325510': 0.02250713,
      '3259A0': 0.03727,
      '326110': 0.03921501,
      '326190': 0.08124951,
      '331110': 0.14265947,
      '331313': 0.04165178,
      '331410': 0.02977746,
      '331420': 0.02444014,
    },
  },
  ['15100000']: {
    label: 'Materials',
    BEA_CODES: {
      '2122A0': 0.02248315,
      '321100': 0.03159076,
      '3219A0': 0.02311347,
      '322120': 0.04056197,
      '322130': 0.02376303,
      '322210': 0.04382099,
      '325110': 0.10055594,
      '325180': 0.03642098,
      '325190': 0.12649576,
      '325211': 0.08353706,
      '325310': 0.02557458,
      '325414': 0.02331182,
      '325510': 0.02250713,
      '3259A0': 0.03727,
      '326110': 0.03921501,
      '326190': 0.08124951,
      '331110': 0.14265947,
      '331313': 0.04165178,
      '331410': 0.02977746,
      '331420': 0.02444014,
    },
  },
  ['15101000']: {
    label: 'Chemicals',
    BEA_CODES: {
      '325110': 0.14383048,
      '325130': 0.01220458,
      '325180': 0.05209485,
      '325190': 0.18093357,
      '325211': 0.11948748,
      '3252A0': 0.03190643,
      '325310': 0.03658067,
      '325320': 0.02059368,
      '325411': 0.01682093,
      '325413': 0.01627783,
      '325414': 0.03334412,
      '325510': 0.03219314,
      '325520': 0.01622132,
      '3259A0': 0.05330925,
      '326110': 0.0560913,
      '326120': 0.02113186,
      '326140': 0.0108179,
      '326150': 0.01277378,
      '326160': 0.01717137,
      '326190': 0.11621546,
    },
  },
  ['15102000']: {
    label: 'Construction Materials',
    BEA_CODES: {
      '212310': 0.13633539,
      '2123A0': 0.15345015,
      '327100': 0.06968591,
      '327310': 0.05567649,
      '327320': 0.19443327,
      '327330': 0.05213264,
      '327390': 0.08210403,
      '327400': 0.05772665,
      '327910': 0.04629456,
      '327991': 0.03459889,
      '327992': 0.03153341,
      '327993': 0.04742703,
      '327999': 0.03860158,
    },
  },
  ['15103000']: {
    label: 'Containers & Packaging',
    BEA_CODES: {
      '322210': 0.44247717,
      '322220': 0.16502711,
      '327200': 0.21751675,
      '332430': 0.17497897,
    },
  },
  ['15104000']: {
    label: 'Metals & Mining',
    BEA_CODES: {
      '212230': 0.03471313,
      '2122A0': 0.06756329,
      '331110': 0.42870071,
      '331200': 0.03217257,
      '331313': 0.12516624,
      '331410': 0.08948314,
      '331420': 0.07344415,
      '331490': 0.04276161,
      '331510': 0.06357479,
      '331520': 0.04242036,
    },
  },
  ['15105000']: {
    label: 'Paper & Forest Products',
    BEA_CODES: {
      '113000': 0.08763403,
      '321100': 0.15463603,
      '321200': 0.10089579,
      '321910': 0.10319417,
      '3219A0': 0.11313987,
      '322110': 0.03134844,
      '322120': 0.19854985,
      '322130': 0.11631944,
      '322230': 0.02975149,
      '322291': 0.04317585,
      '322299': 0.02135504,
    },
  },
  ['20000000']: {
    label: 'Industrials',
    BEA_CODES: {
      '230301': 0.04451135,
      '233240': 0.02869354,
      '233262': 0.02060727,
      '2332C0': 0.03083246,
      '2332D0': 0.02367493,
      '423800': 0.04036,
      '481000': 0.04788557,
      '484000': 0.08698607,
      '48A000': 0.03888607,
      '492000': 0.02269974,
      '518200': 0.03798091,
      '541200': 0.04395344,
      '541300': 0.07929738,
      '541610': 0.05383808,
      '541700': 0.13148696,
      '5419A0': 0.02217308,
      '550000': 0.11574677,
      '561300': 0.06689566,
      '561700': 0.03849586,
      '562111': 0.011587407276015399,
      '562212': 0.0018652966625666,
      '562213': 0.0011613926333188,
      '562910': 0.0034390421637104,
      '562920': 0.0015373589691926,
      '562HAZ': 0.0032322048654350997,
      '562OTH': 0.0021721674297610996,
    },
  },
  ['20100000']: {
    label: 'Capital Goods',
    BEA_CODES: {
      '230301': 0.10103109,
      '233210': 0.02646619,
      '233230': 0.02799445,
      '233240': 0.06512809,
      '233262': 0.04677403,
      '2332A0': 0.04638637,
      '2332C0': 0.06998298,
      '2332D0': 0.05373694,
      '332310': 0.02010331,
      '332320': 0.0186741,
      '332710': 0.02116024,
      '332720': 0.01460391,
      '333111': 0.01700224,
      '333120': 0.01919042,
      '336120': 0.01834582,
      '336411': 0.04640746,
      '336412': 0.01760022,
      '336413': 0.01508369,
      '423800': 0.09160843,
      '550000': 0.26272001,
    },
  },
  ['20101000']: {
    label: 'Aerospace & Defense',
    BEA_CODES: {
      '336411': 0.50489821,
      '336412': 0.19148476,
      '336413': 0.16410575,
      '336414': 0.08619123,
      '33641A': 0.02546012,
      '336992': 0.02785994,
    },
  },
  ['20102000']: {
    label: 'Building Products',
    BEA_CODES: {
      '233210': 0.22124004,
      '233262': 0.39100026,
      '2332A0': 0.3877597,
    },
  },
  ['20103000']: {
    label: 'Construction & Engineering',
    BEA_CODES: {
      '230301': 0.31783421,
      '233230': 0.08806789,
      '233240': 0.20488679,
      '2332C0': 0.2201598,
      '2332D0': 0.16905131,
    },
  },
  ['20104000']: {
    label: 'Electrical Equipment',
    BEA_CODES: {
      '333611': 0.09854085,
      '333612': 0.02486037,
      '333613': 0.02371263,
      '333618': 0.15746547,
      '335120': 0.06045091,
      '335210': 0.01888384,
      '335221': 0.02625038,
      '335222': 0.02088477,
      '335224': 0.02151007,
      '335228': 0.02626825,
      '335311': 0.03412311,
      '335312': 0.06511976,
      '335313': 0.06375603,
      '335314': 0.05787828,
      '335911': 0.03736272,
      '335912': 0.02623847,
      '335920': 0.08810075,
      '335930': 0.0704139,
      '335991': 0.01974138,
      '335999': 0.05843806,
    },
  },
  ['20105000']: {
    label: 'Industrial Conglomerates',
    BEA_CODES: { '550000': 1.0 },
  },
  ['20106000']: {
    label: 'Machinery',
    BEA_CODES: {
      '33211A': 0.03065506,
      '332310': 0.07650748,
      '332320': 0.07106832,
      '332710': 0.08052983,
      '332720': 0.05557832,
      '332800': 0.05513027,
      '33291A': 0.05200603,
      '332999': 0.02918982,
      '33299A': 0.02749651,
      '333111': 0.0647057,
      '333120': 0.07303328,
      '33329A': 0.03948148,
      '333318': 0.03262358,
      '333415': 0.05548763,
      '33391A': 0.029771,
      '333920': 0.05511974,
      '33399A': 0.03852496,
      '336120': 0.06981896,
      '336500': 0.02679022,
      '336611': 0.0364818,
    },
  },
  ['20107000']: {
    label: 'Trading Companies & Distributors',
    BEA_CODES: { '423800': 1.0 },
  },
  ['20200000']: {
    label: 'Commercial & Professional Services',
    BEA_CODES: {
      '323110': 0.02056174,
      '518200': 0.06180997,
      '541200': 0.07152965,
      '541300': 0.12904823,
      '541400': 0.01195212,
      '541610': 0.08761587,
      '5416A0': 0.02005032,
      '541700': 0.21398134,
      '541920': 0.00507004,
      '541940': 0.01147718,
      '5419A0': 0.03608437,
      '561100': 0.02020899,
      '561200': 0.01160878,
      '561300': 0.10886573,
      '561400': 0.03185665,
      '561500': 0.01664854,
      '561600': 0.02330328,
      '561700': 0.062648,
      '561900': 0.01500265,
      '562111': 0.018857299575201,
      '562212': 0.003035576218629,
      '562213': 0.001890045658122,
      '562910': 0.005596683260376,
      '562920': 0.002501891747319,
      '562HAZ': 0.005260077080581499,
      '562OTH': 0.0035349764597714995,
    },
  },
  ['20201000']: {
    label: 'Commercial Services & Supplies',
    BEA_CODES: {
      '323110': 0.08387794,
      '323120': 0.00613735,
      '339940': 0.00456553,
      '561100': 0.08243894,
      '561200': 0.04735593,
      '561400': 0.12995349,
      '561500': 0.06791475,
      '561600': 0.09506154,
      '561700': 0.25556125,
      '561900': 0.0612006,
      '562111': 0.0769249667456056,
      '562212': 0.0123830879782424,
      '562213': 0.0077101018000432,
      '562910': 0.0228306641665856,
      '562920': 0.0102060180301064,
      '562HAZ': 0.0214575397123764,
      '562OTH': 0.014420301567040399,
    },
  },
  ['20202000']: {
    label: 'Professional Services',
    BEA_CODES: {
      '518200': 0.07635098,
      '541200': 0.08835725,
      '541300': 0.15940727,
      '541400': 0.01476389,
      '541610': 0.10822781,
      '5416A0': 0.02476723,
      '541700': 0.26432119,
      '541920': 0.00626278,
      '541940': 0.01417723,
      '5419A0': 0.04457335,
      '561100': 0.02496322,
      '561300': 0.13447676,
      '561400': 0.03935104,
    },
  },
  ['20300000']: {
    label: 'Transportation',
    BEA_CODES: {
      '481000': 0.20136666,
      '482000': 0.07298964,
      '483000': 0.03604229,
      '484000': 0.36579069,
      '485000': 0.06483226,
      '48A000': 0.16352231,
      '492000': 0.09545614,
    },
  },
  ['20301000']: {
    label: 'Air Freight & Logistics',
    BEA_CODES: { '492000': 1.0 },
  },
  ['20302000']: { label: 'Passenger Airlines', BEA_CODES: { '481000': 1.0 } },
  ['20303000']: {
    label: 'Marine Transportation',
    BEA_CODES: { '483000': 1.0 },
  },
  ['20304000']: {
    label: 'Ground Transportation',
    BEA_CODES: {
      '482000': 0.14493212,
      '484000': 0.72633349,
      '485000': 0.1287344,
    },
  },
  ['20305000']: {
    label: 'Transportation Infrastructure',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['25000000']: {
    label: 'Consumer Discretionary',
    BEA_CODES: {
      '233411': 0.03583445,
      '2334A0': 0.04552924,
      '336112': 0.04347255,
      '423100': 0.02809019,
      '423400': 0.05151008,
      '423600': 0.03916969,
      '423A00': 0.06357115,
      '441000': 0.0421335,
      '444000': 0.03470204,
      '448000': 0.03849491,
      '452000': 0.05256472,
      '454000': 0.04955737,
      '4B0000': 0.05774018,
      '541100': 0.07416755,
      '611A00': 0.05247541,
      '721000': 0.0392673,
      '722110': 0.07874593,
      '722211': 0.08385231,
      '722A00': 0.04193651,
      '811100': 0.04718495,
    },
  },
  ['25100000']: {
    label: 'Automobiles & Components',
    BEA_CODES: {
      '326210': 0.03943343,
      '326220': 0.010433,
      '326290': 0.03400914,
      '336111': 0.10831025,
      '336112': 0.33992854,
      '336211': 0.01365424,
      '336212': 0.01541766,
      '336213': 0.00474344,
      '336214': 0.01950104,
      '336310': 0.05350385,
      '336320': 0.0361607,
      '336350': 0.0703256,
      '336360': 0.04346627,
      '336370': 0.05460135,
      '336390': 0.10440262,
      '3363A0': 0.04256601,
      '336991': 0.00954288,
    },
  },
  ['25101000']: {
    label: 'Automobile Components',
    BEA_CODES: {
      '326210': 0.0727261,
      '326220': 0.01924132,
      '326290': 0.06272223,
      '336211': 0.02518219,
      '336212': 0.0284344,
      '336213': 0.00874822,
      '336214': 0.03596529,
      '336310': 0.09867584,
      '336320': 0.06669029,
      '336350': 0.12969978,
      '336360': 0.08016377,
      '336370': 0.10069993,
      '336390': 0.19254719,
      '3363A0': 0.07850345,
    },
  },
  ['25102000']: {
    label: 'Automobiles',
    BEA_CODES: {
      '336111': 0.23659805,
      '336112': 0.74255604,
      '336991': 0.02084591,
    },
  },
  ['25200000']: {
    label: 'Consumer Durables & Apparel',
    BEA_CODES: {
      '230302': 0.11113884,
      '233411': 0.20201607,
      '233412': 0.04213788,
      '2334A0': 0.2566703,
      '313100': 0.00588948,
      '313200': 0.01440885,
      '313300': 0.01099793,
      '314110': 0.01108701,
      '314120': 0.0045483,
      '314900': 0.01276258,
      '315000': 0.01810311,
      '316000': 0.00966316,
      '332200': 0.01277583,
      '333112': 0.00940447,
      '337110': 0.01349738,
      '337121': 0.01091424,
      '337122': 0.00506275,
      '339910': 0.0151985,
      '339920': 0.01290487,
      '423600': 0.22081846,
    },
  },
  ['25201000']: {
    label: 'Household Durables',
    BEA_CODES: {
      '230302': 0.12468806,
      '233411': 0.22664438,
      '233412': 0.04727502,
      '2334A0': 0.28796165,
      '332200': 0.01433336,
      '333112': 0.01055099,
      '334300': 0.00416567,
      '337110': 0.01514288,
      '337121': 0.01224483,
      '337122': 0.00567996,
      '33712N': 0.00357417,
      '423600': 0.24773902,
    },
  },
  ['25202000']: {
    label: 'Leisure Products',
    BEA_CODES: { '339920': 0.84255617, '339930': 0.15744383 },
  },
  ['25203000']: {
    label: 'Textiles, Apparel & Luxury Goods',
    BEA_CODES: {
      '313100': 0.05096303,
      '313200': 0.12468307,
      '313300': 0.0951676,
      '314110': 0.09593848,
      '314120': 0.03935748,
      '314900': 0.1104375,
      '315000': 0.15665039,
      '316000': 0.08361752,
      '339910': 0.13151613,
      '339920': 0.11166879,
    },
  },
  ['25300000']: {
    label: 'Consumer Services',
    BEA_CODES: {
      '541100': 0.12459493,
      '541940': 0.01263506,
      '611100': 0.01650827,
      '611A00': 0.08815406,
      '611B00': 0.02904016,
      '624100': 0.036139,
      '624400': 0.02116513,
      '624A00': 0.01959621,
      '713200': 0.03790397,
      '713900': 0.02792051,
      '721000': 0.0659656,
      '722110': 0.1322862,
      '722211': 0.14086447,
      '722A00': 0.07044963,
      '811100': 0.07926654,
      '811200': 0.02018477,
      '811300': 0.02546511,
      '811400': 0.01373614,
      '812300': 0.01095751,
      '812900': 0.02716676,
    },
  },
  ['25301000']: {
    label: 'Hotels, Restaurants & Leisure',
    BEA_CODES: {
      '713100': 0.01065385,
      '713200': 0.07888285,
      '713900': 0.05810604,
      '721000': 0.13728257,
      '722110': 0.27530394,
      '722211': 0.29315636,
      '722A00': 0.14661439,
    },
  },
  ['25302000']: {
    label: 'Diversified Consumer Services',
    BEA_CODES: {
      '541100': 0.23142056,
      '541920': 0.01036704,
      '541940': 0.02346814,
      '611100': 0.03066218,
      '611A00': 0.16373589,
      '611B00': 0.05393871,
      '624100': 0.06712398,
      '624400': 0.03931176,
      '624A00': 0.03639767,
      '811100': 0.14722837,
      '811200': 0.03749085,
      '811300': 0.04729847,
      '811400': 0.02551327,
      '812200': 0.01523172,
      '812300': 0.02035229,
      '812900': 0.0504591,
    },
  },
  ['25500000']: {
    label: 'Consumer Discretionary Distribution & Retail',
    BEA_CODES: {
      '423100': 0.06389705,
      '423400': 0.11717052,
      '423A00': 0.14460596,
      '441000': 0.09584153,
      '444000': 0.07893709,
      '447000': 0.04834268,
      '448000': 0.08756477,
      '452000': 0.11956952,
      '454000': 0.11272867,
      '4B0000': 0.1313422,
    },
  },
  ['25501000']: {
    label: 'Distributors',
    BEA_CODES: {
      '423100': 0.1961997,
      '423400': 0.35977908,
      '423A00': 0.44402122,
    },
  },
  ['25503000']: {
    label: 'Broadline Retail',
    BEA_CODES: { '452000': 0.51472428, '454000': 0.48527572 },
  },
  ['25504000']: {
    label: 'Specialty Retail',
    BEA_CODES: {
      '441000': 0.21682217,
      '444000': 0.17857928,
      '447000': 0.10936559,
      '448000': 0.19809768,
      '4B0000': 0.29713528,
    },
  },
  ['30000000']: {
    label: 'Consumer Staples',
    BEA_CODES: {
      '1111A0': 0.02846164,
      '1111B0': 0.0616394,
      '112120': 0.02451663,
      '1121A0': 0.05226038,
      '112300': 0.02502709,
      '112A00': 0.0267511,
      '311119': 0.02141667,
      '311420': 0.02201552,
      '311513': 0.02158686,
      '31151A': 0.02169244,
      '311615': 0.03338847,
      '31161A': 0.08111126,
      '311810': 0.02676593,
      '312110': 0.0259917,
      '312200': 0.03268116,
      '325610': 0.02716936,
      '424400': 0.0850771,
      '424A00': 0.19164351,
      '445000': 0.12034475,
      '446000': 0.07045903,
    },
  },
  ['30100000']: {
    label: 'Consumer Staples Distribution & Retail',
    BEA_CODES: {
      '424400': 0.18197361,
      '424A00': 0.40991127,
      '445000': 0.25740849,
      '446000': 0.15070663,
    },
  },
  ['30101000']: {
    label: 'Consumer Staples Distribution & Retail',
    BEA_CODES: {
      '424400': 0.18197361,
      '424A00': 0.40991127,
      '445000': 0.25740849,
      '446000': 0.15070663,
    },
  },
  ['30200000']: {
    label: 'Food, Beverage & Tobacco',
    BEA_CODES: {
      '1111A0': 0.04741414,
      '1111B0': 0.10268482,
      '112120': 0.04084215,
      '1121A0': 0.08706036,
      '112300': 0.04169253,
      '112A00': 0.04456455,
      '311119': 0.03567794,
      '311224': 0.03547899,
      '311300': 0.03268123,
      '311410': 0.02915592,
      '311420': 0.03667556,
      '311513': 0.03596146,
      '31151A': 0.03613735,
      '311615': 0.05562172,
      '31161A': 0.13512292,
      '311810': 0.04458926,
      '311910': 0.03105409,
      '312110': 0.04329946,
      '312120': 0.02984214,
      '312200': 0.05444341,
    },
  },
  ['30201000']: {
    label: 'Beverages',
    BEA_CODES: {
      '312110': 0.40299482,
      '312120': 0.27774548,
      '312130': 0.14491068,
      '312140': 0.17434902,
    },
  },
  ['30202000']: {
    label: 'Food Products',
    BEA_CODES: {
      '1111A0': 0.0496397,
      '1111B0': 0.10750471,
      '111300': 0.02990503,
      '112120': 0.04275923,
      '1121A0': 0.09114686,
      '112300': 0.04364953,
      '112A00': 0.04665636,
      '115000': 0.03017297,
      '311119': 0.03735262,
      '311224': 0.03714433,
      '311300': 0.03421525,
      '311410': 0.03052446,
      '311420': 0.03839707,
      '311513': 0.03764945,
      '31151A': 0.03783359,
      '311615': 0.05823253,
      '31161A': 0.14146542,
      '311810': 0.04668222,
      '3118A0': 0.02655696,
      '311910': 0.03251173,
    },
  },
  ['30203000']: { label: 'Tobacco', BEA_CODES: { '312200': 1.0 } },
  ['30300000']: {
    label: 'Household & Personal Products',
    BEA_CODES: {
      '325610': 0.23456591,
      '325620': 0.15712797,
      '446000': 0.60830612,
    },
  },
  ['30301000']: {
    label: 'Household Products',
    BEA_CODES: { '325610': 0.59885008, '325620': 0.40114992 },
  },
  ['30302000']: {
    label: 'Personal Care Products',
    BEA_CODES: { '446000': 1.0 },
  },
  ['35000000']: {
    label: 'Health Care',
    BEA_CODES: {
      '325412': 0.03867057,
      '339112': 0.01117196,
      '339113': 0.00965224,
      '339114': 0.00128892,
      '339115': 0.00216766,
      '339116': 0.00138542,
      '424200': 0.04710214,
      '541511': 0.07219457,
      '541700': 0.17859319,
      '621100': 0.13176705,
      '621200': 0.03401707,
      '621300': 0.02608986,
      '621400': 0.03261042,
      '621500': 0.01653568,
      '621600': 0.02771746,
      '621900': 0.01075226,
      '622000': 0.2730359,
      '623A00': 0.0509041,
      '623B00': 0.0124386,
      '812100': 0.02190494,
    },
  },
  ['35100000']: {
    label: 'Health Care Equipment & Services',
    BEA_CODES: {
      '339112': 0.01427296,
      '339113': 0.01233141,
      '339114': 0.00164669,
      '339115': 0.00276934,
      '339116': 0.00176997,
      '424200': 0.06017627,
      '541511': 0.09223359,
      '621100': 0.16834157,
      '621200': 0.04345917,
      '621300': 0.03333161,
      '621400': 0.04166208,
      '621500': 0.02112548,
      '621600': 0.03541098,
      '621900': 0.01373676,
      '622000': 0.34882236,
      '623A00': 0.06503352,
      '623B00': 0.01589118,
      '812100': 0.02798509,
    },
  },
  ['35101000']: {
    label: 'Health Care Equipment & Supplies',
    BEA_CODES: {
      '339112': 0.43527914,
      '339113': 0.37606807,
      '339114': 0.05021862,
      '339115': 0.08445592,
      '339116': 0.05397824,
    },
  },
  ['35102000']: {
    label: 'Health Care Providers & Services',
    BEA_CODES: {
      '424200': 0.06877476,
      '621100': 0.19239563,
      '621200': 0.04966898,
      '621300': 0.03809431,
      '621400': 0.0476151,
      '621500': 0.02414407,
      '621600': 0.0404708,
      '621900': 0.01569958,
      '622000': 0.39866504,
      '623A00': 0.07432606,
      '623B00': 0.01816184,
      '812100': 0.03198384,
    },
  },
  ['35103000']: {
    label: 'Health Care Technology',
    BEA_CODES: { '541511': 1.0 },
  },
  ['35200000']: {
    label: 'Pharmaceuticals, Biotechnology & Life Sciences',
    BEA_CODES: {
      '325412': 0.16242137,
      '339112': 0.04692368,
      '339113': 0.04054064,
      '541700': 0.75011431,
    },
  },
  ['35201000']: { label: 'Biotechnology', BEA_CODES: { '541700': 1.0 } },
  ['35202000']: { label: 'Pharmaceuticals', BEA_CODES: { '325412': 1.0 } },
  ['35203000']: {
    label: 'Life Sciences Tools & Services',
    BEA_CODES: { '339112': 0.53648935, '339113': 0.46351065 },
  },
  ['40000000']: {
    label: 'Financials',
    BEA_CODES: {
      '511200': 0.08102252,
      '522A00': 0.12647629,
      '523900': 0.15969449,
      '523A00': 0.07467674,
      '524113': 0.03332421,
      '5241XX': 0.17886381,
      '524200': 0.12507916,
      '525000': 0.04496252,
      '52A000': 0.17590026,
    },
  },
  ['40100000']: { label: 'Banks', BEA_CODES: { '52A000': 1.0 } },
  ['40101000']: { label: 'Banks', BEA_CODES: { '52A000': 1.0 } },
  ['40200000']: {
    label: 'Financial Services',
    BEA_CODES: {
      '511200': 0.16642789,
      '522A00': 0.25979424,
      '523900': 0.32802755,
      '523A00': 0.15339307,
      '525000': 0.09235725,
    },
  },
  ['40201000']: {
    label: 'Financial Services',
    BEA_CODES: { '511200': 0.3904722, '522A00': 0.6095278 },
  },
  ['40202000']: { label: 'Consumer Finance', BEA_CODES: { '522A00': 1.0 } },
  ['40203000']: {
    label: 'Capital Markets',
    BEA_CODES: { '523900': 0.68137411, '523A00': 0.31862589 },
  },
  ['40204000']: {
    label: 'Mortgage Real Estate Investment Trusts (REITs)',
    BEA_CODES: { '525000': 1.0 },
  },
  ['40300000']: {
    label: 'Insurance',
    BEA_CODES: {
      '524113': 0.09880655,
      '5241XX': 0.53033269,
      '524200': 0.37086076,
    },
  },
  ['40301000']: {
    label: 'Insurance',
    BEA_CODES: {
      '524113': 0.09880655,
      '5241XX': 0.53033269,
      '524200': 0.37086076,
    },
  },
  ['45000000']: {
    label: 'Information Technology',
    BEA_CODES: {
      '333242': 0.00825789,
      '334112': 0.0069456,
      '334118': 0.00917165,
      '334210': 0.00763984,
      '334220': 0.0229067,
      '334413': 0.03776599,
      '334418': 0.01338833,
      '33441A': 0.01982386,
      '334510': 0.02215721,
      '334511': 0.03654006,
      '334513': 0.00837411,
      '334515': 0.00993796,
      '334516': 0.01093327,
      '33451A': 0.00928135,
      '425000': 0.05642304,
      '511200': 0.16001038,
      '518200': 0.14423931,
      '541511': 0.20185551,
      '541512': 0.14219332,
      '54151A': 0.07215461,
    },
  },
  ['45100000']: {
    label: 'Software & Services',
    BEA_CODES: {
      '511200': 0.22209687,
      '518200': 0.20020637,
      '541511': 0.28017855,
      '541512': 0.19736651,
      '54151A': 0.1001517,
    },
  },
  ['45102000']: {
    label: 'IT Services',
    BEA_CODES: {
      '518200': 0.25736672,
      '541511': 0.36017151,
      '541512': 0.25371604,
      '54151A': 0.12874573,
    },
  },
  ['45103000']: { label: 'Software', BEA_CODES: { '511200': 1.0 } },
  ['45200000']: {
    label: 'Technology Hardware & Equipment',
    BEA_CODES: {
      '334111': 0.0254189,
      '334112': 0.02664776,
      '334118': 0.03518831,
      '334210': 0.0293113,
      '334220': 0.08788476,
      '334290': 0.01454045,
      '334418': 0.05136621,
      '33441A': 0.07605701,
      '334510': 0.08500923,
      '334511': 0.14019104,
      '334512': 0.00796914,
      '334513': 0.03212846,
      '334514': 0.02028842,
      '334515': 0.03812835,
      '334516': 0.04194702,
      '334517': 0.02646036,
      '33451A': 0.03560919,
      '334610': 0.00937925,
      '425000': 0.21647486,
    },
  },
  ['45201000']: {
    label: 'Communications Equipment',
    BEA_CODES: {
      '334210': 0.22249948,
      '334220': 0.6671253,
      '334290': 0.11037522,
    },
  },
  ['45202000']: {
    label: 'Technology Hardware, Storage & Peripherals',
    BEA_CODES: {
      '334111': 0.29131751,
      '334112': 0.30540103,
      '334118': 0.40328146,
    },
  },
  ['45203000']: {
    label: 'Electronic Equipment, Instruments & Components',
    BEA_CODES: {
      '334418': 0.06576907,
      '33441A': 0.09738307,
      '334510': 0.10884546,
      '334511': 0.17950001,
      '334512': 0.01020365,
      '334513': 0.04113714,
      '334514': 0.02597721,
      '334515': 0.04881938,
      '334516': 0.05370879,
      '334517': 0.03387973,
      '33451A': 0.04559386,
      '334610': 0.01200915,
      '425000': 0.27717349,
    },
  },
  ['45300000']: {
    label: 'Semiconductors & Semiconductor Equipment',
    BEA_CODES: { '333242': 0.17942616, '334413': 0.82057384 },
  },
  ['45301000']: {
    label: 'Semiconductors & Semiconductor Equipment',
    BEA_CODES: { '333242': 0.17942616, '334413': 0.82057384 },
  },
  ['50000000']: {
    label: 'Communication Services',
    BEA_CODES: {
      '339930': 0.00138793,
      '511110': 0.00851832,
      '511120': 0.01114941,
      '511130': 0.03037517,
      '5111A0': 0.01041707,
      '512100': 0.09727271,
      '512200': 0.01335387,
      '515100': 0.03677017,
      '515200': 0.01493716,
      '517110': 0.23662361,
      '517210': 0.14418603,
      '517A00': 0.01795301,
      '519130': 0.05925922,
      '5191A0': 0.00984606,
      '541800': 0.30795026,
    },
  },
  ['50100000']: {
    label: 'Telecommunication Services',
    BEA_CODES: {
      '517110': 0.59339461,
      '517210': 0.36158359,
      '517A00': 0.04502181,
    },
  },
  ['50101000']: {
    label: 'Diversified Telecommunication Services',
    BEA_CODES: { '517110': 0.92947893, '517A00': 0.07052107 },
  },
  ['50102000']: {
    label: 'Wireless Telecommunication Services',
    BEA_CODES: { '517210': 1.0 },
  },
  ['50200000']: {
    label: 'Media & Entertainment',
    BEA_CODES: {
      '339930': 0.00230845,
      '511110': 0.01416798,
      '511120': 0.01854411,
      '511130': 0.0505211,
      '5111A0': 0.01732606,
      '512100': 0.16178753,
      '512200': 0.02221064,
      '515100': 0.0611575,
      '515200': 0.02484404,
      '519130': 0.09856211,
      '5191A0': 0.01637632,
      '541800': 0.51219416,
    },
  },
  ['50201000']: {
    label: 'Media',
    BEA_CODES: {
      '511110': 0.01741194,
      '511120': 0.02279004,
      '511130': 0.06208863,
      '5111A0': 0.0212931,
      '515100': 0.07516038,
      '515200': 0.03053243,
      '519130': 0.1211293,
      '5191A0': 0.02012591,
      '541800': 0.62946827,
    },
  },
  ['50202000']: {
    label: 'Entertainment',
    BEA_CODES: {
      '339930': 0.01239062,
      '512100': 0.86839388,
      '512200': 0.11921551,
    },
  },
  ['50203000']: {
    label: 'Interactive Media & Services',
    BEA_CODES: { '339930': 1.0 },
  },
  ['55000000']: {
    label: 'Utilities',
    BEA_CODES: {
      '221100': 0.72384894,
      '221200': 0.15872512,
      '221300': 0.11742594,
    },
  },
  ['55100000']: {
    label: 'Utilities',
    BEA_CODES: {
      '221100': 0.72384894,
      '221200': 0.15872512,
      '221300': 0.11742594,
    },
  },
  ['55101000']: { label: 'Electric Utilities', BEA_CODES: { '221100': 1.0 } },
  ['55102000']: { label: 'Gas Utilities', BEA_CODES: { '221200': 1.0 } },
  ['55103000']: {
    label: 'Multi-Utilities',
    BEA_CODES: { '221200': 0.57477644, '221300': 0.42522356 },
  },
  ['55104000']: { label: 'Water Utilities', BEA_CODES: { '221300': 1.0 } },
  ['55105000']: {
    label: 'Independent Power and Renewable Electricity Producers',
    BEA_CODES: { '221100': 1.0 },
  },
  ['60000000']: {
    label: 'Real Estate',
    BEA_CODES: { '493000': 0.11697375, '531ORE': 0.88302625 },
  },
  ['60100000']: {
    label: 'Equity Real Estate Investment Trusts (REITs)',
    BEA_CODES: { '493000': 0.11697375, '531ORE': 0.88302625 },
  },
  ['60101000']: { label: 'Diversified REITs', BEA_CODES: { '531ORE': 1.0 } },
  ['60102500']: {
    label: 'Industrial REITs',
    BEA_CODES: { '493000': 0.11697375, '531ORE': 0.88302625 },
  },
  ['60103000']: { label: 'Hotel & Resort REITs', BEA_CODES: { '531ORE': 1.0 } },
  ['60104000']: { label: 'Office REITs', BEA_CODES: { '531ORE': 1.0 } },
  ['60105000']: { label: 'Health Care REITs', BEA_CODES: { '531ORE': 1.0 } },
  ['60106000']: { label: 'Residential REITs', BEA_CODES: { '531ORE': 1.0 } },
  ['60107000']: { label: 'Retail REITs', BEA_CODES: { '531ORE': 1.0 } },
  ['60108000']: { label: 'Specialized REITs', BEA_CODES: { '531ORE': 1.0 } },
  ['60200000']: {
    label: 'Real Estate Management & Development',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['60201000']: {
    label: 'Real Estate Management & Development',
    BEA_CODES: { '531ORE': 1.0 },
  },
};
