import React from 'react';
import { Box, SxProps, Theme } from '@mui/material';
import HelpChat from '../shared/Chat';
import { useRouter } from 'next/router';
import { getDustBotForRoute } from '@watershed/shared-universal/getDustBotForRoute';
import { useLingui } from '@lingui/react/macro';
import { useQuestionHandler } from '../utils/hooks';
import { GQDustBot } from '@watershed/shared-universal/generated/graphql-schema-types';
import { PrecannedMessage } from '../utils/types';

interface RightSidebarHelpWrapperProps {
  sx?: SxProps<Theme>;
  onClose: () => void;
}

const STYLES: { [key: string]: SxProps<Theme> } = {
  chatContainer: {
    flexGrow: 1,
    overflow: 'hidden',
    height: '100dvh',
    borderLeft: (theme) => `0.5px solid ${theme.palette.grey30}`,
    backgroundColor: (theme) => theme.palette.grey05,
  },
};

export function RightSidebarHelpWrapper({
  onClose,
}: RightSidebarHelpWrapperProps) {
  const { t } = useLingui();
  const router = useRouter();
  const {
    dustBot,
    precannedMessages,
  }: {
    dustBot: GQDustBot | null;
    precannedMessages: Array<PrecannedMessage> | undefined;
  } = getDustBotForRoute(router.pathname);

  const { activeMessages, isTyping, handleQuestionSubmit, resetChat } =
    useQuestionHandler(dustBot, precannedMessages);

  if (!dustBot) {
    console.warn('No DustBot configured for this route:', router.pathname);
    return null;
  }

  const chatSx = {
    border: 'none',
    borderRadius: 0,
    backgroundColor: 'transparent',
  };

  return (
    <Box sx={STYLES.chatContainer}>
      <HelpChat
        isContained={true}
        title={t({
          message: `Ask WaterBot...`,
          context: 'Title for the help chat panel',
        })}
        isCollapsed={false}
        activeMessages={activeMessages}
        isTyping={isTyping}
        onQuestionSubmit={handleQuestionSubmit}
        precannedMessages={precannedMessages}
        onResetChat={resetChat}
        onCollapsedChange={onClose}
        sx={chatSx}
      />
    </Box>
  );
}
