import {
  ProductionGraphSchemaType,
  ProductionGraphNode,
  InputRate,
} from './types';
import { ProductionGraphSchema } from '@watershed/shared-universal/TSchema/schemas/productionGraph/ProductionGraphSchema';

import { z } from 'zod';

export function createNodeWithRequiredData(nodeData: {
  name: string;
  nodeType: ProductionGraphNode['nodeType'];
  lifecycleStage: ProductionGraphNode['lifecycleStage'];
  outputAmount: ProductionGraphNode['outputAmount'];
  outputAmountUnit: ProductionGraphNode['outputAmountUnit'];
  outputMaterialName: ProductionGraphNode['outputMaterialName'];
  identifier: ProductionGraphNode['identifier'];
  downstreamNodeIdentifier: ProductionGraphNode['downstreamNodeIdentifier'];
  inputRates: ProductionGraphNode['inputRates'];
}): ProductionGraphNode {
  const defaultNodeValues = {
    airDistance: null,
    airDistanceUnit: null,
    assumptions: null,
    autobomTier: null,
    biogenicEmissionsKgco2e: null,
    conversionFactorCitation: null,
    conversionFactorList: null,
    conversionFactorUnit: null,
    conversionFactorValue: null,
    countryCodes: null,
    cumulativeBiogenicEmissionsKgco2e: null,
    cumulativeEmissionsKgco2e: null,
    cumulativeFlagEmissionsKgco2e: null,
    ecoinventActivity: null,
    ecoinventActivitiesRaw: null,
    ecoinventCpcClassification: null,
    ecoinventEmissionsFactor: null,
    ecoinventGeography: null,
    ecoinventHsCodeClassification: null,
    ecoinventIsicClassification: null,
    ecoinventReferenceProductName: null,
    ecoinventUnit: null,
    edits: null,
    emissionsKgco2e: null,
    facilityAssumptions: null,
    facilityDescription: null,
    facilityName: null,
    facilityLocation: null,
    facilityCompany: null,
    flagEmissionsKgco2e: null,
    mappedEcoinventActivity: null,
    railDistance: null,
    railDistanceUnit: null,
    rawProcessInputsResponse: null,
    roadDistance: null,
    roadDistanceUnit: null,
    supplierName: null,
    unitConversionResponse: null,
    waterDistance: null,
    waterDistanceUnit: null,
    tags: null,
  };
  return {
    ...defaultNodeValues,
    ...nodeData,
  };
}

function convertToSnakeCase(
  record: Record<string, unknown>
): Record<string, unknown> {
  const result: Record<string, unknown> = {};
  for (const [key, value] of Object.entries(record)) {
    // Convert camelCase to snake_case
    const snakeKey = key.replace(
      /[A-Z]/g,
      (letter) => `_${letter.toLowerCase()}`
    );
    result[snakeKey] = value;
  }
  return result;
}

export function convertToCamelCase(
  record: Record<string, unknown>
): Record<string, unknown> {
  const result: Record<string, unknown> = {};
  for (const [key, value] of Object.entries(record)) {
    // Convert snake_case to camelCase
    const camelKey = key.replace(/_([a-z])/g, (_, letter) =>
      letter.toUpperCase()
    );
    result[camelKey] = value;
  }
  return result;
}

function parseInputRates(inputRates: string): Array<InputRate> {
  const inputRateSchema = z.object({
    childNodeIdentifier: z.string(),
    rate: z.number(),
    unitChildPerUnit: z.string().nullish(),
  });

  const inputRatesSchema = z.array(inputRateSchema);

  const parsedData = JSON.parse(inputRates).map(convertToCamelCase);
  return inputRatesSchema.parse(parsedData).map((inputRate) => ({
    ...inputRate,
    unitChildPerUnit: inputRate.unitChildPerUnit ?? '',
  }));
}

function parseTags(tags: string | null): Array<string> {
  if (!tags) {
    return [];
  }

  const tagsSchema = z.array(z.string());
  return tagsSchema.parse(JSON.parse(tags));
}

export function convertTSchemaToNode(
  row: ProductionGraphSchemaType
): ProductionGraphNode {
  const camelCaseRow = convertToCamelCase(row);
  return {
    ...camelCaseRow,
    inputRates: parseInputRates(row.input_rates),
    tags: parseTags(row.tags),
  } as ProductionGraphNode;
}

export function convertNodeToTSchema(
  node: Partial<ProductionGraphNode>
): Partial<ProductionGraphSchemaType> {
  const schemaType = convertToSnakeCase(node);
  const result: Partial<ProductionGraphSchemaType> = { ...schemaType };

  if (node.inputRates) {
    const inputRatesAsSnakeCase = node.inputRates.map(convertToSnakeCase);
    result.input_rates = JSON.stringify(inputRatesAsSnakeCase);
  }

  if (node.tags) {
    result.tags = JSON.stringify(node.tags);
  }

  return result;
}

export function convertCSVImportToTSchema(
  row: Record<string, unknown>
): Record<string, unknown> {
  // Convert empty strings to null values and parse number fields
  const processedRow: Record<string, unknown> = {};
  const schema = ProductionGraphSchema.schema.properties;

  for (const [key, value] of Object.entries(row)) {
    const propertyType = schema[key as keyof typeof schema]?.type;
    if (!propertyType) {
      // Skip any columns that are not in TSchema else it will error
      continue;
    }

    // Handle empty strings
    if (value === '') {
      processedRow[key] = null;
      continue;
    } else if (propertyType === 'number' && typeof value === 'string') {
      processedRow[key] = parseFloat(value);
    } else {
      processedRow[key] = value;
    }
  }
  return processedRow;
}
