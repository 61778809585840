import { keyframes } from '@mui/material';

import Typography from '@mui/material/Typography';
import { CODE_FONT_FAMILY } from '@watershed/style/styleUtils';
import { ReactNode } from 'react';

const dots = keyframes`
  0%, 20% { content: '' }
  40% { content: '.' }
  60% { content: '..' }
  80%, 100% { content: '...' }
`;

export default function WithAnimatedEllipseSuffix({
  children,
}: { children: ReactNode }) {
  return (
    <Typography
      variant="body2"
      fontSize={12}
      fontFamily={CODE_FONT_FAMILY}
      color="grey50"
      sx={{
        mt: 0.25,
        '&::after': {
          content: '"."',
          animation: `${dots} 2s infinite`,
        },
      }}
    >
      {children}
    </Typography>
  );
}
