import { useState } from 'react';
import { Box, SxProps, Theme, useMediaQuery } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';

import { HELP_SIDEBAR_WIDTH } from '@watershed/shared-universal/utils/constants';
import { ErrorFallback } from '../components/ErrorFallback';
import LoggedInLayout from './LoggedInLayout';
import { SkipNavContent } from '../components/navigation/SkipNav';
import { SettingsLayout as Settings } from '../components/settings/v2/components/SettingsLayout';
import SettingsNavigation from '../components/settings/v2/components/navigation/SettingsNavigation';
import { NavigationProvider } from '../components/settings/v2/components/navigation/NavigationContext';
import { RightSidebarHelpTrigger } from '../components/support/HelpPanel/rightPanel/Trigger';
import { RightSidebarHelpWrapper } from '../components/support/HelpPanel/rightPanel/Wrapper';
import { DustBotErrorFallback } from '../components/support/HelpPanel/DustBotErrorFallback';
import { sharedShadow } from '@watershed/style/styleUtils';

const layoutSx: (isRightHelpOpen: boolean) => SxProps<Theme> = (
  isRightHelpOpen
) => ({
  display: 'grid',
  gridTemplateColumns: isRightHelpOpen ? `1fr ${HELP_SIDEBAR_WIDTH}px` : '1fr',
  backgroundColor: (theme) => theme.palette.grey10,
  height: '100dvh',
  position: 'relative',
  overflow: 'hidden',
  outline: (theme: Theme) => `0.5px solid ${theme.palette.grey30}`,
  '@media (max-width: 900px)': {
    gridTemplateColumns: '1fr',
  },
});

export default function SettingsLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isRightHelpOpen, setIsRightHelpOpen] = useState(false);

  const handleRightHelpToggle = () => {
    setIsRightHelpOpen((prev) => !prev);
  };

  const handleRightHelpClose = () => {
    setIsRightHelpOpen(false);
  };

  const isSmallScreen = useMediaQuery('(max-width: 900px)');

  const isRightHelpOpenOrSmallScreen = isRightHelpOpen && isSmallScreen;

  return (
    <LoggedInLayout>
      <Box sx={layoutSx(isRightHelpOpen)}>
        <NavigationProvider>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <SettingsNavigation />
          </ErrorBoundary>
          <Settings.Layout>
            <SkipNavContent />
            {children}
          </Settings.Layout>
        </NavigationProvider>
        {isRightHelpOpen && (
          <ErrorBoundary FallbackComponent={DustBotErrorFallback}>
            <Box
              sx={{
                marginRight: '8px',
                marginTop: '8px',
                marginBottom: '8px',
                borderRadius: '6px',
                border: (theme) => `0.5px solid ${theme.palette.grey30}`,
                boxShadow: sharedShadow,
                width: '100%',
                overflow: 'clip',
                '& > div': {
                  height: 'calc(100dvh - 16px)',
                  border: 0,
                  backgroundColor: 'grey05',
                  ...(isSmallScreen && {
                    position: 'fixed',
                    top: 0,
                    right: 0,
                    zIndex: 2000,
                    height: '100dvh',
                    width: '100dvw',
                  }),
                },
              }}
            >
              <RightSidebarHelpWrapper onClose={handleRightHelpClose} />
            </Box>
          </ErrorBoundary>
        )}
        {isRightHelpOpenOrSmallScreen ? null : (
          <RightSidebarHelpTrigger
            isOpen={isRightHelpOpen}
            onClick={handleRightHelpToggle}
          />
        )}
      </Box>
    </LoggedInLayout>
  );
}
