import { JSONSchema7 } from 'json-schema-to-ts';
import {
  ActionDefinition,
  ActionResult,
} from '@watershed/shared-universal/aiAgent/types';

// Helper methods to manage type safety and validation
// General idea is that application developer only has to define one schema for each action
// and then use these helper methods to create the action definition.
// This will validate response from the LLM before invoking the action.

/**
 * Creates a type-safe action with runtime validation from a JSON Schema.
 *
 * @param name - The name of the action
 * @param schema - The JSON Schema that defines the action's arguments
 * @param func - The function to execute with typed arguments
 * @param comments - Detailed documentation about the action. This is passed to the LLM
 *   so it should be written from the LLM's perspective.
 * @param context - Optional context to bind 'this' to.
 * @returns An ActionDefinition with runtime validation to be passed to AIAgentRegistry
 *
 * @example
 * ```typescript
 * const addNodeSchema = {
 *   type: 'object',
 *   properties: {
 *     name: { type: 'string' },
 *     type: { type: 'string' }
 *   },
 *   required: ['name', 'type']
 * } as const;
 *
 * const addNodeAction = createAction(
 *   'addNode',
 *   addNodeSchema,
 *   (args) => {
 *     return {
 *       message: `Added node ${args.name}`,
 *       data: { success: true }
 *     };
 *   },
 *   'Adds a new node to the graph. This will stage an add node action that can be committed or reverted.'
 * );
 * ```
 */
export function createAction({
  name,
  schema,
  func,
  comments,
  context,
}: {
  name: string;
  schema: JSONSchema7;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  func: (args: any) => ActionResult;
  comments: string;
  context?: unknown;
}): ActionDefinition {
  return {
    name,
    inputSchema: schema,
    veryDetailedComments: comments,
    context,
    function: (args: Record<string, unknown>) => {
      // TODO: Add back validation. Maybe we use zod-from-json-schema to generate a zod schema
      // and then use that to validate the args.

      // TODO: Maybe someone smarter than me can figure out how to get
      // the type inference to work here. I tried with FromSchema but
      // ended up with a bunch of errors around infinite instantiation and
      // types too complex for TS to handle. So for now, given we have
      // runtime validation, we'll just cast to any. Main loss is that
      // developer must ensure the function signature matches the schema.

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return func(args as unknown as any);
    },
  };
}
