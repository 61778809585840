import mapValues from 'lodash/mapValues';

export type UndefinedToNull<T> = {
  [K in keyof T]-?: undefined extends T[K] ? NonNullable<T[K]> | null : T[K];
};

/**
 * Converts all values shallowly in the object that are undefined to null.
 */
export function convertUndefinedToNull<T extends Record<string, unknown>>(
  obj: T
): UndefinedToNull<T> {
  // shhhh lodash i know what i'm doing
  return mapValues(obj, (v) => v ?? null) as UndefinedToNull<T>;
}
