import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import omit from 'lodash/omit';

export type NullToUndefined<T> = {
  [K in keyof T]: null extends T[K] ? NonNullable<T[K]> | undefined : T[K];
};

export type ConvertNullToUndefined<
  T extends Record<string, unknown>,
  K extends keyof T,
> = NullToUndefined<Pick<T, K>> & Omit<T, K>;

/**
 * Converts all values shallowly in the object that are null to undefined for the passed keys
 *
 * Example:
 * ```ts
 *  convertNullsToUndefinedExcept({a : null, b : null, c: 1}, ['b']) // {a : null, b : undefined, c: 1}
 * ```
 */
export function convertNullsToUndefined<
  T extends Record<string, unknown>,
  K extends keyof T,
>(obj: T, specifiedKeys: Array<K>): ConvertNullToUndefined<T, K> {
  return {
    ...omit(obj, specifiedKeys),
    ...convertAllNullsToUndefined(pick(obj, specifiedKeys)),
  };
}
/**
 * Converts all values shallowly in the object that are null to undefined
 */
export function convertAllNullsToUndefined<T extends Record<string, unknown>>(
  obj: T
): NullToUndefined<T> {
  // shhhh lodash i know what i'm doing
  return mapValues(obj, (v, k) => v ?? undefined) as NullToUndefined<T>;
}

/**
 * Converts all values shallowly in the object that are null to undefined except if the values belongs to one of the passed keys
 *
 * Example:
 * ```ts
 *  convertNullsToUndefinedExcept({a : null, b : null, c: 1}, ['b']) // {a : undefined, b : null, c: 1}
 * ```
 */
export function convertNullsToUndefinedExcept<
  T extends Record<string, unknown>,
  K extends keyof T,
>(
  obj: T,
  actuallyNullableKeys: Array<K>
): NullToUndefined<Omit<T, K>> & Pick<T, K> {
  return {
    ...pick(obj, actuallyNullableKeys),
    ...convertAllNullsToUndefined(omit(obj, actuallyNullableKeys)),
  };
}
