import { v4 as uuidv4 } from 'uuid';
import {
  ProductionGraphNode,
  ProductionGraphEditAction,
  ProductionGraphEditActionWithUnvalidatedData,
} from '@watershed/shared-universal/productionGraph/types';
import { ImpactCategory } from './types';
import assertNever from '@watershed/shared-util/assertNever';
import { convertUndefinedToNull } from '@watershed/shared-universal/utils/convertUndefinedToNull';
import {
  GQProductionGraphNodeEdit,
  GQProductionGraphNodeEditDataInput,
} from '@watershed/shared-universal/generated/graphql-schema-types';
import { convertAllNullsToUndefined } from '../utils/convertNullToUndefined';
import { BadInputError } from '@watershed/errors/BadInputError';
import { BadDataError } from '@watershed/errors/BadDataError';
import invariant from 'invariant';

const getUpstreamNodes = (
  node: ProductionGraphNode,
  productionGraphNodes: Array<ProductionGraphNode>
): Array<ProductionGraphNode> => {
  return productionGraphNodes.filter(
    (n) => n.downstreamNodeIdentifier === node.identifier
  );
};

export const getNodeMaterialInputs = (
  node: ProductionGraphNode,
  productionGraphNodes: Array<ProductionGraphNode>
): Array<ProductionGraphNode> => {
  const upstreamNodes = getUpstreamNodes(node, productionGraphNodes);
  // Only include material, processing, and transportation nodes
  return upstreamNodes.filter(
    (n) =>
      n.nodeType === 'material' ||
      n.nodeType === 'processing' ||
      n.nodeType === 'transportation'
  );
};

export const getNodeProcessInputs = (
  node: ProductionGraphNode,
  productionGraphNodes: Array<ProductionGraphNode>
): Array<ProductionGraphNode> => {
  const upstreamNodes = getUpstreamNodes(node, productionGraphNodes);
  // Only include material, processing, and transportation nodes
  return upstreamNodes.filter((n) => n.nodeType === 'process_input');
};

export const getImpactForNode = (
  node: ProductionGraphNode,
  impactCategory: ImpactCategory
): number | null => {
  switch (impactCategory) {
    case ImpactCategory.FossilEmissions:
      return node.emissionsKgco2e;
    case ImpactCategory.BiogenicEmissions:
      return node.biogenicEmissionsKgco2e;
    case ImpactCategory.FlagEmissions:
      return node.flagEmissionsKgco2e;
    default:
      assertNever(impactCategory);
  }
};

export const getCumulativeImpactsForNode = (
  node: ProductionGraphNode,
  impactCategory: ImpactCategory
): number | null => {
  switch (impactCategory) {
    case ImpactCategory.FossilEmissions:
      return node.cumulativeEmissionsKgco2e;
    case ImpactCategory.BiogenicEmissions:
      return node.cumulativeBiogenicEmissionsKgco2e;
    case ImpactCategory.FlagEmissions:
      return node.cumulativeFlagEmissionsKgco2e;
    default:
      assertNever(impactCategory);
  }
};

export function convertEditToGQL(
  edit: ProductionGraphEditAction
): GQProductionGraphNodeEdit {
  switch (edit.type) {
    case 'AddNode': {
      const fieldsToNull = Object.keys(edit.data).filter(
        (key) => edit.data[key as keyof typeof edit.data] === null
      );
      return {
        data: {
          fieldsToNull,
          ...convertUndefinedToNull(edit.data),
        },
        type: edit.type,
        nodeId: edit.nodeId,
      };
    }
    case 'DeleteNode': {
      return {
        type: edit.type,
        nodeId: edit.nodeId,
        data: null,
      };
    }
    case 'EditNode': {
      const fieldsToNull = Object.keys(edit.data).filter(
        (key) => edit.data[key as keyof typeof edit.data] === null
      );
      return {
        data: {
          fieldsToNull,
          ...convertUndefinedToNull(edit.data),
        },
        type: edit.type,
        nodeId: edit.nodeId,
      };
    }
  }
}

// Note that data coming in from GQL is not validated against the discriminated union, so we use unknown type here.
// We will validate the data downstream using TSchema. Data should NOT be operated on
// in typescript without validation.
export function convertEditFromGQL(
  edit: GQProductionGraphNodeEdit
): ProductionGraphEditActionWithUnvalidatedData {
  const { type, nodeId } = edit;

  const cleanData = (data: GQProductionGraphNodeEditDataInput) => {
    const { fieldsToNull, ...rest } = data;
    return {
      ...convertAllNullsToUndefined(rest),
      ...(Object.fromEntries(
        fieldsToNull.map((key) => [key, null])
      ) as Partial<ProductionGraphNode>),
    };
  };

  switch (type) {
    case 'DeleteNode': {
      if (!nodeId) {
        throw new BadInputError('DeleteNode action requires a nodeId');
      }
      if (edit.data) {
        throw new BadInputError(
          'DeleteNode action should not have any data fields'
        );
      }
      return {
        type,
        nodeId,
      };
    }

    case 'EditNode': {
      if (!nodeId) {
        throw new BadInputError('EditNode action requires a nodeId');
      }
      if (!edit.data) {
        throw new BadInputError('EditNode action should have data');
      }
      const cleanedData = cleanData(edit.data);
      return {
        type,
        nodeId,
        data: cleanedData,
      };
    }

    case 'AddNode': {
      if (!edit.data) {
        throw new BadInputError('Add actions should have data');
      }
      const cleanedData = cleanData(edit.data);
      return {
        type,
        nodeId,
        data: cleanedData,
      };
    }
  }
}

export const recalculateEmissionsAndOutputAmountsInPlace = (
  nodeMap: Record<string, ProductionGraphNode>,
  deletedNodeIds: Set<string>
): void => {
  let updatedNodeCount = 0;

  // Before we start, we need to validate the production graph
  validateProductionGraph(nodeMap, deletedNodeIds);

  const visited = new Set<string>();
  // Recursive function to traverse graph and calculate emissions and output amounts
  // Note we cannot possibly have a cyclic graph because of how we specify
  // downstream node identifier today as a single pointer, so we do not track visited nodes
  const calculateEmissionsAndOutputAmounts = (
    node: ProductionGraphNode,
    requiredOutputAmount: number
  ): number => {
    if (visited.has(node.identifier)) {
      throw new BadDataError(
        // eslint-disable-next-line @watershed/no-join-commas
        `Cycle detected in production graph. Node ${node.identifier} has already been visited. Visited nodes: ${Array.from(visited).join(', ')}`
      );
    }
    visited.add(node.identifier);

    if (
      node.conversionFactorValue !== null &&
      node.ecoinventEmissionsFactor !== null
    ) {
      node.emissionsKgco2e =
        node.ecoinventEmissionsFactor *
        node.conversionFactorValue *
        requiredOutputAmount;
    } else {
      // Workaround for now because emissions factors either don't exist (transportation) or are not guaranteed
      // to match units. We don't have unit conversion yet, so we shouldn't just multiply by the emissions factor.
      // Instead, we scale the emissions by the ratio of the new output amount to the old output amount.
      node.emissionsKgco2e =
        node.outputAmount !== 0
          ? (requiredOutputAmount / node.outputAmount) *
            (node.emissionsKgco2e || 0)
          : 0;
    }
    node.outputAmount = requiredOutputAmount;

    invariant(
      !isNaN(node.emissionsKgco2e),
      `Emissions for node ${node.identifier} should be a number. ${requiredOutputAmount} / ${node.outputAmount} * ${node.emissionsKgco2e}`
    );

    // Calculate upstream emissions using input rates
    const upstreamEmissions = node.inputRates.reduce((sum, inputRate) => {
      const upstreamNode = nodeMap[inputRate.childNodeIdentifier];
      if (upstreamNode) {
        return (
          sum +
          calculateEmissionsAndOutputAmounts(
            upstreamNode,
            inputRate.rate * node.outputAmount
          )
        );
      }
      return sum;
    }, 0);

    const cumulativeEmissions = (node.emissionsKgco2e || 0) + upstreamEmissions;
    node.cumulativeEmissionsKgco2e = cumulativeEmissions;
    updatedNodeCount++;
    return cumulativeEmissions;
  };

  // Start calculation from nodes that have no downstream nodes. In practice there should only be one such node,
  // which is the functional unit, but this function is written to handle arbitrary DAGs.
  Object.values(nodeMap).forEach((node) => {
    if (
      !node.downstreamNodeIdentifier &&
      !deletedNodeIds.has(node.identifier)
    ) {
      calculateEmissionsAndOutputAmounts(node, node.outputAmount);
    }
  });

  invariant(
    updatedNodeCount === Object.keys(nodeMap).length - deletedNodeIds.size,
    `All nodes should have been recalculated except deleted nodes. Updated node count: ${updatedNodeCount}, total nodes: ${Object.keys(nodeMap).length}, deleted nodes: ${deletedNodeIds.size}`
  );
};
/**
 * Checks that downstream node identifiers and input rates are consistent across the production graph.
 * @param nodeMap Map of node identifiers to nodes
 * @param deletedNodes Array of node identifiers that have been deleted.
 * This is a super special case for now that graph may contain deleted nodes still to show to users
 * In such case downstream node identifier is defined, but input rates is not
 * @throws Error if inconsistencies are found
 */
export function validateDownstreamNodeIdentifierAndInputRatesConsistency(
  nodeMap: Record<string, ProductionGraphNode>,
  deletedNodeIds: Set<string>
): void {
  // Check each node to ensure that if it has a downstream node, it appears in that node's inputRates
  for (const [nodeId, node] of Object.entries(nodeMap)) {
    if (deletedNodeIds.has(nodeId)) {
      continue;
    }
    if (node.downstreamNodeIdentifier) {
      const downstreamNode = nodeMap[node.downstreamNodeIdentifier];

      if (!downstreamNode) {
        throw new BadDataError(
          `Node ${nodeId} references non-existent downstream node ${node.downstreamNodeIdentifier}`
        );
      }

      // Check if this node appears in the downstream node's inputRates
      const foundInInputRates = downstreamNode.inputRates.some(
        (inputRate) => inputRate.childNodeIdentifier === nodeId
      );

      if (!foundInInputRates) {
        throw new BadDataError(
          `Node ${nodeId} has downstream node ${node.downstreamNodeIdentifier}, but is not listed in its inputRates`
        );
      }
    }
  }

  // Check in the reverse direction: ensure that for each inputRate, the referenced child node has this node as its downstream
  for (const [nodeId, node] of Object.entries(nodeMap)) {
    if (deletedNodeIds.has(nodeId)) {
      continue;
    }
    for (const inputRate of node.inputRates) {
      const childNodeId = inputRate.childNodeIdentifier;
      const childNode = nodeMap[childNodeId];

      if (!childNode) {
        throw new BadDataError(
          `Node ${nodeId} references non-existent child node ${childNodeId} in its inputRates`
        );
      }

      if (childNode.downstreamNodeIdentifier !== nodeId) {
        throw new BadDataError(
          `Node ${nodeId} lists ${childNodeId} in its inputRates, but ${childNodeId} does not have ${nodeId} as its downstream node`
        );
      }
    }
  }

  for (const [nodeId, node] of Object.entries(nodeMap)) {
    if (!node.downstreamNodeIdentifier && node.inputRates.length === 0) {
      if (Object.keys(nodeMap).length - deletedNodeIds.size === 1) {
        // This is the case of a graph with only a functional unit, which is allowed
        continue;
      }
      throw new BadDataError(
        `Node ${nodeId} is orphaned with no downstream node and no input rates`
      );
    }
  }
}

/**
 * Validates the production graph for consistency.
 * @param nodeMap Map of node identifiers to nodes
 * @param deletedNodeIds Set of node identifiers that have been deleted.
 * This is a super special case for now that graph may contain deleted nodes still to show to users
 * In such case downstream node identifier is defined, but input rates is not
 * @throws Error if the graph is invalid
 */
export function validateProductionGraph(
  nodeMap: Record<string, ProductionGraphNode>,
  deletedNodeIds: Set<string>
): void {
  validateDownstreamNodeIdentifierAndInputRatesConsistency(
    nodeMap,
    deletedNodeIds
  );
  validateNoDuplicateInputRates(nodeMap);
}

/**
 * Validates that no node in the production graph has duplicate input rates for the same child node.
 * Each child node should only appear once in a node's inputRates array.
 *
 * @param nodeMap Map of node identifiers to nodes
 * @throws BadDataError if any node has duplicate input rates for the same child node
 */
function validateNoDuplicateInputRates(
  nodeMap: Record<string, ProductionGraphNode>
): void {
  for (const [nodeId, node] of Object.entries(nodeMap)) {
    const childrenIdentifierSet = new Set<string>();
    for (const inputRate of node.inputRates) {
      if (childrenIdentifierSet.has(inputRate.childNodeIdentifier)) {
        throw new BadDataError(
          `Node ${nodeId} has duplicate input rate for child node ${inputRate.childNodeIdentifier}`
        );
      }
      childrenIdentifierSet.add(inputRate.childNodeIdentifier);
    }
  }
}

/**
 * Gets a subgraph consisting of the specified node and all its upstream nodes
 * @param nodeId ID of the root node for the subgraph
 * @param nodeMap Map of all nodes in the graph
 * @returns Array of nodes that form the subgraph
 */
export function getSubgraphForNode(
  nodeId: string,
  nodeMap: Record<string, ProductionGraphNode>
): Array<ProductionGraphNode> {
  const subgraphNodeIds = new Set<string>();

  // Recursive function to collect all upstream nodes
  const collectUpstreamNodes = (id: string) => {
    if (subgraphNodeIds.has(id)) {
      // Prevent processing the same node twice in case of cycles
      return;
    }

    subgraphNodeIds.add(id);

    // Get the current node
    const node = nodeMap[id];
    if (!node) return;

    // Process all input rates (child nodes)
    for (const inputRate of node.inputRates) {
      collectUpstreamNodes(inputRate.childNodeIdentifier);
    }
  };

  // Start collection from the specified node
  collectUpstreamNodes(nodeId);

  // Convert the set of IDs to an array of nodes
  return Array.from(subgraphNodeIds)
    .map((id) => nodeMap[id])
    .filter((node): node is ProductionGraphNode => node !== undefined);
}

export function regenerateIdentifiersForGraph(
  nodes: Array<ProductionGraphNode>
): Array<ProductionGraphNode> {
  // Create a mapping from old identifiers to new ones
  const idMapping: Record<string, string> = {};

  // First pass: generate new identifiers for all nodes
  nodes.forEach((node) => {
    if (node.identifier) {
      idMapping[node.identifier] = uuidv4();
    }
  });

  // Second pass: update all nodes with new identifiers and fix references
  return nodes.map((node) => {
    const newNode = { ...node, identifier: idMapping[node.identifier] };

    // Update downstreamNodeIdentifier if it exists
    if (node.downstreamNodeIdentifier) {
      if (idMapping[node.downstreamNodeIdentifier]) {
        newNode.downstreamNodeIdentifier =
          idMapping[node.downstreamNodeIdentifier];
      } else {
        throw new BadDataError(
          `Node ${node.identifier} has invalid downstream node identifier ${node.downstreamNodeIdentifier}`
        );
      }
    }

    // Update inputRates references
    if (node.inputRates && node.inputRates.length > 0) {
      newNode.inputRates = node.inputRates.map((inputRate) => {
        if (idMapping[inputRate.childNodeIdentifier]) {
          return {
            ...inputRate,
            childNodeIdentifier: idMapping[inputRate.childNodeIdentifier],
          };
        } else {
          throw new BadDataError(
            `Node ${node.identifier} references non-existent child node ${inputRate.childNodeIdentifier} in its inputRates`
          );
        }
      });
    }

    return newNode;
  });
}

/**
 * Queries the production graph for nodes that match the given filters and returns a subgraph of the specified node
 * @param nodes Array of nodes in the production graph
 * @param filters Record of filters to apply to the nodes
 * @param subgraphOptions Options for the subgraph to query
 * @returns Array of nodes that match the filters and form the subgraph
 */
export const queryGraph = (
  nodes: Array<ProductionGraphNode>,
  filters: Record<string, string | number | boolean | null>,
  subgraphOptions?: {
    centerNodeId: string;
    direction: 'upstream' | 'downstream';
    maxDepth: number;
  }
): Array<ProductionGraphNode> => {
  // Start with all nodes or just the subgraph if specified
  let nodesToSearch = [...nodes];
  const nodeMap = new Map<string, ProductionGraphNode>(
    nodes.map((node) => [node.identifier, node])
  );

  if (subgraphOptions) {
    // Find all nodes in the subgraph of the specified node
    const subgraphNodeIds = new Set<string>();
    const { centerNodeId, direction, maxDepth } = subgraphOptions;

    // Function to collect upstream nodes (nodes that flow into the center node)
    const collectUpstreamNodes = (nodeId: string, currentDepth: number = 0) => {
      if (subgraphNodeIds.has(nodeId)) {
        return; // Prevent infinite loops
      }
      if (currentDepth > maxDepth) {
        return; // Exceeding max depth
      }

      subgraphNodeIds.add(nodeId);

      const upstreamInputRates = nodeMap.get(nodeId)?.inputRates;

      upstreamInputRates?.forEach((inputRate) => {
        collectUpstreamNodes(inputRate.childNodeIdentifier, currentDepth + 1);
      });
    };

    // Function to collect downstream nodes (nodes that the center node flows into)
    const collectDownstreamNodes = (
      nodeId: string,
      currentDepth: number = 0
    ) => {
      if (subgraphNodeIds.has(nodeId)) {
        return; // Prevent infinite loops
      }
      if (currentDepth > maxDepth) {
        return; // Exceeding max depth
      }

      subgraphNodeIds.add(nodeId);

      // Find the node that this node flows into
      const downstreamNodeIdentifier =
        nodeMap.get(nodeId)?.downstreamNodeIdentifier;

      if (downstreamNodeIdentifier) {
        collectDownstreamNodes(downstreamNodeIdentifier, currentDepth + 1);
      }
    };

    // Start collection from the specified node based on direction
    if (direction === 'upstream') {
      collectUpstreamNodes(centerNodeId);
    }
    if (direction === 'downstream') {
      collectDownstreamNodes(centerNodeId);
    }

    // Filter to only include nodes in the subgraph
    nodesToSearch = nodesToSearch.filter((node) =>
      subgraphNodeIds.has(node.identifier)
    );
  }

  // Apply all filters
  const nodesToReturn = nodesToSearch.filter((node) => {
    // Check each filter against the node
    const result = Object.entries(filters).every(([key, value]) => {
      // Handle nested properties with dot notation (e.g., "tags.0")
      const parts = key.split('.');
      let current: unknown = node;

      // Navigate to the nested property
      for (const part of parts) {
        if (current === null || current === undefined) {
          return false;
        }
        current = (current as Record<string, unknown>)[part];
      }

      // Handle array properties (e.g., checking if a tag exists)
      if (Array.isArray(current)) {
        return current.includes(value);
      }

      return current === value;
    });

    return result;
  });

  // Filter out nodes with null keys
  return nodesToReturn.map((node) => {
    const filteredNode = { ...node };
    Object.keys(filteredNode).forEach((key) => {
      if (filteredNode[key as keyof ProductionGraphNode] === null) {
        delete filteredNode[key as keyof ProductionGraphNode];
      }
    });
    return filteredNode;
  });
};
