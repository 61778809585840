import React, {
  SetStateAction,
  useLayoutEffect,
  useReducer,
  useState,
} from 'react';
import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import SideBar from '../components/navigation/SideBar';
import usePrevious from '@watershed/ui-core/hooks/usePrevious';
import { SkipNavContent } from '../components/navigation/SkipNav';
import LoggedInLayout from './LoggedInLayout';
import {
  SIDEBAR_BREAKPOINT_WIDTH,
  SIDEBAR_COLLAPSED_WIDTH,
  HELP_SIDEBAR_WIDTH,
  SIDEBAR_EXPANDED_WIDTH,
} from '@watershed/shared-universal/utils/constants';
import { useIsSideBarExpandAutoCollapsed } from '../components/navigation/atoms';
import { useResizeObserverWithPredicate } from '@watershed/shared-frontend/hooks/useResizeObserverWithPredicate';
import { FinanceSnapshotsContextProvider } from '../utils/finance/FinanceSnapshotsContext';
import { useIsOnFinancePage } from '../utils/CurrentProductContext';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '../components/ErrorFallback';
import { RightSidebarHelpTrigger } from '../components/support/HelpPanel/rightPanel/Trigger';
import { RightSidebarHelpWrapper } from '../components/support/HelpPanel/rightPanel/Wrapper';
import { DustBotErrorFallback } from '../components/support/HelpPanel/DustBotErrorFallback';
import { ProductionGraphAIAgentWrapper } from '../components/materials/productionGraph/ProductionGraphAIAgent/ProductionGraphAIAgentWrapper';
import { useRouter } from 'next/router';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '100%',
      width: 'fit-content',
      maxHeight: '100vh',
      height: '100dvh',
      margin: '0',
      padding: '0',
      display: 'grid',
      gridTemplateColumns: `${SIDEBAR_EXPANDED_WIDTH}px 1fr`,
      maxWidth: '100vw',
      backgroundColor: theme.palette.grey100,
      '&.is-collapsed': {
        gridTemplateColumns: `${SIDEBAR_COLLAPSED_WIDTH}px 1fr`,
      },
      '&.is-right-sidebar-open': {
        gridTemplateColumns: `${SIDEBAR_EXPANDED_WIDTH}px 1fr ${HELP_SIDEBAR_WIDTH}px`,
        '&.is-collapsed': {
          gridTemplateColumns: `${SIDEBAR_COLLAPSED_WIDTH}px 1fr ${HELP_SIDEBAR_WIDTH}px`,
        },
      },
      '@media print': { display: 'block' },
    },
    content: {
      width: '100%',
      minHeight: '100vh',
      backgroundColor: theme.palette.background.paper,
      gridColumn: 2,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    rightSidebarContainer: {
      position: 'relative',
      overflow: 'hidden',
      backgroundColor: theme.palette.grey05,
      gridColumn: 3,
    },
  })
);

type SidebarAction =
  | { type: 'set-collapse'; payload: { value: boolean } }
  | {
      type: 'window-resize-collapse';
      payload: { value: boolean };
    };

interface SidebarState {
  isCollapsed: boolean;
  hasManuallyControlled: boolean;
}

function sidebarReducer(
  state: SidebarState,
  action: SidebarAction
): SidebarState {
  switch (action.type) {
    case 'set-collapse':
      return {
        isCollapsed: action.payload.value,
        hasManuallyControlled: true,
      };
    case 'window-resize-collapse':
      if (state.isCollapsed !== action.payload.value) {
        return {
          isCollapsed: action.payload.value,
          hasManuallyControlled: false,
        };
      }
      return state;
    default:
      return state;
  }
}

const predicate = (entry: ResizeObserverEntry) => {
  return entry.contentRect.width < SIDEBAR_BREAKPOINT_WIDTH;
};

function useSidebarState() {
  const [isSideBarExpandAutoCollapsed, setIsSideBarExpandAutoCollapsed] =
    useIsSideBarExpandAutoCollapsed();

  const currentIsCollapsedDueToResize =
    useResizeObserverWithPredicate(predicate);
  const previousIsCollapsedDueToResize = usePrevious(
    currentIsCollapsedDueToResize
  );
  const [{ isCollapsed: isCollapsedState, hasManuallyControlled }, dispatch] =
    useReducer(sidebarReducer, {
      isCollapsed: currentIsCollapsedDueToResize,
      hasManuallyControlled: false,
    });

  useLayoutEffect(() => {
    if (
      currentIsCollapsedDueToResize !== previousIsCollapsedDueToResize &&
      !hasManuallyControlled
    ) {
      dispatch({
        type: 'window-resize-collapse',
        payload: { value: currentIsCollapsedDueToResize },
      });
      document.body.classList.toggle(
        'sidebar-collapsed-yes',
        currentIsCollapsedDueToResize
      );
      document.body.classList.toggle(
        'sidebar-collapsed-no',
        !currentIsCollapsedDueToResize
      );
    } else if (hasManuallyControlled) {
      document.body.classList.toggle('sidebar-collapsed-yes', isCollapsedState);
      document.body.classList.toggle('sidebar-collapsed-no', !isCollapsedState);
    }
  }, [
    currentIsCollapsedDueToResize,
    previousIsCollapsedDueToResize,
    hasManuallyControlled,
    isCollapsedState,
  ]);

  const isCollapsed = isCollapsedState || isSideBarExpandAutoCollapsed;
  const setIsCollapsed = (value: SetStateAction<boolean>) => {
    setIsSideBarExpandAutoCollapsed(false);
    const newValue = typeof value === 'function' ? value(isCollapsed) : value;
    dispatch({
      type: 'set-collapse',
      payload: { value: newValue },
    });
    document.body.classList.toggle('sidebar-collapsed-yes', newValue);
    document.body.classList.toggle('sidebar-collapsed-no', !newValue);
  };

  return {
    isCollapsed,
    setIsCollapsed,
  };
}

export default function SidebarLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const classes = useStyles();
  const { isCollapsed, setIsCollapsed } = useSidebarState();
  const isOnFinancePage = useIsOnFinancePage();
  const [isRightHelpOpen, setIsRightHelpOpen] = useState(false);
  const router = useRouter();

  const isProductionGraphRoute = router.pathname.startsWith(
    '/purchased-material/material-variant/'
  );
  // TODO: Add feature flag check if necessary
  const showProductionGraphAgent = isProductionGraphRoute;

  const handleRightHelpToggle = () => {
    setIsRightHelpOpen((prev) => !prev);
    setIsCollapsed(true);
  };

  const handleRightHelpClose = () => {
    setIsRightHelpOpen(false);
  };

  const innerContent = (
    <div
      className={clsx(
        classes.root,
        isCollapsed && 'is-collapsed',
        isRightHelpOpen && 'is-right-sidebar-open'
      )}
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <SideBar isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
      </ErrorBoundary>
      <SkipNavContent />
      <main className={clsx(classes.content)}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {children}
        </ErrorBoundary>
      </main>
      {isRightHelpOpen && (
        <div className={classes.rightSidebarContainer}>
          <ErrorBoundary FallbackComponent={DustBotErrorFallback}>
            {showProductionGraphAgent ? (
              <ProductionGraphAIAgentWrapper
                enabled={isRightHelpOpen}
                onCollapsedChange={handleRightHelpClose}
              />
            ) : (
              <RightSidebarHelpWrapper onClose={handleRightHelpClose} />
            )}
          </ErrorBoundary>
        </div>
      )}
      <RightSidebarHelpTrigger
        isOpen={isRightHelpOpen}
        onClick={handleRightHelpToggle}
      />
    </div>
  );

  const contentWithProvider = isOnFinancePage ? (
    <FinanceSnapshotsContextProvider>
      {innerContent}
    </FinanceSnapshotsContextProvider>
  ) : (
    innerContent
  );
  return <LoggedInLayout>{contentWithProvider}</LoggedInLayout>;
}
